import React from 'react';

import {richText as welcomeEN} from './en/welcome';
import {richText as welcomePL} from './pl/welcome';
import {richText as chromeEN} from './en/chrome';
import {richText as chromePL} from './pl/chrome';

import {richText as opRodoEN} from "./en/operator/rodo";
import {richText as opRodoPL} from "./pl/operator/rodo";

import {richText as disclaimerEN} from "./en/operator/disclaimer";
import {richText as disclaimerPL} from "./pl/operator/disclaimer";

import {rodoPage as opRodoPageEN} from "./en/operator/rodo";
import {rodoPage as opRodoPagePL} from "./pl/operator/rodo";

import {helpPage as opHelpEN} from "./en/operator/help";
import {helpPage as opHelpPL} from "./pl/operator/help";

import {helpPage as govHelpEN} from "./en/gov/help";
import {helpPage as govHelpPL} from "./pl/gov/help";

import {faqPage as opFaqEN} from './en/operator/faq';
import {faqPage as opFaqPL} from './pl/operator/faq';

import {faqPage as govFaqEN} from "./en/gov/faq";
import {faqPage as govFaqPL} from "./pl/gov/faq";

import {disclaimerPage as disclaimerPageEN} from "./en/operator/disclaimer";
import {disclaimerPage as disclaimerPagePL} from "./pl/operator/disclaimer";

import LocalizedPage from './LocalizedPage';

export const chrome = {
    en: chromeEN,
    pl: chromePL,
};

export const welcome = {
    en: welcomeEN,
    pl: welcomePL
};

export const operatorRodo = {
    en: opRodoEN,
    pl: opRodoPL
};

export const disclaimer = {
    en: disclaimerEN,
    pl: disclaimerPL
};

//hmm, slightly different than rodo popup
const operatorRodoPage = {
    en: opRodoPageEN,
    pl: opRodoPagePL
};

const operatorFaqPage = {
    en: opFaqEN,
    pl: opFaqPL
};

const operatorHelpPage = {
    en: opHelpEN,
    pl: opHelpPL
};

const govFaqPage = {
    en: govFaqEN,
    pl: govFaqPL
};

const govHelpPage = {
    en: govHelpEN,
    pl: govHelpPL
};

// same disclaimer for gov and operator - situated in operator directory
const disclaimerPage = {
    en: disclaimerPageEN,
    pl: disclaimerPagePL
};

export const OperatorRodoPage = () => <LocalizedPage content={operatorRodoPage} />;

export const OperatorFaqPage = () => <LocalizedPage content={operatorFaqPage} />;

export const OperatorHelpPage = () => <LocalizedPage content={operatorHelpPage} />;

export const GovFaqPage = () => <LocalizedPage content={govFaqPage} />;

export const GovHelpPage = () => <LocalizedPage content={govHelpPage} />;

export const DisclaimerPage = () => <LocalizedPage content={disclaimerPage} />;

