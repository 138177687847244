import React from "react";

const withMyRender = (myRenderFunction) => WrappedComponent => {

    return class Enhancer extends WrappedComponent {
        render() {
            const superRender = super.render();
            return myRenderFunction(this, superRender);
        }
    };
};

export default withMyRender