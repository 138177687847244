import {CSSTransition} from "react-transition-group";
import React from "react";
import PropTypes from "prop-types";


//TODO transition exit not working
export default class MyTransitionDecorator extends React.Component {
    state = {
        shouldAppear: false
    };

    componentDidMount() {
        this.setState(
            {
                shouldAppear: true
            }
        )
    }

    componentWillUnmount() {
        this.setState(
            {
                shouldAppear: false
            }
        )
    }

    render() {
        const { children,
                timeout,
                unmountOnExit,
                classNames
        } = this.props;

        return (
            <CSSTransition timeout={timeout}
                           classNames={classNames}
                           in={this.state.shouldAppear}
                           unmountOnExit={unmountOnExit}
            >
                {children}
            </CSSTransition>
        )
    }
}

MyTransitionDecorator.propTypes = {
    timeout: PropTypes.number,
    classNames: PropTypes.string.isRequired,
    unmountOnExit: PropTypes.bool,
    children: PropTypes.object.isRequired
};

MyTransitionDecorator.defaultProps = {
    timeout: 0,
    unmountOnExit: false
};