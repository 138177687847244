import React from 'react'
import {Layout} from "ra-ui-materialui";
import {withStyles} from "@material-ui/core/styles";
import compose from 'recompose/compose';
import MySettingsView from "./auth/MySettingsView";
import StoreProvider from "../MyStoreProvider";
import ResponsiveController from "./ResponsiveController";
import MyAccountView from "./auth/MyAccountView";
import InfoBoxView from "./InfoBoxView";
import MyNotificationView from "./auth/MyNotificationView";
import BoxContainer from "./BoxContainer"
import AlertBoxView from "./AlertBoxView";
import PubSubSubscriber from "./PubSubSubscriber";
import MyViewView from "./auth/MyViewView";
import MyAboutView from "./auth/MyAboutView";
import MyCommercialServicesView from "./auth/MyCommercialServicesView";
import EventsStreamWindow from "./EventsStreamWindow";
import {isSuperAdmin} from "../logic/MyRestConfig";
import WelcomeScreens from "./welcomeScreens/WelcomeScreens";

const styles = (theme) => ({
    root: {
        zIndex: "unset"
    },
    appFrame: {
        flex: "1"
    },
    content: {
        backgroundColor: theme.palette.colorCircle.layout,
        paddingLeft: "4rem",
        paddingRight: "4rem",
        paddingBottom: "0",
        paddingTop: "0",
        overflowY: "auto",
        maxHeight: "calc(100vh - 48px)",
        //hack for fitting in content
        width: "70vw",

        [theme.breakpoints.up('sm')]: {
            paddingLeft: "0.5rem",
            paddingRight: "0.5rem",
            width: "68vw"
        },
        [theme.breakpoints.up('lg')]: {
            paddingLeft: "1rem",
            paddingRight: "1rem",
        },
        [theme.breakpoints.up('xl')]: {
            paddingLeft: "4rem",
            paddingRight: "4rem",
        },


    },
    contentWithSidebar: {
        maxHeight: "calc(100vh - 48px)",
    }
});





class MyLayout extends Layout {

    //FIXME!!!
    //using MySettingsView blocks mission editor..

    render() {
        const store = StoreProvider.getStore();
        const {mySelf} = store.getState();

        return (
            <>
                <ResponsiveController>
                    {super.render()}
                    <WelcomeScreens/>
                    <MySettingsView/>
                    <MyAccountView/>
                    <MyNotificationView roles={mySelf.roles}/>
                    <MyViewView/>
                    <MyAboutView/>
                    <MyCommercialServicesView/>
                    <BoxContainer>
                        <AlertBoxView/>
                        <InfoBoxView/>
                    </BoxContainer>
                    {
                        isSuperAdmin(mySelf.roles) && <EventsStreamWindow/>
                    }

                </ResponsiveController>
                {/*fixme -> mount/onmount on login/logout (so here's wrong place), or even better - use saga */}
                <PubSubSubscriber/>
            </>
        )
    }
}

export default compose(
    withStyles(styles)
)(MyLayout);

