
export const STORE_TICK = "STORE_TICK";

export const TIMER_START_RQ = "TIMER_START_RQ";

export const TIMER_STOP_RQ = "TIMER_STOP_RQ";

export const TIMER_TICK_RQ = "TIMER_TICK_RQ";



