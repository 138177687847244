
import React from "react";
import { Divider } from '@material-ui/core'

import MissionConflictsSwitch from "./MissionConflictsSwitch";
import MissionViewerMapUI from '../../../../gl_map/Components/Maps/MissionViewerMapUI';
import Configs from "../../../../Configs";
//1.1 react admin viewer (no full screen link)
//refactoring WIP...

//right now could be moved to missionfield
//or contrary -> unify viewer setup here

export const MISSION_CONFLICTS_MAP_SIZE = 1000; //in px

const MissionViewer = (props) => {

    const {
        record,
        helpers,
        conflicts,
        legsPrimitives,

        bBox,
        ...rest
    } = props;


    const showPreview = (legsPrimitives.length > 0);
    const wrapperStyle = (showPreview) ? { position: "relative", height: `${Configs.MAP_SIZE}px` } : {};

    //const mergedPrimitives = [...helpers, ...legsPrimitives]; //for now primitives should be on top
    //console.warn(legsPrimitives, helpers, mergedPrimitives);
    //console.warn('============legsPrimitives', legsPrimitives);

    //console.warn('============helpers', helpers);

    //console.warn('============inspectData', inspectData);

    if (legsPrimitives.length === 0) {
        console.warn('no geometry in mission viewer, returning warning only');
        return <h5>no geometry in mission!</h5>;
    }

    return (
        <div>

            <MissionConflictsSwitch
                //record={record}
                conflicts={conflicts}
                {...rest}
            />
            <Divider style={{ width: '100%', height: '3px' }} />
            <MissionViewerMapUI
                bBox={bBox}
                legsPrimitives={legsPrimitives}
                //inspectData = {inspectData}
                wrapperStyle={wrapperStyle}
                record={record}
            />

        </div>
    );
};

export default MissionViewer;