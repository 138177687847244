import React from 'react'
import Slider from "rc-slider";
import Tooltip from "rc-tooltip";
import Range from "rc-slider/es/Range";
import 'rc-slider/assets/index.css';
import "./MyVerticalSlider.css"
import Typography from "@material-ui/core/Typography/Typography";
import {withStyles} from "@material-ui/core";



const styles = {
    valueBox: {
        marginBottom: "0.9rem",
    },
    boxText: {
        display: "inline-block",
    },
    boxRow: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
    }
};

class MyVerticalSlider extends React.Component {

    componentDidUpdate(prevProps, prevState, prevContext) {
        if (prevProps.max !== this.props.max ||
            prevProps.min !== this.props.min)
        {
            this._setMinMaxValueBoundries(this.props);
        }
    }

    componentDidMount() {
        this._setMinMaxValueBoundries(this.props);
    }

    _setMinMaxValueBoundries = (props) => {
        if(!!props.max) {
            this._adjustValueToMax(props)
        }
        if(!!props.min) {
            this._adjustValueToMin(props)
        }
    };

    _adjustValueToMax = (props) => {
        if(props.value[1] > props.max) {
            props.onChange([props.value[0], props.max]);
            console.log('MyVerticalSlider - upper value exceeds max value. Setting upper value to max value...')
        }
    };

    _adjustValueToMin = (props) => {
        if(props.value[0] < props.min) {
            props.onChange([props.min, props.value[1]]);
            console.log('MyVerticalSlider - bottom value exceeds min value. Setting bottom value to min value...')
        }
    };


    render() {
        const Handle = Slider.Handle;
        const {onChange, classes, unit='', ...rest} = this.props;

        const handle = (props) => {
            const {value, dragging, index, ...restProps} = props;
            return (
                <Tooltip
                    overlay={`${value}${unit}`}
                    visible={dragging}
                    placement="right"
                    key={index}
                    prefixCls={'my-rc-tooltip'}
                >
                    <Handle value={value} {...restProps} />
                </Tooltip>
            );
        };

        return (
            <>
                <div className={classes.valueBox}>
                    <div className={classes.boxRow}>
                        <Typography className={classes.boxText} variant="body2">&uarr;</Typography>
                        <Typography className={classes.boxText} variant="body2">{this.props.value[1]+unit}</Typography>
                    </div>
                    <div className={classes.boxRow}>
                        <Typography className={classes.boxText} variant="body2">&darr;</Typography>
                        <Typography className={classes.boxText} variant="body2">{this.props.value[0]+unit}</Typography>
                    </div>
                </div>
                <Range pushable handle={handle} vertical onChange={onChange} {...rest}/>
            </>
        )
    }
}

export default withStyles(styles)(MyVerticalSlider);