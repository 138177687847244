import {
    STORE_ALL_ZONETYPES_V2,
} from "../constants/ZoneTypesV2Constants";
import { unixTimeNow } from "../../modules2lib/common/src/TimeUtils";

//no deck.gl in app
const initialState = {
    storeTimestamp: 0
};

const ZoneTypesV2Reducer = (previousState = initialState, { type, payload }) => {
    switch (type) {
        case STORE_ALL_ZONETYPES_V2:
            return {
                ...previousState,
                ...payload,
                storeTimestamp: unixTimeNow()
            };


        default:
            return previousState;
    }
};

export default ZoneTypesV2Reducer;
