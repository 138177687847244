//probably text is common, with only difference in accept line. so it probably can be unified.


import React from 'react';
export const rodoPage = (
    <div id="rodo-content">
        <h2>SPEŁNIENIE OBOWIĄZKU INFORMACYJNEGO</h2>
        Zgodnie z art. 13 ust. 1 i ust. 2 ogólnego Rozporządzenia o ochronie danych osobowych z dnia 27 kwietnia 2016 r.
informuję, iż Administratorem danych osobowych jest Polska Agencja Żeglugi Powietrznej, ul. Wieżowa 8, 02-147
Warszawa. Kontakt do Inspektora Ochrony Danych: inspektorochronydanych@pansa.pl
        <div></div>
        <br/>
        Pani/Pana dane osobowe będą przetwarzane w celu zarejestrowania informacji o zamiarze wykonania lotu
bezzałogowym statkiem powietrznym w związku koniecznością zapewnienia bezpiecznej, ciągłej, płynnej i efektywnej
żeglugi powietrznej w polskiej przestrzeni powietrznej. Przetwarzanie to jest niezbędne do wypełnienia obowiązku
prawnego ciążącego na Administratorze (art. 6 ust. 1 lit. c RODO), a obowiązek ten wynika z Wytycznych nr 15
Prezesa Urzędu Lotnictwa Cywilnego z dnia 1 sierpnia 2023, a także w związku z wykonywaniem zadania
realizowanego w interesie publicznym i w ramach sprawowania władzy publicznej powierzonej PAŻP (art. 6 ust. 1 lit.
e RODO). Pani/Pana dane osobowe możemy przetworzyć również w celu realizacji komunikacji z osobami
zgłaszającymi zamiar wykonania lotu bezzałogowym statkiem powietrznym, a przetwarzanie to jest niezbędne do
celów wynikających z prawnie uzasadnionych interesów realizowanych przez Administratora (art. 6 ust. 1 lit. f RODO)
polegającym na zapewnieniu możliwości przekazania istotnych informacji osobom zgłaszającym zamiar wykonania
lotu bezzałogowym statkiem powietrznym.
        <div></div>
        <br/>
        Pani/Pana dane osobowe będziemy przetwarzać nie dłużej aniżeli 6 lat od momentu usunięcia konta w aplikacji. Dane
osobowe jakie zbierzemy na Pani/Pana temat to: imię i nazwisko, adres zamieszkania, dane działalności, numer
pilota nadany przez ULC, numer telefonu oraz informacje o aktualnej lokalizacji (na podstawie GPS).
        <div></div>
        <br/>
        Pani/Pana dane osobowe mogą zostać przekazane dostawcom systemów i usług IT w tym partnerom
technologicznym, z którymi współpracuje PAŻP, Urzędowi Lotnictwa Cywilnego, oraz upoważnionym podmiotom na
udokumentowany wniosek np. policja. Pani/Pana dane osobowe mogą zostać również przekazane do instytucji
odpowiedzialnych za zapewnienie bezpiecznej żeglugi powietrznej w krajach ościennych w sytuacji, w której lot
wykonywany jest w okolicy Wieży lotniczej kraju ościennego. Jeśli wyrazi Pani/Pan na to zgodę to Pani/Pana numer
telefonu będzie udostępniany również innym użytkownikom aplikacji.
        <div></div>
        <br/>
        W zakresie wynikającym z RODO i z ograniczeniami w nim wskazanymi posiada Pani/Pan prawo dostępu do treści
swoich danych oraz prawo ich sprostowania, usunięcia, ograniczenia przetwarzania, prawo do przenoszenia danych,
prawo wniesienia sprzeciwu.        
        <div></div>
        <br/>
        Ma Pani/Pan prawo wniesienia skargi do Urzędu Ochrony Danych Osobowych mieszczącego się przy ulicy Stawki 2
w Warszawie. Więcej informacji dostępnych pod adresem <a target="_blank" href="https://www.uodo.gov.pl">www.uodo.gov.pl</a>
        <div></div>
        <br/>
        Podanie przez Panią/Pana danych osobowych jest obligatoryjne i wynika bezpośrednio z przepisów prawa.
Wykonanie lotu bezzałogowym statkiem powietrznym bez poinformowania o tym PAŻP jest niezgodne z prawem.
    </div>
);

export const richText = {
    title: `<h2>Korzystając z systemu PansaUTM:</h2>`,
    content:
    `Zgodnie z art. 13 ust. 1 i ust. 2 ogólnego Rozporządzenia o ochronie danych osobowych z dnia 27 kwietnia 2016 r.
informuję, iż Administratorem danych osobowych jest Polska Agencja Żeglugi Powietrznej, ul. Wieżowa 8, 02-147
Warszawa. Kontakt do Inspektora Ochrony Danych: inspektorochronydanych@pansa.pl
        <div></div>
        <br/>
        Pani/Pana dane osobowe będą przetwarzane w celu zarejestrowania informacji o zamiarze wykonania lotu
bezzałogowym statkiem powietrznym w związku koniecznością zapewnienia bezpiecznej, ciągłej, płynnej i efektywnej
żeglugi powietrznej w polskiej przestrzeni powietrznej. Przetwarzanie to jest niezbędne do wypełnienia obowiązku
prawnego ciążącego na Administratorze (art. 6 ust. 1 lit. c RODO), a obowiązek ten wynika z Wytycznych nr 15
Prezesa Urzędu Lotnictwa Cywilnego z dnia 1 sierpnia 2023, a także w związku z wykonywaniem zadania
realizowanego w interesie publicznym i w ramach sprawowania władzy publicznej powierzonej PAŻP (art. 6 ust. 1 lit.
e RODO). Pani/Pana dane osobowe możemy przetworzyć również w celu realizacji komunikacji z osobami
zgłaszającymi zamiar wykonania lotu bezzałogowym statkiem powietrznym, a przetwarzanie to jest niezbędne do
celów wynikających z prawnie uzasadnionych interesów realizowanych przez Administratora (art. 6 ust. 1 lit. f RODO)
polegającym na zapewnieniu możliwości przekazania istotnych informacji osobom zgłaszającym zamiar wykonania
lotu bezzałogowym statkiem powietrznym.
        <div></div>
        <br/>
        Pani/Pana dane osobowe będziemy przetwarzać nie dłużej aniżeli 6 lat od momentu usunięcia konta w aplikacji. Dane
osobowe jakie zbierzemy na Pani/Pana temat to: imię i nazwisko, adres zamieszkania, dane działalności, numer
pilota nadany przez ULC, numer telefonu oraz informacje o aktualnej lokalizacji (na podstawie GPS).
        <div></div>
        <br/>
        Pani/Pana dane osobowe mogą zostać przekazane dostawcom systemów i usług IT w tym partnerom
technologicznym, z którymi współpracuje PAŻP, Urzędowi Lotnictwa Cywilnego, oraz upoważnionym podmiotom na
udokumentowany wniosek np. policja. Pani/Pana dane osobowe mogą zostać również przekazane do instytucji
odpowiedzialnych za zapewnienie bezpiecznej żeglugi powietrznej w krajach ościennych w sytuacji, w której lot
wykonywany jest w okolicy Wieży lotniczej kraju ościennego. Jeśli wyrazi Pani/Pan na to zgodę to Pani/Pana numer
telefonu będzie udostępniany również innym użytkownikom aplikacji.
        <div></div>
        <br/>
        W zakresie wynikającym z RODO i z ograniczeniami w nim wskazanymi posiada Pani/Pan prawo dostępu do treści
swoich danych oraz prawo ich sprostowania, usunięcia, ograniczenia przetwarzania, prawo do przenoszenia danych,
prawo wniesienia sprzeciwu.        
        <div></div>
        <br/>
        Ma Pani/Pan prawo wniesienia skargi do Urzędu Ochrony Danych Osobowych mieszczącego się przy ulicy Stawki 2
w Warszawie. Więcej informacji dostępnych pod adresem <a target="_blank" href="https://www.uodo.gov.pl">www.uodo.gov.pl</a>
        <div></div>
        <br/>
        Podanie przez Panią/Pana danych osobowych jest obligatoryjne i wynika bezpośrednio z przepisów prawa.
Wykonanie lotu bezzałogowym statkiem powietrznym bez poinformowania o tym PAŻP jest niezgodne z prawem.
    `,
        confirm: `Potwierdź`,
        close: 'Zamknij'
};
