//huh common stuff. temp solution
//hmm should be parent class? -> nope inheritance not recommended...
import React from 'react';

import DeckGLDrawToolbar from '../../Components/DeckGLDrawToolbar'
import { Rnd } from 'react-rnd';
import InspectorWindow from '../../old2review/InspectorWindow';
import NewMissionPlannerWindow from '../../old2review/NewMissionPlannerWindow';
import NewZoneEditorWindow from '../../old2review/NewZoneEditorWindow';

import ZonesViewerWindow from '../../old2review/ZonesViewerWindow';
import ZonesListWindow from '../../old2review/ZoneListWindow';

import {calcBoundingBox} from './commonTurf.js';
import {fitViewport2Bbox} from '../../Components/DeckGLOverlay'

export const CustomAttribution = () => (
    <div className="attribution">© <a href="https://www.mapbox.com/about/maps/">Mapbox</a> © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>|ADSBExchange <a href="https://www.mapbox.com/map-feedback/" target="_blank">Improve this map</a>
    </div>
);

//dang. just cleanup now
export function primitives2polys(primitives=[]) {
    let cleanup = [];

    //huh multipoly support not done in calcBoundingBox..
    //as usually -> fixme/optimize later (..and check docs/implemantations for multipolys and rings)
    primitives.forEach((primitive) => {
        if (primitive.polygon) {
            cleanup.push(primitive);
        }
    });

    return cleanup;
}


export function primitives2Bbox (primitives = []) {
    if (primitives.length === 0) return null;
    let tempArr = [[]];

    //huh multipoly support not done in calcBoundingBox..
    //as usually -> fixme/optimize later (..and check docs/implemantations for multipolys and rings)
    primitives.forEach((primitive) => {
        if (primitive.polygon && primitive.polygon.length > 0) {
            const bbox = calcBoundingBox(primitive.polygon);
            tempArr[0].push([bbox.west, bbox.south], [bbox.east, bbox.north]);
        }
    });

    return calcBoundingBox(tempArr);
}




//purified js
//deckgl viewport bounded to primitives
//... and editables.. hmmm
//hmm was refactored, is unused now.. so not tested (poly cleanup removed btw)

export function primitives2newViewport ({viewportData, primitives = []}) { //, editables = []}) {

    const bBox = primitives2Bbox(primitives);
    //console.log(finalBbox);

    if (bBox) {
        fitViewport2Bbox({viewportData, bBox})
    }

    //was return {viewportData, polys:cleanup};
    return {viewportData};

}


//purified js
//deckgl viewport bounded to primitives
// export function getDimensions (element, props, menuOffset = 0) {
//
//     const parentRect = element.parentElement.getBoundingClientRect();
//
//     const width =  props.width || element.innerWidth || parentRect.width || window.innerWidth;
//
//     console.log('getDimensions', props.width , element.innerWidth , parentRect.width , window.innerWidth);
//     let height = props.height
//         || (element.innerHeight - menuOffset)
//         || (parentRect.height - menuOffset)
//         || (window.innerHeight - menuOffset);
//     console.log('getDimensions', width, height);
//
//     return {
//         width, height
//     };
// };
