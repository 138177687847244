import {withStyles} from '@material-ui/core/styles'
import {Sidebar} from 'ra-ui-materialui'


const styles = theme => {console.log("sidebar", theme);
return({
    drawerPaper: {
        position: 'relative',
        height: 'auto',
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        //backgroundImage: `linear-gradient(to right,  ${theme.palette.secondary.main}, ${theme.palette.secondary.light})`,
        backgroundImage: `url('img/drone_background.jpg')`,
        backgroundRepeat: "no-repeat",
        '@media screen and (min-height: 786px)': {
            backgroundSize: "cover",
            backgroundPositionX: "10%",
        },
        '&:before': {
            backgroundColor: theme.palette.colorCircle.menuBackgroundColor ? theme.palette.colorCircle.menuBackgroundColor : undefined,
            content: "close-quote",
            display: "block",
            height: "100%",
            position: "absolute",
            width: "100%",
        },
        marginTop: '0',
        borderRight: 'none',
        [theme.breakpoints.only('xs')]: {
            // marginTop: 0,
            height: '100vh',
            position: 'inherit',
            backgroundColor: 'inherit',
        },
        [theme.breakpoints.up('md')]: {
            border: 'none',
            // marginTop: '1.5em',
        },
    },
})};

class MySidebar extends Sidebar {}

export default withStyles(styles)(MySidebar);