import React, {cloneElement} from "react";
import has from "lodash.has";
import {omit} from "lodash";


export const makeElementTabbable = (el) => {
    return cloneElement(el,
        {
            tabIndex: "0",
            role: "button",
        },
    );
};

export const shouldDisableAdd = (array = [], fields) => {
    const length = array.length;
    if(!array || length === 0) return false;

    const lastEntry = array[length-1];

    for(const field of fields) {
        if(has(lastEntry, field))
            continue;
        else
            return true;
    }
    return false;
};

export const PropsProxyCatcher = (props) => {
    console.log("props catch", props);
    return React.cloneElement(
        props.children,
        omit(props, 'children')
    )
};

export const isSvg = (file) => {
    const {title, base64File} = file;
    const {name, type} = base64File;

    return type === 'image/svg+xml' || title.endsWith('.svg') || name.endsWith('.svg');
}