import {BaseModule, ErrorMsgs} from '../common/'

const ERR = ErrorMsgs.ZONE_TYPE;


//fixme add coder/decoder
export default class ZoneTypesProcessor extends BaseModule {

    constructor(...args) {
        super(...args);
        this._moduleId = 'ZoneType';
    }

    getZoneRules(zoneTypes, zoneType = null) {

        try {
            // eslint-disable-next-line
            let test = zoneTypes[0]
        } catch (e) {
            //this.debug(String(zoneTypes.toString()));
            this.throwErr(ERR.ZONE_TYPES_VALIDATION);
        }

        //fixme -> decode here
        if (zoneType === null) return zoneTypes;

        if (!(zoneTypes[zoneType])) {
            console.log('zoneType', zoneType);
            this.throwErr(ERR.ZONE_TYPE_NOT_FOUND)
        }
        return zoneTypes[zoneType];
    }

}
