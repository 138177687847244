
import {
    DELETE_ZONE,
    FETCH_ALL_ZONES_V2_RQ,
    SAVE_ZONE_DATA_V2_RQ,
    STORE_ALL_ZONES_V2
} from "../constants/ZonesV2Constants";

export const fetchAllZonesV2Rq = (payload) => {
    console.log('fetchAllZonesV2Rq', payload);
    return ({
        type: FETCH_ALL_ZONES_V2_RQ,
        payload,
    });
};

export const storeAllZonesV2 = (payload) => {
    console.log('storeAllZonesV2', payload);
    return ({
        type: STORE_ALL_ZONES_V2,
        payload,
    });
};

export const saveZoneDataV2Rq = (payload) => {

    console.log('saveZoneDataV2Rq', payload);
    return ({
        type: SAVE_ZONE_DATA_V2_RQ,
        payload,
    });
};

// in fact it moves zone from table zone to zone_archive
export const zoneDelete = (payload) => {

    console.log('zoneDelete', payload);
    return ({
        type: DELETE_ZONE,
        payload,
    });
};