//myself related helpers

import {turfDisjoint, turfWithin} from "../../gl_map/glSetups/oldCommon/commonTurf";
import {polygon as turfPoly} from "@turf/helpers";
import {get} from "lodash";

//assumes that partialy overlapping geom is injurisdiction.

export function isInMyJurisdiction(mySelf, zonesV2, turfGeom) {
    let inJurisdiction = false;
    if (mySelf && mySelf.units && mySelf.units.length > 0) {
        for (let i = 0; i<mySelf.units.length; i++) {

            if (mySelf.units[i].geojson && mySelf.units[i].geojson.coordinates) {

                const jurisdiction = turfPoly(mySelf.units[i].geojson.coordinates);
                //console.log('==unit jurisdiction', jurisdiction);

                if (!turfDisjoint(turfGeom, jurisdiction)) {
                    inJurisdiction = true;
                    break;
                }
            }
        }
    }

    ///... aaaand here goes famous _fisJurisdictionHack.
    const userType = get(mySelf, 'pandora.type', false);

    const isFis = (userType && userType === 'FIS');
    if (isFis && inJurisdiction) {
        inJurisdiction = _fisJurisdictionHack(zonesV2.rawData, turfGeom);
    }

    return inJurisdiction;
}


//aargh.
// we need to remove overlapping ctr jurisdiction;

// and since intersects with holes are not supported/tested, we cannot make holes in FIS jurisdiction in db.
// furthermore -> we would have to check other jurisdictions functionality through whole system.
// so.. hack (SLOW btw, so used on load, recalc on button on the other hand maybe even worse. (ticks) )

//31.10.19 should be dynamic after final activities... so both in preprocess checkins and missions (as is now) and in onZoneActivitiesChange (non-existing yet)
//.... to unify with __isCtrControlled(?) and fix activities when activities are closed.

// btw2 . making dynamic ctrControlled checks would break with FIS with holes in db. haha.
function _fisJurisdictionHack(zonesV2rawData = [], turfGeom) {
    //console.log('_fisJurisdictionHack', zonesV2rawData, geojsonFeature);
    for (let i=0; i<zonesV2rawData.length; i++) {
        if (zonesV2rawData[i].inspectData.__zoneType === 'CTR') {
            if (turfWithin(turfGeom, zonesV2rawData[i].inspectData.zoneRecord.geojson)) {
                console.log('in CTR!!!', zonesV2rawData[i].inspectData);
                return false;
            }
        }
    }
    return true;
}
