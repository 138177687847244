//v.01
//new missions from loobpack.. draft now

import PubSub from 'pubsub-js'
import {PubSubSignals} from '../constants/PubSubSignals'
import StoreProvider from "../../dr_ra2/MyStoreProvider";
import {fetchAllMissionsV2Rq} from "../actions/MissionsV2Actions";
import {deserializeZoneData, isEditableZone} from "./ZonesListV2";
import {deserializeLegData} from "./MyMissionPlanner";
import {functionalColors, standardColors} from "../../gl_map/glSetups/oldCommon/commonInits";
import {InspectTypes} from "../../modules2lib/common/";
import moment from "moment";
import {get, has} from 'lodash'
import {isInMyJurisdiction} from '../oldLogic/MySelf'
import {polygon as turfPoly} from "@turf/helpers";
import {turfWithin} from "../../gl_map/glSetups/oldCommon/commonTurf";

//implemented on the backend, but..
// TODO double check if backend intersections works correctly
function isInvalid3dIntersection(zonePrimitive, legPrimitive) {


    //filtering off intersections by height
    //assuming minmax passed in feet (and correctly calculated with GND AMSL offset)
    const zoneMin = zonePrimitive.min;
    const zoneMax = zonePrimitive.max;
    const legMin = legPrimitive.min;
    const legMax = legPrimitive.max;


    //console.log('=========== isInvalid3dIntersection, ', zoneMin, zoneMax);
    //console.log('=========== isInvalid3dIntersection, ', legMin, legMax);
    //console.log('=========== isInvalid3dIntersection, ',(legMin > zoneMax || legMax < zoneMin));

    return (legMin > zoneMax || legMax < zoneMin);
}


//helper,
//requires record passed through deserializeMissionData
//export function isGovOwnedZone

// fixme.. backend output now is crazy.. so we should optimize there (see url notes)
// to expand and cleanup, and what about another endpoint calls
// todo review usage.. as it is one of crucial functions now..

// myEditableUnitsUids -> to review with rt-map2 functionality..
export function deserializeMissionData({
                                           missionRecord,
                                           mySelf = null,
                                           myAppExtras = null,
                                           slimRequest = false,
                                           calcMaxHeight = true,
                                            zoneTypesV2,
                                            zonesV2 //required for ctr/fis hack, so legs warnings primitives are redundant now?
                                       }) {
    const ret = {missionRecord, legs: [], primitives: [], warnings: [], conflicts: [], __inJurisdiction:false};
    let tempWarningsTracker = {};

    let operator = missionRecord.operator;
    if (!operator) {
        console.warn('no operator data');
        missionRecord.operator = null;
    } else {
        missionRecord.operator.displayName = (operator.firstName && operator.lastName)
            ? `${operator.firstName} ${operator.lastName}`
            : operator.email; //fallback for now...
    }
    const legs = missionRecord.legs;
    if (!legs || legs.length === 0) {
        missionRecord.displayMaxHeight = 'N/A';
        missionRecord.conflicts = [];
        console.warn('no legs');

    } else {

        //precalc owned units, fixme roles!, probably shouldnt be here
        //currently code related to preflight (gov) phase (review rt-map2 usage)
        const myEditableUnitsUids = [];

        if (mySelf && mySelf.units && mySelf.units.length > 0) {
            mySelf.units.forEach((unit) => {
                myEditableUnitsUids.push(unit.uid);
            });

        }

        //ugh. should be numeric already, assuming int amsl feets
        legs.forEach((leg) => {
            leg.min = parseInt(leg.min);
            leg.max = parseInt(leg.max);

            // aaand... mission editor functionality here...
            // warnings are zones affected by conflicts
            // (deduped -> two legs colliding with one zone will create 2 conflicts, but 1 warning)
            // btw, naming probably should be fixed
            //fixme correct primitive behaviour in case of slimRequest
            const {primitive} = deserializeLegData(leg);
            ret.legs.push(primitive);
            ret.primitives.push(primitive);
            const legRecord = primitive.inspectData.legRecord;

            //30.05.2019 note:
            //in rt-map we need to have jurisdiction check (only rt-map?)
            //we need more info on ASM1 vs LAU behaviour.
            if (myAppExtras && myAppExtras.app.name === 'RT_MAP') {

                console.log('===mission leg', mySelf, legRecord);
                const poly = turfPoly(legRecord.geojson.coordinates);

                //console.error('missionJurisdiction check');

                primitive.inspectData.__inJurisdiction = isInMyJurisdiction(mySelf, zonesV2, poly);

                //if any of legs is in jurisdoction, mission jurisdiction check is set to true now.

                if (primitive.inspectData.__inJurisdiction) {
                    ret.__inJurisdiction = true;
                }
            }

            if (legRecord.zones) {
                for (let j = 0; j < legRecord.zones.length; j++) {
                    const zone = legRecord.zones[j];

                    //this part (rt-map) should be fixed when zonesv2 will be used by rt-map
                    //(skip getting zones, connect using uid)
                    //
                    //19.04.2019 note: on the other hand, let's not preoptimize,
                    // as we still don;t have clear input on requested final behaviour...
                    // so... since we are to use warnings list also in dashboard ,
                    // swapping
                    //if (myAppExtras && myAppExtras.app.name === 'RT_MAP') {
                    // to
                    if (slimRequest) {
                        if (!tempWarningsTracker[zone.name]) {
                            tempWarningsTracker[zone.name] = true;

                            ret.warnings.push({
                                name:zone.name,
                                type:zone.type,
                                __slimV1:true,
                                min:zone.min,
                                max:zone.max,
                                ...(has(zone, 'etc.status') && {color: zone.etc.status}),
                            });
                        }
                    }

                    else {

                        //this part (MyMissionField) really sucks, but what can i do.. ugh

                        const zonePrimitive = deserializeZoneData(zone, zoneTypesV2);
                        console.log('=====creating zone primitive for ', zone.name, zonePrimitive);

                        //console.warn('legPrimitive', primitive);

                        if (isInvalid3dIntersection(zonePrimitive.primitive, primitive)) {
                            //shouldn't get here, with correctly working backend, testing

                            console.error('====intersection invalid, skipping', zone.name);

                        } else {

                            //aand we need to connect manually zone data to zoneleg... by id
                            //(two different arrays from backend) - see endpoint in config... loopback issue

                            //btw aaargh leg.zoneLeg is ARRAY (jasiek!)
                            //so connecting here.. zone2zoneLeg
                            if (legRecord.zoneLeg) {
                                legRecord.zoneLeg.forEach((singleZoneLeg) => {

                                    const zoneRecord = zonePrimitive.primitive.inspectData.zoneRecord;

                                    if (singleZoneLeg.zoneId === zoneRecord.uid) {
                                        singleZoneLeg.zonePrimitive = zonePrimitive;
                                        //cnt++
                                        //for each is not breakable.. but sprinting now...
                                        //warnings data as list for gridv view, -> one conflict per one zone leg

                                        //btw. maybe it should be primitive? not parent? like other stuff?
                                        singleZoneLeg.__intersectionValid = true; //just for track..
                                        singleZoneLeg.__hasZoneOwnership = isEditableZone(zoneRecord, myEditableUnitsUids);

                                        console.log('====isEditableZone, ', zoneRecord.name, singleZoneLeg.__hasZoneOwnership);


                                        ret.conflicts.push(singleZoneLeg)

                                    }
                                })
                            }

                            //warnings primitives set only once (merged for display in map)
                            if (!tempWarningsTracker[zone.name]) {
                                tempWarningsTracker[zone.name] = true;
                                ret.warnings.push(zonePrimitive);
                            }
                        }
                    }
                }
            }
        });

        if (calcMaxHeight && legs && legs[0] && legs[0].max) {
            const maxArray = legs.map(leg => leg.max);
            missionRecord.displayMaxHeight = (legs.length > 1)
                ? maxArray.reduce(function (a, b) {
                    return Math.max(a, b);
                })
                :
                maxArray[0];

        }

        //btw fixme?
        // but.. (on the other hand, let's not preoptimize,.. etc)
        // yikes. in rt-map (any map mode?) should use already existing zone data,
        // not full db call..

        //cleanup warnigns vs conflicts during refactoring
        //console.log('=========passed primitives', ret.warnings);
        //console.log('=========passed tabular data', ret.conflicts);

        console.log('===mission deserialize', ret);

        //missionRecord.combinedConflicts = missionFixJoinLeg2LegZone2Primitive(legs, ret.warnings);
    }

    PubSub.publish(PubSubSignals.MISSION_DESERIALIZED);
    return ret;
}

export function deserializeMissionsV2(missionsDBArr, opts) {
    console.log('deserializeMissionsV2, fixme!', missionsDBArr, opts);

    let deserializedMissionsV2 = [];
    let t0 = performance.now();

    for (let i = 0; i < missionsDBArr.length; i++) {

        let deserialized = deserializeMissionData({missionRecord: missionsDBArr[i], ...opts });
        //console.log('deserialized',deserialized);

        if (!deserialized) {
            continue;
        }

        //deserialized._sort = deserialized.primitive.max || 0;
        deserializedMissionsV2.push(deserialized);

    }

    //deserializedZonesArr.sort(function(a, b){return a._sort - b._sort});

    console.log(deserializedMissionsV2);
    let t1 = performance.now();
    console.log("Call to deserializeMissionsV2 all took " + (t1 - t0) + " milliseconds.");

    console.log('arr', deserializedMissionsV2);

    return deserializedMissionsV2;
}

export const ON_BLOCK_ETA_THRESHOLD = -15*60; //-15 min; //dang-> fix in original code, but switch tests to correct negaticty

//LGZ -> check if we need this in that way or maybe approach with moment like _isCheckInOnBlock in BarsController.jsx?
//DN -> internally _tMinus is using moment to prepare fast int comparison, but yeah, not very happy about naming,
export function isMissionOnBlock (missionRecord) {
    return (
        (missionRecord.__tMinus >= ON_BLOCK_ETA_THRESHOLD && missionRecord.__tMinus < 0) ||
        isMissionUnderWay(missionRecord)
    )
}

//DN -> btw, we kind of recalculate a lot of things for bars here, and this data will be also probably needed in deck.gl layers.
//so i think they should be precalculated on tick / on fetch as custom data in rawData (like __tMinus and __inJurisdiction)
export function isMissionUnderWay (missionRecord) {
    let start = 0;
    let stop = 0;
    if(has(missionRecord, 'start')) {
        start = missionRecord.start;
    }

    if(has(missionRecord, 'stop')) {
        stop = missionRecord.stop;
    }

    return moment().isAfter(start) && moment().isBefore(stop)
}

export function isMissionEnded(missionRecord) {
    let stop = 0;

    if(has(missionRecord, 'stop')) {
        stop = missionRecord.stop;
    }

    return moment().isAfter(stop)
}

//call on tick update
//fixing on block status
export function processTimers(rawData, tick) {

    //let cleanedList = [];
    let dirty = false;
    console.log('missionsStore/tick', rawData, tick);

    const unixTime = moment(tick.unixTime*1000);

    for (let i = 0; i < rawData.length; i++) {

        const missionRecord = rawData[i].missionRecord;

        const start = moment(missionRecord.start);

        missionRecord.__tMinus = unixTime.diff(start, 's');

        console.log('missionsStore/__tMinus', missionRecord.__tMinus );


        //nasty optimization - must be  tick on every second in saga!!!
        if (missionRecord.__tMinus === ON_BLOCK_ETA_THRESHOLD){
            //force redraws.
            dirty = true;
        }
    }
    if (dirty) {
        console.log('new mission on block!');
    }

    return {isDirty:dirty, cleanedList:rawData };
}

//hmm there is somewhere else mission draw.. to unify...

export function processMissionsV2ToDeck({rawData = [], bottomCutoff = Number.MIN_SAFE_INTEGER, topCutoff = Number.MAX_SAFE_INTEGER, selectedMissionUid}) {
    let icos = [], points = [], paths = [], polys = [], texts = [];

    //console.log('processing missions', rawData);
    for (let i = 0; i<rawData.length; i++) {
        //console.log('processZonesV2', zones[i]);
        const mission = rawData[i];
        //poly.name = poly.inspectData.name;

        console.log('=====processMissionsV2ToDeck', mission);

        //meaningful only in context of rt_map
        const isStarted = mission.missionRecord.__tMinus >= 0;
        const isOnBlock = isMissionOnBlock(mission.missionRecord);
        const isSelected = mission.missionRecord.uid === selectedMissionUid;

        console.log('isStarted', isStarted);

        for (let j = 0; j < mission.legs.length; j++) {
            const leg = mission.legs[j];

            let legPoly = {};
            legPoly.elevation = 1000; //fixme

            if(isSelected) {
                legPoly.fillColor = functionalColors.selected;
            }
            else {
                legPoly.fillColor = (isOnBlock)?functionalColors.newPrimitive:standardColors.black50;
            }
            legPoly.lineColor = legPoly.fillColor;

            legPoly.inspectData = leg.inspectData;
            legPoly.inspectData = {inspectType:InspectTypes.MISSIONLEGFIXME, legInspect:leg.inspectData, missionInspect:mission};

            //console.log('leg here', leg);

            if (leg.min > topCutoff) continue;
            //
            if (leg.max < bottomCutoff) continue;

            legPoly.polygon = leg.polygon;
            legPoly.centroid = leg.centroid;
            legPoly.draft = true;

            polys.push(legPoly);
        }
    }

    //console.log('zonesPrimitives', zonesPrimitives);

    return {icos, points, paths, polys, texts};
}


export function requestMissionsV2() {

    console.warn('OLD requestMissionsV2 here!');

    const store = StoreProvider.getStore();
    store.dispatch(fetchAllMissionsV2Rq());

}

export default {
    requestMissionsV2,
    deserializeMissionsV2,
    // cleanOutdatedZones,
    // deserializeZoneData,
}
