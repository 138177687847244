import {turfCircle, turfDistance} from '../oldCommon/commonTurf.js'
import {endDraw} from "./deckEditor";

import DeckCircle from '../deckPrimitives/DeckCircle';
import PubSub from "pubsub-js";
import {PubSubSignals} from "../../../LogicV1Redux/constants/PubSubSignals";

//pure functions
//no z-axis at the moment, so keeping clear
export function onCircleDrawClick(deckCircle, latLng, layerClickEvent) {
    // sprint fixme some better dirty setup needed
    PubSub.publish(PubSubSignals.SET_DRAW_EDITOR_DIRTY);

    if (!deckCircle) return;

    const center = deckCircle.helpers[0];
    deckCircle = deckCircle.recalc(center, latLng);
    if (deckCircle.radius !== 0) {//second click
        console.log('closing circle');
        endDraw(deckCircle);
    }

    return deckCircle;
}

export function onCircleDrawMouseMove(deckCircle, latLng) {
    if (deckCircle) {
        const center = deckCircle.helpers[0];
        deckCircle = deckCircle.recalc(center, latLng);
        return deckCircle
    }
}
