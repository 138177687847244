import {Component} from "react";
import { withRouter, Link} from 'react-router-dom';
import React from "react";
import {Close} from "@material-ui/icons"
import {compose} from "recompose"
import PropTypes from 'prop-types'
import {IconButton} from "@material-ui/core";

class CancelButton extends Component {

    _handleClick = () => {
        if(!this.props.href || !this.props.basePath) this.props.history.goBack();
    };

    render() {
        const {basePath = '', staticContext, ...rest} = this.props;
        console.log("CB",this.props);
        return (
            <IconButton
                onClick={this._handleClick}
                component={Link}
                to={basePath}
                {...rest}
                >
                <Close/>
            </IconButton>
        )
    }
}

CancelButton.propTypes = {
    href: PropTypes.string
};

CancelButton.defaultProps = {
    href: null
};

export default compose(
    withRouter,
)(CancelButton);
