
import React from 'react';

import * as dd from 'dis-gui';

import PubSub from "pubsub-js";
import logger from "../../LogicV1Redux/oldLogic/Logger";
import Store from "store/dist/store.modern";
import ZoneEditor from "../../LogicV1Redux/oldLogic/MyZoneEditor";
import ZoneEditorGeometryRenderer from "./ZoneEditorGeometryRenderer";
import {ddStyle} from "./sampleDisGUI";
import DataProvider from "../../dr_ra2/MyDataProvider";
import {PubSubSignals} from '../../LogicV1Redux/constants/PubSubSignals'
import {Rnd} from "react-rnd";
import {verticalResizeOnly} from "./rndConstants";
import {API_VERBS} from "../../dr_ra2/MyApiVerbs";

const defaults = {
    newLegType : 'unknown',
    unitId : '',
    zoneId : '',
    authToken : '',
    primitive : null,
    zoneRecord : null,
};


//simplified version of old zone editor. (ddgui based)
//zone is loaded in view
//FIXME dedupe with redux
export default class ZoneEditorWindow extends React.Component {

    constructor(props) {
        super(props);

        this.state = {};
        this.saveZone = this.saveZone.bind(this);
    }

    //huh, mission editor have to have (at least now) save zone in fullscreen component..
    //let's see what's better (except redux of course)
    saveZone() {

        console.error ('saving zonedata with data provider from dd.gui editor window... to cleanup');
        if (this.state.primitive) {
            //ZoneEditor.requestSave(this.state);
            console.log(this.state);
            //const data = serializeZonePrimitive(this.state);
            console.error('old serialiazation removed');
            const data = null;
            const id = this.state.primitive.inspectData.zoneRecord.id;
            const dataProvider = DataProvider.getDataProvider();
            dataProvider(API_VERBS.UPDATE, 'zones', {id, data})
                .then((req) => {
                    console.log(API_VERBS.UPDATE, 'result', req );
                    //showNotification('myroot.auth.phoneVerified');
                    // push('/myaccount');
                    //this.setState({editables:record2primitives(req.data) });

                })
                .catch((e) => {
                    console.error(e);
                    //showNotification('myroot.httperror', 'warning');
                });

        } else {
            alert('fill correctly data first')
        }
    }

    componentWillMount() {
        console.log('NewZoneEditorWindow, subscribing');
        //feeding from draw.js event directly, move to redux store...
        console.error('double check flow (moved to redux?), heavy rewrites for 1.1');

        // this.pubsubDrawEnd = PubSub.subscribe(PubSubSignals.DRAW_UPDATED, (msg, data) => {
        //     console.log('onDrawEnd zone editor');
        //     console.log('pubsub', msg, data);
        //     this.setState({primitive:data.current});
        // });

        // this.pubsubZoneLoaded = PubSub.subscribe(PubSubSignals.ZONE_LOADED, (msg, data) => {
        //     console.log('onZoneLoaded zone editor');
        //     console.log('pubsub', msg, data);
        //     const {zoneRecord, primitive} = data;
        //     if (primitive)
        //         setEditablePrimitives([primitive]);
        //     else
        //         setEditablePrimitives(null);
        //     this.setState({zoneRecord, primitive});
        // });
        this.pubsubZoneLoaded = PubSub.subscribe(PubSubSignals.NEW_ZONE_LOADED, (msg, data) => {
            console.log('onZoneLoaded zone editor');
            console.log('pubsub', msg, data);
            const {primitive} = data;
            //editable primitive set in  fullscreen editor
            // if (primitive)
            //     setEditablePrimitives([primitive]);
            // else
            //     setEditablePrimitives(null);
            this.setState({primitive});
        });
    }

    componentWillUnmount() {

        //PubSub.unsubscribe(this.pubsubDrawEnd);
        PubSub.unsubscribe(this.pubsubZoneLoaded);
    }


    render() {

        const showGeometryRenderer = (this.state.primitive);

        return (

            <dd.GUI  style = {ddStyle} >
                <dd.Button
                    label='Save Zone'
                    onClick={this.saveZone}
                />
                {showGeometryRenderer ?
                    (
                        <ZoneEditorGeometryRenderer
                            primitive={this.state.primitive}
                        />
                    )
                    :null
                }

            </dd.GUI>
        )
    }

}

//    console.error('fix css!, without sizeMe if possible!')

export const RndZoneEditorWnd = ({viewportData}) => (
    <Rnd
        className = 'rnd-window'
        default={{
            //x: viewportData.width - 450,
            y: 10,
        }}
        maxHeight="95%"
        bounds = "parent"
        enableUserSelectHack = {false}
        enableResizing = {verticalResizeOnly}
        dragHandleClassName = "rnd-dragbar"
    >
        <div className="rnd-dragbar">Zone Editor</div>
        <div style={{overflowY:"auto", maxHeight:(viewportData.height-100)+"px"}}>
            <ZoneEditorWindow style={{overflowY:"auto"}}/>
        </div>

    </Rnd>
);
