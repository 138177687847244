
import React from 'react';
//import PropTypes from 'prop-types';


import sizeMe from "react-sizeme";

import UnitGeomViewer from "./UnitGeomViewer";
import UnitGeomEditor from "./UnitGeomEditor";

import {primitives2Bbox} from "../../../../gl_map/glSetups/oldCommon/_CommonStuff";
import {initializeLayersObject} from "../../../../gl_map/glSetups/newCommon/mapStateConstructors";
import StoreProvider from "../../../MyStoreProvider";
import {clearCustomLayer, storeCustomLayer} from "../../../../LogicV1Redux/actions/CustomLayerActions";
import {clearAllPrimitives, setAllEditablePrimitives} from "../../../../LogicV1Redux/actions/DrawEditorActions";

import {shallowEqual} from 'recompose';
import {fetchAllZonesV2Rq} from "../../../../LogicV1Redux/actions/ZonesV2Actions";
import {deserializeUnitData} from "../../../../LogicV1Redux/oldLogic/UnitsListV2";

//to review before cleaning
import PubSub from "pubsub-js";
import {PubSubSignals} from "../../../../LogicV1Redux/constants/PubSubSignals";
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import {GEOM_CONVERSIONS} from "../../MyGeomConversionMenu";
import TranslatableTypography from "../../TranslatableTypography";

import {connect} from 'react-redux';
import compose from 'recompose/compose';


//was almost the same as ZoneField,
//after refactoring dispatch should not use store call, but mapDispatchToProps. probably.

class MyUnitGeomField extends React.Component {

    state = {
        editSafetyToggle: false
    };

    componentDidMount() {

        console.log("==MUGF did mount");

        //check if still needed
        if (this.props.zonesV2.rawData.length === 0) {
            StoreProvider.getStore().dispatch(fetchAllZonesV2Rq());
        }

        this._do();

    }

    componentWillUnmount() {
        console.log("==MUGF unmount");
        StoreProvider.getStore().dispatch(clearAllPrimitives());
        StoreProvider.getStore().dispatch(clearCustomLayer());

    }

    handleToggle = name => event => {
        this.setState({ [name]: event.target.checked });
    };


    componentDidUpdate(prevProps) {
        console.log("==MUGF did update", prevProps, this.props);
        if (!shallowEqual(this.props, prevProps)) {
            this._do();
        } else {
            console.log('==MUGF shallow equal false', this.props);
        }
    }


    _do = () => {
        const { record = {}, size, showEditLink = false, showConflicts = false, zoneTypesV2, mySettingsVisible } = this.props;

        console.log("==MUGF did do", this.props);

        //hacking so-called inifinite rerender on opened settings view
        // probably caused by dispatch call below AND multiple store changes created by new form!)
        //i guess can be normally binded as prop from state, but later...
        //18-03-20, binded, and undefined... since long time i guess


        if (mySettingsVisible) {
            console.warn('DO NOT update unit on settings open!');
            return;
        }


        const unitData = deserializeUnitData(record, zoneTypesV2);
        console.log('==================MyUNITV2', unitData);
        console.log('==MUGF', { record, size, showEditLink , showConflicts, unitData });

        if (showConflicts) {
            console.error('no conflicts support in unit viewer / editor as for now');
        }

        const primitives = (unitData && unitData.primitive)?[unitData.primitive]:[];
        const bBox = primitives2Bbox(primitives);

        console.log(primitives);

        //yikes. temp!
        const newLayer = initializeLayersObject();
        newLayer.polys = primitives;
        //newLayer.texts = warningsTexts;
        //console.log(newLayer)
        if (this.props.enableEdit) {
            StoreProvider.getStore().dispatch(setAllEditablePrimitives(primitives));
        }
        else {
            StoreProvider.getStore().dispatch(storeCustomLayer({deckData: newLayer}));
        }
        this.setState({bBox, primitives});

    };


    render() {
        if (!this.state.primitives) {
            return null;
        }

        //sanitize
        const {
            record = {},
            enableEdit = false, //from permissions and roles
            //permissions,
            //dispatch, //hmm reattaching later in context (btw - is it passed now?)
            //...rest
        } = this.props;

        const {primitives, helpers, conflicts, bBox, editSafetyToggle} = this.state;

        //temp using zone edit/view
        //const EditorOrViewer = (enableEdit && editSafetyToggle ) ? UnitGeomEditor : UnitGeomViewer;
        const EditorOrViewer = (enableEdit ) ? UnitGeomEditor : UnitGeomViewer;
        //const EditorOrViewer = UnitViewer; //to reenable enableEdit
        return (
            //<>
                /* ALMOST ok, but somehow seems slow.. let's wait if needed
                //https://gidrone.azure.pansa.pl/playground/ra2-playground/issues/227
                {enableEdit
                    ? <FormControlLabel
                        control={
                            <Switch
                                checked={editSafetyToggle}
                                onChange={this.handleToggle('editSafetyToggle')}
                                value="editSafetyToggle"
                                color="primary"
                            />
                        }
                        label={<TranslatableTypography content="enableGeometryEdit"/>}
                    />
                    : null
                }
                */
                <EditorOrViewer record={record}
                    //helpers={helpers}
                    //conflicts={conflicts}
                    //legsPrimitives={legsPrimitives}
                                bBox = {bBox}
                                primitives = {primitives}
                />

            //</>
        )
    }
}

const mapStateToProps = state => {
    return {
        mySettingsVisible: state.mySettings.visible,  //UNDEFINED!!!
        zonesV2: state.zonesV2,
        zoneTypesV2: state.zoneTypesV2
    }
};

export default compose(
    connect(
        mapStateToProps,
        null
    ),
)(MyUnitGeomField)
