import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import pure from 'recompose/pure';
import Typography from "@material-ui/core/Typography";
import {TooltipProxy as Tooltip} from './TooltipProxy';
import compose from 'recompose/compose';
import {makeElementTabbable} from "../utils/reactHelpers";
import Labeled from "ra-ui-materialui/esm/input/Labeled";



const _renderMiniStatusField = ({source, record, tooltip, statusMappingFn, iconGetter, mini}) => {
    const status = get(record, source);
    const icon = iconGetter(status);
    const tabbableIcon = makeElementTabbable(icon);

    if(mini) return tabbableIcon;
    else
    return <Tooltip title={<Typography>{tooltip.text ? statusMappingFn(tooltip.text) : statusMappingFn(get(record, source))}</Typography>}
                    leaveDelay={200}
                    enterTouchDelay={50}
                    disableFocusListener={false}
            >
            {tabbableIcon}
    </Tooltip>
};

const StatusField = ({ source, record = {}, tooltip = false, statusMappingFn, iconGetter, label, mini, addLabel = true, resource}) => {
    const _label = label ? label : `resources.${resource}.fields.status`;

    if(tooltip || mini) {
            return _renderMiniStatusField({source, record, tooltip, statusMappingFn, iconGetter, mini});
    }
    else {
        return (
            <>
                {
                   addLabel ?
                        <Labeled label={_label}>
                            {statusMappingFn(get(record, source))}
                        </Labeled>
                        :
                        statusMappingFn(get(record, source))
                }
            </>
        );
    }
};

StatusField.propTypes = {
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
    tooltip: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    statusMappingFn: PropTypes.func,
    iconGetter: PropTypes.func.isRequired,
    addLabel: PropTypes.bool
};

const PureStatusField = compose(
    pure,
)(StatusField);

PureStatusField.defaultProps = {
    statusMappingFn: (text) => <>{text}</>,
};

export default PureStatusField;
