import React from 'react'
import Card from "@material-ui/core/Card/Card";
import CardContent from "@material-ui/core/CardContent/CardContent";
import Typography from "@material-ui/core/Typography/Typography";
import CardActions from "@material-ui/core/CardActions/CardActions";
import connect from "react-redux/es/connect/connect";
import {withStyles} from "@material-ui/core";
import {translate} from 'ra-core'
import compose from 'recompose/compose';
import CardHeader from "@material-ui/core/CardHeader/CardHeader";
import Avatar from "@material-ui/core/Avatar/Avatar";
import IconButton from "@material-ui/core/IconButton/IconButton";
import SettingsIcon from '@material-ui/icons/Settings';
import CloseIcon from '@material-ui/icons/Close';
import {
    toggleSettingsView,
    toggleProfileView,
    toggleNotificationView,
    toggleViewView,
    toggleAboutView,
    toggleCommercialServicesView
} from "../../logic/settings/MySettingsActions";
import {Link} from "react-router-dom";
import {Popover} from "@material-ui/core";
import {isOperator, isSuperAdmin} from "../../logic/MyRestConfig";
import {bindActionCreators} from "redux";
import {getUserShowName} from "./MyAccountView";



const styles = theme => ({
    settingsCard: {
        margin: "0"
    },
    cardHeader: {
        backgroundColor: theme.palette.primary.main,
        borderRadius: "4px"
    },
    cardContent: {
        overflowY: "auto"
    },
    cardParagraph: {
        marginBottom: "1em"
    },
    link: {
        color: theme.palette.colorCircle.menuLink
    }
});

class MySettingsView extends React.Component {


    _handleClose = () => {
        this.props.toggleSettingsView();
    };

    _handleProfileView = (e) => {
        e.preventDefault();
        this._handleClose();
        this.props.toggleProfileView({show: true});
    };

    _handleNotificationView = (e) => {
        e.preventDefault();
        this._handleClose();
        this.props.toggleNotificationView({show: true});
    };

    _handleViewView = (e) => {
        e.preventDefault();
        this._handleClose();
        this.props.toggleViewView({show: true});
    };

    _handleAboutView = (e) => {
        e.preventDefault();
        this._handleClose();
        this.props.toggleAboutView({show: true});
    };

    _handleCommercialServicesView = (e) => {
        e.preventDefault();
        this._handleClose();
        this.props.toggleCommercialServicesView({show: true});
    };

    render() {
        const {classes, settingsView, translate, mySelf, locale} = this.props;
        const superadminOptionalText = isSuperAdmin(mySelf.roles) ? 'SUPERADMIN mode' : null;

        return (
            <Popover
                anchorOrigin={{
                    vertical: "center",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "center",
                    horizontal: "right",
                }}
                open={settingsView.visible}
                onClose={this._handleClose}
                anchorEl={settingsView.eventTarget}
            >
                <Card className={classes.settingsCard}>
                    <CardHeader
                        avatar={
                            <Avatar aria-label="Settings" className={classes.avatar}>
                                <SettingsIcon/>
                            </Avatar>
                        }
                        action={
                            <IconButton onClick={this._handleClose}>
                                <CloseIcon/>
                            </IconButton>
                        }
                        title={translate('myroot.action.settings')}
                        titleTypographyProps={{variant: "body2"}}
                        subheaderTypographyProps={{color: "textPrimary", variant: "button"}}
                        subheader={getUserShowName({superadmin: superadminOptionalText, mySelf})}
                        className={classes.cardHeader}
                    />
                    <CardContent className={classes.cardContent}>
                        <div className={classes.cardParagraph}>
                            <Typography gutterBottom variant="headline" component="h2">
                                {translate('ra.auth.user_menu')}
                            </Typography>
                            <Typography>
                            <a href="#" className={classes.link}
                                onClick={this._handleProfileView}
                            >
                                {translate('myroot.auth.accountSettings')}
                            </a>
                            </Typography>
                            {isOperator(mySelf.roles) &&
                                <Typography>
                                    <a href="#"
                                       onClick={this._handleCommercialServicesView}
                                       className={classes.link}
                                    >
                                        {translate('myroot.configuration.commercialServices')}
                                    </a>
                                </Typography>
                            }
                        </div>

                        <div className={classes.cardParagraph}>
                            <Typography gutterBottom variant="headline" component="h2">
                                {translate('myroot.configuration.app')}
                            </Typography>
                            <Typography>
                                <a href="#"
                                   onClick={this._handleNotificationView}
                                   className={classes.link}
                                >
                                    {translate('myroot.configuration.notifications')}
                                </a>
                            </Typography>
                            <Typography>
                                <a href="#"
                                   onClick={this._handleViewView}
                                   className={classes.link}
                                >
                                    {translate('myroot.configuration.view')}
                                </a>
                            </Typography>
                            <Typography>
                                <a href="#"
                                   onClick={this._handleAboutView}
                                   className={classes.link}
                                >
                                    {translate('myroot.configuration.about')}
                                </a>
                            </Typography>
                        </div>

                        {isOperator(mySelf.roles) &&
                        <div className={classes.cardParagraph}>
                            <Typography gutterBottom variant="headline" component="h2">
                                {translate('myroot.auth.termsOfUse')}
                            </Typography>
                            <Typography>
                                <Link
                                    to={`/docs/rodo`}
                                    onClick={this._handleClose}
                                    className={classes.link}
                                >
                                    {translate('myroot.auth.privacyPolicyInfo')}
                                </Link>
                            </Typography>
                            <Typography>
                                <Link
                                    to={`/docs/disclaimer`}
                                    onClick={this._handleClose}
                                    className={classes.link}
                                >
                                    {translate('myroot.auth.disclaimer')}
                                </Link>
                            </Typography>
                        </div>
                        }
                    </CardContent>
                    <CardActions>
                    </CardActions>
                </Card>
            </Popover>
        )
    }
}


const mapStateToProps = state => {
    return {
        settingsView: state.mySettings.settingsView,
        mySelf : state.mySelf,
        myAppExtras : state.myAppExtras,
        locale: state.i18n.locale,
    }
};

const mapDispatchToProps = (dispatch) => ({
    toggleSettingsView: bindActionCreators(toggleSettingsView, dispatch),
    toggleProfileView: bindActionCreators(toggleProfileView, dispatch),
    toggleNotificationView: bindActionCreators(toggleNotificationView, dispatch),
    toggleViewView: bindActionCreators(toggleViewView, dispatch),
    toggleAboutView: bindActionCreators(toggleAboutView, dispatch),
    toggleCommercialServicesView: bindActionCreators(toggleCommercialServicesView, dispatch),
});


export default compose(
    translate,
    connect(
        mapStateToProps,
        mapDispatchToProps
    ),
    withStyles(styles)
)(MySettingsView);
