
import React from 'react';
export const disclaimerPage = (
    <div id="disclaimer-content">
        <h2>ZASTRZEŻENIA PRAWNE</h2>
        <br/>
        <br/>
        &copy;&nbsp;Wszelkie prawa zastrzeżone.
        <br/><br/>
        Zawarte w systemie PansaUTM informacje, materiały, elementy graficzne stanowią własność Hawk-e sp. z o.o. i/lub Polskiej Agencji Żeglugi Powietrznej i/lub innych wskazanych właścicieli i nie mogą być używane w celach handlowych.
        Wszelka modyfikacja, reprodukcja lub przetwarzanie niniejszej domeny jest zabroniona.
        Dane zawarte na stronach tej domeny można zweryfikować kontaktując się z:
        <ul>
            <li>
                z AMC Polska (ASM-3) w sprawie aktywnych, elastycznych elementów przestrzeni powietrznej (FUA) pod numerami: +48 22 574 57 33, +48 22 574 57 34 lub +48 22 574 57 35;
            </li>
            <li>
                z Działem Zarządzania Operacjami Bezzałogowych Statków Powietrznych PAŻP w sprawie elementów przestrzeni powietrznej charakterystycznych dla koncepcji U-space oraz w sprawie zasad wykonywania lotów BSP w polskiej przestrzeni powietrznej pod numerami: +48 22 574 5741, +48 22 574 5715, +48 22 574 7553.
            </li>
        </ul>
        Polska Agencja Żeglugi Powietrznej oświadcza, że w przypadku przesłania jakiejkolwiek korespondencji do niniejszej domeny za pośrednictwem Internetu lub poczty elektronicznej, nie zapewnia zachowania prywatności czy poufności otrzymanych informacji, materiałów oraz nie zapewnia, że nie zostaną naruszone inne prawa z nimi związane.
        <br/><br/>
        Polska Agencja Żeglugi Powietrznej zastrzega sobie prawo odtwarzania przetwarzania, publikacji lub jakiegokolwiek innego działania w wykorzystaniu otrzymanej powyższą drogą korespondencji, w jakimkolwiek celu.
        <br/><br/>
        Za dostępność i zawartość ścieżek podanych/wskazanych w niniejszej domenie, a administrowanych przez osoby trzecie, Polska Agencja Żeglugi Powietrznej nie ponosi żadnej odpowiedzialności.
        <br/><br/>
        Polska Agencja Żeglugi Powietrznej zastrzega sobie prawo do zmiany powyższych zastrzeżeń oraz wyłączeń od wszelkiej odpowiedzialności w każdym dowolnie wybranym czasie.
        <br/>
    </div>
);

export const richText = {
    title: `<h2>ZASTRZEŻENIA PRAWNE</h2>`,
    content:
        `
        &copy;Wszelkie prawa zastrzeżone. Zawarte w systemie PansaUTM informacje, materiały, elementy graficzne stanowią własność Hawk-e sp. z o.o. i/lub Polskiej Agencji Żeglugi Powietrznej i/lub innych wskazanych właścicieli i nie mogą być używane w celach handlowych.
        Wszelka modyfikacja, reprodukcja lub przetwarzanie niniejszej domeny jest zabroniona. 
        Dane zawarte na stronach tej domeny można zweryfikować kontaktując się z:
        <ul>
            <li>
                z AMC Polska (ASM-3) w sprawie aktywnych, elastycznych elementów przestrzeni powietrznej (FUA) pod numerami: +48 22 574 57 33, +48 22 574 57 34 lub +48 22 574 57 35;
            </li>
            <li>
                z Działem Zarządzania Operacjami Bezzałogowych Statków Powietrznych PAŻP w sprawie elementów przestrzeni powietrznej charakterystycznych dla koncepcji U-space oraz w sprawie zasad wykonywania lotów BSP w polskiej przestrzeni powietrznej pod numerami: +48 22 574 5741, +48 22 574 5715, +48 22 574 7553.
            </li>
        </ul>
        Polska Agencja Żeglugi Powietrznej oświadcza, że w przypadku przesłania jakiejkolwiek korespondencji do niniejszej domeny za pośrednictwem Internetu lub poczty elektronicznej, nie zapewnia zachowania prywatności czy poufności otrzymanych informacji, materiałów oraz nie zapewnia, że nie zostaną naruszone inne prawa z nimi związane.
        <br/><br/>
        Polska Agencja Żeglugi Powietrznej zastrzega sobie prawo odtwarzania przetwarzania, publikacji lub jakiegokolwiek innego działania w wykorzystaniu otrzymanej powyższą drogą korespondencji, w jakimkolwiek celu.
        <br/><br/>
        Za dostępność i zawartość ścieżek podanych/wskazanych w niniejszej domenie, a administrowanych przez osoby trzecie, Polska Agencja Żeglugi Powietrznej nie ponosi żadnej odpowiedzialności.
        <br/><br/>
        Polska Agencja Żeglugi Powietrznej zastrzega sobie prawo do zmiany powyższych zastrzeżeń oraz wyłączeń od wszelkiej odpowiedzialności w każdym dowolnie wybranym czasie.
        <br/><br/>
        `
};
