import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import withWidth, {isWidthDown} from '@material-ui/core/withWidth';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import {FieldTitle} from 'react-admin';
import MyStep from "./MyStep";
import CancelButton from "../../mui_test_suite/customComponents/CancelButton";
import TranslatableTypography from "./TranslatableTypography";
import Card from "@material-ui/core/Card";
import StepConnector from "@material-ui/core/StepConnector"
import * as ReactDOM from "react-dom";
import StepButton from "@material-ui/core/StepButton";
import compose from 'recompose/compose';
import classnames from 'classnames';
import {isOverflowingWidth} from "../utils/cssHelpers";
import {Divider} from "@material-ui/core";


const STEPPER_BREAKPOINT = 'lg';

const styles = theme => ({
    root: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start"
    },
    upperBar: {
        width: "100%"
    },
    upperCard: {
        marginLeft: "-10%",
        marginRight: "-10%",
        marginTop: "-30px",
        minWidth: "600px",
        padding: "1rem",
        // hack for good shrinking
        width: "unset",
        [theme.breakpoints.down('sm')]: {
            marginLeft: "0",
            marginRight: "0",
            minWidth: "unset"
        }
    },
    header: {
        display: "flex",
        justifyContent: "space-between",
        width: "100%"
    },
    stepperBar: {
        alignSelf: "stretch",
        backgroundColor: "inherit",
        scrollBehavior: "smooth",
        overflow: "hidden",

        [theme.breakpoints.up('md')]: {
            paddingBottom: 3*theme.spacing.unit,
        },

        [theme.breakpoints.up('xl')]: {
            paddingBottom: "20px",
        },
    },
    button: {
        marginRight: theme.spacing.unit,
    },
    input: {
        flexDirection: "column",
        display: "flex",

        [theme.breakpoints.up('md')]: {
            marginTop: 2*theme.spacing.unit,
            marginBottom: 3*theme.spacing.unit,
        },

        [theme.breakpoints.up('xl')]: {
            marginTop: 4*theme.spacing.unit,
            marginBottom: 10*theme.spacing.unit,
        },
    },
    inputArea: {
        alignSelf: "stretch"
    },
    horizontal: {
        [theme.breakpoints.up('md')]: {
            flexShrink: "0",
        },

        [theme.breakpoints.up('xl')]: {
            flexShrink: "inherit",
        },
    },
    connectorLine: {
        [theme.breakpoints.up('md')]: {
            paddingRight: "0.9rem",
        },
        [theme.breakpoints.up('lg')]: {
            paddingRight: "2rem",
        },

        [theme.breakpoints.up('xl')]: {
            paddingRight: "inherit",
        },
    },
    connectorRoot: {
        [theme.breakpoints.down('xl')]: {
            flex: "0",
        },
        [theme.breakpoints.up('xl')]: {
            flex: "1",
        },
    },
    connectorOnlySecond: {
        "& + $hidden + &": {
            display: "none"
        },
    },
    active: {
        textDecoration: "underline"
    },
    icon: {
        fontWeight: "500"
    },
    stepLabelRoot: {
        textAlign: "left"
    },
    hidden: {
        display: "none"
    },
    navButtons: {
        [theme.breakpoints.down('sm')]: {
            marginTop: "2rem"
        },
        width: "100%"
    },
    embedded: {
        borderStyle: "solid",
        padding: "1rem",
        borderWidth: "2px",
        borderRadius: "4px",
    },
    embeddedDivider: {
        margin: "1rem 0",
    },
    embeddedInput: {
        flexDirection: "column",
        display: "flex",
    }
});

class MyHorizontalStepper extends React.Component {

    state = {
        activeStepNumber: 0,
        stepCount: this.props.children.length,
        skipped: new Set(),
    };

    constructor(props) {
        super(props);
        this.stepperRef = React.createRef();
        this.removedSteps = [];
    }

    _isStepSkipped = (stepIndex) => {
        return this.state.skipped.has(stepIndex);
    };

    _isLastEditableStep = () => {
        const {activeStepNumber, stepCount} = this.state;
        return activeStepNumber === stepCount-1;
    };

    _shouldShowOptional = (optional) => {
        const {lastStepIsEditableOne} = this.props;
        if (optional && !this._isLastEditableStep()) return true;
        else return !!(optional && this._isLastEditableStep() && !lastStepIsEditableOne);
    };

    _isStepperOverflowing = () => {
        if(!ReactDOM.findDOMNode(this.stepperRef.current)) return false;
        return isOverflowingWidth(ReactDOM.findDOMNode(this.stepperRef.current));
    };

    _handleNext = () => {
        const { activeStepNumber } = this.state;
        let { skipped } = this.state;

        if (this._isStepSkipped(activeStepNumber)) {
            skipped = new Set(skipped.values());
            skipped.delete(activeStepNumber);
        }

        this.setState({
            activeStepNumber: activeStepNumber + 1,
            skipped,
        });

        this.props.onStepChanged(activeStepNumber + 1);

        //Less or equal?!
        if(isWidthDown(STEPPER_BREAKPOINT, this.props.width)) this._removeStepFromBar(activeStepNumber);
    };

    _handleStep = stepNumber => () => {
        this.setState({
            activeStepNumber: stepNumber,
        });
        this.props.onStepChanged(stepNumber);
    };

    _handleBack = () => {
        const { activeStepNumber } = this.state;

        this.setState(state => ({
            activeStepNumber: state.activeStepNumber - 1,
        }));

        this.props.onStepChanged(activeStepNumber - 1);

        if(isWidthDown(STEPPER_BREAKPOINT, this.props.width)) this._insertPreviousStepIntoBar(activeStepNumber);
    };

    _handleSkip = (activeStep) => {

        const { activeStepNumber } = this.state;
        if (!activeStep.props.optional) {
            throw new Error("You can't skip a step that isn't optional.");
        }

        this.setState(state => {
            const skipped = new Set(state.skipped.values());
            skipped.add(activeStepNumber);
            return {
                activeStepNumber: activeStepNumber + 1,
                skipped,
            };
        });

        this.props.onStepChanged(activeStepNumber + 1);
        activeStep.props.onStepSkip(activeStepNumber);

        if(isWidthDown(STEPPER_BREAKPOINT, this.props.width)) this._removeStepFromBar(activeStepNumber);

    };

    _handleReset = () => {

        this.setState({
            activeStepNumber: 0,
        });

        this.props.onStepChanged(0);
        this.props.onReset();

        if(isWidthDown(STEPPER_BREAKPOINT, this.props.width)) this._insertAllPreviousStepsIntoBar();
    };

    _isWidthChanging = (previousWidth, nextWidth) => {
        return previousWidth !== nextWidth;
    };

    _insertPreviousStepIntoBar = (activeStepNumber) => {
        const i = this.removedSteps.indexOf(activeStepNumber-1);
        this.removedSteps.splice(i, 1);
    };

    _insertAllPreviousStepsIntoBar = () => {
        this.removedSteps.length = 0;
    };

    _removeStepFromBar = (activeStepNumer) => {
        this.removedSteps.push(activeStepNumer);
    };

    _removeAllPreviousStepsFromBar = (activeStepNumber) => {
        for(let i = activeStepNumber; i > 0; i--) {
            this._removeStepFromBar(i-1);
        }
    };

    _isHidden = (stepNumber) => {
        return this.removedSteps.includes(stepNumber)
    };

    _isAnyHidden = () => {
        return this.removedSteps.length > 0;
    };


    componentWillUpdate(nextProps, nextState, nextContext) {

        if(this.state.stepCount !== React.Children.toArray(nextProps.children).length)

        this.setState({
            stepCount: React.Children.toArray(nextProps.children).length
        });

        const isWidthChanging = this._isWidthChanging(this.props.width, nextProps.width);
        if(!isWidthChanging) return;
        else if(isWidthDown(STEPPER_BREAKPOINT, nextProps.width)) {
            this._removeAllPreviousStepsFromBar(this.state.activeStepNumber);
        }
        else {
            this._insertAllPreviousStepsIntoBar();
        }


    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if(prevState.activeStepNumber !== prevState.stepCount-1 && this._isLastEditableStep())
            this.props.onLastEditableStepEntered();
    }

    render() {
        const {classes, children, labelWhenOptional, subheader, basePath, title, onExit, isEmbedded}= this.props;
        const steps = React.Children.toArray(children);
        const {activeStepNumber} = this.state;
        const activeStep = steps[activeStepNumber];

        const connector = (
            <StepConnector
                classes={{
                    line: classes.connectorLine,
                    root: classes.connectorRoot
                }}
            />
        );
        const oneConnector = (
            <StepConnector
                classes={{
                    line: classes.connectorLine,
                    root: classnames(classes.connectorRoot, classes.connectorOnlySecond)
                }}
            />
        );

        const stepper = (
            <Stepper activeStep={activeStepNumber}
                     className={classes.stepperBar}
                     connector={this._isAnyHidden() ? oneConnector : connector}
                     ref={this.stepperRef}>
                {steps.map((myStep, index) => {

                    const labelProps = {};
                    const {isValid, optional, label, onStepSkip, ...rest} = myStep.props;

                    if (optional) {
                        labelProps.optional =
                            <Typography variant="caption">
                                <FieldTitle label={labelWhenOptional}/>
                            </Typography>;
                    }
                    if (this._isStepSkipped(index)) {
                        labelProps.completed = false;
                    }
                    return (
                        <Step key={label} {...rest}
                              className={
                                  classnames(classes.horizontal, {
                                      [classes.hidden]: this._isHidden(index)
                                  })
                              }
                        >
                            <StepButton onClick={this._handleStep(index)} {...labelProps}>
                                <StepLabel {...labelProps} classes={{
                                    active: classes.active,
                                    iconContainer: classes.icon,
                                    root: classes.stepLabelRoot
                                }}>
                                    <FieldTitle label={label}/>
                                </StepLabel>
                            </StepButton>
                        </Step>
                    );
                })}
            </Stepper>
        );

        return (
            <div className={classnames(classes.root, {[classes.embedded]: isEmbedded})}>
                <div className={classes.upperBar}>
                    {
                        isEmbedded ?
                            <>
                                {stepper}
                                <Divider className={classes.embeddedDivider}/>
                            </>
                            :
                            <Card className={classes.upperCard}>
                                <div className={classes.header}>
                                    <TranslatableTypography variant="display1" content={title} upperCase/>
                                    <CancelButton basePath={basePath} onClick={onExit}/>
                                </div>
                                {stepper}
                            </Card>
                    }

                </div>
                {/*{this._isStepperOverFlowing() && <h1>OVERFLOW</h1>}*/}
                {activeStepNumber === steps.length ?
                //     (
                //     <div>
                //         <Typography className={classes.instructions}>
                //             !All steps completed - you&quot;re finished
                //         </Typography>
                //         <Button onClick={this._handleReset} className={classes.button}>
                //             <FieldTitle label={'myroot.action.reset'}/>
                //         </Button>
                //     </div>
                    // )
                    null : (
                        <>
                            <div className={classes.inputArea}>
                                <div>{subheader}</div>
                                <div className={
                                    classnames({
                                        [classes.input]: !isEmbedded,
                                        [classes.embeddedInput]: isEmbedded
                                    })}>
                                    {activeStep}
                                </div>
                            </div>
                            <div className={classes.navButtons}>
                                {isEmbedded && <Divider className={classes.embeddedDivider}/>}
                                <Button
                                    disabled={activeStepNumber === 0}
                                    onClick={this._handleBack}
                                    className={classes.button}
                                >
                                    <FieldTitle label={'myroot.navigation.back'}/>
                                </Button>
                                {this._shouldShowOptional(activeStep.props.optional) ?
                                    (<Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => this._handleSkip(activeStep)}
                                        className={classes.button}
                                        disabled={!!activeStep.props.isValid}
                                    >
                                        <FieldTitle label={'myroot.navigation.skip'}/>
                                    </Button>) : null
                                }
                                {!this._isLastEditableStep() &&
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={this._handleNext}
                                    className={classes.button}
                                    disabled={!activeStep.props.isValid}
                                >
                                    <FieldTitle
                                        label={'ra.navigation.next'}
                                    />

                                </Button>}
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={this._handleReset}
                                    className={classes.button}
                                >
                                    <FieldTitle label={'myroot.action.clearForm'}/>
                                </Button>
                            </div>
                        </>
                    )}
            </div>
        )
    }

}

MyHorizontalStepper.propTypes = {
    children: function (props, propName, componentName) {
        const prop = props[propName];
        let error = null;
        React.Children.forEach(prop, function (child) {
            if (child === null) return null;
            if (child.type !== MyStep) {
                error = new Error('`' + componentName + '` children should be of type `MyStep`.');
            }
        });
        return error
    },
    labelWhenOptional: PropTypes.string,
    onLastEditableStepEntered: PropTypes.func,
    onReset: PropTypes.func,
    onExit: PropTypes.func,
    onStepChanged: PropTypes.func,
};

MyHorizontalStepper.defaultProps = {
    labelWhenOptional: 'Optional',
    onLastEditableStepEntered: () => {},
    onStepChanged: () => {},
    onReset: () => {},
    onExit: () => {},
};

const enhance = compose(
    withStyles(styles),
    withWidth()
);

export default enhance(MyHorizontalStepper);