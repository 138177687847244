import React from 'react'
import Card from "@material-ui/core/Card/Card";
import CardContent from "@material-ui/core/CardContent/CardContent";
import Typography from "@material-ui/core/Typography/Typography";
import CardActions from "@material-ui/core/CardActions/CardActions";
import connect from "react-redux/es/connect/connect";
import {withStyles} from "@material-ui/core";
import withWidth, {isWidthDown} from "@material-ui/core/withWidth";
import {translate} from 'ra-core'
import compose from 'recompose/compose';
import CardHeader from "@material-ui/core/CardHeader/CardHeader";
import Avatar from "@material-ui/core/Avatar/Avatar";
import IconButton from "@material-ui/core/IconButton/IconButton";
import AccountIcon from '@material-ui/icons/AccountCircle';
import CloseIcon from '@material-ui/icons/Close';
import StoreProvider from "../../MyStoreProvider";
import {toggleProfileView} from "../../logic/settings/MySettingsActions";
import {SelfGovsEditForm, SelfOperatorsEditForm} from "./MyAccountForms";
import {isSuperAdmin} from "../../utils/tempCommon";
import Dialog from "@material-ui/core/Dialog/Dialog";
import {getAllRolesText, getAllUnits, getMainRoleText} from "../../utils/accountHelpers";
import Divider from "@material-ui/core/Divider/Divider";
import MyNicknameInputDialog from "./MyNicknameInputDialog";
import {change, destroy, getFormNames} from "redux-form";
import {bindActionCreators} from "redux";
import {ACCOUNT_FORM_NAMES} from "../../logic/settings/AccountConstants";
import PrivilegesField from "../resources/OperatorCustomComponents/PrivilegesField";
import Configs, {FEATURES_V1_2_ENUMS} from "../../../Configs";

const ACCOUNT_VIEW_SMOOTHER_UX_ENABLED = Configs.enabledFeatures.includes(FEATURES_V1_2_ENUMS.ACCOUNT_VIEW_SMOOTHER_UX);

export const getUserShowName = ({superadmin, mySelf}) => {
    let name = '';

    if (!!superadmin) name = superadmin;
    else if (!!mySelf.firstName && !!mySelf.lastName) name = `${mySelf.firstName} ${mySelf.lastName}`;
    else if (!!mySelf.firstName) name = mySelf.firstName;
    else if (!!mySelf.username) name = mySelf.username;
    else name = mySelf.email;

    if (!!mySelf.nickName) name += ` (${mySelf.nickName})`;

    return name;

};

const styles = theme => ({
    settingsCard: {
        margin: "0",
        //minHeight: "805px",
        borderRadius: "6px",
        '@media screen and (max-height: 919px)': {
            minHeight: "unset"
        },
        display: "flex",
        flexDirection: "column",
    },
    cardHeader: {
        backgroundColor: theme.palette.primary.main,
        borderRadius: "4px",
        //height: "4rem"
    },
    cardContent: {
        overflowY: "hidden",
        display: "flex",
        flexDirection: "row",
        [theme.breakpoints.down('sm')]: {
            flexDirection: "column",
            height: "100%",
            justifyContent: "space-evenly",
            alignSelf: "center",
        },
        ...(ACCOUNT_VIEW_SMOOTHER_UX_ENABLED && {
            '@media screen and (max-height: 700px)': {
                overflowY: "auto",
                height: "100%"
            }
        }),
        ...(ACCOUNT_VIEW_SMOOTHER_UX_ENABLED && {
            '@media screen and (max-height: 700px) and (max-width: 959px)': {
                overflowY: "initial"
            }
        }),
    },
    cardParagraph: {
        marginBottom: "1em",
        width: "100%",
        ...(ACCOUNT_VIEW_SMOOTHER_UX_ENABLED ?
            {
                overflow: "auto"
            }
            :
            {
                '@media screen and (max-height: 919px)': {
                    overflow: "auto",
                },
            }
        ),
    },
    avatarSide: {
        display: "flex",
        flexDirection: "column",
        padding: "7em 0",
        alignItems: "center",
        [theme.breakpoints.down('sm')]: {
            padding: "0"
        },
        ...(ACCOUNT_VIEW_SMOOTHER_UX_ENABLED && {
            '@media screen and (max-height: 700px) and (min-width: 959px)': {
                padding: "0",
                width: "36rem",
                margin: "5rem 0",
                justifyContent: "center"
            },
        }),
    },
    bigAvatar: {
        fontSize: "10rem",
        width: "12rem",
        height: "12rem",
        margin: "0 5rem",
        ...(ACCOUNT_VIEW_SMOOTHER_UX_ENABLED && {
            '@media screen and (max-height: 700px)': {
                width: "0",
                height: "0",
                transform: "scale(0)",
                transition: "transform 1s, width 1s 0.5s , height 1s 0.5s ",
            }
        }),
    },
    bigIcon: {
        fontSize: "inherit"
    },
    bigUserName: {
        marginTop: "1rem"
    },
    paper: {
        '@media screen and (min-height: 920px)': {
            overflowY: ACCOUNT_VIEW_SMOOTHER_UX_ENABLED ? undefined : "inherit",
            maxHeight: ACCOUNT_VIEW_SMOOTHER_UX_ENABLED ? "100%" : "unset",
        },
        ...(ACCOUNT_VIEW_SMOOTHER_UX_ENABLED && {
            '@media screen and (max-height: 700px)': {
                height: "100%"
            }
        }),
        [theme.breakpoints.down('sm')]: {
            overflowX: "hidden",
            margin: "0"
        },
        borderRadius: "6px",
    },
    divider: {
        margin: "1rem 0"
    }
});

class MyAccountView extends React.Component {

    state = {
        expanded: false,
    };

    constructor(props) {
        super(props);
    }

    _handleClose = () => {
        const store = StoreProvider.getStore();
        store.dispatch(toggleProfileView({show: false}));
        this._destroyForms();
    };

    _onExpanded = (expanded) => {
        this.setState({
            expanded
        })
    };

    _destroyForms = () => {
        const activeForms = this.props.formNames;
        const formsToDestroy = activeForms.filter(
            form => {
                for(const accountFormName of ACCOUNT_FORM_NAMES) {
                    // startsWith used because of multiple units forms names
                    if(form.startsWith(accountFormName)) return true;
                }
                return false;
            }
        );
        this.props.destroyForms(...formsToDestroy)
    };

    render() {

        const {classes, profileView, translate, mySelf, myAppExtras, width, locale} = this.props;
        let superadminOptionalText;

        const accountForm = (myAppExtras.appMode === 'OPERATOR')

            ? <SelfOperatorsEditForm onExpansionChange={this._onExpanded}/>
            : (isSuperAdmin())
                ? superadminOptionalText = 'SUPERADMIN mode'
                : <SelfGovsEditForm onExpansionChange={this._onExpanded}/>;

        const hideHeader = ACCOUNT_VIEW_SMOOTHER_UX_ENABLED ? false : this.state.expanded && isWidthDown('sm', width);

        return (
            <>
                <Dialog
                    open={profileView.visible}
                    onClose={this._handleClose}
                    maxWidth="md"
                    classes={{
                        paper: classes.paper
                    }}
                    disableBackdropClick
                >
                    <Card className={classes.settingsCard}>

                        <CardHeader
                            avatar={
                                <Avatar aria-label="Account setting" className={classes.avatar}>
                                    <AccountIcon/>
                                </Avatar>
                            }
                            action={
                                <IconButton onClick={this._handleClose}>
                                    <CloseIcon/>
                                </IconButton>
                            }
                            title={translate("myroot.auth.accountSettings")}
                            titleTypographyProps={{variant: "body2"}}
                            subheaderTypographyProps={{color: "textPrimary", variant: "button"}}
                            //subheader={this._name(superadminOptionalText)}
                            className={classes.cardHeader}
                        />

                        <CardContent className={classes.cardContent}>
                            {
                                !hideHeader &&
                                <>
                                    <div className={classes.avatarSide}>

                                        {
                                            !isWidthDown('sm', width) &&
                                            <Avatar className={classes.bigAvatar}>
                                                <AccountIcon className={classes.bigIcon}/>
                                            </Avatar>
                                        }

                                        <Typography variant="title" className={classes.bigUserName}>
                                            {getUserShowName({superadmin: superadminOptionalText, mySelf})}
                                        </Typography>
                                        <Typography>
                                            {getMainRoleText(mySelf.roles)}
                                        </Typography>
                                        <br/>
                                        <Typography>
                                            {translate("resources.unitGovs.fields.role")}: {getAllRolesText(mySelf.roles)}
                                        </Typography>
                                        <br/>
                                        <PrivilegesField record={mySelf} source="priv"/>
                                        {/*{*/}
                                        {/*    has(mySelf, 'units') && mySelf.units.length > 0 &&*/}
                                        {/*    <Typography>*/}
                                        {/*        {translate("resources.units.name", {smart_count: 1})}: {getAllUnits(mySelf.units)}*/}
                                        {/*    </Typography>*/}
                                        {/*}*/}

                                    </div>
                                    {
                                        isWidthDown('sm', width) &&
                                        <Divider className={classes.divider}/>
                                    }
                                </>
                            }

                            <div className={classes.cardParagraph}>
                                <Typography gutterBottom variant="headline" component="h2">
                                    {translate("myroot.auth.accountData")}
                                </Typography>
                                <div>
                                    {accountForm}
                                </div>
                            </div>
                        </CardContent>
                        <CardActions>
                        </CardActions>
                    </Card>
                </Dialog>
                <MyNicknameInputDialog/>
            </>
        )
    }
}


const mapStateToProps = state => {
    return {
        profileView: state.mySettings.profileView,
        mySelf: state.mySelf,
        myAppExtras: state.myAppExtras,
        locale: state.i18n.locale,
        formNames: getFormNames()(state)
    }
};


export default compose(
    translate,
    connect(
        mapStateToProps,
        dispatch => ({
            destroyForms: bindActionCreators(destroy, dispatch),
        })
    ),
    withWidth(),
    withStyles(styles)
)(MyAccountView);
