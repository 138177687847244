
import {FETCH_ALL_CHECKINS_V1_RQ, STORE_ALL_CHECKINS_V1, SET_OP_OWNED_CHECKIN_V1} from "../constants/CheckinsV1Constants";

export const fetchAllCheckinsV1Rq = (payload) => {
    console.log('fetchAllCheckinsV1Rq', payload);
    return ({
        type: FETCH_ALL_CHECKINS_V1_RQ,
        payload,
    });
};

export const storeAllCheckinsV1 = (payload) => {
    console.log('storeAllCheckinsV1', payload);
    return ({
        type: STORE_ALL_CHECKINS_V1,
        payload,
    });
};

export const setOpOwnedCheckinV1 = payload => {
    console.log('setOpOwnedCheckinV1', payload);
    return ({
        type: SET_OP_OWNED_CHECKIN_V1,
        payload,
    });
};
