
import React from 'react';

import * as dd from 'dis-gui'; //renaming didn't work

//copy paste from doc
export const ddStyle = {
    paddingX: 3,
    paddingY: 3,
    backgroundColor: '#EEE',
    lowlight: '#DDD',
    lowlighterr: '#FBB',
    highlight: '#444',
    separator: '1px solid #DDD',
    label: {
        fontColor: '#444',
        fontWeight: 'normal'
    },
    labelWidth: 100,
    controlWidth: 250,
    top: '0px',  //was 3px
    right: '0px', //was 3px
    position: 'relative'
};

//just copy..
//for tests and such
//add again gradient control.. (removed cos wanted to test basics)
export default class SampleDisGUI extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <dd.GUI style = {ddStyle} >
                <dd.Text
                    label='authToken'
                    value={'qaq'}
                    onChange={()=>{}}
                />
                <dd.Folder label='Text' expanded={true}>
                    <dd.Text
                        label='Text'
                        value={'qaq'}
                        onChange={()=>{}}
                    />
                    <dd.Color
                        label='Color'
                        red={128}
                        green={128}
                        blue={128}
                        onChange={()=>{}}
                    />
                </dd.Folder>
                <dd.Folder label='Background' expanded={true}>
                    <dd.Select
                        label='Noise'
                        options={['Smooth', 'Fractal']}
                        value={'Smooth'}
                        onChange={()=>{}}
                    />
                    <dd.Number
                        label='Scale'
                        min={1}
                        max={20}
                        value={5}
                        decimals={3}
                        onChange={()=>{}}
                    />
                    <dd.Button
                        label='Randomize Gradient'
                        onChange={()=>{}}
                    />
                    <dd.Folder label='Animation' expanded={true}>
                        <dd.Number
                            label='Speed'
                            min={0}
                            max={0.01}
                            step={0.001}
                            decimals={3}
                            value={0.002}
                            onChange={()=>{}}
                        />
                        <dd.Checkbox
                            label='checkbox'
                            checked={true}
                            onChange={()=>{}}
                        />
                    </dd.Folder>
                </dd.Folder>
            </dd.GUI>
        )
    }

    onClickRandomGradient() {

    }

}
