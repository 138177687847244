
export const ICONS = {
    BLUE_DRONE_ICO : 'BLUE_DRONE_ICO',
    CYAN_DRONE_ICO : 'CYAN_DRONE_ICO',

    GREEN_DRONE_ICO: 'GREEN_DRONE_ICO',
    GREEN2_DRONE_ICO: 'GREEN2_DRONE_ICO',
    GREY_DRONE_ICO: 'GREY_DRONE_ICO',
    WHITE_DRONE_ICO: 'WHITE_DRONE_ICO',
    WHITE2_DRONE_ICO: 'WHITE2_DRONE_ICO',

    ORANGE_DRONE_ICO: 'ORANGE_DRONE_ICO',
    ORANGE2_DRONE_ICO: 'ORANGE2_DRONE_ICO',
    SALMON_DRONE_ICO: 'SALMON_DRONE_ICO',

    PURPLE_DRONE_ICO: 'PURPLE_DRONE_ICO',
    RED_DRONE_ICO: 'RED_DRONE_ICO',
    YELLOW_DRONE_ICO: 'YELLOW_DRONE_ICO',
    MAGENTA_DRONE_ICO: 'MAGENTA_DRONE_ICO',
    BLUE_HOSPITAL_ICO: 'BLUE_HOSPITAL_ICO',

    BLUE_DRONE_SELECTED_ICO: 'BLUE_DRONE_SELECTED_ICO',
    CYAN_DRONE_SELECTED_ICO: 'CYAN_DRONE_SELECTED_ICO',

    GREEN_DRONE_SELECTED_ICO: 'GREEN_DRONE_SELECTED_ICO',
    GREEN2_DRONE_SELECTED_ICO: 'GREEN2_DRONE_SELECTED_ICO',
    GREY_DRONE_SELECTED_ICO: 'GREY_DRONE_SELECTED_ICO',
    WHITE_DRONE_SELECTED_ICO: 'WHITE_DRONE_SELECTED_ICO',
    WHITE2_DRONE_SELECTED_ICO: 'WHITE2_DRONE_SELECTED_ICO',

    ORANGE_DRONE_SELECTED_ICO: 'ORANGE_DRONE_SELECTED_ICO',
    ORANGE2_DRONE_SELECTED_ICO: 'ORANGE2_DRONE_SELECTED_ICO',
    SALMON_DRONE_SELECTED_ICO: 'SALMON_DRONE_SELECTED_ICO',

    PURPLE_DRONE_SELECTED_ICO: 'PURPLE_DRONE_SELECTED_ICO',
    RED_DRONE_SELECTED_ICO: 'RED_DRONE_SELECTED_ICO',
    YELLOW_DRONE_SELECTED_ICO: 'YELLOW_DRONE_SELECTED_ICO',
    MAGENTA_DRONE_SELECTED_ICO: 'MAGENTA_DRONE_SELECTED_ICO',
    BLUE_HOSPITAL_SELECTED_ICO: 'BLUE_HOSPITAL_SELECTED_ICO',

    //colors unused now..
    HELI_BW: 'HELI_BW',
    HELI_GRAY: 'HELI_GRAY',
    HELI_YELLOW: 'HELI_YELLOW',
    HELI_RED: 'HELI_RED',

    PLANE_BW: 'PLANE_BW',
    PLANE_GRAY: 'PLANE_GRAY',
    PLANE_YELLOW: 'PLANE_YELLOW',
    PLANE_RED: 'PLANE_RED',

    UFO_BW: 'UFO_BW',
    UFO_GRAY: 'UFO_GRAY',
    UFO_YELLOW: 'UFO_YELLOW',
    UFO_RED: 'UFO_RED',

    LOCATION_HOME: 'LOCATION_HOME',
    LOCATION_PROBE: 'LOCATION_PROBE',
    BLUE_SQUARE: 'BLUE_SQUARE',
    BLUE_CIRCLE: 'BLUE_CIRCLE'
};

//species from adsb sources
// { value: '0', text: 'None' },
// { value: '1', text: 'Landplane' },
// { value: '2', text: 'Seaplane' },
// { value: '3', text: 'Amphibian' },
// { value: '4', text: 'Helicopter' },
// { value: '5', text: 'Gyrocopter' },
// { value: '6', text: 'Tilt-wing' }
// 8 -> Radio Mast
//let testIco;

//hmm very simplified
export function aircraft2ico(species, noAlt = false) {
    if (species === 1) {
        return (noAlt)?ICONS.PLANE_GRAY:ICONS.PLANE_BW;
    }
    if (species === 4) {
        return (noAlt)?ICONS.HELI_GRAY:ICONS.HELI_BW;
    }
    return (noAlt)?ICONS.UFO_GRAY:ICONS.UFO_BW;
}

/* old rt-map setup..
kept as reference...
to remove when all functionality is working (types/selects/delays)
    {status: 0, ico: blueIcon, selectedIco : blueSelectedIcon, fills: optsBlue,
        icoDelayed: blueIcon, selectedIcoDelayed : blueSelectedIcon, fillsDelayed: optsBlue},
    {status: 1, ico: greenIcon, selectedIco : greenSelectedIcon, fills: optsGreen,
        icoDelayed: green2Icon, selectedIcoDelayed : green2SelectedIcon, fillsDelayed: optsGreen2},
    {status: -1, ico: orangeIcon, selectedIco : orangeSelectedIcon, fills: optsOrange,
        icoDelayed: orange2Icon, selectedIcoDelayed : orange2SelectedIcon,  fillsDelayed: optsOrange2},
    {status: -2, ico: greyIcon, selectedIco : greySelectedIcon, fills: optsGrey,
        icoDelayed: greyIcon, selectedIcoDelayed : greySelectedIcon,  fillsDelayed: optsGrey},
    {status: -3, ico: redIcon, selectedIco : redSelectedIcon, fills: optsRed,
        icoDelayed: redIcon, selectedIcoDelayed : redSelectedIcon,  fillsDelayed: optsRed},
    {status: -4, ico: purpleIcon, selectedIco : purpleSelectedIcon, fills: optsPurple,
        icoDelayed: purpleIcon, selectedIcoDelayed : purpleSelectedIcon,  fillsDelayed: optsPurple},
    {status: 'fallback', ico: yellowIcon, selectedIco : yellowSelectedIcon, fills: optsYellow,
        icoDelayed: yellowIcon, selectedIcoDelayed : yellowSelectedIcon,  fillsDelayed: optsYellow},
 */

export function checkinStatus2ico(checkinProperties, isSelected = false) {
    // if (checkinProperties.status === 0) {
    //     return (isSelected)?ICONS.BLUE_DRONE_SELECTED_ICO:ICONS.BLUE_DRONE_ICO
    // }
    // if (checkinProperties.status === -4) {
    //     return (isSelected)?ICONS.PURPLE_DRONE_SELECTED_ICO:ICONS.PURPLE_DRONE_ICO
    // }
    // if (checkinProperties.status === -3) {
    //     return (isSelected)?ICONS.RED_DRONE_SELECTED_ICO:ICONS.RED_DRONE_ICO
    // }
    // if (checkinProperties.status === -2) {
    //     return (isSelected)?ICONS.GREY_DRONE_SELECTED_ICO:ICONS.GREY_DRONE_ICO
    // }
    // if (checkinProperties.status === -1) {
    //     return (isSelected)?ICONS.ORANGE_DRONE_SELECTED_ICO:ICONS.ORANGE_DRONE_ICO
    // }
    // if (checkinProperties.status === 1) {
    //     return (isSelected)?ICONS.GREEN_DRONE_SELECTED_ICO:ICONS.GREEN_DRONE_ICO
    // }
    // if (isSelected) {
    //     console.warn('wtf, unknown checkin status', checkinProperties);
    // }
    // return (isSelected)?ICONS.YELLOW_DRONE_SELECTED_ICO:ICONS.YELLOW_DRONE_ICO
}


export const standardColors = {
    'navy': [0, 0, 128],
    'magenta': [255, 0, 255],
    'red': [255, 0, 0],
    'purple' : [128, 0, 128],
    'black' : [0,0,0],
    'white' : [255,255,255],
    'black50' : [0,0,0,128],
    'gold' : [255,215,0],
    'blue': [0, 0, 255],
    'gray': [128, 128, 128],
    'green': [0, 255, 0]
};

export const functionalColors = {
    'newPrimitive' : [...standardColors.magenta, 192],
    'ban' : [...standardColors.red, 128],
    'ok' : [...standardColors.magenta, 192],
    'selected' : [...standardColors.gold, 192]
};

export default {
    checkinStatus2ico,
    aircraft2ico,
    standardColors,
    functionalColors,
}
