import {TextField} from 'react-admin'
import React from "react";
import {getStatusIconColored} from "../../../utils/Dashboard/statusIcon";
import StatusField from "../../StatusField";
import {ReferenceFieldDecorator as ReferenceField} from "../../ReferenceFieldDecorator";
import Table from "@material-ui/core/Table/Table";
import TableRow from "@material-ui/core/TableRow/TableRow";
import TableCell from "@material-ui/core/TableCell/TableCell";
import TableBody from "@material-ui/core/TableBody/TableBody";
import withWidth, {isWidthDown} from "@material-ui/core/withWidth";
import Send from '@material-ui/icons/Send';
import {compose} from "redux";
import {translate} from "react-admin";
import {SummaryCards} from "../../SummaryCards";
import {translateCaaStatusToTypo} from "../../../utils/applicationsHelpers";
import {ConditionalDroneRegistryField} from "./ConditionalDroneRegistryField";


const sortFieldOptions = [
    {
        label: 'resources.missions.fields.created',
        value: 'created'
    },
    {
        label: 'resources.missions.fields.modified',
        value: 'modified'
    },
    {
        label: 'resources.drones.fields.name',
        value: 'name'
    },
    {
        label: 'resources.applications.verification',
        value: 'status'
    },
    {
        label: 'resources.droneModels.fields.make',
        value: 'make'
    },
    {
        label: 'resources.droneModels.fields.model',
        value: 'model'
    },
    {
        label: 'resources.drones.fields.registry',
        value: 'registry'
    },
    {
        label: 'resources.droneModels.fields.type',
        value: 'type'
    },
    {
        label: 'resources.drones.fields.operator',
        value: 'operatorid'
    },
];


export const MissionDronesSummaryCards = compose(translate, withWidth())
(
    ({
         basePath,
         permissions,
         resource,
         translate,
         width,
         ...rest
     }) => (
        <SummaryCards
            title={
                ({record}) => <ReferenceField source="id"
                                              reference="drones"
                                              linkType="show"
                                              basePath={'/drones'}
                                              {...{record}}
                >
                    <TextField source="name"/>
                </ReferenceField>
            }
            subheader={
                ({record}) => <ReferenceField source="id"
                                              reference="drones"
                                              linkType={false}
                                              {...{record, basePath}}
                >
                    <ConditionalDroneRegistryField/>
                </ReferenceField>
            }
            avatarIcon={<Send/>}
            {...{sortFieldOptions, ...rest}}
        >
            {
                ({record}) =>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell>
                                    {translate('resources.applications.verification')}
                                </TableCell>
                                <TableCell>
                                    <ReferenceField source="id"
                                                    reference="drones"
                                                    linkType={false}
                                                    {...{record, basePath}}
                                    >
                                        <StatusField source="status"
                                                     tooltip={isWidthDown('md', width)}
                                                     statusMappingFn={translateCaaStatusToTypo}
                                                     iconGetter={getStatusIconColored}
                                                     record={record}
                                        />
                                    </ReferenceField>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    {translate('resources.droneModels.fields.make')}
                                </TableCell>
                                <TableCell>
                                    <ReferenceField source="id"
                                                    basePath={basePath}
                                                    record={record}
                                                    reference="drones"
                                                    linkType={false}
                                    >
                                        <TextField source="make"/>

                                    </ReferenceField>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    {translate('resources.droneModels.fields.model')}
                                </TableCell>
                                <TableCell>
                                    <ReferenceField source="id"
                                                    reference="drones"
                                                    linkType={false}
                                                    {...{record, basePath}}
                                    >
                                        <TextField source="model"/>
                                    </ReferenceField>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    {translate('resources.droneModels.fields.type')}
                                </TableCell>
                                <TableCell>
                                    <ReferenceField source="id"
                                                    reference="drones"
                                                    linkType={false}
                                                    {...{record, basePath}}
                                    >
                                    <TextField source="type"/>
                                    </ReferenceField>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    {translate('resources.droneModels.fields.class')}
                                </TableCell>
                                <TableCell>
                                    <ReferenceField source="id"
                                                    reference="drones"
                                                    linkType={false}
                                                    {...{record, basePath}}
                                    >
                                        <TextField source="class"/>
                                    </ReferenceField>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    {translate('resources.droneModels.fields.mtom')}
                                </TableCell>
                                <TableCell>
                                    <ReferenceField source="id"
                                                    reference="drones"
                                                    linkType={false}
                                                    {...{record, basePath}}
                                    >
                                        <TextField source="mtom"/>
                                    </ReferenceField>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    {translate('resources.droneModels.fields.fs')}
                                </TableCell>
                                <TableCell>
                                    <ReferenceField source="id"
                                                    reference="drones"
                                                    linkType={false}
                                                    {...{record, basePath}}
                                    >
                                        <TextField source="fs"/>
                                    </ReferenceField>
                                </TableCell>
                            </TableRow>
                        </TableBody>

                    </Table>
            }
        </SummaryCards>
    )
);