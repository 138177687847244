import {STORE_ALL_ZONES_V2, FORCE_UPDATE_ZONES_V2} from "../constants/ZonesV2Constants";
import {initializeLayersObject} from "../../gl_map/glSetups/newCommon/mapStateConstructors";
import {unixTimeNow} from "../oldLogic/TimeConverters";
import {processZonesV2ToDeck} from "../oldLogic/ZonesListV2";


//TODO: solve interesting problem of data derived from redux and memoization of accessor functions
//https://redux.js.org/recipes/computingderiveddata
const initialState = {rawData:[], deckData:initializeLayersObject(), storeTimestamp : 0};

const ZonesV2Reducer = (previousState = initialState, { type, payload }) => {
    switch (type) {
        case STORE_ALL_ZONES_V2:
            console.log('----- ZonesV2Reducer reducer STORE_ALL_ZONES_V2:', type, payload);

            if (payload.rawData) {
                payload.deckData = processZonesV2ToDeck(payload);

            }

            return {...previousState, ...payload, storeTimestamp:unixTimeNow()};

        case FORCE_UPDATE_ZONES_V2:
            return {...previousState, storeTimestamp:unixTimeNow()};

        default:
            return previousState;

    }
};

export default ZonesV2Reducer;
