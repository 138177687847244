import {MissionV2Statuses} from "../../LogicV1Redux/constants/MissionsV2Enums";
import TranslatableTypography from "../components/TranslatableTypography";
import React from "react";

export const areAllZonesOwned = (legs) => {
    let allZonesOwned = true;
    for (let i = 0; i < legs.length; i++) {
        //zoneLeg IS array!
        for (let j = 0; j < legs[i].zoneLeg.length; j++) {
            const singleZoneLeg = legs[i].zoneLeg[j];
            console.log('======singleZoneLeg status check here', singleZoneLeg);
            if (!singleZoneLeg.__hasZoneOwnership) {
                allZonesOwned = false;
            }
        }
    }
    return allZonesOwned;
};

export const areAllMyZonesAccepted = (legs) => {

    for (let i = 0; i < legs.length; i++) {
        for (let j = 0; j < legs[i].zoneLeg.length; j++) {
            const singleZoneLeg = legs[i].zoneLeg[j];
            console.log('======singleZoneLeg acceptance status check here', singleZoneLeg);
            if (singleZoneLeg.__hasZoneOwnership === true) {
                if(singleZoneLeg.status !== 'accepted') {
                    return false;
                }
            }
        }
    }
    return true;
};

export const isSomeZoneOwned = (legs) => {
    let someZoneOwned = false;
    for (let i = 0; i < legs.length; i++) {
        for (let j = 0; j < legs[i].zoneLeg.length; j++) {
            const singleZoneLeg = legs[i].zoneLeg[j];
            if (singleZoneLeg.__hasZoneOwnership) {
                someZoneOwned = true;
                break;
            }
        }
    }
    return someZoneOwned;
};

export const getMissionStatusTranslationResource = (status) => {
    let translation;
    switch (status) {
        case MissionV2Statuses.ACCEPTED:
            translation = 'resources.missions.status.accepted';
            break;
        case MissionV2Statuses.REJECTED:
            translation = 'resources.missions.status.rejected';
            break;
        case MissionV2Statuses.PLANNED:
            translation = 'resources.missions.status.pending';
            break;
        case MissionV2Statuses.CREATED:
            translation = 'resources.missions.status.created';
            break;
        case MissionV2Statuses.UNKNOWN:
        default: translation = 'BAD_STATUS'
    }
    return translation;
};

export const translateMissionStatusToTypo = (status) => {
    return <TranslatableTypography component='span' content={getMissionStatusTranslationResource(status)} options={{smart_count: 1}}/>
};