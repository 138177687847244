//export function handleClick(lat, lng, layerClickEvent, event) {
//hmm mulitphase1 -> check updating current primitive
//hmm mulitphase1 -> check updating current primitive
//hmm mulitphase1 -> check updating current primitive, event required for mouse right click
//hmm mulitphase1 -> check updating current primitive
//after multi phase1
import {
    addEditablePrimitive,
    getCurrentPrimitive,
    getDrawMode,
    getEditablePrimitives,
    removeEditablePrimitive,
    setCurrentPrimitive,
    setDrawMode
} from "./deckDrawStoreBridge";
import {DRAW_MODES, PRIMITIVES} from "./DrawEditorEnums";
import {onPolyDrawClick, onPolyDrawMouseMove} from "./deckDrawPoly";
import {onCircleDrawClick, onCircleDrawMouseMove} from "./deckDrawCircle";
import {onCapsuleDrawClick, onCapsuleDrawMouseMove} from "./deckDrawCapsule";
import {onCapsule2kmDrawClick, onCapsule2kmDrawMouseMove} from "./deckDrawCapsule2km";
import {onPipeDrawClick, onPipeDrawMouseMove} from "./deckDrawPipe";
import {onPolyEditClick, onPolyEditMouseMove} from "./deckEditPoly";
import {onCircleEditClick, onCircleEditMouseMove} from "./deckEditCircle";
import {onCapsuleEditClick, onCapsuleEditMouseMove} from "./deckEditCapsule";
import {onPipeEditClick, onPipeEditMouseMove} from "./deckEditPipe";
import {onPointDrawClick, onPointDrawMouseMove} from "./deckDrawPoint";
import {onLocationDrawClick, onLocationDrawMouseMove} from "./deckDrawLocation";
import {onMeasureDrawClick, onMeasureDrawMouseMove} from "./deckDrawMeasure";
import {onMeasureEditClick, onMeasureEditMouseMove} from "./deckEditMeasure";
import DeckPoint from "../deckPrimitives/DeckPoint";
import DeckLocation from "../deckPrimitives/DeckLocation"
import DeckPoly from "../deckPrimitives/DeckPoly";
import DeckCircle from "../deckPrimitives/DeckCircle";
import DeckCapsule from "../deckPrimitives/DeckCapsule";
import DeckPipe from "../deckPrimitives/DeckPipe";
import {updateDrawLayers, endDraw, setDrawCursor, isEditingModeEnabled, } from "./deckEditor";
import DeckMeasure from "../deckPrimitives/DeckMeasure";

export function handleMouseMove(latLng, event) {
    //const clientXY = {x:e.clientX, y:e.clientY};
    //console.log('mousemove deckdraw', event);
    const doRedraw = isEditingModeEnabled();
    if (doRedraw) {
        if (getDrawMode() === DRAW_MODES.EDIT) {
            //console.log('editing here');
            _editMoves(latLng);
        } else
            _drawMoves(latLng);
    }

    return doRedraw;
}

function _drawClicks(latLng, layerClickEvent, event) {
    let editablePrimitive = getCurrentPrimitive();

    const drawMode = getDrawMode();

    if (drawMode === DRAW_MODES.MEASURE) {
        if (!editablePrimitive) {
            console.log('starting new measure');
            editablePrimitive = new DeckMeasure(latLng, latLng);
            addEditablePrimitive(editablePrimitive);
            //console.log('new deck circle', editablePrimitive)
        } else {
            editablePrimitive = onMeasureDrawClick(editablePrimitive, latLng, layerClickEvent, event);
        }
        console.log('measure', editablePrimitive);
    } else if (drawMode === DRAW_MODES.DRAW_POINT) {
        if (!editablePrimitive) {
            console.log('starting new point');
            editablePrimitive = new DeckPoint(latLng);
            addEditablePrimitive(editablePrimitive);
            //console.log('new deck circle', editablePrimitive)
        } else {
            editablePrimitive = onPointDrawClick(editablePrimitive, latLng, layerClickEvent, event);
        }
        console.log('point', editablePrimitive);
    } else if (drawMode === DRAW_MODES.DRAW_LOCATION) {
        if (!editablePrimitive) {
            console.log('starting new location');
            editablePrimitive = new DeckLocation(latLng);
            addEditablePrimitive(editablePrimitive);
            //console.log('new deck circle', editablePrimitive)
        } else {
            editablePrimitive = onLocationDrawClick(editablePrimitive, latLng, layerClickEvent, event);
        }
        console.log('point', editablePrimitive);
    } else if (drawMode === DRAW_MODES.DRAW_POLY) {
        if (!editablePrimitive) {
            console.log('starting new poly');
            editablePrimitive = new DeckPoly([latLng, latLng]);
            addEditablePrimitive(editablePrimitive);
            //console.log('new deck circle', editablePrimitive)
        } else {
            editablePrimitive = onPolyDrawClick(editablePrimitive, latLng, layerClickEvent, event);
        }
        console.log('poly', editablePrimitive);
    } else if (drawMode === DRAW_MODES.DRAW_CIRCLE) {
        if (!editablePrimitive) {
            console.log('starting new circle');

            editablePrimitive = new DeckCircle(latLng, latLng); //fixme constructor?
            addEditablePrimitive(editablePrimitive);
            //console.log('new deck circle', editablePrimitive)
        } else {
            editablePrimitive = onCircleDrawClick(editablePrimitive, latLng, layerClickEvent);
        }
        console.log('circle', editablePrimitive);
    } else if (drawMode === DRAW_MODES.DRAW_CAPSULE) {
        if (!editablePrimitive) {
            console.log('starting new capsule');

            editablePrimitive = new DeckCapsule(latLng, latLng); //fixme constructor?
            addEditablePrimitive(editablePrimitive);

            //console.log('new deck capsule', editablePrimitive)
        } else {
            editablePrimitive = onCapsuleDrawClick(editablePrimitive, latLng, layerClickEvent);
        }
        console.log('capsule', editablePrimitive);
    } else if (drawMode === DRAW_MODES.DRAW_CIRCLE2KM) {
        if (!editablePrimitive) {
            console.log('starting new circle2km');

            editablePrimitive = new DeckCircle(latLng, 2000); //hardcoded radius
            addEditablePrimitive(editablePrimitive);
            //single click for predefined radius, so...
            endDraw();
            console.log('new deck circle2km', JSON.stringify(editablePrimitive))
        } else {
            //editablePrimitive = onCircleDrawClick(editablePrimitive, lat, lng, layerClickEvent);
        }
        console.log('circle2km', editablePrimitive);
    } else if (drawMode === DRAW_MODES.DRAW_CAPSULE2KM) {
        if (!editablePrimitive) {
            console.log('starting new capsule2km');

            editablePrimitive = new DeckCapsule(latLng, latLng, 2000); //fixme constructor?
            addEditablePrimitive(editablePrimitive);
            console.log('new deck capsule2km', JSON.stringify(editablePrimitive));
            console.log('new deck capsule2km', editablePrimitive)
        } else {
            editablePrimitive = onCapsule2kmDrawClick(editablePrimitive, latLng, layerClickEvent);
        }
        console.log('capsule2km', editablePrimitive);
    } else if (drawMode === DRAW_MODES.DRAW_PIPE) {
        if (!editablePrimitive) {
            console.log('starting new pipe');

            editablePrimitive = new DeckPipe([latLng, latLng]);  //fixme constructor?
            addEditablePrimitive(editablePrimitive);

            console.log('new deck pipe', editablePrimitive)
        } else {
            editablePrimitive = onPipeDrawClick(editablePrimitive, latLng, layerClickEvent);
        }
        console.log('pipe', editablePrimitive);
    } else if (drawMode === DRAW_MODES.DRAW_LINE) {
        // onRhumbLineDrawClick(lat, lng, layerClickEvent);
        // //hmm just testing FIXME
        // onGreatCircleDrawClick(lat, lng, layerClickEvent);
        // drawMarkers = [...getRhumbLineMarkers()];
        // drawPaths = [];
        // const rhumbline = getRhumbLine();
        // const greatcircle = getGreatCircle();
        // //drawPaths = (rhumbline)?[rhumbline]:[]; //single now
        // if (rhumbline) drawPaths.push(rhumbline);
        // if (greatcircle) drawPaths.push(greatcircle);
    }
}

function _editClicks(latLng, layerClickEvent, event) {
    let editablePrimitive = getCurrentPrimitive();
    const editablePrimitives = getEditablePrimitives();

    const drawMode = getDrawMode();

    console.log('_editClicks DrawMode', drawMode);

    if (!editablePrimitive) {
        console.log('no editable primitive');
        return;
    }
    if (editablePrimitive.primitiveType === PRIMITIVES.POINT) {
        console.log('unsupported')
    } else if (editablePrimitive.primitiveType === PRIMITIVES.LOCATION) {
        console.log('unsupported')
    } else if (editablePrimitive.primitiveType === PRIMITIVES.MEASURE) {
        editablePrimitive = onMeasureEditClick(editablePrimitive, latLng, layerClickEvent, event);
        console.log('onMeasureEditClick', editablePrimitive);
    } else if (editablePrimitive.primitiveType === PRIMITIVES.POLYGON) {
        editablePrimitive = onPolyEditClick(editablePrimitive, latLng, layerClickEvent, event);
        console.log('onPolyEditClick', editablePrimitive);

    } else if (editablePrimitive.primitiveType === PRIMITIVES.CIRCLE) {
        editablePrimitive = onCircleEditClick(editablePrimitive, latLng, layerClickEvent);
        console.log('onCircleEditClick', editablePrimitive);

    } else if (editablePrimitive.primitiveType === PRIMITIVES.CAPSULE) {
        editablePrimitive = onCapsuleEditClick(editablePrimitive, latLng, layerClickEvent);
        console.log('capsule', editablePrimitive);

    } else if (editablePrimitive.primitiveType === PRIMITIVES.PIPE) {
        editablePrimitive = onPipeEditClick(editablePrimitive, latLng, layerClickEvent);
        console.log('pipe', editablePrimitive);
    } else if (drawMode === DRAW_MODES.DRAW_LINE) {
        console.log('unsupported');
        return;
        // onRhumbLineDrawClick(lat, lng, layerClickEvent);
        // //hmm just testing FIXME
        // onGreatCircleDrawClick(lat, lng, layerClickEvent);
        // drawMarkers = [...getRhumbLineMarkers()];
        // drawPaths = [];
        // const rhumbline = getRhumbLine();
        // const greatcircle = getGreatCircle();
        // //drawPaths = (rhumbline)?[rhumbline]:[]; //single now
        // if (rhumbline) drawPaths.push(rhumbline);
        // if (greatcircle) drawPaths.push(greatcircle);
    }

    if (!editablePrimitive) {
        setDrawMode(DRAW_MODES.SELECT);
        //return;
    }

    // PubSub.publish(PubSubSignals.DRAW_UPDATED, {current:editablePrimitive, all:editablePrimitives});

    //_updateDrawLayers();
    //drawCursor = getDrawCursorIco(lat, lng);
}

function _drawMoves(latLng) {
    const drawMode = getDrawMode();

    let editablePrimitive = getCurrentPrimitive();
    if (drawMode === DRAW_MODES.MEASURE) {
        editablePrimitive = onMeasureDrawMouseMove(editablePrimitive, latLng);
    } else if (drawMode === DRAW_MODES.DRAW_POLY) {
        editablePrimitive = onPolyDrawMouseMove(editablePrimitive, latLng);
    } else if (drawMode === DRAW_MODES.DRAW_CIRCLE) {
        editablePrimitive = onCircleDrawMouseMove(editablePrimitive, latLng);
    } else if (drawMode === DRAW_MODES.DRAW_CAPSULE) {
        editablePrimitive = onCapsuleDrawMouseMove(editablePrimitive, latLng);
    } else if (drawMode === DRAW_MODES.DRAW_CIRCLE2KM) {
        //editablePrimitive = onCircleDrawMouseMove(editablePrimitive, lat, lng);
        //do nothing!
    } else if (drawMode === DRAW_MODES.DRAW_CAPSULE2KM) {
        editablePrimitive = onCapsule2kmDrawMouseMove(editablePrimitive, latLng);
    } else if (drawMode === DRAW_MODES.DRAW_PIPE) {
        editablePrimitive = onPipeDrawMouseMove(editablePrimitive, latLng);
    }

    if (!editablePrimitive) {
        updateDrawLayers("_drawMoves no editable"); //hmm
        return null;
    }
    //review call (somehow lonely -> one place?)
    setDrawCursor(latLng, 'cursorEdit', 64);

    updateDrawLayers("_drawMoves VTX");

}

function _editMoves(latLng) {
    let editablePrimitive = getCurrentPrimitive();


    if (!editablePrimitive) {
        //console.log('no editable primitive');
        return;
    } else if (editablePrimitive.primitiveType === PRIMITIVES.MEASURE) {
        editablePrimitive = onMeasureEditMouseMove(editablePrimitive, latLng);
    } else if (editablePrimitive.primitiveType === PRIMITIVES.POLYGON) {
        editablePrimitive = onPolyEditMouseMove(editablePrimitive, latLng);
    } else if (editablePrimitive.primitiveType === PRIMITIVES.CIRCLE) {
        editablePrimitive = onCircleEditMouseMove(editablePrimitive, latLng);
    } else if (editablePrimitive.primitiveType === PRIMITIVES.CAPSULE) {
        editablePrimitive = onCapsuleEditMouseMove(editablePrimitive, latLng);
    } else if (editablePrimitive.primitiveType === PRIMITIVES.PIPE) {
        editablePrimitive = onPipeEditMouseMove(editablePrimitive, latLng);
    }
    if (!editablePrimitive) {
        updateDrawLayers("_editMoves no editable"); //hmm
        return null;
    }

    updateDrawLayers("_editMoves");
}

export function handleClick(latLng, layerClickEvent, event) {
    const drawMode = getDrawMode();
    const editablePrimitives = getEditablePrimitives();

    //console.log("editablePrimitives)

    const doRedraw = (drawMode !== DRAW_MODES.NO_DRAW);
    if (doRedraw) {
        if (drawMode === DRAW_MODES.SELECT || drawMode === DRAW_MODES.DELETE) {
            console.log('fix select/delete support here', layerClickEvent, editablePrimitives);
            if (layerClickEvent && layerClickEvent.object) {
                console.log(layerClickEvent.object);
                for (let i = 0; i < editablePrimitives.length; i++) {
                    if (editablePrimitives[i] === layerClickEvent.object) {
                        console.log('found?@', i);
                        if (drawMode === DRAW_MODES.SELECT) {
                            setCurrentPrimitive(editablePrimitives[i]);
                            return true;
                        } else {
                            //delete by object click
                            removeEditablePrimitive(editablePrimitives[i]);
                            //_updateDrawLayers();
                            return true;
                        }
                    }
                }
            } else {
                //setDrawMode(DRAW_MODES.SELECT);
                //setCurrentPrimitive(null);
                return true;
            }

        } else if (drawMode === DRAW_MODES.EDIT) {


            //console.log('found?@', i);
            console.log('editing here', layerClickEvent, event);
            _editClicks(latLng, layerClickEvent, event);


        } else {
            console.log('drawing here', layerClickEvent);
            _drawClicks(latLng, layerClickEvent, event);
        }
    }
    return doRedraw;
}
