import React from "react";
import {List} from "react-admin";
import { withStyles } from '@material-ui/core/styles';



const styles = theme => ({
    card: {
        [theme.breakpoints.up('md')]: {
            minWidth: "673px"
        }
    },
});

export const ListDecorator = withStyles(styles)(List);