import englishMessages from './ra-language-english';
import {RESOURCES} from "../logic/MyRestConfig";
import Configs, {FEATURES_V1_2_ENUMS} from "../../Configs";


const INFOBOX_NEW_MISSION_INFOS_ENABLED = Configs.enabledFeatures.includes(FEATURES_V1_2_ENUMS.INFOBOX_NEW_MISSION_INFOS);
const MISSION_DROPDOWN_SUPPORTED_TYPES_ENABLED = Configs.enabledFeatures.includes(FEATURES_V1_2_ENUMS.MISSION_DROPDOWN_SUPPORTED_TYPES);
const ASM_NEW_LABELS = Configs.enabledFeatures.includes(FEATURES_V1_2_ENUMS.ASM_NEW_LABELS);
const NEW_ALERTBOX_ENABLED = Configs.enabledFeatures.includes(FEATURES_V1_2_ENUMS.ALERTBOX_NEW_INFOS);
const MISSION_112_ENABLED = Configs.enabledFeatures.includes(FEATURES_V1_2_ENUMS.MISSION_112);
const ACM_INFOBOXES_ENABLED = Configs.enabledFeatures.includes(FEATURES_V1_2_ENUMS.ACM_INFOBOXES);
const APPLICATION_NEW_GOV_USER_MSGS_ENABLED = Configs.enabledFeatures.includes(FEATURES_V1_2_ENUMS.APPLICATION_NEW_GOV_USER_MSGS);

export const messages = {
    simple: {
        not_available: 'N/A',
        action: {
            resetViews: 'Reset views',
            archive: 'Archive'
        },

    },
    ...englishMessages,
    resources: {
        defaults : { // do not use.. different languages -> different contexts
        },
        archives : {
            archiveLabel : 'Archive',
            activeLabel : 'Active',
            action: {
                archive: 'Archive',
                unarchive: 'Revert'
            },
            notifications: {
                archived:  'Archived successfully',  //hmm (was smart count)
                unarchived: 'Reverted successfuly'
            }
        },
        operations : {
            notifications: {
                saveFailedErr: 'Operation Save Failed',
            },
        },
        applications: {
            status: {
                accepted: 'Application verified |||| Applications verified',
                rejected: 'Application rejected |||| Applications rejected',
                pending: 'Application pending verification |||| Applications pending verification',
                unknown: 'Application saved |||| Applications saved',
                pendingAgain: 'Application is waiting for re-verification |||| Applications awaiting re-verification',
                modified: "Application rejected and modified by operator |||| Applications rejected and modified by operator",
                created: "Not subjected to verification",
                meetingRequest: `Meeting pending for operator's acceptance |||| Meetings pending for operator's acceptance`,
                meetingAccepted: "Arranged meeting |||| Arranged meetings",
                meetingRejected: "Denied meeting |||| Denied meetings"
            },
            resource: "Resource |||| Resources",
            loading: 'Loading applications',
            name: 'Application name',
            verification: "Vrification",
            archive: 'Archived',
            verify: "Request  verification",
            ...(NEW_ALERTBOX_ENABLED && {verificationStatus: 'Verification status'}),
            ...(NEW_ALERTBOX_ENABLED && {messagesCaa: 'Messages from CAA'}),
            ...(NEW_ALERTBOX_ENABLED && {messagesPrincipal: 'Messages from principal'}),
            accept: "Accept application",
            archiveDisabled: "Cannot archive during verification",
            reject: "Reject application",
            createMeeting: "Arrange meeting",
            confirmMeeting: "Confirm meeting",
            cancelMeeting: "Cancel meeting",
            denyMeeting: "Deny meeting",
            meeting: "Meeting |||| Meetings",
            kind: "Kind",
            notifications: {
                applicationSentMsg: 'Application has been sent for verification',
                applicationSentFailMsg: 'Application could not be sent for verification',
                applicationSending: 'Sending the application for verification',
                applicationChangeStatusMsg: 'The status of the application has been updated',
                applicationChangeStatusFailMsg: 'The application status could not be updated',
                applicationChangingStatus: 'Updating the status of the application'
            },
            rejectDialogTitle: 'Application rejected',
            rejectDialogDefaultText: 'Reason for rejection',
            rejectDialogLabel: 'Additional information about the rejection',
            acceptDialogLabel: 'Additional information about the confirmation',
            meetingDialogTitle: 'Arrange meeting',
            meetingDialogDefaultText: 'Location',
            meetingDialogLabel: 'Additional info about the meeting (e.g. location)',
            meetingDate: 'Meeting date',
            principalDialogTitle: "Request verification",
            principalDialogLabel: "Choose the principal of the request",
            principal: "Principal",
            verificationDialogTitle: "Do you want to lose verification?",
            verificationDialogLabel: "Saving changes will cause verification removal. Are you sure?",
            rejectMeetingDialogTitle: 'Deny meeting',
            rejectMeetingDialogLabel: 'Causes of meeting deny',
            rejectMeetingDates: 'Enter at least one meeting date proposal: ',
            rejectMeetingDate: 'Date #%{number}',
            rejectMeetingDatePropsals: "New meeting date proposals",
            ...(APPLICATION_NEW_GOV_USER_MSGS_ENABLED && {issuedBy: "Issued by"}),
            ...(APPLICATION_NEW_GOV_USER_MSGS_ENABLED ),
},
        files : {
            attachments : 'Attachments',
            dropboxLabel : 'Attachments',
            rejectedInput: 'File can not be uploaded. Too large or wrong format! Acceptable file or files size is 10MB. ' +
                'Acceptable file formats: JPEG, PNG, PDF.',
            rejectedInputFromBundleSize: 'File can not be uploaded. The maximum size of attachment has been exceeded!',
            dropboxPlaceholder : "Drag-and-drop to upload, or click to select from the catalog",
            adding: "Adding attachments"
        },
        droneModels : {
            menu: 'Drone models',
            create: "New drone model",
            name: 'Drone model |||| Drone models',
            fields: {
                model: 'Model',
                make: 'Manufacturer',
                mtom: 'MTOM (g)',
                performance: 'Performance',
                ft: 'Flight time (min)',
                vmax: 'VMAX (m/s)',
                vmaxH: 'Vmax horizontal (m/s)',
                vmaxV: 'Vmax vertical (m/s)',
                type: 'Type',
                class: 'Class',
                fs: 'Fail safe',
                category: "Category",
                range: 'Range (m)',
                rangeFollowMe: 'Follow-me range (m)',
                drive: "Drive",
                driveTypes: {
                    electric: "Electric",
                    fuel: "Liquid fuel",
                    hybrid: "Hybrid"
                },
                flightModes: "Control mode",
                flightModeTypes: {
                    manual: "Manual",
                    gps: "GPS"
                },
                camera: "Camera",
                lights: "Lighting",
                lightTypes: {
                    navigation: "Navigation lights",
                    strobo: "Strobe"
                },
                maxTakeOffHeight: "Maximum height above take-off (m)",
                bms: "Battery Management System (BMS)",
                altitudeSensor: "Altitude sensor",
                uSpaceIntf: "U-space interface",
                safeEquipment: "Emergency equipment",

            },
            tooltips: {
                vmaxH: 'Maximum horizontal speed',
                vmaxV: 'Maximum vertical speed',
                range: 'Maximum range',
                rangeFollowMe: 'Maximum range in follow-me mode',
                maxTakeOffHeight: "Maximum attainable height above the take-off point (m)",
                bms: "Battery Management System (BMS)",
                altitudeSensor: "Altitude sensor (radio, barometric,...)",
                uSpaceIntf: "U-space interface",
                safeEquipment: "Emergency equipment (eg. parachute)",
                model: "If this is not a serial drone, type DIY",
                mtom: "Maximum Take-Off Mass",
                ft: "Total flight time",
                fs: "Select features of the drone that improve the safety of the mission",
                camera: "Please provide camera features like: type, resolution, image transmission, etc.",
                lights: "Select type of lighting",
            }

        },
        drones : {
            menu: 'Drones',
            create: 'Add drone',
            name: 'Drone |||| Drones',
            photoSerialNo: "Please attach photo of drone's serial number",
            fields: {
                uid: 'Unique identifier',
                registry: 'Registration',
                droneModels: 'Models',
                name: 'Name',
                created: 'Added',
                modified: 'Modified',
                etc_icao: 'ICAO code',
                etc_call: 'Transponder code',
                serialNo: 'Serial number',
                remoteId: 'Remote ID',
                equipment: 'Equipment',
                mlu: "Basic location device",
                elu: "Emergency location device",
                fpm: "Flight parameters monitoring",
                fps: "Flight parameters recorder",
                tether: "Tether (m)",
                customFeatures: "Special features",
                limits: "Operational restrictions",
                minAge: "Minimum age (years)",
                impact: "Energy impact (J)",
                lwa: "Sound Power Level Lwa (dBa)",
                certification: "Certificates",
                risk: "Risk",
                photo: "Photo",
                photoRequired: "Photo *",
                operator: 'Operator'
            },
            tooltips: {
                registry: "Registration number assigned by CAA (Civil Aviation Authority)",
                mlu: "Determination of the current location, speed, altitude and direction of the flight of the unmanned aircraft in order to transfer these data to the Air Traffic Service (ATS) provider via the teleinformation system or by telephone at the request of the ATS unit",
                elu: "Determination by the operator of the current position of the unmanned aircraft in the event of loss of control or interruptions in communication",
                fpm: "How flight parameters are monitored",
                fps: "How flight parameters are recorded",
                tether: "Maximum tether length (m)",
                limits: "Known operational limitations",
                minAge: "The minimum age required by law",
                impact: "The energy transmitted to the human head at the terminal velocity",
                lwa: "A guaranteed A-weighted sound power level Lwa determined as per Part 13 and Part 14 not exceeding the levels established in Part 15",
                certification: "European Declarations of Conformity",
                risk: "Risk related description",
                serialNo: "Drone's serial number. In case of a self-built drone (DIY), please enter the serial number of the flight controller.",
                etc_call: "Transponder call (SQUAWK)",
                etc_icao: "Official ICAO HEX code assigned by CAA",
                remoteId: "Remote identification number",
                name: "Type a name for your drone to get easy identified while using the system"
            }
        },
        certTypes : {
            menu: 'Types of certificates',
            name: 'Type of certificate |||| Types of certificates',

            fields : {
                name: 'Type',
                country: 'Country',
                expire: 'Valid until',
                validity: 'Validity',
                cert_code: "Code",
                fields: 'Fields',
                etc_certId: 'Certificate ID'
            }
        },

        certs : {
            menu: 'Certificates',
            name: 'Certificate |||| Certificates',
            create: 'New certificate',
            typesSelectionLabel: 'Select the type of certificate',
            fields: {
                uid: 'Unique identifier',
                created: 'Added',
                modified: 'Modified',
                operator: 'Operator'
            },
            attnInfo: "Attach a scan of your certificate"
        },
        insurances : {
            menu: 'Insurance',
            name: 'Insurance |||| Insurance',
        },
        operators : {
            menu: 'Operators',
            name: 'Operator |||| Operators',
            search: 'Search operator',
            fields: {
                created: 'Added',
                email: 'Email',
                username: 'Username',
                emailVerified: 'Email verification',
                phone: 'Telephone',
                phoneVerified: 'Phone verification',
                firstName : 'First name',
                lastName : 'Last name',
                nickName: 'Callsign',
                ban: 'User account ban time'
            }
        },
        govs : {
            menu: 'Govs',
            create: 'New Governmental user',
            name: 'Gov |||| Govs',
            search: 'Search GOV user',
            fields: {

                created: 'Added',
                email: 'Email',
                username: 'Username',
                emailVerified: 'Email verification',
                firstName : 'First name',
                lastName : 'Last name',
                phone: 'Telephone',
                password: 'Password',
                etc: 'Advanced Params', //hmm

            },
            permissions: 'Permissions preview'
        },
        units : {
            menu: 'Units',
            name: 'Unit |||| Units',
            create: 'New unit',
            fields: {
                name: 'Name',
                fullname: 'Full name',
                type: 'Type',
                contact: 'Contact',
                created: 'Added'
            },
            data: 'Unit data from',
        },
        unitsAll: {
            fields: {
                name: 'Name',
                fullname: 'Full name',
                type: 'Type',
                contact: 'Contact',
                created: 'Added'
            }
        },
        unitGovs : {
            menu: 'UnitGovs',
            name: 'Unit-Govs role |||| Unit-Govs roles',
            create: 'New Governmental user role',
            fields: {
                role: 'Role',
                created: 'Added'
            }
        },
        orgs : {
            menu: 'Orgs',
            fields: {
                created: 'Added'
            }
        },
        orgOperators : {
            menu: 'OrgOperators',
            fields: {
                created: 'Added'
            }
        },
        logs : {
            menu: 'Logs',
            name: 'Log |||| Logs',

            fields: {
                created: 'Date'
            }
        },
        [RESOURCES.EVENT_GOVS]: {
            menu: 'Events GOV',
            name: 'Event GOV |||| Events GOV',
            live: 'Stream view',
            liveWindow: 'Server events stream',

            fields: {
                created: 'Created',
                action: 'Action',
                subject: 'Subject',
                diff: 'Changes',
                govid: 'Gov'
            }
        },
        [RESOURCES.EVENT_OPERATORS]: {
            menu: 'Events OP',
            name: 'Event OPERATOR |||| Events OPERATOR',
            live: 'Stream view',
            liveWindow: 'Server events stream',

            fields: {
                created: 'Created',
                action: 'Action',
                subject: 'Subject',
                diff: 'Changes',
                operatorid: 'Operator'
            }
        },
        missions : {
            menu: 'Missions',
            name: 'Mission |||| Missions',
            create: 'New mission',
            //createHelp: 'Aby dodać geometrię, najpierw utwórz misję (flow to fix)',
            duration: "Mission duration:",
            startTimeNormalizeWarning: "The mission duration can not be shorter than 5 minutes! To keep this condition, " +
                "changed the start time to %{time}.",
            startTimeMinNormalizeWarning: "The mission start time cannot be in the past! To keep this condition, " +
                "changed the start time to %{time}.",
            stopTimeNormalizeWarning: "The mission duration can not be shorter than 5 minutes! To keep this condition, " +
                "changed the end time to %{time}.",
            recommendedStartInfoVLOS: 'A VLOS mission should be submitted for acceptance minimum %{vlosTime} working days before the start',
            ...(ACM_INFOBOXES_ENABLED && {recommendedStartInfoVLOSwithPriv: 'For automatic missions (ACM) there are no restrictions on the beginning of the mission. ' +
                'VLOS Missions not accepted automatically, must be submitted for approval at least %{vlosTime} business days before the start. ' +
                'BVLOS Missions must be submitted for approval at least %{bvlosTime} days before the start.'}),
            recommendedStartInfoBVLOS: 'A BVLOS mission should be submitted for acceptance minimum %{bvlosTime} days before the start',
            ...(ACM_INFOBOXES_ENABLED && {recommendedStartInfoBVLOSwithPriv: 'For automatic missions (ACM) there are no restrictions on the beginning of the mission. ' +
                'VLOS Missions not accepted automatically, must be submitted for approval at least %{vlosTime} business days before the start. ' +
                'BVLOS Missions must be submitted for approval at least %{bvlosTime} days before the start.'}),
            loading: "Loading mission",
            attnInfo: "If any additional documents are required to complete the flight, please attach them",
            ...(ACM_INFOBOXES_ENABLED && {attnInfoWithPriv: 'If applicable, attach the UAV flight permission received ' +
                    'from the airspace or aerodrome managing'}),
            timeDialogTitle: "Official terms",
            timeDialogLabel: ASM_NEW_LABELS ?
                "Minimal time to consider mission acceptance by PANSA UAV Operations’ Management Department is not achieved. For VLOS type missions %{vlosTime} working days are required, " +
                "for BVLOS type mission %{bvlosTime} days. Notice that mission can be not considered!"
                :
                "Minimal time to consider mission acceptance by ASM is not achieved. For VLOS type missions %{vlosTime} working days are required, " +
                "for BVLOS type mission %{bvlosTime} days. Notice that mission can be not considered!",
            noConflictsDialogTitle: "No conflicts",
            noConflictsDialogLabel: "VLOS mission does not contain conflicts. There is no need to get acceptance for it. At the flight day check airspace limitations amd for common safety make a mobile app fligth check-in.",
            ...(MISSION_112_ENABLED && {abuse112: "Caution! Mission is tagged as privileged 112, nonetheless the operator does not have priviliges to perform " +
                "such missions. Probably the privilege has been revoked after sending mission for acceptance."}),
            steps: {
                missionNameAndType: 'Name and type of the mission',
                duration: 'Duration',
                additionalInfo: 'Additional information',
                target: 'Flight category',
                aircrafts: 'Aircrafts',
                observers: 'Observers and attachments',
                graphicalMissionEditor: 'Mission graphic editor',
                ...(MISSION_112_ENABLED && {mission112: '112 mission'}),
            },
            fields: {
                uid: 'Unique identifier',
                approval : 'Approval No',
                additionalDocs: MISSION_DROPDOWN_SUPPORTED_TYPES_ENABLED ?
                    'Additional documents (*.jpg, *.pdf)'
                    :
                    'Additional documents',
                created: 'Added',
                modified: 'Modified',
                start: 'Start (local time)',
                stop: 'End (local time)',
                status: 'Status',
                name: 'Name',
                type: 'Type',
                mcmt: "Minimum Continuous Mission Time in minutes (MCMT)",
                etc_pilotNr: "Pilot number",
                etc_operatorNr: "Operator number",
                etc_fallbackDates: 'Additional dates',
                etc_shortDescription: 'Brief description',
                etc_activityType: 'Flight category',
                etc_puropse_sport: 'Open',
                etc_purpose_other: 'Specific or certified',
                etc_puropse_training: 'Specific - NSTS or STS',
                etc_purpose_spec: 'Specific - ULC permission',
                etc_puropse_auto: 'Specific - LUC',
                etc_purpose_operational: 'Certified',
                etc_observers: 'Observers',
                etc_additionalObservers: 'Additional observers',
                etc_observerName: 'Name and surname of the observer',
                etc_observerPhone: 'Phone of the observer',
                etc_drones: 'Type and Quantity of aircraft',
                etc_smbt: 'Supplementary Mission Battery Time in minutes (SMBT)',
                etc_noConflictsDialogYesUnderstood: 'Applies to the mission for which conflicts with other spaces were not detected at the planning stage - an understanding of the lack of conflicts in the declared area and time was confirmed.',
                etc_noConflictsDialogNotUnderstood: 'Applies to the mission for which conflicts with other spaces were not detected at the planning stage - an understanding of the lack of conflicts in the declared area and time was not confirmed!',
                ...(MISSION_112_ENABLED && {etc_is112: '112 privileged mission'}),
                etc_isKSID: 'Mission KSID',

            },
            labels: {
                contactData: 'Contact details',
            },
            editor: 'Mission editor',
            status: {
                accepted: 'Accepted mission |||| Accepted missions',
                rejected: 'Rejected mission |||| Rejected missions',
                pending: 'Awaiting mission |||| Awaiting missions',
                unknown: 'Mission with unknown status |||| Missions with unknown status',
                created: 'Created mission |||| Created missions'
            },
            notifications: {
                statusChangedMsg: 'The status of the mission has been changed',
                legsTooLargeErr: 'At least one mission sector is too large',
                legsOutsideBorder: 'At least one mission sector is outside Poland border',

                legsNotValidErr: 'At least one mission sector has invalid geometry',
                saveFailedErr: 'Error during saving mission',
                awaitingForResolveMsg: 'Mission saved, counting conflicts...',
                cloneFailedErr: 'The mission could not be cloned',
                cloning: 'The mission is cloning',
                holidaysCheckError: "Cannot check holidays. Official term calculation not possible."
            },
            tooltips: {
                acceptSystemDisabled: "Enter phone, callsign, first and last name in settings to enable sending mission for acceptance",
                acceptEditorDisabled: "Save changes or draw at least one mission trajectory area with set top and bottom height boundary, and next save to enable sending mission for acceptance",
                phoneInputDisabled: "To enter or change the phone number go to profile settings",
                noConflictsDisabled: "Mission has no conflicts, no need for acceptance",
                name: "Make sure that the name best describes the nature of the operation",
                smbt: "Maximum holding time over the planned one given in minutes in case you have to wait for the permission to fly through the zone",
                mcmt: "Minimum acceptable time of continuous mission to recognize the mission as successful",
                shortDescription: "If there is something special or unusual in your mission, describe it here",
                activityType: "The purpose of the flight in accordance with the classification specified in official regulations",
                aircrafts: "Please select aircrafts that will be used for the mission",
                observers: "If the flight requires additional observers (e.g. for EVLOS flights), please enter them together with their current phone number. " +
                    "Please make sure that the number you have entered is correct",
                is112: "You have privileges to enforce your mission with 112 status. In case of enabling this feature, " +
                    "ATC service will take your mission as priority.",
                pilotNr: "Enter pilot number in xxx-xx-xxxxxxxxxxxx",
                operatorNr:"Enter operator nubmer in xxxxxxxxxxxxxxxx (16 chars) format"
            }
        },
        zones : {
            menu: 'Airspace',
            name: 'Airspace |||| Airspaces',
            create: 'New airspace',
            createHelp: 'To add geometry, first create a mission',
            fields: {
                name: 'Name',
                type: 'Type',
                created: 'Added',
                unitName: 'Unit',
                country: 'Country',
                othername: 'Othername',
                start: 'Start',
                stop: 'End',
                activity: 'Activity',
                source: 'Source',
                contact: 'Contact',
                restriction: 'Restrictions',
                min: "Lower altitude boundary (ft AMSL)",
                max: "Upper altitude boundary (ft AMSL)",
                description: {
                    pl: "Description (PL)",
                    en: "Description (EN)"
                }
            },
            zoneLegsCnt: 'Conflicts',
            editor: 'Airspace editor',
            notifications: {
                saveFailedErr: 'Airspace write error',
                oneAreaRequiredErr: 'The airspace must have exactly one area',
                wrongMinMaxErr: 'Bad values of minimum / maximum',
                deleteFailedErr: 'Zone delete error. %{status}'

                //awaitingForResolveMsg: 'Strefa zapisana, liczenie konfliktów...',
            },
            useDescriptionTemplate: "Use a template for zone desciption",
            tooltips: {
                noPermissionsToArchive: 'You have no permissions to delete the zone'
            }
        },
        zoneTypes: {
            menu: "Zone Types",
            name: "Zone Type |||| Zone Types",
            fields: {
                name: 'Name',
                description: {
                    pl: "Description (PL)",
                    en: "Description (EN)"
                }
            }
        },
        pois : {
            //POI naming to fix?
            menu: 'POIs',
            name: 'POI |||| POIs',
            create: 'New POI',
            //createHelp: 'To add geometry, first create a mission',
            fields: {
                name: 'Name',
                type: 'Type',
                created: 'Added',
                unitName: 'Unit',
                country: 'Country',
                othername: 'Othername',
                start: 'Start',
                stop: 'End',
                activity: 'Activity',
                source: 'Source',
                contact: 'Contact',
                description: 'Description',
                restriction: 'Restrictions'
            },
            editor: 'POI editor',
            notifications: {
                //saveFailedErr: 'Airspace write error',
                //oneAreaRequiredErr: 'The airspace must have exactly one area',
                //wrongMinMaxErr: 'Bad values of minimum / maximum',

                //awaitingForResolveMsg: 'Strefa zapisana, liczenie konfliktów...',
            },
        },

        faas : {
            menu: 'FAA NOTAMs',
            name: 'NOTAM |||| NOTAMs',

            fields: {
                created: 'Added',
            }
        },
        zoneLegs : {
            menu: 'ZoneLegs(PL)',
            fields: {
                created: 'Added',
                status: 'Status',
            },
            zoneName : 'Airspace',
            unresolvedConflicts: 'Unresolved conflicts',
            allConflicts: 'All conflicts',
            conflicts: 'Conflicts'

        },
        legs : {
            menu: 'Legs',
            fields: {
                created: 'Added',
            },

        },
        notams: {
            menu: 'Notams',
            name: 'Notam |||| Notams',
            create: 'New Notam',
            publishing: 'Publishing NOTAM',
            validation: {
                lower: 'Lower limit cannot be greater or equal to upper limit',
                upper: 'Upper limit cannot be smaller or equal to lower limit',
            },
            activitiesReminder: 'Do not forget to add NOTAM activities!',
            fields: {
                created: 'Added',
                seriesLine: 'Series',
                series: 'Series, number in sequence, year of issue',
                type: 'Type',
                seriesref: 'Series, number in sequence, year of issue of referenced NOTAM',
                qLine: 'Q line',
                q: {
                    fir: 'FIR',
                    code: 'Code',
                    traffic: 'Traffic',
                    purpose: 'Purpose',
                    scope: 'Scope',
                    lower: 'Lower limit',
                    upper: 'Upper limit',
                    coords: 'Coordinates, radius'
                },
                aLine: 'A line',
                a: 'Identification of ICAO location indicator in which the facility, airspace\n' +
                    'or condition reported on is located',
                bLine: 'B line',
                b: 'Valid from',
                cLine: 'C line',
                c: 'Valid to',
                dLine: 'D line',
                d: 'Diurnal, time schedule',
                actDefName: 'Activities definitions',
                actDef: {
                    periodMode: 'Period mode',
                    repeatMode: 'Repeat mode',
                    begin: 'Begin',
                    end: 'End',
                    months: 'Months',
                    days: 'Days',
                    weekdays: 'Weekdays',
                    slotMode: 'Hour slot mode',
                    hourSlots: 'Hour slots',
                },
                eLine: 'E line',
                e: 'Text of NOTAM',
                fLine: 'F line',
                f: 'Lower limit',
                gLine: 'G line',
                g: 'Upper limit',
                preview: 'NOTAM preview',
                activationsCalc: 'Calculated activities',
                activations: 'Activities',
                zoneDataLine: 'PansaUTM zone data',
                zoneReferenceType: 'Zone reference type',
                zonename: 'Zone name',
                zonetype: 'Zone type',

                activityRepeatModes: {
                    days: 'Days',
                    weekdays: 'Weekdays',
                    months: 'Months',
                    nonStop: 'Non-stop',
                },
                activityTimeModes: {
                    single: 'Single period',
                    repeat: 'Repeating period'
                },
                activitySlotModes: {
                    h24: 'H24',
                    slots: 'Slots'
                },
                zoneReference: {
                    new: 'New zone',
                    exist: 'Existing zone'
                },
                hourSlots: "Hour slots",
                description: {
                    pl: "Description (PL)",
                    en: "Description (EN)"
                },
                deactivated: 'DEACTIVATED',
            }
        },
        [RESOURCES.ACM_RULES]: {
            menu: 'ACM',
            name: 'ACM rule |||| ACM rules',
            createException: 'Create exception',
            create: 'Create ACM rule',
            defaultRule: 'Default rule',
            exceptionRule: 'Exception from default rule |||| Exceptions from default rule',
            acmAsmToggle: 'Use ACM for mission acceptance',
            acmTwrToggle: 'Use ACM for checkin acceptance on TWR',
            acmRpaDialogTitle: 'ACM settings for RPA',
            acmRpaDialogLabel: 'Current configuration for %{rpa} in %{ctr}',
            acmTermsCreatorDialogTitle: 'Settings for ACM automatic terms',
            acmTermsCreatorDialogLabel: 'Configuration for %{ctr}',
            acmTermsCreatorDialogSubheader: 'MANUAL: Enter terms definitions for automatic ACM approvals. Default ' +
                'terms are mandatory. In case of leaving terms blank for individual RPA types (colors), ACM module ' +
                'will use the defualt terms.  All entered terms will be assigned to all RPA areas in %{ctr} according ' +
                'as type. Edition on individual RPA ACM terms will be possible on further stage.',
            acmRulesTablePreview: '%{ctr} ACM rules table view',
            editingLocked: 'Editing locked. First define ACM default rules table for %{ctr}.',
            infoOnlyActive: 'Only active rules are shown',

            fields: {
                created: 'Created',
                designator: 'Location',
                start: 'Hour slot (UTC)',
                blue: 'Blue RPA',
                green: 'Green RPA',
                yellow: 'Yellow RPA',
                red: 'Red RPA',
                parentid: 'Default rule id',
                etc: {
                    terms: {
                        def: 'Default',
                        red: 'In red RPA',
                        yellow: 'In yellow RPA',
                        green: "In green RPA",
                        blue: "In blue RPA",
                        header: "ACM automatic approval terms definitions",
                        description: {
                            pl: "Definition (PL)",
                            en: "Definition (EN)"
                        }
                    }
                }
            },
            notifications: {
                feedingAcm: 'Supplementing defaults',
                errorOnLoading: 'Cannot load ACM rules',
                savingAcmRpa: 'Saving changes in ACM',
                errorOnSaving: "Cannot save ACM rules. %{status}",
            },
            validation: {
                onlyOneTerms: 'For particular RPA type of ACM terms all language definition required or all blank',
                defaultsEmpty: 'Default rules cannot be blank',
            }
        },
    },
    myroot: {
        rodo: 'RODO clause',
        action: {
            clear: 'Clear',
            clearForm: 'Reset form',
            execute: 'Execute',
            finish: 'Finish',
            reset: 'Reset',
            send: 'Send',
            sendForAccept: 'Send for accept',
            settings: 'Settings',
            notShowAgain: 'Don\'t show again',
            sortBy: "Sort by",
            order: "Order",
            sortAscending: "Ascending",
            sortDescending: "Descending",
            viewOnly: "View only",
            understood: "Understood",
        },
        admin: {
            actionsLabel: 'Actions',
            actions: {
                resendEmail: 'Send verification email again',
                banUser: 'Ban user account',
                unbanUser: 'Remove user account ban',
                setPrivilege112UAVOandSUAVO: 'Add privileges UAVO 112 and Super Pilot',
                setPrivilegeSUAVO: 'Add privilege UAVO Super Pilot',
                unsetPrivilege112UAVO: 'Revoke privilege UAVO 112',
                unsetPrivilegeSUAVO: 'Revoke privilege UAVO Super Pilot'
            },
            logs: 'Logs',
            messages: {
                resendEmailSuccess: 'Verification email sent again',
                resendEmailError: 'Could not send verification email again. Status: %{status}',
                banUserSuccess: 'User account ban issued for 100 years',
                banUserError: 'Could not ban user account. Status: %{status}',
                unbanUserSuccess: 'User account ban has been removed',
                unbanUserError: 'Could not remove user account ban. Status: %{status}',
                priviSetSuccess: 'Privilege updated',
                priviSetError: 'Could not update privilege. Status: %{status}'
            },
            tools: 'Admin tools'
        },
        appBar: {
            language: 'Language',
            help: 'Help'
        },
        message: {
            saving: "Saving",
            wait: "Please wait...",
            logoutTimeout: 'You will be logged out in a while...',
            actionExecute: 'Executing action'
        },
        navigation: {
            back: 'Back',
            more: 'More',
            skip: 'Skip',
        },
        httpCode : {
            401: '401: Authorization error'
        },
        dialog : {
            ok : 'OK',
            cancel : 'Cancel',
            testMsg : 'Test Message',
            testTitle : 'Test Title',
        },

        auth: {
            accountSettings: 'Account settings',
            accountData: 'Account data',
            username: 'Username',
            email: 'Email',
            password: 'Password',
            newPassword: 'New password',
            emailOrUsername: 'Email or username',
            signIn: 'Login',
            signingIn: 'Login in',
            signUp: 'Register',
            jumpToAccountSettings: "Jump to account settings",

            login: 'Login',
            logout: 'Logout',

            logIn: 'Login',
            logOut: 'Logout',

            register: 'Register',
            resetPassRq: 'Password reset',
            resetPass: 'Password reset',
            reject: 'Reject',
            retype : 'Repeat password',
            confirm : 'Confirm',
            verify : 'Verify',
            createNickName:'Create callsign',

            matchError : 'The password does not match',
            authError: 'Authentication error, please try again',
            registerRqError: 'Registration data error',
            registrationMailSentMsg : 'The registration mail has been sent to the address %{email}, check your email to continue.',
            registrationVerificationMsg : 'Optionally, you can enter the received link to verify your registration',
            verificationLink : 'verification link',
            passwordResetRequestMsg : 'Enter the email associated with the account to reset your password',
            passwordResetRequestFailMsg : 'Password reset error',
            passwordResetDataMsg : 'Enter a new password',
            passwordFinalResetPassSuccess : 'The password has been changed, go to the login page.',
            passwordResetMailSentMsg : 'The password reset email has been sent to the address %{email}, check your email to continue.',
            passwordVerificationMsg : 'Enter the received link to verify new password',

            passwordResetSuccessMsg : 'The password has been reset, log in to proceed.',
            onEmailVerifiedMsg : 'The mail has been verified, log in to continue.',
            accountReadMsg : 'Account details downloaded',
            accountReadError : 'Account read error',
            accountUpdateFailMsg : 'Error updating account',
            accountUpdatedMsg : 'Account details have been updated',
            accountUpdatedNothingMsg : 'Account details has not been updated',
            accountUpdatedNicknameMsg: 'First name, last name and callsign have been updated',
            privacyPolicyInfo: 'Information about the processing of personal data',
            phoneChangeRequestSuccessMsg: 'A request to change the phone number has been sent. Wait for the SMS with the verification code',
            phoneVerifySuccessMigrationMsg: "Found mobile user with same phone number. Your accounts have been merged. In a while you will be logged out - please login again in all your applications.",
            phoneChangeRequestFailMsg: 'Error requesting a phone number change!',
            phoneVerifySuccessMsg: "The phone number has been verified successfully",
            phoneVerifyFailMsg: "Incorrect verification code. Try again!",
            editionLocked: "Edition is blocked. At least one application is pending in verification.",
            editionLockedOffline: "Edition is blocked. You're probably offline. Check connection and re-open account settings window.",
            sendLockedPhoneNotVerified: "Phone number is not verified. Sending missions or applications for acceptance " +
                "will be disabled.",
            sendLockedNoFullName: "First name and last name is not submitted. Sending missions or applications for " +
                "acceptance will be disabled.",
            sendLockedNoCompany: "Company data is not submitted. Sending applications for " +
                "acceptance as company will be disabled.",
            sendLockedNoNickName: "Unique callsing is not submitted. Sending missions or applications for " +
                "acceptance will be disabled.",
            nickNameDialogTitle: "Choose a unique callsign",
            nickNameDialogHeader: "Callsign is used to recognize your missions and drones by Air Traffic Control. " +
                "Further callsign change is only possible if you change your first name or last name.",
            termsOfUse: "Terms of use",
            disclaimer: "Disclaimer"


        },
        configuration: {
            about: 'About',
            aboutSystem: 'About system',
            appSettings: "System settings",
            app: "System",
            commercialServices: "Commercial services",
            commercialServicesSettings: "Commercial services settings",
            notificationsSettings: "Notifications settings",
            notifications: "Notifications",
            notificationsInfoBoxSounds: "INFO BOX component sounds",
            notificationsResetInfoBoxHistory: "Reset INFO BOX seen history",
            notificationsResetInfoBoxHistoryDone: "INFO BOX seen history reset successful",
            notificationsAlertBoxSounds: "ALERT BOX component sounds",
            view: "View",
            viewSettings: "View settings",
            viewDashResetDone: 'Dashboard default layout restored',
            viewDashboardLayoutReset: 'Restore dashboard default layout'
        },
        dashboard : {
            title : 'Welcome to dashboard',
            fetchError : 'Fetching dashboard error',
            ctrZonesHeader : 'CTR Zones',
            accountForm : {
                username : 'Username',
                usernameDscr : 'The unique account identifier',
                email : "Email",
                emailDscr : "User's e-mail account",
                phone : "Phone",
                phoneDscr : "GSM phone number",
                phoneVerified: "Phone verified",
                personalData : "Personal data",
                personalDataDscr : "Personal data with respect to the GDPR (Global Data Protection Regulation)",
                dob : "Date of birth",
                firstName : "First name",
                lastName : "Last name",
                emailVerified : 'Email verified',
                smsCode : "SMS code",
                smsCodeSentAt: "SMS code sent:",
                smsCodeDscr : "Enter the received SMS code at:",
                smsCodeAgain: "Send code again",
                smsCodeResendLocked: "To send verification sms again wait 2 minutes, next logout and login again",
                changeNumber: "Change phone number",
                smsVerification: "SMS verification",
                territoryOfOperation: "Territory of operations",
                providedServices: "Provided services",
                nickName: "Callsign"
            }
        },
        zoneConflictResolver :{
            issuedBy: 'Issued by:',
            unitName: 'Unit name:',

            unitApprovalRejected: 'Rejected',
            unitApprovalConfirmed: 'Accepted',
            rejectDialogTitle: 'Mission rejected',
            rejectDialogDefaultText: 'Reason for rejection: ',
            rejectDialogLabel: 'Additional information about the rejection',
            rejectBtn: 'Reject',
            acceptDialogTitle: 'Acceptance of the mission',

            acceptDialogLabel: 'Additional information about acceptance: ',
            acceptDialogOverrideMsg: 'Mission requires additional approvals, accept it only if the operator has confirmed their possession',
            acceptDialogPartialMsg: 'Mission requires additional approvals, you can only accept your zones',

            acceptMineLabel: 'Accept my airspaces',
            acceptFullLabel: 'Accept missions',
            acceptBtn: 'Accept',
        },
        company: {
            data: "Company data",
            name: "Company name",
            name2: "Company",
            tax: "Tax ID number",
            commercialRodo: "I agree to the processing for marketing purposes, including profiling, and for analytical purposes, any of my personal data that I leave as part of the commercial services for the purpose of marketing campaigns and services of third parties and by entities cooperating with Droneradar Sp. z o.o. and trusted partners.",
            commercialRodoHeader: "Marketing agreement",
            commercialRodoNotChecked: "The service will be disabled! Please check marketing agreement first...",
            workingHours: {
                name: "Working hours",
                start: "from",
                stop: "to"
            },
            mainDisclaimer: "All personnel directly involved in the operations are competent to perform their tasks, and the UAS will be operated only by remote pilots with the appropriate level of competency",
            disclaimers: "Disclaimers",
            services: {
                techInspection: "Technical inspections",
                workProgress: "Work progress reports",
                patrol: "Surveillance flights",
                airMonitoring: "Air quality monitoring",
                agriculturalInspection: "Agricultural cultivation inspections",
                forestInspection: "Forest area inspections",
                waterInspection: "River troughs and bays state inspections",
                modelling: "3D modelling",
                antiqueInspection: "Antique supervision",
                animalsCounting: "Animal counting",
                cargoDrop: "Cargo drops",
                movie: "Shootage",
                movieHD: "Shootage HD",
                movie4K: "Shootage 4K",
                movieNight: "Shootage with night vision (NOD, NVG)",
                streaminghHD: "Live-Streaming half-HD (720p)",
                streamingHD: "Live-Streaming HD (1080p/1080i)",
                streaming4K: "Live-Streaming 4K",
                weddings: "Shootage weddings",
                energyInspections: "High voltage lines inspections",
                gasInspection: "Gas pipes inspections",
                oilInspection: "Oil pipes inspections",
                heatInspection: "Heat pipes inspections",
                tracking: "Detective tracking",
                ortofoto: "Orthophotomaps generation",
                ortotermo: "Orthothermomaps generation",
                thermovision: "Facility thermal inspections (thermovision)",
                searchThermovision: "Search flights with thermovision usage",
                search: "Search flights",
                searchNightVision: "Searchflight with night vision usage (NOD, NVG) ",
                measurement: "Measurement flights (capacity, area)",
                race: "Drone race",
                lightShow: "Drone light show",
                spectometry: "Spectometry",
                agriculturalSprays: 'Agricultural sprays',
                decontamination: 'Decontamination',
                other: "Other",
            }
        },
        address: {
            address : "Address",
            town : "City",
            zipcode : 'ZIP code',
            country : 'Country',
            addressData : "Address data",
            addressDscr : "Address data",
        },
        validation : {
            required : "Required",
            optional : "Optional",
            //common validation for custom field types
            nullableJSONObject: "Must be valid JSON object or null",
            JSONObject: "Valid JSON object required",
            usernameOrEmailFormat: 'Valid email address or user name (account name) required',
            whiteSpaceOnEdgeNotAllowed: 'White characters are not allowed at the beginning or the end (e.g. space)',
            nameFormat: 'Only letters allowed, and between words: dot, dash, apostrophe or space',
            passwordFormat: `Required at least: 8 characters, one letter, one digit and one special character from set: !@#$%^&*\\({}-+=,.<>;:\'"/`
        },
        info: {
            vlosSelection: {
                title: "<b>VLOS</b>",
                content: INFOBOX_NEW_MISSION_INFOS_ENABLED ?
                    'In accordance with commision implementing regulation (EU) 2019/947 of 24 may 2019 of the rules and procedures for the operation of unmanned aircraft. For more information please visit <a href="https://www.pansa.pl/drony" target="_blank">https://www.pansa.pl/drony</a>'
                    :
                    "Currently supported processes:\n" +
                    "\n" +
                    "- assistance in obtaining permission for a flight within visual line os sight (VLOS) in controlled spaces (CTR)\n" +
                    "\n" +
                    "- EVLOS flight: VLOS flight with additional observers in the controlled space"
            },
            ...(ACM_INFOBOXES_ENABLED && {vlosSelectionWithPriv: {
                    title: "<b>VLOS flights with ACM</b>",
                    content:  "You are logged as a %{privileges} user. You will receive automatic approval (ACM) of the " +
                        "mission in the CTR, as the RPA volume is available. Automatic approvals can only be issued for missions " +
                        "within the horizontal and vertical borders of RPA. Rules for granting automatic consents and capacity " +
                        "for RPA airspaces are available in the Help tab. For missions that are not subject to automatic consent, " +
                        "wait for the mission to be approved by the DTM Supervisor."
                }}),
            bvlosSelection: {
                title: "<b>BVLOS</b>",
                content: INFOBOX_NEW_MISSION_INFOS_ENABLED ?
                    'In accordance with commision implementing regulation (EU) 2019/947 of 24 may 2019 of the rules and procedures for the operation of unmanned aircraft. For more information please visit <a href="https://www.pansa.pl/drony" target="_blank">https://www.pansa.pl/drony</a>'
                    :
                    "Currently supported processes\n" +
                    "\n" +
                    "- help in obtaining permission to fly beyond visual line of sight (BVLOS)"
            },
            ...(ACM_INFOBOXES_ENABLED && {bvlosSelectionWithPriv: {
                    title: "<b>BVLOS flights with ACM</b>",
                    content:  "You are logged as a %{privileges} user. BVLOS requests are not supported " +
                        "in the automatic acceptance system ACM. Wait for the manual mission approval by the DTM " +
                        "Supervisor after submitting the application."
                }}),
            dronePhotoSerial: {
                title: "<b>Authenticity confirmation</b>",
                content: "Please attach photo with serial number of the drone. " +
                    "If the drone is a non-serial device (DIY), please attache photo of the serial number of the flight controller (it may be a picture from the screen of the program that supports the controller)."
            },
            missionEditor: {
                title: "<b>Draw your mission.</b>",
                content: "Remember that your mission can consist of several elements: circles, polygons and routes. " +
                    "For each element, specify the minimum and maximum flight altitude. " +
                    "Try to avoid crossing many airspaces. " +
                    "At the top of the map there are switches which can be used to change the parameters of the displayed map and layer."
            },
            targetBvlos: {
                title: "<b>BVLOS</b>",
                content: 'In accordance with commision implementing regulation (EU) 2019/947 of 24 may 2019 of the rules and procedures for the operation of unmanned aircraft. For more information please visit <a href="https://www.pansa.pl/drony" target="_blank">https://www.pansa.pl/drony</a>'
            },
            targetVlos: {
                title: "<b>VLOS</b>",
                content: 'In accordance with commision implementing regulation (EU) 2019/947 of 24 may 2019 of the rules and procedures for the operation of unmanned aircraft. For more information please visit <a href="https://www.pansa.pl/drony" target="_blank">https://www.pansa.pl/drony</a>'
            },
            alert: {
                certChange: NEW_ALERTBOX_ENABLED ?
                    "<b>Verification status changed (certificates)</b>" +
                    "<br/>" +
                    "<br/>" +
                    "Certificate: <a href='%{link}'>%{name}</a>" +
                    "<br/>" +
                    "New status: <span style='text-transform: uppercase; font-weight: 500'>%{status}</span>" +
                    "<br/>" +
                    "<code>%{time}</code>"
                    :
                    "<b>Certificate application status change. Refresh certificates list.</b>",
                droneChange: NEW_ALERTBOX_ENABLED ?
                    "<b>Verification status changed (drones)</b>" +
                    "<br/>" +
                    "<br/>" +
                    "Drone: <a href='%{link}'>%{name}</a>" +
                    "<br/>" +
                    "New status: <span style='text-transform: uppercase; font-weight: 500'>%{status}</span>" +
                    "<br/>" +
                    "<code>%{time}</code>"
                    :
                    "<b>Drone application status change. Refresh drones list.</b>",
                missionChange: NEW_ALERTBOX_ENABLED ?
                    "<b>Acceptance status changed (missions)</b>" +
                    "<br/>" +
                    "<br/>" +
                    "Mission: <a href='%{link}'>%{name}</a>" +
                    "<br/>" +
                    "New status: <span style='text-transform: uppercase; font-weight: 500'>%{status}</span>" +
                    "<br/>" +
                    "<code>%{time}</code>"
                    :
                    "<b>Mission status change. Refresh missions list.</b>",
            }
        }
    },
    error: {
        //backend translatable errors fixme after backend review...
        EmailNonUnique : 'Email already registered',
        ValidationError : 'Validation error',
        FailedAuthCheck : 'Failed Authentication Check',
        INVALID_TOKEN : 'Invalid token',
        LOGIN_FAILED: 'Login error',
        EMAIL_NOT_FOUND : 'Email not registered',
        LOGIN_FAILED_EMAIL_NOT_VERIFIED: 'The email has not yet been verified',
        NO_RESPONSE: 'No response from the server',
        AUTHORIZATION_REQUIRED : 'No authorization',
        PhoneFormat: 'Wrong phone number format. 11 to 14 digits required.',
        SmsCodeFormat: 'Invalid sms code format. Only digits allowed.',
        EMAIL_CHANGE_EMAIL_EXISTS: 'Email already registered',
        TaxNumber: "Wrong tax number format. 10 digits required without hyphens.",
        PersonalOrCompanyDataForCAA: "Fill in personal or company data in account settings to enable selection.",
        UserStateCheck: "Cannot check user's applications state. %{error}.",
        allCheckboxesRequired: "All disclaimers agree is required."


    }
};

export default messages;



