import Configs, {FEATURES_V1_2_ENUMS, patchVersion} from "./Configs";
import {TIMER_TICK_RQ} from './LogicV1Redux/constants/TickConstants';
import {STORE_SOCKET_DATA} from './LogicV1Redux/constants/SocketConstants';
import {STORE_ALL_CHECKINS_V2} from './LogicV1Redux/constants/CheckinsV2Constants';

import jsLogger from "js-logger";
export const Logger = jsLogger;

Logger.useDefaults();

export const LOGGERS = {
    MAIN:'Main',
    ZONES_DATA:'ZonesData',
    ZONES_SAGA:'ZonesSaga',
    ZONE_ACTIVITY:'zoneActivity',
    ALERTS_DATA:'AlertsData',
    CHECKIN_DATA:'checkinData',

    CHECKINFLOW:'CheckinFlow'
};

export const useMocks = {
    // zoneTypesV2 : false,
    // zonesV2: false,
    // checkinsV2 : false,
    // alertsV2 : true,
    // kpIndexV1 : false,
    // geocageV1 : false,
    // what3wordsV1 : false,
};

const enableLogs = false;

export const useSimpleReduxLogger = false; //process.env.REACT_APP_REDUX_LOGGER;
export const useAdvancedReduxLogger = false; //process.env.REACT_APP_REDUX_LOGGER_ADVANCED; //only for react native apps

//https://www.npmjs.com/package/redux-logger#log-everything-except-actions-with-certain-type
//const blacklistedActions = [TIMER_TICK_RQ, STORE_SOCKET_DATA, STORE_ALL_CHECKINS_V2];
const blacklistedActions = [TIMER_TICK_RQ, STORE_SOCKET_DATA];
export const reduxLoggerPredicate =
    (getState, action) => {
    return blacklistedActions.indexOf(action.type) === -1;
};

//disabling logs for prod builds..
//if (process.env.REACT_APP_DISABLE_LOGS) {
console.log(patchVersion);

// if(Configs.enabledFeatures.some(feature=>Object.values(FEATURES_V1_2_ENUMS).includes(feature))) {
//     console.warn(`PansaUTM v1.2 features enabled:\n${Configs.enabledFeatures.join('\n')}`);
// }

if (!enableLogs) {
    console.debug = () => {};
    console.log = () => {};
    console.warn = () => {};
    console.dir = () => {};
}

if (enableLogs) {
    Logger.setLevel(Logger.ERROR);
    //Logger.get(LOGGERS.ZONES_DATA).setLevel(Logger.ERROR);

} else {
    Logger.setLevel(Logger.ERROR);
}
