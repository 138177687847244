//v1.0.4

import {InspectTypes} from "../../modules2lib/common/";
import { functionalColors, ICONS} from "../../gl_map/glSetups/oldCommon/commonInits";
import {FlightStatusesV2, LostControlAlert} from '../../LogicV1Redux/constants/CheckinsV2Enums'
import {ICO_SIZES} from "../../gl_map/glSetups/oldCommon/deckInits";
import moment from "moment";
import {turfCircle, turfDisjoint, turfWithin} from "../../gl_map/glSetups/oldCommon/commonTurf";
import {isoString2UnixTime, unixTimeNow} from "./TimeConverters";
import {isInMyJurisdiction} from "./MySelf";
import has from "lodash.has";
import {LatLng2Dms} from "./GeoConverters";
//import DataProvider from '../../dr_ra2/MyDataProvider'

import {polygon as turfPoly} from "@turf/helpers";
import {get} from "lodash";

import {FEATURES_V1_2_ENUMS, enabledFeatures, NEW_PANDORA_TIMEOUTS_BEHAVIOUR} from "../../Configs";

const OPERATION_CANCEL_IN_CTR = enabledFeatures.includes(FEATURES_V1_2_ENUMS.OPERATION_CANCEL_IN_CTR);

//fixes for wraps,
export function checkinRecordFromRawData(el){
    return el.inspectData.checkinRecord;
}

export function checkinGeojsonFromRawData(el){
    return el.geojsonFeature;
}

export function checkinInspectDataFromRawData(el){
    return el.inspectData;
}

//see store notes -> current selection is passed from inspect data for now
export function processCheckinsV2ToDeck({selectedCheckinV2, rawData, bottomCutoff = Number.MIN_SAFE_INTEGER, topCutoff = Number.MAX_SAFE_INTEGER}) {

    let icos = [], points = [], paths = [], polys = [], texts = [];

    //console.warn('=========process checkins, inspect data', selectedCheckinV2);

    //console.log('checkins', checkins);

    // geometry
    //     :
    // {coordinates: Array(2), type: "Point"}
    // properties
    //     :
    // {height: 150, id: 5072, last_mod_atm_user: null, modified: 1522281738.90293, panic: null, …}

    rawData.forEach((el) => {

        try {

            const checkin = checkinRecordFromRawData(el);
            const geojson = checkinGeojsonFromRawData(el);
            const inspectData = checkinInspectDataFromRawData(el);
            //console.log(checkin);
            let checkinIco = {}; //Object.assign({}, sampleIco);

            const isSelected = (selectedCheckinV2 && selectedCheckinV2.checkinRecord.uid === checkin.uid);

            checkinIco.icon = checkinV2Status2ico(inspectData, isSelected);

            checkinIco.position = geojson.coordinates;

            //hmm, fixme
            checkinIco.position[2] = 100; //checkin.properties.height; //AGL/AMSL fixme!

            checkinIco.size = ICO_SIZES.CHECKIN;
            checkinIco.inspectData = inspectData;

            inspectData.__outOfBoundary = (checkin.__max < bottomCutoff);

            if (inspectData.__lostControl) {
                console.warn('draw __lostControl removed!!, to optimize')
                let panicPoly = {};

                const {droneLostBattery, droneLostSpeed} = checkin.etc;
                const opRadius = droneLostSpeed * (droneLostBattery/60) * 1000; //meters
                panicPoly.polygon = turfCircle(checkinIco.position, opRadius);
                panicPoly.elevation = 10000 ; //AGL/AMSL fixme!!
                panicPoly.fillColor = functionalColors.ban;
                panicPoly.inspectData = {
                    checkin,
                    inspectType: InspectTypes.CHECKINV2
                };

                panicPoly.draft = true;
                polys.push(panicPoly);

                //polys.push()
            }

            if (!inspectData.__outOfBoundary) {

                icos.push(checkinIco);

                let text = {};
                text.centroid = checkinIco.position;

                //should be preprocessed.. probably
                const shortUntil = moment(checkin.stop).format('HH:mm');

                text.displayName = `${shortUntil}/${checkin.__max}`;

                texts.push(text);
            }
        } catch (e) {
            console.error('error in checkin raw data', el);
            console.dir(e);
        }

    });
    //console.log('checkins', icos);
    return {icos, points, paths, polys, texts};

}




//call on tick update
//testing for outdated alerts is client-side based (reducing bandwidth)
//unixTime should be serverTime.
export function cleanOutdatedCheckins(prevCheckinsStore, tick) {
    console.log('cleanOutdatedCheckins should be server-side now')
    const {rawData, selectedCheckinV2, operatorCheckinV2} = prevCheckinsStore;
    const timeNow = tick.unixTime || unixTimeNow();

    let cleanedList = [];
    let dirty = false;
    console.log('cleanOutdatedCheckins/tick', prevCheckinsStore, tick);

    for (let i = 0; i < rawData.length; i++) {
        const checkin = rawData[i];
        const {inspectData} = checkin;

        //update
        inspectData.__isBeforeStart = inspectData.__start > timeNow;
        inspectData.__isAfterStop = inspectData.__stop < timeNow;

        //do not autoremove controlled operation
        const requiredOperationCancel = OPERATION_CANCEL_IN_CTR && inspectData.__isControlled;
        const keepInList = !inspectData.__isAfterStop || inspectData.__lostControl;
        //fixme -> temporary switch for behaviour
        const keepFilter = (NEW_PANDORA_TIMEOUTS_BEHAVIOUR)?
            //wip, reviewing behaviour
            (requiredOperationCancel || keepInList)
            //(inspectData.checkinRecord.active)
            :(requiredOperationCancel || !inspectData.__isAfterStop);

        if (keepFilter) {
        //if (requiredOperationCancel || !inspectData.__isAfterStop || inspectData.__lostControl) {
            cleanedList.push(rawData[i]);
        } else {
            if (selectedCheckinV2 && selectedCheckinV2.checkinRecord.uid === inspectData.checkinRecord.uid) {
                selectedCheckinV2.__outdated = true;
            }
            if (operatorCheckinV2 && operatorCheckinV2.uid === inspectData.checkinRecord.uid) {
                operatorCheckinV2.__outdated = true;
            }
            dirty = true;
        }
    }
    if (dirty) {
        console.log('removed checkins!');
    }

    return {isDirty: dirty, cleanedList, selectedCheckinV2, operatorCheckinV2};
}

const defaultOpRadius = 0.500; //km
const circleOpts = {steps: 6, units: 'kilometers'};

//returns geometry.
export function createOpCircle (center, opRadius = defaultOpRadius) {

    return turfPoly(turfCircle(center, opRadius, circleOpts));
}


function _processConflicts(inspectData, geojsonFeature, zonesV2, mySelf) {

    console.log(inspectData);

    if (geojsonFeature.type !== 'Point') {
        console.error('non-point checkin not supported yet!', geojsonFeature);
    }

    //console.error('checkinJurisdiction check');

    //03-04-21 optimization, using checkin point for checks, not .5km circle
    //const coords = geojsonFeature.coordinates;
    //const opCircle = createOpCircle(coords);
    //const opGeometry = createOpCircle(coords);

    const opGeometry = geojsonFeature;

    inspectData.__inJurisdiction = isInMyJurisdiction(mySelf, zonesV2, opGeometry);

    console.warn('===checkin in jurisdiction', inspectData.__inJurisdiction);

    if (!inspectData.__inJurisdiction) {
        //ensure vals;
        inspectData.__warningsNames = [];
        return inspectData;
    }

    //console.error('createWarningsNamesList', inspectData, zonesV2.rawData.length) //, opCircle);

    const warningNames = [];

    for (let i=0; i < zonesV2.rawData.length; i++ ) {
        const primitive = zonesV2.rawData[i].primitive;

        //damn.. precache?
        if (!primitive.__turfPoly) {
            primitive.__turfPoly = turfPoly(primitive.polygon);
        }

        //OPTIMIZATION!
        // checking ONLY 'color'-ed warningNames, as only usage in flow is getHighestRPAcssColor
        const color = has(primitive, 'inspectData.zoneRecord.etc.status');

        if (color) {
            const disjoint = turfDisjoint(opGeometry, primitive.__turfPoly);
            if (!disjoint) {
                warningNames.push({
                        name: primitive.inspectData.name,
                        min: primitive.inspectData.zoneRecord.min,
                        max: primitive.inspectData.zoneRecord.max,
                        color: primitive.inspectData.zoneRecord.etc.status
                        //was
                        //...(has(primitive, 'inspectData.zoneRecord.etc.status') && {color: primitive.inspectData.zoneRecord.etc.status})
                    }
                );
            }
        }
    }

    inspectData.__warningsNames = warningNames;
    return inspectData;
}

//call on fetch success, preprocessing data here
export function preProcessCheckIns(responseData, appExtras, prevCheckinsStore, zonesV2, mySelf) {
    const checkinsArr = responseData || [];
    let selectedCheckinV2Update, operatorCheckinV2Update;
    const timeNow = unixTimeNow();

    let {operatorCheckinV2, selectedCheckinV2} = prevCheckinsStore;

    const cleanedList = [];

    const mobileMode = appExtras.app.type === 'MOBILE';
    const rtMapMode = appExtras.app.name === 'RT_MAP';
    if (mobileMode) {
        console.log('temp test -> processing checkins in MOBILE mode', prevCheckinsStore);
    } else if (rtMapMode) {
        console.log('temp test -> processing checkins in RT_MAP mode');
    }

    for (let i = 0; i < checkinsArr.length; i++) {
        let checkinRecord = checkinsArr[i];
        let geojsonFeature = checkinRecord.geojson;

        //hmm, somehow there were some records with etc null on trtdm, probably manually copied.
        //our 'hacker' sends wrong payload..., should be serverside fixed
        if (!checkinRecord.etc || !geojsonFeature) {
            console.error('malformed checkinRecord', checkinRecord);
            continue;
        }

        //we don't want duplicates
        delete checkinRecord.geojson;

        //old variant fetch with relation
        if (checkinRecord.operatorHack) {
            checkinRecord.priv = checkinRecord.operatorHack.priv;
        }

        let inspectData = {
            inspectType:InspectTypes.CHECKINV2,
            checkinRecord,
        };

        const status = checkinRecord.status;

        inspectData.__status = checkinRecord.status;
        inspectData.__start = isoString2UnixTime(checkinRecord.start);
        inspectData.__stop = isoString2UnixTime(checkinRecord.stop);
        inspectData.__max = checkinRecord.etc.__max;
        inspectData.__isControlled = checkinRecord.ctrl;
        //request filter should do, but just to ensure;

        inspectData.__isBeforeStart = inspectData.__start > timeNow;
        inspectData.__isAfterStop = inspectData.__stop < timeNow;

        inspectData.__lostControl = checkinRecord.status === FlightStatusesV2.LOST_CONTROL;

        if (inspectData.checkinRecord.status === FlightStatusesV2.LOST_CONTROL) {
            console.warn ('fixme end time for LOST_CONTROL');
        }

        const latLng = geojsonFeature.coordinates;
        inspectData.__latLng = latLng;
        inspectData.__latLngDMS = LatLng2Dms({lat:latLng[1],lng:latLng[0]});

        const govMsgsBlock = checkinRecord.govMsgs || [];
        const l = govMsgsBlock.length;

        if (l > 0) {
            inspectData.__checkedBy = govMsgsBlock[l-1].issuerEmail;
        }
        //inspectData.__checkedMsg = checkinRecord.etc.atcStatusIssuerMsg;

        inspectData.__weightClass = checkinRecord.etc.weightClass;

        if (rtMapMode) {
            //conflicts resolve probably should be on backend.
            inspectData = _processConflicts(inspectData, geojsonFeature, zonesV2, mySelf);

        } else if (mobileMode) {
            //fixme
            // checkforchanges and pass somehow info back
        }

        const newRawEl = {inspectData, geojsonFeature};

        if (selectedCheckinV2 && selectedCheckinV2.checkinRecord.uid === checkinRecord.uid) {
            selectedCheckinV2Update = inspectData;
        }
        //storing record, not inspectData. hmm
        if (operatorCheckinV2 && operatorCheckinV2.uid === checkinRecord.uid) {
            operatorCheckinV2Update = checkinRecord;
        }

        cleanedList.push(newRawEl);
    }

    if (selectedCheckinV2 && !selectedCheckinV2Update) {
        selectedCheckinV2 = {...selectedCheckinV2, __outdated : true};
    } else {
        // if (selectedCheckinV2) {
        //     selectedCheckinV2.__outdated = false;
        // }
        selectedCheckinV2 = selectedCheckinV2Update; //ie undeifined?
    }

    if (operatorCheckinV2 && !operatorCheckinV2Update) {
        operatorCheckinV2.__outdated = true;
    } else {
        // if (operatorCheckinV2) {
        //     operatorCheckinV2.__outdated = false;
        // }
        operatorCheckinV2 = operatorCheckinV2Update; //ie undeifined?
    }

    console.log('preProcessCheckIns', cleanedList);

    return {rawData:cleanedList, selectedCheckinV2, operatorCheckinV2};
}

export function checkinV2Status2ico(inspectData, isSelected = false) {
    const {__isBeforeStart, __isAfterStop} = inspectData;
    const {status} = inspectData.checkinRecord;

    if (status === FlightStatusesV2.LOST_CONTROL) {
        return (isSelected)?ICONS.RED_DRONE_SELECTED_ICO:ICONS.RED_DRONE_ICO
    }

    if (__isAfterStop) {
        return (isSelected)?ICONS.ORANGE2_DRONE_SELECTED_ICO:ICONS.ORANGE2_DRONE_ICO
    }
    if (status === FlightStatusesV2.CREATED) {
        if (__isBeforeStart) {// || __isAfterStop) {
            return (isSelected)?ICONS.WHITE_DRONE_SELECTED_ICO:ICONS.WHITE_DRONE_ICO
        }
        return (isSelected)?ICONS.YELLOW_DRONE_SELECTED_ICO:ICONS.YELLOW_DRONE_ICO
    }
    if (status === FlightStatusesV2.IN_QUEUE) {
        // if (__isBeforeStart) {// || __isAfterStop) {
        //     return (isSelected)?ICONS.WHITE2_DRONE_SELECTED_ICO:ICONS.WHITE2_DRONE_ICO
        // }
        return (isSelected)?ICONS.WHITE2_DRONE_SELECTED_ICO:ICONS.WHITE2_DRONE_ICO
    }
    if (status === FlightStatusesV2.LAND_NOW || status === FlightStatusesV2.LANDING_ACK) {
        return (isSelected)?ICONS.PURPLE_DRONE_SELECTED_ICO:ICONS.PURPLE_DRONE_ICO
    }
    if (status === FlightStatusesV2.CANCELLED) {
        return (isSelected)?ICONS.GREY_DRONE_SELECTED_ICO:ICONS.GREY_DRONE_ICO
    }
    if (status === FlightStatusesV2.REJECTED || status === FlightStatusesV2.REJECTED_ACK) {
        // if (__isBeforeStart) {
        //     return (isSelected)?ICONS.SALMON_DRONE_SELECTED_ICO:ICONS.SALMON_DRONE_ICO
        // }
        return (isSelected)?ICONS.SALMON_DRONE_SELECTED_ICO:ICONS.SALMON_DRONE_ICO
    }
    if (status === FlightStatusesV2.ACCEPTED || status === FlightStatusesV2.SEEN || status === FlightStatusesV2.ACCEPTED_ACK){
        // if (__isBeforeStart) {
        //     return (isSelected)?ICONS.GREEN2_DRONE_SELECTED_ICO:ICONS.GREEN2_DRONE_ICO
        // }
        return (isSelected)?ICONS.GREEN2_DRONE_SELECTED_ICO:ICONS.GREEN2_DRONE_ICO
    }
    if ( status === FlightStatusesV2.ATC_MODIFIED || FlightStatusesV2.ATC_MODIFIED_ACK) {
        return (isSelected)?ICONS.CYAN_DRONE_SELECTED_ICO:ICONS.CYAN_DRONE_ICO
    }
    if (isSelected) {
        console.warn('wtf, unknown checkin status', inspectData);
    }
    return (isSelected)?ICONS.MAGENTA_DRONE_SELECTED_ICO:ICONS.MAGENTA_DRONE_ICO
}


//aargh
export function findCheckinRawDataByUid(rawData, uid) {
    let found;
    for (let i = 0; i < rawData.length; i++) {
        console.log(rawData[i]);
        if (rawData[i].inspectData.checkinRecord.uid === uid) {
            found = rawData[i];
            break;
        }
    }
    return found;
}


export function processLostControlCheckins(previousLostControlReviews, rawData) {

    const mutatedLostControlReviews = new Map(previousLostControlReviews);

    const checkinUids = rawData.map(checkinBundle => checkinBundle.inspectData.checkinRecord.uid);

    //cleaning
    for(const reviewUid of previousLostControlReviews.keys()){
        if(!checkinUids.includes(reviewUid)) {
            mutatedLostControlReviews.delete(reviewUid);
        }
    }


    //executing
    for (const checkinBundle of rawData) {
        const {__inJurisdiction, __status} = checkinBundle.inspectData;
        const {uid} = checkinBundle.inspectData.checkinRecord;


        if (!!__inJurisdiction && __status === FlightStatusesV2.LOST_CONTROL) {
            if (!mutatedLostControlReviews.has(uid)) {
                mutatedLostControlReviews.set(uid, LostControlAlert.SHOULD_REVIEW);
            }
        }
    }

    return mutatedLostControlReviews;
}
