export const ALERT_TYPES = {
    CUSTOM_ALERT: 'customAlert', //*almost* the same as inspecttype, huh
    ZONE_ALERT: 'zoneAlert'
};

export const ALERT_STATUSES = {
    LAND_NOW: 'landNow',
    WARNING: 'warning',
    ENDED: 'ended'
};

export const ALERT_SUBTYPES = {
    LPR : 'lpr',
    CTR : 'ctr'
};

//buu helper for now.
// export function getOwnedAreaName() {
//     const user = getPandoraUser();
//     return user.options.area;
// }

//huh
export const RADIO_VALUE_TEXTS = {
    [ALERT_SUBTYPES.LPR]: "LPR/HEMS ALARM",
    [ALERT_SUBTYPES.CTR]: "CTR ALARM"
};

export const EMIT_SIGNALS = {
    CREATE_AREA_ALERT:"createAreaAlert",
    CANCEL_AREA_ALERT:"cancelAreaAlert"
};
