import {Responsive} from "react-admin"
import React from "react";
import Button from '@material-ui/core/Button';
import SettingsIcon from '@material-ui/icons/Settings';
import classnames from "classnames";
import {withStyles} from "@material-ui/core";
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import {toggleSettingsView} from "../logic/settings/MySettingsActions";
import { translate } from 'ra-core';

const styles = () => ({
    innerIcon: {
        paddingRight: '1.2em',
    },
});

const mapDispatchToProps = (dispatch) => ({
    openSettings: (eventTarget)=>dispatch(toggleSettingsView(eventTarget)),
});

export default compose(
    translate,
    connect(null, mapDispatchToProps),
    withStyles(styles)
)(

    ({classes, className, translate, openSettings}) =>(
        <Responsive
            xsmall={
                <Button
                    className={classnames('logout', className)}
                    onClick={(event)=>openSettings(event.currentTarget)}
                >
                    <SettingsIcon className={classes.iconPaddingStyle}/>
                    {translate('myroot.action.settings')}
                </Button>
            }
            medium={
                <Button
                    className={classnames('logout', className)}
                    onClick={(event)=>openSettings(event.currentTarget)}
                    size="small"
                >
                    <SettingsIcon className={classes.innerIcon}/>
                    {translate('myroot.action.settings')}
                </Button>
            }
        />)
);