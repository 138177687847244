import {
    ADD_BLOB,
    LOADING_START,
    REMOVE_BLOB,
    TOGGLE_MISSION_NO_CONFLICTS_DIALOG,
    TOGGLE_MISSION_TERM_DIALOG,
    TOGGLE_EVENTS_STREAM_WINDOW, TOGGLE_ACM_DIALOG_RPA, TOGGLE_ACM_TERMS_CREATOR_DIALOG
} from "./MyUiActions";
import {LOADING_END} from "./MyUiActions";
import {SET_TITLE} from "./MyUiActions";

let initialState = {
    loading: 0,
    loadingMessageBundle: {
        primaryMessage: "ra.page.loading",
        secondaryMesage: 'myroot.message.wait'
    },
    //TODO move to another reducer probably
    blobs: new Map(),
    missionTermDialog: {
        visible: false,
    },
    missionNoConflictsDialog: {
        visible: false,
    },
    eventsStreamWindow: {
        visible: false,
        portal: false,
        resource: undefined,
    },
    acmRpaDialog: {
        visible: false
    },
    acmTermsCreatorDialog: {
        visible: false
    }
};

export const setInitialTitle = (title) => {
    initialState = {
        ...initialState,
        title
    };
};

export default (state = initialState, {type, primaryMessage = "ra.page.loading", secondaryMessage = "myroot.message.wait", title, blob, hash, show, portal, resource}) => {
    const {loading} = state;
    const blobsMap = new Map(state.blobs);

    switch (type) {
        case LOADING_START:
            return {
                ...state,
                loading: loading + 1,
                loadingMessageBundle: {
                    primaryMessage,
                    secondaryMessage,
                },
            };

        case LOADING_END:
            return {...state, loading: loading - 1};

        case SET_TITLE:
            return {...state, title: title};

        //TODO move to another reducer probably
        case ADD_BLOB:

            if(!blobsMap.has(hash)) {
                blobsMap.set(hash, blob);
            }

            return {
                ...state,
                blobs: blobsMap
            };

        //TODO move to another reducer probably
        case REMOVE_BLOB:

            blobsMap.delete(hash);
            return {...state, blobs: blobsMap};
        case TOGGLE_MISSION_TERM_DIALOG:
            return {
                ...state,
                missionTermDialog: {
                    visible: show !== undefined ? show : !state.missionTermDialog.visible,
                }
            };
        case TOGGLE_MISSION_NO_CONFLICTS_DIALOG:
            return {
                ...state,
                missionNoConflictsDialog: {
                    visible: show !== undefined ? show : !state.missionNoConflictsDialog.visible,
                }
            };
            // TODO this is a pure super admin feature but compiles both to gov and operator app
            // TODO LGZ -> consider if we shouldn't make another reducer for GOV(super admin) windows
            // TODO on the other hand - is this a security issue at all?
        case TOGGLE_EVENTS_STREAM_WINDOW:
            return {
                ...state,
                eventsStreamWindow: {
                    visible: show !== undefined ? show : !state.eventsStreamWindow.visible,
                    portal: portal !== undefined ? portal : false,
                    resource: resource !== undefined ? resource : state.eventsStreamWindow.resource,
                }
            };
        case TOGGLE_ACM_DIALOG_RPA:
            return {
                ...state,
                acmRpaDialog: {
                    visible: show !== undefined ? show : !state.acmRpaDialog.visible,
                }
            };
        case TOGGLE_ACM_TERMS_CREATOR_DIALOG:
            return {
                ...state,
                acmTermsCreatorDialog: {
                    visible: show !== undefined ? show : !state.acmTermsCreatorDialog.visible,
                }
            };

        default:
            return state;
    }
}