import React from 'react'
import Card from "@material-ui/core/Card/Card";
import CardContent from "@material-ui/core/CardContent/CardContent";
import CardActions from "@material-ui/core/CardActions/CardActions";
import connect from "react-redux/es/connect/connect";
import {Table, TableCell, TableHead, TableRow, Typography, withStyles} from "@material-ui/core";
import {required, translate} from 'ra-core'
import compose from 'recompose/compose';
import CardHeader from "@material-ui/core/CardHeader/CardHeader";
import Avatar from "@material-ui/core/Avatar/Avatar";
import IconButton from "@material-ui/core/IconButton/IconButton";
import CloseIcon from '@material-ui/icons/Close';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import StoreProvider from "../../MyStoreProvider";
import {toggleCommercialServicesView} from "../../logic/settings/MySettingsActions";
import Dialog from "@material-ui/core/Dialog/Dialog";
import {CheckboxGroupInput, Labeled, SelectArrayInput, SimpleForm} from "ra-ui-materialui";
import {myAuthSagaSaveSelfOperatorRq} from "../../../LogicV2/auth/MyExtraAuthActions";
import has from "lodash.has";
import moment from "moment";
import TableBody from "@material-ui/core-v3/TableBody";
import {TimePickerLocale} from "../TimePickersLocaleProxy";
import {destroy, formValueSelector, isDirty, isValid, reset} from "redux-form";
import TranslatableTypography from "../TranslatableTypography";
import {WarningText} from "../WarningText";
import {oneElementRequired} from "../../utils/validationHelpers";

const styles = theme => ({
    settingsCard: {
        margin: "0",
        borderRadius: "6px",
        '@media screen and (max-height: 919px)': {
            minHeight: "unset"
        },
        display: "flex",
        flexDirection: "column",
        maxHeight: "98vh"
    },
    cardHeader: {
        backgroundColor: theme.palette.primary.main,
        borderRadius: "4px",
    },
    cardContent: {
        overflowY: "auto",
        display: "flex",
        flexDirection: "column",
    },
    cardParagraph: {
        marginBottom: "1em",
        width: "100%",
    },
    paper: {
        '@media screen and (min-height: 920px)': {
            overflowY: "inherit",
            maxHeight: "unset",
        },
        borderRadius: "6px",
    },
    timePicker: {
        width: "6ch",
        minWidth: "unset",
        margin: 0,
        marginBottom: "1rem"
    },
    timeCell: {
    },
    table: {
        "& td": {
            padding: "0 1rem",
        },
        "& th": {
            padding: "0 1rem",
        },
        "& tr": {
            "&:nth-child(even)": {
                backgroundColor: "inherit"
            }
        }
    },
    tableLabel: {
        width: "unset",
        marginRight: "unset"
    },
    selectArray: {
        width: "unset"
    },
    rodoLabel: {
        paddingBottom: `${theme.spacing.unit}px`
    }

});


//ISO 3166-2
export const voivodeshipsPL = [
    { id: 'PL-02', name: 'dolnośląskie' },
    { id: 'PL-04', name: 'kujawsko-pomorskie' },
    { id: 'PL-06', name: 'lubelskie' },
    { id: 'PL-08', name: 'lubuskie' },
    { id: 'PL-10', name: 'łódzkie' },
    { id: 'PL-12', name: 'małopolskie' },
    { id: 'PL-14', name: 'mazowieckie' },
    { id: 'PL-16', name: 'opolskie' },
    { id: 'PL-18', name: 'podkarpackie' },
    { id: 'PL-20', name: 'podlaskie' },
    { id: 'PL-22', name: 'pomorskie' },
    { id: 'PL-24', name: 'śląskie' },
    { id: 'PL-26', name: 'świętokrzyskie' },
    { id: 'PL-28', name: 'warmińsko-mazurskie' },
    { id: 'PL-30', name: 'wielkopolskie' },
    { id: 'PL-32', name: 'zachodniopomorskie' },
];

export const services = [
    { id: 'S0001', name: 'myroot.company.services.techInspection' },
    { id: 'S0002', name: 'myroot.company.services.workProgress' },
    { id: 'S0003', name: 'myroot.company.services.patrol' },
    { id: 'S0004', name: 'myroot.company.services.airMonitoring' },
    { id: 'S0005', name: 'myroot.company.services.agriculturalInspection' },
    { id: 'S0006', name: 'myroot.company.services.forestInspection' },
    { id: 'S0007', name: 'myroot.company.services.waterInspection' },
    { id: 'S0008', name: 'myroot.company.services.modelling' },
    { id: 'S0009', name: 'myroot.company.services.antiqueInspection' },
    { id: 'S0010', name: 'myroot.company.services.animalsCounting' },
    { id: 'S0011', name: 'myroot.company.services.cargoDrop' },
    { id: 'S0012', name: 'myroot.company.services.movie' },
    { id: 'S0013', name: 'myroot.company.services.movieHD' },
    { id: 'S0014', name: 'myroot.company.services.movie4K' },
    { id: 'S0015', name: 'myroot.company.services.movieNight' },
    { id: 'S0016', name: 'myroot.company.services.streaminghHD' },
    { id: 'S0017', name: 'myroot.company.services.streamingHD' },
    { id: 'S0018', name: 'myroot.company.services.streaming4K' },
    { id: 'S0019', name: 'myroot.company.services.weddings' },
    { id: 'S0020', name: 'myroot.company.services.energyInspections' },
    { id: 'S0021', name: 'myroot.company.services.gasInspection' },
    { id: 'S0022', name: 'myroot.company.services.oilInspection' },
    { id: 'S0023', name: 'myroot.company.services.heatInspection' },
    { id: 'S0024', name: 'myroot.company.services.tracking' },
    { id: 'S0025', name: 'myroot.company.services.ortofoto' },
    { id: 'S0026', name: 'myroot.company.services.ortotermo' },
    { id: 'S0027', name: 'myroot.company.services.thermovision' },
    { id: 'S0028', name: 'myroot.company.services.searchThermovision' },
    { id: 'S0029', name: 'myroot.company.services.search' },
    { id: 'S0030', name: 'myroot.company.services.searchNightVision' },
    { id: 'S0031', name: 'myroot.company.services.measurement' },
    { id: 'S0032', name: 'myroot.company.services.race' },
    { id: 'S0033', name: 'myroot.company.services.lightShow' },
    { id: 'S0034', name: 'myroot.company.services.spectometry' },
    { id: 'S0035', name: 'myroot.company.services.agriculturalSprays'},
    { id: 'S0036', name: 'myroot.company.services.decontamination'},
    { id: 'S9999', name: 'myroot.company.services.other' },
];

export const rodoChecks = [
    {id: 'commercialRodo', name: 'myroot.company.commercialRodo' }
];

const getCharCodeAtZeroForSortPL = (word) => {
    switch(word[0]) {
        case 'ą':
            return 97.5;
        case 'Ą':
            return 65.5;
        case 'ę':
            return 101.5;
        case 'Ę':
            return 69.5;
        case 'ć':
            return 99.5;
        case 'Ć':
            return 67.5;
        case 'ń':
            return 110.5;
        case 'Ń':
            return 78.5;
        case 'ł':
            return 108.5;
        case 'Ł':
            return 76.5;
        case 'ó':
            return 111.5;
        case 'Ó':
            return 79.5;
        case 'ś':
            return 115.5;
        case 'Ś':
            return 83.5;
        case 'ż':
            return 122.5;
        case 'Ż':
            return 90.5;
        case 'ź':
            return 122.8;
        case 'Ź':
            return 90.8;

        default:
            return word.charCodeAt(0);
    }
};

class MyCommercialServicesView extends React.Component {

    constructor(props) {
        super(props);
    }

    _handleClose = () => {
        const store = StoreProvider.getStore();
        store.dispatch(toggleCommercialServicesView({show: false}));
        this.props.destroy();
    };

    _handleSave = (data) => {
        console.log('handleSave commercial services', data);
        this.props.myAuthSagaSaveSelfRqAction(data);
        //hmm? needed due to proper isDirty showing for workingHour deep nested start and stop fields
        this.props.reset();
    };


    render() {

        const {classes, commercialServicesView, translate, mySelf, isValid, isDirty, rodo = []} = this.props;
        const disabled = false;
        return (
            <Dialog
                open={commercialServicesView.visible}
                onClose={this._handleClose}
                maxWidth="md"
                classes={{
                    paper: classes.paper
                }}
                disableBackdropClick
            >
                <Card className={classes.settingsCard}>

                    <CardHeader
                        avatar={
                            <Avatar aria-label="View settings" className={classes.avatar}>
                                <AttachMoneyIcon/>
                            </Avatar>
                        }
                        action={
                            <IconButton onClick={this._handleClose}>
                                <CloseIcon/>
                            </IconButton>
                        }
                        title={translate("myroot.configuration.commercialServicesSettings")}
                        titleTypographyProps={{variant: "body2"}}
                        subheaderTypographyProps={{color: "textPrimary", variant: "button"}}
                        className={classes.cardHeader}
                    />

                    <CardContent className={classes.cardContent}>
                        <div className={classes.cardParagraph}>

                            <SimpleForm form={'self-form-commercial-services'}
                                        save={this._handleSave}
                                        record={{
                                            etc: {
                                                commercial: {
                                                    territory: has(mySelf, 'etc.commercial.territory') ? mySelf.etc.commercial.territory : null,
                                                    services: has(mySelf, 'etc.commercial.services') ? mySelf.etc.commercial.services : null,
                                                    workingHours: has(mySelf, 'etc.commercial.workingHours') ? mySelf.etc.commercial.workingHours : null,
                                                    //need to pass an empty array -  https://github.com/marmelab/react-admin/issues/2527
                                                    rodo: has(mySelf, 'etc.commercial.rodo') ? mySelf.etc.commercial.rodo : [],
                                                }
                                            }
                                        }}

                                        redirect={false}
                                        toolbar={disabled || !isValid  || !isDirty ? null : undefined}
                            >
                                
                                <SelectArrayInput label={'myroot.dashboard.accountForm.territoryOfOperation'}
                                                  source="etc.commercial.territory"
                                                  {...{disabled}}
                                                  choices={voivodeshipsPL}
                                                  validate={[oneElementRequired(translate), required()]}
                                                  className={classes.selectArray}
                                />
                                <SelectArrayInput label={'myroot.dashboard.accountForm.providedServices'}
                                                  source="etc.commercial.services"
                                                  validate={[oneElementRequired(translate), required()]}
                                                  {...{disabled}}
                                                  choices={services.sort(
                                                      (a, b) => {
                                                          const aFirstLetterCode = getCharCodeAtZeroForSortPL(translate(a.name));
                                                          const bFirstLetterCode = getCharCodeAtZeroForSortPL(translate(b.name));
                                                          return aFirstLetterCode - bFirstLetterCode
                                                      }
                                                  )}
                                                  className={classes.selectArray}
                                />
                                <Labeled    label={'myroot.company.workingHours.name'}
                                            className={classes.tableLabel}
                                >
                                <>
                                <Table className={classes.table}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>

                                            </TableCell>
                                            <TableCell>
                                                <Typography>
                                                    {moment().isoWeekday(1).format('ddd')}
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography>
                                                    {moment().isoWeekday(2).format('ddd')}
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography>
                                                    {moment().isoWeekday(3).format('ddd')}
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography>
                                                    {moment().isoWeekday(4).format('ddd')}
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography>
                                                    {moment().isoWeekday(5).format('ddd')}
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography>
                                                    {moment().isoWeekday(6).format('ddd')}
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography>
                                                    {moment().isoWeekday(7).format('ddd')}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>
                                                {translate("myroot.company.workingHours.start")}
                                            </TableCell>
                                            <TableCell className={classes.timeCell}>
                                                    <TimePickerLocale
                                                        className={classes.timePicker}
                                                        source="etc.commercial.workingHours.monday.start"
                                                    />
                                            </TableCell>
                                            <TableCell className={classes.timeCell}>
                                                    <TimePickerLocale
                                                        className={classes.timePicker}
                                                        source="etc.commercial.workingHours.tuesday.start"
                                                    />
                                            </TableCell>
                                            <TableCell className={classes.timeCell}>
                                                    <TimePickerLocale
                                                        className={classes.timePicker}
                                                        source="etc.commercial.workingHours.wednesday.start"
                                                    />
                                            </TableCell>
                                            <TableCell className={classes.timeCell}>
                                                    <TimePickerLocale
                                                        className={classes.timePicker}
                                                        source="etc.commercial.workingHours.thursday.start"
                                                    />
                                            </TableCell>
                                            <TableCell className={classes.timeCell}>
                                                    <TimePickerLocale
                                                        className={classes.timePicker}
                                                        source="etc.commercial.workingHours.friday.start"
                                                    />
                                            </TableCell>
                                            <TableCell className={classes.timeCell}>
                                                    <TimePickerLocale
                                                        className={classes.timePicker}
                                                        source="etc.commercial.workingHours.saturday.start"
                                                    />
                                            </TableCell>
                                            <TableCell className={classes.timeCell}>
                                                    <TimePickerLocale
                                                        className={classes.timePicker}
                                                        source="etc.commercial.workingHours.sunday.start"
                                                    />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                {translate("myroot.company.workingHours.stop")}
                                            </TableCell>
                                            <TableCell className={classes.timeCell}>
                                                <TimePickerLocale
                                                    className={classes.timePicker}
                                                    source="etc.commercial.workingHours.monday.stop"
                                                />
                                            </TableCell>
                                            <TableCell className={classes.timeCell}>
                                                <TimePickerLocale
                                                    className={classes.timePicker}
                                                    source="etc.commercial.workingHours.tuesday.stop"
                                                />
                                            </TableCell>
                                            <TableCell className={classes.timeCell}>
                                                <TimePickerLocale
                                                    className={classes.timePicker}
                                                    source="etc.commercial.workingHours.wednesday.stop"
                                                />
                                            </TableCell>
                                            <TableCell className={classes.timeCell}>
                                                <TimePickerLocale
                                                    className={classes.timePicker}
                                                    source="etc.commercial.workingHours.thursday.stop"
                                                />
                                            </TableCell>
                                            <TableCell className={classes.timeCell}>
                                                <TimePickerLocale
                                                    className={classes.timePicker}
                                                    source="etc.commercial.workingHours.friday.stop"
                                                />
                                            </TableCell>
                                            <TableCell className={classes.timeCell}>
                                                <TimePickerLocale
                                                    className={classes.timePicker}
                                                    source="etc.commercial.workingHours.saturday.stop"
                                                />
                                            </TableCell>
                                            <TableCell className={classes.timeCell}>
                                                <TimePickerLocale
                                                    className={classes.timePicker}
                                                    source="etc.commercial.workingHours.sunday.stop"
                                                />
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                                    </>
                                </Labeled>
                            

                            </SimpleForm>
                        </div>
                    </CardContent>
                    <CardActions>
                    </CardActions>
                </Card>
            </Dialog>
        )
    }
}


const mapStateToProps = state => {
    return {
        commercialServicesView: state.mySettings.commercialServicesView,
        mySelf : state.mySelf,
        rodo: formValueSelector('self-form-commercial-services')(state, 'etc.commercial.rodo'),
        isDirty: isDirty('self-form-commercial-services')(state),
        isValid: isValid('self-form-commercial-services')(state),
    }
};

const mapDispatchToProps = dispatch =>
    (
        {
            myAuthSagaSaveSelfRqAction: (e) => {
                console.log('dispatchin myAuthSagaSaveSelfOperatorRq commercial services', e);
                dispatch(myAuthSagaSaveSelfOperatorRq(e))
            },
            reset: () => {
                dispatch(reset('self-form-commercial-services'));
            },
            destroy: () => dispatch(destroy('self-form-commercial-services'))
        }
    );

export default compose(
    translate,
    connect(
        mapStateToProps,
        mapDispatchToProps
    ),
    withStyles(styles)
)(MyCommercialServicesView);
