import englishMessages from './en';
import polishMessages from './pl';

//huh, promise returned in example... maybe later to test out lazy import
// const messages = {
//     pl: () => import('./i18n/pl.js').then(messages => messages.default),
// };

export default locale => {

    console.log(polishMessages);

    if (locale === 'pl') {
        //return messages[locale]();
        return polishMessages;
    }

    // Always fallback on english
    return englishMessages;
};
