import React from 'react';
import styles from "../../css/docs.css"

export const helpPage = (
    <div id="help-content">
        <h1>
            Witamy w serwisie PansaUTM!
        </h1>
        <p>
            Pod adresem <a href="https://www.pansa.pl/jak-korzystac-z-pansautm/" target="_blank">https://www.pansa.pl/jak-korzystac-z-pansautm/</a> znajduje się instrukcja jak korzystać z PansaUTM z zachowaniem procedur oraz bezpieczeństwa wykonywania operacji. 
        </p>
        <br/>
        <p>
        W razie pytań lub sugestii dotyczących działania systemu zapraszamy do kontaktu e-mail: <a href="mailto:drony@pansa.pl">drony@pansa.pl</a>
        </p>
        <br/>
        <p>
        Dziękujemy i życzymy bezpiecznych lotów.
        </p>
        <img id="help-image" src="./img/help/help_pl.png" alt="Help"/>
        
    </div>
)
