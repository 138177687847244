import {ArrayField, Datagrid, TextField} from "ra-ui-materialui";
import MyTextField from '../../MyTextField';
import MyLinkField from '../../MyLinkField';

import {dateTimeFormatter} from "../../../utils/momentHelpers";
import {ReferenceFieldDecorator as ReferenceField} from "../../ReferenceFieldDecorator";
import React from "react";

const ConflictsGovRenderer = ({shownConflicts}) => {
    console.log('===========ConflictsGovRenderer', shownConflicts);

    return (
        <ArrayField
            record={shownConflicts}
            source="filteredConflicts">

            <Datagrid>
                <MyTextField label={'resources.zoneLegs.fields.created'}
                             sortable={false}
                             format={dateTimeFormatter}
                             source="created"/>

                <TextField source="zonePrimitive.primitive.inspectData.zoneRecord.type"
                           label={'resources.zones.fields.type'}
                           sortable={false}
                />

                <MyLinkField source="zonePrimitive.primitive.inspectData.name"
                             idSource="zoneId"
                    //link={'/zone-conflict-resolver/{id}'} //unfinished functionality. hiding for v1.1
                             link={'/zones/{id}/show'}
                             label={'resources.zones.fields.name'}
                             sortable={false}
                />

                <ReferenceField source="zonePrimitive.primitive.inspectData.zoneRecord.unitid"
                                label={'resources.zones.fields.unitName'}

                                reference="unitsAll"
                                linkType="show"
                                basePath={"/unitsAll"} //required
                                sortable={false}

                >
                    <TextField source="name"/>
                </ReferenceField>

                <TextField source="status"
                           label={'resources.zoneLegs.fields.status'}
                           sortable={false}

                />


                {/*to fix with permissions and ownership*/}
                {/*<AcceptButton/>*/}
                {/*<RejectButton/>*/}

            </Datagrid>
        </ArrayField>
    )
};

export default ConflictsGovRenderer;