
import React from 'react';

import * as dd from 'dis-gui'; //renaming didn't work

import DisPrimitiveRenderer from "./DisPrimitiveRenderer"; //renaming didn't work

//fixme add custom type renderers with edit

export default class MissionPlannerLegRenderer extends React.Component {

    constructor(props) {
        super(props);
        console.log('leg here', props);
    }

    render() {
        const {legRecord, primitive} = this.props;
        if (!primitive) return (
            <dd.Text
                label='No geometry!'
                value={'start drawing'}
                onChange={()=>{}}
            />
        );
        //console.log(this.props);
        return (
            <dd.Folder label='Leg' expanded={true}>
                <DisPrimitiveRenderer primitive = {primitive}/>
            </dd.Folder>
        )
    }



}
