
import {
    MAP_UI_VOLUME,
    MAP_UI_SIZE,
    MAP_UI_LPR_HELIPADS_V1_SHOW,
    MAP_UI_MATZ_SHOW,
    MAP_TICK_ENABLED,
    MAP_UI_ALERTS_V1_SHOW,
    MAP_UI_ALERTS_V2_SHOW,

    MAP_UI_CHECKINS_V1_SHOW,
    MAP_UI_CHECKINS_V2_SHOW,

    MAP_UI_RT_MAP_ZONES_V1_SHOW,
    MAP_UI_ZONES_V2_SHOW,
    MAP_UI_POIS_V2_SHOW,
    MAP_UI_UNITS_V2_SHOW,

    MAP_UI_MISSIONS_V2_SHOW,
    //MAP_UI_RTDM_SOURCES_SHOW,
    MAP_UI_LIVE_ABSB_EXCHANGE_SHOW,
    MAP_SETUP_RQ,
    MAP_LPR_HELIPADS_V1_ENABLE_RQ,
    MAP_UI_RTDM_CREOTECH_TICKS_SHOW,
    MAP_UI_RTDM_SOAP_LPR_UNITS_TICKS_SHOW,
    MAP_UI_RTDM_EXCHANGE_TICKS_SHOW,
    MAP_UI_RTDM_AEROBITS_TICKS_SHOW,

    MAP_ALERTS_V1_ENABLE_RQ,
    MAP_ALERTS_V2_ENABLE_RQ,
    MAP_CHECKINS_V1_ENABLE_RQ,
    MAP_CHECKINS_V2_ENABLE_RQ,

    MAP_MISSIONS_V2_ENABLE_RQ,
    MAP_RT_MAP_ZONES_V1_ENABLE_RQ,
    MAP_ZONES_V2_ENABLE_RQ,
    MAP_POIS_V2_ENABLE_RQ,
    MAP_UNITS_V2_ENABLE_RQ,

    MAP_LIVE_ABSB_EXCHANGE_ENABLE_RQ,
    MAP_RTDM_SOAP_LPR_UNITS_TICKS_ENABLE_RQ,
    MAP_RTDM_CREOTECH_TICKS_ENABLE_RQ,
    MAP_RTDM_EXCHANGE_TICKS_ENABLE_RQ,
    MAP_RTDM_AEROBITS_TICKS_ENABLE_RQ,

    MAP_UI_ENTITIES_VISIBILITY,
    MAP_UI_HEIGHT_FILTER,
    PANDORA_TRAFFIC_ENABLED,
    PANDORA_LPR_TRAFFIC_ENABLED,
    PANDORA_CREOTECH_TRAFFIC_ENABLED,
    PANDORA_TRAFFIC_HEIGHT_FILTER,
    MAP_CLEAR_SETUP_RQ, MAP_UI_CALLSIGN_FLOOR_NUMBERING_ENABLED,
} from "../constants/MapUxConfigConstants";

export const storeUiVolume = (payload) => {
    return ({
        type: MAP_UI_VOLUME,
        payload,
    });
};

export const storeUiSize = (payload) => {
    return ({
        type: MAP_UI_SIZE,
        payload,
    });
};

export const storeUiShowMatz = (payload) => {
    return ({
        type: MAP_UI_MATZ_SHOW,
        payload,
    });
};

export const storeUiTickEnabled = (payload) => {
    return ({
        type: MAP_TICK_ENABLED,
        payload,
    });
};

export const storeUiAlertsV1Show = (payload) => {
    return ({
        type: MAP_UI_ALERTS_V1_SHOW,
        payload,
    });
};

export const storeUiAlertsV1EnableRq = (payload) => {
    return ({
        type: MAP_ALERTS_V1_ENABLE_RQ,
        payload,
    });
};

export const storeUiAlertsV2Show = (payload) => {
    return ({
        type: MAP_UI_ALERTS_V2_SHOW,
        payload,
    });
};

export const storeUiAlertsV2EnableRq = (payload) => {
    return ({
        type: MAP_ALERTS_V2_ENABLE_RQ,
        payload,
    });
};

export const storeUiCheckinsV1Show = (payload) => {
    return ({
        type: MAP_UI_CHECKINS_V1_SHOW,
        payload,
    });
};

export const storeUiCheckinsV1EnableRq = (payload) => {
    return ({
        type: MAP_CHECKINS_V1_ENABLE_RQ,
        payload,
    });
};

export const storeUiCheckinsV2Show = (payload) => {
    return ({
        type: MAP_UI_CHECKINS_V2_SHOW,
        payload,
    });
};

export const storeUiCheckinsV2EnableRq = (payload) => {
    return ({
        type: MAP_CHECKINS_V2_ENABLE_RQ,
        payload,
    });
};


export const storeUiLprHelipadsV1Show = (payload) => {
    return ({
        type: MAP_UI_LPR_HELIPADS_V1_SHOW,
        payload,
    });
};

export const storeUiLprHelipadsV1EnableRq = (payload) => {
    return ({
        type: MAP_LPR_HELIPADS_V1_ENABLE_RQ,
        payload,
    });
};

export const storeUiMissionsV2Show = (payload) => {
    return ({
        type: MAP_UI_MISSIONS_V2_SHOW,
        payload,
    });
};

export const storeUiMissionsV2EnableRq = (payload) => {
    return ({
        type: MAP_MISSIONS_V2_ENABLE_RQ,
        payload,
    });
};

export const storeUiRtMapZonesV1Show = (payload) => {
    return ({
        type: MAP_UI_RT_MAP_ZONES_V1_SHOW,
        payload,
    });
};

export const storeUiRtMapZonesV1EnableRq = (payload) => {
    return ({
        type: MAP_RT_MAP_ZONES_V1_ENABLE_RQ,
        payload,
    });
};

export const storeUiZonesV2Show = (payload) => {
    return ({
        type: MAP_UI_ZONES_V2_SHOW,
        payload,
    });
};

export const storeUiZonesV2EnableRq = (payload) => {
    return ({
        type: MAP_ZONES_V2_ENABLE_RQ,
        payload,
    });
};


export const storeUiPoisV2Show = (payload) => {
    return ({
        type: MAP_UI_POIS_V2_SHOW,
        payload,
    });
};

export const storeUiPoisV2EnableRq = (payload) => {
    return ({
        type: MAP_POIS_V2_ENABLE_RQ,
        payload,
    });
};


export const storeUiUnitsV2Show = (payload) => {
    return ({
        type: MAP_UI_UNITS_V2_SHOW,
        payload,
    });
};

export const storeUiUnitsV2EnableRq = (payload) => {
    return ({
        type: MAP_UNITS_V2_ENABLE_RQ,
        payload,
    });
};

export const storeUiLiveAbsbExchangeShow = (payload) => {
    return ({
        type: MAP_UI_LIVE_ABSB_EXCHANGE_SHOW,
        payload,
    });
};

export const storeUiLiveAbsbExchangeEnableRq = (payload) => {
    return ({
        type: MAP_LIVE_ABSB_EXCHANGE_ENABLE_RQ,
        payload,
    });
};

export const storeUiRtdmCreotechTicksShow = (payload) => {
    return ({
        type: MAP_UI_RTDM_CREOTECH_TICKS_SHOW,
        payload,
    });
};

export const storeUiRtdmCreotechTicksEnableRq = (payload) => {
    return ({
        type: MAP_RTDM_CREOTECH_TICKS_ENABLE_RQ,
        payload,
    });
};


export const storeUiRtdmSoapLprUnitsTicksShow = (payload) => {
    return ({
        type: MAP_UI_RTDM_SOAP_LPR_UNITS_TICKS_SHOW,
        payload,
    });
};

export const storeUiRtdmSoapLprUnitsTicksEnableRq = (payload) => {
    return ({
        type: MAP_RTDM_SOAP_LPR_UNITS_TICKS_ENABLE_RQ,
        payload,
    });
};

export const storeMapUiRtdmAerobitsTicksShow = (payload) => {
    return ({
        type: MAP_UI_RTDM_AEROBITS_TICKS_SHOW,
        payload,
    });
};

export const storeUiMapRtdmAerobitsTicksEnableRq = (payload) => {
    return ({
        type: MAP_RTDM_AEROBITS_TICKS_ENABLE_RQ,
        payload,
    });
};

export const storeUiRtdmExchangeTicksShow = (payload) => {
    return ({
        type: MAP_UI_RTDM_EXCHANGE_TICKS_SHOW,
        payload,
    });
};

export const storeUiRtdmExchangeTicksEnableRq = (payload) => {
    return ({
        type: MAP_RTDM_EXCHANGE_TICKS_ENABLE_RQ,
        payload,
    });
};

export const storeUiEntitiesVisibility = (payload) => {
    return ({
        type: MAP_UI_ENTITIES_VISIBILITY,
        payload,
    });
};

export const storeUiHeightFilter = (payload) => {
    return ({
        type: MAP_UI_HEIGHT_FILTER,
        payload,
    });
};

export const storePandoraTrafficEnabled = (payload) => {
    return ({
        type: PANDORA_TRAFFIC_ENABLED,
        payload,
    });
};

export const storePandoraLprTrafficEnabled = (payload) => {
    return ({
        type: PANDORA_LPR_TRAFFIC_ENABLED,
        payload,
    });
};

export const storePandoraCreotechTrafficEnabled = (payload) => {
    return ({
        type: PANDORA_CREOTECH_TRAFFIC_ENABLED,
        payload,
    });
};

export const storePandoraTrafficHeightFilter = (payload) => {
    return ({
        type: PANDORA_TRAFFIC_HEIGHT_FILTER,
        payload,
    });
};

export const runUiMapSetup = (payload) => {
    return ({
        type: MAP_SETUP_RQ,
        payload,
    });
};


export const mapClearSetupRq = (payload) => {
    return ({
        type: MAP_CLEAR_SETUP_RQ,
        payload,
    });
};


export const storeCallsignFloorNumberingEnabled = (payload) => {
    return ({
        type: MAP_UI_CALLSIGN_FLOOR_NUMBERING_ENABLED,
        payload,
    });
};
