import englishMessages from 'ra-language-english';
import lodash from "lodash"

// mutates messages
function overwriteWith(customMessages, messages) {
    lodash.each(
        customMessages,
        (value, path) => lodash.set(messages, path, value)
    );
}

const messages = {
    ...englishMessages
}

const customMessages = {
    'ra.notification.updated': 'Updated successfully'
}

overwriteWith(customMessages, messages);

export default messages;

