

export const CaaApplicationHoldStatuses = {
    HOLD_MEETING_REQUEST: 'meeting request',
    HOLD_MEETING_ACCEPTED: 'meeting accepted',
    HOLD_MEETING_REJECTED: 'meeting rejected'
};



export const CaaApplicationStatuses = {
    ACCEPTED: 'accepted',
    VERIFY: 'verify',
    VERIFY_AGAIN: 'verify again',
    REJECTED: 'rejected',
    CREATED: 'created',
    MODIFIED: 'modified',
    ...CaaApplicationHoldStatuses
};

export const CaaApplicationStatusesSelectInputArray = [
    {id: CaaApplicationStatuses.CREATED, name: 'resources.applications.status.created'},
    {id: CaaApplicationStatuses.ACCEPTED, name: 'resources.applications.status.accepted'},
    {id: CaaApplicationStatuses.REJECTED, name: 'resources.applications.status.rejected'},
    {id: CaaApplicationStatuses.VERIFY, name: 'resources.applications.status.pending'},
    {id: CaaApplicationStatuses.VERIFY_AGAIN, name: 'resources.applications.status.pendingAgain'},
    {id: CaaApplicationStatuses.MODIFIED, name: 'resources.applications.status.modified'},
    {id: CaaApplicationStatuses.HOLD_MEETING_ACCEPTED, name: 'resources.applications.status.meetingAccepted'},
    {id: CaaApplicationStatuses.HOLD_MEETING_REJECTED, name: 'resources.applications.status.meetingRejected'},
    {id: CaaApplicationStatuses.HOLD_MEETING_REQUEST, name: 'resources.applications.status.meetingRequest'},
];

//first iteration of backend status validation: dictionary
const govAllowedStatuses = [
    CaaApplicationStatuses.ACCEPTED,
    CaaApplicationStatuses.REJECTED,
    CaaApplicationStatuses.HOLD_MEETING_REQUEST,
];

const opAllowedStatuses = [
    CaaApplicationStatuses.HOLD_MEETING_ACCEPTED,
    CaaApplicationStatuses.HOLD_MEETING_REJECTED,
    CaaApplicationStatuses.VERIFY,
    CaaApplicationStatuses.VERIFY_AGAIN,
    CaaApplicationStatuses.CREATED,
    CaaApplicationStatuses.MODIFIED,
];

function isOpAllowedStatus(status) {
    return opAllowedStatuses.indexOf(status !== -1)
}

function isGovAllowedStatus(status) {
    return govAllowedStatuses.indexOf(status !== -1)
}
