import React from 'react';
import { AutocompleteInput, Filter, Responsive, SimpleList, Edit, Create, Datagrid,
    ReferenceField, TextField, EditButton, DisabledInput, LongTextInput, ReferenceInput, SelectInput, SimpleForm, RadioButtonGroupInput, SelectArrayInput, TextInput, EmailField, EmailInput, BooleanInput,  SimpleShowLayout, Show, ShowButton, FunctionField, required, minLength, maxLength, minValue, maxValue, number, regex, email, choices, translate} from 'react-admin';
import {dateFormatter, dateTimeFormatter} from "../../utils/momentHelpers";
import MyTextField from '../MyTextField';
import {canUpdate} from "../../logic/MyRestConfig";
import BasicEditActions from "../MyBasicEditActions";
import BasicListActions from "../MyBasicListActions";
import BasicShowActions from "../MyBasicShowActions";

import {isSuperAdmin} from "../../utils/tempCommon";
import MyTransitionDecorator from "../MyTransitionDecorator";
import {MyRecordTitle, MyResourceListTitle} from "../MyTitle";
import MyPagination from "../MyPagination";
import {ListDecorator as List} from "../ListDecorator";
import MyUnitGeomField from "./UnitCustomComponents/MyUnitGeomField";
import UnitSaveToolbar from "./UnitCustomComponents/UnitSaveToolbar";
import MyIlikeSearch from "./MyIlikeSearch";
import {FEATURES_V1_2_ENUMS, enabledFeatures} from "../../../Configs";
import {tax} from "../../utils/validationHelpers";

const UNIT_SHOW_JURISDICTION = enabledFeatures.includes(FEATURES_V1_2_ENUMS.UNIT_SHOW_JURISDICTION);


/*
Unit 20.04.18
{
    uid uuid NOT NULL DEFAULT gen_random_uuid(),
    created timestamp with time zone DEFAULT now(),
    name text COLLATE pg_catalog."default" NOT NULL,
    etc jsonb,
    address jsonb,
    contact text COLLATE pg_catalog."default",
    fullname text COLLATE pg_catalog."default",
    typ text COLLATE pg_catalog."default", -- !!!! not type , "typ"
    geometry geometry,
    CONSTRAINT unit_pkey PRIMARY KEY (uid)
}

typy
CAA/ULC
ANSP/PAŻP
LEA/SM czyli Law Enforcement Agency/ Służba Mundurowa czyli Policja, Straż graniczna, Straż wiezięnna.
LAU/JAS czyli Local Administrative Unit / Jednostka Administracji Samorządowej
OtherUnit / InnyUnit - typu porty, parki krajobrazowe i inne

: NOTE 30-05-19 .. port gdynia is LAU for now...
adding ANSP_TOWER i ANSP_FIS for local jurisdiction support for ansp units
*/

export const UNIT_TYPES = {
    CAA:'CAA',
    ANSP:'ANSP',
    LEA:'LEA',
    LAU:'LAU',
    ANSP_TOWER: 'ANSP_TOWER',
    ANSP_FIS: 'ANSP_FIS',
    OTHER: 'OTHER'
};

const typeChoices = [
    {id: UNIT_TYPES.CAA, name: 'CAA'},
    {id: UNIT_TYPES.ANSP, name: 'ANSP'},
    {id: UNIT_TYPES.ANSP_TOWER, name: 'ANSP_TOWER'},
    {id: UNIT_TYPES.ANSP_FIS, name: 'ANSP_FIS'},
    {id: UNIT_TYPES.LAU, name: 'LAU'},
    {id: UNIT_TYPES.OTHER, name: 'Other'},

];


const UnitsFilter = (props) => (
    <Filter {...props}>
        <MyIlikeSearch source="name"
                       label='resources.units.fields.name'/>
        <MyIlikeSearch source="typ" //TYP!
                       label='resources.units.fields.type'/>

    </Filter>
);

export const UnitsList = (props) => (
    <MyTransitionDecorator timeout={700} classNames="menucard" unmountOnExit>
        <PureUnitsList {...props}/>
    </MyTransitionDecorator>
);

//hackin pagination, (error in getUnits filter for non-superadmin govs)
export const PureUnitsList = ({permissions, ...props}) => {
    const hackPagination = !isSuperAdmin();
    const perPage = hackPagination?null:10; //default 10;
    return (
    <List {...props}
          filters={<UnitsFilter/>}
          //sort={{ field: 'created', order: 'DESC' }}
          bulkActions={false}
          actions={<BasicListActions />}
          title={<MyResourceListTitle resource={'units'}/>}
          pagination={<MyPagination/>}
        //perPage = {perPage}
    >
        <Responsive
            // small={
            //     <SimpleList
            //         primaryText={record => record.name}
            //         secondaryText={record =>
            //             permissions === 'test' ? record.status : null}
            //     />
            // }
            small={
                <Datagrid>
                    {/*atc columns reviewed in #96/98 issues*/}

                    {/*<MyTextField format={dateTimeFormatter}*/}
                                 {/*source="created"/>*/}
                    <TextField source="name" />
                    <TextField source="typ" />
                    {canUpdate(props.resource, permissions) && <EditButton />}
                    {!canUpdate(props.resource, permissions) && <ShowButton />}
                </Datagrid>
            }
        />
    </List>
)};

const UnitShowLayout = (props) => {
    const {translate, record} = props;

    return (
        <SimpleShowLayout {...props}
        >
            <MyTextField source="created"
                         format={dateTimeFormatter}
            />
            <TextField source="typ"/>

            <TextField source="name"/>

            <TextField source="fullname"/>

            <TextField source="contact"/>

            <TextField label={'myroot.company.tax'}
                       source="etc.tax"
            />

            <TextField source="address.line1"
                       label={translate('myroot.address.address') + ' 1'}
            />

            <TextField source="address.line2"
                       label={translate('myroot.address.address') + ' 2'}
            />

            <TextField source="address.town"
                       label={'myroot.address.town'}
            />

            <TextField source="address.zipcode"
                       label={'myroot.address.zipcode'}
            />

            <TextField source="address.country"
                       label={'myroot.address.country'}
            />
            {UNIT_SHOW_JURISDICTION && record.geojson ?
                <MyUnitGeomField enableEdit={false}/>
                : null
            }
        </SimpleShowLayout>
    )
};

export const UnitsShow = translate(
    ({permissions, translate, ...props}) => (
    <Show {...props}
          actions={<BasicShowActions/>}
          title={<MyRecordTitle resource={'units'}/>}
    >
        <UnitShowLayout translate={translate} />
    </Show>
));

export const UnitsCreate = translate(
    ({permissions, translate, ...props}) => (
    <Create {...props}
            /* undoable={false} -  no undo in create anyway */
    >
        <SimpleForm // form={'unit-create-form'} // must be default
                    defaultValue={{}}
        >
            <SelectInput source="typ"
                         validate={defaultRequired}
                         choices={typeChoices}/>
            <TextInput source="name"
                       validate={defaultRequired}/>
            <LongTextInput source="fullname"/>
            <LongTextInput source="contact" />

            <TextInput label={'myroot.company.tax'}
                       source="etc.tax"
                       validate={[tax]}
            />

            <TextInput label={translate('myroot.address.address') + ' 1'}
                       source="address.line1"
                       validate={defaultRequired}
            />
            <TextInput label={translate('myroot.address.address') + ' 2'}
                       source="address.line2"
                       validate={defaultNonRequired}
            />
            <TextInput label={'myroot.address.town'}
                       source="address.town"
                       validate={defaultRequired}
            />
            <TextInput label={'myroot.address.zipcode'}
                       source="address.zipcode"
                       validate={defaultRequired}
            />
            <TextInput label={'myroot.address.country'}
                       source="address.country"
                       validate={defaultRequired}
            />

            {/*there is a 1.2 feature unit create new endpoint, so.. not showing editable field before checking what's going on now*/}
            {/*<MyUnitGeomField enableEdit={false}/>*/}

        </SimpleForm>
    </Create>
));

const defaultRequired = [required(), minLength(2), maxLength(64)];
const defaultNonRequired = [minLength(2), maxLength(64)];

export const UnitsEdit = translate(
    ({permissions, translate, ...props}) => {

    // const hackPagination = !isSuperAdmin();
    // const perPage = hackPagination ? null : 10; //default 10;
    return (
        <Edit {...props}
              undoable={false}
              actions={<BasicEditActions/>}
              title={<MyRecordTitle resource={'units'}/>}
        >
            <SimpleForm //form={'unit-edit-form'}  // must be default
                defaultValue={{}}
                toolbar={<UnitSaveToolbar />}
            >
                <DisabledInput source="created"
                               format={dateTimeFormatter}
                               />
                {isSuperAdmin()
                    ? <SelectInput source="typ"
                                   validate={defaultRequired}
                                   choices={typeChoices}/>
                    : <DisabledInput source="typ" />
                }

                <TextInput source="name"
                           validate={defaultRequired}/>
                <LongTextInput source="fullname"/>
                <LongTextInput source="contact"/>

                <TextInput label={'myroot.company.tax'}
                           source="etc.tax"
                           validate={[tax]}
                />

                <TextInput label={translate('myroot.address.address') + ' 1'}
                           source="address.line1"
                           validate={defaultRequired}
                />
                <TextInput label={translate('myroot.address.address') + ' 2'}
                           source="address.line2"
                           validate={defaultNonRequired}
                />
                <TextInput label={'myroot.address.town'}
                           source="address.town"
                           validate={defaultRequired}
                />
                <TextInput label={'myroot.address.zipcode'}
                           source="address.zipcode"
                           validate={defaultRequired}
                />
                <TextInput label={'myroot.address.country'}
                           source="address.country"
                           validate={defaultRequired}
                />

                <MyUnitGeomField enableEdit={isSuperAdmin()}/>

            </SimpleForm>
        </Edit>
    )
});
