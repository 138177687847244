import React from 'react';
import {CheckboxGroupInput, maxLength, minLength, regex, required, SimpleForm, TextField, TextInput} from 'react-admin';

import {withStyles} from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TranslatableTypography from "../../TranslatableTypography";
import classnames from "classnames";
import {InfoText} from "../../InfoText";
import {panelStyles} from "./panelStyles";
import has from "lodash.has";
import {WarningText} from "../../WarningText";
import lodashMemoize from "lodash/memoize";
import {ACCOUNT_COMPANY_FORM, PANEL_COMPANY} from "../../../logic/settings/AccountConstants";
import {tax} from "../../../utils/validationHelpers";

const defaultNonRequired = [minLength(2), maxLength(64)];
const defaultRequired = [required(), minLength(2), maxLength(64)];

export const disclaimers = [
    {id: 'main', name: 'myroot.company.mainDisclaimer'},
];

const validateCheckboxGroupResolver = (...args) => {
    return args.join('_');
};

const checkboxGroupAllRequired =
    ((options = []) => lodashMemoize(
            Object.assign(
                (value, allValues, props) => {
                    const {translate} = props;
                    for(const option of options) {
                        if(!value.includes(option.id)) {
                            return translate('error.allCheckboxesRequired');
                        }
                    }
                    return undefined;
                },
                {isRequired: true}
            ), validateCheckboxGroupResolver
        )
    );


export default withStyles(panelStyles)(
    ({
         classes,
         mySelf,
         expanded,
         handleChange,
         handleSave,
         offline,
         disabled,
         translate,
         isGov
     }) => {
        const operatorCompanyPanel = () =>
            <ExpansionPanel expanded={expanded === PANEL_COMPANY}
                            onChange={handleChange(PANEL_COMPANY)}
                            id={PANEL_COMPANY}
                            className={classnames(
                                classes.expansionPanel,
                                {
                                    [classes.overflowHide]: expanded && expanded !== PANEL_COMPANY,
                                    [classes.expansionPanelNotActive]: expanded !== PANEL_COMPANY,
                                }
                            )}
                            square={false}
            >
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon/>}>
                    <div className={classes.block}>
                        <Typography component="p" className={classes.heading}>
                            {translate('myroot.company.data')}
                        </Typography>
                        <Typography component="p" className={classes.subheading}>
                            {translate('myroot.validation.optional')}
                        </Typography>
                    </div>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <div className={classes.block}>

                        <Typography component="p" className={classes.subheading}>
                            {translate('myroot.company.data')}
                        </Typography>
                        <SimpleForm form={ACCOUNT_COMPANY_FORM}
                                    save={handleSave}
                                    defaultValue={{
                                        etc: {
                                            company: {
                                                disclaimers: []
                                            }
                                        }
                                    }}
                                    record={has(mySelf, 'etc.company') ?
                                        {
                                            etc: {
                                                company: {
                                                    name: mySelf.etc.company.name,
                                                    tax: mySelf.etc.company.tax,
                                                    address: {
                                                        line1: mySelf.etc.company.address.line1,
                                                        line2: mySelf.etc.company.address.line2,
                                                        town: mySelf.etc.company.address.town,
                                                        zipcode: mySelf.etc.company.address.zipcode,
                                                        country: mySelf.etc.company.address.country,
                                                    },
                                                    disclaimers: mySelf.etc.company.disclaimers ? mySelf.etc.company.disclaimers : []
                                                }
                                            }
                                        }
                                        : undefined}
                                    redirect={false}
                                    toolbar={disabled ? null : undefined}
                        >
                            <TextInput label={'myroot.company.name'}
                                       source="etc.company.name"
                                       validate={defaultRequired}
                                       {...{disabled}}
                            />
                            <TextInput label={'myroot.company.tax'}
                                       source="etc.company.tax"
                                       validate={[...defaultRequired, tax]}
                                       {...{disabled}}
                            />
                            <TextInput label={translate('myroot.address.address') + ' 1'}
                                       source="etc.company.address.line1"
                                       validate={defaultRequired}
                                       {...{disabled}}
                            />
                            <TextInput label={translate('myroot.address.address') + ' 2'}
                                       source="etc.company.address.line2"
                                       validate={defaultNonRequired}
                                       {...{disabled}}
                            />
                            <TextInput label={'myroot.address.town'}
                                       source="etc.company.address.town"
                                       validate={defaultRequired}
                                       {...{disabled}}
                            />
                            <TextInput label={'myroot.address.zipcode'}
                                       source="etc.company.address.zipcode"
                                       validate={defaultRequired}
                                       {...{disabled}}
                            />
                            <TextInput label={'myroot.address.country'}
                                       source="etc.company.address.country"
                                       validate={defaultRequired}
                                       {...{disabled}}
                            />
                            <CheckboxGroupInput
                                className={classes.disclaimer}
                                classes={{
                                    label: classes.checkboxLabel
                                }}
                                source="etc.company.disclaimers"
                                label={'myroot.company.disclaimers'}
                                choices={disclaimers}
                                validate={[checkboxGroupAllRequired(disclaimers)]}
                                {...{disabled}}
                            />
                        </SimpleForm>
                        {
                            isGov ?
                                null
                                :
                                <>
                                    {!mySelf.userState.hasCompany &&
                                    <WarningText colored
                                                 content={<TranslatableTypography
                                                     content="myroot.auth.sendLockedNoCompany"/>}
                                                 className={classnames({
                                                     [classes.disabledText]: expanded === PANEL_COMPANY,
                                                     [classes.disabledTextHide]: expanded !== PANEL_COMPANY,
                                                 })}
                                    />
                                    }

                                    {disabled && !offline &&
                                    <InfoText content={<TranslatableTypography content="myroot.auth.editionLocked"/>}
                                              className={classnames({
                                                  [classes.disabledText]: expanded === PANEL_COMPANY,
                                                  [classes.disabledTextHide]: expanded !== PANEL_COMPANY,
                                              })}

                                    />
                                    }
                                    {disabled && offline &&
                                    <InfoText
                                        content={<TranslatableTypography content="myroot.auth.editionLockedOffline"/>}
                                        className={classnames({
                                            [classes.disabledText]: expanded === PANEL_COMPANY,
                                            [classes.disabledTextHide]: expanded !== PANEL_COMPANY,
                                        })}

                                    />
                                    }
                                </>
                        }

                    </div>
                </ExpansionPanelDetails>
            </ExpansionPanel>;

        // for 1.1 only view of unit data
        const govCompanyPanel = () =>
            <>
                {
                    mySelf.units.map(
                        (unit, idx) =>
                            <ExpansionPanel expanded={expanded === `${PANEL_COMPANY}${idx}`}
                                            onChange={handleChange(`${PANEL_COMPANY}${idx}`)}
                                            id={`${PANEL_COMPANY}${idx}`}
                                            className={classnames(
                                                classes.expansionPanel,
                                                {
                                                    [classes.overflowHide]: expanded && expanded !== `${PANEL_COMPANY}${idx}`,
                                                    [classes.expansionPanelNotActive]: expanded !== `${PANEL_COMPANY}${idx}`,
                                                }
                                            )}
                                            key={`${PANEL_COMPANY}${idx}`}
                                            square={false}
                            >
                                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon/>}>
                                    <div className={classes.block}>
                                        <Typography component="p" className={classes.heading}>
                                            {translate('resources.units.data') + ' ' + unit.name}
                                        </Typography>
                                        <Typography component="p" className={classes.subheading}>
                                            {translate('myroot.action.viewOnly')}
                                        </Typography>
                                    </div>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                    <div className={classes.block}>

                                        {/*<Typography component="p" className={classes.subheading}>*/}
                                        {/*    {translate('myroot.company.data')}*/}
                                        {/*</Typography>*/}
                                        <SimpleForm form={`${ACCOUNT_COMPANY_FORM}-${idx}`}
                                                    save={handleSave}
                                                    redirect={false}
                                                    record={unit}
                                            // toolbar={disabled ? null : undefined}
                                                    toolbar={null}
                                        >
                                            <TextField label={'myroot.company.name'}
                                                       source='name'
                                                       validate={defaultRequired}
                                                       {...{disabled}}
                                            />
                                            <TextField label={'resources.units.fields.fullname'}
                                                       source='fullname'
                                                // validate={defaultRequired}
                                                // {...{disabled}}
                                            />
                                            <TextField label={'resources.units.fields.contact'}
                                                       source='contact'
                                                // validate={defaultRequired}
                                                // {...{disabled}}
                                            />
                                            <TextField label={'resources.units.fields.type'}
                                                       source='typ'
                                                // validate={defaultRequired}
                                                // {...{disabled}}
                                            />
                                            <TextField label={'myroot.company.tax'}
                                                       source="etc.tax"
                                                // validate={[...defaultRequired,
                                                //     regex(/((\d{3}-\d{3}-\d{2}-\d{2})|(\d{3}-\d{2}-\d{2}-\d{3}))$/, 'error.TaxNumber')
                                                // ]}
                                                // {...{disabled}}
                                            />
                                            <TextField label={translate('myroot.address.address') + ' 1'}
                                                       source="address.line1"
                                                // validate={defaultRequired}
                                                // {...{disabled}}
                                            />
                                            <TextField label={translate('myroot.address.address') + ' 2'}
                                                       source="address.line2"
                                                // validate={defaultNonRequired}
                                                // {...{disabled}}
                                            />
                                            <TextField label={'myroot.address.town'}
                                                       source="address.town"
                                                // validate={defaultRequired}
                                                // {...{disabled}}
                                            />
                                            <TextField label={'myroot.address.zipcode'}
                                                       source="address.zipcode"
                                                // validate={defaultRequired}
                                                // {...{disabled}}
                                            />
                                            <TextField label={'myroot.address.country'}
                                                       source="address.country"
                                                // validate={defaultRequired}
                                                // {...{disabled}}
                                            />
                                        </SimpleForm>
                                    </div>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                    )
                }
            </>;


        return isGov ? govCompanyPanel() : operatorCompanyPanel()
    }
);