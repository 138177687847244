import React from 'react';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import PubSub from "pubsub-js";
import {connect} from "react-redux";
import compose from 'recompose/compose';

import logger from '../../LogicV1Redux/oldLogic/Logger'
import Inspector from 'react-inspector';

import {PubSubSignals} from '../../LogicV1Redux/constants/PubSubSignals'
import withStyles from "@material-ui/core-v3/styles/withStyles";
import inspectData from "../../LogicV1Redux/reducers/InspectDataReducer";
import {Rnd} from "react-rnd";


const cssFix = {
    maxHeight: '100%',
    overflowY: 'auto'
};

const infoRenderer = (data) => {
    console.log('infoRenderer', data);

    // for (const [key, value] of Object.entries(data)) {
    //     console.log(key, value);
    //     let newPath = Object.assign({}, samplePath);
    //     newPath.path = value;
    //     newPath.color = [0,0,255,255];
    //     paths.push(newPath);
    // }
    //
    // return data.map(el => {
    //     console.log(el);
    //     const out = el.toString();
    //     return <div>{out}</div>
    // })
    let ret = '';

    Object.entries(data).forEach(([key, value]) => {
        console.log(`${key} ${value.toString()}`);
        ret +=  `${key}:${value.toString()}\n`;
    });
    return ret;
};

class InspectorWindowBase extends React.Component {
    // constructor() {
    //     super();
    //     this.state = {info : {data:' here', data2:'test'}};
    // };

    // componentWillMount() {
    //
    //     this.pubsubInfo = PubSub.subscribe(PubSubSignals.INSPECT_DATA, (msg, data) => {
    //         //logger.debug('pubsub', msg, data);
    //         //console.log('pubsub', msg, data);
    //         this.setState({info:data});
    //     });
    //
    //     // this.pubsubTick = PubSub.subscribe(PubSubSignals.TICK, (msg, data) => {
    //     //     this.setState({currTime:data.unixTime})
    //     // });
    // }
    //
    // componentWillUnmount() {
    //     PubSub.unsubscribe(this.pubsubInfo);
    //     // PubSub.unsubscribe(this.pubsubTick)
    //
    // }

    render() {
        //console.log(this.state.info);

        let {inspectData} = this.props;
        return(
            <div style = {cssFix}>
                 <Inspector
                     name = {'inspect'}
                     expandLevel = {2}
                     data={inspectData}
                 />
            </div>
        )
    }
}

const mapStateToProps = state => {
    //console.log('InspectorWindow mapStateToProps', state);
    return {
        //myAuth : state.myAuth,
        //mySelf : state.mySelf,
        //myAppExtras : state.myAppExtras,
        inspectData: state.inspectData
    }
};

const enhance = compose(
    //translate,
    //withStyles(styles),
    connect(mapStateToProps)
);

export const InspectorWindow = enhance(InspectorWindowBase);


export const RndInspectorWnd = ({viewportData}) => {
    console.error('fix css!, without sizeMe if possible!')
    return (
        <Rnd
            style={{overflowY: "auto"}}
            className='rnd-window'
            default={{
                x: 10,
                //y: viewportData.height - 330,
                width: 240,
                height: 320,
            }}
            maxHeight="95%"
            bounds="parent"
        >
            <InspectorWindow/>
        </Rnd>
    );
};