import React from "react";
import WelcomeScreen from "./WelcomeScreen";


/**
 * Manager for WelcomeScreens. Queues each screen in array and shows screens in rising order once.
 * @param welcomeScreens array of WelcomeScreen component instances:
 */
class WelcomeScreensManager extends React.Component {

    state = {
        isOpen: -1
    };

    _setNextDialog = () => {
        //iterate to first screen which should be shown
        for(let i = this.state.isOpen+1; i<this.props.welcomeScreens.length; i++) {
            const {props} = this.props.welcomeScreens[i];
            if(props.shouldShow()) {
                this.setState({
                    isOpen: i,
                });
                return;
            }
        }
        //no more screens to show found, show no one
        this.setState({
            isOpen: -1,
        });
    };

   _handleClose = (props) => {
        props.onClose();
        this._setNextDialog();
    };

    _handleConfirm = (props) => {
        props.onConfirm();
        this._setNextDialog();
    };

    componentDidMount() {
       this._setNextDialog()
    }

    render() {
        const {welcomeScreens} = this.props;
        const {isOpen} = this.state;

        return welcomeScreens.map((welcomeScreen, idx) => {
                const props = welcomeScreen.props;
                if (props.shouldShow())
                    return React.cloneElement(welcomeScreen, {
                            key: idx,
                            isOpen: isOpen === idx,
                            onClose: () => {
                                this._handleClose(props)
                            },
                            onConfirm: () => {
                                this._handleConfirm(props)
                            },
                        });
                else {
                    return null;
                }
            }
        );
    }
}

const welcomeScreenArrayPropType = (props, propName, componentName) => {
    let error = null;
    const prop = props[propName];
    if (!Array.isArray(prop)) {
        error= new Error('`' + componentName + `\` prop ${propName} should be an array`);

    }
    prop.map((el) => {
            if (el.type !== WelcomeScreen) {
                error = new Error('`' + componentName + `\` prop ${propName} should be an array of elements of type 'WelcomeScreen'`);
            }
        }
    );
    return error;
};

WelcomeScreensManager.propTypes = {
    welcomeScreens: welcomeScreenArrayPropType
};

export default WelcomeScreensManager;