import ShowWindow from "../../rt_map_ra2/components/prepared-components/ShowWindow";
import PortalWindow from "../../rt_map_ra2/components/prepared-components/PortalWindow";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import compose from 'recompose/compose';
import React from "react";
import {toggleEventsStreamWindow} from "../logic/ui/MyUiActions";
import { translate } from 'ra-core';
import IconButton from "@material-ui/core/IconButton";
import {OpenInNew} from "@material-ui/icons";
import {urls} from "../../Configs";
import PubSub from "pubsub-js";
import {PubSubSignals} from "../../LogicV1Redux/constants/PubSubSignals";
import moment from "moment";
import * as ReactDOM from "react-dom";
import {withStyles} from "@material-ui/core";
import {VariableSizeList} from "react-window";

const GUTTER = 5;
const LINE_HEIGHT = 14;
const FONT_SIZE = 13;
const VIRTUAL_LIST_IN_SHOW_WINDOW_HEIGHT = 400;
const VIRTUAL_LIST_IN_SHOW_WINDOW_WIDTH = 800;

const styles = {
    customCard: {
        backgroundColor: "black",
    },
    white: {
        color: "white"
    },
    '@keyframes blink': {
        '100%': {
            opacity: 1,
        },
    },
    connectedCursor: {
        opacity: 0,
        fontFamily: 'monospace',
        animation: "blink 0.8s cubic-bezier(1, -1, 0, 1) infinite",
        margin: `${LINE_HEIGHT}px 0`,
    },
    text: {
        fontFamily: 'monospace',
        lineHeight: `${LINE_HEIGHT}px`,
        fontSize: `${FONT_SIZE}px`,
        wordBreak: 'break-all'
    },
    issuerText: {
        color: "#3cb371"
    },
    portal: {
        marginLeft: "1rem",
        marginRight: "3rem",
        overflowWrap: "break-word",
    },
    showWindow: {
        minWidth: `${VIRTUAL_LIST_IN_SHOW_WINDOW_WIDTH+60}px`,
        minHeight: `${VIRTUAL_LIST_IN_SHOW_WINDOW_HEIGHT+170}px`,
        overflowWrap: 'break-word',
    }
};

const getInitialPortalWindowSizes = () => ({
    height: window.innerHeight / 1.3,
    width: window.innerWidth / 2
});

class EventsStreamWindow extends React.Component {

    showContentRef = React.createRef();
    portalContainer = document.createElement('div');
    listRef = React.createRef();

    state = {
        messages: [],
        unloading: false,

        portalWindowSize: {
            width: getInitialPortalWindowSizes().width,
            height: getInitialPortalWindowSizes().height
        },

        showWindowSize: {
            width: VIRTUAL_LIST_IN_SHOW_WINDOW_WIDTH,
            height: VIRTUAL_LIST_IN_SHOW_WINDOW_HEIGHT,
        }
    };

    componentDidMount() {

        this._pubSubMsg = PubSub.subscribe(PubSubSignals.LOG_PUBLISHED, (msg, data) => {
            const {classes} = this.props;

            const {action, who, row = {}, timestamp: serverTime} = data;
            const {action: action2, diff, govid, operatorid, subject, uid} = row;

            const timestamp = serverTime ? serverTime : `${moment().format()}*`;
            const id = govid ? govid : operatorid;
            const item =
                <span key={action + timestamp} className={classes.text}>
                    {timestamp} - <span className={classes.issuerText}>{who.toUpperCase()}</span>:&nbsp;
                    <b>{action.toUpperCase()}</b> {JSON.stringify(diff)}&nbsp;- {who}id: {id} - subject: {subject}
                </span>;

            const newMessages = [...this.state.messages];
            newMessages.push(item);

            this.setState({
                messages: newMessages,
            });
        });

        //TODO setting portal window styles, for now like this
        this.portalContainer.style.maxHeight = '100%';
        this.portalContainer.style.overflow = 'hidden';
        this.portalContainer.style.backgroundColor = 'black';
        this.portalContainer.style.color = 'white';
        this.portalContainer.style.height = '100%';

        window.addEventListener('unload', () => {
            this.setState({
                unloading: true
            })
        });
    }

    componentWillUnmount() {
        PubSub.unsubscribe(this._pubSubMsg);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if(this.listRef.current && this.state.messages.length > 0) {
            this.listRef.current.scrollToItem(this.state.messages.length-1)
        }

    }

    _calcNodeCharLength = (node) => {
        let length = 0;
        for(const child of node.props.children) {
            if(typeof child === 'string') {
                length += child.length;
            }
            if(React.isValidElement(child)) {
                length += this._calcNodeCharLength(child)
            }
        }
        return length;
    };

    _detachToNewWindow = () => {
        // timeout needed for position state update in rnd before unmount
        setTimeout(
            () => this.props.toggleEventsStreamWindow({show: false, portal: true}),
            10
        );
    };

    render() {
        const {open, portalOpen, toggleEventsStreamWindow, translate, resource, socketConnected, classes} = this.props;
        const {unloading, messages} = this.state;

        const title = resource && translate(`resources.${resource}.liveWindow`).toUpperCase() + ' - ' + urls.apiBaseV2;
        const connIndicator =  socketConnected && !unloading ? <span className={classes.connectedCursor}>_</span> : <span className={classes.text}>{`<<NO CONNECTION>>`}</span>;

        const Row = ({index, style}) =>
            <div style={{
                ...style,
                width: "98%"
            }}>
                {this.state.messages[index]}
                {
                    index === messages.length - 1 &&
                    <>
                        <br/>
                        <br/>
                        {connIndicator}
                    </>
                }
            </div>;

        const getItemSize = width => index => {
            const charsLength = this._calcNodeCharLength(messages[index]);
            const charLineCapacity = 21 * width / 202;

            let lines = Math.ceil(charsLength / charLineCapacity);

            // some lines more for scroll bar and additionally one for width lower than 377
            if(width > 377) {
                lines += 1;
            }
            else {
                lines += 3;
            }
            // if last entry add lines for connIndicator
            if(index === messages.length-1){
                lines += 2;
            }

            const height = lines*14+2*GUTTER;
            console.log("get item sizes", charsLength, charLineCapacity, lines, height);
            return height;
        };

        const VirtualList = ({height, width}) =>
                        <VariableSizeList
                            height={height}
                            width={width}
                            itemCount={messages.length}
                            itemSize={getItemSize(width)}
                            ref={this.listRef}
                        >
                            {Row}
                        </VariableSizeList>;


        return (
            <>
                <ShowWindow title={title}
                            content={
                                <>
                                    {
                                        messages.length > 0 ?
                                            <VirtualList height={this.state.showWindowSize.height}
                                                         width={this.state.showWindowSize.width}/>
                                            :
                                            connIndicator
                                    }
                                </>
                            }
                            open={open}
                            onClose={() => toggleEventsStreamWindow({show: false})}
                            rndProps={{
                                onDragStop: (e, d) => {
                                    if (d.x > window.outerWidth) {
                                        this._detachToNewWindow();
                                    }
                                },
                                default: {
                                    x: 20,
                                    y: 60,
                                }
                            }}
                            actions={
                                <>
                                    <IconButton
                                        onClick={this._detachToNewWindow}
                                    >
                                        <OpenInNew/>
                                    </IconButton>
                                </>
                            }
                            contentRef={this.showContentRef}
                            onResize={
                                ({width, height}) => {
                                    const nWidth = parseInt(width);
                                    const nHeight = parseInt(height);

                                    this.setState({
                                        showWindowSize: {
                                            width: nWidth > VIRTUAL_LIST_IN_SHOW_WINDOW_WIDTH+60 ? nWidth-60 : VIRTUAL_LIST_IN_SHOW_WINDOW_WIDTH,
                                            height: nHeight > VIRTUAL_LIST_IN_SHOW_WINDOW_HEIGHT+170 ? nHeight-170 : VIRTUAL_LIST_IN_SHOW_WINDOW_HEIGHT,
                                        }
                                    })}}
                            classes={{window: classes.showWindow}}
                            initialSize={{
                                height: VIRTUAL_LIST_IN_SHOW_WINDOW_HEIGHT+170,
                                width: VIRTUAL_LIST_IN_SHOW_WINDOW_WIDTH+60
                            }}
                />
                {portalOpen &&
                <PortalWindow
                    windowProps={[
                        `height=${getInitialPortalWindowSizes().height}`,
                        `width=${getInitialPortalWindowSizes().width}`,
                        'left=10',
                        'top=10',
                        'resizable=yes',
                        'scrollbars=yes',
                        'toolbar=no',
                        'menubar=no',
                        'location=no',
                        'directories=no',
                        'status=no',
                        'location=no'
                    ]}
                    onClose={() => toggleEventsStreamWindow({show: false, portal: false})}
                    title={title}
                    containerEl={this.portalContainer}
                    onResize={({width, height}) => this.setState({
                        portalWindowSize: {
                            width,
                            height
                        }
                    })}
                >
                    <div className={classes.portal}>
                        {
                            messages.length > 0 ?
                                <VirtualList height={this.state.portalWindowSize.height} width={this.state.portalWindowSize.width-16}/>
                                :
                                connIndicator
                        }
                    </div>
                </PortalWindow>
                }
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        open: state.myUi.eventsStreamWindow.visible,
        resource:  state.myUi.eventsStreamWindow.resource,
        portalOpen: state.myUi.eventsStreamWindow.portal,
        socketConnected: state.socket.connected
    }
};


const mapDispatchToProps = dispatch =>
    (
        {
            toggleEventsStreamWindow: bindActionCreators(toggleEventsStreamWindow, dispatch),
        }
    );

export default compose(
    translate,
    withStyles(styles),
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(EventsStreamWindow);