import {Toolbar} from "ra-ui-materialui";
import Zoom from "@material-ui/core/Zoom";
import {MissionV2Statuses} from "../../../../LogicV1Redux/constants/MissionsV2Enums";
import React from "react";
import TranslatableTypography from '../../TranslatableTypography';
import MissionSaveActionsButton from "./MissionSaveActionsButton";
import FlexDiv from "../../FlexDiv";
import { translate } from 'react-admin';
import {compose} from 'recompose';
import Delay from "react-delay";


const MissionEditSaveToolbar = (props => {
    console.warn('==========MissionEditSaveToolbar props ', props);
    const {translate, mySelf, ...rest} = props;
    //TODO refactor to permissions

    //Safety valve. Do not render toolbar if finally mission went to accepted.
    if(rest.record.status ===  MissionV2Statuses.ACCEPTED) return null;
    //Safety valve. Do not render toolbar if mission is awaiting for acceptance.
    if(rest.record.status ===  MissionV2Statuses.PLANNED) return null;

    return (

        <Toolbar>
            {/*TODO hack for mission field crash issue, prevents sending mission to acceptance without mission filed mounted yet*/}
            <Delay wait={700}>
                <MissionSaveActionsButton
                    //submitOnEnter={true}
                    {...rest}
                    label={'ra.action.save'}

                />
                <FlexDiv/>
                <MissionSaveActionsButton
                    {...rest}
                    setStatus={MissionV2Statuses.PLANNED}
                    label={'myroot.action.sendForAccept'}
                />
            </Delay>
        </Toolbar>
    );
});

export default compose(translate)(MissionEditSaveToolbar)