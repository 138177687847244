import React from "react";
import {OperatorPrivileges} from "../../../utils/operatorHelpers";
import U112Chip from "./U112Chip";
import SuavoChip from "./SuavoChip";
import classnames from "classnames";
import {withStyles} from "@material-ui/core";
import Configs, {FEATURES_V1_2_ENUMS} from "../../../../Configs";

const OPERATOR_PRIVILEGES_ENABLED = Configs.enabledFeatures.includes(FEATURES_V1_2_ENUMS.OPERATOR_PRIVILIGES);

const styles = {
    root: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        '& > :not(:only-child):not(:last-child)': {
            marginRight: "0.7rem"
        },
        '& > *': {
            margin: "0.5rem 0"
        }
    }
};

const PrivilegesField = ({className, classes, record, source}) => {

        if (!OPERATOR_PRIVILEGES_ENABLED) return null;

        const priv = record[source];
        const privileges = new OperatorPrivileges(priv);

        if (!privileges.isEither(OperatorPrivileges.FLAG_112UAVO | OperatorPrivileges.FLAG_SUAVO)) return null;

        return (
            <div className={classnames(classes.root, className)}>
                {privileges.is112() && <U112Chip/>}
                {privileges.isSuavo() && <SuavoChip/>}
            </div>
        )
    }
;

export default withStyles(styles)(PrivilegesField)

