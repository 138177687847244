import React from "react";
import { connect } from "react-redux";
import TranslatableTypography from '../../TranslatableTypography'
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import ConflictsGovRenderer from "./ConflictsGovRenderer"
import ConflictsOperatorRenderer from "./ConflictsOperatorRenderer"
import { compose } from 'recompose';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

class MissionConflictsSwitch extends React.Component {
    state = {
        filtering: false,
    };

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    render() {
        const { myAppExtras, conflicts, ...rest } = this.props;
        const { filtering } = this.state;

        const isGov = myAppExtras.appMode === 'GOV';

        console.log('MissionConflictsSwitch', conflicts);

        if (!conflicts || conflicts.length === 0)
            return null;
        //duh
        const shownConflicts = (filtering)
            ? conflicts.filter(x => x.status === null)
            : conflicts;

        const data = { filteredConflicts: shownConflicts }

        return (
            <div>
                <ExpansionPanel>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                        <TranslatableTypography
                            component='span'

                            content={'resources.zoneLegs.conflicts'} />
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <Select
                            value={filtering}
                            onChange={this.handleChange}
                            //displayEmpty
                            name="filtering"
                        //className={classes.selectEmpty}
                        >
                            <MenuItem value={true}>
                                <TranslatableTypography
                                    component='span'
                                    color="error"
                                    content={'resources.zoneLegs.unresolvedConflicts'} />
                            </MenuItem>
                            <MenuItem value={false}>
                                <TranslatableTypography
                                    component='span'
                                    //color="error"
                                    content={'resources.zoneLegs.allConflicts'} />
                            </MenuItem>
                        </Select>
                    </ExpansionPanelDetails>
                    <ExpansionPanelDetails>

                        {
                            (isGov)
                                ? <ConflictsGovRenderer shownConflicts={data} {...rest} />
                                : <ConflictsOperatorRenderer shownConflicts={data} {...rest} />
                        }
                    </ExpansionPanelDetails>
                </ExpansionPanel>



            </div>
        )
    }

}

const mapStateToPropsConflictsViewer = state => {
    return {
        //mySelf : state.mySelf,
        myAppExtras: state.myAppExtras,
        //inspectData: state.inspectData,

    }
};

export default compose(
    //translate,
    connect(
        mapStateToPropsConflictsViewer,
    ),
    //withStyles(styles)
)(MissionConflictsSwitch);