
import React from "react";

import UnitViewerMapUI from '../../../../gl_map/Components/Maps/UnitGeomViewerMapUI';
import MyGeomOutput from "../../MyGeomOutput"
//currently the same as ZoneViewer,

const UnitGeomViewer = (props) => {

    const {
        record,
        helpers,
        conflicts,

        bBox,
        ...rest
    } = props;


    const wrapperStyle = {position: "relative", height: "500px"};

    return (
        <UnitViewerMapUI
            bBox = {bBox}
            //inspectData = {inspectData}
            wrapperStyle = {wrapperStyle}
            record = {record}
        />
    );
};

export default UnitGeomViewer;
