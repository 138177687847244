import React from "react";
import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import MyLayout from "./MyLayout";
import MySidebar from  "./MySidebar"
import MyAppBar from './MyAppBar';
import {Loading} from "ra-ui-materialui";
import {withStyles} from '@material-ui/core';
import {connect} from 'react-redux';
import compose from 'recompose/compose';
import classnames from "classnames";
import Backdrop from "@material-ui/core/Backdrop/Backdrop";


export const LayoutScrollBottomContext = React.createContext(null);


const styles = theme => ({
    blur: {
        filter: "blur(5px)"
    },
    loading: {
        position: "absolute",
        zIndex: "1",
        top: "0",
        alignSelf: "center",
    },
    root: {
        transition: "0.3s filter linear",
        willChange: "content",
        // place for alert and info box fab
        marginBottom: "5rem"
    },
    message: {
        color: theme.palette.primary.contrastText
    },
    backdrop: {
        backgroundColor: "unset",
        zIndex: 1000,
        top: 'unset',
        left: 'unset',
        bottom: 'unset',
        right: 'unset',
        width: "100%",
        height: "100%"
    }
});


const mapStateToProps = state => {
    return {
        loading: state.myUi.loading,
        loadingMessageBundle: state.myUi.loadingMessageBundle,
    }
};

//TODO during refactoring move loading to MyLayout
const PureLayoutWithLoading =
    ({classes, loading, loadingMessageBundle, dispatch, onLayoutScroll, ...props}) => {
        return (
            <MyLayout  {...props} sidebar={MySidebar} appBar={MyAppBar} onScroll={onLayoutScroll}>
                <div className={loading > 0 ? classes.blur : classes.root}>
                    {props.children}
                </div>
                {loading > 0 &&
                <>
                    <Backdrop open className={classes.backdrop}/>
                    <Loading loadingPrimary={loadingMessageBundle.primaryMessage}
                             loadingSecondary={loadingMessageBundle.secondaryMessage}
                             className={classnames(classes.loading, classes.message)}

                    />
                </>
                }
            </MyLayout>
        )
    };

const LayoutWithLoading = compose(
    withStyles(styles),
    connect(mapStateToProps)
)(PureLayoutWithLoading);



export default class MyLayoutThemeDecorator extends React.Component {

    state = {
        scrollBottom: false,
    };


    _onScroll = (ev) => {
        const {scrollBottom} = this.state;

        const element = ev.target;
        if (element.scrollTop >= (element.scrollHeight - element.clientHeight)) {
            if(!scrollBottom) {
                this.setState({
                    scrollBottom: true,
                })
            }
        }
        else {
            if(scrollBottom) {
                this.setState({
                    scrollBottom: false,
                })
            }
        }
    };

    provideTheme = (theme, childeren) => {
        if(theme) {
            return (
                <MuiThemeProvider theme={this.props.theme}>
                    {childeren}
                </MuiThemeProvider>
            )
        }
        else {
            return (
                <div>
                    {childeren}
                </div>
            )
        }
    };

    render() {
        const {theme} = this.props;
        return (
            <LayoutScrollBottomContext.Provider value={this.state.scrollBottom}>
                {this.provideTheme(theme, <LayoutWithLoading onLayoutScroll={this._onScroll} {...this.props}/>)}
            </LayoutScrollBottomContext.Provider>
        )
    }
};