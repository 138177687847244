import {STORE_LOOPBACK_VERSION, RODO_OPENED} from "../constants/MyAppExtrasConstants";

export const storeLoopbackVersion = (payload) => {
    //console.log('====== start TIMER_START_RQ saga', payload);
    return ({
        type: STORE_LOOPBACK_VERSION,
        payload,
    });
};

export const rodoOpened = (payload) => {
    return ({
        type: RODO_OPENED,
        payload,
    });
};