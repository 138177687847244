import React from 'react';
export const helpPage = (
    <div id="help-content">
        <h2>Pomoc</h2>
            Wsparcie merytoryczne w zakresie przepisów wykonywania lotów BSP,
            ustalonych w PAŻP procedur jak i obsługi systemu zapewniają <br/> pracownicy
            Działu Zarządzania Operacjami Bezzałogowych Statków Powietrznych (OSU):
        <ul>
            <li>
                tel. wewn. PAŻP: <b>5715</b>, <b>7553</b>, <b>5741</b>, <b>5749</b>
            </li>
            <li>
                mail: <a href="mailto:drony@pansa.pl">drony@pansa.pl</a>
            </li>
        </ul>
        <br/>

        Wsparcie techniczne systemu zapewnia Dyżurny Technik PAŻP:
        <ul>
            <li>
                tel. wewn. PAŻP: <b>6666</b> (czynny H24/7)
            </li>
            <li>
                mail: <a href="mailto:dyzurny.at@pansa.pl">dyzurny.at@pansa.pl</a>
            </li>
            <li>
                tel. zewn.: <b>+48 22 574 66 66</b>
            </li>
        </ul>
    </div>
);

export const richText = {
    title: `<h2>Pomoc</h2>`,
    content: `Wsparcie merytoryczne w zakresie przepisów wykonywania lotów BSP, 
    ustalonych w PAŻP procedur jak i obsługi systemu zapewniają pracownicy
    Działu Zarządzania Operacjami Bezzałogowych Statków Powietrznych (OSU):
    <ul>
        <li>
            tel. wewn. PAŻP: <b>5715</b>, <b>7553</b>, <b>5741</b>, <b>5749</b>
        </li>
        <li>
            mail: <a href="mailto:drony@pansa.pl">drony@pansa.pl</a>
        </li> 
    </ul>
    <br/>

    Wsparcie techniczne systemu zapewnia Dyżurny Technik PAŻP:
    <ul>
        <li>
            tel. wewn. PAŻP: <b>6666</b> (czynny H24/7) 
        </li>
        <li>
            mail: <a href="mailto:dyzurny.at@pansa.pl">dyzurny.at@pansa.pl</a>
        </li>
        <li>
            tel. zewn.: <b>+48 22 574 66 66</b>
        </li>
    </ul>
    `,
};
