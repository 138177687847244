//hmm, if filters w/modelId should be passed in options in methods, should also support.. not used now (query for now)
//const fixModelId = (modelId, url, options) => {
export const fixModelId = (modelId, url) => {
    return (modelId)?url.replace('{modelId}', modelId):url;
};

export const fixReferenceId = (referenceId, url) => {
    return (referenceId)?url.replace('{referenceId}', referenceId):url;
};

export const fixUserId = (userId, url) => {
    return (userId)?url.replace('{userId}', userId):url;
};

export const fixFileName = (fileName, url) => {
    return (fileName)?url.replace('{fileName}', fileName):url;
};
