import {
    MY_STORE_SETAUTH,
    MY_STORE_SETSELF,
    MY_STORE_CLEARSELF,
    MY_STORE_CLEARAUTH,

    MY_AUTHSAGA_REGISTER_RQ,
    MY_AUTHSAGA_REGISTERCONFIRM_RQ,
    MY_AUTHSAGA_RESETPASS_RQ,
    MY_AUTHSAGA_NEWPASS_RQ,
    MY_AUTHSAGA_LOADSELF_RQ,
    MY_AUTHSAGA_NEWPHONE_RQ,
    MY_AUTHSAGA_PHONECODEVERIFY_RQ,
    MY_AUTHSAGA_NICKNAME_RQ,
    MY_AUTHSAGA_SAVESELF_GOV_RQ,
    MY_AUTHSAGA_SAVESELF_OPERATOR_RQ
} from "./MyAuthConstants";


export const myAuthSetStore = (payload) => {
    console.log('myAuthSetStore (token etc into persistable store) action here , ', payload);
    return ({
        type: MY_STORE_SETAUTH,
        payload,
        //meta: { auth: true, pathName },
        //meta: {myauth:true}
    });
};

export const myAuthClearAuth = (payload) => {
    console.log('myAuthStoreSelf (user data into store) action here , ', payload);
    return ({
        type: MY_STORE_CLEARAUTH,
        payload,
        //meta: { auth: true, pathName },
        //meta: {myauth:true}
    });
};

export const myAuthStoreSelf = (payload) => {
    console.log('myAuthStoreSelf (user data into store) action here , ', payload);
    return ({
        type: MY_STORE_SETSELF,
        payload,
        //meta: { auth: true, pathName },
        //meta: {myauth:true}
    });
};


export const myAuthClearSelf = (payload) => {
    console.log('myAuthStoreSelf (user data into store) action here , ', payload);
    return ({
        type: MY_STORE_CLEARSELF,
        payload,
        //meta: { auth: true, pathName },
        //meta: {myauth:true}
    });
};

export const myAuthSagaRegisterRq = (payload, pathName) => {
    console.log('user myAuthSagaRegisterRq action here , ', payload, pathName);
    return ({
        type: MY_AUTHSAGA_REGISTER_RQ,
        payload,
        //meta: { auth: true, pathName },
        meta: {myauth:true}
    });
};

export const myAuthSagaConfirmRegistrationRq = (payload, pathName) => {
    console.log('user myAuthSagaRegisterRq action here , ', payload, pathName);
    return ({
        type: MY_AUTHSAGA_REGISTERCONFIRM_RQ,
        payload,
        //meta: { auth: true, pathName },
        meta: {myauth:true}
    });
};


export const myAuthSagaResetPassRq = (payload, pathName) => {
    console.log('user myAuthSagaResetPassRq action here , ', payload, pathName);
    return ({
        type: MY_AUTHSAGA_RESETPASS_RQ,
        payload,
        //meta: { auth: true, pathName },
        meta: {myauth:true}

    });
};

export const myAuthSagaNewPassRq = (payload, pathName) => {
    console.log('user myAuthSagaResetPassRq action here , ', payload, pathName);
    return ({
        type: MY_AUTHSAGA_NEWPASS_RQ,
        payload,
        //meta: { auth: true, pathName },
        meta: {myauth:true}

    });
};

export const myAuthSagaLoadSelfRq = (payload, pathName) => {
    console.log('user myAuthSagaLoadSelfRq action here , ', payload, pathName);
    return ({
        type: MY_AUTHSAGA_LOADSELF_RQ,
        payload,
        //meta: { auth: true, pathName },
        meta: {myauth:true}

    });
};

export const myAuthSagaSaveSelfOperatorRq = (data) => ({
    type: MY_AUTHSAGA_SAVESELF_OPERATOR_RQ,
    payload: {data},
    meta: { myauth:true },
});

export const myAuthSagaSaveSelfGovRq = (data) => ({
    type: MY_AUTHSAGA_SAVESELF_GOV_RQ,
    payload: {data},
    meta: { myauth:true },
});

export const myAuthSagaNewPhoneRq = (data) => ({
    type: MY_AUTHSAGA_NEWPHONE_RQ,
    payload: {data},
    meta: { myauth:true },
});

export const myAuthSagaPhoneCodeVerifyRq = (data) => ({
    type: MY_AUTHSAGA_PHONECODEVERIFY_RQ,
    payload: {data},
    meta: { myauth:true },
});

export const myAuthSagaNicknameRq = (payload) => ({
    type: MY_AUTHSAGA_NICKNAME_RQ,
    payload,
    meta: { myauth:true },
});
