import React from 'react'
import connect from "react-redux/es/connect/connect";
import compose from 'recompose/compose';
import NotificationIcon from '@material-ui/icons-v3/NotificationImportant';
import {bindActionCreators} from "redux";
import {
    CONFIGURATION_KEY_NOTIFICATIONS_ALERTBOX_SOUNDS,
} from "../logic/settings/ConfigurationConstants";
import {toggleNotificationView} from "../logic/settings/MySettingsActions";
import BoxView from "./BoxView";


const AlertBoxView = (props) =>
    <BoxView icon={<NotificationIcon/>}
             title={"Alert box"}
             hideSeen
             showDuplicates
             isWarning
             soundPath={'/sound/215416__unfa__short-ping.flac'}
             {...props}/>;

const mapDispatchToProps = (dispatch) => ({
    addToSeenInfos: ()=>{},
    toggleNotificationView: bindActionCreators(toggleNotificationView, dispatch),
});

const mapStateToProps = state => {
    return {
        queue: state.alertbox.queue,
        popVersion: state.alertbox.popVersion,
        playNotificationSound: state.configuration[CONFIGURATION_KEY_NOTIFICATIONS_ALERTBOX_SOUNDS],
    }
};

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps
    ),
)(AlertBoxView);
