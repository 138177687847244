import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import React from "react";
import Avatar from "@material-ui/core/Avatar/Avatar";
import {withStyles} from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import {SortSelect} from "./SortSelect";
import {compose} from "redux";
import {translate} from "react-admin";
import classnames from "classnames"

const cardStyle = theme => ({
    card: {
        width: "100%",
        minHeight: 300,
        margin: '0.5em',
        display: 'inline-block',
        verticalAlign: 'top',
        backgroundColor: theme.palette.colorCircle.second,
    },
    avatar: {
        backgroundColor: theme.palette.primary.dark
    },
    title: {
        fontWeight: "bold"
    }

});

const sortOrderOptions = [

    {
        label: 'myroot.action.sortDescending',
        value: 'DESC'
    },
    {
        label: 'myroot.action.sortAscending',
        value: 'ASC'
    },
];

export const SummaryCards = compose(
    withStyles(cardStyle),
    translate
)
(
    class extends React.Component {

        _onChangeSort = name => event => {
            event.stopPropagation();

            let newSortField;

            if (name === 'sortField') {
                newSortField = event.target.value;
            }
            else {
                //will invert order when last a present query has same sortField
                newSortField = this.props.currentSort.field;
            }

            this.props.setSort(newSortField);
        };

        render() {

            const {
                ids,
                data,
                classes,
                currentSort,
                sortFieldOptions,
                title = ()=>{},
                subheader = ()=>{},
                avatarIcon,
                actions = ()=>{},
                children = ()=>{},
                disableSort,
                ...rest
            } = this.props;

            return (
                <>
                    {
                        !disableSort &&
                        <Toolbar>
                            <SortSelect
                                value={currentSort.field}
                                label={'myroot.action.sortBy'}
                                handleChange={this._onChangeSort}
                                id={'sortField'}
                                options={sortFieldOptions}
                            />
                            <SortSelect
                                value={currentSort.order}
                                label={'myroot.action.order'}
                                handleChange={this._onChangeSort}
                                id={'sortOrder'}
                                options={sortOrderOptions}
                            />
                        </Toolbar>
                    }

                    <div style={{margin: '1em'}}>
                        {ids.map(id => {

                            const titleEl = title({record: data[id]});

                            return (
                                <Card key={id} className={classes.card}>
                                    <CardHeader
                                        title={
                                            React.cloneElement(
                                                titleEl,
                                                {className: classnames(
                                                        classes.title,
                                                        titleEl.props.className
                                                    )}
                                            )
                                        }
                                        subheader={subheader({record: data[id]})}
                                        avatar={
                                            <Avatar className={classes.avatar}>
                                                {avatarIcon}
                                            </Avatar>
                                        }
                                    />
                                    <CardContent>
                                        {children({record: data[id]})}
                                    </CardContent>
                                    <CardActions style={{textAlign: 'right'}}>
                                        {actions({record: data[id]})}
                                    </CardActions>
                                </Card>
                            )
                        }
                        )}
                    </div>
                </>
            )
        }
    }
);

SummaryCards.defaultProps = {
    data: {},
    ids: [],
};