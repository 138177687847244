import React from 'react';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import PubSub from "pubsub-js";
import logger from '../../LogicV1Redux/oldLogic/Logger'
import Inspector from 'react-inspector';
//import {getZones} from '../../LogicV1Redux/oldLogic/ZonesListV2'
import {invalidateZones} from "../glSetups/oldCommon/deckInits";
import {PubSubSignals} from '../../LogicV1Redux/constants/PubSubSignals'
import { connect } from 'react-redux';
import compose from 'recompose/compose';

import {Rnd} from "react-rnd";

//huh, unused for now - very simple view (list)

const cssFix = {
    maxHeight: '100%',
    overflowY: 'auto'
};

const infoRenderer = (data) => {
    console.log('infoRenderer', data);

    // for (const [key, value] of Object.entries(data)) {
    //     console.log(key, value);
    //     let newPath = Object.assign({}, samplePath);
    //     newPath.path = value;
    //     newPath.color = [0,0,255,255];
    //     paths.push(newPath);
    // }
    //
    // return data.map(el => {
    //     console.log(el);
    //     const out = el.toString();
    //     return <div>{out}</div>
    // })
    let ret = '';

    Object.entries(data).forEach(([key, value]) => {
        console.log(`${key} ${value.toString()}`);
        ret +=  `${key}:${value.toString()}\n`;
    });
    return ret;
};

class ZoneListWindowBase extends React.Component {
    constructor() {
        super();
        this.state = {info : {data:' here', data2:'test'}};
    };

    componentWillMount() {

        // this.pubsubZonesLoaded = PubSub.subscribe(PubSubSignals.ON_ZONES_DESERIALIZED, (msg, data) => {
        //     let sorted = [];
        //     let src = getZones();
        //     console.log(src);
        //     for (let i = 0; i < src.length; i++) {
        //         sorted.push(src[i].zoneRecord);
        //     }
        //     //console.log(sorted);
        //
        //     sorted.sort(function(a, b) {
        //         const textA = a.name.toUpperCase();
        //         const textB = b.name.toUpperCase();
        //         return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        //     });
        //     console.log(sorted);
        //     this.setState({info:sorted});
        //
        // });
    }

    componentWillUnmount() {
        //PubSub.unsubscribe(this.pubsubInfo);
        // PubSub.unsubscribe(this.pubsubTick)

    }

    render() {
        //console.log(this.state.info);

        const {viewData} = {...this.state.info};
        return(
            <div style = {cssFix}>
                huh somehow never implemented?
                <Inspector table
                    data={this.state.info}
                />
            </div>
        )
    }
}


const mapStateToProps = state => {
    console.log('TODO, sorting here, see old pubsub handler');
    return {
        // isLoading: state.admin.loading > 0,
        // alertsV1 : state.alertsV1,
        // checkinsV1 : state.checkinsV1,
        zonesV2 : state.zonesV2,

    }
};

const enhance = compose(
    //translate,
    //muiThemeable(), // force redraw on theme change
    connect(
        mapStateToProps,
        // {
        //     storeTick,
        //     //userLogout: userLogoutAction,
        // }
    )
);

export const ZonesListWindow = enhance(ZoneListWindowBase);

//    console.error('fix css!, without sizeMe if possible!')
export const RndZonesListWnd = ({viewportData}) => (
    <Rnd

        className='rnd-window'
        default={{
            x: 50,
            //y: viewportData.height - 330,
            width: 640,
            height: 240,
        }}
        maxHeight="95%"
        bounds = "parent"
        dragHandleClassName = ".rnd-dragbar"

    >
        <div className="rnd-dragbar">Zone List</div>
        <div style={{overflowY:"auto", maxHeight:(viewportData.height-100)+"px"}}>
            <ZonesListWindow style={{overflowY:"auto"}}/>
        </div>

    </Rnd>
);