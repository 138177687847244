import {getAuthHeaderV2} from "./AuthConverters";
import PubSub from "pubsub-js";
import axios from "axios/index";
import { urls } from "../../Configs";
import {deserializeZoneData} from "./ZonesListV2"
import {PubSubSignals} from '../constants/PubSubSignals'

let authToken;


//was used earlier
export function requestLoad(zoneState) {

    authToken = zoneState.authToken;
    console.error('loading here, to remove', zoneState);

    //superadmin now
    //const url = `${urls.readMissionUrl.replace('%operatorId%', operatorId)}?filter=${filter}`;
    const url = `${urls.readZoneUrl}/${zoneState.zoneId}`;

    const promise =  axios(url, {
        method : 'get',
        headers : getAuthHeaderV2({token:authToken}),
    });

    promise
        .then(
            result => {
                console.log('axios load zone', result);
                console.error('deserializeZoneData fixme passed data');
                PubSub.publish(PubSubSignals.ZONE_LOADED, deserializeZoneData(result.data));
            }
        )
        .catch((error) => {
            console.error('error in loading mission', error);
        });
    return null;
}

//still used(?)
export function requestSave(zoneState) {
    //only update for now

    console.error('saving here, to remove', zoneState);

    authToken = zoneState.authToken;
    console.log('saving here', zoneState);
    //console.log('saving here after serialize', serializeZonePrimitive(zoneState));

    console.error('old serialiazation removed');
    //superadmin now
    //const url = `${urls.readMissionUrl.replace('%operatorId%', operatorId)}?filter=${filter}`;
    const url = `${urls.updateZoneUrl}/${zoneState.zoneRecord.uid}`;

    const promise =  axios(url, {
        method : urls.updateZoneMethod,
        headers : getAuthHeaderV2({token:authToken}),
        //data: serializeZonePrimitive(zoneState),
    });

    promise
        .then(
            result => {
                console.log('axios save zone', result);
            }
        )
        .catch((error) => {
            console.error('error in saving zone', error);
        });
    return null;
}

export default {
    requestLoad,
    //set,
    requestSave
}
