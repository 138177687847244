import WelcomeScreen from "../WelcomeScreen";
import {disclaimer, operatorRodo} from "../../../../docs";
import React from "react";
import moment from "moment";

const RodoAndDisclaimer = ({cookies, userLogout}) =>
    <WelcomeScreen
    shouldShow={
        () => {
            return cookies.get('rodo') !== 'accepted-for-1-year';
        }
    }
    record={{
        en: {
            ...operatorRodo.en,
                content:
                    operatorRodo.en.content +
                    `<br/><br/>` +
                    disclaimer.en.title +
                    disclaimer.en.content

        },
        pl: {
            ...operatorRodo.pl,
                content:
                    operatorRodo.pl.content +
                    `<br/><br/>` +
                    disclaimer.pl.title +
                    disclaimer.pl.content
        },
    }}
    onConfirm={
        () => {
            cookies.set('rodo', 'accepted-for-1-year', { path: '/', expires: moment().add(1, 'year').toDate()});
        }}
    onClose={
        () => {
            cookies.set('rodo', 'rejected', { path: '/'});
            userLogout();
        }}
/>;

export default RodoAndDisclaimer;