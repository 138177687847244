
//overcomplicated for now, but let's keep link
//https://github.com/matpaul/redux-timer-middleware/blob/master/src/index.js


import {TIMER_START_RQ, TIMER_TICK_RQ, TIMER_STOP_RQ} from "../constants/TickConstants";

const initialState = {};

const tickReducer = (previousState = initialState, { type, payload }) => {

    switch (type) {
        case TIMER_START_RQ:

            return {...previousState, ...payload };
        case TIMER_TICK_RQ:

            return {...previousState, ...payload };
        case TIMER_STOP_RQ:

            return {...previousState, ...payload };
        default:
            return previousState;
    }
};

export default tickReducer;
