
//pubsub signals list to review/cleanup
//do not remove commented out signals before rewriting ALL apps
export const PubSubSignals = {
    //ALERTS_DATA: 'alertsData', redux now
    //CHECKINS_LIST: 'checkInsList', redux now
    CHECKIN_DATA: 'checkInData', //hmm very old one
    TICK : 'tick',
    NOTIFICATION: 'notification',
    INVALIDATE_ZONES: 'invalidateZones',
    INVALIDATE_ALERTS: 'invalidateAlerts',
    OPEN_MODAL: 'openModal',
    CLOSE_MODAL: 'closeModal',
    MENU_STORE: 'menuStore',
    MODIFY_PERMISSION_DIALOG: 'modifyPermissionDialog',
    INSPECT_DATA: 'inspectData',
    CANCEL_OWNED_ALERT_MODAL :'cancelOwnedAlertModal',
    CREATE_OWNED_ALERT_DIALOG : 'createOwnedAlertDialog',
    CREATE_CUSTOM_ALERT_DIALOG :'createCustomAlertDialog',
    CLOSE_CREATE_ALERT_DIALOG : 'closeCreateAlertDialog',
    FORCE_REDRAW_DECK_GL : 'forceRedrawDeckGL',
    DRAW_UPDATED: 'drawUpdated',
    DRAW_MODE: 'drawMode',  //almost removed, clean muimap
    //ON_ZONES_DESERIALIZED: 'onZonesDeserialized', redux now
    ZONE_LOADED: 'zoneLoaded',
    NEW_ZONE_LOADED: 'newZoneLoaded', //???
    MISSION_LOADED: 'missionLoaded',
    NEW_MISSION_LOADED: 'newMissionLoaded', //???
    REQUEST_MISSION_SAVE: 'requestMissionSave',
    ZONE_VERTICAL_SCALE: 'zoneVerticalScale',
    ZONE_TOP_CUTOFF: 'zoneTopCutoff',
    ZONE_BOTTOM_CUTOFF: 'zoneBottomCutoff',
    //ON_ZONES_RULES_CHANGE: 'onZonesRulesChange',
    SET_DRAW_EDITOR_DIRTY: 'onDrawEditorChange', //should be redux, but need it asap
    CLEAR_DRAW_EDITOR_DIRTY: 'onDrawEditorSetup', //should be redux, but need it asap
    MISSION_DESERIALIZED: 'missionDeserialized', //call when zoneLeg ownership has been computed

    //MAP_VIEWPORT_BBOX:'mapSetBBox', //viewport related, signal seems better than redux,
    MAP_VIEWPORT_ZOOM_IN:'mapZoomIn', //viewport related, signal seems better than redux,
    MAP_VIEWPORT_ZOOM_OUT:'mapZoomOut', //viewport related, signal seems better than redux,
    MAP_VIEWPORT_SET_BBOX:'mapSetNewBbox',
    MAP_VIEWPORT_SET_HOME:'mapSetHome',

    MISSION_STATUS_CHANGE: 'missionStatusChange', //temp hack for dashboard.
    DRONE_STATUS_CHANGE: 'droneStatusChange', //temp hack for dashboard.
    CERT_STATUS_CHANGE: 'certStatusChange', //temp hack for dashboard.
    OPERATION_NOTIFICATION: 'operationNotification',
    ALERT_NOTIFICATION: 'alertNotification',
    LOG_PUBLISHED: 'logPublished',

    SHOW_OPERATION_TAB: 'showBarsTab',
    //SHOW_DRAW_TOOLBAR: 'showDrawToolbar', no dynamic switch to edit now.
};

export default {
    PubSubSignals
}
