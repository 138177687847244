import React from 'react'
import connect from "react-redux/es/connect/connect";
import compose from 'recompose/compose';
import InfoIcon from '@material-ui/icons/Info';
import {bindActionCreators} from "redux";
import {addToSeenInfos} from "../logic/box/InfoSeenActions";
import {CONFIGURATION_KEY_NOTIFICATIONS_INFOBOX_SOUNDS} from "../logic/settings/ConfigurationConstants";
import {toggleNotificationView} from "../logic/settings/MySettingsActions";
import BoxView from "./BoxView";


const InfoBoxView = (props) =>
    <BoxView icon={<InfoIcon/>}
             title={"Info box"}
             soundPath={'/sound/220212__gameaudio__ping-bing.wav'}
             {...props}/>;

const mapDispatchToProps = (dispatch) => ({
    addToSeenInfos: bindActionCreators(addToSeenInfos, dispatch),
    toggleNotificationView: bindActionCreators(toggleNotificationView, dispatch),
});

const mapStateToProps = state => {
    return {
        queue: state.infobox.queue,
        popVersion: state.infobox.popVersion,
        seenPersist: state.infoSeen.seen,
        playNotificationSound: state.configuration[CONFIGURATION_KEY_NOTIFICATIONS_INFOBOX_SOUNDS],
    }
};

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps
    ),
)(InfoBoxView);
