
const center = [21.0122, 52.2297];
//const center = [21.262935, 52.3379040];

const defaultZoom = 10;

export const myAppDefaults = {
    bottomCutoff : 0,
    topCutoff :100000,
    adsbSourcesPollInSec: 2,
    adsbExchangePollInSec: 5,
    tick: 1000, //ms
};

export const mapboxOpts = {
    //pansa-web token
    accessToken: "pk.eyJ1IjoicGFuc2F1dG0iLCJhIjoiY2t2aTQ0NnNvMGxkYzMycXhsMmFxMDd6YiJ9.GhrkfQRKKn5WhcOXxzAgng",
    style: 'mapbox://styles/pansautm/ckvrtmvh70elu14m39fju535m', //old
    basicStyle : 'mapbox://styles/pansautm/ckvrtmvh70elu14m39fju535m',
    satelliteStyle : 'mapbox://styles/mapbox/satellite-v9',
    //style: 'mapbox://styles/mapbox/streets-v9'
    //style: "mapbox://styles/mapbox/outdoors-v9" // eslint-disable-line

    center: center,
    zoom: defaultZoom,
};

export const defaultViewportData = {
    latitude : mapboxOpts.center[1],
    longitude : mapboxOpts.center[0],
    zoom: mapboxOpts.zoom,
    maxZoom: 24,
    bearing: 0,
    pitch: 0, //not 30! at least not until 3d is back
    //fov:30, not supported.. hmm
};

export const CHECKIN_VIEWPORT_BBOX_RADIUS = 2000;
