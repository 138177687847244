//fast draft.

//https://github.com/axios/axios/blob/master/lib/core/enhanceError.js


export function throwLoopbackError(err) {
    const {request, response, message, name} = err;
    //console.error('(mss) err', config, code, request, response);
    console.error('(mss) err', request, response, message, name);

    let retMsg = message;

    if (response && response.data && response.data.error && response.data.error.code) {
        //converting to translatable code
        //important! can be used in sagas (like mission legs delete monkey patch)
        retMsg = 'error.'+response.data.error.code;
    }

    throw new Error(retMsg);
}
