import React from "react";
import {withStyles} from "@material-ui/core";
import get from "lodash.get";
import {Link} from "ra-ui-materialui";
import MyGeomConversionMenu, {GEOM_CONVERSIONS} from './MyGeomConversionMenu';
import {wkb2geojson} from '../../gl_map/glSetups/deckPrimitives/DeckPrimitiveSerialization';
import compose from 'recompose/compose';
import {connect} from "react-redux";
import {addEditablePrimitive, setCurrentPrimitive, removeEditablePrimitive} from "../../gl_map/glSetups/deckDraw/deckDrawStoreBridge";
import DeckPoly from "../../gl_map/glSetups/deckPrimitives/DeckPoly";

//import {change, updateSyncWarnings} from 'redux-form';

//import PubSub from "pubsub-js";
//import {PubSubSignals} from "../../../LogicV1Redux/constants/PubSubSignals";
//import {JSONField} from './MyJSONInput';

import {point, lineString, multiPoint, polygon} from "@turf/helpers";
import isPlainObject from "lodash.isobjectlike";
import { translate } from 'react-admin';
import {bindActionCreators} from "redux";
import {saveUnitDataV2Rq} from "../../LogicV1Redux/actions/UnitsV2Actions";

//ugh.. custom import for hacking component;
import {default as formActions} from 'redux-form/es/actions' ;

//standard validation function requires editable geometry access
//so 'easy' validation is not (yet?) connectable..
//furthermore per-field validation is not (yet?) possible,
//due to fact that geometry fields ARE NOT redux-form fields now.
//there were multiple issues with implementing them earlier,
//so unless that's corrected (especially missions editing),
// we have to stick to current flow...

// export function validEditableGeometry(value, allValues, props) {
//     console.log('==validEditableGeometry', value, allValues, props);
//     // if (value !== null && !isPlainObject(value)) {
//     //     return props.translate('myroot.validation.nullableJSONObject');
//     // }
//     console.log('==json validation ok', value);
//
//     return undefined;
// }

//fixme -> connect theme styling
class MyGeomInputBase extends React.Component {
    state = {
        meta : {},
        conversion: GEOM_CONVERSIONS.GEOJSON_FEATURE,
        inputVal: ''
    };

    onConversionChange = ({mode}) => {
        console.log('currentMode', mode);
        this.setState({ conversion: mode});
    };

    onValueChange = (event) => {
        let {currentEditablePrimitive} = this.props;

        const newVal = event.target.value;
        console.log("==MGI data change", newVal);

        if (!newVal) {
            //setCurrentPrimitive(null);
            removeEditablePrimitive(currentEditablePrimitive);
            this.setState({meta:{error:null}, inputVal:newVal});

            return;
        }
        try {
            //assuming DeckPoly and geojson now
            const input = JSON.parse(newVal);
            let coordinates;
            console.log("==MGI data change", input);
            //we can add some auto-detect here later...
            switch (this.state.conversion) {
                    case GEOM_CONVERSIONS.GEOJSON_FEATURE:
                    coordinates = input.geometry.coordinates;
                    break;
                case GEOM_CONVERSIONS.GEOJSON_GEOMETRY:
                    coordinates = input.coordinates;
                    break;
                default:
                // code block
            }

            if (currentEditablePrimitive) {
                currentEditablePrimitive.recalc(coordinates[0]);
                setCurrentPrimitive(currentEditablePrimitive);
            } else {
                const newEditablePrimitive = new DeckPoly(coordinates[0]);
                addEditablePrimitive(newEditablePrimitive);
            }
            this.setState({meta:{error:null}, inputVal:newVal})
            //console.error('wrong input', e);


        } catch (e) {
            //fixme -> either rewrite as global form validation function.
            // or create fake field in related forms to store error (easiest?)
            // or connect true redux-form.. but having in mind really complicated mission editor.

            // this.props.updateSyncErrors('record-form', //have to be default
            //     //{ foo: 'foo error' },
            //     //null,
            //     {},
            //     'some global error');
            //console.error('wrong input', e);
            this.setState({meta:{error:'wrong.input'}, inputVal:newVal})
        }
    };

    _do = () => {
        console.log("==MGI do",  this.props);
        let inputVal;


    }

    // componentWillReceiveProps(nextProps, nextContext) {
    //     console.log("==MGI componentWillReceiveProps",  nextProps);
    //
    // }

    componentDidUpdate(prevProps) {
        console.log("==MGI did update", prevProps, this.props);
        // if (!shallowEqual(this.props, prevProps)) {
        //     this._do();
        // } else {
        //     console.log('mmf shallow equal false');
        // }
        this._do();
    }

    componentDidMount() {
        console.log("==MGI did mount",  this.props);
        this._do();
    }

    render() {
        console.log("==MGI render props",  this.props);

        const disabled = false;

        const {conversion, meta, inputVal } = this.state;

        let finalError = (meta && meta.error)? meta.error : null;
        let convertedVal = inputVal;
        //let inputVal = ''; //not null
        console.log("==MGI convertedVal A",  convertedVal, finalError);

        const {currentEditablePrimitive} = this.props;

        try {
            if (!finalError && currentEditablePrimitive) {
                switch (conversion) {
                    case GEOM_CONVERSIONS.GEOJSON_FEATURE: {
                            const turfPoly = polygon(currentEditablePrimitive.polygon);
                            convertedVal = JSON.stringify(turfPoly);
                        }
                        break;
                    case GEOM_CONVERSIONS.GEOJSON_GEOMETRY: {
                            const turfPoly = polygon(currentEditablePrimitive.polygon);
                            convertedVal = JSON.stringify(turfPoly.geometry);
                        }
                    break;
                    default:
                    // code block
                }
            }
        } catch (e) {
            console.warn('conversion failed', e);
            finalError = 'conversion.failed';
            convertedVal = inputVal;
        }

        console.log("==MGI convertedVal B",  convertedVal, finalError);
        return (
            <div>
                <MyGeomConversionMenu defaultVal={conversion} onChange={this.onConversionChange}/>
                <textarea disabled={disabled}
                          onChange={this.onValueChange}
                          style={{width: "100%", minHeight: '100px'}}
                          value={convertedVal}/>
                {finalError &&
                <span className="error" style={{color:"red"}}> {finalError}</span>}
                {/*<JSONField record={record} source="__geoconversion"*/}
                            {/*//validate={etcJSONValidation}*/}
                {/*/>*/}
                {/*<div>{inputVal}</div>*/}
            </div>
        )
    }
}


// const mapDispatchToProps_edit = (dispatch) => ({
//     change: bindActionCreators(change, dispatch),
//     updateSyncErrors: bindActionCreators(updateSyncErrors, dispatch),
// });

const mapDispatchToProps = dispatch =>
    (
        {
            updateSyncErrors : (form, syncError, error) => {
                console.error('dispatching form error', form, syncError, error);
                dispatch(formActions.updateSyncErrors(form, syncError, error));
            }
        }
    );


export const MyGeomInput = compose(
    translate,
    //withStyles(styles), //react warnings here - clean styles
    //connect(mapStateToProps_edit, mapDispatchToProps_edit, null, connectOpts_edit),
    connect(null, mapDispatchToProps) //, null, connectOpts_edit),

)(MyGeomInputBase);

export default MyGeomInput;
