import { initializeLayersObject } from "./mapStateConstructors";
import AdsbSources from "../../../LogicV1Redux/oldLogic/RtdmSources";
import //processADSBCreotechTicks,
//processAdsbExchangeTicks,
//processLprHelipadsV1, processZonesV2
"../oldCommon/deckInits";
import { unixTimeNow } from "../../../LogicV1Redux/oldLogic/TimeConverters";
import { getTestCursor } from "../oldCommon/testDeckInits";

//TODO review/clean passed props data
//and maybe split (again) usage...
export const FullMapLayersRedraw = ({ prevState, nextProps }) => {
    console.log("FullMapLayersRedraw", prevState, nextProps);

    //disabling process redraws -> related to live data
    //processRedraws(bottomCutoff, topCutoff);

    const {
        menuStore: FIXMEmenuStore,
        liveAbsbExchange,
        checkinsV1,
        checkinsV2,

        alertsV1,
        alertsV2,

        zonesV1,
        zonesV2,
        inspectData,
        mapUxConfig,
        lprHelipadsV1,

        rtdmExchangeTicks,
        rtdmExchangeAircrafts,
        rtdmCreotechTicks,
        rtdmSoapLprUnitsTicks,
        rtdmAerobitsTicks,

        missionsV2,
        mapLocations,
        customLayer
    } = nextProps;
    let pattern = initializeLayersObject();

    let newState = Object.assign({}, pattern);
    let textsData = {};

    newState.polys = prevState.polys || []; //keep by default

    let deckLiveFlights = Object.assign({}, pattern);
    let deckCreotechTicks = Object.assign({}, pattern);
    //let deckRtdmXchangeAircrafts = Object.assign({}, pattern); combined with ticks
    let deckRtdmXchangeTicks = Object.assign({}, pattern);
    let deckRtdmSoapLprUnitsTicks = Object.assign({}, pattern);
    let deckRtdmAerobitsTicks = Object.assign({}, pattern);

    let deckCheckinsV1 = Object.assign({}, pattern);
    let deckCheckinsV2 = Object.assign({}, pattern);
    let deckAlertsV1 = Object.assign({}, pattern);
    let deckAlertsV2 = Object.assign({}, pattern);

    let deckZonesV2 = Object.assign({}, pattern);
    let deckZonesV1 = Object.assign({}, pattern);
    let deckLprHelipads = Object.assign({}, pattern);
    let deckMissionsV2 = Object.assign({}, pattern);
    let deckLocations = Object.assign({}, pattern);

    let deckCustomLayer = Object.assign({}, pattern);

    if (customLayer) {
        deckCustomLayer = customLayer.deckData;
        textsData["custom-layer-texts"] = {
            data: deckCustomLayer.texts
        };
    }

    //no polys part, redraw only truly dynamic stuff
    //unused in rt-map, for now, but since config is working
    //keeping code.

    //old
    if (AdsbSources.isOpened()) {
        // if (mapUxConfig.FIXMEshowCreotech)
        //     creotechTicks = processADSBCreotechTicks();
        // if (mapUxConfig.FIXMEshowAdsbXchange)
        //     adsbXchangeTicks = processAdsbExchangeTicks();
    }

    if (rtdmCreotechTicks && mapUxConfig.uiRtdmCreotechTicksShow) {
        deckCreotechTicks = rtdmCreotechTicks.deckData;
        textsData["deck-creotech-units-texts"] = {
            data: deckCreotechTicks.texts,
            getTextAnchor: "end",
            getAlignmentBaseline: "bottom",
            getPixelOffset: [-15, -15]
        };
    }

    // if (rtdmExchangeAircrafts && mapUxConfig.uiRtdmExchangeAircraftsShow) {
    //     deckRtdmXchangeAircrafts = rtdmExchangeAircrafts.deckData;
    // }

    if (rtdmExchangeTicks && mapUxConfig.uiRtdmExchangeTicksShow) {
        deckRtdmXchangeTicks = rtdmExchangeTicks.deckData;
    }

    if (rtdmSoapLprUnitsTicks && mapUxConfig.uiRtdmSoapLprUnitsTicksShow) {
        deckRtdmSoapLprUnitsTicks = rtdmSoapLprUnitsTicks.deckData;
        textsData["soap-lpr-units-texts"] = {
            data: deckRtdmSoapLprUnitsTicks.texts,
            getTextAnchor: "end",
            getAlignmentBaseline: "bottom",
            getPixelOffset: [-15, -15]
        };
    }

    if (rtdmAerobitsTicks && mapUxConfig.uiRtdmSoapLprUnitsTicksShow) { //FIXME!
        deckRtdmAerobitsTicks = rtdmAerobitsTicks.deckData;
        textsData["soap-lpr-units-texts"] = {  //FIXME!
            data: deckRtdmAerobitsTicks.texts,
            getTextAnchor: "end",
            getAlignmentBaseline: "bottom",
            getPixelOffset: [-15, -15]
        };
    }

    if (liveAbsbExchange && mapUxConfig.uiLiveAbsbExchangeShow) {
        deckLiveFlights = liveAbsbExchange.deckData;
    }

    if (checkinsV1 && mapUxConfig.uiCheckinsV1Show) {
        deckCheckinsV1 = checkinsV1.deckData;
        textsData["checkins-v1-texts"] = {
            data: deckCheckinsV1.texts,
            //getTextAnchor:"end",
            getAlignmentBaseline: "bottom",
            getPixelOffset: [0, -38]
        };
    }

    if (checkinsV2 && mapUxConfig.uiCheckinsV2Show) {
        deckCheckinsV2 = checkinsV2.deckData;
        textsData["checkins-v1-texts"] = {
            data: deckCheckinsV1.texts,
            //getTextAnchor:"end",
            getAlignmentBaseline: "bottom",
            getPixelOffset: [0, -38]
        };
    }

    if (lprHelipadsV1 && mapUxConfig.uiLprHelipadsV1Show) {
        deckLprHelipads = lprHelipadsV1.deckData;
    }

    if (alertsV1 && mapUxConfig.uiAlertsV1Show) {
        //console.log('getting alerts', alertsV1);
        deckAlertsV1 = alertsV1.deckData;
    }

    if (alertsV2 && mapUxConfig.uiAlertsV2Show) {
        //console.log('getting alerts', alertsV1);
        deckAlertsV2 = alertsV2.deckData;
    }

    if (zonesV2 && mapUxConfig.uiZonesV2Show) {
        //console.log('getting new zones', zonesV2);
        deckZonesV2 = zonesV2.deckData;
        textsData["zones-v2-texts"] = {
            data: deckZonesV2.texts
        };
    }

    if (mapLocations) {
        deckLocations = mapLocations.deckData;
    }

    //for pandora show only?
    if (zonesV1 && mapUxConfig.uiRtMapZonesV1Show) {
        //console.log('getting old zones', zonesV1);
        deckZonesV1 = zonesV1.deckData;
        textsData["zones-v1-texts"] = {
            data: deckZonesV1.texts
        };
    }

    if (missionsV2 && mapUxConfig.uiMissionsV2Show) {
        //console.log('getting missions', missionsV2);
        deckMissionsV2 = missionsV2.deckData;
    }

    newState.points = [
        ...deckCheckinsV1.points,
        ...deckCheckinsV2.points,
        ...deckCreotechTicks.points,
        ...deckRtdmXchangeTicks.points,
        ...deckRtdmSoapLprUnitsTicks.points,
        ...deckRtdmAerobitsTicks.points
    ];

    //hmm we need to update SOME icons, but hospitals (aka lprhemses) are 'static', so.. fixme optimize?
    newState.icos = [
        ...deckLiveFlights.icos,
        ...deckLprHelipads.icos,
        ...deckCheckinsV1.icos,
        ...deckCheckinsV2.icos,

        ...deckCreotechTicks.icos,
        ...deckRtdmXchangeTicks.icos,
        ...deckRtdmSoapLprUnitsTicks.icos,
        ...deckRtdmAerobitsTicks.icos,
        //...deckRtdmXchangeAircrafts.icos, //icos alreadt attached to ticks
        ...deckLocations.icos
    ];

    newState.paths = [
        ...deckCheckinsV1.paths,
        ...deckCreotechTicks.paths,
        ...deckRtdmXchangeTicks.paths,
        ...deckRtdmSoapLprUnitsTicks.paths,
        ...deckRtdmAerobitsTicks.paths,
    ];

    //same as icos -> optimize only some...
    //...deckLiveFlights.texts,
    //...deckLprHelipads.texts,
    //...deckCheckins.texts,
    //...deckCreotechTicks.texts,
    //...deckRtdmXchangeTicks.texts,
    //...deckRtdmSoapLprUnitsTicks.texts,
    //...deckZonesV1.texts,

    const oldZonesDirty = zonesV1 && prevState.redrawPolysTimestamp <= zonesV1.storeTimestamp;
    const alertsV1Dirty = alertsV1 && prevState.redrawPolysTimestamp <= alertsV1.storeTimestamp;
    const alertsV2Dirty = alertsV2 && prevState.redrawPolysTimestamp <= alertsV2.storeTimestamp;

    const newZonesDirty = zonesV2 && prevState.redrawPolysTimestamp <= zonesV2.storeTimestamp;
    const missionsDirty = missionsV2 && prevState.redrawPolysTimestamp <= missionsV2.storeTimestamp;

    const customLayerDirty = customLayer && prevState.redrawPolysTimestamp <= customLayer.storeTimestamp;
    const checkinsV1Dirty = checkinsV1 && prevState.redrawPolysTimestamp <= checkinsV1.storeTimestamp;
    //const checkinsV2Dirty = checkinsV2 && prevState.redrawPolysTimestamp <= checkinsV2.storeTimestamp;

    //console.log('spreadingPolys dirties');

    if (
        alertsV1Dirty ||
        alertsV2Dirty ||
        oldZonesDirty ||
        newZonesDirty ||
        missionsDirty ||
        customLayerDirty ||
        checkinsV1Dirty ||
        false //checkinsV2Dirty //really overkill probably FIXME! have to rewrite to layers, like texts
    ) {
        newState.redrawPolysTimestamp = unixTimeNow();

        //console.log('spreadingPolys dirties', alertsDirty, oldZonesDirty);
        //console.log('alerts', alerts.polys);

        // console.log("spreadingOldZones", deckZonesV1.polys);
        // console.log("spreadingNewZones", deckZonesV2.polys);
        // console.log("spreadingAlertsV1", deckAlertsV1.polys);
        // console.log("spreadingMissions", deckMissionsV2.polys);
        // console.log("spreadingCustomLayer", deckCustomLayer.polys);
        // console.log("spreadingCheckinsV1", deckCheckinsV1.polys);

        //hmm
        newState.polys = [
            ...deckZonesV1.polys,
            ...deckZonesV2.polys,
            ...deckAlertsV1.polys,
            ...deckAlertsV2.polys,

            ...deckMissionsV2.polys,
            ...deckCustomLayer.polys,
            //...deckCheckinsV1.polys,
            //...deckCheckinsV2.polys,

        ];
    }

    newState.textsData = textsData;
    newState.__redrawnTS = unixTimeNow();
    //
    //console.log('===========newstate textsData', newState.textsData);

    return newState;
};

export const EditorMapLayersRedraw = ({ prevStatePolys, props, alertsDirty, zonesDirty }) => {
    console.log("onForceRedraw!");
    const { menuStore, checkinsV1, alertsV1, zonesV2, inspectData, mapUxConfig, lprHelipadsV1 } = props;

    const tick = { unixTime: unixTimeNow() };

    let pattern = initializeLayersObject();

    let newState = Object.assign({}, pattern);
    newState.polys = prevStatePolys || [];

    let zones = Object.assign({}, pattern);

    //hmm?
    newState.points = [];
    newState.icos = [];
    newState.paths = [];

    //hmm

    if (alertsDirty || zonesDirty) {
        console.log("spreadingPolys dirties", alertsDirty, zonesDirty);

        console.log("spreadingPolys menu", menuStore.showAlerts, menuStore.showZonesV2);

        //console.log('alerts', alerts.polys);

        if (menuStore.showZonesV2) {
            //console.error('==================processing  zonesv2 to review');
            //zones = processZonesV2({zones:zonesV2});
            zones = zonesV2.deckData;
        }

        console.log("spreadingNewZones", zones);

        //hmm2
        newState.helperPolys = [...zones.polys];
    }

    //disabling process redraws -> related to live data
    //processRedraws(bottomCutoff, topCutoff);

    //let newState = getLayersData();

    newState.cursor = getTestCursor();
    newState.tick = tick;

    return newState;
};

export const WasLiveMapLayersRedraw = ({ prevStatePolys, props, alertsDirty, zonesDirty }) => {
    console.log("onForceRedraw!");
    const { menuStore, checkinsV1, alertsV1, zonesV2, inspectData, mapUxConfig, lprHelipadsV1 } = props;

    const tick = { unixTime: unixTimeNow() };

    let pattern = initializeLayersObject();

    let newState = Object.assign({}, pattern);
    newState.polys = prevStatePolys || [];

    let adsbXchangeTicks = Object.assign({}, pattern);
    let creotechTicks = Object.assign({}, pattern);
    let checkins = Object.assign({}, pattern);
    let zones = Object.assign({}, pattern);
    let alerts = Object.assign({}, pattern);

    //no polys part, redraw only truly dynamic stuff
    if (AdsbSources.isOpened()) {
        //old!
        // if (menuStore.showCreotech)
        //     creotechTicks = processADSBCreotechTicks();
        // if (menuStore.showAdsbXchange)
        //     adsbXchangeTicks = processAdsbExchangeTicks();
    }

    if (menuStore.showCheckins) {
        checkins = checkinsV1.deckData;
    }

    newState.points = [...checkins.points, ...creotechTicks.points, ...adsbXchangeTicks.points];
    newState.icos = [...checkins.icos, ...creotechTicks.icos, ...adsbXchangeTicks.icos];
    newState.paths = [...checkins.paths, ...creotechTicks.paths, ...adsbXchangeTicks.paths];

    //hmm

    if (alertsDirty || zonesDirty) {
        console.log("spreadingPolys dirties", alertsDirty, zonesDirty);

        console.log("spreadingPolys menu", menuStore.showAlerts, menuStore.showZonesV2);

        //console.log('alerts', alerts.polys);

        if (menuStore.showAlerts) {
            console.log("processing alerts");
            alerts = alertsV1.deckData;
        }

        if (menuStore.showZonesV2) {
            console.log("processing zones");
            //console.error('==================processing  zonesv2 to review');

            zones = zonesV2.deckData;
        }

        console.log("spreadingAlerts", alerts.polys);
        console.log("spreadingNewZones", zones.polys);

        //hmm2
        newState.polys = [...zones.polys, ...alerts.polys];

        //hmm
        // newState.texts = [
        //     ...zones.texts,
        // ];
    }

    //disabling process redraws -> related to live data
    //processRedraws(bottomCutoff, topCutoff);

    // newState.cursor = getTestCursor();
    // newState.tick = tick;
    //
    //console.log(newState);

    return newState;
};

// right now force redraw is not used, is redraw is called from propschange?... to fix (unify!)
export const ViewerMapLayersRedraw = () => {
    return {};
};
