import React from "react";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";


export const MyHeightUnitsSwitch = ({value, onChange}) => {

    return (
        <RadioGroup
            aria-label="units"
            name="units"
            value={value}
            onChange={onChange}
            row
            style={{marginLeft: "2rem"}}
        >
            <FormControlLabel
                value="AGL"
                control={<Radio color="primary" />}
                label="AGL"
                labelPlacement="start"
            />
            <FormControlLabel
                value="AMSL"
                control={<Radio color="primary" />}
                label="AMSL"
                labelPlacement="end"
            />
        </RadioGroup>
    );
};
