import React from 'react';
import PropTypes from 'prop-types';
import {Field, propTypes, reduxForm} from 'redux-form';
import {connect} from 'react-redux';
import compose from 'recompose/compose';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import {withStyles} from '@material-ui/core/styles';
import {translate, userLogin} from 'ra-core';
import {Link} from 'react-router-dom';

import {loginPageStyles as styles, renderInput} from "../../utils/tempCommon";
import StoreProvider from "../../MyStoreProvider";
import {required, showNotification} from "react-admin";
import {validateEmailOrUsername} from "../../utils/validationHelpers";
//import CardText from '@material-ui/core/CardText';

const login = (auth, dispatch, { redirectTo }) =>
    dispatch(userLogin(auth, redirectTo));

const extraLinks = (extras, translate, classes) => (extras.appMode === 'OPERATOR')
    ?
    (
        <CardActions>
            <Button
                variant="outlined"
                color="primary"
                className={classes.button}
                component={Link}
                to="/register"
            >
                {translate('myroot.auth.register')}
            </Button>
            <Button
                color="primary"
                className={classes.button}
                component={Link}
                to="/reset-pass-rq"
            >
                {translate('myroot.auth.resetPassRq')}
            </Button>
        </CardActions>
    )
    :
    (
        <CardActions>
            <Button
                color="primary"
                className={classes.button}
                component={Link}
                to="/reset-pass-rq"
            >
                {translate('myroot.auth.resetPassRq')}
            </Button>
        </CardActions>
    )
;

const validateRequired = [required(), ];

const LoginForm = ({ classes, isLoading, handleSubmit, translate, extras }) => (
    <form onSubmit={handleSubmit(login)}>
        <div className={classes.form}>
            <div className={classes.input}>
                <Field
                    name="username"
                    component={renderInput}
                    label={translate('myroot.auth.emailOrUsername')}
                    disabled={isLoading}
                    validate={validateEmailOrUsername}
                />
            </div>
            <div className={classes.input}>
                <Field
                    name="password"
                    component={renderInput}
                    label={translate('ra.auth.password')}
                    type="password"
                    disabled={isLoading}
                    validate={validateRequired}
                />
            </div>
        </div>
        <CardActions>
            <Button
                variant="raised"
                type="submit"
                color="primary"
                disabled={isLoading}
                className={classes.button}
            >
                {isLoading && <CircularProgress size={25} thickness={2} />}
                {translate('myroot.auth.signIn')}
            </Button>
        </CardActions>
        {extraLinks(extras, translate, classes)}
    </form>
);
LoginForm.propTypes = {
    ...propTypes,
    classes: PropTypes.object,
    redirectTo: PropTypes.string,
};

const mapStateToProps = state => {
    console.log('loginform mapStateToProps', state);

    return ({isLoading: state.admin.loading > 0})
};

//form after connect ? works, but... hmmm..
const enhance = compose(
    withStyles(styles),
    translate,
    reduxForm({
        form: 'sign-in-form',
        validate: (values, props) => {
            const errors = {};
            const { translate } = props;

            // if (!values.username)
            //     errors.username = translate('ra.validation.required');
            // if (!values.password)
            //     errors.password = translate('ra.validation.required');

            //just test. nope
            // const store = StoreProvider.getStore();
            // if (Object.entries(errors).length > 0) {
            //     console.log('errors', errors);
            //     store.dispatch(showNotification('saving data', 'warning'));
            // }

            return errors;
        },
        onSubmitFail: ({...e}) => {
            console.log('==============submit failed', e);
            //just test. but works.
            const store = StoreProvider.getStore();
            store.dispatch(showNotification('ra.message.invalid_form', 'warning'));
        },
        // replaced with saga, otherwise should be dispatched locally (probably)
        // onSubmitSuccess: ({...e}) => {
        //     console.log('===============submit success', e);
        // }
    }),
    connect(mapStateToProps),

);

export default enhance(LoginForm);
