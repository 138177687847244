//was, but connecting server time is not obvious in simple converter lib
// fixme!!! Client UTC time, fix w/evaled ServerTime

export function unixTimeNow() {
    return Date.now()/1000;
}

export function unixTime2Iso(timestamp) {
    return new Date(timestamp*1000).toISOString();
}

export function isoTimeNow() {
    return new Date().toISOString();
}

export function isoString2UnixTime(isoString) {
    return new Date(isoString).getTime()/1000;
}

export function durationMM2until(durationMm, from = unixTimeNow() ) {
    return from + durationMm*60;
}
