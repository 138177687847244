import React from "react";
import Switch from "@material-ui/core/Switch/Switch";
import {Map, Satellite} from "@material-ui/icons";
import withStyles from "@material-ui/core/styles/withStyles";


const styles = (theme) => ({
    switchSelection: {
        display: "flex",
        alignItems: "center",
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        "&:not(:first-child)": {
            marginLeft: 4*theme.spacing.unit
        }
    },
    iconColor: {
        color: theme.palette.primary.contrastText
    }
});

export default withStyles(styles)(
    ({onSatelliteToggle, useSatelliteMap, classes}) => {

    const handleChange = (event) => {
        onSatelliteToggle();
    };

    return (
        <div className={classes.switchSelection}>
            <Map className={classes.iconColor}/>
            <Switch
                checked={useSatelliteMap}
                onChange={handleChange}
                value="checkedSatellite"
                color="primary"
            />
            <Satellite className={classes.iconColor}/>
        </div>
    )
})

