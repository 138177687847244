import React from "react";
import {invertColorToCSSHexString} from "../../../dr_ra2/utils/cssColorInverter";
import withStyles from "@material-ui/core/styles/withStyles";
import BtnSwitchSateliteMap from "./BtnSwitchSateliteMap";
import BtnSwitchAllZonesV2 from "./BtnSwitchAllZonesV2";


const styles = (theme) => ({
    box: {
        display: "flex",
        borderRadius: "5px",
        backgroundColor: invertColorToCSSHexString(theme.palette.primary.contrastText, 0.75),
        margin: theme.spacing.unit,
        pointerEvents : "auto"
    }
});

export default withStyles(styles)(
    ({onSatelliteToggle, useSatelliteMap, uiZonesV2Show, onZonesToggle, classes}) => {
    return (
        <div className={classes.box}>
            <BtnSwitchSateliteMap useSatelliteMap={useSatelliteMap} onSatelliteToggle={onSatelliteToggle} />
            <BtnSwitchAllZonesV2 uiZonesV2Show={uiZonesV2Show} onZonesToggle={onZonesToggle}/>
        </div>
    )
})
