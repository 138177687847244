//mixed from RA 2.0.2 docs

import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_ERROR, AUTH_CHECK, AUTH_GET_PERMISSIONS } from 'react-admin';
import {
    myAuthSetStore,
    myAuthStoreSelf,
    myAuthClearAuth,
    myAuthClearSelf,
} from "../LogicV2/auth/MyExtraAuthActions";
import {
    clearCustomLayer
} from "../LogicV1Redux/actions/CustomLayerActions";
import {mapClearSetupRq} from "../LogicV1Redux/actions/MapUxConfigActions";
import {clearAllPrimitives} from '../LogicV1Redux/actions/DrawEditorActions'

//import decodeJwt from 'jwt-decode'; token not decodable... looback settings to check

import StoreProvider from './MyStoreProvider'
import DataProvider from './MyDataProvider'

//buu?
import {combineRoles, PM_ROLES} from './logic/MyRestConfig';

import {

    MY_AUTH_REGISTER_RQ,
    MY_AUTH_RESETPASS_RQ,
    MY_AUTH_NEWPASS_RQ,
    MY_AUTH_LOADSELF_RQ,
    MY_AUTH_SAVESELF_RQ,
    MY_AUTH_REGISTERCONFIRM_RQ,
    MY_AUTH_NEWPHONE_RQ,
    MY_AUTH_PHONECODEVERIFY_RQ,
    MY_AUTH_NICKNAME_RQ,
    MY_AUTH_ADDNICKNAME_RQ,

} from "../LogicV2/auth/MyAuthConstants";
import * as moment from "moment";
import {UNIT_TYPES} from "./components/resources/Units";

import {AppTitle} from "../LogicV1Redux/constants/AppTitleEnums";
import {omit} from "lodash";
import {getDeviceInfo} from "./utils/browserHelpers";
import {API_VERBS} from "./MyApiVerbs";

// in src/comment/commentActions.js
// export const userLogin = (data) => ({
//     type: 'DR2_USER_LOGIN',
//     payload: { data },
//     meta: { resource: '!AUTH!', fetch: 'USER_LOGIN', cancelPrevious: true },
// });

//this part bit sucks since i am splitting auth related sagas into two parts
//react admin defaults, and needed extras (to keep updating easy, and let's see what guys will add)
//because of that i am keeping original actions, so here i have to use dataProvider call

//console.warn('hardcoded roles to fix asap!!');
const MyAuthProvider = (type, params) => {
    const store = StoreProvider.getStore();
    const dataProvider = DataProvider.getDataProvider();
    // AUTH, MY_AUTH are asyncs, so MY_AUTH should be called from custom saga, not directly... fixme but later
    const currentState = store.getState();

    //console.warn(type, 'store', currentState);

    //special case
    if (type === AUTH_GET_PERMISSIONS) {


        const mySelf = currentState.mySelf;

        if (mySelf.roles) {
            return Promise.resolve({roles:mySelf.roles, perResource:mySelf.perResource});
        } else {
            //fallback to loadself
            type = MY_AUTH_LOADSELF_RQ
        }
    }

    switch (type) {
        case AUTH_LOGIN: {
            console.log(AUTH_LOGIN, params);

            const {username, password, fromQueryString = false} = params;

            let data = {password};
            //fixing payload
            let isEmail = (username.indexOf('@') !== -1);
            if (isEmail) {
                data.email = username
            } else {
                data.username = username
            }

            data.deviceInfo = getDeviceInfo();

            //return Promise.resolve();
            return dataProvider(API_VERBS.LOGIN, '!AUTH!', {data})
                .then((result) => {
                    console.log(API_VERBS.LOGIN, result);
                    let auth = {...result.data};
                    console.log('auth', auth);

                    auth.token = auth.id;
                    delete auth.id;

                    const myAppExtras = store.getState().myAppExtras;
                    console.log(myAppExtras);

                    if (myAppExtras && myAppExtras.app.name === 'RT_MAP') {
                        store.dispatch(myAuthStoreSelf({pandoraAuth:{pass:data.password,user:data.username, fromQueryString}}));
                    }
                    //token not decodable... looback settings to check
                    //console.log('decoded token', decodeJwt(auth.token));
                    store.dispatch(myAuthSetStore(auth));

                    //console.error('======initing socket v2!!!');

                    //MySocketIoV2Client.init({auth, myAppExtras});

                    //aargh, sprint
                    //LGZ here -> after over half a year, WHY double call to set store? madrid? TODO cleanup
                    if (myAppExtras && myAppExtras.app.name === 'RT_MAP') {
                        setTimeout(() => {
                            store.dispatch(myAuthStoreSelf({pandoraAuth:{pass:data.password,user:data.username, fromQueryString}}));
                        }, 33);
                    }

                    return Promise.resolve();
                })
                .catch((e) => {
                    console.error(e);
                    return Promise.reject(e);
                });
        }
        case AUTH_LOGOUT: {

            //Reset title
            //TODO is this the best place for this? If yes - title setup should be also here in AUTH_LOGIN.
            document.title = AppTitle.CORE;
            store.dispatch(myAuthClearAuth());
            store.dispatch(myAuthClearSelf({}));
            //TODO clear storage PER REDUCER and PER APP! (saga i guess, got something like that in mobile app already)
            store.dispatch(clearCustomLayer({}));
            store.dispatch(clearAllPrimitives({}));

            //WIP: clear missing custom stores

            //ooops. pandora requires custom initialState, hot fix -> disabling map setup clear at all
            //store.dispatch(mapClearSetupRq({}));

            console.warn(`${AUTH_LOGOUT} here, finish clearing stores WIP!`);
            return Promise.resolve();
        }
        // called when the API returns an error
        case AUTH_ERROR: {
            console.log(AUTH_ERROR, params);

            const {status} = params;
            if (status === 401 || status === 403) {
                return Promise.reject();
            }
            return Promise.resolve();
        }
        // called when the user navigates to a new location
        // since we are using stored lb tokens, extra validation is not needed here, except rt-map
        case AUTH_CHECK: {

            console.log(AUTH_CHECK, params);

            //console.log('store', store.getState());
            if (params.isRtMap && !params.pandoraAuthOk) {
                console.log('rejecting auth check');
                return Promise.reject();
            }
            return Promise.resolve();
        }
        case MY_AUTH_LOADSELF_RQ: {
            console.log(MY_AUTH_LOADSELF_RQ, params);

            //console.log(store.getState());
            //return Promise.resolve();

            return dataProvider(API_VERBS.LOADSELF, '!AUTH!', {})
                .then((result) => {
                    //console.log(`${API_VERBS.LOADSELF} ret`, result);
                    let self = {...result.data};
                    console.log(API_VERBS.LOADSELF, self);

                    let roles = [];

                    const currentState = store.getState();

                    //FIXME aargh hardcoded!
                    //- hardcoded operator - as there is no role in operator)
                    //and - for now hardcoded setting manager role for every gov..,
                    //  should use  unit-gov data (role in unit)
                    // but there is same problem like in zone-leg,
                    // -> either combine on frontend two tables per id
                    // or wait until issue is solved on backend.. huh..
                    // so.. let's skip for today.
                    if (currentState.myAppExtras.appMode === 'OPERATOR') {
                        roles.push(PM_ROLES.OPERATOR);
                    } else {
                        const isSuperAdmin = !!(self.units.find(el => el.name === 'SUPERADMIN'));
                        //old email method
                        //const isSuperAdmin = (self.email === 'droneadmin@droneradar.eu');
                        if (isSuperAdmin) {
                            roles.push(PM_ROLES.SUPERADMIN);
                            roles.push(PM_ROLES.MANAGER);
                            //disabled due to restrictions on notam create
                            //roles.push(PM_ROLES.ATC_MASTER);
                            roles.push(PM_ROLES.DRAI_EDITOR);
                            roles.push(PM_ROLES.LAU_EDITOR);
                            //roles.push(PM_ROLES.CAA_EDITOR); hmm, dashboard .. leaving as is for now

                        } else {
                            roles.push(PM_ROLES.MANAGER);

                            //hacking per unit typ
                            //JASIEK! typ !
                            //caa -> ulc
                            const isCaa = !!(self.units.find(el => el.typ === UNIT_TYPES.CAA));
                            //lau
                            const isLau = !!(self.units.find(el => el.typ === UNIT_TYPES.LAU));
                            //ansp
                            const isAnsp = !!(self.units.find(el => el.typ === UNIT_TYPES.ANSP));

                            for (let unit of self.units) {
                                console.log('unit here', unit);
                            }

                            //advanced mixing roles untested/unsupported...
                            // LG-> what is the difference between these two editors?
                            if (isLau) {
                                roles.push(PM_ROLES.DRAI_EDITOR);
                                roles.push(PM_ROLES.LAU_EDITOR);
                            }
                            if (isCaa) {
                                roles.push(PM_ROLES.CAA_EDITOR);
                            }
                            //huh, for madrid?
                            if (isAnsp) {
                                roles.push(PM_ROLES.ATC_MASTER);
                            }
                            //huh -> here should be full unit roles processing
                            roles.push(PM_ROLES.MANAGER);
                        }

                    }
                    //store.dispatch(myAuthSetStore(auth));

                    const perResource = combineRoles(roles);

                    store.dispatch(myAuthStoreSelf({...self, roles, perResource}));
                    console.warn('fck!, roles', roles);

                    return Promise.resolve({roles, perResource});

                })
                .catch((e) => {
                    console.error(e);
                    return Promise.reject(e);
                });

        }
        //my custom auth
        case MY_AUTH_SAVESELF_RQ: {
            console.log(MY_AUTH_SAVESELF_RQ, params);
            //return Promise.reject();
            return dataProvider(API_VERBS.SAVESELF, '!AUTH!', {data:params.data})
                .then((result) => {
                    console.log(API_VERBS.SAVESELF, result);

                    //store.dispatch(myAuthStoreSelf({...self, roles}));
                    store.dispatch(myAuthStoreSelf({...result.data}));

                    return Promise.resolve();

                })
                .catch((e) => {
                    console.error(e);
                    return Promise.reject(e);
                });

        }
        case MY_AUTH_NICKNAME_RQ: {
            console.log(API_VERBS.REQUEST_NICKNAMES, params);
            return dataProvider(API_VERBS.REQUEST_NICKNAMES, '!AUTH!', {data: params});
        }
        case MY_AUTH_ADDNICKNAME_RQ: {
            console.log(API_VERBS.PUT_NICKNAME, params);
            return dataProvider(API_VERBS.PUT_NICKNAME, '!AUTH!', {data: params})
                .then((result) => {
                    console.log(API_VERBS.PUT_NICKNAME, result);
                    store.dispatch(myAuthStoreSelf({...result.data}));
                    return Promise.resolve();
                })
                .catch((e) => {
                    console.error(e);
                    return Promise.reject(e);
                });
        }
        case MY_AUTH_NEWPASS_RQ: {

            return dataProvider(API_VERBS.NEWPASSRQ, '!AUTH!', {data:params})
                .then((result) => {
                    console.log(API_VERBS.NEWPASSRQ, result);

                    return Promise.resolve({data:result});
                })
                .catch((e) => {
                    console.error(API_VERBS.NEWPASSRQ,e);
                    return Promise.reject(e);
                });
        }
        case MY_AUTH_RESETPASS_RQ: {

            return dataProvider(API_VERBS.RESETPASSRQ, '!AUTH!', {data:params})
                .then((result) => {
                    console.log(API_VERBS.RESETPASSRQ, result);
                    store.dispatch(myAuthStoreSelf({resetPassRqEmail:params.email}));

                    return Promise.resolve({data:result});
                })
                .catch((e) => {
                    console.error(API_VERBS.RESETPASSRQ,e);
                    return Promise.reject(e);
                });
        }
        case MY_AUTH_REGISTER_RQ: {

            //store.dispatch(myAuthStoreSelf({registrationEmail:params.email}));
            // console.warn('=========REGISTER to finish', params);
            // return Promise.resolve();

            return dataProvider(API_VERBS.REGISTER, '!AUTH!', {data:params})
                .then((result) => {
                    console.log(API_VERBS.REGISTER, result);
                    store.dispatch(myAuthStoreSelf({registrationEmail:result.data.email}));

                    //store.dispatch(myAuthStoreSelf({...self, roles}));
                    //store.dispatch(myAuthStoreSelf({...result.data}));

                    return Promise.resolve({data:result});

                })
                .catch((e) => {
                    console.error(API_VERBS.REGISTER, e);
                    return Promise.reject(e);
                });
        }
        case MY_AUTH_REGISTERCONFIRM_RQ: {

            return dataProvider(API_VERBS.REGISTERCONFIRM, '!AUTH!', {data:params})
                .then((result) => {
                    console.log(API_VERBS.REGISTERCONFIRM, result);
                    return Promise.resolve({data:result});
                })
                .catch((e) => {
                    console.error(API_VERBS.REGISTERCONFIRM, e);
                    return Promise.reject(e);
                });
        }
        case MY_AUTH_NEWPHONE_RQ: {
            console.log(MY_AUTH_NEWPHONE_RQ, params);

            //reset code request
            store.dispatch(myAuthStoreSelf({
                smsCodeRequest: undefined
            }));

            const cleanedData = omit(params.data, 'smsCodeAgain') ;
            return dataProvider(API_VERBS.PATCH_PHONE, '!AUTH!', {data: cleanedData})
                .then((result) => {
                    console.log(API_VERBS.PATCH_PHONE, result);
                    //TODO phoneVerified=false should be returned from backend
                    //TODO backend should return message nr and send time
                    store.dispatch(myAuthStoreSelf({
                        smsCodeConfirmed: undefined,
                        smsCodeRequest: result.data.status,
                        // TODO save this in etc to server
                        smsCodeTime: moment().format('D.MM.YYYY HH:mm:ss'),
                        phoneVerified: false,
                        phone: result.data.phone,
                        smsCodeAgain: params.data.smsCodeAgain
                    }));
                    return Promise.resolve();

                })
                .catch((e) => {
                    console.error(e);
                    store.dispatch(myAuthStoreSelf({smsCodeRequest: false}));
                    return Promise.reject(e);
                });

        }
        case MY_AUTH_PHONECODEVERIFY_RQ: {
            console.log(MY_AUTH_PHONECODEVERIFY_RQ, params);
            return dataProvider(API_VERBS.VERIFY_PHONE, '!AUTH!', {data:params.data})
                .then((result) => {
                    console.log(API_VERBS.VERIFY_PHONE, result);
                    //TODO phoneVerified=true should be returned from backend
                    store.dispatch(myAuthStoreSelf({
                        smsCodeConfirmed: result.data.status,
                        smsCodeRequest: undefined,
                        phoneVerified: true,
                        smsCodeAgain: undefined,
                    }));
                    return Promise.resolve(result);

                })
                .catch((e) => {
                    console.error(e);
                    store.dispatch(myAuthStoreSelf({smsCodeConfirmed: false}));
                    return Promise.reject(e);
                });

        }

        default: {
            console.log('Unknown AUTH method here', type, params);

            return Promise.reject('Unknown method');
        }
    }
};

export default MyAuthProvider;
