
import {turfCircle, turfDistance, turfGreatCircle, turfDestination} from '../oldCommon/commonTurf.js'
import { PRIMITIVES} from '../deckDraw/DrawEditorEnums'
import {getDrawIco,  DEFAULT_MAX, DEFAULT_MIN} from '../deckDraw/deckEditor';
import {DeckPrimitive} from "./DeckPrimitive";
import {functionalColors} from "../oldCommon/commonInits";
import {turfArea} from "../oldCommon/commonTurf";

//circle is defined by center and second construction point for radius calc or TODO radius itself
export default class DeckMeasure extends DeckPrimitive {

    constructor(latlngA, LatLngB) {
        super();

        this.primitiveType = PRIMITIVES.MEASURE;
        this.helpers = [latlngA, LatLngB];
        this.texts = [];
        this._calcGeometry();
    }

    //fixme?
    recalc(latlngA, latlngB) {
        this.helpers = [latlngA, latlngB];
        this._calcGeometry();
        return this;
    }



    _calcGeometry() {
        this.distance = turfDistance(this.helpers[0], this.helpers[1]);

        this.centroid = [(this.helpers[0][0]+this.helpers[1][0])/2, (this.helpers[0][1]+this.helpers[1][1])/2];

        this.texts = [];

        let text = {};
        text.centroid = this.centroid;
        //text.displayName = this.distance.toFixed(2);

        text.displayName = this.distance.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
        text.displayName += 'm';

        this.texts.push(text);

        this.markers = [
            getDrawIco('BLUE_CIRCLE', this.helpers[0][0], this.helpers[0][1]),
            getDrawIco('BLUE_CIRCLE', this.helpers[1][0], this.helpers[1][1]),
        ];

        this.paths = (this.distance !== 0) ? [
            {
                path: turfGreatCircle(this.helpers[0], this.helpers[1]),  //using greatCircle!.. hmm?
                widthScale: 1,
            }
        ] : null;

    }

}
