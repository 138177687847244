import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import ActionCheck from '@material-ui/icons/CheckCircle';
import AlertError from '@material-ui/icons/ErrorOutline';
import classnames from 'classnames';
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import {translate} from 'react-admin';
import compose from 'recompose/compose'
import {InfoText} from "./InfoText";
import Typography from "@material-ui/core/Typography/Typography";

const styles = (theme) => {
    return ({
        confirmPrimary: {
            color: theme.palette.primary.main,
        },
        confirmWarning: {
            color: theme.palette.error.main,
            '&:hover': {
                backgroundColor: fade(theme.palette.error.main, 0.12),
                // Reset on mouse devices
                '@media (hover: none)': {
                    backgroundColor: 'transparent',
                },
            },
        },
        iconPaddingStyle: {
            paddingRight: '0.5em',
        },
        closeButton: {
            position: 'absolute',
            right: '0px',
            top: '0px',
        },
        textField: {
            width: "100%",
            marginRight: "0"
        },
        paperScrollPaper: {
            flexGrow: "1",
        },
        dialogTitle: {
            cursor: "move",
            userSelect: "none"
        },
    })
};

const MyInputDialogCore = ({
                               isOpen,
                               onClose,
                               onChange,
                               onDragBegin,
                               onDragStop,
                               dialogContent,
                               classes,
                               onConfirm,
                               inputText,
                               confirmColor,
                               placeholder,
                               confirmDisabled,
                               title,
                               label,
                               cancel,
                               confirm,
                               translate,
                               confirmActions,
                               infoText,
                               translateX = "0px",
                               translateY = "0px",
                               hideCancel,
                               hideClose,
                               hideConfirm,
                               maxWidth
                           }) => (
    <Dialog
        open={isOpen}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        classes={{paperScrollPaper: classes.paperScrollPaper}}

        onDragOver={ (event)=>{
            event.preventDefault();
        }}

        PaperProps={{
            style: {
                transform: `translateX(${translateX}) translateY(${translateY})`
            },
        }}
        maxWidth={maxWidth}
    >
        <DialogTitle id="alert-dialog-title"
                     className={classes.dialogTitle}
                     draggable={"true"}
                     onDragStart={onDragBegin}
                     onDragEnd={onDragStop}
        >
            {translate(title)}
            {
                !hideClose &&
                <IconButton
                    key="close"
                    aria-label="Close"
                    color="inherit"
                    className={classes.closeButton}
                    onClick={onClose}
                >
                    <CloseIcon/>
                </IconButton>
            }

        </DialogTitle>
        <DialogContent>
            {!dialogContent ?
                <div>
                    {infoText && <Typography><InfoText content={translate(infoText)}/></Typography>}
                    <TextField
                        error
                        id="outlined-error"
                        label={translate(label)}
                        className={classes.textField}
                        margin="normal"
                        variant="outlined"
                        placeholder={translate(placeholder)}
                        multiline
                        value={inputText}
                        onChange={onChange}
                    />
                </div>
                :
                <>
                    {
                        label &&
                        <DialogContentText>
                            {Array.isArray(label) ? translate(...label) : translate(label)}
                        </DialogContentText>
                    }

                    {dialogContent()}
                </>
            }
        </DialogContent>
        <DialogActions>
            {
                !hideCancel &&
                <Button onClick={onClose}>
                    <AlertError className={classes.iconPaddingStyle}/>
                    {translate(cancel)}
                </Button>
            }
            {
                !hideConfirm &&
                (confirmActions ?
                        confirmActions({inputText, classes, translate}) :
                        <Button
                            onClick={() => {
                                onConfirm(inputText)
                            }}
                            className={classnames('ra-confirm', {
                                [classes.confirmWarning]: confirmColor === 'warning',
                                [classes.confirmPrimary]: confirmColor === 'primary',
                            })}
                            disabled={confirmDisabled}
                        >
                            <ActionCheck className={classes.iconPaddingStyle}/>
                            {translate(confirm)}
                        </Button>
                )
            }

        </DialogActions>
    </Dialog>
);

class MyInputDialog extends React.Component {

    state = {
        inputText: this.props.translate(this.props.defaultText),
            x: 0,
            y: 0,
            xStart: 0,
            yStart: 0,
        // inputs: undefined
    };

    // componentWillMount() {
    //     if(!!this.props.content) {
    //         const inputsCount = this.props.content().props.children.length;
    //         this.setState({
    //             inputs: new Array(inputsCount)
    //         })
    //     }
    // }

    _onDragStart = (event) => {
        this.setState({
            xStart: event.clientX,
            yStart: event.clientY,
        })
    };

    _onDragStop = (event) => {
        const {xStart, yStart, x, y} = this.state;
        const xStop = event.clientX;
        const yStop = event.clientY;

        //If drop is behind the window, close
        if(xStop < 0 || yStop < 0 || xStop > window.innerWidth || yStop > window.innerHeight) {
            this.props.onClose();
        }
        else {
            this.setState({
                x: x + xStop - xStart,
                y: y + yStop - yStart,
            });
        }

    };

    _onChange = (event) =>
        this.setState({
            inputText: event.target.value,
        });


    render() {
        const {inputText, x, y} = this.state;
        const {content, noInput} = this.props;
        const dialogContent = noInput ? ()=><></> : content;

        return (
            <MyInputDialogCore {...{
                inputText,
                dialogContent,
                onDragBegin: this._onDragStart,
                onDragStop: this._onDragStop,
                onChange: this._onChange,
                translateX: `${x}px`,
                translateY: `${y}px`,
                ...this.props
            }}
            />
        )
    }
}

MyInputDialog.propTypes = {
    cancel: PropTypes.string.isRequired,
    classes: PropTypes.object.isRequired,
    confirm: PropTypes.string.isRequired,
    confirmColor: PropTypes.string.isRequired,
    confirmDisabled: PropTypes.bool,
    content: PropTypes.func,
    isOpen: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    label: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
    noInput: PropTypes.bool,
    confirmActions: PropTypes.func,
    infoText: PropTypes.string,
    hideCancel: PropTypes.bool,
    hideClose: PropTypes.bool,
    hideConfirm: PropTypes.bool
};

MyInputDialog.defaultProps = {
    cancel: 'Cancel',
    classes: {},
    confirm: 'Confirm',
    onConfirm: ()=>{},
    confirmColor: 'primary',
    isOpen: false,
    title: "Title",
    confirmActions: null,
};


export default compose(
    withStyles(styles),
    translate
)(MyInputDialog);