import React from "react";
import {withStyles} from "@material-ui/core";
import get from "lodash.get";
import {Link} from "ra-ui-materialui";
import MyGeomConversionMenu, {GEOM_CONVERSIONS} from './MyGeomConversionMenu';
import {wkb2geojson} from '../../gl_map/glSetups/deckPrimitives/DeckPrimitiveSerialization';


//fixme -> connect theme styling

class MyGeomOutput extends React.Component {
    state = {
        conversion: GEOM_CONVERSIONS.GEOJSON_FEATURE
    };

    onConversionChange = ({mode}) => {
        console.log('currentMode', mode);
        this.setState({ conversion: mode });

    };

    render() {
        const {record} = this.props;
        const disabled = true;
        const {conversion} = this.state;

        let outputVal = ''; //not null;
        try {
            if (record.geometry) {
                switch (conversion) {
                    case GEOM_CONVERSIONS.GEOJSON_FEATURE:
                        //fixme should use backend geojson! (not available for units now)
                        // and what about properties...
                        outputVal = JSON.stringify({ "type": "Feature", geometry: wkb2geojson(record.geometry)});
                        break;
                    case GEOM_CONVERSIONS.GEOJSON_GEOMETRY:
                        //fixme should use backend geojson! (not available for units now)
                        outputVal = JSON.stringify(wkb2geojson(record.geometry));

                        break;
                    default: //WKB
                        outputVal = record.geometry;
                }
            }
        } catch (e) {
            console.warn('conversion failed', e)
        }
        return (
            <div>
                <MyGeomConversionMenu defaultVal={conversion} onChange={this.onConversionChange}/>
                <textarea disabled={disabled} style={{width: "100%", minHeight: '100px'}}
                          value={outputVal}/>
            </div>
        )
    }
}

export default MyGeomOutput;
