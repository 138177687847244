import React from 'react';
import {maxLength, minLength, required, SimpleForm, TextInput, email} from 'react-admin';

import {withStyles} from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TranslatableTypography from "../../TranslatableTypography";
import classnames from "classnames";
import {InfoText} from "../../InfoText";
import {panelStyles} from "./panelStyles";
import DisabledInput from "ra-ui-materialui/esm/input/DisabledInput";
import {ACCOUNT_EMAIL_FORM, PANEL_EMAIL} from "../../../logic/settings/AccountConstants";

const defaultRequired = [required(), minLength(2), maxLength(64)];

export default withStyles(panelStyles)(
    ({
         classes,
         mySelf,
         expanded,
         handleChange,
         handleSave,
         offline,
         disabled,
         translate
     }) =>
        <ExpansionPanel expanded={expanded === PANEL_EMAIL}
                        onChange={handleChange(PANEL_EMAIL)}
                        className={classnames(
                            classes.expansionPanel,
                            {
                                [classes.overflowHide]: expanded && expanded !== PANEL_EMAIL,
                                [classes.expansionPanelNotActive]: expanded !== PANEL_EMAIL,
                            }
                        )}
                        square={false}
                        id={PANEL_EMAIL}
        >
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon/>}>
                <div className={classes.block}>
                    <Typography component="p" className={classes.heading}>
                        {translate('myroot.dashboard.accountForm.email')}
                    </Typography>
                    <Typography component="p" className={classes.subheading}>
                        {translate('myroot.validation.required')}
                    </Typography>
                </div>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                <div className={classes.block}>

                    <Typography component="p" className={classes.subheading}>
                        {translate('myroot.dashboard.accountForm.emailDscr')}
                    </Typography>
                    <SimpleForm form={ACCOUNT_EMAIL_FORM}
                                save={handleSave}

                                record={{
                                    email: mySelf.email,
                                    emailVerified: mySelf.emailVerified,
                                }}
                                redirect={false}
                                toolbar={disabled ? null : undefined}
                    >
                        <TextInput source="email"
                                   label={'myroot.dashboard.accountForm.email'}
                                   validate={[...defaultRequired, email()]}
                                   {...{disabled}}
                        />
                        <DisabledInput source="emailVerified"
                                       label={'myroot.dashboard.accountForm.emailVerified'}
                                       input={mySelf.emailVerified ? {value: translate('ra.boolean.true')} : {value: translate('ra.boolean.false')}}
                        />
                    </SimpleForm>

                    {disabled && !offline &&
                    <InfoText content={<TranslatableTypography content="myroot.auth.editionLocked"/>}
                              className={classnames({
                                  [classes.disabledText]: expanded === PANEL_EMAIL,
                                  [classes.disabledTextHide]: expanded !== PANEL_EMAIL,
                              })}

                    />
                    }

                    {disabled && offline &&
                    <InfoText content={<TranslatableTypography content="myroot.auth.editionLockedOffline"/>}
                              className={classnames({
                                  [classes.disabledText]: expanded === PANEL_EMAIL,
                                  [classes.disabledTextHide]: expanded !== PANEL_EMAIL,
                              })}

                    />
                    }
                </div>
            </ExpansionPanelDetails>
        </ExpansionPanel>
)