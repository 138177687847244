//see zone field notes,
// this is similar component for loopback mission
// was linked to full screen, now (v1.1) geometry edition is enabled within, and fullscreen link is removed..

import React from 'react';

import {deserializeMissionData} from "../../../../LogicV1Redux/oldLogic/MissionsListV2";
import {initializeLayersObject} from "../../../../gl_map/glSetups/newCommon/mapStateConstructors";

import StoreProvider from "../../../MyStoreProvider";
import {setAllEditablePrimitives, clearAllPrimitives} from "../../../../LogicV1Redux/actions/DrawEditorActions";
import {storeCustomLayer} from "../../../../LogicV1Redux/actions/CustomLayerActions";

import {shallowEqual} from 'recompose';

import {primitives2Bbox} from "../../../../gl_map/glSetups/oldCommon/_CommonStuff";

import {fetchAllZonesV2Rq} from "../../../../LogicV1Redux/actions/ZonesV2Actions";
import {storeUiZonesV2Show} from '../../../../LogicV1Redux/actions/MapUxConfigActions'


import MissionEditor from './MissionEditor';
import MissionViewer from './MissionViewer';
import {storeInspectData} from "../../../../LogicV1Redux/actions/InspectActions";
import {InspectTypes} from "../../../../modules2lib/common";

//---------------- My Mission Field HOC

//fixme optimize loops/deserilizes/joins
//HOC processes *passed* mission data (legs/legZones/zones) into reasonable data structure..

class MyMissionField extends React.Component {



    // shouldComponentUpdate(nextProps, nextState) {
    //     console.log('MyMissionField shouldComponentUpdate', nextProps);
    //     if (this.props !== nextProps) {
    //         //console.log('old props', this.props);
    //         if (this.props.record.legs === []) return true;
    //
    //         if (this.props.record.legs === nextProps.record.legs) {
    //             console.log('same legs');
    //             return (nextState === this.state);
    //         }
    //         else {
    //             console.log('updating');
    //         }
    //         //this.setState({viewportData:prevViewport});
    //     }
    //     return true;
    // }

    componentDidMount() {
        console.log("MMF did mount");

        //clearing inspect data on mission field init due to issue #440
        StoreProvider.getStore().dispatch(storeInspectData({InspectType:InspectTypes.NO_DATA}));

        this._do();
    }

    componentWillUnmount() {
        console.log("MMF unmount");
        StoreProvider.getStore().dispatch(clearAllPrimitives());

    }

    componentDidUpdate(prevProps) {
        console.log("MMF did update", prevProps, this.props);
        if (!shallowEqual(this.props, prevProps)) {
            this._do();
        } else {
            console.log('mmf shallow equal false');
        }
    }

    _do = () => {
        console.log("MMF did do");

        //hacking so-called inifinite rerender on opened settings view
        // probably caused by dispatch call below AND multiple store changes created by new form!)
        //i guess can be normally binded as prop from state, but later...
        const state = StoreProvider.getStore().getState();
        if (state.mySettings.profileView.visible) {
            console.warn('DO NOT update mission on settings open!');
            return;
        }


        const {record = {}} = this.props;
//fixme viewer mode... somehow

        console.log('========wtf filtering MyMission orig', Object.assign({}, record));

        console.log('MyMission record', record);

        const {mySelf} = state; //to check units ownership for legzones status updates.

        const zoneTypesV2 = StoreProvider.getState().zoneTypesV2;
        const zonesV2 = StoreProvider.getState().zonesV2;

        const missionData = deserializeMissionData({missionRecord: Object.assign({}, record), mySelf, zoneTypesV2, zonesV2 });

        console.log('MyMission deserialized', missionData);

        const legsPrimitives =
            (missionData && missionData.primitives)
                ? missionData.primitives : [];

        let warningsPrimitives = [];
        const warningsTexts = [];
        const bBox = primitives2Bbox(legsPrimitives);

        if ((missionData && missionData.warnings)) {
            missionData.warnings.forEach((warning) => {
                    //can be failed deserialization?
                    //console.log('warning', warning);

                    if (warning.primitive) {
                        warningsPrimitives.push(warning.primitive);
                        const text = {};
                        text.centroid = warning.primitive.centroid;
                        text.displayName = warning.primitive.inspectData.name;
                        warningsTexts.push(text);
                    }
                }
            )
        }

//there is esoteric difference between conflicts and warnigns..
//to cleanup during refactoring
        const conflicts =
            //(record.__editedLegsPrimitives)
            //? record.__editedLegsPrimitives
            //:
            (missionData && missionData.conflicts)
                ? missionData.conflicts : [];

//const warningsPrimitives = warningsPrimitives; //mer

        console.warn('setting state in MyMissionField');
        console.log('mss', legsPrimitives, warningsPrimitives);

        //TODO review/retest old notes
//...and launch editor redux,
//without connecting! to test if can be safely connected (wihout rerenders)

        //const zonesV2 = StoreProvider.getState().zonesV2;
        //TODO doublecheck -> looks like should be set already!!!
        if (zonesV2.rawData.length === 0) {
            StoreProvider.getStore().dispatch(fetchAllZonesV2Rq());
        }

        if (this.props.enableEdit) {
            StoreProvider.getStore().dispatch(setAllEditablePrimitives(legsPrimitives));

            const newLayer = initializeLayersObject();
            newLayer.polys = [...warningsPrimitives];
            newLayer.texts = warningsTexts;

            StoreProvider.getStore().dispatch(storeCustomLayer({deckData:newLayer, rawData:warningsPrimitives}));


            if (legsPrimitives.length === 0) {
                StoreProvider.getStore().dispatch(storeUiZonesV2Show(true));
            } else {
                StoreProvider.getStore().dispatch(storeUiZonesV2Show(false));
            }
        }

        else {
            //move to viewer?
            const mergedPrimitives = [...warningsPrimitives, ...legsPrimitives]; //for now primitives should be on top
            const newLayer = initializeLayersObject();
            newLayer.polys = mergedPrimitives;
            newLayer.texts = warningsTexts;
            //console.log(newLayer)

            StoreProvider.getStore().dispatch(storeCustomLayer({deckData:newLayer}));

        }

        //PubSub.publish(PubSubSignals.ON_ZONES_RULES_CHANGE, getZoneRules());

        this.setState({helpers: warningsPrimitives, legsPrimitives, conflicts, bBox});
    };



    render() {
        if (!this.state) {
            return null;
        }

        //sanitize
        const {
            record = {},
            enableEdit = false,
            altSliderOptions,
            //permissions,
            //dispatch, //hmm reattaching later in context (btw - is it passed now?)
            //...rest
        } = this.props;

        const {legsPrimitives, helpers, conflicts, bBox} = this.state;
        const EditorOrViewer = (enableEdit) ? MissionEditor : MissionViewer;

        return (
            <EditorOrViewer record={record}
                            helpers={helpers}
                            conflicts={conflicts}
                            legsPrimitives={legsPrimitives}
                            bBox = {bBox}
                            altSliderOptions={altSliderOptions}
            />
        )
    }
}


export default MyMissionField;
//TODO retest - previously had warning -> !DO NOT CONNECT mapStateToProps HERE -> will recalc record!
//export default enhance(MyMissionField)

