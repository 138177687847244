const MAX_INTEGER_SIZE_1 = 0xF;
const MAX_INTEGER_SIZE_2 = 0xFF;

/**
 * Inverts given css-color.
 * @param primaryColor Css-color consisting of 3, 4, 6 or 8 hexadecimal digits string with or without '#' at the beginning.
 * @param opacity Opacity percentage value in range from 0 to 1, where 1 is 100%.
 * If given, will override the alpha channel in 4 and 8 hexadecimal digit css-color format.
 * @returns {string} Css 4 or 8 digit hexadecimal string (format: #RRGGBBAA)
 */
export const invertColorToCSSHexString = (primaryColor, opacity) => {

    _checkOpacity(opacity);

    const cleanedHexString = primaryColor.replace(/^#/, '');
    const channels = _parseToIntChannels(cleanedHexString);
    const channelsInverted = _invertChannels(channels);

    if(opacity !== undefined) {
        if(channels.size === 2)
            channelsInverted['alpha'] = Math.round(parseFloat(opacity) * MAX_INTEGER_SIZE_2);
        else
            channelsInverted['alpha'] = Math.round(parseFloat(opacity) * MAX_INTEGER_SIZE_1);
    }
    else if (!channelsInverted.hasAlpha) {
        if(channels.size === 2)
            channelsInverted['alpha'] = MAX_INTEGER_SIZE_2;
        else
            channelsInverted['alpha'] = MAX_INTEGER_SIZE_1;
    }

    const hexStringChannels = _parseToHexStringChannels(channelsInverted, channels.size);

    return `#${hexStringChannels.red}${hexStringChannels.green}${hexStringChannels.blue}${hexStringChannels.alpha}`.toUpperCase();
};


function _checkOpacity(opacity){
    if(opacity < 0 || opacity > 1) throw `Invalid opacity value. Acceptable range is 0 to 1. Got ${opacity}!`
}


function _parseToIntChannels(input) {
    const channels = {};
    switch(input.length) {
        case 4:
            channels.alpha = parseInt(input[3], 16);
        case 3:
            channels.size = 1;
            channels.red = parseInt(input[0], 16);
            channels.green = parseInt(input[1], 16);
            channels.blue = parseInt(input[2], 16);
            break;

        case 8:
            channels.alpha = parseInt(input[6] + input[7], 16);
        case 6:
            channels.size = 2;
            channels.red = parseInt(input[0] + input[1], 16);
            channels.green = parseInt(input[2] + input[3], 16);
            channels.blue = parseInt(input[4] + input[5], 16);
            break;

        default:
            throw `Invalid hex notation for css-color. Hex digit count should be 3, 4, 6 or 8. Is ${input.length}!`
    }
    return channels;
}


function _invertChannels(channels) {
    const channelsInverted = {
        hasAlpha: false
    };

    for (const key in channels) {
        if (key === 'size') {
            continue;
        }
        if (key === 'alpha') {
            channelsInverted.hasAlpha = true;
            channelsInverted[key] = channels[key];
            continue;
        }

        if (channels.size === 2)
            channelsInverted[key] = MAX_INTEGER_SIZE_2 - channels[key];
        else
            channelsInverted[key] = MAX_INTEGER_SIZE_1 - channels[key];
    }
    return channelsInverted;
}


function _parseToHexStringChannels(input, channelSize) {
    const channels = {};

    for(const key in input) {
        if(typeof input[key] !== 'number') continue;

        let hexString = input[key].toString(16);
        if(hexString.length < 2 && channelSize === 2) {
            hexString = '0' + hexString;
        }

        channels[key] = hexString;
    }
    return channels;
}
