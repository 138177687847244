import {AutocompleteInput, Filter, ReferenceInput, TextInput} from "ra-ui-materialui";
import React from "react";
import TextField from '@material-ui/core/TextField';
import { Field } from 'redux-form';
import {number, minValue} from 'react-admin'
import compose from 'recompose/compose';
import {translate} from "react-admin";
import get from "lodash.get";

// ilike search, in postres format ilike strin%

const IlikeSearch = (props) => {
    //console.log('IlikeSearch', props);
    //console.log('IlikeSearch val', props.input.value);

    function fromIlike(val) {
        if (val && val.ilike) {
            const t = val.ilike;
            //console.log('lastT', t[t.length-1]);
            if (t[t.length - 1] === '%') {
                return t.substring(0, t.length - 1);
            } else return t;
        }
        return '';
    }

    function toIlike(val) {
        return {ilike: val + '%'}
    }

    const {resource, source, label, inlineStyle} = props;


    //no validation in filter fields!
    return (
        <Field name={source}
               type="text"
               component={TextInput}
               label={label ? label : `resources.${resource}.fields.${source}`}
               format={fromIlike}
               parse={toIlike}
               style={inlineStyle}
        />
    )
};


const enhance = compose(
    //withStyles(styles),
    translate
);

export default enhance(IlikeSearch);

