import {point, lineString, multiPoint, polygon} from "@turf/helpers";

import harvestineDistance from "@turf/distance";
import circle from "@turf/circle";
import buffer from "@turf/buffer";
import greatCircle from "@turf/great-circle";
import harvestineDestination from "@turf/destination";
import centroid from "@turf/centroid";
import area from "@turf/area";
import kinks from "@turf/kinks";
import within from "@turf/boolean-within";
import disjoint from "@turf/boolean-disjoint";
//import {wkb2geojson} from "../deckPrimitives/DeckPrimitiveSerialization";

const STEPS = 16;


//hmm return of custom primitives creators should be 'turf' type object? to review and unify

//err latlon => lonlat, (ordering)

//pos as arrays / return meters
export function turfDistance(latLngA, latLngB) {
    const from = point(latLngA);
    const to = point(latLngB);
    const options = {units: 'kilometers'};

    const distance = harvestineDistance(from, to, options);

    return distance*1000;
}

//pos as arrays, radius in meters
//TODO -> fix precision, and/or pass true data as arcs, pass extra data for edits?
export function turfCircle(center, radius) {
    const options = {steps:STEPS, units: 'kilometers'};
    const geojson = circle(center, radius/1000, options);
    //console.log(geojson.geometry.coordinates);
    return geojson.geometry.coordinates;
}


//fixme -> heavy non configurable output (turf buffer)
export function turfCapsule(latLngA, latLngB, radius) {
    const line = lineString([latLngA, latLngB]);

    //var point = Point([-90.548630, 14.616599]);
    const options = {steps:STEPS, units: 'kilometers'};
    //console.log('buffer... hmm');
    //console.log('buffer... hmm', line, radius, options);
    const geojson =  buffer(line, radius/1000, options);
    //console.log(geojson.geometry.coordinates);
    return geojson.geometry.coordinates;
}

//fixme -> heavy non configurable output (turf buffer)
export function turfPipe(latlngs, radius) {
    const line = lineString(latlngs);

    //var point = Point([-90.548630, 14.616599]);
    const options = {steps:STEPS, units: 'kilometers'};
    //console.log('buffer... hmm');
    //console.log('buffer... hmm', line, radius, options);
    const geojson =  buffer(line, radius/1000, options);
    //console.log(geojson.geometry.coordinates);
    return geojson.geometry.coordinates;
}

export function turfGreatCircle(latLngA, latLngB) {
    const start = point(latLngA);
    const end = point(latLngB);
    const options = {npoints:STEPS};
    const geojson = greatCircle(start, end, options);
    //console.log(geojson.geometry.coordinates);

    //console.log('harvesine dist (km)', turfDistance(latLngA, latLngB)/1000);
    return geojson.geometry.coordinates;

}

//pos as array, distance in meters, bearing in degrees
export function turfDestination(LatLng, distance, bearing) {
    const start = point(LatLng);
    const options = {units: 'kilometers'};
    const geojson =  harvestineDestination(start, distance/1000, bearing, options);
    console.log('turfDestination ret', geojson);
    return geojson.geometry.coordinates;
}

export function turfCentroid(latLngs) {
    const points = multiPoint(latLngs);
    //console.log(latLngs, points);
    const geojson = centroid(points);
    return geojson.geometry.coordinates;
}

//return in square meters
export function turfArea(polyLatLngs) {
    //console.log(latLngs);
    if (!polyLatLngs[0]) return undefined;
    const length = polyLatLngs[0].length;
    if (length < 4 ) {
        return undefined;
    }
    if (!isPosEqual(polyLatLngs[0][0], polyLatLngs[0][length - 1])) return undefined;
    const poly = polygon(polyLatLngs);
    return area(poly);
}

//return array
export function turfKinks(polyLatLngs) {
    //console.log(latLngs);
    if (!polyLatLngs[0]) return undefined;
    const length = polyLatLngs[0].length;
    if (length < 4 ) {
        return undefined;
    }
    if (!isPosEqual(polyLatLngs[0][0], polyLatLngs[0][length - 1])) return undefined;

    const poly = polygon(polyLatLngs);
    const fc = kinks(poly);
    return fc.features;
}

//helper wrap;
export function geometry2feature (geometry) {
    return { "type": "Feature", "geometry":geometry}
}

//turf geom/poly is geojson feature..
export function turfWithin(turfGeomInside, turfPolyOutside) {
    return within(turfGeomInside, turfPolyOutside);
}

export function turfDisjoint(turfGeomA, turfGeomB) {
    return disjoint(turfGeomA, turfGeomB);
}

const EPSILON = 0.000001;

//TODO zsupport
//assuming small epsilons (square comparison)
//or find some common function
export function isPosEqual(latLngA, latLngB, Epsilon) {
    Epsilon = Epsilon || EPSILON;

    //console.log(latLngA);
    //console.log(latLngB);
    const CheckX = (Math.abs(latLngA[0] - latLngB[0]) < Epsilon);
    const CheckY = (Math.abs(latLngA[1] - latLngB[1]) < Epsilon);
    return CheckX && CheckY;

}

//hmmm, custom for decks lonLat, turf got function for geojsons, compare implemenation
export function calcBoundingBox(lonLats) {
    if ((lonLats.length === 0) || (lonLats[0].length === 0))
        return null;

    let bbox = {
        east:Number.MIN_SAFE_INTEGER,
        west:Number.MAX_SAFE_INTEGER,
        north: Number.MIN_SAFE_INTEGER,
        south:Number.MAX_SAFE_INTEGER,
    };

    //ugh!!! outer ring only!!!
    lonLats[0].forEach((lonLat) => {
        //console.log('lonlat', lonLat);
        const lon = lonLat[0];
        const lat = lonLat[1];

        bbox.east = (lon > bbox.east) ? lon : bbox.east;
        bbox.north = (lat > bbox.north) ? lat : bbox.north;
        bbox.west = (lon < bbox.west) ? lon : bbox.west;
        bbox.south = (lat < bbox.south) ? lat : bbox.south;
    });

    return bbox;
}

