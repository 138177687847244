import React from 'react';
import PropTypes from 'prop-types';
import { Field, propTypes, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
//import CardText from '@material-ui/core/CardText';

import {email, required } from 'react-admin';

import Card from '@material-ui/core/Card';

import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import { translate } from 'ra-core';
import { Link } from 'react-router-dom';

import {loginPageStyles as styles, renderInput} from "../../utils/tempCommon";
import {myAuthSagaRegisterRq} from "../../../LogicV2/auth/MyExtraAuthActions";
import StoreProvider from "../../MyStoreProvider";
import {showNotification} from "react-admin";
import ButtonLink from '../MyButtonLink';
import Typography from '@material-ui/core/Typography';

const ResetPassIntermediateMsg = ({ classes, isLoading, handleSubmit, translate, mySelf }) => (
    <Card>
        <CardActions>
            <Typography gutterBottom>
                {translate('myroot.auth.passwordResetMailSentMsg', {email:mySelf.resetPassRqEmail})}
            </Typography>
        </CardActions>
        <CardActions>
            <Button
                fullWidth={true}
                variant="contained"
                color="primary"
                component={Link}
                to={'/login'}
                //className={classes.link}
            >
                {/*<ProductIcon className={classes.icon} />*/}
                {translate('myroot.dialog.ok')}
            </Button>
        </CardActions>
    </Card>
);

const mapStateToProps = state => {
    console.log('---------------mapStateToProps ResetPassIntermediateMsg', state);
    return { isLoading: state.admin.loading > 0, mySelf:state.mySelf }
};

//form after connect ? works, but... hmmm..
const enhance = compose(
    withStyles(styles),
    translate,
    connect(mapStateToProps),
);

export default enhance(ResetPassIntermediateMsg);
