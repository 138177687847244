import {DRAW_MODES} from './DrawEditorEnums'

import {getCurrentPrimitive, getDrawMode, getEditablePrimitives, setDrawMode} from "./deckDrawStoreBridge";
import {functionalColors} from "../oldCommon/commonInits";


//fixme review comments after finished refactoring

//edits was written in haste... hmm?

//review events drawUpdated!

//fixme hardcoded radius values for predefined primitives (circle2km, capsule2km. etc)
//should be configurable with drawstart params.. somehow
//also names should be refactored to more suitable .. then.

//now interesting question ->
// should we move it to redux, with separate store, but what about 'onmousemove?'
// or keep it as is.. kind of singleton with local data.. bit unconnetcted..
// or somehow instantaite, in context + connect to redux ondrawend...(and replace pubsub here)

//fixme values
export const DEFAULT_MAX = 150;
export const DEFAULT_MIN = 0;

let drawMarkers = [];
let drawPrimitives = [];
let drawPaths= [];
let drawCursor = [];
let drawTexts = [];

export function getDrawIco(type, lat, lng) {
    return {position: [lat, lng], icon: type, size: 32} //was 20
}


export function getDrawCursorIco(latLng, icon, size) {
    return {position: latLng, icon: 'cursorEdit', size: 64}
}

//review call (somehow lonely -> one place?)
//furthermore in latest versions it somehow is not passed further (see getDrawCursor)
export function setDrawCursor(latLng, icon, size) {

}

export function endDraw() {
    const editablePrimitive = getCurrentPrimitive();
    console.log('endDraw', editablePrimitive);
    setDrawMode(DRAW_MODES.EDIT);

}



//IMPORTANT: updates for deck gl requires new array (shallow copy comparison)


//after multi phase1..

//iirc this is hack for fast layers rebuild
//todo cleanup messages requirement after
export function updateDrawLayers(tempmsg) {
    if (!tempmsg) {
        console.warn('====removed _updateDrawLayers!');

        return;
    }

    //console.error(tempmsg);

    const editablePrimitives = getEditablePrimitives();
    const currentPrimitive = getCurrentPrimitive();
    //FIXME! for multi!.. double check polygon markers hack!

    const markers = (currentPrimitive && currentPrimitive.markers) ? currentPrimitive.markers : [];
    const extras = (currentPrimitive && currentPrimitive.extras) ? currentPrimitive.extras : [];

    drawMarkers = [...markers, ...extras];
    drawPaths = (currentPrimitive && currentPrimitive.paths) ? [...currentPrimitive.paths] : []; //hmm

    drawTexts = (currentPrimitive && currentPrimitive.texts) ? currentPrimitive.texts : []; // hmm

    let newPrimitives = [];

    for (let i = 0; i < editablePrimitives.length; i++) {
        if (editablePrimitives[i].polygon) {
            editablePrimitives[i].fillColor = (editablePrimitives[i].__kinks)
                ? functionalColors.ban
                : functionalColors.newPrimitive;

            newPrimitives.push(editablePrimitives[i])
        }
    }

    //!! drawPrimitives is not the same as editablePrimitives (can have no geometry..)
    drawPrimitives = newPrimitives;
}

function _clearDrawLayers() {
    drawMarkers = [];
    drawPaths = [];
    drawPrimitives = [];
    drawCursor = [];
    drawTexts = [];
}

export function isEditingModeEnabled() {
    //console.log('editingmode', (drawMode !== DRAW_MODES.NO_DRAW) && (drawMode !== DRAW_MODES.SELECT), drawMode)
    const drawMode = getDrawMode();

    return ((drawMode !== DRAW_MODES.NO_DRAW) && (drawMode !== DRAW_MODES.SELECT) && (drawMode !== DRAW_MODES.DELETE))
}
//
// export function isDrawSelectModeEnabled() {
//     //console.log('editingmode', (drawMode !== DRAW_MODES.NO_DRAW) && (drawMode !== DRAW_MODES.SELECT), drawMode)
//     return ((drawMode === DRAW_MODES.SELECT))
// }
//
// export function isDeleteModeEnabled() {
//     //console.log('editingmode', (drawMode !== DRAW_MODES.NO_DRAW) && (drawMode !== DRAW_MODES.SELECT), drawMode)
//     return ((drawMode === DRAW_MODES.DELETE))
// }


export function getDrawMarkers() {
    return drawMarkers;
}

export function getDrawPrimitives() {
    return drawPrimitives;
}

export function getDrawPaths() {
    return drawPaths;
}

export function getDrawTexts() {
    return drawTexts;
}

export function getDrawCursor() {
    //return [drawCursor]; //yikes
    return []; //yikes
}

