import {endDraw} from "./deckEditor";

import PubSub from "pubsub-js";
import {PubSubSignals} from "../../../LogicV1Redux/constants/PubSubSignals";


//see deck poly notes

function _closePoly(deckPoly, helpers){
    console.log('closing poly');
    helpers[helpers.length - 1] = deckPoly.markers[0].position;
    deckPoly.recalc(helpers);
    endDraw(deckPoly);
    return deckPoly;
}

//fixme same click?
export function onPolyDrawClick(deckPoly, latLng, layerClickEvent, event) {
    // sprint fixme some better dirty setup needed
    PubSub.publish(PubSubSignals.SET_DRAW_EDITOR_DIRTY);

    if (deckPoly) {
        const helpers = deckPoly.helpers;

        console.log('poly click', deckPoly.markers, layerClickEvent);
        if (event && event.rightButton && helpers.length > 3) {
            deckPoly = _closePoly(deckPoly, helpers)
        }
        else if (layerClickEvent && deckPoly.markers[0] === layerClickEvent.object) {
            deckPoly = _closePoly(deckPoly, helpers)

        } else {
            helpers[helpers.length - 1] = latLng;
            helpers.push(latLng);
            deckPoly.recalc(helpers);
        }
    } else {
        //deckPoly = new DeckPoly([[lat, lng], [lat, lng]])
    }
    return deckPoly;
}

export function onPolyDrawMouseMove(deckPoly, latLng) {
    // if (drawMarkers.length === 0) {
    //     return;
    // }
    //drawMarkers.push(getDrawIco('BLUE_CIRCLE', lat, lng));
    if (deckPoly) {
        const helpers = deckPoly.helpers;
        helpers[helpers.length-1] = latLng;
        deckPoly.recalc(helpers);

        //hacking markers display on draw (remove last if more than 1, as autoclose immidiately happen)

        //console.log('poly markers in',editablePrimitive.markers);
        //#*($. splice sucks, whatever...
        let newArr = [];
        for (let i = 0; i < deckPoly.markers.length - 1; i++) {
            newArr.push(deckPoly.markers[i]);
        }
        deckPoly.markers = newArr;
    }
    return deckPoly;

}
