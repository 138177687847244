//extending original PolygonLayer (again)
//to support new floor/ceiling (Elevation removed)

//slightly slower (probably) than original,
//since we need to calculate BOTH min and max.

import {CompositeLayer} from '@deck.gl/core';
import SolidPolygonLayer from './solid-polygon-layer62/solid-polygon-layer';
import {PathLayer} from '@deck.gl/layers';
import * as Polygon from './solid-polygon-layer62/polygon';

const defaultLineColor = [0, 0, 0, 255];
const defaultFillColor = [0, 0, 0, 255];

const defaultProps = {
    stroked: true,
    filled: true,
    extruded: false,
    elevationScale: 1,
    wireframe: false,

    lineWidthScale: 1,
    lineWidthMinPixels: 0,
    lineWidthMaxPixels: Number.MAX_SAFE_INTEGER,
    lineJointRounded: false,
    lineMiterLimit: 4,
    lineDashJustified: false,
    fp64: false,

    getPolygon: f => f.polygon,
    // Polygon fill color
    getFillColor: defaultFillColor,
    // Point, line and polygon outline color
    getLineColor: defaultLineColor,
    // Line and polygon outline accessors
    getLineWidth: 1,
    // Line dash array accessor
    getLineDashArray: null,

    // Polygon extrusion accessor
    // getElevation: 1000,

    // Optional settings for 'lighting' shader module
    lightSettings: {},

    // Polygon start extrusion accessor
    getFloor: 0,
    // Polygon end extrusion accessor
    getCeiling: 1000,

};

export default class PolygonLayerExtended62 extends CompositeLayer {
    initializeState() {
        this.state = {
            paths: []
        };
    }

    updateState({oldProps, props, changeFlags}) {
        const geometryChanged =
            changeFlags.dataChanged ||
            (changeFlags.updateTriggersChanged &&
                (changeFlags.updateTriggersChanged.all || changeFlags.updateTriggersChanged.getPolygon));

        if (geometryChanged) {
            const {data, getPolygon} = this.props;
            this.state.paths = [];
            data.forEach(object => {
                const complexPolygon = Polygon.normalize(getPolygon(object));
                complexPolygon.forEach(polygon =>
                    this.state.paths.push({
                        path: polygon,
                        object
                    })
                );
            });
        }
    }

    getPickingInfo({info}) {
        return Object.assign(info, {
            // override object with picked data
            object: (info.object && info.object.object) || info.object
        });
    }

    _getAccessor(accessor) {
        if (typeof accessor === 'function') {
            return x => accessor(x.object);
        }
        return accessor;
    }

    /* eslint-disable complexity */
    renderLayers() {
        // Layer composition props
        const {data, stroked, filled, extruded, wireframe, elevationScale, transitions} = this.props;

        // Rendering props underlying layer
        const {
            lineWidthScale,
            lineWidthMinPixels,
            lineWidthMaxPixels,
            lineJointRounded,
            lineMiterLimit,
            lineDashJustified,
            fp64
        } = this.props;

        // Accessor props for underlying layers
        const {
            getFillColor,
            getLineColor,
            getLineWidth,
            getLineDashArray,
            //getElevation,
            getFloor,
            getCeiling,
            getPolygon,
            updateTriggers,
            lightSettings
        } = this.props;

        const {paths} = this.state;

        const hasData = data && data.length > 0;

        // Filled Polygon Layer
        const polygonLayer =
            hasData &&
            new SolidPolygonLayer(
                this.getSubLayerProps({
                    id: 'fill',
                    updateTriggers: {
                        //getElevation: updateTriggers.getElevation,
                        getFloor: updateTriggers.getFloor,
                        getCeiling: updateTriggers.getCeiling,
                        getFillColor: updateTriggers.getFillColor,
                        getLineColor: updateTriggers.getLineColor
                    }
                }),
                {
                    data,
                    extruded,
                    elevationScale,

                    fp64,
                    filled,
                    wireframe,

                    getPolygon,
                    //getElevation,
                    getFloor,
                    getCeiling,
                    getFillColor,
                    getLineColor,

                    lightSettings,
                    transitions
                }
            );

        // Polygon line layer
        const polygonLineLayer =
            !extruded &&
            stroked &&
            hasData &&
            new PathLayer(
                this.getSubLayerProps({
                    id: 'stroke',
                    updateTriggers: {
                        getWidth: updateTriggers.getLineWidth,
                        getColor: updateTriggers.getLineColor,
                        getDashArray: updateTriggers.getLineDashArray
                    }
                }),
                {
                    data: paths,

                    fp64,
                    widthScale: lineWidthScale,
                    widthMinPixels: lineWidthMinPixels,
                    widthMaxPixels: lineWidthMaxPixels,
                    rounded: lineJointRounded,
                    miterLimit: lineMiterLimit,
                    dashJustified: lineDashJustified,

                    transitions: transitions && {
                        getWidth: transitions.getLineWidth,
                        getColor: transitions.getLineColor,
                        getPath: transitions.getPolygon
                    },

                    getPath: x => x.path,
                    getColor: this._getAccessor(getLineColor),
                    getWidth: this._getAccessor(getLineWidth),
                    getDashArray: this._getAccessor(getLineDashArray)
                }
            );

        return [
            // If not extruded: flat fill layer is drawn below outlines
            !extruded && polygonLayer,
            polygonLineLayer,
            // If extruded: draw fill layer last for correct blending behavior
            extruded && polygonLayer
        ];
    }
    /* eslint-enable complexity */
}

PolygonLayerExtended62.layerName = 'PolygonLayerExtended';
PolygonLayerExtended62.defaultProps = defaultProps;
