
// Override of original BasicListActions
// (btw - NOT ACTIONS as redux actions.. fixme rename)

// see -> https://github.com/marmelab/react-admin/docs/List.md#actions

// src:
// https://github.com/marmelab/react-admin//packages/ra-ui-materialui/src/list/ListActions.js


// removing export in default actions,
// bulks are removed per resource
// refresh was moved to appbar by react-admin
// adding custom archive/unarchive link support

// last sync -> react-admin@2.4.0

import React, { cloneElement } from 'react';
import PropTypes from 'prop-types';
import onlyUpdateForKeys from 'recompose/onlyUpdateForKeys';
import { sanitizeListRestProps } from 'ra-core';
import Storage from "@material-ui/icons/Storage";
//import Unarchive from "@material-ui/icons/Unarchive";

import ArchiveButton from './MyArchiveLinkButton';

import {
    CreateButton,
    //ExportButton,
    //RefreshButton,
    CardActions,
    Button,
    Link
} from 'react-admin';
import {IconButton} from "@material-ui/core";
import {Unarchive} from "@material-ui/icons";
import MyCreateButton from "./MyCreateButton";

const Actions = ({
                     bulkActions,
                     currentSort,
                     className,
                     resource,
                     filters,
                     displayedFilters,
                     exporter,
                     filterValues,
                     hasCreate,
                     basePath,
                     selectedIds,
                     onUnselectItems,
                     showFilter,

                     archiveLink,
                     archiveLabel,
                     createButtonProps,
                     children,
                     ...rest
                 }) => (
    <CardActions className={className} {...sanitizeListRestProps(rest)}>
        {bulkActions &&
        cloneElement(bulkActions, {
            basePath,
            filterValues,
            resource,
            selectedIds,
            onUnselectItems,
        })}
        {children}
        {filters &&
        cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
        })}
        {hasCreate && <MyCreateButton basePath={basePath} {...createButtonProps}/>}
        {archiveLink && <ArchiveButton basePath={archiveLink}
                                       label={archiveLabel} />
        }

        {/*<ExportButton*/}
            {/*resource={resource}*/}
            {/*sort={currentSort}*/}
            {/*filter={filterValues}*/}
            {/*exporter={exporter}*/}
        {/*/>*/}

    </CardActions>
);

Actions.propTypes = {
    bulkActions: PropTypes.oneOfType([PropTypes.element, PropTypes.bool]),
    basePath: PropTypes.string,
    className: PropTypes.string,
    currentSort: PropTypes.object,
    displayedFilters: PropTypes.object,
    exporter: PropTypes.func,
    filters: PropTypes.element,
    filterValues: PropTypes.object,
    hasCreate: PropTypes.bool,
    resource: PropTypes.string,
    onUnselectItems: PropTypes.func.isRequired,
    selectedIds: PropTypes.arrayOf(PropTypes.any),
    showFilter: PropTypes.func,
};

Actions.defaultProps = {
    selectedIds: [],
};

export default onlyUpdateForKeys([
    'resource',
    'filters',
    'displayedFilters',
    'filterValues',
    'selectedIds',
])(Actions);

