
import {
    SET_DRAW_MODE,
    SET_CURRENT_PRIMITIVE,
    SET_ALL_EDITABLE_PRIMITIVES,
    CLEAR_ALL_PRIMITIVES,
    ADD_NEW_PRIMITIVE,
    REMOVE_PRIMITIVE} from "../constants/DrawEditorConstants";
import PubSub from "pubsub-js";
import {PubSubSignals} from "../constants/PubSubSignals";
import {DRAW_MODES, PRIMITIVES} from "../../gl_map/glSetups/deckDraw/DrawEditorEnums";
import {unixTimeNow} from "../oldLogic/TimeConverters";


const initialState = {
    drawMode:DRAW_MODES.NO_DRAW,
    currentPrimitive:null,
    editablePrimitives:[]
};


//hmm, some state logic is also set in DeckGLDrawToolbar.jsx
function pureSetDrawMode(previousState, payload) {
    const mode = payload;
    const nextState = {};
    console.warn('pureSetDrawMode', mode);
    if (mode !== DRAW_MODES.EDIT) {
        console.log('mulit phase1 not removing all previous!only clears current');
        nextState.currentPrimitive = null;
    } else {
        console.log('edit mode here');
        const currentPrimitive = previousState.currentPrimitive;
        console.log(previousState.editablePrimitives);
        console.log(currentPrimitive);
        //hmm, showing midpoint edits here...
        if (currentPrimitive && currentPrimitive.primitiveType === PRIMITIVES.POLYGON) {
            currentPrimitive.recalc(currentPrimitive.helpers, true);
            nextState.currentPrimitive = currentPrimitive;

        }
    }
    console.log('setting draw mode', mode);
    return { ...previousState, ...nextState, drawMode:mode, storeTimestamp:unixTimeNow() }
}

//was used in old logic, may be useful
function _validatePrimitiveType(primitive){
    switch (primitive.primitiveType) {
        case PRIMITIVES.CAPSULE:
        case PRIMITIVES.CIRCLE:
        case PRIMITIVES.PIPE:
        case PRIMITIVES.POLYGON:
            break;
        default:
            console.error('primitive not supported', primitive);
            return;
    }
}

const drawEditorReducer = (previousState = initialState, { type, payload }) => {

    switch (type) {
        case SET_DRAW_MODE:
            return pureSetDrawMode(previousState, payload);

        case SET_CURRENT_PRIMITIVE:
            return { ...previousState, drawMode: DRAW_MODES.EDIT, currentPrimitive:payload, storeTimestamp:unixTimeNow() };

        case SET_ALL_EDITABLE_PRIMITIVES: { //aka init editor?
            PubSub.publish(PubSubSignals.CLEAR_DRAW_EDITOR_DIRTY);

            const currentPrimitive = (payload.length > 0) ? payload[0] : null;
            const editablePrimitives = (payload.length > 0) ? [...payload] : [];
            return {drawMode: DRAW_MODES.EDIT, editablePrimitives, currentPrimitive, storeTimestamp:unixTimeNow()};
        }
        case CLEAR_ALL_PRIMITIVES: //aka clear editor?
            //damn, need to force new array
            return { ...initialState, editablePrimitives:[], storeTimestamp:unixTimeNow() };
        case ADD_NEW_PRIMITIVE: {
            const editablePrimitives = previousState.editablePrimitives;
            const currentPrimitive = payload;
            editablePrimitives.push(currentPrimitive);
            return {...previousState, editablePrimitives, currentPrimitive, storeTimestamp:unixTimeNow()};
        }
        case REMOVE_PRIMITIVE: {
            // sprint fixme some better dirty setup needed

            PubSub.publish(PubSubSignals.SET_DRAW_EDITOR_DIRTY);

            const editablePrimitives = previousState.editablePrimitives.filter(el => el !== payload);
            return {...previousState, editablePrimitives, currentPrimitive: null, storeTimestamp:unixTimeNow()};
        }
        default:
            return previousState;
    }
};

export default drawEditorReducer;
