//special STATIC data set before launching redux (at least now)
//i wanted to have access in store for configs, as it was easier to connect everything
//but stupid me, i forgot that store calls should return true previous state...
//so fixing here...

import {STORE_LOOPBACK_VERSION, RODO_OPENED} from "../constants/MyAppExtrasConstants";

let initialState ;

//have to be called once BEFORE running redux.
export function setAppExtrasInitialState(appExtras) {
    initialState = {...appExtras, ...{isRodoOpened: false}};
}

const appExtrasReducer = (previousState = initialState, { type, payload }) => {

    switch (type) {
        case STORE_LOOPBACK_VERSION:
            return {...previousState, loopbackVersion:payload };
        case RODO_OPENED:
            return {...previousState, ...{isRodoOpened: payload.isRodoOpened}};
        default:
            return previousState;
    }

};

export default appExtrasReducer;
