import ModuleError from './ModuleError'
import MsgProcessor from './MsgProcessor';

const t = MsgProcessor.t;
//console.log('msgProcessor', MsgProcessor);

export default class BaseModule {
    constructor({debug = false, logger = null}) {
        this._moduleId = 'UnknownModule'; //should be overriden by constructors
        if (debug && logger) {
            //errors should be processed as caught exceptions
            this.warn = logger.warn;
            this.log = logger.log;
            this.debug = logger.debug;

        } else if (debug) {
            this.warn = console.warn;
            this.log = console.log;
            this.debug = console.debug;
        } else {
            this.warn = () => {};
            this.log = () => {};
            this.debug = () => {};
        }
    }

    //hmm should be translatable?
    //passing extra args.
    throwErr(errorObject, ...sprintfArgs) {
        const {code, msg, moduleId} = errorObject;
        const module = (moduleId)?moduleId:this._moduleId;
        throw new ModuleError({
            msg: t(msg, ...sprintfArgs),
            code,
            module,
            data:{msgFormat:msg, sprintfArgs}
        })
    }
}
