import React, { cloneElement } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import MuiAppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import HelpIcon from '@material-ui/icons/Help';
import withWidth, {isWidthDown} from '@material-ui/core/withWidth';
import compose from 'recompose/compose';
import { toggleSidebar as toggleSidebarAction } from 'ra-core';
import {Link} from 'react-router-dom'

import {UserMenu} from 'ra-ui-materialui';
import {Headroom} from 'ra-ui-materialui';
import {MyUserMenu} from "./MyUserMenu";

import MyLangSelector from './MyLangSelector';
import MyLoadingIndicator from "./MyLoadingIndicator";
import {TooltipProxy as Tooltip} from "./TooltipProxy";


const styles = theme => ({
    toolbar: {
        paddingRight: 24,
    },
    menuButton: {
        marginLeft: '0.5em',
        marginRight: '0.5em',
    },
    menuButtonIconClosed: {
        transition: theme.transitions.create(['transform'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        transform: 'rotate(0deg)',
    },
    menuButtonIconOpen: {
        transition: theme.transitions.create(['transform'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        transform: 'rotate(180deg)',
    },
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
});

const AppBar = ({
                    children,
                    classes,
                    className,
                    logout,
                    open,
                    title,
                    toggleSidebar,
                    userMenu,
                    width,
                    locale,
                    ...rest
                }) => {

    return (
        <Headroom>
            <MuiAppBar
                className={className}
                color="secondary"
                position="static"
                {...rest}
            >
                <Toolbar
                    disableGutters
                    variant={width === 'xs' ? 'regular' : 'dense'}
                    className={classes.toolbar}
                >
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={toggleSidebar}
                        className={classNames(classes.menuButton)}
                        disabled={isWidthDown('sm', width)}
                    >
                        <MenuIcon
                            classes={{
                                root: open
                                    ? classes.menuButtonIconOpen
                                    : classes.menuButtonIconClosed,
                            }}
                        />
                    </IconButton>
                    <Typography
                        variant="title"
                        color="inherit"
                        className={classes.title}
                        id="react-admin-title"
                    />
                    <MyLoadingIndicator/>
                    <MyLangSelector />
                    <Tooltip content={'myroot.appBar.help'}>
                        <IconButton
                            color="inherit"
                            aria-label="help"
                            className={classNames(classes.menuButton)}
                            to={`/docs/help`}
                            component={Link}
                        >
                            <HelpIcon/>
                        </IconButton>
                    </Tooltip>
                    <div id="profile-icon">{cloneElement(<MyUserMenu/>, { logout })}</div>
                </Toolbar>
            </MuiAppBar>
        </Headroom>
    );
};

AppBar.propTypes = {
    children: PropTypes.node,
    classes: PropTypes.object,
    className: PropTypes.string,
    logout: PropTypes.element,
    open: PropTypes.bool,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
        .isRequired,
    toggleSidebar: PropTypes.func.isRequired,
    userMenu: PropTypes.node,
    width: PropTypes.string,
};

AppBar.defaultProps = {
    userMenu: <UserMenu />,
};

const enhance = compose(
    connect(
        state => ({
            locale: state.i18n.locale, // force redraw on locale change
        }),
        {
            toggleSidebar: toggleSidebarAction,
        }
    ),
    withStyles(styles),
    withWidth()
);

export default enhance(AppBar);
