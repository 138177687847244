import React from "react";
import {ReferenceField} from "react-admin";
import { withStyles } from '@material-ui/core/styles';



const styles = theme => ({
    link: {
        color: theme.palette.colorCircle.link,
        textDecoration: "underline"
    }
});

export const ReferenceFieldDecorator = withStyles(styles)(ReferenceField);



