
//created for early tests
/*
export function setPositionFromLiveADSBExchange() {
    let newArr = [aircraftsGeoJSON.features[0]];
    newArr[0].geometry.coordinates[0] += speed * Math.sin(direction) / 500;
    newArr[0].geometry.coordinates[1] += speed * Math.cos(direction) / 500;
    newArr[0].properties.icon_opacity = Math.random();

    direction = (direction + 10) % 360 * (180 / Math.PI);
    //map.setLayoutProperty('drone', 'icon-rotate', direction * (180 / Math.PI));

    //real code
    let aircrafts = getADSBExchangeFlights();

    //console.log('aircrafts', aircrafts);

    for (let i = 0; i<aircrafts.length; i++) {
        //let flight = records[records.length - 1];
        let flight = aircrafts[i];
        //console.log(flight);
        let point = {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [flight.Long, flight.Lat]
            },
            "properties": {
                "title": `${flight.Icao}/${flight.Call} @${flight.Alt}ft`,
                "icon_type": ac2ico(flight.Species),
                "icon_rotate": flight.Trak,
                "icon_opacity": 1,
                "icao" : flight.Icao
            }
        };
        newArr.push(point);
    }

    aircraftsGeoJSON.features = newArr;

}
*/
// export let testPos = [ 21.03,52.23,5000];
import {functionalColors} from "./commonInits";

let direction = 0, manual = false, speed = 1;
/*
export function makeLayerFromADSBExchangeTicks() {
    let newArr = [adsbXChangeTicksGeoJSON.features[0]];
    newArr[0].geometry.coordinates[0] = aircraftsGeoJSON.features[0].geometry.coordinates[0];
    newArr[0].geometry.coordinates[1] = aircraftsGeoJSON.features[0].geometry.coordinates[1];

    //real code
    let ticks = RtdmSources.getXChangeTicks();

    //console.log('ticks', ticks[0]);

    for (let i = 0; i<ticks.length; i++) {
        //let flight = records[records.length - 1];
        let tick = ticks[i];
        //console.log(flight);
        let point = {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [tick.Long, tick.Lat]
            },
            "properties": {
                //"title": `${tick.Icao}/${tick.Call} @${flight.Alt}ft`,
            }
        };
        newArr.push(point);
    }

    adsbXChangeTicksGeoJSON.features = newArr;

}

export function makeLayerFromADSBCreotechTicks() {
    let newArr = [];

    //real code
    let ticks = RtdmSources.getCreotechTicks();
    // if (ticks[0])
    //     console.log('ticks', ticks[0]);

    for (let i = 0; i<ticks.length; i++) {
        //let flight = records[records.length - 1];
        let tick = ticks[i];
        //console.log(flight);
        let point = {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [tick.lon, tick.lat]
            },
            "properties": {
                //"title": `${tick.Icao}/${tick.Call} @${flight.Alt}ft`,
            }
        };
        newArr.push(point);
    }

    adsbCreotechGeoJSON.features = newArr;

}

export function makeLayerFromAlertsV1() {
    let newArr = []; //[alertsV1GeoJSON.features[0]];

    //real code
    let alerts = AlertsList.getAlerts();

    for (let i = 0; i<alerts.length; i++) {
        //fixme -> rebuild alert during request?
        let alert = {
            type: 'feature',
            geometry: alerts[i].geojson,
            properties: {}
        };
        newArr.push(alert);
    }

    alertsV1GeoJSON.features = newArr;

}
*/

let testIco = [
    {position: [  21.03, 52.23, 0], icon: 'myPlane', size: 64, angle:45},
];


let testPath = [{
    path: [

        [
            20.74745264673004,
            52.33735648470913,
            0
        ],
        [
            20.751572519776914,
            52.29454188241123,
            400

        ],
        [
            21.14296045923004,
            52.265135342776965,
            800

        ],
        [
            21.149826914308164,
            52.29874122380509,
            1000

        ],
        [
            20.74745264673004,
            52.33735648470913,
            1000
        ]
    ],
    widthScale: 1,
    color: functionalColors.newPrimitive
}];

let testPoly = [{

    polygon:

         [
                [
                    [
                        20.74745264673004,
                        52.33735648470913
                    ],
                    [
                        20.751572519776914,
                        52.29454188241123
                    ],
                    [
                        21.14296045923004,
                        52.265135342776965
                    ],
                    [
                        21.149826914308164,
                        52.29874122380509
                    ],
                    [
                        20.74745264673004,
                        52.33735648470913
                    ]
                ]

         ],
    fillColor: functionalColors.newPrimitive,
    elevation: 10000,
    max: 5000,
    min:2500,

}];

let testPoints = [
    {position: [21.15, 52.23], normal: [-1, 0, 0], color: [0, 0, 0]},
];

let testCursor = [
    {position: [  21.1, 52.2, 1000], icon: 'BLUE_SQUARE', size: 128},
];


export function getTestCursor() {
    return testCursor;
}

export function getTestPoly() {
    return testPoly;
}

export function getTestIco() {
    return testIco;
}

export function getTestPath() {
    return testPath;
}

export function getTestPoints() {
    return testPoints;
}

// export function getTestPathGeojson() {
//     return test3dPathGeojson;
// }

function testPosUpdate() {
    //let newArr = [aircraftsGeoJSON.features[0]];
    testIco[0].position[0] += speed * Math.sin(direction) / 500;
    testIco[0].position[1] += speed * Math.cos(direction) / 500;
    direction = (direction + 10) % 360 * (180 / Math.PI);

    testPath[0].path[0][0] = testIco[0].position[0];
    testPath[0].path[0][1] = testIco[0].position[1];
    testPath[0].color = functionalColors.newPrimitive;
    //testPoly[0].polygon[0][0][0] = testIco[0].position[0];
    //testPoly[0].polygon[0][0][1] = testIco[0].position[1];

    testPoly[0].fillColor = functionalColors.newPrimitive;

    console.log(testPath[0].color)

}

export function processRedraws() {
    testPosUpdate();
}

export default {
    processRedraws,
    getTestIco,
    getTestPath,
    getTestPoly,
    getTestPoints
}
