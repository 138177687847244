// https://hackernoon.com/using-a-react-16-portal-to-do-something-cool-2a2d627b0202

import React from 'react'
import * as ReactDOM from "react-dom";

export default class PortalWindow extends React.PureComponent {


    static defaultProps = {
        onClose: ()=>{},
        onResize: ()=>{},
        title: 'Title',
        // STEP 1: create a container <div>
        containerEl: document.createElement('div')
    };

    constructor(props) {
        super(props);

        this.externalWindow = null;
    }

    render() {
        // STEP 2: append props.children to the container <div> that isn't mounted anywhere yet
        return ReactDOM.createPortal(this.props.children, this.props.containerEl);
    }

    componentDidMount() {
        // STEP 3: open a new browser window and store a reference to it
        this.externalWindow = window.open('', '', this.props.windowProps);



        // STEP 4: append the container <div> (that has props.children appended to it) to the body of the new window
        this.externalWindow.document.body.appendChild(this.props.containerEl);
        this.externalWindow.document.title = this.props.title;

        copyStyles(document, this.externalWindow.document);
        this.externalWindow.addEventListener('beforeunload', () => {
            this.props.onClose();
        });
        this.externalWindow.addEventListener('resize', () => {
            this.props.onResize({width: this.externalWindow.innerWidth, height: this.externalWindow.innerHeight})
        });
    }


    componentWillUnmount() {
        // STEP 5: This will fire when this.state.showWindowPortal in the parent component becomes false
        // So we tidy up by closing the window
        this.externalWindow.close();
    }
}


function copyStyles(sourceDoc, targetDoc) {
    Array.from(sourceDoc.styleSheets).forEach(styleSheet => {
        try {
            if (styleSheet.cssRules) { // true for inline styles
                const newStyleEl = sourceDoc.createElement('style');

                Array.from(styleSheet.cssRules).forEach(cssRule => {
                    newStyleEl.appendChild(sourceDoc.createTextNode(cssRule.cssText));
                });

                targetDoc.head.appendChild(newStyleEl);
            } else if (styleSheet.href) { // true for stylesheets loaded from a URL
                const newLinkEl = sourceDoc.createElement('link');

                newLinkEl.rel = 'stylesheet';
                newLinkEl.href = styleSheet.href;
                targetDoc.head.appendChild(newLinkEl);
            }
        }
        catch(e) {
            console.log("CSS ERROR", e);
        }

    });
}

