import {endDraw} from "./deckEditor";
import DeckPoly from '../deckPrimitives/DeckPoly';
import {isPosEqual} from "../oldCommon/commonTurf";
import PubSub from "pubsub-js";
import {PubSubSignals} from "../../../LogicV1Redux/constants/PubSubSignals";


//overall ->see deck poly notes


export function onPipeDrawClick(deckPipe, latLng, layerClickEvent) {

    // sprint fixme some better dirty setup needed
    PubSub.publish(PubSubSignals.SET_DRAW_EDITOR_DIRTY);

    if (deckPipe) {
        console.log('pipe click', deckPipe, layerClickEvent);

        //if
        const helpers = deckPipe.helpers;

        const lastSetHelper = helpers[helpers.length - 2];
        const lastMarker = deckPipe.markers[deckPipe.markers.length - 1];
        const checkMarkerEvent =  (layerClickEvent && lastMarker === layerClickEvent.object);
        const checkCloseEnoughLastClick =  (lastSetHelper && isPosEqual(latLng,lastSetHelper));
        if (checkMarkerEvent) {
            console.warn('closing pipe from check event');
            helpers[helpers.length - 1] = lastMarker.position;
            deckPipe.recalc(helpers);
            deckPipe.fix();
            //fixme! hardcoded
            deckPipe.setRadius(50);
            endDraw(deckPipe);

        } else if (checkCloseEnoughLastClick) {
            console.warn('closing pipe from close enough');
            deckPipe.recalc(helpers);
            deckPipe.fix();
            //fixme! hardcoded
            deckPipe.setRadius(50);
            endDraw(deckPipe);

        } else {
            helpers[helpers.length - 1] = latLng;
            helpers.push(latLng);
            deckPipe.recalc(helpers);
        }
    } else {
        //deckPoly = new DeckPoly([[lat, lng], [lat, lng]])
    }

    return deckPipe;
}

export function onPipeDrawMouseMove(deckPipe, latLng) {

    if (deckPipe) {
        const helpers = deckPipe.helpers;
        helpers[helpers.length-1] = latLng;
        deckPipe.recalc(helpers);

        //hacking markers display on draw (remove last if more than 1, as autoclose immidiately happen)

        //console.log('poly markers in',editablePrimitive.markers);
        //#*($. splice sucks, whatever...
        let newArr = [];
        for (let i = 0; i < deckPipe.markers.length - 1; i++) {
            newArr.push(deckPipe.markers[i]);
        }
        deckPipe.markers = newArr;
    }

    return deckPipe;

}
