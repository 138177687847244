import {sprintf} from "./Utils";

//have to support %s syntax
// (i18next seems way to go...)
// for now using sprintf.
export default class MsgProcessor {

    static set(msgProcessor) {
        this.t = msgProcessor;
    }
}

MsgProcessor.t = sprintf;
