import StoreProvider from "../../../dr_ra2/MyStoreProvider";
import {
    addNewPrimitive as addNewPrimitiveAction,
    clearAllPrimitives as clearAllPrimitivesAction,
    removePrimitive as removePrimitiveAction,
    setAllEditablePrimitives as setAllEditablePrimitivesAction,
    setCurrentPrimitive as setCurrentPrimitiveAction,
    setDrawMode as setDrawModeAction
} from "../../../LogicV1Redux/actions/DrawEditorActions";
import {updateDrawLayers} from "./deckEditor";

export function getDrawMode() {
    return StoreProvider.getState().drawEditorData.drawMode;
}

export function setDrawMode(mode) {
    StoreProvider.getStore().dispatch(setDrawModeAction(mode));
}

export function getCurrentPrimitive() {
    return StoreProvider.getState().drawEditorData.currentPrimitive;
}

export function setCurrentPrimitive(editablePrimitive) {
    StoreProvider.getStore().dispatch(setCurrentPrimitiveAction(editablePrimitive));
}

export function getEditablePrimitives() {
    return StoreProvider.getState().drawEditorData.editablePrimitives;
}

export function addEditablePrimitive(editablePrimitive) {
    StoreProvider.getStore().dispatch(addNewPrimitiveAction(editablePrimitive));
}

export function clearAllEditablePrimitives() {
    StoreProvider.getStore().dispatch(clearAllPrimitivesAction());
    updateDrawLayers("_clearAllEditablePrimitives");
}

export function removeEditablePrimitive(editablePrimitive) {
    StoreProvider.getStore().dispatch(removePrimitiveAction(editablePrimitive));
}

export function setEditablePrimitives(newEditablePrimitives) {
    StoreProvider.getStore().dispatch(setAllEditablePrimitivesAction(newEditablePrimitives));

}