import React, {Component} from 'react';
import {
    AutocompleteInput,
    Filter,
    List,
    Responsive,
    SimpleList,
    Edit,
    Create,
    Datagrid,
    ReferenceField,
    TextField,
    EditButton,
    DisabledInput,
    LongTextInput,
    ReferenceInput,
    SelectInput,
    SimpleForm,
    RadioButtonGroupInput,
    SelectArrayInput,
    TextInput,
    SimpleShowLayout,
    Show,
    ShowButton,
    FunctionField,
    NumberInput,
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email,
    choices,
    FormDataConsumer
} from 'react-admin';
import {bindActionCreators} from 'redux'
import {
    DateInput as DatePicker,
    TimeInput as TimePicker,
    DateTimeInput as DateTimePicker
} from 'react-admin-date-inputs';

import {withStyles} from '@material-ui/core/styles';
import { translate } from 'ra-core';
import {connect} from 'react-redux';

import compose from 'recompose/compose';

import Paper from '@material-ui/core/Paper';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import {
    myAuthSagaNewPhoneRq,
    myAuthSagaPhoneCodeVerifyRq,
    myAuthSagaNicknameRq,
    myAuthSagaSaveSelfOperatorRq,
    myAuthSagaSaveSelfGovRq
} from "../../../LogicV2/auth/MyExtraAuthActions";
import {change} from "redux-form";
import SanitizedMuiButton from "../SanitizedMuiButton";
import TranslatableTypography from "../TranslatableTypography";
import {formValueSelector, isDirty, isValid} from 'redux-form';
import classnames from "classnames";
import has from 'lodash.has'
import {InfoText} from "../InfoText";
import {WarningText} from "../WarningText";
import UserNamePanel from "./panels/UserNamePanel";
import EmailPanel from "./panels/EmailPanel";
import PhonePanel from "./panels/PhonePanel";
import AddressPanel from "./panels/AddressPanel";
import CompanyPanel from "./panels/CompanyPanel";
import {ACCOUNT_PHONE_FORM} from "../../logic/settings/AccountConstants";

const CardText = () => {
    return null
};
const styles = theme => ({
    root: {
        margin: "2px",
    },

    block: {
        display: 'block',
    },

    heading: {
        fontSize: theme.typography.pxToRem(15),
        //flexDirection: "row"
        //flexBasis: auto
        //flexBasis: '100%',
        //flexShrink: 0,
    },
    subheading: {
        //flexWrap: "wrap",
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
        //flexDirection: "row"

    },
    phoneCodeDiv: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
    },
    expansionPanel: {
        marginBottom: "0.5rem",
        marginTop: "0",
        opacity: "1",
        transition: 'height 0.5s 0.35s, opacity 1s 0.35s, margin-bottom 0.5s 0.35s',
    },
    expansionPanelNotActive: {
        height: "4.125rem",
    },
    overflowHide: {
        marginBottom: "0rem",
        transition: 'opacity 1s, height 0.5s, margin-bottom 1s',
        opacity: "0",
        height: "0",
        overflow: "hidden",
    },
    disabledText: {
        margin: "0.5rem 0",
        height: "2rem",
        width: "27rem",
        opacity: "1",
        transition: 'height 0.5s 0.55s, width 0.5s 0.55s,opacity 0.5s 0.95s, margin 0.5s 0.55s',
    },
    disabledTextHide: {
        margin: "0 0",
        opacity: "0",
        height: "0",
        width: "0",
        overflow: "hidden",
        transition: 'height 0.5s 0.35s, width 0.5s 0.35s,opacity 1s 0.35s, margin 0.5s 0.35s',
    },
    inline: {
        display: "flex",
        alignItems: "center",
    },
    requiredNonFilled: {
        color: "#000",
        fontWeight: "600"
    }
});

//fixme!

//    handleClose = () => {
//         //this.setState({ open: false });
//         console.log ('manual dispatch hideModal');
//         //this.props.hideModal();
//         this.props.dispatch({type:HIDE_MODAL})
//     };


//TODO move animation to react-trasition-group
class selfOperatorsEditForm extends React.Component {

    state = {
        expanded: false,
        showPhoneCode: false,
    };

    componentDidMount() {
        const {smsCodeRequest, smsCodeConfirmed} = this.props.mySelf;
            if(smsCodeRequest && !smsCodeConfirmed) {
                this.setState({
                    showPhoneCode: true
                })
            }
            else if(smsCodeConfirmed) {
                this.setState({
                    showPhoneCode: false
                })
            }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {smsCodeRequest: smsCodeRequestPrev, smsCodeConfirmed: smsCodeConfirmedPrev} = prevProps.mySelf;
        const {smsCodeRequest, smsCodeConfirmed} = this.props.mySelf;

        if(smsCodeConfirmed !== smsCodeConfirmedPrev || smsCodeRequest !== smsCodeRequestPrev) {
            if(smsCodeRequest && !smsCodeConfirmed) {
                this.setState({
                    showPhoneCode: true
                })
            }
            else if(smsCodeConfirmed) {
                this.setState({
                    showPhoneCode: false
                })
            }
        }
    }

    handleChange = panel => (event, expanded) => {
        this.props.onExpansionChange(expanded ? panel : false);
        this.setState({
            expanded: expanded ? panel : false,
        });
    };

    handleSave = (data) => {
        console.log('handleSave', this.props.myAuthSagaSaveSelfOperatorRqAction , data);
        this.props.myAuthSagaSaveSelfOperatorRqAction(data);
    };

    handlePhoneSave = (data) => {
        this.props.myAuthSagaNewPhoneRqAction(data);
    };

    handlePhoneCodeVerify = (data) => {
        this.props.myAuthSagaPhoneCodeVerifyRqAction(data);
    };

    handleNickNameCreate = (data) => {
        this.props.myAuthSagaNicknameRqAction(data);
    };

    handlePhoneChangeView = () => {
        this.setState({
            showPhoneCode: false
        })
    };

    handlePhoneVerifyView = () => {
        this.setState({
            showPhoneCode: true
        })
    };

    render() {

    const {classes, mySelf, translate, phone, phoneCode, isDirty, isValid} = this.props;
    const {expanded, showPhoneCode} = this.state;

    //if is undefined probably network error occured before first profile view open
    const offline =  mySelf.userState.canEditSelf === undefined;
    const {hasPhone, hasFullName, hasNickname} = mySelf.userState;
    const disabled = offline ? true : !mySelf.userState.canEditSelf;

    console.log("expaned",expanded);
    console.log('selfOperatorsEditForm', mySelf);

    return (

        <div className={classes.root}>

            <UserNamePanel
                handleChange={this.handleChange}
                handleSave={this.handleSave}
                handleNickNameCreate={this.handleNickNameCreate}
                isOperator
                {...{hasFullName, hasNickname, mySelf, expanded, offline, disabled, translate}}
            />

            <EmailPanel
                handleChange={this.handleChange}
                handleSave={this.handleSave}
                {...{mySelf, expanded, offline, disabled, translate}}
            />

            <PhonePanel
                handleChange={this.handleChange}
                handleSave={this.handleSave}
                handlePhoneSave={this.handlePhoneSave}
                handlePhoneCodeVerify={this.handlePhoneCodeVerify}
                handlePhoneChangeView={this.handlePhoneChangeView}
                handlePhoneVerifyView={this.handlePhoneVerifyView}
                {...{
                    mySelf, expanded, offline, disabled, translate, hasPhone,
                    showPhoneCode, phoneCode, isDirty, phone, isValid
                }}
            />

            <AddressPanel
                handleChange={this.handleChange}
                handleSave={this.handleSave}
                {...{mySelf, expanded, offline, disabled, translate}}
            />

            <CompanyPanel
                handleChange={this.handleChange}
                handleSave={this.handleSave}
                {...{mySelf, expanded, offline, disabled, translate}}
            />

        </div>
    );}
}


class selfGovEditForm extends React.Component {

    state = {
        expanded: null,
    };


    handleChange = panel => (event, expanded) => {
        this.props.onExpansionChange(expanded ? panel : false);
        this.setState({
            expanded: expanded ? panel : false,
        });
    };

    handleSave = (data) => {
        this.props.myAuthSagaSaveSelfGovRqAction(data);
    };

    render() {
        const {classes, mySelf, translate, myAuthSagaSaveSelfGovRqAction, myAuthSagaSaveSelfOperatorRqAction} = this.props;
        const {expanded} = this.state;

        console.log('selfGovEditForm', mySelf);

        const __address = translate('myroot.address.address');
        const defaultNonRequired = [minLength(2), maxLength(64)];

        return (

            <div className={classes.root}>

                <UserNamePanel
                    handleChange={this.handleChange}
                    handleSave={this.handleSave}
                    hasFullName={mySelf.userState.hasFullName}
                    isGov
                    {...{mySelf, expanded, translate}}
                />


                <EmailPanel
                    handleChange={this.handleChange}
                    handleSave={this.handleSave}
                    {...{mySelf, expanded, translate}}
                />

                <PhonePanel
                    handleChange={this.handleChange}
                    handleSave={this.handleSave}
                    hasPhone={mySelf.userState.hasPhone}
                    isGov
                    {...{
                        mySelf, expanded, translate
                    }}
                />

                <CompanyPanel
                    handleChange={this.handleChange}
                    handleSave={this.handleSave}
                    isGov
                    disabled
                    {...{mySelf, expanded, translate}}
                />
            </div>
        );
    }
}


const mapStateToProps = state => {
    return {
        mySelf : state.mySelf,
    }
};


// const mapDispatchToProps = (dispatch) => {
//     return bindActionCreators({change}, dispatch);
// };

const mapDispatchToProps = dispatch =>
(
    {
        myAuthSagaSaveSelfOperatorRqAction : (e) => {
            console.log('dispatchin myAuthSagaSaveSelfOperatorRq', e);
            dispatch(myAuthSagaSaveSelfOperatorRq(e))
        },
        myAuthSagaSaveSelfGovRqAction : (e) => {
            console.log('dispatchin myAuthSagaSaveSelfGovRq', e);
            dispatch(myAuthSagaSaveSelfGovRq(e))
        },
        myAuthSagaNewPhoneRqAction : (e) => {
            console.log('dispatchin myAuthSagaNewPhoneRqAction', e);
            dispatch(myAuthSagaNewPhoneRq(e))
        },
        myAuthSagaPhoneCodeVerifyRqAction : (e) => {
            console.log('dispatchin myAuthSagaPhoneCodeVerifyRqActio', e);
            dispatch(myAuthSagaPhoneCodeVerifyRq(e))
        },
        myAuthSagaNicknameRqAction : (e) => {
            console.log('dispatchin myAuthSagaNicknameRqAction', e);
            dispatch(myAuthSagaNicknameRq(e))
        },
        change: bindActionCreators(change, dispatch),
    }
);

const enhance = compose(
    translate,
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps),
);


export const SelfOperatorsEditForm = enhance(selfOperatorsEditForm);

export const SelfGovsEditForm = enhance(selfGovEditForm);


export const ZSelfOperatorsEditForm = ({mySelf, translate, ...props}) => {

// const __usernameDscr = translate('myroot.dashboard.accountForm.usernameDscr');
// const __email = translate('myroot.dashboard.accountForm.email');
// const __emailDscr = translate('myroot.dashboard.accountForm.emailDscr');
//
// const __phone = translate('myroot.dashboard.accountForm.phone');
// const __phoneDscr = translate('myroot.dashboard.accountForm.phoneDscr');
//
// const __dob = translate('myroot.dashboard.accountForm.dob');
// const __firstName = translate('myroot.dashboard.accountForm.firstName');
// const __lastName = translate('myroot.dashboard.accountForm.lastName');
//
// const __personalData = translate('myroot.dashboard.accountForm.personalData');
// const __personalDataDscr = translate('myroot.dashboard.accountForm.personalDataDscr');
//
// const __country = translate('myroot.address.country');
// const __town = translate('myroot.address.town');
// const __zipcode = translate('myroot.address.zipcode');
//
// const __addressData = translate('myroot.address.addressData');
// const __addressDataDscr = translate('myroot.address.addressDscr');
//
// const __required = translate('myroot.validation.required');
// const __optional = translate('myroot.validation.optional');
//
// const __smsCode = translate('myroot.dashboard.accountForm.smsCode');
// const __smsCodeDscr = translate('myroot.dashboard.accountForm.smsCodeDscr');
//
// const __cancel = translate('myroot.dialog.cancel');

    const __address = translate('myroot.address.address');

    const defaultRequired = [required(), minLength(2), maxLength(64)];
    const defaultNonRequired = [minLength(2), maxLength(64)];

    const hideStyle = {display: "none"};  //hack for single input fields not supporting sumbitOnEnter

    const showPhoneCode = false;

    return (
        <div>



            <Card>
                <CardHeader
                    title={'myroot.dashboard.accountForm.phone'}
                    subtitle={'myroot.validation.required'}
                    actAsExpander={true}
                    showExpandableButton={true}
                />

                <CardText expandable={true}>
                    {{
                        true: (
                            <div>
                                <h4>{translate('myroot.dashboard.accountForm.smsCodeDscr')}</h4>

                                <SimpleForm record={{
                                    phone: mySelf.phone,
                                }}
                                            save={this.handlePhoneVerify}
                                            redirect={false}
                                >
                                    <DisabledInput source="phone"/>
                                    <TextInput label={'myroot.dashboard.accountForm.smsCode'}
                                               source="smsCode"
                                               validate={defaultNonRequired}/>

                                    {/*<SaveButton type={"submit"} redirect={false}/>*/}

                                </SimpleForm>
                            </div>
                        ),
                        false: (
                            <div>
                                <h4>{translate('myroot.dashboard.accountForm.phoneDscr')}</h4>

                                <SimpleForm record={{
                                    phone: mySelf.phone,
                                    phoneVerified: mySelf.phoneVerified,
                                }}
                                            save={this.handlePhoneSave}
                                            redirect={false}
                                >
                                    <TextInput source="phone" validate={defaultNonRequired}/>
                                    <DisabledInput source="phoneVerified"/>

                                    {/*<SaveButton type={"submit"} redirect={false}/>*/}

                                </SimpleForm>
                            </div>
                        ),
                    }[showPhoneCode]}

                </CardText>
            </Card>
            <Card>
                <CardHeader
                    title="unused/disabled/test"
                    subtitle="test"
                    actAsExpander={true}
                    showExpandableButton={true}
                />

                <CardText expandable={true}>
                    <SimpleForm record={mySelf}
                                redirect={false}

                    >
                        <DisabledInput source="username"/>

                        <DisabledInput source="realm"/>
                        <DisabledInput source="created"/>
                        <DisabledInput source="uid"/>
                        <DisabledInput source="lang"/>
                        <DisabledInput source="nickName"/>

                    </SimpleForm>
                </CardText>
            </Card>
        </div>
    )
};
