import React from 'react';
import Button from '@material-ui/core/Button';
import ActionCheck from '@material-ui/icons/CheckCircle';
import classnames from 'classnames';
import MyInputDialog from "../../MyInputDialog";


// TODO refactor - change name - is not temp any more
const confirmActions = ({
                            confirmMine,
                            confirmFull,
                            confirmColor,
                            onConfirmMine,
                            onConfirmFull,
                            allZonesOwned,
                            confirmMineDisabled,
                            confirmFullDisabled,
                            canOverrideStatus
                        }) => ({
                                  inputText, classes, translate
                        }) =>
    <>
    {(allZonesOwned || canOverrideStatus) ?
        <Button
            onClick={() => {
                onConfirmFull(inputText)
            }}
            className={classnames('ra-confirm', {
                [classes.confirmWarning]: confirmColor === 'warning',
                [classes.confirmPrimary]: confirmColor === 'primary',
            })}
            disabled={confirmFullDisabled}
        >
            <ActionCheck className={classes.iconPaddingStyle}/>
            {!!(confirmFull) ? translate(confirmFull) : null}

        </Button>
        : null
    }
    {(!allZonesOwned) ?
        <Button
            onClick={() => {
                onConfirmMine(inputText)
            }}
            className={classnames('ra-confirm', {
                [classes.confirmWarning]: confirmColor === 'primary',
                [classes.confirmPrimary]: confirmColor === 'primary',
            })}
            disabled={confirmMineDisabled}
        >
            <ActionCheck className={classes.iconPaddingStyle}/>
            {!!(confirmMine) ? translate(confirmMine) : null}

        </Button>
        : null
    }
</>;


export default ({
                  confirmMine,
                  confirmFull,
                  confirmColor,
                  confirmMineDisabled,
                  confirmFullDisabled,
                  onConfirmMine,
                  onConfirmFull,
                  allZonesOwned,
                  canOverrideStatus,
                  ...props,
              }) => (
        <MyInputDialog
            confirmActions={confirmActions({
                confirmMine,
                confirmFull,
                confirmColor,
                confirmMineDisabled,
                confirmFullDisabled,
                onConfirmMine,
                onConfirmFull,
                allZonesOwned,
                canOverrideStatus
            })}
            {...props}
        />
    );
