import React from 'react';
import PropTypes from 'prop-types';
import { Field, propTypes, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
//import CardText from '@material-ui/core/CardText';

import {email, required } from 'react-admin';
import Typography from '@material-ui/core/Typography';

import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import { translate } from 'ra-core';
import { Link } from 'react-router-dom';

import {loginPageStyles as styles, renderInput} from "../../utils/tempCommon";
import {myAuthSagaResetPassRq} from "../../../LogicV2/auth/MyExtraAuthActions";
import StoreProvider from "../../MyStoreProvider";
import {showNotification} from "react-admin";

const resetPass = (auth, dispatch, { redirectTo }) => {
    console.log('resetPassAction', auth, dispatch, redirectTo);
    return dispatch(myAuthSagaResetPassRq(auth, redirectTo));
};

const validateEmail = [required()] //, email()];

const ResetPassRqForm = ({ classes, isLoading, handleSubmit, translate, extras }) => (
    <form onSubmit={handleSubmit(resetPass)}>
        <div className={classes.form}>
            <div className={classes.input}>
                <Field
                    name="email"
                    component={renderInput}
                    label={translate('myroot.auth.email')}
                    disabled={isLoading}
                    validate={validateEmail}
                />
            </div>
        </div>
        <CardActions>
            <Button
                variant="raised"
                type="submit"
                color="primary"
                disabled={isLoading}
                className={classes.button}
            >
                {isLoading && <CircularProgress size={25} thickness={2} />}
                {translate('myroot.auth.resetPassRq')}
            </Button>
        </CardActions>
        <CardActions>
            <Button
                variant="outlined"
                color="primary"
                className={classes.button}
                component={Link}
                to="/login"
            >
                {translate('myroot.auth.signingIn')}
            </Button>
            {(false) //:(extras.appMode === 'OPERATOR') //disabling for beta
            ?
            <Button
                variant="outlined"
                color="primary"
                className={classes.button}
                component={Link}
                to="/register"
            >
                {translate('myroot.auth.register')}
            </Button>
            :
            null}
        </CardActions>

    </form>
);
ResetPassRqForm.propTypes = {
    ...propTypes,
    classes: PropTypes.object,
    redirectTo: PropTypes.string,
};

const mapStateToProps = state => ({ isLoading: state.admin.loading > 0 });


//form after connect ? works, but... hmmm..
const enhance = compose(
    withStyles(styles),
    translate,
    connect(mapStateToProps),

    reduxForm({
        form: 'reset-pass-rq-form',
        // replaced with ra validation
        // validate: (values, props) => {
        //     const errors = {};
        //     const { translate } = props;
        //     if (!values.email)
        //         errors.email = translate('ra.validation.required');
        //     return errors;
        // },
        onSubmitFail: ({...e}) => {
            console.log('==============submit failed', e);
            //just test. but works.
            const store = StoreProvider.getStore();
            store.dispatch(showNotification('ra.message.invalid_form', 'warning'));
        },
        // replaced with saga, otherwise should be dispatched locally (probably)
        // onSubmitSuccess: ({...e}) => {
        //     console.log('===============submit success', e);
        // }
    }),


);

export default enhance(ResetPassRqForm);
