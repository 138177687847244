
import React from "react";
import MissionEditorMapUI from '../../../../gl_map/Components/Maps/MissionEditorMapUI';
import MissionConflictsSwitch from './MissionConflictsSwitch';
import Configs from "../../../../Configs";
//1.1 editor (no full screen link)
//refactoring WIP...

//right now could be moved to missionfield
//or contrary -> unify editor setup here

const MissionEditor = ({record, conflicts, primitives, legsPrimitives, myAppExtras, bBox, altSliderOptions, ...rest}) => {
    const wrapperStyle = {position: "relative", height: `${Configs.MAP_SIZE}px`};


    //currently mission editor!
    console.log('MissionEditor, legsPrimitives', legsPrimitives);
    return (
        <div>
            <MissionConflictsSwitch
                conflicts={conflicts}

                //record={record}
                {...rest}
            />

            <MissionEditorMapUI
                bBox = {bBox}
                //heightBundle = {heightBundle}
                wrapperStyle = {wrapperStyle}
                record = {record}
                altSliderOptions={altSliderOptions}
                //inspectData = {inspectData}
            />
        </div>
    )
};

export default MissionEditor;