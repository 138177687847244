import {CONFIGURATION_REMOVE_KEY, CONFIGURATION_SET_KEY, CONFIGURATION_SET_KEY_OBJECT} from "./ConfigurationConstants";


export const setConfigurationKey = (key, value) => {
    return ({
        type: CONFIGURATION_SET_KEY,
        key,
        value
    })
};


export const setConfigurationKeyObject = (key, value) => {
    return ({
        type: CONFIGURATION_SET_KEY_OBJECT,
        key,
        value
    })
};

export const removeConfigurationKey = (key) => {
    return ({
        type: CONFIGURATION_REMOVE_KEY,
        key,
    })
};

