import {endDraw} from "./deckEditor";

import PubSub from "pubsub-js";
import {PubSubSignals} from "../../../LogicV1Redux/constants/PubSubSignals";

//pure functions
//no z-axis at the moment, so keeping clear
export function onPointDrawClick(deckPoint, latLng, layerClickEvent) {
    // sprint fixme some better dirty setup needed
    PubSub.publish(PubSubSignals.SET_DRAW_EDITOR_DIRTY);

    if (!deckPoint) return;

    deckPoint = deckPoint.recalc(latLng);
    console.log('closing deckPoint');
    endDraw(deckPoint);

    return deckPoint;
}

export function onPointDrawMouseMove(deckPoint, latLng) {
    console.log('point draw move', deckPoint);

    if (deckPoint) {
        deckPoint = deckPoint.recalc(latLng);
    }
    return deckPoint;
}
