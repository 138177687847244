//not finished... i guess
// what about helpers?
export const SET_DRAW_MODE = "SET_DRAW_MODE";

export const SET_CURRENT_PRIMITIVE = "SET_CURRENT_PRIMITIVE";

export const SET_ALL_EDITABLE_PRIMITIVES = "SET_ALL_EDITABLE_PRIMITIVES";
export const CLEAR_ALL_PRIMITIVES = "CLEAR_ALL_PRIMITIVES";

export const ADD_NEW_PRIMITIVE = "STORE_NEW_PRIMITIVE";
export const REMOVE_PRIMITIVE = "REMOVE_PRIMITIVE";


