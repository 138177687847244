import {
    SET_DRAW_MODE,
    SET_CURRENT_PRIMITIVE,
    SET_ALL_EDITABLE_PRIMITIVES,
    CLEAR_ALL_PRIMITIVES,
    ADD_NEW_PRIMITIVE,
    REMOVE_PRIMITIVE

} from "../constants/DrawEditorConstants";


export const setDrawMode = (payload) => {
    console.log('setDrawMode', payload);
    return ({
        type: SET_DRAW_MODE,
        payload,
    });
};

export const setCurrentPrimitive = (payload) => {
    console.log('setCurrentPrimitive', payload);
    return ({
        type: SET_CURRENT_PRIMITIVE,
        payload,
    });
};


export const setAllEditablePrimitives = (payload) => {
    console.log('setAllEditablePrimitives', payload);
    return ({
        type: SET_ALL_EDITABLE_PRIMITIVES,
        payload,
    });
};

export const clearAllPrimitives = () => {
    console.log('clearAllPrimitives', );
    return ({
        type: CLEAR_ALL_PRIMITIVES,
    });
};

export const addNewPrimitive = (payload) => {
    console.log('addNewPrimitive', payload);
    return ({
        type: ADD_NEW_PRIMITIVE,
        payload,
    });
};

export const removePrimitive = (payload) => {
    console.log('removePrimitive', payload);
    return ({
        type: REMOVE_PRIMITIVE,
        payload,
    });
};