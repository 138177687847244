let reduxStore = undefined;

//helper for acessing reduxStore outside React components
export default {
    init(store) {
        reduxStore = store;
    },
    getStore(){ //review calls and fix to shorthands...
        return reduxStore;
    },
    getState(){ //pass key?
        return reduxStore.getState();
    },

    //test spreading before fixing
    _dispatch({...e}){
        return reduxStore.dispatch({...e});
    }
};