import lodashMemoize from "lodash/memoize";
import {regex, email} from "ra-core";
import XRegExp from 'xregexp'

// From react-admin:
// If we define validation functions directly in JSX, it will
// result in a new function at every render, and then trigger infinite re-render.
// Hence, we memoize every built-in validator to prevent a "Maximum call stack" error.
const memoize = function (fn, resolver = defaultResolver) {
    return lodashMemoize(fn, resolver);
};

const defaultResolver = function () {
    const args = [];
    for (let _i = 0; _i < arguments.length; _i++) {
        args[_i] = arguments[_i];
    }
    return JSON.stringify(args);
};

const validateNotamAltArgsResolver = (...args) => {
    const values = args[1];
    const source = args[3];
    return [values.f, values.g, source].join("_");
};
export const validateNotamAltitudes =
    ((source) => lodashMemoize(
            (value, allValues, props) => {

                if (!(!!allValues.f && !!allValues.g)) {
                    return undefined;
                }

                const {translate} = props;
                const f = parseInt(allValues.f);
                const g = parseInt(allValues.g);
                const val = parseInt(value);
                switch (source) {
                    case 'f':
                        if (val >= g) {
                            return translate('resources.notams.validation.lower');
                        }
                        break;
                    case 'g':
                        if (val <= f) {
                            return translate('resources.notams.validation.upper');
                        }
                        break;
                }
                return undefined;
            }, validateNotamAltArgsResolver
        )

    );
export const validateNoSpaceTrim = lodashMemoize(
    (value, allValues, props) => {
        if (value && value.trim() !== value) {
            const {translate} = props;
            return translate('myroot.validation.whiteSpaceOnEdgeNotAllowed');
        }
        return undefined;
    }
);


const validateEmailOrUsernameMessage = ({translate}) => translate('myroot.validation.usernameOrEmailFormat');


export const validateEmailOrUsername = lodashMemoize(
    (value, allValues, props) => {
        const emailValidationErr = email()(value, allValues, props);
        const userValidationErr = validateUsername(value, allValues, props);

        if (emailValidationErr && userValidationErr) {
            return props.translate('myroot.validation.usernameOrEmailFormat');
        } else return undefined;
    }
);

export const validateUsername = regex(/^[\w\.\-]{2,64}$/, validateEmailOrUsernameMessage);

export const validateName = lodashMemoize(
    (value, allValues, props) => {
        if (!XRegExp("^(\\p{Letter} ?'?\\-?\\.?(\\. )?){2,64}$").test(value)) {
            const {translate} = props;
            return translate('myroot.validation.nameFormat');
        }
        return undefined;
    }
);

const validatePasswordMessage = ({translate}) => translate('myroot.validation.passwordFormat');
export const validatePassword = regex(/^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*\)\^\(\{\}\-\_\+\=\,\.\<\>\;\:\'\"\\\/\|\[\]])[a-zA-Z0-9!@#$%^&*\)\^\(\{\}\-\_\+\=\,\.\<\>\;\:\'\"\\\/\|\[\]]{8,}$/, validatePasswordMessage);

// const validateAllLangsInputArgsResolver = function (...args) {
//     console.log("artgs arguments", args)
//     if(arguments.length===0){
//         console.log("artgs EMPTY")
//         return 'empty';
//     }
//     const source = args[3];
//     const values = args[1];
//     const pathToRpaType = source.slice(0, source.lastIndexOf('.'));
//     console.log('artgs l ', pathToRpaType);
//     const langs = get(values, pathToRpaType);
//     let cacheKey = '';
//     for (const key in langs) {
//         cacheKey += langs[key];
//     }
//     console.log('artgs c', cacheKey, source);
//     return cacheKey;
// };

export const validateAllLangsInput =
  memoize(
        function (rpaType, source, message) {
            return function (value, allValues, props) {
                const VALUE_CHECK = false;
                if (VALUE_CHECK ? !value : true) {
                    let someNotEmpty = undefined;
                    let someEmpty = undefined;
                    const {translate} = props;
                    for (const property in allValues.etc.terms[rpaType]) {

                        if (!allValues.etc.terms[rpaType][property]) {
                            someEmpty = true;
                        } else {
                            someNotEmpty = true;
                        }

                        if (someEmpty !== undefined && someNotEmpty !== undefined) {
                            const result = (someEmpty ? !someNotEmpty : someNotEmpty) ? undefined : translate(message);
                            return result;
                        }

                    }
                    //xor
                    const result = (someEmpty ? !someNotEmpty : someNotEmpty) ? undefined : translate(message);
                    return result;
                }
                return undefined;
            }
        }
    );

export const oneElementRequired = lodashMemoize((translate, message = 'ra.validation.required') => (value, values, props) => {
    if (Array.isArray(value))
        return value.length > 0 ? undefined : translate(message);
    else return translate(message);

});

export const tax = regex(/^[\d]{10}$/, 'error.TaxNumber');