import React, {DOMFactory} from 'react';
import PropTypes from 'prop-types';
import { Field, propTypes, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { parseUrl, stringify } from 'query-string';
import compose from 'recompose/compose';
//import CardText from '@material-ui/core/CardText';

import {email, required } from 'react-admin';

import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import { translate } from 'ra-core';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';

import {loginPageStyles as styles, renderInput} from "../../utils/tempCommon";
import {myAuthSagaNewPassRq} from "../../../LogicV2/auth/MyExtraAuthActions";
import StoreProvider from "../../MyStoreProvider";
import {showNotification} from "react-admin";
import {validatePassword} from "../../utils/validationHelpers";
//import { parseUrl, stringify } from 'query-string';

//duh
const getTokenFromLocation = (location) => {

    let accessToken = false;
    if (location) {
        const parsed = parseUrl(location.search);
        console.log(parsed);
        if (parsed && parsed.query && parsed.query.access_token) {
            accessToken = parsed.query.access_token;
        }
    }

    return accessToken;
};



const finalResetPassDataRq = (auth, dispatch, { redirectTo }) => {

    console.log('==== can early test for data correctness here', auth);
    const {newPassword, retype} = auth;

    if (auth.accessToken) {
        dispatch(myAuthSagaNewPassRq({newPassword,retype,access_token:auth.accessToken}, redirectTo));
    }
    else if (auth.link) {

        //assuming url passed
        const parsed = parseUrl(auth.link);
        const query = parsed.query;
        console.log('=====parsed', parsed);

        if (query && query.access_token) {
            console.log('confirmRegistrationRq', auth);
            dispatch(myAuthSagaNewPassRq({newPassword,retype,access_token:query.access_token}, redirectTo));
        }
        else
            return Promise.reject();
    }
    else
        return Promise.reject();

};

const validatePass = [required(), validatePassword]; //, email()];
const validateLink = []; // [required()] //, email()];
const validatePassRetype = [required()];

const ResetPassForm = ({ classes, isLoading, handleSubmit, translate, ...rest }) => {

    console.log('reset pass rest', rest);

    const locationAccessToken = getTokenFromLocation(rest.location);


    console.log('reset pass locationAccessToken', locationAccessToken);

    return (
        <form onSubmit={handleSubmit(finalResetPassDataRq)} >

            <div className={classes.form}>
                {(!locationAccessToken)?
                    <>
                    <CardActions>
                        <Typography gutterBottom>
                            {translate('myroot.auth.passwordVerificationMsg')}
                        </Typography>
                    </CardActions>
                    <div className={classes.input}>
                        <Field
                            name="link"
                            component={renderInput}
                            label={translate('myroot.auth.verificationLink')}
                            disabled={isLoading}
                            //validate={validateLink}
                        />
                    </div>
                    </>
                    :
                    <div>
                        <Field //damn, hidden field, fast
                            name="accessToken"
                            val={locationAccessToken}
                            component={({ input, val }) =>
                                {
                                    input.onChange(val);
                                    return null
                                }
                            }
                        />
                    </div>
                }
                <div className={classes.input}>
                    <Field
                        name="newPassword"
                        component={renderInput}
                        label={translate('myroot.auth.newPassword')}
                        type="password"
                        disabled={isLoading}
                        validate={validatePass}

                    />
                </div>
                <div className={classes.input}>
                    <Field
                        name="retype"
                        component={renderInput}
                        label={translate('myroot.auth.retype')}
                        type="password"
                        disabled={isLoading}
                        validate={validatePassRetype}
                    />
                </div>
            </div>

            <CardActions>
                <Button
                    variant="raised"
                    type="submit"
                    color="primary"
                    disabled={isLoading}
                    className={classes.button}
                >
                    {isLoading && <CircularProgress size={25} thickness={2}/>}
                    {translate('myroot.auth.verify')}
                </Button>
            </CardActions>
            {/*
        <Link to={{pathname: "/login"}} >{translate('myroot.auth.signIn')}</Link>,&emsp;
        <Link to={{pathname: "/reset-pass"}} >{translate('myroot.auth.resetPass')}</Link>
        */}
        </form>
    );
}

// RegisterSuccessForm.propTypes = {
//     ...propTypes,
//     classes: PropTypes.object,
//     redirectTo: PropTypes.string,
// };

const mapStateToProps = state => ({ isLoading: state.admin.loading > 0 });

//form after connect ? works, but... hmmm..
const enhance = compose(
    withStyles(styles),
    translate,
    connect(mapStateToProps),
    reduxForm({
        form: 'reset-pass-form',
        // onChange: ({...e}) => {
        //
        // },
        //can have early custom validation, but have on submit.. so...
        validate: (values, props) => {
            console.log('values', values);
            const errors = {};
            const { translate } = props;
            if (values.newPassword && values.newPassword !== values.retype) {
                errors.retype =  translate('myroot.auth.matchError');
                //just test. nope.
                //const store = StoreProvider.getStore();
                //store.dispatch(showNotification('ra.message.invalid_form', 'warning'));
            }

            return errors;
        },

        onSubmitFail: ({...e}) => {
            console.log('==============submit failed', e);
            //just test. but works.
            const store = StoreProvider.getStore();
            store.dispatch(showNotification('ra.message.invalid_form', 'warning'));
        },
        //replaced with saga, otherwise should be dispatched locally (probably)
        onSubmitSuccess: ({...e}) => {
            console.log('===============submit success', e);
        }

    })
);

export default enhance(ResetPassForm);
