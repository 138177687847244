import {Avatar, Chip, withStyles} from "@material-ui/core";
import React from "react";


const styles = {
    avatar: {
        color: "#ffffff",
        backgroundColor: "#f0d000",
        borderRadius: "46%",
        fontSize: "0.7rem"
    },
    colorPrimary: {
        color: "#000000",
        backgroundColor: "#ffffff",
        border: "solid",
        borderWidth: "2px",
        borderRadius: "18px",
        borderColor: "#f0d000",
        fontSize: "1.7rem",
        fontWeight: "400",
    }
};


const SuavoChip = ({classes}) => (
    <Chip
        avatar={
            <Avatar>UAVO</Avatar>
        }
        label="SuperPilot"
        classes={classes}
        color="primary"
    />
);

export default withStyles(styles)(SuavoChip);
