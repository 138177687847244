// 15/09/18 rewriting original FileInput from ra 2.2.4
//to support current backend support for attachments
//recompress on drop
//note: File is extended by react-dropzone,
//and preview field is created with: file.preview = window.URL.createObjectURL(file)
// /Users/droneradar/WebstormProjects/_justChecking/react-dropzone/src/index.js

//image manipulation using jimp library
//https://stackoverflow.com/questions/11821096/what-is-the-difference-between-an-arraybuffer-and-a-blob?rq=1

//possible extensions
//https://github.com/exif-js/exif-js
//https://github.com/shutterstock/changedpi


import React from 'react';
import {compose, withStateHandlers} from 'recompose';
import { withStyles } from '@material-ui/core/styles';
import { addField, translate, showNotification } from 'ra-core';
import {connect} from 'react-redux';

import Jimp from "jimp";
import Labeled from "ra-ui-materialui/lib/input/Labeled";
import sanitizeRestProps from "./RAField-sanitizeRestProps";
import MyFileInputPreview from "./MyFileInputPreview";
import withMyRender from "../utils/withMyRender";
import {ImageInput} from "ra-ui-materialui/lib/input/ImageInput";

import Dropzone from 'react-dropzone';
import classnames from 'classnames';
import Typography from "@material-ui/core/Typography";
import {addBlobToCacheList, loadingEnd, loadingStart, removeBlobFromCacheList} from "../logic/ui/MyUiActions";
import {getBlobHash} from "./MyFileField";



const styles = {
    root: { width: '100%' },
    dropZone: {
        background: '#efefef',
        cursor: 'pointer',
        padding: '1rem',
        textAlign: 'center',
        color: '#999',
    },
    previews: {
        display: "flex",
        flexWrap: "wrap"
    },
    removeButton: {
        display: 'inline-block',
        position: 'relative',
        float: 'left',
        '& button': {
            position: 'absolute',
            top: '0.5rem',
            right: '0.5rem',
            minWidth: '2rem',
            opacity: 0,
        },
        '&:hover button': {
            opacity: 1,
        },
    },
};

function myRender(thisClass, superRender) {

    const {
        accept,
        bundleSize,
        children,
        classes = {},
        className,
        disableClick,
        isRequired,
        label,
        maxSize,
        minSize,
        multiple,
        resource,
        source,
        options,
        showNotification,
        removeBlobFromCacheList,
        blobs,
        disabled,
        ...rest
    } = thisClass.props;

    const onDropAccepted = (event) => {
        let byteCounter = 0;
        event.map(file => byteCounter += file.size);
        if(bundleSize + byteCounter > maxSize) {
            showNotification('resources.files.rejectedInputFromBundleSize', 'error');
        }
        else {
            thisClass.onDrop(event);
        }
    };

    const onRemove = (file) => {
        const blobHash = getBlobHash(file);
        if(blobs.has(blobHash)) {
            URL.revokeObjectURL(blobs.get(blobHash));
            removeBlobFromCacheList(blobHash);
        }
        thisClass.onRemove(file)();
    };

    return (
        <Labeled
            label={label}
            className={classnames(classes.root, className)}
            source={source}
            resource={resource}
            isRequired={isRequired}
            {...sanitizeRestProps(rest)}
        >
                <span>
                    <Dropzone
                        onDropAccepted={onDropAccepted}
                        onDropRejected={()=>showNotification('resources.files.rejectedInput', 'warning', {autoHideDuration: 9500})}
                        accept={accept}
                        disableClick={disableClick}
                        maxSize={maxSize}
                        minSize={minSize}
                        multiple={false}
                        className={classes.dropZone}
                        {...{disabled}}
                        {...options}
                    >
                        {thisClass.label()}
                    </Dropzone>
                    <Typography>{`${(bundleSize*9.537*10**-7).toFixed(2)}/${Math.round(maxSize*9.537*10**-7).toFixed(2)} MB`}</Typography>
                    {children && (
                        <div className={classes.previews}>
                            {thisClass.state.files.map((file, index) => (
                                <MyFileInputPreview
                                    key={index}
                                    file={file}
                                    onRemove={()=>onRemove(file)}
                                    className={classes.removeButton}
                                    viewOnly={disabled}
                                >
                                    {React.cloneElement(children, {
                                        record: file,
                                        className: classes.preview,
                                        blobs
                                    })}
                                </MyFileInputPreview>
                            ))}
                        </div>
                    )}
                </span>
        </Labeled>
    );
}

//explicit copy of jimp data (5.5.0), skipping (large) buffer)
function getJimpData(image) {
    return {
        reader: 'JIMP 5.5.0',
        alpha : image.bitmap.alpha,
        bpp: image.bitmap.bpp,
        color: image.bitmap.color,
        colorType: image.bitmap.colorType,
        bitmapSize: image.bitmap.data.length,
        depth: image.bitmap.depth,
        gamma: image.bitmap.gamma,
        height: image.bitmap.height,
        interlace: image.bitmap.interlace,
        palette: image.bitmap.palette,
        width: image.bitmap.width,
        _quality: image._quality,
        _exif: image._exif // looks like not implemented yet
    };
}

//TODO props.buffercounter methods should be in one place {do not repeat yourself}
//TODO fix viewing multiple files while dropping or adding from dialog - shows always only one

//
//export
class MyFileInput extends ImageInput {
    static defaultProps = {
        ...ImageInput.defaultProps,
        labelMultiple: 'ra.input.image.upload_several',
        labelSingle: 'ra.input.image.upload_single',
    };

/*
    // turn a browser dropped file structure into expected structure
    transformFile = file => {
        if (!(file instanceof File)) {
            return file;
        }

        const { source, title } = React.Children.toArray(
            this.props.children
        )[0].props;

        const transformedFile = {
            rawFile: file,
            [source]: file.preview,
        };

        if (title) {
            transformedFile[title] = file.name;
        }

        return transformedFile;
    };


    onDrop = files => {
        console.log('------ondrop----', files);

        const updatedFiles = this.props.multiple
            ? [...this.state.files, ...files.map(this.transformFile)]
            : [...files.map(this.transformFile)];

        console.log('updatedFiles', updatedFiles);

        this.setState({ files: updatedFiles });

        if (this.props.multiple) {
            this.props.input.onChange(updatedFiles);
        } else {
            this.props.input.onChange(updatedFiles[0]);
        }
    }
*/

    componentDidMount() {
       this._recountBundleSize();
    }

    componentDidUpdate() {
        this._recountBundleSize();
    }

    _recountBundleSize = () => {
        const {value} = this.props.input;
        let size = 0;
        if (Array.isArray(value)) {
            this.props.input.value.map(el => size += el.base64File.fileSize);
            this.props.setBundleSize(size);
        }
    };

// turn a browser dropped file structure into expected structure
    transformFile = file => {

        const updateState = file => {
            const updatedFiles = this.props.multiple
                ? [...this.state.files, file]
                : [file];

            this.setState({ files: updatedFiles });
        };

        if (!(file instanceof File)
            || file.base64File) {
            updateState(file);
            return file;
        }

        return new Promise((resolve, reject)=>{

            // probably already tested by react-dropzone
            // if ( /\.(jpe?g|png|gif)$/i.test(file.name) ) {
            //
            // }

            console.log('------transform file as promise----', file);

            let originalFileData = {
                lastModified : file.lastModified,
                name: file.name,
                size: file.size,
                type: file.type
            };

            const { source, title } = React.Children.toArray(
                this.props.children
            )[0].props;

            console.log('source', source);
            console.log('title', title);

            const jpegType = Jimp.MIME_JPEG;
            const pdfType = 'application/pdf';

            //Support for pdf
            if(originalFileData.type === pdfType) {
                const fileReader = new FileReader();
                fileReader.onload = (event) => {

                    let base64StringHead = event.target.result;
                    const base64String = base64StringHead.replace(`data:${pdfType};base64,`, ``);

                    let base64File = {
                        name: file.name,
                        type: pdfType,
                        content: base64String,
                        contentSize: base64String.length,
                        fileSize: file.size,
                        encodingComparison: null
                    };

                    const newPreview = window.URL.createObjectURL(file);

                    console.log('base64 from buffer', base64File);
                    console.log('newPreview', newPreview);


                    const blobHash = getBlobHash({base64File});
                    this.props.addBlobToCacheList(blobHash, newPreview);



                    const transformedFile = {
                        //blob: newPreview,
                        thumbSrc: '/img/icons/pdf-icon.png',
                        base64File
                    };

                    if (title) {
                        transformedFile.title = file.name;
                    }

                    window.URL.revokeObjectURL(file.preview);

                    updateState(transformedFile);

                    resolve(transformedFile);

                };
                fileReader.readAsDataURL(file);
                return;
            }

            new Jimp(file.preview, (error, image)=>{

                // Check for errors
                if (error) {
                    alert(`There was an error processing the image.`);
                    resolve(file);
                }
                else {
                    console.log(`Successfully processed the image`, image);
                }

                let originalBitmapData = getJimpData(image);

                console.log('image', image);

                //was for tests, keeping code just in case
                // // //Resize this image
                // image.resize(512, 512)
                // //lower the quality by 90%
                //     .invert()
                //     .quality(10)

                //hardcoded?
                // anyway jpg 75 seems ok even for print (LGZ experience)
                // but we should add ability to recognize dpi
                image.quality(75)
                    //let's do manual conversion to blob AND base64 as we'll have control on possible memory leaks.
                    .getBuffer(jpegType, (err, buffer)=>{

                        console.log('buffer from image', buffer);
                        //buffer to blob
                        let blob = new Blob([new Uint8Array(buffer)], {type: jpegType});

                        console.log('blob from buffer', blob);

                        let newPreview = window.URL.createObjectURL(blob); // eslint-disable-line no-param-reassign



                        console.log('blob as URL', newPreview);

                        let base64String = buffer.toString('base64');

                        let base64File = {

                            name:file.name,
                            type:jpegType,
                            content:base64String,
                            contentSize:base64String.length,
                            fileSize: file.size,
                            encodingComparison: {
                                originalFileData,
                                originalBitmapData,
                                processedBitmapData:getJimpData(image)
                            }
                        };

                        console.log('base64 from buffer', base64File);

                        const transformedFile = {
                            //blob: newPreview,
                            base64File
                        };
                        const blobHash = getBlobHash({base64File});
                        this.props.addBlobToCacheList(blobHash, newPreview);

                        if (title) {
                            //hmm. why dynamic title field in original fileinput?
                            //transformedFile[title] = file.name;
                            transformedFile.title = file.name;
                        }

                        //clean up memory leak
                        window.URL.revokeObjectURL(file.preview);

                        updateState(transformedFile);

                        resolve(transformedFile);

                    });
            })
        });
    };

    onDrop = files => {

        if (this.processingImages) return;

        let promises = [];

        console.log('files', files);

        this.processingImages = true;
        this.props.loadingStart('resources.files.adding');

        promises = files.map(file => this.transformFile(file));

        //promises.push(promise);

        console.log('promises', promises);

        Promise
            .all(promises)
            .then(resolves => {  // (*)

                console.log('resolves', resolves);

                for(let resolve of resolves) {
                    console.log('resolve', resolve);
                }

                // const updatedFiles = this.props.multiple
                //     ? [...this.state.files, ...resolves]
                //     : [...resolves];
                //
                // console.log('updatedFiles', updatedFiles);
                //
                // this.setState({ files: updatedFiles });

                // funnily enough manually calling onChange blocks display update...
                if (this.props.multiple) {
                    this.props.input.onChange(this.state.files);
                } else {
                    this.props.input.onChange(this.state.files[0]);
                }

                this.processingImages = false;
                this.props.loadingEnd();
            });

    };
}

export default compose(
    withStateHandlers(
        () => ({
            bundleSize: 0,
        }),
        {
            increaseBundleSize: ({bundleSize}) => (value) => ({
                bundleSize: bundleSize + value,
            }),
            decreaseBundleSize: ({bundleSize}) => (value) => ({
                bundleSize: bundleSize - value,
            }),
            setBundleSize: () => (value) => ({
                bundleSize: value
            })
        }
    ),
    addField,
    translate,
    connect(
        state => ({blobs: state.myUi.blobs}),
        {showNotification, addBlobToCacheList, removeBlobFromCacheList, loadingStart, loadingEnd}
    ),
    withStyles(styles),
    withMyRender(myRender),
)(MyFileInput);
