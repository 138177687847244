//store related

export const STORE_ALL_ALERTS_V2 = "STORE_ALL_ALERTS_V2";
export const SET_ATC_OWNED_ALERT_V2 = "SET_ATC_OWNED_ALERT_V2"; //old approach -> single owned zone. fixme later
//sagas related

export const FETCH_ALL_ALERTS_V2_RQ = "FETCH_ALL_ALERTS_V2_RQ";

export const POST_ALERT_V2_RQ = 'POST_ALERT_V2_RQ';
export const CANCEL_ALERT_V2_RQ = 'CANCEL_ALERT_V2_RQ';

