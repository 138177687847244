import React from 'react';

//import Button from '@material-ui/core/Button';
import {
    CardActions,
    ListButton,
    ShowButton,
    //DeleteButton,
    RefreshButton,
} from 'react-admin';
import BackButton from "../../mui_test_suite/customComponents/BackButton";
import withStyles from "@material-ui/core-v3/styles/withStyles";


const styles = {
    box: {
        display: "contents"
    },
    push: {
        marginLeft: "auto"
    }
};

//NOT ACTIONS as redux actions.. fixme rename

//removing delete in default actions
//https://marmelab.com/react-admin/CreateEdit.html#actions

class EditActions extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        const {classes, basePath, data, resource} = this.props;
        console.log("basepath", basePath)
        return (
            <CardActions>
                <div className={classes.box}>
                    <BackButton/>
                    <div className={classes.push}/>
                    <ListButton basePath={basePath}/>
                    {/*<RefreshButton/>*/}
                </div>
            </CardActions>
        )
    }
}

export default withStyles(styles)(EditActions);
