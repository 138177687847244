import PubSub from "pubsub-js";
import {PubSubSignals} from "../../../LogicV1Redux/constants/PubSubSignals";


const EDIT_MODE = {
    SELECT : 'SELECT',
    MOVE_LL_A:'LATLONG_A', //center
    MOVE_LL_B:'LATLONG_B', //and center2
    MOVE_LL_C:'LATLONG_C', //and hmmm
    MOVE_CAPSULE: 'MOVE'
};
//let editableCircle = null;
let editMode = EDIT_MODE.SELECT;
let moveLatLng = null;

//no z-axis at the moment, so keeping clear
export function onCapsuleEditClick(deckCapsule, latLng, layerClickEvent) {

    //editableCircle = deckCircle;
    if (editMode === EDIT_MODE.SELECT) {

        const lceObject = (layerClickEvent && layerClickEvent.object)?layerClickEvent.object:null;

        if (lceObject === deckCapsule.markers[2]) {
            console.log('latC circle clicked');
            editMode = EDIT_MODE.MOVE_LL_C;
            return deckCapsule; //probably some styling later..

        } else if (lceObject === deckCapsule.markers[1]) {
            console.log('latB circle clicked');
            editMode = EDIT_MODE.MOVE_LL_B;
            return deckCapsule; //probably some styling later..

        } else if (lceObject === deckCapsule.markers[0]) {
            console.log('center circle clicked');
            editMode = EDIT_MODE.MOVE_LL_A;
            return deckCapsule; //probably some styling later..

        } else if (lceObject === deckCapsule) {

            editMode = EDIT_MODE.MOVE_CAPSULE;
            // sprint fixme some better dirty setup needed
            //PubSub.publish(PubSubSignals.SET_MISSION_LEGS_DIRTY);
            moveLatLng = latLng;
            //originalZeroLatLng = latlngs[0];
            return deckCapsule; //probably some styling later..

        }
        return null; //deselect
    }
    else if (editMode === EDIT_MODE.MOVE_LL_A) {

        // sprint fixme some better dirty setup needed
        PubSub.publish(PubSubSignals.SET_DRAW_EDITOR_DIRTY);

        editMode = EDIT_MODE.SELECT;
        return deckCapsule.recalc(latLng, deckCapsule.helpers[1], deckCapsule.helpers[2]);
    }
    else if (editMode === EDIT_MODE.MOVE_LL_B) {

        // sprint fixme some better dirty setup needed
        PubSub.publish(PubSubSignals.SET_DRAW_EDITOR_DIRTY);

        editMode = EDIT_MODE.SELECT;
        return deckCapsule.recalc(deckCapsule.helpers[0], latLng, deckCapsule.helpers[2]);
    }
    else if (editMode === EDIT_MODE.MOVE_LL_C) {

        // sprint fixme some better dirty setup needed
        PubSub.publish(PubSubSignals.SET_DRAW_EDITOR_DIRTY);

        editMode = EDIT_MODE.SELECT;
        return deckCapsule.recalc(deckCapsule.helpers[0], deckCapsule.helpers[1], latLng);
    }
    else if (editMode === EDIT_MODE.MOVE_CAPSULE) {
        editMode = EDIT_MODE.SELECT
    }
    return deckCapsule;
}

export function onCapsuleEditMouseMove(deckCapsule, latLng) {
    if (editMode === EDIT_MODE.MOVE_LL_A) {
        return deckCapsule.recalc(latLng, deckCapsule.helpers[1], deckCapsule.helpers[2]);
    }
    else if (editMode === EDIT_MODE.MOVE_LL_B) {
        return deckCapsule.recalc(deckCapsule.helpers[0], latLng, deckCapsule.helpers[2]);
    }
    else if (editMode === EDIT_MODE.MOVE_LL_C) {
        return deckCapsule.recalc(deckCapsule.helpers[0], deckCapsule.helpers[1], latLng);
    }
    else if (editMode === EDIT_MODE.MOVE_CAPSULE) {

        let latlngs = deckCapsule.helpers;

        let offset = [];
        offset[0] = latLng[0] - moveLatLng[0];
        offset[1] = latLng[1] - moveLatLng[1];
        for (let i = 0; i < latlngs.length; i++) {
            latlngs[i][0] += offset[0];
            latlngs[i][1] += offset[1];
        }
        moveLatLng = latLng;

        // sprint fixme some better dirty setup needed
        PubSub.publish(PubSubSignals.SET_DRAW_EDITOR_DIRTY);

        return deckCapsule.recalc(deckCapsule.helpers[0], deckCapsule.helpers[1], deckCapsule.helpers[2]);
    }

    return null;

}
