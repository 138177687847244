//v1 related

//store related

export const STORE_ALL_CHECKINS_V2 = "STORE_ALL_CHECKINS_V2";
export const SET_LOST_CONTROL_REVIEWED = "SET_LOST_CONTROL_REVIEWED";

//sagas related

export const FETCH_ALL_CHECKINS_V2_RQ = "FETCH_ALL_CHECKINS_V2_RQ";


//naming is hard :)
export const POST_OWNED_CHECKIN_V2 = "SET_OP_OWNED_CHECKIN_V2";
export const POST_ATC_REVIEWED_CHECKIN_V2 = 'SET_ATC_OWNED_CHECKIN_V2';
