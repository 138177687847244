import React from 'react';
import Tooltip from "@material-ui/core/Tooltip";
import TranslatableTypography from "./TranslatableTypography";
import Zoom from "@material-ui/core/Zoom/Zoom";


const sanitizeRestProps =
    ({
         className,
         basePath,
         record,
         resource,
         ...rest
     }) => rest;

export const TooltipProxy = ({children, content, ...props}) => (
    <Tooltip title={<TranslatableTypography variant="caption" color="inherit"
                                            content={content}/>}
             TransitionComponent={Zoom}
             disableFocusListener
             disableTouchListener
             placement="top"
             enterDelay={600}
             PopperProps={{disablePortal: true}}
             {...sanitizeRestProps(props)}

    >
        {children}
    </Tooltip>
);
