
import React from 'react';

import * as dd from 'dis-gui';
import DisPrimitiveRenderer from "./DisPrimitiveRenderer"; //renaming didn't work


export default class ZoneEditorGeometryRenderer extends React.Component {

    constructor(props) {
        super(props);
        //console.log('geometry here', props);
    }

    render() {
        const {primitive} = this.props;
        if (!primitive) return (
            <dd.Text
                label='No geometry!'
                value={'start drawing'}
                onChange={()=>{}}
            />
        );
        //console.log(this.props);
        return (
            <dd.Folder label='Geometry' expanded={true}>
                <DisPrimitiveRenderer primitive = {primitive}/>
            </dd.Folder>
        )
    }

}
