import {PRIMITIVES} from "../deckDraw/DrawEditorEnums";
import DeckCapsule from "./DeckCapsule";
import DeckCircle from "./DeckCircle";
import DeckPipe from "./DeckPipe";
import DeckPoly from "./DeckPoly";
import {DEFAULT_MAX, DEFAULT_MIN} from "../deckDraw/deckEditor";
import {fixLatLngPrecision} from "../../../LogicV1Redux/oldLogic/GeoConverters";

//yikes
var Buffer = require('buffer/').Buffer ; // note: the trailing slash is important!

var wkx = require('wkx'); //fixme!
//var Buffer = require('buffer').Buffer; //fixme

export function wkb2geojson(wkb) {
    const wkbBuffer = Buffer.from(wkb, 'hex');
    //console.log('wkbBuffer', wkbBuffer);

    return wkx.Geometry.parse(wkbBuffer).toGeoJSON();
}


export function deserializePrimitive({primitive, geometry, min, max}) {
    //cloning as usually
    let newEditablePrimitive = {};
    //console.log('deserializing primitive, ', primitive, geometry);

    switch (primitive.type) {
        //fixme! better constructors

        case PRIMITIVES.CAPSULE:
            newEditablePrimitive = new DeckCapsule(primitive.start, primitive.end, primitive.radius);
            break;
        case PRIMITIVES.CIRCLE:
            newEditablePrimitive = new DeckCircle(primitive.centre, primitive.radius);
            break;
        case PRIMITIVES.PIPE:
            newEditablePrimitive = new DeckPipe(primitive.latLngs);
            newEditablePrimitive.setRadius(primitive.radius);
            break;
        case PRIMITIVES.POLYGON:
        default:
            if (primitive.type !== PRIMITIVES.POLYGON) {
                console.log('not editable primitive, fixme?', primitive.type, primitive, geometry);
            }
            //console.log('wkbBuffer', wkbBuffer);

            const geojson = wkb2geojson(geometry);
            //console.log('geom', geom);
            newEditablePrimitive = new DeckPoly(geojson.coordinates[0]);
    }
    newEditablePrimitive.setMin(min || DEFAULT_MIN);
    newEditablePrimitive.setMax(max || DEFAULT_MAX);

    return newEditablePrimitive;

}

export function serializePrimitive(primitive, useWKT = false) {
    let ret = {primitive:{}};
    switch (primitive.primitiveType) {
        case (PRIMITIVES.CIRCLE):
            ret.primitive.type = PRIMITIVES.CIRCLE;
            ret.primitive.radius = primitive.radius;
            ret.primitive.centre = fixLatLngPrecision(primitive.centre);
            break;
        case (PRIMITIVES.CAPSULE):
            ret.primitive.type = PRIMITIVES.CAPSULE;
            ret.primitive.radius = primitive.radius;
            ret.primitive.start = fixLatLngPrecision(primitive.helpers[0]);
            ret.primitive.end = fixLatLngPrecision(primitive.helpers[1]);
            break;
        case (PRIMITIVES.PIPE):
            ret.primitive.type = PRIMITIVES.PIPE;
            ret.primitive.radius = primitive.radius;
            ret.primitive.latLngs = primitive.helpers;
            break;
        default:
            ret.primitive.type = primitive.primitiveType;

    }

    const fixedGeom = [];
    //fixme inner rings
    for (let i = 0; i < primitive.polygon[0].length; i++) {
        fixedGeom.push(fixLatLngPrecision(primitive.polygon[0][i]));
    }
    //ret.geom = fixedGeom;
    //uses SRID 4326
    const geom = wkx.Geometry.parseGeoJSON({ type: 'Polygon', coordinates: [fixedGeom] });

    if (useWKT) {
        ret.wkt = geom.toWkt(); //to cleanup. required by rt-map backend
    } else {
        ret.wkb = geom.toEwkb().toString('HEX')
            .toUpperCase(); //equelity tests. db returns uppercase..
    }


    ret.min = primitive.min || DEFAULT_MIN;
    ret.max = primitive.max || DEFAULT_MAX;
    console.log('serializedPrimitive', ret);

    return ret;
}
