import Configs, {FEATURES_V1_2_ENUMS} from "../../../../Configs";

const ACCOUNT_VIEW_SMOOTHER_UX_ENABLED = Configs.enabledFeatures.includes(FEATURES_V1_2_ENUMS.ACCOUNT_VIEW_SMOOTHER_UX);

export const panelStyles = theme => ({
    root: {
        margin: "2px",
    },

    block: {
        display: 'block',
        maxHeight: ACCOUNT_VIEW_SMOOTHER_UX_ENABLED ? undefined : '60vh',
        overflow: ACCOUNT_VIEW_SMOOTHER_UX_ENABLED ? undefined : 'auto'
    },

    heading: {
        fontSize: theme.typography.pxToRem(15),
        //flexDirection: "row"
        //flexBasis: auto
        //flexBasis: '100%',
        //flexShrink: 0,
    },
    subheading: {
        //flexWrap: "wrap",
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
        //flexDirection: "row"

    },
    phoneCodeDiv: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
    },
    phoneCodeInput: {
        minWidth: "300px"
    },
    expansionPanel: {
        marginBottom: "0.5rem",
        marginTop: "0",
        opacity: "1",
        transition: 'height 0.5s 0.35s, opacity 1s 0.35s, margin-bottom 0.5s 0.35s',
    },
    expansionPanelNotActive: {
        height: "4.125rem",
    },
    overflowHide: {
        marginBottom: "0rem",
        transition: 'opacity 1s, height 0.5s, margin-bottom 1s',
        opacity: "0",
        height: "0",
        overflow: "hidden",
    },
    disabledText: {
        margin: "0.5rem 0",
        height: "2rem",
        width: "27rem",
        opacity: "1",
        transition: 'height 0.5s 0.55s, width 0.5s 0.55s,opacity 0.5s 0.95s, margin 0.5s 0.55s',
        maxWidth: ACCOUNT_VIEW_SMOOTHER_UX_ENABLED ? "100%" : undefined,
    },
    disabledTextHide: {
        margin: "0 0",
        opacity: "0",
        height: "0",
        width: "0",
        overflow: "hidden",
        transition: 'height 0.5s 0.35s, width 0.5s 0.35s,opacity 1s 0.35s, margin 0.5s 0.35s',
    },
    inline: {
        display: "flex",
        alignItems: "center",
    },
    requiredNonFilled: {
        color: theme.palette.primary.contrastText,
        fontWeight: "600"
    },
    disclaimer: {
        width: "unset"
    },
    checkboxLabel: {
        paddingBottom: `${theme.spacing.unit}px`
    }
});