import {ALERT_SHOW_MSG} from "./AlertboxConstants";


export const showAlertMessages = ({situationID, locationValid, data}) => {
    return ({
        type: ALERT_SHOW_MSG,
        situationID,
        locationValid,
        data
    })
};

