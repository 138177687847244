export const LOADING_START = 'LOADING_START';
export const LOADING_END = 'LOADING_END';
export const SET_TITLE = 'SET_TITLE';
export const ADD_BLOB = 'ADD_BLOB';
export const REMOVE_BLOB = 'REMOVE_BLOB';
export const TOGGLE_MISSION_TERM_DIALOG = 'TOGGLE_MISSION_TERM_DIALOG';
export const TOGGLE_MISSION_NO_CONFLICTS_DIALOG = 'TOGGLE_MISSION_NO_CONFLICTS_DIALOG';
export const TOGGLE_EVENTS_STREAM_WINDOW = 'TOGGLE_EVENTS_STREAM_WINDOW';
export const TOGGLE_ACM_DIALOG_RPA = 'TOGGLE_ACM_DIALOG_RPA';
export const TOGGLE_ACM_TERMS_CREATOR_DIALOG = 'TOGGLE_ACM_TERMS_CREATOR_DIALOG';

export const loadingStart = (primaryMessage, secondaryMessage) => {
    console.log('----- action LOADING START:');
    return ({
        type: LOADING_START,
        primaryMessage,
        secondaryMessage
    })
};

export const loadingEnd = () => {
    console.log('----- action LOADING END:');
    return ({
        type: LOADING_END
    })
};

export const setTitle = (title) => {
    console.log('----- action SET TITLE:', title);
    return ({
        type: SET_TITLE,
        title,
    })
};

//TODO move to another reducer probably
export const addBlobToCacheList = (hash, blob) => {
    return ({
        type: ADD_BLOB,
        hash,
        blob,
    })
};

export const removeBlobFromCacheList = (hash) => {
    return ({
        type: REMOVE_BLOB,
        hash,
    })
};

export const toggleEventsStreamWindow = ({show, portal, resource}) => {
    return ({
        type: TOGGLE_EVENTS_STREAM_WINDOW,
        show,
        portal,
        resource
    })
};

export const toggleAcmRpaDialog = ({show}) => {
    return ({
        type: TOGGLE_ACM_DIALOG_RPA,
        show
    })
};


export const toggleAcmTermsCreatorDialog = ({show}) => {
    return ({
        type: TOGGLE_ACM_TERMS_CREATOR_DIALOG,
        show
    })
};