
import {turfCircle, turfDistance, turfGreatCircle, isPosEqual} from '../oldCommon/commonTurf.js'

import {DEFAULT_MAX, DEFAULT_MIN, getDrawIco} from '../deckDraw/deckEditor';

import { PRIMITIVES} from '../deckDraw/DrawEditorEnums'
import {DeckPrimitive} from "./DeckPrimitive";
import {functionalColors} from "../oldCommon/commonInits";
import {turfArea, turfCentroid, turfKinks} from "../oldCommon/commonTurf";
import {mSq2kmSq} from "../../../LogicV1Redux/oldLogic/GeoConverters";

export const MARKER_TYPES = {
    VTX:'VTX',
    INSERT:'INSERT'
};


//unfinished... some quirks still and missing add/remove vertex in edit mode

//export default class DeckPoly extends deckPoint{ ->> see deckPoint note.
export default class DeckPoly extends DeckPrimitive{

    //fixme?
    constructor(latLngs) {
        super();

        this.primitiveType = PRIMITIVES.POLYGON;
        this.helpers = latLngs;

        this._calcGeometry();
    }

    recalc(latLngs, calcInserts = false) {

        this.helpers = latLngs;
        this._calcGeometry(calcInserts);
        return this;
    }

    _calcGeometry(calcInserts = false) {

        //console.log('deckpoly calc', calcInserts);

        // if (this.helpers.length > 2) {
        //     const closedPoly = [...this.helpers, this.helpers[0]];
        //     this.poly = {
        //         polygon: closedPoly,
        //         fillColor: functionalColors.newPrimitive,
        //     };
        //     this.paths = [];
        //
        // } else if (this.helpers.length === 2) {
        //     this.poly = null;
        //     this.paths = [{
        //         path: [this.helpers[0],  this.helpers[1]],
        //         widthScale: 1,
        //     }];
        // } else {
        //     this.poly = null;
        //     this.paths = null;
        // }
        this.extras = [];
        this.markers = [];
        this.polygon = [];
        this.paths = null;

        if ( !isPosEqual(this.helpers[0], this.helpers[1])) {
            this.paths = [{
                path: this.helpers,
                widthScale: 1,
            }]
        }

        if (this.helpers.length > 2) {
            this.polygon = [this.helpers]
        }
        //aargh. last one overrides visibility and selects stop working correctly <-fixed in move
        //still works after events swap (19-11-18), so keeping as-is

        const shownMarkersLength = this.helpers.length;

        for (let i = 0; i < shownMarkersLength; i++) {

            const curr = this.helpers[i];

            const marker = getDrawIco('BLUE_CIRCLE', curr[0], curr[1]);

            marker.type = MARKER_TYPES.VTX;
            marker.llIdx = i;

            this.markers.push(marker);

            if (calcInserts && i !== shownMarkersLength -1) {

                const next = this.helpers[i + 1];
                //fixme date change line

                const insert = getDrawIco('BLUE_SQUARE', (curr[0] + next[0]) / 2, (curr[1] + next[1]) / 2);
                insert.type = MARKER_TYPES.INSERT;
                insert.afterIdx = i;
                this.extras.push(insert);
            }
        }

        this.centroid = turfCentroid(this.helpers);
        //fixme? should be optimized to custom call?
        this.__area = {mSq:turfArea(this.polygon || [])};
        this.__area.kmSq = mSq2kmSq(this.__area.mSq);

        const kinks = turfKinks([this.helpers]);
        this.__kinks = (kinks && kinks.length > 0)?kinks:null;
        //this.__kinks = kinks;
    }
}
