import {MissionV2Statuses} from "../../../LogicV1Redux/constants/MissionsV2Enums";
import Icon from "@material-ui/core/Icon/Icon";
import React from "react";
import {warningColor, dangerColor, successColor, infoColor} from "../../components/dashboard/style/material-dashboard-react"
import {CaaApplicationStatuses} from "../../../LogicV1Redux/constants/CaaApplicationEnums";


export const getStatusIcon = (status) => _getIcon(status);

export const getStatusIconColored = (status) =>
        _getIcon(status, _getStatusColor(status));
        
export const getStatusColorText = (status) => {
    switch (status) {
        case MissionV2Statuses.PLANNED:
        case CaaApplicationStatuses.VERIFY:
        case CaaApplicationStatuses.VERIFY_AGAIN:
        case CaaApplicationStatuses.HOLD_MEETING_REQUEST:
        case CaaApplicationStatuses.HOLD_MEETING_ACCEPTED:
            return 'warning';
        case MissionV2Statuses.ACCEPTED:
        case CaaApplicationStatuses.ACCEPTED:

            return 'success';
        case MissionV2Statuses.REJECTED:
        case CaaApplicationStatuses.REJECTED:
        case CaaApplicationStatuses.HOLD_MEETING_REJECTED:
            return 'danger';
        case MissionV2Statuses.CREATED:
        case CaaApplicationStatuses.CREATED:
        case CaaApplicationStatuses.MODIFIED:
            return 'info';
        case MissionV2Statuses.BLOCKED:
            return 'danger';
        default:
            return 'info';
    }
};

const _getIcon = (status, color) => {
    let icon;
    switch (status) {
        case MissionV2Statuses.PLANNED:
            icon = 'help_outline';
            break;
        case MissionV2Statuses.ACCEPTED:
        case CaaApplicationStatuses.ACCEPTED:
            icon = 'done';
            break;
        case MissionV2Statuses.REJECTED:
        case CaaApplicationStatuses.REJECTED:
            icon = 'clear';
            break;
        case MissionV2Statuses.CREATED:
        case CaaApplicationStatuses.CREATED:
            icon = 'save_alt';
            break;
        case MissionV2Statuses.BLOCKED:
            icon = 'help_outline';
            break;
        case CaaApplicationStatuses.MODIFIED:
            icon = 'low_priority';
            break;
        case CaaApplicationStatuses.VERIFY:
            icon = 'search';
            break;
        case CaaApplicationStatuses.VERIFY_AGAIN:
            icon = 'youtube_searched_for';
            break;
        case CaaApplicationStatuses.HOLD_MEETING_REQUEST:
            icon = 'assignment_ind';
            break;
        case CaaApplicationStatuses.HOLD_MEETING_ACCEPTED:
            icon = 'assignment_turned_in';
            break;
        case CaaApplicationStatuses.HOLD_MEETING_REJECTED:
            icon = 'assignment_late';
            break;
        default:
            icon = 'error_outline';
            break;

    }
    return <Icon style={{color: color}}>{icon}</Icon>
};

export const _getStatusColor = (status) => {
    switch (status) {
        case MissionV2Statuses.PLANNED:
        case CaaApplicationStatuses.VERIFY:
        case CaaApplicationStatuses.VERIFY_AGAIN:
        case CaaApplicationStatuses.HOLD_MEETING_REQUEST:
        case CaaApplicationStatuses.HOLD_MEETING_ACCEPTED:
            return warningColor;
        case MissionV2Statuses.ACCEPTED:
        case CaaApplicationStatuses.ACCEPTED:

            return successColor;
        case MissionV2Statuses.REJECTED:
        case CaaApplicationStatuses.REJECTED:
        case CaaApplicationStatuses.MODIFIED:
        case MissionV2Statuses.BLOCKED:
        case CaaApplicationStatuses.HOLD_MEETING_REJECTED:
            return dangerColor;
        case MissionV2Statuses.CREATED:
        case CaaApplicationStatuses.CREATED:
            return infoColor;

    }
};



