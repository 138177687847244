import React from 'react';
import TextField from '@material-ui/core/TextField';
import { cyan, pink } from '@material-ui/core/colors';

import StoreProvider from '../MyStoreProvider';
import {isSuperAdmin as hasSuperadminRole} from "../logic/MyRestConfig";



export const loginPageStyles = () => ({
    form: {
        padding: '0 1em 1em 1em',
    },
    input: {
        marginTop: '1em',
    },
    button: {
        width: '100%',
    },
});

// see http://redux-form.com/6.4.3/examples/material-ui/
export const renderInput = ({
                         meta: { touched, error } = {}, // eslint-disable-line react/prop-types
                         input: { ...inputProps }, // eslint-disable-line react/prop-types
                         ...props
                     }) => (
    <TextField
        error={!!(touched && error)}
        helperText={touched && error}
        {...inputProps}
        {...props}
        fullWidth
    />
);

//FIXME not tested mui 1
export function getColorsFromTheme(theme) {
    if (!theme) return { primary1Color: cyan[500], accent1Color: pink['A200'] };
    const { palette: { primary1Color, accent1Color } } = theme;
    return { primary1Color, accent1Color };
}



// export function processServerErrors(response) {
//
// }

//copy paste from aor-loopback/fetch lib for now..
//kind of worried about double decode required, so for tests
//var queryParameters = exports.queryParameters = function queryParameters(data) {
export function encodeQuery(data) {
    return Object.keys(data).map(function (key) {
        return [key, data[key]].map(encodeURIComponent).join('=');
    }).join('&');
}

//https://stackoverflow.com/questions/2090551/parse-query-string-in-javascript
export function decodeQuery(queryString) {
    let query = {};
    let pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
    for (let i = 0; i < pairs.length; i++) {
        let pair = pairs[i].split('=');
        query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
    }
    return query;
}


//keeping as helper, should be moved/joined to MyRestConfig (if will be good place then?) as parameterless call
export function isSuperAdmin() {

    const store = StoreProvider.getStore();
    const currentState = store.getState();

    //can be called before login somehow
    if (!currentState.mySelf.roles) {
        return false;
    }

    return hasSuperadminRole(currentState.mySelf.roles);

}
