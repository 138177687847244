import {MapController} from 'deck.gl';

class MyController extends MapController {
    constructor(options = {}) {
        super({...options, doubleClickZoom: false});
        console.log('my controller here');
        //this.events = ['pointermove'];
    }

    //fixme to finish custom event controller
    // handleEvent(event) {
    //     if (event.type === 'wheel') {
    //         console.log('aqq');
    //         // do something
    //     }
    //     if (event.type === 'panstart') {
    //         console.log(event);
    //         this.eventManager.manager.emit('tap', event);
    //         // do something
    //     }
    //     if (event.type === 'panmove') {
    //         //console.log(event);
    //         // do something
    //     } else {
    //         super.handleEvent(event);
    //     }
    // }
}

export default MyController
