
import {turfCapsule, turfDistance} from '../oldCommon/commonTurf.js'
import { PRIMITIVES} from '../deckDraw/DrawEditorEnums'
import {DEFAULT_MAX, DEFAULT_MIN, getDrawIco} from '../deckDraw/deckEditor';
import {turfArea, turfDestination, turfGreatCircle} from "../oldCommon/commonTurf";
import {DeckPrimitive} from "./DeckPrimitive";
import {functionalColors} from "../oldCommon/commonInits";
import {mSq2kmSq} from "../../../LogicV1Redux/oldLogic/GeoConverters";

//capsule is defined by two ends and third construction point for radius calc or TODO radius itself
export default class DeckCapsule extends DeckPrimitive {

    constructor(latlngA, latlngB, radiusOrLatLngC) {
        super();

        this.primitiveType = PRIMITIVES.CAPSULE;
        this._parseParams(latlngA, latlngB, radiusOrLatLngC);
        this._calcGeometry();
    }

    recalc(latlngA, latlngB, radiusOrLatLngC) {
        this._parseParams(latlngA, latlngB, radiusOrLatLngC);
        this._calcRadius();
        this._calcGeometry();
        return this;
    }

    _parseParams(latlngA, latlngB, radiusOrLatLngC) {
        this.helpers = [latlngA];
        if (latlngB) {
            this.helpers.push(latlngB);
        }
        if (latlngB && radiusOrLatLngC) {
            if (Array.isArray(radiusOrLatLngC)) {
                this.helpers.push(radiusOrLatLngC);
                this._calcRadius();
            }
            else {
                //fixme calculate bearing from axis
                this.radius = radiusOrLatLngC;
                this.helpers.push(turfDestination(latlngB, radiusOrLatLngC, 90));
            }
        }
    }

    setRadius(val) {
        this.radius = val;
        //fixme calculate bearing from axis
        this.helpers[2] = turfDestination(this.helpers[1], val, 90);
        this._calcGeometry();
        return this;
    }

    _calcRadius() {
        this.radius = (this.helpers.length > 2)?turfDistance(this.helpers[1], this.helpers[2]):undefined;
        this.start = (this.helpers[0])?this.helpers[0]:undefined;
        this.end = (this.helpers[1])?this.helpers[1]:undefined;


    }

    _calcGeometry() {
        console.log(this.radius);
        this.polygon = (this.radius)?turfCapsule(this.helpers[0], this.helpers[1], this.radius):[];

        this.markers = [
            getDrawIco('BLUE_CIRCLE', this.helpers[0][0], this.helpers[0][1]),
        ];
        this.paths = [];

        if (this.helpers[1]) {
            this.markers.push(getDrawIco('BLUE_CIRCLE', this.helpers[1][0], this.helpers[1][1]));
            this.paths.push(
                {
                    path: [this.helpers[0], this.helpers[1]],  //using line!.. hmm?
                    widthScale: 1,
                }
            );
        }
        if (this.helpers[2]) {
            this.markers.push(getDrawIco('BLUE_CIRCLE', this.helpers[2][0], this.helpers[2][1]));
            this.paths.push(
                {
                    path: turfGreatCircle(this.helpers[1], this.helpers[2]),  //using greatCircle!.. hmm?
                    widthScale: 1,
                }
            );
        }
        this.centroid = [(this.helpers[0][0]+this.helpers[1][0])/2, (this.helpers[0][1]+this.helpers[1][1])/2];

        //fixme? should be optimized to custom call?
        this.__area = {mSq:turfArea(this.polygon)};
        this.__area.kmSq = mSq2kmSq(this.__area.mSq);
    }

}
