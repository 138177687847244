import {turfCircle, turfDistance} from '../oldCommon/commonTurf.js'
import {endDraw} from "./deckEditor";

import DeckCapsule from '../deckPrimitives/DeckCapsule';
import PubSub from "pubsub-js";
import {PubSubSignals} from "../../../LogicV1Redux/constants/PubSubSignals";

//let deckCapsule = null;

let clickCnt = 1;
//no z-axis at the moment, so keeping clear params
//almost pure js
export function onCapsuleDrawClick(deckCapsule, latLng, layerClickEvent) {
    //console.log('capsule click', deckCapsule, clickCnt);
    //reiniting with fresh one -> one point already clicked
    // sprint fixme some better dirty setup needed
    PubSub.publish(PubSubSignals.SET_DRAW_EDITOR_DIRTY);

    if (deckCapsule.helpers.length === 1) {
        clickCnt = 1;
    }
    ///hmm double cliks?
    if (clickCnt === 1) {
        deckCapsule = deckCapsule.recalc(deckCapsule.helpers[0], latLng);
        clickCnt++;
    } else {
        deckCapsule = deckCapsule.recalc(deckCapsule.helpers[0], deckCapsule.helpers[1], latLng);
        clickCnt = 1;
        endDraw(deckCapsule);
    }
    return deckCapsule;
}

export function onCapsuleDrawMouseMove(deckCapsule, latLng) {
    console.log('capsule move', deckCapsule, clickCnt);

    if (deckCapsule) {
        if (clickCnt === 1) {
            deckCapsule = deckCapsule.recalc(deckCapsule.helpers[0], latLng);
        } else {
            deckCapsule = deckCapsule.recalc(deckCapsule.helpers[0], deckCapsule.helpers[1], latLng);
        }
    }
    return deckCapsule;

}
