import React from 'react';
import PropTypes from 'prop-types';
import { Field, propTypes, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
//import CardText from '@material-ui/core/CardText';
import {email, required } from 'react-admin';

import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import { translate } from 'ra-core';
import { Link } from 'react-router-dom';

import {loginPageStyles as styles, renderInput} from "../../utils/tempCommon";
import {myAuthSagaRegisterRq} from "../../../LogicV2/auth/MyExtraAuthActions";
import StoreProvider from "../../MyStoreProvider";
import {showNotification} from "react-admin";
import {validatePassword} from "../../utils/validationHelpers";
import RodoPopUp from './RodoPopUp'

import {rodoOpened} from '../../../LogicV1Redux/actions/MyAppExtrasActions'
//can use redirectTo!!
const registerRq = (auth, dispatch, { redirectTo }) =>
    dispatch(myAuthSagaRegisterRq(auth, redirectTo));

const validateEmail = [required(), email()];
const validatePass = [required(), validatePassword];
const validatePassRetype = [required()];


const RegisterForm = ({ classes, isLoading, handleSubmit, translate, extras }) => {
    const store = StoreProvider.getStore()
 
    const openRodo = () =>{ 
        store.dispatch(rodoOpened({isRodoOpened: true}))
    }

    const closeRodo=()=>{
        store.dispatch(rodoOpened({isRodoOpened: false}))
    }

    return <form onSubmit={handleSubmit(registerRq)}>
        <div className={classes.form}>
            <div className={classes.input}>
                <Field
                    name="email"
                    component={renderInput}
                    label={translate('myroot.auth.email')}
                    disabled={isLoading}
                    validate={validateEmail}
                />
            </div>
            <div className={classes.input}>
                <Field
                    name="password"
                    component={renderInput}
                    label={translate('myroot.auth.password')}
                    type="password"
                    disabled={isLoading}
                    validate={validatePass}

                />
            </div>
            <div className={classes.input}>
                <Field
                    name="retype"
                    component={renderInput}
                    label={translate('myroot.auth.retype')}
                    type="password"
                    disabled={isLoading}
                    validate={validatePassRetype}
                />
            </div>
        </div>
        <CardActions>
            <Button
                variant="raised"
                type="submit"
                color="primary"
                disabled={isLoading}
                className={classes.button}
            >
                {isLoading && <CircularProgress size={25} thickness={2} />}
                {translate('myroot.auth.signUp')}
            </Button>
        </CardActions>
        
        <CardActions>
            <Button
                variant="outlined"
                color="primary"
                className={classes.button}
                component={Link}
                to="/login"
            >
                {translate('myroot.auth.signingIn')}
            </Button>
            <Button
                color="primary"
                className={classes.button}
                component={Link}
                to="/reset-pass-rq"
            >
                {translate('myroot.auth.resetPassRq')}
            </Button>
        </CardActions>
        <CardActions>
        <Button
                color="primary"
                className={classes.button}
                onClick={()=>{openRodo()}}
            >
                {translate('myroot.rodo')}
            </Button>
      
            <RodoPopUp isOpen={extras.isRodoOpened} closeRodo={closeRodo}></RodoPopUp>
    
        </CardActions>

    </form>
};
RegisterForm.propTypes = {
    ...propTypes,
    classes: PropTypes.object,
    redirectTo: PropTypes.string,
};

const mapStateToProps = state => ({ isLoading: state.admin.loading > 0 });

//form after connect ? works, but... hmmm..
const enhance = compose(
    withStyles(styles),
    translate,
    connect(mapStateToProps),
    reduxForm({
        form: 'sign-up-form',
        validate: (values, props) => {
            const errors = {};
            const { translate } = props;
            if (values.password && values.password !== values.retype) {
                errors.retype =  translate('myroot.auth.matchError');
                //just test. nope.
                //const store = StoreProvider.getStore();
                //store.dispatch(showNotification('ra.message.invalid_form', 'warning'));
            }

            return errors;
        },
        onSubmitFail: ({...e}) => {
            console.log('==============submit failed', e);
            //just test. but works.
            const store = StoreProvider.getStore();
            store.dispatch(showNotification('ra.message.invalid_form', 'warning'));
        },
        // replaced with saga, otherwise should be dispatched locally (probably)
        // onSubmitSuccess: ({...e}) => {
        //     console.log('===============submit success', e);
        // }

    })
);

export default enhance(RegisterForm);
