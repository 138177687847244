export const PANEL_ADDRESS = 'adress panel';
export const ACCOUNT_ADRESS_FORM = 'self-form-address';

export const PANEL_COMPANY = 'company panel';
export const ACCOUNT_COMPANY_FORM = 'self-form-company';

export const PANEL_EMAIL = 'email panel';
export const ACCOUNT_EMAIL_FORM = 'self-form-mail';

export const PANEL_PHONE = 'phone panel';
export const ACCOUNT_PHONE_FORM = 'self-form-phone';

export const PANEL_USER_NAME = 'username panel';
export const ACCOUNT_USERNAME_FORM = 'self-form-username';

export const ACCOUNT_FORM_NAMES = [
    ACCOUNT_EMAIL_FORM,
    ACCOUNT_COMPANY_FORM,
    ACCOUNT_ADRESS_FORM,
    ACCOUNT_PHONE_FORM,
    ACCOUNT_USERNAME_FORM
];