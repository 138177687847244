import MyInputDialog from "../MyInputDialog";
import React from "react";
import {translate} from 'react-admin'
import {callbackFromNicknameDialog, toggleNicknameDialog} from "../../logic/settings/MySettingsActions";
import connect from "react-redux/es/connect/connect";
import compose from 'recompose/compose';
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";

class MyNicknameInputDialog extends React.Component {


    state = {
        value: ""
    };

    _handleChange = (event) => {
        this.setState({
            value: event.target.value
        })
    };

    render() {
        const {callbackFromNicknameDialog, nickNameDialog, toggleNicknameDialog, translate} = this.props;
        const {nicknames} = nickNameDialog;
        const {value} = this.state;

        return(
            <MyInputDialog title={'myroot.auth.nickNameDialogTitle'}
                           label={'myroot.auth.nickNameDialogHeader'}
                           confirm={'myroot.action.send'}
                           cancel={'ra.action.cancel'}
                           onConfirm={()=>{
                               toggleNicknameDialog({show: false});
                               callbackFromNicknameDialog({result: value})
                           }}
                           onClose={()=>{
                               toggleNicknameDialog({show: false});
                               callbackFromNicknameDialog({result: false})
                           }}
                           isOpen={nickNameDialog.visible}
                           confirmColor={'warning'}
                           confirmDisabled={value===""}
                           content={()=>
                               <TextField
                                   select
                                   label={translate('myroot.dashboard.accountForm.nickName')}
                                   value={value}
                                   onChange={this._handleChange}
                                   margin="normal"
                               >
                                   {nicknames.map(nickname => (
                                       <MenuItem key={nickname} value={nickname}>
                                           {nickname}
                                       </MenuItem>
                                   ))}
                               </TextField>
                           }
            />
        )
    }
}



const mapStateToProps = state => {
    return {
        nickNameDialog: state.mySettings.nickNameDialog,
    }
};

const mapDispatchToProps = dispatch =>
    (
        {
            callbackFromNicknameDialog: (e) => {
                dispatch(callbackFromNicknameDialog(e));
            },
            toggleNicknameDialog: (e) => {
                dispatch(toggleNicknameDialog(e));
            },
        }
    );

export default compose(
    translate,
    connect(
        mapStateToProps,
        mapDispatchToProps
    ),
)(MyNicknameInputDialog);