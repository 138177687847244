//special toolbar in edit view calling custom save mission saga

import {Component} from "react";
import PubSub from "pubsub-js";
import {PubSubSignals} from "../../../../LogicV1Redux/constants/PubSubSignals";
import {SaveButton} from "ra-ui-materialui";
import {
    callbackFromMissionNoConflictsDialog,
    callbackFromMissionTermDialog,
    saveMissionDataV2Rq,
    saveMissionStatusV2Rq
} from "../../../../LogicV1Redux/actions/MissionsV2Actions";
import {getFormInitialValues, getFormMeta, getFormValues, isPristine, isValid} from "redux-form";
import {connect} from "react-redux";
import React from "react";
import { showNotification } from 'react-admin';
import {TooltipProxy as Tooltip} from '../../TooltipProxy';
import MyInputDialog from "../../MyInputDialog";
import {MissionType} from "../../../../LogicV1Redux/constants/MissionsV2Enums";
import moment from "moment";
import {Holidays} from "../../../utils/momentHelpers";
import {BVLOS_OFFICIAL_CONSIDER_TIME, VLOS_OFFICIAL_CONSIDER_TIME} from "../../../../LogicV1Redux/constants/MissionsV2Constants";

class MissionSaveActionsButtonBase extends Component {

    state = {
        legsDirty: false,         //yikes, to redux, but first we need to review/cleanup related events flow fixme later
    };

    componentDidMount() {
        //console.log('=====btn subscribin ')
        this.pubsubOnMissionsLegsDirty = PubSub.subscribe(PubSubSignals.SET_DRAW_EDITOR_DIRTY, (msg, data) => {
            //console.log('=======on pubsubOnMissionsLegsDirty MissionSaveActionsButtonBase');
            this.setState({legsDirty:true});
        });
        this.pubsubOnMissionsLegsClean = PubSub.subscribe(PubSubSignals.CLEAR_DRAW_EDITOR_DIRTY, (msg, data) => {
            //console.log('===========on pubsubOnMissionsLegsDirty MissionSaveActionsButtonBase');
            this.setState({legsDirty:false});
        });
    }

    componentWillUnmount() {
        //console.log('=====btn unmounting ')

        PubSub.unsubscribe(this.pubsubOnMissionsLegsDirty);
        PubSub.unsubscribe(this.pubsubOnMissionsLegsClean);

        this.setState({legsDirty:false}) //fixme on refresh!, not working, but keeping
    }

    _handleClick = () => {

        // this._handleDialogClose();

        const { basePath, handleSubmit, redirect, dispatchSaveMissionDataV2RqAction, dispatchSaveMissionStatusV2RqAction, showNotification, setStatus} = this.props;
        console.log('=====save btn props = ', this.props);

        //fixme redirects..
        //dispatchSaveMissionDataV2RqAction(record, basePath, redirect); //hmm, basePath/redirect not passed anyway
        return handleSubmit(record => {
            console.log('=====save btn props = ', this.props, record);
            if (this.props.setStatus) {
                //record.status = this.props.setStatus; //fixme to constants
                //hacking for madrid;
                // (Extra etc for modified timestamp

                const data = {
                    uid: record.uid,
                    status:this.props.setStatus,
                    __etc:record.etc,
                    start: record.start,
                    type: record.typ,
                    // passing for conflicts check
                    __legs: record.legs,
                };

                dispatchSaveMissionStatusV2RqAction(data);
            }
            else {
                const data = Object.assign({}, record);
                dispatchSaveMissionDataV2RqAction(data);
            }
        });

    };

    _getSaveButton = (editorDisabled, systemDisabled, props, isSetStatusButton) => {

        if (editorDisabled && isSetStatusButton) return (
            <Tooltip content='resources.missions.tooltips.acceptEditorDisabled'>
                <span>
                    <SaveButton
                        handleSubmitWithRedirect={this._handleClick}
                        {...props}
                        disabled={true}/>
                </span>
            </Tooltip>
        );
        else if (systemDisabled && isSetStatusButton) return (
            <Tooltip content='resources.missions.tooltips.acceptSystemDisabled'>
                <span>
                    <SaveButton
                        handleSubmitWithRedirect={this._handleClick}
                        {...props}
                        disabled={true}/>
                </span>
            </Tooltip>
        );
        else return (
                <SaveButton
                    handleSubmitWithRedirect={this._handleClick}
                    {...props}
                />
            )
    };

    render() {
        const {basePath,
            submitOnEnter,
            handleSubmitWithRedirect,
            dispatchSaveMissionDataV2RqAction,
            dispatchSaveMissionStatusV2RqAction,
            showNotification,
            isFormValid,
            isFormPristine,
            setStatus,
            formValues,
            formMeta,

            formInitialValues,
            isLoading,
            canSendMission,
            termDialogOpen,
            callbackFromMissionTermDialog,
            callbackFromMissionNoConflictsDialog,
            showNoConflictsDialog,
            noConflictsDialogOpen,
            ...props} = this.props;

        const noLegs = (this.props.record && this.props.record.legs)
            ?(this.props.record.legs.length === 0):false;
        const {legsDirty} = this.state;
        //console.log('======saveBtn props', this.props);

        //somehow pristine doesn't work...
        //neither equal.. for formValues and formInitialValues...
        //on the other hand there is a lot happening here..
        //so let's fix the issue with acceptable level..

        //changes to dirty on any visit in fields...
        const dirtyValues = Object.keys(formMeta).length !== 0;

        const editorDisabled = (noLegs || legsDirty || dirtyValues) || !isFormValid;
        const systemDisabled = !canSendMission;
        const isSetStatusButton = !!setStatus;
        const saveButton = this._getSaveButton(editorDisabled, systemDisabled, props, isSetStatusButton);
        return (
            <>
                {saveButton}
                {isSetStatusButton &&
                <>
                    <MyInputDialog title={'resources.missions.timeDialogTitle'}
                                   label={['resources.missions.timeDialogLabel', {
                                       vlosTime: VLOS_OFFICIAL_CONSIDER_TIME,
                                       bvlosTime: BVLOS_OFFICIAL_CONSIDER_TIME
                                   }]}
                                   confirm={'myroot.action.sendForAccept'}
                                   cancel={'ra.action.cancel'}
                                   onConfirm={() => callbackFromMissionTermDialog({response: true})}
                                   onClose={() => callbackFromMissionTermDialog({response: false})}
                                   isOpen={termDialogOpen}
                                   confirmColor={'warning'}
                                   noInput
                    />
                    <MyInputDialog title={'resources.missions.noConflictsDialogTitle'}
                                   label={'resources.missions.noConflictsDialogLabel'}
                                   confirm={'myroot.action.understood'}
                                   onConfirm={() => callbackFromMissionNoConflictsDialog({response: true})}
                                   onClose={() => callbackFromMissionNoConflictsDialog({response: false})}
                                   isOpen={noConflictsDialogOpen}
                                   confirmColor={'warning'}
                                   noInput
                                   hideCancel
                                   hideClose
                    />
                </>
                }

            </>
        )
    }
}

//used also in atc toolbar
export const mapDispatchToProps_MissionSaveActionsBtn = dispatch =>
    (
        {
            dispatchSaveMissionDataV2RqAction : (e) => {
                console.log('dispatchin dispatchSaveMissionDataV2RqAction', e);
                dispatch(saveMissionDataV2Rq(e))
            },
            dispatchSaveMissionStatusV2RqAction : (e) => {
                console.log('dispatchin dispatchSaveMissionStatusV2RqAction', e);
                dispatch(saveMissionStatusV2Rq(e))
            },
            showNotification : (e) => {
                dispatch(showNotification(e));
            },
            callbackFromMissionTermDialog: (e) => {
                dispatch(callbackFromMissionTermDialog(e));
            },
            callbackFromMissionNoConflictsDialog: (e) => {
                dispatch(callbackFromMissionNoConflictsDialog(e));
            },
        }
    );

const editFormName = 'record-form'; //have to be default
const mapStateToProps_MissionSaveActionsBtn = (state) => ({
    //myAppExtras: state.myAppExtras,

    //hack, form currently doesn't set saving correctly,
    // but saga puts FETCH_START, FETCH_END we can use
    saving: state.admin.loading > 0,

    isFormValid: isValid(editFormName)(state),
    isFormPristine: isPristine(editFormName)(state),

    formMeta: getFormMeta(editFormName)(state),
    formValues: getFormValues(editFormName)(state),
    formInitialValues: getFormInitialValues(editFormName)(state),
    canSendMission: state.mySelf.userState.canSendMission,
    termDialogOpen: state.myUi.missionTermDialog.visible,
    noConflictsDialogOpen: state.myUi.missionNoConflictsDialog.visible,
});

export default connect(
    mapStateToProps_MissionSaveActionsBtn,
    mapDispatchToProps_MissionSaveActionsBtn
)(MissionSaveActionsButtonBase);
