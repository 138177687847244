//BASE FOR TRACKING REFACTORING AND OPTIMIZATION
//DO NOT REPLACE AUTOMAGICALLY YET!

import {
    GET_LIST,
    GET_ONE,
    CREATE,
    UPDATE,
    DELETE,
    GET_MANY,
    GET_MANY_REFERENCE,
} from 'react-admin';

import {
    GOV_ON_OPERATOR_UPDATE,
    MANAGE_BAN_OPERATOR,
    RESEND_VERIFICATION_EMAIL_GOV,
    RESEND_VERIFICATION_EMAIL_OPERATOR
} from "../LogicV1Redux/constants/AdminConstants";

const COUNT = 'COUNT';
const UPLOAD = 'UPLOAD'; //unused case of loopback containers support, was working, so keeping verbs and implementation
const DOWNLOAD = 'DOWNLOAD'; //unused case of loopback containers support, was working, so keeping verbs and implementation

const PATCH = 'PATCH'; //looks unused, probably some older approach.

const LOADSELF = 'LOADSELF';
const REQUEST_NICKNAMES = 'REQUEST_NICKNAMES';
const SAVESELF = 'SAVESELF';
const PUT_NICKNAME = 'PUT_NICKNAME';
const PATCH_PHONE = 'PATCH_PHONE';
const VERIFY_PHONE = 'VERIFY_PHONE';
const LOGIN = 'LOGIN';
const NEWPASSRQ = 'NEWPASSRQ';
const RESETPASSRQ = 'RESETPASSRQ';
const REGISTER = 'REGISTER';
const REGISTERCONFIRM = 'REGISTERCONFIRM';

//differentating LB api and RA api
// ({resource} or {resource}/{id} for loopback, sometimes in context overriden (see urls setup)
const LB_GET_ONE = 'LB_GET_ONE';
const LB_GET_MANY_REFERENCE = 'LB_GET_MANY_REFERENCE';
const LB_GET_MANY = 'LB_GET_MANY';

const LB_CREATE = 'LB_CREATE';
const LB_UPDATE = 'LB_UPDATE';
const LB_DELETE = 'LB_DELETE';

//REVIEWING VERBS USAGE (replacing strings) WIP
export const API_VERBS = {
    GET_LIST, //strings checked.
    GET_ONE, //strings checked.
    CREATE, //strings checked. twice
    UPDATE, //strings checked. twice
    DELETE, //strings checked. twice
    GET_MANY, //strings checked. twice
    GET_MANY_REFERENCE,  //strings checked. twice
    COUNT, //strings checked. twice
    UPLOAD, //strings checked. twice
    DOWNLOAD, //strings checked. twice
    PATCH, //strings checked as API verb, looks unused!. twice
    LOADSELF, //strings checked. twice
    REQUEST_NICKNAMES, //strings checked. twice
    SAVESELF, //strings checked. twice
    PUT_NICKNAME, //strings checked. twice
    PATCH_PHONE, //strings checked. twice
    VERIFY_PHONE, //strings checked. twice
    LOGIN, //strings checked. twice
    NEWPASSRQ, //strings checked. twice
    RESETPASSRQ, //strings checked. twice
    REGISTER, //strings checked. twice
    REGISTERCONFIRM, //strings checked. twice
    GOV_ON_OPERATOR_UPDATE, //strings checked. twice
    MANAGE_BAN_OPERATOR, //strings checked. twice
    RESEND_VERIFICATION_EMAIL_GOV, //strings checked. twice
    RESEND_VERIFICATION_EMAIL_OPERATOR, //strings checked. twice
    LB_GET_ONE,
    LB_GET_MANY,
    LB_GET_MANY_REFERENCE,
    LB_CREATE,
    LB_UPDATE,
    LB_DELETE,

};

