import {MissionV2Statuses} from "../../../../LogicV1Redux/constants/MissionsV2Enums";
import EditButton from "ra-ui-materialui/esm/button/EditButton";
import ShowButton from "ra-ui-materialui/esm/button/ShowButton";
import {Button as RaButton} from "ra-ui-materialui";
import {Queue} from "@material-ui/icons";
import React from "react";
import {cloneMission} from "../../../../LogicV1Redux/actions/MissionsV2Actions";
import {connect} from "react-redux";
import {isOperator} from "../../../logic/MyRestConfig";

const PureMissionActionButtons = (props) => {
    const {mySelf, record, permissions, dispatchCloneMission, ...rest} = props;

    if(!permissions) return null;

    //buu, sprint
    let canEdit = false;
    if (isOperator(permissions.roles)) {
        canEdit = record.status !== MissionV2Statuses.ACCEPTED && record.status !== MissionV2Statuses.PLANNED;
    }

    return (
        <div style={{display: "flex"}}>
            {
                canEdit
                    ? <EditButton record={record} {...rest} />
                    : <ShowButton record={record} {...rest}/>
            }
            {
                isOperator(permissions.roles) ?
                    <>
                        <RaButton label={'ra.action.clone'}
                                  onClick={() => dispatchCloneMission(record)}
                        >
                            <Queue/>
                        </RaButton>
                        {/*<ArchiveButton record={record} {...rest} />*/}

                    </>
                    : null
            }
        </div>
    )
};

const mapDispatchToProps_MissionActionButtons = dispatch => ({
    dispatchCloneMission: (e) => {
        dispatch(cloneMission(e))
    }
});

export const MissionActionButtons = connect(null, mapDispatchToProps_MissionActionButtons)(PureMissionActionButtons);