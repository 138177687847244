//todo cleanup and update descriptions to v1.1

//v 0.2 cleanup needed...

//early preflight (for v2) operator side business logic

import PubSub from 'pubsub-js'
//import Store from 'store/dist/store.modern'
import {urls} from "../../Configs";
import axios from "axios/index";
import logger from "./Logger";
import {deserializePrimitive, serializePrimitive} from "../../gl_map/glSetups/deckPrimitives/DeckPrimitiveSerialization";

import { PRIMITIVES} from '../../gl_map/glSetups/deckDraw/DrawEditorEnums'
//fixme -> auth, actions/redux
import React from 'react'
import {PubSubSignals} from '../constants/PubSubSignals'

import {getAuthHeaderV2} from './AuthConverters'

import {myAuthStoreSelf} from "../../LogicV2/auth/MyExtraAuthActions";
import {
    showNotification,
    hideNotification,
} from 'react-admin';
import StoreProvider from "../../dr_ra2/MyStoreProvider";

import isEqual from "lodash.isequal";
import isNumber from "lodash";

//import moment from "moment/moment";

//leg
// uid uuid NOT NULL DEFAULT gen_random_uuid(),
//     start timestamp with time zone,
//     "end" timestamp with time zone,
//     geometry geometry,
//     etc jsonb,
//     missionid uuid NOT NULL,
//     status text,
//     CONSTRAINT leg_pkey PRIMARY KEY (uid),
//     CONSTRAINT mission_fk FOREIGN KEY (missionid)
// REFERENCES public.mission (uid) MATCH SIMPLE
// ON UPDATE NO ACTION ON DELETE NO ACTION


let authToken;

export const MISSION_STATUSES = {
    PLANNING : "PLANNING",
};

let defaults = {

};

//let mission;

//let legs = [];

export function isMissionValid() {
    //let data = get();

    return true;
}

//redux - not mission window - should use this
// export function set(newData) {
//     let data = get();
//
//     data = { ...data, ...newData};
//
//     //PubSub.publish('mission', data)
// }



//promise all
//https://stackoverflow.com/questions/44402079/how-to-make-multiple-axios-requests-using-a-dynamic-array-of-links-in-javascript

//TODO cleanup old functionality. rename (in next refactoing)
//btw -> full processing could be bottleneck in rt-map (missions deserialization took over 1 sec on 110 missions list .. or logs?)
//console.warn('refactoring -> review serialization/deserializion legs usage, clean other things, rename file (is MissionEditorPlanner')

export function requestSave(missionState) {
    //only update for now
    //updateMission(missionState); - not needed yet.
    authToken = missionState.authToken;
    console.log('saving here', missionState);
    saveLegs(missionState);
}

//stub now
function updateMissionDataTransform(missionData) {
    return missionData;
}

//mission status vs leg status.. interesting issue
//let's start with null/planned, ..
// but wait. operator cannot change zoneleg... huh. BACKEND TODO?
//and heights conversion, damn...
export function serializeLegData(editablePrimitive, missionStatus) {

    console.log('leg editablePrimitive', editablePrimitive);

    const legRecord = (editablePrimitive.inspectData)?editablePrimitive.inspectData.legRecord:null;

    let newRecord = Object.assign({etc:{}},legRecord);

    newRecord = (newRecord)?JSON.parse(JSON.stringify(newRecord)):null; //hack for deep copy, we really need dirty

    console.log('oldRecordCp',newRecord);

    const {primitive, wkb, min, max } = serializePrimitive(editablePrimitive);

    newRecord.etc.primitive = primitive;
    newRecord.etc.centroid = editablePrimitive.centroid;
    newRecord.etc.aglMinMax = editablePrimitive.aglMinMax;
    if (isNumber(editablePrimitive.srtmV1Gnd)) {
        newRecord.etc.srtmV1Gnd = editablePrimitive.srtmV1Gnd;
    }
    //damn.. changes on srtm are updated in saga, not here, to rethink...

    newRecord.geometry = wkb;
    newRecord.min = min;
    newRecord.max = max;
    newRecord.uid = (legRecord)?legRecord.uid:null;

    console.warn('-------after serialize primitive', newRecord)

    //aand checking dirty.. WITH attached relations (cleanup? should be on copy..)
    //fixme Equality tests can fail on centroid -> seen during dev..
    if (!isEqual(newRecord, legRecord)) {
        console.warn('------ leg modified or new', newRecord, legRecord );
        newRecord.__dirty = true;
    } else {
        console.log('------ same leg!!', newRecord, legRecord)
    }

    return newRecord;
}

export function deserializeLegData(legRecord) {
    console.log('deserializeLegData', legRecord);

    const inspectData = {legRecord, inspectType:"LEG_FIXME"};
    const ret = {primitive:{}};
    if (!(legRecord && legRecord.geometry)) {
        console.warn('cannot deserialize leg data, fixme', inspectData);
        return {primitive:{inspectData}};
    } else {
        if (legRecord.etc && legRecord.etc.primitive) {
            ret.primitive = deserializePrimitive({
                primitive:legRecord.etc.primitive,
                geometry:legRecord.geometry,
                min:legRecord.min,
                max:legRecord.max,
            });
        } else {
            //forcing polygon
            ret.primitive = deserializePrimitive({
                primitive:{type:PRIMITIVES.POLYGON},
                geometry:legRecord.geometry,
                min:legRecord.min,
                max:legRecord.max,
            });
        }
        if (legRecord.etc.aglMinMax) {
            ret.primitive.aglMinMax = [...legRecord.etc.aglMinMax];
        }

        if (isNumber(legRecord.etc.srtmV1Gnd)) {
            ret.primitive.srtmV1Gnd = legRecord.etc.srtmV1Gnd;
        }

        ret.primitive.inspectData = inspectData;
        return ret;
    }
}

//stub now
function updateLegDataTransform(legData, missionStatus) {
    return serializeLegData(legData, missionStatus);
}

//stub now
function createLegDataTransform(legData, missionStatus) {
    return serializeLegData(legData, missionStatus);
}

function updateLegAsPromise(legData, missionStatus, legId) {
    const url = urls.updateLegUrl.replace('%legId%', legId);

    return axios(url, {
        method : urls.updateLegMethod,
        data : updateLegDataTransform(legData, missionStatus),
        headers : getAuthHeaderV2({token:authToken}),
    });
}

function createLegAsPromise(legData, missionStatus, missionId) {
    const url = urls.createLegUrl.replace('%missionId%', missionId);
    console.log('createLegAsPromise data', createLegDataTransform(legData, missionStatus));

    return axios(url, {
        method : 'POST',
        data : createLegDataTransform(legData, missionStatus),
        headers : getAuthHeaderV2({token:authToken}),
    });
}


//keeping for now, but outdated as we download relations via lb filter/scope
function loadLegs(missionState) {
    const {missionId, legs, missionStatus} = missionState;
    console.log('loading legs', missionId, legs, missionStatus);
    let promises = [];
    for (let i = 0; i < legs.length; i++) {
        if (legs[i].uid) {
            promises.push(updateLegAsPromise(legs[i], missionStatus));
        } else {
            promises.push(createLegAsPromise(legs[i], missionStatus, missionId));
        }
    }
    console.log(promises);
    Promise.all( promises )
        .then(
            results => {
                console.log(results);
            }
        )
        .catch((error) => {
            console.error('error in saving legs', error);
        })
}

function saveLegs(missionState) {
    const {missionId, legs, missionStatus} = missionState;
    //const missionStatus = missionRecord.missionStatus;
    console.log('saving legs here', missionId, legs, missionStatus);
    const store = StoreProvider.getStore();

    store.dispatch(showNotification('saving data', 'info'));

    let promises = [];
    for (let i = 0; i < legs.length; i++) {
        let legId = (legs[i].legRecord)?legs[i].legRecord.uid:null;
        if (legId) {
            promises.push(updateLegAsPromise(legs[i], missionStatus, legId));
        } else {
            promises.push(createLegAsPromise(legs[i], missionStatus, missionId));
        }
    }
    console.log(promises);
    Promise.all( promises )
        .then(
            results => {
                console.log('legs saved', results);
                store.dispatch(hideNotification());

                store.dispatch(showNotification('waiting for deconfliction', 'info'));
            }
        )
        .catch((error) => {
            console.error('error in saving legs', error);
            store.dispatch(hideNotification());

            store.dispatch(showNotification('saving data error', 'warning'));

        })
}

//must exists on lb already
function updateMission(missionState) {

    const promise = axios(urls.updateMissionUrl, {
        method : 'put', //? or patch?
        data : updateMissionDataTransform(missionState),
        headers : getAuthHeaderV2({token:authToken}),
    });

    promise
        .then(response => {
            console.log('updateMission response', response);
            // if (response.data.status.toLowerCase() === 'success') {
            //     set({
            //         checkInID: response.data.id,
            //         status: FlightStatuses.POSTED,
            //         //started: 0, //resetting, server doesn;t provide info.. somehow
            //     });
            //     logger.log('after checkin', get());
            // }
            // else {
            //     throw new Error(`${response.data.code} ${response.data.message}`);
            // }
        })
        .catch(function (error) {
            console.error('updateMission error', error);
        });
}



export default {
    //requestLoad,
    //set,
    requestSave,
}
