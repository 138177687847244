
import {FETCH_ALL_UNITS_V2_RQ, SAVE_UNIT_DATA_V2_RQ, STORE_ALL_UNITS_V2} from "../constants/UnitsV2Constants";

export const fetchAllUnitsV2Rq = (payload) => {
    console.log('fetchAllUnitsV2Rq', payload);
    return ({
        type: FETCH_ALL_UNITS_V2_RQ,
        payload,
    });
};

export const storeAllUnitsV2 = (payload) => {
    console.log('storeAllUnitsV2', payload);
    return ({
        type: STORE_ALL_UNITS_V2,
        payload,
    });
};

export const saveUnitDataV2Rq = (payload) => {

    console.log('saveUnitDataV2Rq', payload);
    return ({
        type: SAVE_UNIT_DATA_V2_RQ,
        payload,
    });
};
