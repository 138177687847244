//store related

export const MY_STORE_SETAUTH = 'MY_STORE_SETAUTH';
export const MY_STORE_CLEARAUTH = 'MY_STORE_CLEARAUTH';
export const MY_STORE_SETSELF = 'MY_STORE_SETSELF';
export const MY_STORE_CLEARSELF = 'MY_STORE_CLEARSELF';
export const MY_STORE_SETPANDORA = 'MY_STORE_SETPANDORA';
export const MY_STORE_SETSELF_USERSTATE = 'MY_STORE_SETSELF_USERSTATE';

//saga related
export const MY_AUTHSAGA_REGISTER_RQ = 'MY_AUTHSAGA_REGISTER_RQ';
export const MY_AUTHSAGA_REGISTERCONFIRM_RQ = 'MY_AUTHSAGA_REGISTERCONFIRM_RQ';
export const MY_AUTHSAGA_RESETPASS_RQ = 'MY_AUTHSAGA_RESETPASS_RQ';
export const MY_AUTHSAGA_LOADSELF_RQ = 'MY_AUTHSAGA_LOADSELF_RQ';
export const MY_AUTHSAGA_SAVESELF_OPERATOR_RQ = 'MY_AUTHSAGA_SAVESELF_OPERATOR_RQ';
export const MY_AUTHSAGA_SAVESELF_GOV_RQ = 'MY_AUTHSAGA_SAVESELF_GOV_RQ';
export const MY_AUTHSAGA_NEWPASS_RQ = 'MY_AUTHSAGA_NEWPASS_RQ';
export const MY_AUTHSAGA_NEWPHONE_RQ = 'MY_AUTHSAGA_NEWPHONE';
export const MY_AUTHSAGA_PHONECODEVERIFY_RQ = 'MY_AUTHSAGA_PHONECODEVERIFY_RQ';
export const MY_AUTHSAGA_NICKNAME_RQ = 'MY_AUTHSAGA_NICKNAME_RQ';

export const MY_AUTH_REGISTER_RQ = 'MY_AUTH_REGISTER_RQ';
export const MY_AUTH_RESETPASS_RQ = 'MY_AUTH_RESETPASS_RQ';
export const MY_AUTH_NEWPASS_RQ = 'MY_AUTH_NEWPASS_RQ';
export const MY_AUTH_LOADSELF_RQ = 'MY_AUTH_LOADSELF_RQ';  //hmm
export const MY_AUTH_SAVESELF_RQ = 'MY_AUTH_SAVESELF_RQ';
export const MY_AUTH_REGISTERCONFIRM_RQ = 'MY_AUTH_REGISTERCONFIRM_RQ';
export const MY_AUTH_NEWPHONE_RQ = 'MY_AUTH_NEWPHONE';
export const MY_AUTH_PHONECODEVERIFY_RQ = 'MY_AUTH_PHONECODEVERIFY_RQ';
export const MY_AUTH_NICKNAME_RQ = 'MY_AUTH_NICKNAME_RQ';
export const MY_AUTH_ADDNICKNAME_RQ = 'MY_AUTH_ADDNICKNAME_RQ';