import {defaultViewportData} from "../../ConfigurableOpts";
import {defaultMenuStore} from "../../Components/_MyMenus";


//have to create new object
export const initializeLayersObject = () => {
    return {
        icos: [],
        polys: [],
        points: [],
        paths: [],
        texts : [],
        cursor : [],
    }
};

export const AtcMapStateConstructor = () => {
    return {

        tick: null,
        __redrawnTS: 0,
        menuStore: defaultMenuStore, //hmm
        showDrawToolbar: false, //hmm
        draggable: true, //hmm
        redrawPolysTimestamp: 0,
        bBox: null,
        ...initializeLayersObject()
    };
};

export const EditorMapStateConstructor = () => {
    return {

        tick: null,


        menuStore: defaultMenuStore, //hmm
        showDrawToolbar:false, //hmm
        draggable: true, //hmm
        minMax:[0,0],
        ...initializeLayersObject()

    };
};

export const LiveMapStateConstructor = () => {
    return {

        tick:null,

        menuStore: defaultMenuStore, //hmm
        showDrawToolbar:false, //hmm
        draggable: true, //hmm
        ...initializeLayersObject()

    };
};

export const ViewerMapStateConstructor = () => {
    return {

        tick: null,

        //menuStore: defaultMenuStore, //hmm
        showDrawToolbar:false, //hmm
        draggable: true, //hmm
        ...initializeLayersObject()

    };
};
