
import get from 'lodash.get';

import AdsbSources from "../../../LogicV1Redux/oldLogic/RtdmSources";
import {getFlights as getADSBExchangeFlights} from "../../../LogicV1Redux/oldLogic/LiveAdsbExchangeList";


import {aircraft2ico, checkinStatus2ico, functionalColors, ICONS} from "./commonInits";


export const LIGHT_SETTINGS = {
    lightsPosition: [20, 50, 1000000],
    ambientRatio: 0.4,
    diffuseRatio: 0.6,
    specularRatio: 0,
    lightsStrength: [1.2, 0.0],
    numberOfLights: 1
};

export const LIGHT_SETTINGS_WIRE = {
    lightsPosition: [20, 50, 1000000],
    ambientRatio: 1,
    diffuseRatio: 1,
    specularRatio: 1,
    lightsStrength: [10, 0.0],
    numberOfLights: 1
};

/*
useful link, but atlasing/mapping made manually now
https://www.leshylabs.com/apps/sstool/
*/

export const ICON_MAPPING = {

    [ICONS.BLUE_DRONE_ICO]: {x: 0, y: 0, width: 88, height: 88, mask: false},
    [ICONS.GREEN_DRONE_ICO]: {x: 88, y: 0, width: 88, height: 88, mask: false},
    [ICONS.GREEN2_DRONE_ICO]: {x: 176, y: 0, width: 88, height: 88, mask: false},
    [ICONS.GREY_DRONE_ICO]: {x: 264, y: 0, width: 88, height: 88, mask: false},
    [ICONS.ORANGE_DRONE_ICO]: {x: 352, y: 0, width: 88, height: 88, mask: false},
    [ICONS.ORANGE2_DRONE_ICO]: {x: 440, y: 0, width: 88, height: 88, mask: false},
    [ICONS.PURPLE_DRONE_ICO]: {x: 528, y: 0, width: 88, height: 88, mask: false},
    [ICONS.RED_DRONE_ICO]: {x: 616, y: 0, width: 88, height: 88, mask: false},
    [ICONS.YELLOW_DRONE_ICO]: {x: 704, y: 0, width: 88, height: 88, mask: false},
    [ICONS.MAGENTA_DRONE_ICO]: {x: 792, y: 0, width: 88, height: 88, mask: false},
    [ICONS.SALMON_DRONE_ICO]: {x: 880, y: 0, width: 88, height: 88, mask: false},
    [ICONS.CYAN_DRONE_ICO]: {x: 616, y: 176, width: 88, height: 88, mask: false},
    [ICONS.WHITE_DRONE_ICO]: {x: 704, y: 176, width: 88, height: 88, mask: false},
    [ICONS.WHITE2_DRONE_ICO]: {x: 792, y: 176, width: 88, height: 88, mask: false},


    [ICONS.BLUE_DRONE_SELECTED_ICO]: {x: 0, y: 88, width: 88, height: 88, mask: false},
    [ICONS.GREEN_DRONE_SELECTED_ICO]: {x: 88, y: 88, width: 88, height: 88, mask: false},
    [ICONS.GREEN2_DRONE_SELECTED_ICO]: {x: 176, y: 88, width: 88, height: 88, mask: false},
    [ICONS.GREY_DRONE_SELECTED_ICO]: {x: 264, y: 88, width: 88, height: 88, mask: false},
    [ICONS.ORANGE_DRONE_SELECTED_ICO]: {x: 352, y: 88, width: 88, height: 88, mask: false},
    [ICONS.ORANGE2_DRONE_SELECTED_ICO]: {x: 440, y: 88, width: 88, height: 88, mask: false},
    [ICONS.PURPLE_DRONE_SELECTED_ICO]: {x: 528, y: 88, width: 88, height: 88, mask: false},
    [ICONS.RED_DRONE_SELECTED_ICO]: {x: 616, y: 88, width: 88, height: 88, mask: false},
    [ICONS.YELLOW_DRONE_SELECTED_ICO]: {x: 704, y: 88, width: 88, height: 88, mask: false},
    [ICONS.MAGENTA_DRONE_SELECTED_ICO]: {x: 792, y: 88, width: 88, height: 88, mask: false},
    [ICONS.SALMON_DRONE_SELECTED_ICO]: {x: 880, y: 88, width: 88, height: 88, mask: false},
    [ICONS.CYAN_DRONE_SELECTED_ICO]: {x: 616, y: 264, width: 88, height: 88, mask: false},
    [ICONS.WHITE_DRONE_SELECTED_ICO]: {x: 704, y: 264, width: 88, height: 88, mask: false},
    [ICONS.WHITE2_DRONE_SELECTED_ICO]: {x: 792, y: 264, width: 88, height: 88, mask: false},


    [ICONS.HELI_BW]: {x: 0, y: 176, width: 88, height: 88, mask: false},
    [ICONS.HELI_GRAY]: {x: 0, y: 264, width: 88, height: 88, mask: false},
    [ICONS.HELI_RED]: {x: 88, y: 264, width: 88, height: 88, mask: false},
    [ICONS.HELI_YELLOW]: {x: 88, y: 176, width: 88, height: 88, mask: false},

    [ICONS.PLANE_BW]: {x: 176, y: 176, width: 88, height: 88, mask: false},
    [ICONS.PLANE_GRAY]: {x: 176, y: 264, width: 88, height: 88, mask: false},
    [ICONS.PLANE_RED]: {y: 264, x: 264, width: 88, height: 88, mask: false},
    [ICONS.PLANE_YELLOW]: {x: 264, y: 176, width: 88, height: 88, mask: false},

    [ICONS.UFO_BW]: {x: 352, y: 176, width: 88, height: 88, mask: false},
    [ICONS.UFO_GRAY]: {x: 352, y: 264, width: 88, height: 88, mask: false},
    [ICONS.UFO_RED]: {x: 440, y: 264, width: 88, height: 88, mask: false},
    [ICONS.UFO_YELLOW]: {x: 440, y: 176, width: 88, height: 88, mask: false},

    [ICONS.LOCATION_HOME]: {x: 264, y: 352, width: 88, height: 88, mask: false, anchorY:76},
    [ICONS.LOCATION_PROBE]: {x: 176, y: 352, width: 88, height: 88, mask: false, anchorY:76},
    [ICONS.BLUE_SQUARE] :  {x: 88, y: 352, width: 88, height: 88, mask: false},
    [ICONS.BLUE_CIRCLE] :  {x: 0, y: 352, width: 88, height: 88, mask: false},

    [ICONS.BLUE_HOSPITAL_ICO]: {x: 528, y: 176, width: 88, height: 88, mask: false},
    [ICONS.BLUE_HOSPITAL_SELECTED_ICO]: {x: 528, y: 264, width: 88, height: 88, mask: false},


};


//hmm, for deck gl ico layer(s)
export const ICO_SIZES = {
    CHECKIN : 64,
    LPR_HELIPAD : 32,
};

let sampleIco = {position: [ 21.03, 52.23, 5000], icon: ICONS.PLANE_BW, size: 64};

let sampleSolidPoly = {
    polygon:
        [
            [
                [
                    20,
                    52
                ],
                [
                    21,
                    52
                ],
                [
                    20,
                    52
                ],
            ]
        ],
    fillColor: functionalColors.newPrimitive,
    elevation: 1000

};

let samplePoint =  {position: [21.15, 52.23], normal: [-1, 0, 0], color: [255, 0, 255]};

let samplePath = {
    path: [
        [
            20,
            52,
            0
        ],
        [
            21,
            52,
            400
        ],
    ],
    width: 1,
    color: functionalColors.newPrimitive
};







export function invalidateZones() {
    console.error('invalidateZones removed');
    //zonesPrimitives = [];
    //zonesV1Polys = [];
}

export function invalidateAlerts() {
    console.error('invalidateAlerts removed');
    //alertsPrimitives = [];
}

//huh. optimaliation is connected to context, so function to remove
export function processRedraws(bottomCutoff, topCutoff) {

    //TODO definitevely fixme for optimization
    //clean previous data... EXCEPT zones
    console.log(bottomCutoff, topCutoff)


    //console.log('processRedraws pre', polys );

    //polys = processZonesV2(bottomCutoff, topCutoff);
    //processCheckinsV1();
    //processAlertsV1();
    //processAdsbExchangeTicks();
    //processADSBExchange();
    //processADSBCreotechTicks();
    //console.log(polys);
    //console.log('processRedraws post', polys );

}

//let icos = [];
//let polys = [];
//let points = [];

//let paths = testPath;//[];


export default {
    LIGHT_SETTINGS,
    LIGHT_SETTINGS_WIRE,
    ICON_MAPPING,

    //processZonesV2,

    //processAdsbExchangeTicks,
    //processADSBCreotechTicks,

}
