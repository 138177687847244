import {Deck} from '@deck.gl/core';


//todo.. possible optimalization onhover? (checks continusly buffer, maybe only on some timeout?)
export default class MyDeck extends Deck {
    //minor change -> passing back HAMMER event not original
    _pickAndCallback(options) {
        const pos = options.event.offsetCenter;
        // Do not trigger callbacks when click/hover position is invalid. Doing so will cause a
        // assertion error when attempting to unproject the position.
        if (!pos) {
            return;
        }

        const radius = this.props.pickingRadius;

        console.log(options.mode);
        const selectedInfos = this.layerManager.pickObject({
            x: pos.x,
            y: pos.y,
            radius,
            //layerIds,
            viewports: this.getViewports(pos),
            mode: options.mode,
            depth: (options.mode === 'click')?100:1, //hmm, default 10
        });

        // const selectedInfos = this.layerManager.pickObject({
        //     x: pos.x,
        //     y: pos.y,
        //     radius,
        //     viewports: this.getViewports(pos),
        //     mode: options.mode,
        //     depth: 1
        // });
        if (options.callback && selectedInfos) {
            const firstInfo = selectedInfos.find(info => info.index >= 0) || null;
            // As per documentation, send null value when no valid object is picked.

            //was
            //options.callback(firstInfo, selectedInfos, options.event.srcEvent);

            //fix here
            options.callback(firstInfo, selectedInfos, options.event);
        }
    }
}
