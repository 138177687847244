import React from 'react'
import Card from "@material-ui/core/Card/Card";
import CardContent from "@material-ui/core/CardContent/CardContent";
import CardActions from "@material-ui/core/CardActions/CardActions";
import connect from "react-redux/es/connect/connect";
import {withStyles} from "@material-ui/core";
import {translate} from 'ra-core'
import compose from 'recompose/compose';
import StoreProvider from "../../MyStoreProvider";
import {toggleAboutView} from "../../logic/settings/MySettingsActions";
import Dialog from "@material-ui/core/Dialog/Dialog";
import Typography from "@material-ui/core/Typography/Typography";
import {version} from "../../../Configs";

const styles = theme => ({
    settingsCard: {
        margin: "0",
        borderRadius: "6px",
        '@media screen and (max-height: 919px)': {
            minHeight: "unset"
        },
        display: "flex",
        flexDirection: "column",
        backgroundImage: `linear-gradient(to top, ${theme.palette.secondary.dark}, ${theme.palette.secondary.light})`,
    },
    cardHeader: {
        backgroundColor: theme.palette.primary.main,
        borderRadius: "4px",
    },
    cardContent: {
        overflowY: "hidden",
        display: "flex",
        flexDirection: "column",
    },
    cardParagraph: {
        marginBottom: "1em",
        width: "100%",
    },
    paper: {
        '@media screen and (min-height: 920px)': {
            overflowY: "inherit",
            maxHeight: "unset",
        },
        borderRadius: "6px",
        margin: "14vw",
    },
    logosArea: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        margin: "5vh 0"
    },
    logosAreaBottom: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center"
    },
    logoImg: {
        // width: "80%",
        height: "30vh"
    },
    logoImg2: {
        width: "20%",
        height: "20%"
    },
    text: {
        color: theme.palette.colorCircle.white
    }

});

class MyAboutView extends React.Component {

    constructor(props) {
        super(props);
    }

    _handleClose = () => {
        const store = StoreProvider.getStore();
        store.dispatch(toggleAboutView({show: false}));
    };

    render() {

        const {classes, aboutView, translate} = this.props;
        const {app, builtOn, devBuild, loopbackVersion} =  this.props.myAppExtras;


        const lbVer = (loopbackVersion && loopbackVersion.tag)?loopbackVersion.tag:'build_101x';

        return (
            <Dialog
                open={aboutView.visible}
                onClose={this._handleClose}
                classes={{
                    paper: classes.paper
                }}
            >
                <Card className={classes.settingsCard}>
                    <CardContent className={classes.cardContent}>
                        <div className={classes.cardParagraph}>
                            <div className={classes.logosArea}>
                                <img src="./pansautm_logo.png" className={classes.logoImg}/>
                                <div className={classes.logosAreaBottom}>
                                    <Typography className={classes.text} variant="caption">
                                        Powered by
                                    </Typography>
                                    <img src="./img/hawke.png" className={classes.logoImg2}/>
                                </div>
                            </div>
                            <Typography className={classes.text} variant="body2">
                                PansaUTM {version}
                            </Typography>
                            <Typography className={classes.text} variant="body1">
                                {app.name} App: {builtOn}
                            </Typography>
                            <Typography className={classes.text} variant="body1">
                                Backend: {lbVer}
                            </Typography>
                            <br/>
                            <Typography className={classes.text} variant="body2">
                                Licences
                            </Typography>
                            <Typography className={classes.text} variant="body1">
                                &copy;All rights reserved. Any information, materials, articles, graphic elements contained in the PansaUTM are owned by Hawk-e and/or Polish Air Navigation Services Agency and/or other indicated owners and may not be used for commercial purposes.
                                Any modification, reproduction or domain processing is prohibited.
                                <br/>Full End-User Licence-Agreement (EULA) avalaible in settings section.
                            </Typography>
                            <br/>
                            <br/>
                            <Typography className={classes.text} variant="button">
                                {'enjoy your flight safely'.toUpperCase()}
                            </Typography>
                            <Typography className={classes.text} variant="caption">
                                Copyright &reg;2018-2020 PANSA, Hawke, Droneradar sp. z o.o.
                            </Typography>

                        </div>
                    </CardContent>
                    <CardActions>
                    </CardActions>
                </Card>
            </Dialog>
        )
    }
}


const mapStateToProps = state => {
    return {
        aboutView: state.mySettings.aboutView,
        myAppExtras: state.myAppExtras
    }
};


export default compose(
    translate,
    connect(
        mapStateToProps
    ),
    withStyles(styles)
)(MyAboutView);
