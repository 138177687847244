import {BaseModule, ErrorMsgs} from '../common/'
import {code2val as c2v, val2code as v2c} from "./ThreeLightsConverters";
import { WeightClassesEnums } from "../../LogicV1Redux/constants/CheckinsV2Enums";
import { THREE_LIGHTS } from "./ThreeLightsEnums";

const ERR = ErrorMsgs.THREE_LIGHTS;


//ATZ, CTR
//<600gr <=100m GREEN
//>600gr <25kg <=100m GREEN
//>25kg YELLOW
function weightCheckAlfa (weightClass, maxAGL) {
    const heightCheck = (maxAGL <= 100);
    const weightCheck = (weightClass === WeightClassesEnums.LIGHT)
        || (weightClass === WeightClassesEnums.MEDIUM);
    if (heightCheck && weightCheck) {
        return THREE_LIGHTS.GREEN;
    }
    return THREE_LIGHTS.YELLOW;
}

//ATZ6KM, CTR6KM
//<600gr <=30m GREEN
//>600gr <25kg YELLOW
//>25kg YELLOW
function weightCheckBeta (weightClass, maxAGL) {
    const heightCheck = (maxAGL <= 30);
    const weightCheck = (weightClass === WeightClassesEnums.LIGHT);
    if (heightCheck && weightCheck) {
        return THREE_LIGHTS.GREEN;
    }
    return THREE_LIGHTS.YELLOW;
}

//EPP (WEIGHT CHECK GAMMA)
// <600gr <=30m GREEN
// <600gr >30m RED
// > 600gr RED
function weightCheckGamma (weightClass, maxAGL) {
    const heightCheck = (maxAGL <= 30);
    const weightCheck = (weightClass === WeightClassesEnums.LIGHT);
    if (heightCheck && weightCheck) {
        return THREE_LIGHTS.GREEN;
    }
    return THREE_LIGHTS.RED;
}

const weightCheckProcessors = [null, weightCheckAlfa, weightCheckBeta, weightCheckGamma];

//fixme add coder/decoder
export default class ThreeLightsProcessor extends BaseModule {

    constructor(...args) {
        super(...args);
        this._moduleId = 'ThreeLightsProcessor';
    }
    calculate(zoneInspectDatum, calcParams) {


        //console.warn('ThreeLightsProcessor', zoneInspectData, weightClass)

        const {__zoneRules, __inactive, name} = zoneInspectDatum;
        const {weightClass, aglMinMax} = calcParams;
        console.log('ThreeLightsProcessor', calcParams);

        let val;
        if (__inactive) {
            val = __zoneRules['status_inactive'];
        } else {
            const weightCheckProcessorIdx = __zoneRules['weight_check'];
            if (weightCheckProcessorIdx) {
                val = weightCheckProcessors[weightCheckProcessorIdx](weightClass, aglMinMax[1])
            } else {
                val = __zoneRules['status_active'];
            }
        }

        return val;
    }

    static code2val = c2v;
    static val2code = v2c;

}
