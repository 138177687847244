import {turfCircle, turfDistance} from '../oldCommon/commonTurf.js'
import {endDraw} from "./deckEditor";

import PubSub from "pubsub-js";
import {PubSubSignals} from "../../../LogicV1Redux/constants/PubSubSignals";

//pure functions
//no z-axis at the moment, so keeping clear
export function onMeasureDrawClick(deckMeasure, latLng, layerClickEvent) {
    // sprint fixme some better dirty setup needed
    PubSub.publish(PubSubSignals.SET_DRAW_EDITOR_DIRTY);

    if (!deckMeasure) return;

    const start = deckMeasure.helpers[0];
    deckMeasure = deckMeasure.recalc(start, latLng);
    if (deckMeasure.distance !== 0) {//second click
        console.log('ending measure');
        endDraw(deckMeasure);
    }

    return deckMeasure;
}

export function onMeasureDrawMouseMove(deckMeasure, latLng) {
    if (deckMeasure) {
        const start = deckMeasure.helpers[0];
        deckMeasure = deckMeasure.recalc(start, latLng);
        return deckMeasure
    }
}
