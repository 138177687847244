//dirty auth for pandora show
//using old string query.. damn..
import { parseUrl, stringify } from 'query-string';
import {decrypt} from '../../rt_map_ra2/SimpleCrypt'
import {urls} from "../../Configs";
import axios from "axios";
import StoreProvider from "../../dr_ra2/MyStoreProvider";

import {
    showNotification,
    hideNotification,
} from 'react-admin';

export const AUTH_HEADER_V1 = 'x-query-auth';

//let currentUser;

//buu, fixme with correct state flow
export function getPandoraUser() {
    return null; //no longer used, to clean up;
}

export function getPandoraAuth() {
    return null; //no longer used, to clean up;
}


//FIXME!!! aaargh, mixing two auths. to have working asap
export function getV2AuthParams() {
    const authV1 = getAuthFromUrl();
    return {"username": authV1.user, "password": authV1.pass};
}

export function getAuthFromUrl() {
    const parsed = parseUrl(window.location.href);
    console.warn('====== authv1', window.location.href, parsed);
    const {user, pass, c} = parsed.query;
    if (user && pass)
        return {user, pass};
    if (c) {
        const decoded = decrypt(decodeURIComponent(c), true);
        console.warn(decoded);
        return decoded;
    }
    else
        return null;
}


export function getAuthHeaderV1(opts) {
    if (!opts)
        console.error('====== missing opts!!!');

    return {
        [AUTH_HEADER_V1]: JSON.stringify(opts)
    };
}

export default {
    getAuthFromUrl,
    getAuthHeaderV1,
    getV2AuthParams,
    getPandoraUser,
}
