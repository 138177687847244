import PubSub from "pubsub-js";
import {PubSubSignals} from "../../../LogicV1Redux/constants/PubSubSignals";


const EDIT_MODE = {
    SELECT : 'SELECT',
    MOVE_LL_A:'LATLONG_A', //center
    MOVE_LL_B:'LATLONG_B', //and hmmm
    MOVE_MEASURE:'MOVE'
};
//let editableCircle = null;
let editMode = EDIT_MODE.SELECT;
let moveLatLng = null;

//no z-axis at the moment, so keeping clear
export function onMeasureEditClick(deckMeasure, latLng, layerClickEvent) {

    const lceObject = (layerClickEvent && layerClickEvent.object)?layerClickEvent.object:null;

    //editableCircle = deckCircle;
    if (editMode === EDIT_MODE.SELECT) {
        if (lceObject === deckMeasure.markers[1]) {
            console.log('latB measure clicked');
            editMode = EDIT_MODE.MOVE_LL_B;
            return deckMeasure; //probably some styling later..

        } else if (lceObject  === deckMeasure.markers[0]) {
            console.log('center circle clicked');
            editMode = EDIT_MODE.MOVE_LL_A;
            return deckMeasure; //probably some styling later..

        } else if (lceObject === deckMeasure) {

            editMode = EDIT_MODE.MOVE_CIRCLE;
            moveLatLng = latLng;
            return deckMeasure; //probably some styling later..

        }
        return null; //deselect
    }
    else if (editMode === EDIT_MODE.MOVE_LL_A) {
        editMode = EDIT_MODE.SELECT;
        return deckMeasure.recalc(latLng, deckMeasure.helpers[1]); //aargh . fixme
    }
    else if (editMode === EDIT_MODE.MOVE_LL_B) {
        editMode = EDIT_MODE.SELECT;
        return deckMeasure.recalc(deckMeasure.helpers[0], latLng);
    }
    else if (editMode === EDIT_MODE.MOVE_MEASURE) {
        editMode = EDIT_MODE.SELECT
    }
    return deckMeasure;
}

export function onMeasureEditMouseMove(deckMeasure, latLng) {
    if (editMode === EDIT_MODE.MOVE_LL_A) {

        // sprint fixme some better dirty setup needed
        PubSub.publish(PubSubSignals.SET_DRAW_EDITOR_DIRTY);

        return deckMeasure.recalc(latLng, deckMeasure.helpers[1]); //aargh . fixme
    }
    else if (editMode === EDIT_MODE.MOVE_LL_B) {

        // sprint fixme some better dirty setup needed
        PubSub.publish(PubSubSignals.SET_DRAW_EDITOR_DIRTY);

        return deckMeasure.recalc(deckMeasure.helpers[0], latLng);
    }
    else if (editMode === EDIT_MODE.MOVE_MEASURE) {

        let latlngs = deckMeasure.helpers;

        let offset = [];
        offset[0] = latLng[0] - moveLatLng[0];
        offset[1] = latLng[1] - moveLatLng[1];
        for (let i = 0; i < latlngs.length; i++) {
            latlngs[i][0] += offset[0];
            latlngs[i][1] += offset[1];
        }
        moveLatLng = latLng;

        // sprint fixme some better dirty setup needed
        PubSub.publish(PubSubSignals.SET_DRAW_EDITOR_DIRTY);

        return deckMeasure.recalc(deckMeasure.helpers[0], deckMeasure.helpers[1]);
    }
    return null;

}
