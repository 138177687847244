import IconButton from "@material-ui/core/IconButton/IconButton";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import React from "react";
import {withStyles} from "@material-ui/core";
import {TooltipProxy as Tooltip} from '../TooltipProxy';
import TranslatableTypography from "../TranslatableTypography";
import Typography from "@material-ui/core/Typography/Typography";

const styles = {
    action: {
        flexDirection: "row",
        justifyContent: "space-between",
        padding: "0",
        display: "flex"
    },
    right: {
        display: "flex",
        alignItems: "center",
    },
    pagination: {
        position: "fixed",
        right: "4rem"
    }

};

export const InfoBoxActions = withStyles(styles)(
    ({
         handlePrevious,
         handleNext,
         previousDisabled,
         nextDisabled,
         handleSeen,
         seenDisabled,
         classes,
         pageStatus,
         hideSeen
     }) => {
    return (
        <div className={classes.action}>

            <Tooltip content='ra.navigation.prev'>
                {/*div needed for showing tooltip when disabled*/}
                <div>
                    <IconButton disabled={previousDisabled}
                                onClick={handlePrevious}>
                        <ChevronLeftIcon/>
                    </IconButton>
                </div>
            </Tooltip>

            {!hideSeen &&
                <Tooltip content='myroot.action.notShowAgain'>
                    <div>
                        <IconButton disabled={seenDisabled}
                                    onClick={handleSeen}>
                            <VisibilityOffIcon/>
                        </IconButton>
                    </div>
                </Tooltip>
            }

            <div className={classes.right}>
                {pageStatus &&
                    <Typography className={classes.pagination}>
                        {`${pageStatus[0]}/${pageStatus[1]}`}
                    </Typography>
                }
            <Tooltip content='ra.navigation.next'>
                <div>
                    <IconButton disabled={nextDisabled}
                                onClick={handleNext}>
                        <ChevronRightIcon/>
                    </IconButton>
                </div>
            </Tooltip>
            </div>

        </div>
    )}
);
