import React, {Component} from 'react';

import {DeckGL, IconLayer, PathLayer, PointCloudLayer, PolygonLayer, TextLayer} from "deck.gl";
import {WebMercatorViewport} from "deck.gl";
import {COORDINATE_SYSTEM, View, MapView, FirstPersonView, OrbitView, MapController} from 'deck.gl';
import {StaticMap, InteractiveMap} from 'react-map-gl';

import PolygonLayerExtended from '../glSetups/deckExtends/PolygonLayerExtended62';

import IconLayerExtended from '../glSetups/deckExtends/IconLayerExtended62';
import {ICON_MAPPING, LIGHT_SETTINGS} from "../glSetups/oldCommon/deckInits";
import {
    getDrawMarkers, getDrawPrimitives, getDrawPaths, getDrawCursor, updateDrawLayers, getDrawTexts, //isDrawSelectModeEnabled, isDeleteModeEnabled
} from '../glSetups/deckDraw/deckEditor';
import { DRAW_MODES} from '../glSetups/deckDraw/DrawEditorEnums'

import PubSub from 'pubsub-js';
import {connect} from "react-redux";
import compose from 'recompose/compose';

import {PubSubSignals} from '../../LogicV1Redux/constants/PubSubSignals'
import {storeInspectData} from "../../LogicV1Redux/actions/InspectActions";
import {InspectTypes} from "../../modules2lib/common/";

import {functionalColors, standardColors} from "../glSetups/oldCommon/commonInits";

import {defaultViewportData, mapboxOpts, myAppDefaults} from "../ConfigurableOpts";
import MyDeck from "../glSetups/oldCommon/MyDeck";
import MyController from "../glSetups/oldCommon/MyController";

//import {requestZonesV2} from "../../LogicV1Redux/oldLogic/ZonesListV2"
//import {getDrawMode} from "../glSetups/deckDraw/deckDrawStoreBridge";
import {handleMouseMove as handleEditorMouseMove, handleClick as handleEditorClick} from "../glSetups/deckDraw/deckDrawUIEvents";

import sizeMe from "react-sizeme";

import "../simplified.css";
import {fitBounds} from 'viewport-mercator-project';
import get from 'lodash.get';
//forced redraw used
//https://stackoverflow.com/questions/30626030/can-you-force-a-react-component-to-rerender-without-calling-setstate

let lastLayerClickEvent = null;

//assuming hammer event

export function localEventCoordsConvert (e, viewport) {
    //fixme - cleanup code after checkin if pandora AND touchable devices finally works (seems ok, but..)
    //try {
        //const bounds = e.target.getBoundingClientRect();
        //console.log('bounds',bounds, e.clientX, e.clientY);

        //const x = e.clientX - bounds.x;
        //const y = e.clientY - bounds.y;

        const pos = e.offsetCenter;

        if (!(pos && viewport)) return null;

        return viewport.unproject([pos.x, pos.y]);

    // } catch (e) {
    //     //LGZ -> TODO some pandora issue on mouse move. not logging, as this is onmousemove. to investigate later
    //     return null;
    // }
    //
    // //console.log(e);
    // try {
    //     const {x, y} = localEventCoords(e);
    //     let LatLng = (this.viewport) ? this.viewport.unproject([x, y]) : null;
    //     //const clientXY = {x:e.clientX, y:e.clientY};
    //     //console.log('mousemove', clientXY, LatLng);
    //     if (!LatLng) return;
    //     LatLng = fixLatLngPrecision(LatLng);
    //     const doRedraw = handleDrawMouseMove(LatLng[0], LatLng[1]);
    //     if (doRedraw)
    //         this.forceUpdate();
    // } catch (e) {
    //     //LGZ -> TODO some pandora issue on mouse move. not logging, as this is onmousemove. to investigate later
    //     return;
    // }
}

const VIEW_LABEL_STYLES = {
    zIndex: 10,
    //position: 'relative',
    padding: 5,
    margin: 20,
    fontSize: 12,
    backgroundColor: '#282727',
    color: '#FFFFFF'
};

const ViewportLabel = props => (
    <div style={{position: 'absolute', top:0}}>
        <div style={{...VIEW_LABEL_STYLES, display: ''}}>{props.children}</div>
    </div>
);


function fixProjection(viewportData) {
    //pitch/roll locked! (for now)
    if (!process.env.REACT_APP_ENABLE_3D) {
        viewportData.bearing = 0;
        viewportData.pitch = 0;
    }

    viewportData._mercator = new WebMercatorViewport({
        width:viewportData.width,
        height:viewportData.height,
        latitude:viewportData.latitude,
        longitude : viewportData.longitude,
        zoom: viewportData.zoom,
        pitch: viewportData.pitch,
        //bearing: 0,
        bearing: viewportData.bearing,
        altitude: viewportData.altitude

    });

    return viewportData;
}

//100 for always visible
function createTextLayer(key, opts, currZoom = 100) {
    const zoomThreshold = opts.zoomThreshold || 9.5;
    return new TextLayer({
        id: key,
        data: opts.data,
        //pickable: true,
        getPosition: d => d.centroid,
        getText: d => d.displayName,
        getSize: 24,
        getAngle: 0,
        getTextAnchor: opts.getTextAnchor || 'middle',
        getAlignmentBaseline: opts.getAlignmentBaseline || 'center',
        getPixelOffset: opts.getPixelOffset || [0, 0],
        visible: (currZoom > zoomThreshold),
        //dataComparator: () => {return false}
        //onHover: ({object}) => setTooltip(`${object.name}\n${object.address}`)
    });
}


export function fitViewport2Bbox (viewportData, bBox) {
    if (!bBox) return viewportData;
    const oldViewport = viewportData;
    // const bboxviewport = new WebMercatorViewport({longitude:0, latitude:0, zoom:10, pitch:0, bearing:0});

    console.log('mmf viewportData', viewportData);

    let newViewport = fitBounds({
        width:oldViewport.width || 500, //ugh, animations
        height:oldViewport.height || 500, //ugh, animations
        bounds:[[bBox.west, bBox.south], [bBox.east, bBox.north]],
        padding: 50,
        //offset: [0, 0]
    });
    console.log('===bounded newViewport', Object.assign({}, newViewport));
    //fix zoom and pitch;
    newViewport.zoom -= 1;
    //newViewport.pitch = 30;

    newViewport = fixProjection({...viewportData, ...newViewport});
    console.log('===fixed newViewport', Object.assign({}, newViewport));

    return newViewport;
}


class DeckGLOverlay extends Component {

    constructor(props) {
        super(props);
        this.handleLayersClick = this.handleLayersClick.bind(this);
        this.handleLayerHover = this.handleLayerHover.bind(this);

        this.handleMouseMove = this.handleMouseMove.bind(this);
        this.handleMouseClick = this.handleMouseClick.bind(this);
        this.handleMouseDown = this.handleMouseDown.bind(this);

        this.verticalScale = 1;

        const initialViewportData = {
            ...defaultViewportData,
            ...this.props.viewportOverride,
        };

        this.state = {
            viewportData: {
                ...initialViewportData,
                width: 500,
                height: 500,
            },
            viewportOverride:this.props.viewportOverride
        };

        //console.warn('(duplicated) requestin requestZonesV2, was here (removed).. refactoring now');
        //requestZonesV2();

        //console.error('test removing handleLayerHover');
        //console.error('test removing handleLayersClick');

    }


    //fixme! deprecated lifecycle
    componentWillReceiveProps(nextProps) {
        //console.log('DeckGLOverlay componentWillReceiveProps', nextProps);
        if (this.props !== nextProps) {
            //console.log('new props received', this.state);
            let prevViewport = this.state.viewportData;
            prevViewport.width = (nextProps.size)?nextProps.size.width:prevViewport.width;
            prevViewport.height = (nextProps.size)?nextProps.size.height:prevViewport.height;

            //for old atc user defaults
            if (this.props.viewportOverride !== nextProps.viewportOverride) {
                prevViewport = {...prevViewport, ...nextProps.viewportOverride};
                this.setState({viewportOverride:nextProps.viewportOverride});
            }

            prevViewport = fixProjection(prevViewport);
            //not the same as viewportOverride, different data
            if (this.props.bBox !== nextProps.bBox) {
                prevViewport = fitViewport2Bbox(prevViewport, nextProps.bBox);
                console.log('mmf overrideViewportData', prevViewport, nextProps.bBox);
            }

            if (this.props.bBox !== nextProps.bBox) {
                prevViewport = fitViewport2Bbox(prevViewport, nextProps.bBox);
                console.log('mmf overrideViewportData', prevViewport, nextProps.bBox);
            }


            if (this.props.drawEditorTimestamp !== nextProps.drawEditorTimestamp) {
                updateDrawLayers('on props drawEditorTimestamp change...');
            }



            this.setState({viewportData:prevViewport});
        }
    }

    onViewportChange = viewportChange => {
        //console.log('atcMap, onViewportChange', viewportChange);
        this.setState({viewportData:fixProjection(viewportChange.viewState)});
    };


    componentDidMount() {

        // this.pubsubForceRedrawDeckGL = PubSub.subscribe(PubSubSignals.FORCE_REDRAW_DECK_GL, (msg, data) => {
        //     console.log('forcing deck gl redraw');
        //     this.forceUpdate();
        // });

        this.pubsubOnZoomIn = PubSub.subscribe(PubSubSignals.MAP_VIEWPORT_ZOOM_IN,
            (msg, data) => {
                const prevViewport = {...this.state.viewportData}; //hah!
                prevViewport.zoom += 1;
                console.log('zooming in');
                this.setState({viewportData:fixProjection(prevViewport)});

            }
        );

        this.pubsubOnZoomOut = PubSub.subscribe(PubSubSignals.MAP_VIEWPORT_ZOOM_OUT,
            (msg, data) => {
                const prevViewport = {...this.state.viewportData}; //hah!
                prevViewport.zoom -= 1;
                console.log('zooming out');

                this.setState({viewportData:fixProjection(prevViewport)});

            }
        );

        console.log('deckglmount, ', this.props);

        this.views = [new MapView({id: 'basemap'})];
        // hmm from example. splitting works (huh, was fun)..
        // first person view rotation on the other hand...
        // whatever - not needed anyway( .. at least for now)
        // this.views =
        //     [
        //         new FirstPersonView({id: 'first-person', height: '50%'}),
        //         new MapView({
        //             id: 'basemap',
        //             controller: MapController,
        //             y: '50%',
        //             height: '50%',
        //             //orthographic
        //         })
        //     ];

        console.log('mmf deckgl mount', this.props.bBox, this.state);
        this.setState({viewportData:fixProjection(this.state.viewportData)});

        if (this.props.bBox) {
            let prevViewport = this.state.viewportData; //fixProjection(this.state.viewportData);
            updateDrawLayers('enabling edit');
            //size me issues.
            setTimeout(
                function() {
                    //updateDrawLayers('enabling edit');

                    prevViewport = fitViewport2Bbox(prevViewport, this.props.bBox);
                    console.log('mmf overrideViewportData', prevViewport, this.props.bBox);
                    this.setState({viewportData: prevViewport});
                }
                    .bind(this),
                //100 works, but for slower comps. who knows.....
                //ok, 300 also (rarely) is too slow... damn
                //looks like extra button (home) is needed anyway
                500

            );

        } else {
            updateDrawLayers('clean up on init');
        }

    }


    componentWillUnmount() {
        PubSub.unsubscribe(this.pubsubOnZoomIn);
        PubSub.unsubscribe(this.pubsubOnZoomOut);

    };

    //unresolved issue with delay, keeping for tests
    handleMouseMove = (mouseEvent) => {
        //console.log('div mouseMove',mouseEvent.button, mouseEvent.buttons);
    };

    //unresolved issue with delay, keeping for tests
    handleMouseDown = (mouseEvent) => {
        console.warn('div mouseDown',mouseEvent.button, mouseEvent.buttons);
        //const e = Object.assign({}, mouseEvent);
        //console.warn('div mouseDown',e);

    };

    //unresolved issue with delay, keeping for tests
    handleMouseClick = (mouseEvent) => {
        //const e = Object.assign({}, mouseEvent);
        console.warn('div mouseClick', mouseEvent.button, mouseEvent.buttons);
        //console.warn('div mouseClick',e);

    };

    //using deck.gl event here ->
    handleLayerHover = (info, pickedInfos, event) => {
        const e = {info, pickedInfos, event};
        const latLng = localEventCoordsConvert(event, this.state.viewportData._mercator);
        //const clientXY = {x:e.clientX, y:e.clientY};
        //console.log('mousemovez', event);
        if (!latLng) return;
        if (event.leftButton || event.middleButton) {
            console.log("handleLayerHover, some button on")
        }
        const doRedraw = handleEditorMouseMove(latLng, event); //hmm pass more data?
        if (doRedraw)
            this.forceUpdate();

    };

    //using deck.gl event here ->
    handleLayersClick = (info, pickedInfos, event) => {

        const e = {info, pickedInfos, event};

        console.log('===========click', e);

        const latLng = localEventCoordsConvert(event, this.state.viewportData._mercator);
        if (!latLng) return;

        //todo draw check in drawable setups
        const doRedraw = handleEditorClick(latLng, info, event);

        console.log('handleLayersClick', e, latLng);

        if (doRedraw) {
            console.log('doredraw or drawlayers?');
            updateDrawLayers('fixing clicks redraw');

            this.forceUpdate();
        } else {
            if (info && info.object && info.object.inspectData) {
                console.warn('inspectable object!', info);

                //multi pick support, probably should be rewritten to support pickedInfos everywhere
                let passedData = {...info.object.inspectData, pickedInfos,
                    pickedLngLat:info.lngLat
                };

                this.props.storeInspectDataAction(passedData);

            } else if (info) {
                console.warn('no inspectable object in broadcast info!', info);
                this.props.storeInspectDataAction({InspectType:InspectTypes.NO_DATA});
            }
            else {
                console.warn('err. no info')
                this.props.storeInspectDataAction({InspectType:InspectTypes.NO_DATA});
            }
        }
    };


    render() {

        //console.log('deckGlOverlay render //props', this.props);
        const {icos, paths, points, polys, cursor, texts, textsData = {}, drawMode, useSatelliteMap, lockViewHack } = this.props;

        //const use3d = true; // at the moment code was tested for 3d true (even in rt-map) so let's keep it this way..
        const use3d = !!(process.env.REACT_APP_ENABLE_3D); //damn perspective, forcing 2d for all for now (fixme livemap)...
        //hmm object mode vs vertex mode?

        //console.log('==========drawMode', drawMode);

        const pickableDrawPolys = ((drawMode === DRAW_MODES.SELECT)
            || (drawMode === DRAW_MODES.DELETE)
            || (drawMode === DRAW_MODES.EDIT))
        ;
        const pickableDefaultPolys = (drawMode === DRAW_MODES.NO_DRAW);
        //console.log('pickable3dlayers', pickable3dLayers);
        //if is draw

        //fixme optimize
        const drawMarkers = getDrawMarkers();
        const drawPrimitives = getDrawPrimitives();
        const drawPaths = getDrawPaths();
        const drawTexts = getDrawTexts();

        const drawCursor = getDrawCursor();

        const controller =
            this.props.customController !== undefined
                ? this.props.customController
                : MyController;

        //console.log('deckglmount , controller', controller);

        //console.log('==========drawMarkers', drawMarkers);
        //console.log('polys', polys);

        const drawMarkersLayer = new IconLayer({
            id: 'drawmarkers-layer',
            data: drawMarkers,
            iconAtlas: './spritesheet.png',
            iconMapping: ICON_MAPPING,

            //getColor: d => d.color,
            getSize: d=>d.size,

            //dataComparator : this.compareTick
            // getPosition: (d) => {
            //     //console.log('fromtick');
            //     console.log('fromtick', this.state.tick);
            //     return d.position;
            // },
            pickable: true,
            //onHover: info => console.log('Hovered:', info),

        });

        //single, not pickable.
        const drawCursorLayer = new IconLayer({
            id: 'drawcursor-layer',
            data: drawCursor || cursor,
            iconAtlas: './spritesheet.png',
            iconMapping: ICON_MAPPING,
            //getColor: d => d.color,
            getSize: 20,

            pickable: false,
        });


        const drawPathLayer = new PathLayer({
            id: 'drawpaths-layer',
            data: drawPaths,
            rounded: true,
            //widthScale: 100,
            widthMinPixels : 2,
            widthMaxPixels : 5,

            pickable : false, //as for now..
            //dataComparator : this.compareTick

        });


        // flight paths to replace with line layer..
        // unfiltered data (random wrong position) really sucks with continous lines
        // (mitering/rounding in 3d)
        const pathLayer = new PathLayer({
            id: 'paths-layer',
            data: paths,
            rounded: false,
            miterLimit: 1,
            //widthScale: 100,
            widthMinPixels : 2,
            widthMaxPixels : 5,
            getColor: d => d.color,
            //dataComparator : this.compareTick
            fp64: true,
        });

        const pointCloudlayer = new PointCloudLayer({
            id: 'point-cloud-layer',
            data: points,
            radiusPixels: 10,
            pickable: true,
            getColor: d => d.color,
            //onHover: info => console.log('Hovered:', info),
        });

        let i = 0;

        //hacking display and z-error for same heights, unused now
        const fixMaxZones = (poly) => {
            let max = poly.max || 1000;
            max += i*0.03;
            i++;
            return max;
        };

        //droniada hacks
        const hackish3DCeil = (poly) => {
            console.log('hackish3DCeil', poly);
            if (process.env.REACT_APP_ENABLE_3D) {
                const max =  get(poly, 'inspectData.zoneRecord.max', 1);
                return max - process.env.REACT_APP_3D_OFFSET_HACK;
            } else {
                return 1;
            }
        };

        const hackish3DFloor = (poly) => {
            return 0; //850, //droniadaHack p.min || 0, //hmm, unfixed?
        };

        const viewportData = this.state.viewportData;

        //console.log(viewportData);

        //looks like better solution for sublayers management
        //(at last texts can be turned off per layer)
        //probably should rewrite other types as well..
        //but on the other hand... instances draw calls..
        //todo some effiecny tests...
        //...when better placement algo is implemented
        let textLayers = [];

        Object.entries(textsData).forEach(([key, value]) => {

            //console.log('textsData', key, value);

            const textLayer = createTextLayer(key, value, viewportData.zoom);

            if (textLayer) {
                textLayers.push(textLayer);
            }
        });

        const drawTextLayer = createTextLayer('draw-editor-texts',
            {
                data:drawTexts,
                //getTextAnchor:"end",
                getAlignmentBaseline:"bottom",
                //getPixelOffset:[0,0],
            });
        //console.log('textLayers', textLayers);

        let layers;
        if (use3d) {

            //const iconLayer = new IconLayer({
            const iconLayer = new IconLayerExtended({
                id: 'icon-layer',
                data: icos,
                iconAtlas: './spritesheet.png',
                iconMapping: ICON_MAPPING,

                //dataComparator : this.compareTick
                // getPosition: (d) => {
                //     //console.log('fromtick');
                //     console.log('fromtick', this.state.tick);
                //     return d.position;
                // },
                pickable: true,
                //onHover: info => console.log('Hovered:', info),
                //sizeScale : 1.0, //let it be base 1.0 (rendered smaller), fix 2d or ico size instead
                getSize: x => x.size || 500,
                getAngle: x => -x.angle || 0, //fixing CW/CCW for deckGl
                //fp64: true,

            });

            const polygon3dLayer = new PolygonLayerExtended({
            //const polygon3dLayer = new PolygonLayer({

                id: 'polygons3d-layer',
                data:polys,
                filled: true,
                stroked: false,
                extruded: true,
                wireframe: true,
                lightSettings: LIGHT_SETTINGS,
                pickable: pickableDefaultPolys,
                //onHover: info => console.log('Hovered:', info),
                //getElevation: f => f.max || 1000,
                getCeiling: hackish3DCeil,
                //getCeiling: (p) => p.max || 0,
                getFloor: (p) => hackish3DFloor,
                //elevationScale : this.verticalScale,
                //getPolygonOffset : ({layerIndex}) => [0, +layerIndex * 1000],
                getFillColor : (p) => (p && p.fillColor) ? p.fillColor : functionalColors.newPrimitive,

                getLineColor : (p) => {
                    return (p && p.lineColor)
                        ? p.lineColor
                        : standardColors.black50
                },
                //getElevation: f => Math.random() * 5000,

                getPolygon : p => {return p.polygon}
                //dataComparator : this.compareTick, replaced with new array

            });

            const drawPolygons3dLayer = new PolygonLayerExtended({
            //const drawPolygons3dLayer = new PolygonLayer({
                id: 'drawpolygons3d-layer',
                data:drawPrimitives,
                filled: true,
                stroked: false,
                extruded: true,
                wireframe: true,
                lightSettings: LIGHT_SETTINGS,
                pickable: pickableDrawPolys,
                //onHover: info => console.log('Hovered:', info),
                //getElevation: f => f.max || 1000,
                getCeiling: (p) => p.maxAGL || p.max || 0, //fixme 3d!  maxAGL = AGL meters, max = AMSL feets .. hmm
                getFloor: (p) => p.minAGL || p.min || 0, //fixme 3d! minAGL = AGL meters, min = AMSL feets .. hmm
                //elevationScale : this.verticalScale,

                getFillColor : (p) => (p && p.fillColor) ? p.fillColor : functionalColors.newPrimitive,
                getPolygon : p => {return p.polygon}


                //dataComparator : this.compareTick, replaced with new array

            });

            layers = [
                ...textLayers,

                drawCursorLayer,
                drawMarkersLayer,
                iconLayer,
                drawPathLayer,
                pathLayer,
                pointCloudlayer,
                //extrusionLayer,
                drawPolygons3dLayer,
                //drawExtrusionLayer,
                polygon3dLayer,
            ];
        } else {

            const iconLayer = new IconLayer({
                id: 'icon-layer',
                data: icos,
                iconAtlas: './spritesheet.png',
                iconMapping: ICON_MAPPING,
                getColor: d => d.color || standardColors.white,

                //dataComparator : this.compareTick
                // getPosition: (d) => {
                //     //console.log('fromtick');
                //     console.log('fromtick', this.state.tick);
                //     return d.position;
                // },
                pickable: true,
                //onHover: info => console.log('Hovered:', info),
                sizeScale : 1.5, //hack to similar size to 3d. but not exactly measured. fixme
                getSize: x => x.size || 500,
                getAngle: x => -x.angle || 0, //fixing CW/CCW for deckGl
                //fp64: true,

            });

            // //huh. to reenable? why not shown?
            // const iconLayer = new IconLayerExtended({
            //     id: 'icon-layer',
            //     data: icos,
            //     iconAtlas: './icos_full1.png',
            //     iconMapping: ICON_MAPPING,
            //     //dataComparator : this.compareTick
            //     // getPosition: (d) => {
            //     //     //console.log('fromtick');
            //     //     console.log('fromtick', this.state.tick);
            //     //     return d.position;
            //     // },
            //     pickable: true,
            //     //onHover: info => console.log('Hovered:', info),
            //     //sizeScale : 32 //
            //
            // });

            const drawPolygonsLayer = new PolygonLayer({
                id: 'drawpolygons2d-layer',
                data:drawPrimitives,
                filled: true,
                stroked: true,
                lineWidthMinPixels : 1,
                lineWidthMaxPixels : 2,
                extruded: false,
                lightSettings: LIGHT_SETTINGS,
                lineMiterLimit: 2,

                pickable: pickableDrawPolys,
                //onHover: info => console.log('Hovered:', info),
                //getElevation: f => f.max || 1000,
                // getCeiling: (p) => p.max || 0,
                // getFloor: (p) => p.min || 0,
                //elevationScale : this.verticalScale,

                getFillColor : (p) => (p && p.fillColor) ? p.fillColor : functionalColors.newPrimitive,
                getLineColor : (p) => {
                    return (p && p.lineColor)
                        ? p.lineColor
                        : standardColors.black50
                },

                getElevation: f => 1,

                getPolygon : p => {return p.polygon}
            });

            const polygonLayer = new PolygonLayer({
                id: 'polygons2d-layer',
                data:polys,
                filled: true,
                stroked: true,
                lineWidthMinPixels : 1,
                lineWidthMaxPixels : 2,
                extruded: false,
                lightSettings: LIGHT_SETTINGS,
                lineMiterLimit: 2,
                pickable: pickableDefaultPolys,

                //onHover: info => console.log('Hovered:', info),
                //getElevation: f => f.max || 1000,
                //getCeiling: fixMaxZones,
                // getCeiling: (p) => p.max || 0,
                // getFloor: (p) => p.min || 0,
                //elevationScale : this.verticalScale,
                //getPolygonOffset : ({layerIndex}) => [0, +layerIndex * 1000],
                getFillColor : (p) => (p && p.fillColor) ? p.fillColor : functionalColors.newPrimitive,

                getLineColor : (p) => {
                    return (p && p.lineColor)
                        ? p.lineColor
                        : standardColors.black50
                },
                //getElevation: f => Math.random() * 5000,

                getElevation: f => 1,

                getPolygon : p => {return p.polygon},
                getLineDashArray : p => {
                    return (p.inspectData && p.inspectData.__inactive)?[10,10]:[0, 0];
                }
            });

            layers = [

                pathLayer,
                pointCloudlayer,
                polygonLayer,

                iconLayer,

                ...textLayers,
                drawPolygonsLayer,
                drawPathLayer,
                drawMarkersLayer,
                drawCursorLayer,

                drawTextLayer
            ];
        }

        //console.log(polygonLayer);
        const mapboxApiAccessToken = mapboxOpts.accessToken;
        const mapStyle = (useSatelliteMap)?mapboxOpts.satelliteStyle:mapboxOpts.basicStyle;
        const attributionControl = false;

        //console.log('drawMarkers', drawMarkers);
        //console.log('deckgl viewportData', viewportData);

        //const onViewportChange = ()=>{console.log('cqq')};
        const {width = 500, height = 500} = (this.props.size) ? this.props.size : {};

        const dragRotate = !!process.env.REACT_APP_ENABLE_3D;

        return (
            <div className="deck-gl-window" style={{cursor:"cursor"}}
                 onMouseMove={this.handleMouseMove}
                 onClick={this.handleMouseClick}
                 onContextMenu={(e)=>  {console.log('bqq'); e.preventDefault(); return false;}}
                 onMouseDown = {this.handleMouseDown}
            >

                <DeckGL
                    // style={{backgroundColor:"red", opacity:0.5,
                    //     //position: 'relative'
                    // }}
                    Deck={MyDeck} //fixing return event
                    //initialViewState={INITIAL_VIEW_STATE}
                    viewState = {viewportData}
                    //controller={MyController}
                    controller={{type: controller, dragPan: !lockViewHack, dragRotate:dragRotate}}

                    views = {this.views}
                    width = {viewportData.width}
                    height = {viewportData.height}

                    onViewStateChange={this.onViewportChange}

                    onLayerClick = {this.handleLayersClick}
                    onLayerHover = {this.handleLayerHover}

                    layers={layers}

                    getCursor = {({isDragging}) => isDragging ? 'pointer' : 'pointer'}
                >

                    <View id="basemap"

                    >
                        <StaticMap
                            reuseMaps = {true}
                            key="map"
                            mapStyle={mapStyle}
                            mapboxApiAccessToken={mapboxApiAccessToken || 'no_token'}
                            //onClick={(e) => {console.log('click, e')}}
                            //onViewStateChange={onViewportChange}
                        />
                        {/*<ViewportLabel key="label">Map View</ViewportLabel>*/}
                    </View>

                    {/*<View id="first-person">*/}
                        {/*<ViewportLabel>First Person View (test)</ViewportLabel>*/}
                    {/*</View>*/}

                    {/*<View id="infovis">*/}
                        {/*<ViewportLabel>Orbit View (test)</ViewportLabel>*/}
                    {/*</View>*/}
                </DeckGL>

                {/*<div style={{color:"red", position:"absolute", top:0, height:10, right:0}}>My size is {width} px x {height}px</div>*/}

            </div>
        )
    }
}


const mapDispatchToProps = dispatch =>
    (
        {
            storeInspectDataAction : (e) => {
                console.log('=== dispatchin storeInspectData', e);
                dispatch(storeInspectData(e))
            }
        }
    );


//hmm to connect or not?
//really dynamic stuff
const mapStateToProps = state => {

    //console.log('mapping state to props in deckgl')
    const drawEditorData = state.drawEditorData;
    //const currentEditablePrimitive = drawEditorData.currentPrimitive;
    //const currPrimitiveCentroid = (currentEditablePrimitive)?currentEditablePrimitive.centroid:null;

    return {
        //mySelf : state.mySelf,
        //myAppExtras: state.myAppExtras,
        //inspectData: state.inspectData,
        drawEditorTimestamp : drawEditorData.storeTimestamp,
        editablePrimitives : drawEditorData.editablePrimitives,
        drawMode : drawEditorData.drawMode,
        //currentEditablePrimitive,
        //currPrimitiveCentroid, //required to update dirtiness
    }
};


const enhance = compose(
    //translate,
    //muiThemeable(), // force redraw on theme change
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
);


export default sizeMe({
    monitorHeight: true,
    noPlaceholder: true, //faster. somehow
    //refreshMode:'debounce'
})(enhance(DeckGLOverlay));
