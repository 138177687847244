import {STORE_ALL_ALERTS_V2, SET_ATC_OWNED_ALERT_V2} from "../constants/AlertsV2Constants";
import {processAlertsV2ToDeck} from "../oldLogic/AlertsV2List";
import {initializeLayersObject} from "../../gl_map/glSetups/newCommon/mapStateConstructors";
import {unixTimeNow} from "../oldLogic/TimeConverters";


const initialState = {rawData: [], deckData: initializeLayersObject(), atcZoneAlerts: [], storeTimestamp: 0};

const alertsV2Reducer = (previousState = initialState, {type, payload}) => {
    switch (type) {
        case STORE_ALL_ALERTS_V2: //data passed WITH atcOwned alert!! (see fetch)

            console.log('----- alertsReducer reducer STORE_ALL_ALERTS_V2:', type, payload);

            if (payload.rawData) {
                payload.deckData = processAlertsV2ToDeck(payload.rawData);
            }

            return {...previousState, ...payload, storeTimestamp:unixTimeNow()};


        // case SET_ATC_OWNED_ALERT_V2: //hmm unused?
        //
        //     console.error('----- alertsReducer FIXME to saga  SET_ATC_OWNED_ALERT_V2:', type, payload);
        //     return {...previousState, atcZoneAlerts: payload, storeTimestamp:unixTimeNow()};

        default:
            return previousState;
    }
};

export default alertsV2Reducer;
