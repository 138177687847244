import WelcomeScreen from "../welcomeScreens/WelcomeScreen";
import { disclaimer, operatorRodo } from "../../../docs";
import React from "react";

class RodoPopUp extends React.Component {
    render() {
        let { isOpen, closeRodo } = this.props;

        return <WelcomeScreen
            registerPage={true}
            shouldShow={true}
            isOpen={isOpen}
            record={{
                en: {
                    ...operatorRodo.en,
                    content:
                        operatorRodo.en.content +
                        `<br/><br/>` +
                        disclaimer.en.title +
                        disclaimer.en.content

                },
                pl: {
                    ...operatorRodo.pl,
                    content:
                        operatorRodo.pl.content +
                        `<br/><br/>` +
                        disclaimer.pl.title +
                        disclaimer.pl.content
                },
            }}
            onConfirm={
                () => { closeRodo() }}
            onClose={
                () => { closeRodo() }}
        />
    }
}

export default RodoPopUp;