import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import {compose} from "redux";
import {translate} from "react-admin";

const styles = theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        minWidth: "200px"
    },
    menu: {
        minWidth: "200px"
    },
});

export const SortSelect = compose(
    withStyles(styles),
    translate
)
(
    ({value, classes, helperText, handleChange = ()=>{}, id, label, options = [], translate}) =>
    <TextField
        id={id}
        select
        label={translate(label)}
        className={classes.textField}
        value={value}
        onChange={handleChange(id)}
        SelectProps={{
            MenuProps: {
                className: classes.menu,
            },
        }}
        helperText={helperText}
        margin="normal"
    >
        {options.map(option => (
            <MenuItem key={option.value} value={option.value}>
                {translate(option.label)}
            </MenuItem>
        ))}
    </TextField>);