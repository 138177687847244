
import React from "react";
import UnitGeomEditorMapUI from '../../../../gl_map/Components/Maps/UnitGeomEditorMapUI';
import MyGeomInput from "../../MyGeomInput"
import PubSub from "pubsub-js";
import {PubSubSignals} from "../../../../LogicV1Redux/constants/PubSubSignals";
import {withStyles} from "@material-ui/core";
import compose from 'recompose/compose';
import {connect} from "react-redux";

import {storeUiUnitsV2Show} from "../../../../LogicV1Redux/actions/MapUxConfigActions";
//import MissionConflictsSwitch from './MissionConflictsSwitch';

//currently the same as ZoneEditor,

const UnitGeomEditor = ({record, conflicts, primitives, legsPrimitives, myAppExtras, bBox, ...rest}) => {
    const wrapperStyle = {position: "relative", height: "500px"};


    //currently zone editor!
    console.log('UnitEditor, legsPrimitives', legsPrimitives);
    return (
        <div>
            {/*<MissionConflictsSwitch*/}
            {/*conflicts={conflicts}*/}

            {/*//record={record}*/}
            {/*{...rest}*/}
            {/*/>*/}

            <UnitGeomEditorMapUI
                bBox = {bBox}
                //heightBundle = {heightBundle}
                wrapperStyle = {wrapperStyle}
                record = {record}
                //inspectData = {inspectData}
            />
        </div>
    )
};

export default UnitGeomEditor;


/*
class UnitGeomEditor extends React.Component {

    //old approach.. as fast fix
    componentDidMount() {
        //console.log('=====btn subscribin ')
        this.pubsubOnDrawEditorDirty = PubSub.subscribe(PubSubSignals.SET_DRAW_EDITOR_DIRTY, (msg, data) => {
            console.log('=======UGE pubsubOnDrawEditorDirty ');
            //this.setState({legsDirty:true});
            this.forceUpdate()

        });
    }

    componentWillUnmount() {
        //console.log('=====btn unmounting ')

        PubSub.unsubscribe(this.pubsubOnDrawEditorDirty);
    }

    render() {
        const {record, conflicts, primitives, legsPrimitives, myAppExtras, bBox, ...rest} = this.props;

        const wrapperStyle = {position: "relative", height: "500px"};

        //currently zone editor!
        console.log('==UGE, primitives', primitives);
        return (
            <>


                <UnitGeomEditorMapUI
                    bBox={bBox}
                    //heightBundle = {heightBundle}
                    wrapperStyle={wrapperStyle}
                    record={record}
                    //inspectData = {inspectData}
                />
                <MyGeomInput bBox={bBox} record={record} primitives={primitives}/>

            </>
        )
    }
}


const mapStateToProps = state => {

    const drawEditorData = state.drawEditorData;
    const currentEditablePrimitive = drawEditorData.currentPrimitive;
    const currPrimitiveCentroid = (currentEditablePrimitive)?currentEditablePrimitive.centroid:null;

    return {
        //mySelf : state.mySelf,
        //myAppExtras: state.myAppExtras,
        //inspectData: state.inspectData,
        editablePrimitives : drawEditorData.editablePrimitives,
        currentEditablePrimitive,
        currPrimitiveCentroid, //required to update dirtiness .. not working on move, fixme
        uiUnitsV2Show: state.mapUxConfig.uiUnitsV2Show,
    }
};


const enhance = compose(
    //withStyles(styles),
    //translate,
    //muiThemeable(), // force redraw on theme change
    connect(
        mapStateToProps,
        {
            storeUiUnitsV2Show,
        },
    )
);

export default enhance(UnitGeomEditor);

*/
