import {STORE_CUSTOM_LAYER, CLEAR_CUSTOM_LAYER} from "../constants/CustomLayerConstants";

export const storeCustomLayer = (payload) => {
    console.log('storeCustomLayer', payload);
    return ({
        type: STORE_CUSTOM_LAYER,
        payload,
    });
};

export const clearCustomLayer = (payload) => {
    console.log('clearCustomLayer', payload);
    return ({
        type: CLEAR_CUSTOM_LAYER,
        payload,
    });
};
