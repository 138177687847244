export function isWithinRange(val, start, end, inclusiveStart = true, inclusiveEnd = true) {
    const startCheckFail = (inclusiveStart) ? (val < start) : (val <= start);
    if (startCheckFail) return false;
    return (inclusiveEnd) ? (end >= val) : (end > val);
}

export function doesRangesOverlap(startA, stopA, startB, stopB, inclusiveStart = true, inclusiveEnd = true) {
    console.error('not implemented!')
}

export function isNumber(val) {
    return (typeof val === 'number') && val === Number(val) && Number.isFinite(val)
}

export function sprintf(format, ...args) {
    let i = 0;
    return format.replace(/%s/g, () => args[i++]);
}
