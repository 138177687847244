import React from 'react'
import {MyHeightUnitsSwitch} from "./MyHeightUnitsSwitch";
import {withStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {Typography} from "@material-ui/core";
import {Warning} from "@material-ui/icons";
import {warningColor, dangerColor} from "../../dr_ra2/components/dashboard/style/material-dashboard-react";
import {m2ftCeiling} from "../../LogicV1Redux/oldLogic/GeoConverters";
import get from "lodash.get";
import {isPosEqual} from "../glSetups/oldCommon/commonTurf";
import {MAX_LEG_AREA_KM_SQ} from "../../LogicV1Redux/constants/LegsV2Constants";
import {invertColorToCSSHexString} from "../../dr_ra2/utils/cssColorInverter";
import isNumber from "lodash";
import {PRIMITIVES} from "../glSetups/deckDraw/DrawEditorEnums";

//currently mission data display! to review and cleanup

const heightBoxEntryStyles = {
    root: {
        display: "flex",
        flexDirection: "row",
    }
};

export const HeightBoxEntry = withStyles(heightBoxEntryStyles)(
    ({classes, ...props}) => <Typography variant={"body2"} className={classes.root} {...props}/>
);

export const HeightBoxWarningIcon = () => <Warning style={{color: warningColor}}/>;

export const HeightBoxDangerIcon = () => <Warning style={{color: dangerColor}}/>;

const heightBoxStyles = theme => ({
    root: {
        position: "absolute",
        borderRadius: "5px",
        padding: "10px 10px",
        // width: "50px",
        // top: "40px",
        bottom: "40px",
        left: "10px",
        fontSize: "smaller",
        // will invert primary font color with result opacity of 75%
        backgroundColor: invertColorToCSSHexString(theme.palette.primary.contrastText, 0.75),
    }
});

class ViewerMapHeightBoxBase extends React.Component {

    state = {
        units: 'AGL'
    };

    _handleUnitsChange  = (event) => {
        this.setState({
            units: event.target.value,
        });
    };

    render() {
        const {showAmslBox, inspectType = {}, heightValuesBundle, classes} = this.props;
        const {units} = this.state;
        console.warn('====showAmslBox', showAmslBox);

        const {
            missionMaxAmsl,
            missionMaxAgl,
            currMaxAmslString,
            currMaxAglString,
            currMinAmslString,
            currMinAglString,
            currGndString

        } = heightValuesBundle;

        const AGL = units === 'AGL';
        const AMSL = units === 'AMSL';

        return (
            <div className={classes.root}>
                {showAmslBox ?
                    <div>
                        {inspectType !== 'LEG_FIXME' ?
                            <>
                                {AMSL && <HeightBoxEntry>mission max
                                    AMSL: {missionMaxAmsl.m}m/{missionMaxAmsl.ft}ft</HeightBoxEntry>}
                                {AGL && <HeightBoxEntry variant={"body2"}>mission max
                                    AGL: {missionMaxAgl.m}m/{missionMaxAgl.ft}ft</HeightBoxEntry>}
                            </>
                            :
                            <>
                                {AMSL && <HeightBoxEntry>leg curr max AMSL: {currMaxAmslString}</HeightBoxEntry>}
                                {AGL && <HeightBoxEntry>leg curr max AGL: {currMaxAglString}</HeightBoxEntry>}

                                {AMSL && <HeightBoxEntry>leg curr min AMSL: {currMinAmslString}</HeightBoxEntry>}
                                {AGL && <HeightBoxEntry>leg curr min AGL: {currMinAglString}</HeightBoxEntry>}

                                {AMSL && <HeightBoxEntry>leg curr GND AMSL: {currGndString}</HeightBoxEntry>}
                                {AMSL && <HeightBoxEntry>leg curr Area: {currGndString} km2</HeightBoxEntry>}
                            </>
                        }
                        <MyHeightUnitsSwitch value={units} onChange={this._handleUnitsChange}/>

                    </div>
                    : <div><HeightBoxEntry>!! old mission - no height...</HeightBoxEntry></div>
                }
            </div>
        )
    }
}

ViewerMapHeightBoxBase.propTypes = {
    showAmslBox: PropTypes.bool,
    inspectType: PropTypes.string,
    heightValuesBundle: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.bool ])).isRequired,
};

export const VieverMapHeightBox = withStyles(heightBoxStyles)(ViewerMapHeightBoxBase);

class EditorMapHeightBoxBase extends React.Component {
    render() {

        const {heightValuesBundle, classes} = this.props;
        //const {units} = this.state;

        const {
            missionMaxAmslString,
            missionMaxAglString,
            currMaxAmslString,
            currMaxAglString,
            currMinAmslString,
            currMinAglString,
            currGndString,
            missionMaxAmslIsDirty,
            currGndIsDirty,
            currAreaString,
            isCurrLegTooLarge,
            curLegHasKinks,
            showAmslBox
        } = heightValuesBundle;

        if (!showAmslBox) return null;
        return (
            <div className={classes.root}>

                <HeightBoxEntry>mission max AMSL: {missionMaxAmslString}
                    {missionMaxAmslIsDirty ?
                        <><HeightBoxWarningIcon/></>
                        : null
                    }
                </HeightBoxEntry>

                <HeightBoxEntry>mission max AGL: {missionMaxAglString}
                    {/*{missionMaxAmslIsDirty ?*/}
                        {/*<><HeightBoxWarningIcon/></>*/}
                        {/*: null*/}
                    {/*}*/}
                </HeightBoxEntry>

                <HeightBoxEntry>leg curr GND: {currGndString}
                    {currGndIsDirty ?
                        <><HeightBoxWarningIcon/></>
                        : null
                    }
                </HeightBoxEntry>
                <HeightBoxEntry>leg curr max AMSL: {currMaxAmslString}
                    {currGndIsDirty ?
                        <><HeightBoxWarningIcon/></>
                        : null
                    }
                </HeightBoxEntry>
                { (curLegHasKinks)?
                    <HeightBoxEntry>leg is invalid
                        <HeightBoxDangerIcon/>
                    </HeightBoxEntry>
                    :
                    <HeightBoxEntry>leg curr max area: {currAreaString} km2
                        {isCurrLegTooLarge ?
                            <><HeightBoxDangerIcon/></>
                            : null
                        }
                    </HeightBoxEntry>
                }
            </div>
        )
    }
}

export const EditorMapHeightBox = withStyles(heightBoxStyles)(EditorMapHeightBoxBase);



//fixme -> compare/unify processing functions

export function makeHeightBundleForEditor(currentEditablePrimitive, editablePrimitives) {

    if (currentEditablePrimitive && currentEditablePrimitive.primitiveType === PRIMITIVES.MEASURE) {
        currentEditablePrimitive = null;
    }

    //what about zones?
    const defaultAglMinMax = [0, 0];

    let aglMinMax = (currentEditablePrimitive && currentEditablePrimitive.aglMinMax)
        ? currentEditablePrimitive.aglMinMax
        : defaultAglMinMax;

    let showAmslBox = false,
        missionMaxAmsl = {ft: Number.MIN_SAFE_INTEGER},
        missionMaxAgl = {m: Number.MIN_SAFE_INTEGER},

        missionMaxAmslIsDirty,
        currMaxAmsl,
        currGndIsDirty;

    console.log(' ======== currentEditablePrimitive', currentEditablePrimitive);

    let currGnd = (currentEditablePrimitive && isNumber(currentEditablePrimitive.srtmV1Gnd))
        ? {
            m: currentEditablePrimitive.srtmV1Gnd,
            ft: m2ftCeiling(currentEditablePrimitive.srtmV1Gnd)
        }
        : null;


    //fixme for zones? or dirty flag in editables?
    const currPrimitiveLegCentroid = get(currentEditablePrimitive,
        'inspectData.legRecord.etc.centroid');

    //console.log('======centroids', currPrimitiveLegCentroid, currentEditablePrimitive.centroid)
    //huh, pos equal required, db save IS not the same as recalced centroid...
    //FIXME!! btw it also means that 'isEqual' in save mission will fail...
    if (currentEditablePrimitive
        && currPrimitiveLegCentroid
        && !isPosEqual(currPrimitiveLegCentroid, currentEditablePrimitive.centroid)) {
        currGndIsDirty = true;
    }

    console.log(' ======== editable primitives', editablePrimitives);

    for (let i = 0; i <editablePrimitives.length; i++) {

        const primitive = editablePrimitives[i];

        if (primitive.primitiveType === PRIMITIVES.MEASURE) continue;

        showAmslBox = true; //there is at least one editable primitive. fixing #74

        const gndM = primitive.srtmV1Gnd;
        if (!gndM) {
            missionMaxAmslIsDirty = true;
            //return;
        }
        const _aglMinMax = (primitive.aglMinMax) ? primitive.aglMinMax : defaultAglMinMax;
        const _maxAmslM = gndM + _aglMinMax[1];
        const _maxAmslFt = m2ftCeiling(_maxAmslM);
        if (_maxAmslFt > missionMaxAmsl.ft) {
            missionMaxAmsl.ft = _maxAmslFt;
            missionMaxAmsl.m = _maxAmslM;
        }

        if (_aglMinMax[1] > missionMaxAgl.m) {
            missionMaxAgl.m = _aglMinMax[1];
            missionMaxAgl.ft = m2ftCeiling(_aglMinMax[1])
        }

        if (primitive === currentEditablePrimitive) {
            currMaxAmsl = {m: _maxAmslM, ft: _maxAmslFt}
        }

        const primitiveLegCentroid = get(primitive,
            'inspectData.legRecord.etc.centroid');

        if (primitiveLegCentroid &&
            !isPosEqual(primitiveLegCentroid, primitive.centroid)) {
            missionMaxAmslIsDirty = true;
        }
    };

    const currGndString = (currGnd)
        ? `${currGnd.m}m/${currGnd.ft}ft`
        : <>{'N/A'}<HeightBoxWarningIcon/></>;
    const currMaxAmslString = (currGnd)
        ? `${currMaxAmsl.m}m/${currMaxAmsl.ft}ft`
        : <>{'N/A'}<HeightBoxWarningIcon/></>;

    let currAreaString, isCurrLegTooLarge;

    if (currentEditablePrimitive
        && currentEditablePrimitive.__area
        && currentEditablePrimitive.__area.mSq) {
        isCurrLegTooLarge = currentEditablePrimitive.__area.kmSq >= MAX_LEG_AREA_KM_SQ;
        currAreaString = currentEditablePrimitive.__area.kmSq.toFixed(2);
    } else {
        currAreaString = 'N/A';
    }

    const curLegHasKinks = (currentEditablePrimitive && currentEditablePrimitive.__kinks);

    let missionMaxAglString, missionMaxAmslString;

    if (missionMaxAmsl.ft === Number.MIN_SAFE_INTEGER) {
        missionMaxAmslString = 'N/A';
    } else {
        missionMaxAmslString = `${missionMaxAmsl.m}m/${missionMaxAmsl.ft}ft`
    }

    if (missionMaxAgl.m === Number.MIN_SAFE_INTEGER) {
        missionMaxAglString = 'N/A';
    } else {
        missionMaxAglString = `${missionMaxAgl.m}m/${missionMaxAgl.ft}ft`
    }


    return {
        aglMinMax,
        showAmslBox,
        missionMaxAmsl,
        missionMaxAmslString,
        missionMaxAgl,
        missionMaxAglString,
        currMaxAmslString,
        currMaxAglString: 'aqq',
        currMinAmslString: 'bqq',
        currMinAglString: 'cqq',
        currGndString,
        missionMaxAmslIsDirty,
        currGndIsDirty,
        currAreaString,
        isCurrLegTooLarge,
        curLegHasKinks

    };
}


export function makeHeightBundleForViewer(legsPrimitives, inspectData) {

    let currGnd = null;

    const defaultAglMinMax = [0, 0];

    let missionMaxAmsl = {ft: Number.MIN_SAFE_INTEGER},
        missionMaxAgl = {},
        currMinAmsl,
        currMaxAmsl,
        showAmslBox = false
    ;

    legsPrimitives.forEach((primitive) => {
        const gndM = primitive.srtmV1Gnd;

        if (!isNumber(primitive.srtmV1Gnd)) {
            console.warn('=== primitive.srtmV1Gnd fail',primitive, primitive.srtmV1Gnd);
            return; //old data
        }

        showAmslBox = true;

        const _aglMinMax = (primitive.aglMinMax) ? primitive.aglMinMax : defaultAglMinMax;
        const _maxAmslM = gndM + _aglMinMax[1];
        const _maxAmslFt = m2ftCeiling(_maxAmslM);
        if (_maxAmslFt > missionMaxAmsl.ft) {
            missionMaxAmsl.ft = _maxAmslFt;
            missionMaxAmsl.m = _maxAmslM;
            missionMaxAgl.ft = m2ftCeiling(_aglMinMax[1]);
            missionMaxAgl.m = _aglMinMax[1];
        }

    });

    const currAglMinMax = get(inspectData,
        'legRecord.etc.aglMinMax', defaultAglMinMax);

    const inspectType = get(inspectData, 'inspectType');

    currGnd = get(inspectData,
        'legRecord.etc.srtmV1Gnd', 0);

    if (currAglMinMax) {
        currMaxAmsl = {m: currAglMinMax[1] + currGnd, ft: m2ftCeiling(currAglMinMax[1] + currGnd)};
        currMinAmsl = {m: currAglMinMax[0] + currGnd, ft: m2ftCeiling(currAglMinMax[0] + currGnd)};

    }

    //ugh
    const currGndString = (currGnd)
        ? `${currGnd}m/${m2ftCeiling(currGnd)}ft`
        : 'N/A';
    const currMaxAmslString = (currGnd)
        ? `${currMaxAmsl.m}m/${currMaxAmsl.ft}ft`
        : 'N/A';
    const currMinAmslString = (currGnd)
        ? `${currMinAmsl.m}m/${currMinAmsl.ft}ft`
        : 'N/A';

    const currMinAglString = (currGnd)
        ? `${currAglMinMax[0]}m/${m2ftCeiling(currAglMinMax[0])}ft`
        : 'N/A';
    const currMaxAglString = (currGnd)
        ? `${currAglMinMax[1]}m/${m2ftCeiling(currAglMinMax[1])}ft`
        : 'N/A';

    //fixme -> inspect data not passing primitive for 'LEG_FIXME'
    const currAreaString = (inspectData && inspectData.__area && inspectData.__area.mSq)
        ? inspectData.__area.kmSq.toFixed(2)
        : 'N/A';


    return {
        showAmslBox,
        inspectType,
        missionMaxAmsl,
        missionMaxAgl,
        currMaxAmslString,
        currMaxAglString,
        currMinAmslString,
        currMinAglString,
        currGndString,
        currAreaString
    };
}
