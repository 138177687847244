
import {storages} from "../../Configs";
import {SET_LOST_CONTROL_REVIEWED, STORE_ALL_CHECKINS_V2} from "../constants/CheckinsV2Constants";
import {initializeLayersObject} from "../../gl_map/glSetups/newCommon/mapStateConstructors";
import {processCheckinsV2ToDeck, processLostControlCheckins} from "../oldLogic/CheckInsV2List";
import {unixTimeNow} from "../oldLogic/TimeConverters";
import {LostControlAlert} from "../constants/CheckinsV2Enums";

const initialState = {
    rawData: [],
    deckData: initializeLayersObject(),
    selectedCheckinV2: null,
    operatorCheckinV2:null,
    storeTimestamp: 0,
    lostControlReviews: new Map(),
};

const checkinsV2Reducer = (previousState = initialState, { type, payload }) => {

    switch (type) {
        case STORE_ALL_CHECKINS_V2:
            //console.log('----- checkinsReducer reducer STORE_ALL_CHECKINS_V2:', type, payload);

            // if (payload.selectedCheckinV2) {
            //     payload.selectedCheckinV2 = {...payload.selectedCheckinV2, storeTimestamp:unixTimeNow()}
            // }

            if (payload.rawData) {
                payload.deckData = processCheckinsV2ToDeck(payload);
                payload.lostControlReviews = processLostControlCheckins(previousState.lostControlReviews, payload.rawData);
            }
            console.log('----- checkinsReducer reducer STORE_ALL_CHECKINS_V2:', type, payload);

            return {...previousState, ...payload, storeTimestamp:unixTimeNow()};

        case SET_LOST_CONTROL_REVIEWED:
                const {uid} = payload;
                const mutatedLostControlReviews = new Map(previousState.lostControlReviews).set(uid, LostControlAlert.REVIEWED);

                return {...previousState, lostControlReviews: mutatedLostControlReviews};
        default:
            return previousState;
    }
};


const persistConfig = {
    key: 'checkins',
    storage: storages.default,
    whitelist: [], //['visibilityPrefs']
};

//export default persistReducer(persistConfig, mapUxConfigReducer);

export default checkinsV2Reducer;
