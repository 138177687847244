
import {STORE_INSPECT_DATA} from "../constants/InspectConstants";

const initialState = null;

const inspectDataReducer = (previousState = initialState, { type, payload }) => {

    switch (type) {
        case STORE_INSPECT_DATA:
            //console.log('----- inspectDataReducer reducer STORE_INSPECT_DATA:', type, payload);
            if (!payload) return null;
            return { ...payload };

        default:
            return previousState;
    }
};

export default inspectDataReducer;
