import {
    isAtcMaster,
    isCaaEditor,
    isDraiEditor,
    isGov,
    isLauEditor,
    isOperator,
    isSuperAdmin
} from "../logic/MyRestConfig";


export const getMainRoleText = (roles) => {
    let rolesText = [];
    if(isSuperAdmin(roles)) rolesText.push('Main Administrator');
    else if(isOperator(roles)) rolesText.push('Operator');
    else if(isCaaEditor(roles)) rolesText.push('CAA Editor');
    else if(isLauEditor(roles)) rolesText.push('LAU Editor');
    else if(isDraiEditor(roles)) rolesText.push('DRAI Editor');
    else if(isAtcMaster(roles)) rolesText.push('ATC Master');
    else if(isGov(roles)) rolesText.push('ASM Editor');
    return rolesText.toString();
};

export const getAllRolesText = (roles) => {
    let rolesText = [];
    if(isSuperAdmin(roles)) rolesText.push('SU');
    if(isOperator(roles)) rolesText.push('OP');
    if(isCaaEditor(roles)) rolesText.push('CAA');
    if(isLauEditor(roles)) rolesText.push('LAU');
    if(isDraiEditor(roles)) rolesText.push('DRAI');
    if(isAtcMaster(roles)) rolesText.push('ATC');
    if(isGov(roles)) rolesText.push('GOV');
    return rolesText.join(`, `)
};

export const getAllUnits = (units) => {
    let unitsText = [];
    units.map(unit => unitsText.push(unit.name));
    return unitsText.join(`, `);
};