import axios from 'axios';
import {urls} from '../../Configs';
import {aircraft2ico, functionalColors, standardColors, ICONS} from "../../gl_map/glSetups/oldCommon/commonInits";
import StoreProvider from "../../dr_ra2/MyStoreProvider";
import {ICO_SIZES} from "../../gl_map/glSetups/oldCommon/deckInits";
import {InspectTypes} from "../../modules2lib/common/";
import {storeInspectData} from "../actions/InspectActions";


//first draft.
//for now? simply polls regularly
//fixme? on socketio?
//fixme gets to pubsub and then to redux

//damn, there is somewhere socket based version (not polled),
//made for droniada.. need to find it..

//was RtdmSources!, but we'll be adding other data, so... rtdm as server is named

const defaultOpts = {
    pollIntervalInSec : 1
};

//xChangeAircrafts could be optimized to object/map search? see getXChangeAircraftByIcaoCode(icao)
//double check side effects (missing/replaced redis etc)
let xChangeAircrafts;

// export function getCreotechTicks() {
//     return creotechTicks;
// }
//
// export function getXChangeTicks() {
//     //console.log('getXChangeTicks', xChangeTicks);
//     return xChangeTicks;
// }

//TODO to optimize, probably..  aircraftsData as a preprocessed map?
// or memoize?
function getXChangeAircraftByIcaoCode(aircraftsData, icao) {
    //console.log(icao, xChangeAircrafts);

    for (let i = 0; i < aircraftsData.length; i++) {
        if (aircraftsData[i].Icao === icao) {
            return aircraftsData[i];
        }
    }

    return null;
}


//setting icon can be optimized with track processing,
// but we should also rewrite paths to lines...
// so maybe later
export function processRtdmSoapLprUnitsTicksToDeck(payload) {

    const {
        rawData = [],
        meta = {},
    } = payload;

    const {
        bottomCutoff = Number.MIN_SAFE_INTEGER,
        topCutoff = Number.MAX_SAFE_INTEGER,
    } = meta;

    let icos = [], points = [], paths = [], polys = [], texts = [];

    // altitude: 302
    // callSign: "SP-HXT"
    // course: 0
    // latitude: 52.272603647268
    // longitude: 20.9092796390819
    // speed: 0
    // status: 0
    // teamName: ""
    // time: 1549387911
    // timestamp: 1549387911

    let tracks={};
    let tracksInBoundary={};

    let flightIcos={};

    for (let i = 0; i<rawData.length; i++) {
        let tick = rawData[i];
        let point = {}; //Object.assign({}, samplePoint);
        point.position = [tick.longitude, tick.latitude];
        //point.position[2] = tick.altitude; //AGL/AMSL /ft? fixme!

        point.position[2] = 25;  //AGL/AMSL fixme!

        tick.__outOfBoundary = ((tick.altitude > topCutoff) || (tick.altitude < bottomCutoff));
        const transparency = (tick.__outOfBoundary)?32:255;

        point.color = [...standardColors.gold, transparency];
        point.inspectData =  {inspectType:InspectTypes.RTDM_SOAP_LPR_UNITS_TICK, tick};

        const callSign = tick.callSign;

        if (!tracks[callSign]) {
            tracks[callSign] = [];
            tracksInBoundary[callSign] = false;
        }

        tracks[callSign].push(point.position);

        points.push(point);

        if (tick.__outOfBoundary) continue;
        tracksInBoundary[callSign] = true;

        if (flightIcos[callSign] && (flightIcos[callSign].inspectData.tick.timestamp < tick.timestamp) ) {
            //console.log('updating with newer');
            let aircraftIco = {}; //Object.assign({}, sampleIco);

            //aircraftIco.icon = ICONS.HELI_YELLOW;
            aircraftIco.position = [...point.position];
            aircraftIco.angle = tick.course; //wrong cw/ccw should be fiexed in icon layer
            aircraftIco.inspectData = {inspectType:InspectTypes.RTDM_SOAP_LPR_UNITS_TICK, tick};
            aircraftIco._displayName = `${callSign}/${tick.speed}/${tick.altitude}`;

            flightIcos[callSign] = aircraftIco;

        } else if (!flightIcos[callSign]) {

            let aircraftIco = {}; //Object.assign({}, sampleIco);
            aircraftIco.size = ICO_SIZES.CHECKIN;

            aircraftIco.icon = ICONS.HELI_YELLOW;

            aircraftIco.position = [...point.position];
            aircraftIco._displayName = `${callSign}/${tick.speed}/${tick.altitude}`;

            aircraftIco.angle = tick.course; //wrong cw/ccw should be fiexed in icon layer
            aircraftIco.inspectData = {inspectType:InspectTypes.RTDM_SOAP_LPR_UNITS_TICK, tick};
            flightIcos[callSign] = aircraftIco;
        }
    }

    //console.log(Object.entries(tracks));

    //hmmm
    for (const [key, value] of Object.entries(tracks)) {
        //console.log(key, value);
        let newPath = {}; //Object.assign({}, samplePath);
        newPath.path = value;

        const trackInBoundary = tracksInBoundary[key];
        const transparency = (trackInBoundary)?255:32;

        newPath.color = [...standardColors.gold, transparency];
        paths.push(newPath);
    }

    for (const [key, value] of Object.entries(flightIcos)) {
        //console.log("lpr flight icos", key, value);
        icos.push(value);

        let text = {};
        text.centroid = value.position;
        text.displayName = value._displayName;
        //delete value._displayName;
        texts.push(text);
    }

    return {icos, points, paths, polys, texts};
}


export function processRtdmAerobitsTicksToDeck(payload) {

    const {
        rawData = [],
        meta = {},
    } = payload;

    const {
        bottomCutoff = Number.MIN_SAFE_INTEGER,
        topCutoff = Number.MAX_SAFE_INTEGER,
    } = meta;

    let icos = [], points = [], paths = [], polys = [], texts = [];

    let tracks={};
    let tracksInBoundary={};

    let flightIcos={};

    for (let i = 0; i<rawData.length; i++) {
        let tick = rawData[i];
        let point = {}; //Object.assign({}, samplePoint);
        point.position = [tick.LON_PARSED, tick.LAT_PARSED];
        //point.position[2] = tick.altitude; //AGL/AMSL /ft? fixme!

        point.position[2] = 25;  //AGL/AMSL fixme!

        // FIXME ALT CALCS?
        //tick.__outOfBoundary = ((tick.altitude > topCutoff) || (tick.altitude < bottomCutoff));
        const transparency = (tick.__outOfBoundary)?32:255;

        point.color = [...standardColors.purple, transparency];
        point.inspectData =  {inspectType:InspectTypes.RTDM_AEROBITS_TICK, tick};

        const uasId = tick.UAS_ID;

        if (!tracks[uasId]) {
            tracks[uasId] = [];
            tracksInBoundary[uasId] = false;
        }

        tracks[uasId].push(point.position);

        points.push(point);

        if (tick.__outOfBoundary) continue;
        tracksInBoundary[uasId] = true;

        if (flightIcos[uasId] && (flightIcos[uasId].inspectData.tick.timestamp < tick.timestamp) ) {
            //console.log('updating with newer');
            let aircraftIco = {}; //Object.assign({}, sampleIco);

            //aircraftIco.icon = ICONS.HELI_YELLOW;
            aircraftIco.position = [...point.position];
            //aircraftIco.angle = tick.course; //wrong cw/ccw should be fiexed in icon layer
            aircraftIco.inspectData = {inspectType:InspectTypes.RTDM_AEROBITS_TICK, tick};
            aircraftIco._displayName = `${uasId}`;

            flightIcos[uasId] = aircraftIco;

        } else if (!flightIcos[uasId]) {

            let aircraftIco = {}; //Object.assign({}, sampleIco);
            aircraftIco.size = ICO_SIZES.CHECKIN;

            aircraftIco.icon = ICONS.UFO_YELLOW;

            aircraftIco.position = [...point.position];
            aircraftIco._displayName = `${uasId}`;

            //aircraftIco.angle = tick.course; //wrong cw/ccw should be fiexed in icon layer
            aircraftIco.inspectData = {inspectType:InspectTypes.RTDM_AEROBITS_TICK, tick};
            flightIcos[uasId] = aircraftIco;
        }
    }

    //console.log(Object.entries(tracks));

    //hmmm
    for (const [key, value] of Object.entries(tracks)) {
        //console.log(key, value);
        let newPath = {}; //Object.assign({}, samplePath);
        newPath.path = value;

        const trackInBoundary = tracksInBoundary[key];
        const transparency = (trackInBoundary)?255:32;

        newPath.color = [...standardColors.gold, transparency];
        paths.push(newPath);
    }

    for (const [key, value] of Object.entries(flightIcos)) {
        //console.log("lpr flight icos", key, value);
        icos.push(value);

        let text = {};
        text.centroid = value.position;
        text.displayName = value._displayName;
        //delete value._displayName;
        texts.push(text);
    }

    return {icos, points, paths, polys, texts};
}


export function processRtdmCreotechTicksToDeck(payload) {

    const {
        rawData = [],
        meta = {},
        currentInspectData
    } = payload;

    const {
        bottomCutoff = Number.MIN_SAFE_INTEGER,
        topCutoff = Number.MAX_SAFE_INTEGER,
    } = meta;

    let icos = [], points = [], paths = [], polys = [], texts = [];

    // alt:37025
    // call:"UTA800"
    // device:"C493000A754C"
    // dist:null
    // flags:null
    // hea:63
    // icao:"424107"
    // lat:52.17561
    // lon:21.05922
    // sig:700
    // sq:1457
    // timestamp:1524490395
    // velh:466
    // velv:0


    //console.log('processRtdmCreotechTicksToDeck', rawData);

    let tracks={};
    let tracksInBoundary={};

    let flightIcos={};

    const currentInspectAC = (currentInspectData && (currentInspectData.inspectType === InspectTypes.RTDM_CREOTECH_AIRCRAFT))
        ? currentInspectData
        : null;

    const currentInspectIcao = (currentInspectAC) ? currentInspectAC.tick.icao: null;

    //console.warn('===currentInspectData', currentInspectData);
    //console.warn('===currentInspectAC', currentInspectAC);
    //console.warn('===currentInspectIcao', currentInspectIcao);

    for (let i = 0; i<rawData.length; i++) {
        let tick = rawData[i];
        let icao = tick.icao;

        tick.__outOfBoundary = ((tick.alt > topCutoff) || (tick.alt < bottomCutoff));
        //tick.__outOfBoundary = false;

        if (tick.__outOfBoundary) continue;

        let point = {}; //Object.assign({}, samplePoint);
        point.position = [tick.lon, tick.lat];


        if (process.env.REACT_APP_ENABLE_3D) {
            //point.position[2] = tick.alt; //AGL/AMSL /ft? fixme!
            point.position[2] = tick.alt - process.env.REACT_APP_3D_OFFSET_HACK; //droniada hack 311 m

        } else {
            point.position[2] = 10;  //AGL/AMSL fixme!
        }

        const transparency = (tick.__outOfBoundary)?16:255;

        point.color = [...standardColors.purple, transparency];

        point.color = functionalColors.newPrimitive;
        point.inspectData = {inspectType:InspectTypes.RTDM_CREOTECH_TICK, tick};

        if (!tracks[icao]) {
            tracks[icao] = [];
            tracksInBoundary[icao] = false;
        }
        tracks[icao].push(point.position);

        points.push(point);

        if (tick.__outOfBoundary) continue;
        tracksInBoundary[icao] = true;

        if (flightIcos[icao] && (flightIcos[icao].inspectData.tick.timestamp < tick.timestamp) ) {
            //console.log('updating with newer');
            let aircraftIco = {}; //Object.assign({}, sampleIco);

            //aircraftIco.icon = ICONS.HELI_YELLOW;
            aircraftIco.position = [...point.position];
            aircraftIco.angle = tick.hea; //wrong cw/ccw should be fiexed in icon layer
            aircraftIco.inspectData = {inspectType:InspectTypes.RTDM_CREOTECH_AIRCRAFT, tick};
            aircraftIco._displayName = `${icao}/${tick.velh}/${tick.alt}`;

            flightIcos[icao] = aircraftIco;

        } else if (!flightIcos[icao]) {

            let aircraftIco = {}; //Object.assign({}, sampleIco);
            aircraftIco.size = ICO_SIZES.CHECKIN;

            aircraftIco.icon = ICONS.UFO_RED;

            aircraftIco.position = [...point.position];
            aircraftIco._displayName = `${icao}/${tick.velh}/${tick.alt}`;

            aircraftIco.angle = tick.hea; //wrong cw/ccw should be fiexed in icon layer
            aircraftIco.inspectData = {inspectType:InspectTypes.RTDM_CREOTECH_AIRCRAFT, tick};
            flightIcos[icao] = aircraftIco;
        }
    }

    //console.log(Object.entries(tracks));

    //hmmm
    for (const [key, value] of Object.entries(tracks)) {
        //console.log(key, value);
        let newPath = {}; //Object.assign({}, samplePath);
        newPath.path = value;
        const trackInBoundary = tracksInBoundary[key];
        const transparency = (trackInBoundary)?255:32;
        newPath.color = [...standardColors.purple, transparency];

        paths.push(newPath);
    }

    for (const [key, value] of Object.entries(flightIcos)) {
        //console.log("lpr flight icos", key, value);
        icos.push(value);

        let text = {};
        text.centroid = value.position;
        text.displayName = value._displayName;
        //delete value._displayName;
        texts.push(text);

        if (key === currentInspectIcao) {

            //hmm, called from reducer from saga
            //somehow should be in saga?

            //known bug -> selecting flight blocks switching to other tabs.
            //more checks needed.. so disabling in build env

            if (process.env.REACT_APP_DRONIADA_WIP) {
                console.log('===== dispatching', key, value);
                const update = {...currentInspectData, tick:value.inspectData.tick, timestamp:value.inspectData.tick.timestamp};
                console.warn('==== dispatching inspect data', update);
                setTimeout(
                    function() {
                        StoreProvider.getStore().dispatch(
                            storeInspectData(update)
                        );
                    },
                    1
                );
            }

        }
    }

    return {icos, points, paths, polys, texts};
}

//setting icon can be optimized with track processing,
// but we should also rewrite paths to lines...
// so maybe later
export function processAdsbExchangeTicks(payload) {

    const {
        rawData = [],
        meta = {},
        currentInspectData,
        aircraftsData
    } = payload;

    const {
        bottomCutoff = Number.MIN_SAFE_INTEGER,
        topCutoff = Number.MAX_SAFE_INTEGER,
    } = meta;

    let icos = [], points = [], paths = [], polys = [], texts = [];


    // Alt:38000
    // AltT: 0
    // Bad:false
    // Call:"DLH731"
    // CallSus:false
    // GAlt:37667
    // Gnd:false
    // Help:false
    // Icao:"3C65A2"
    // InHg:29.5866146
    // Lat:49.35997
    // Long:17.601084
    // Mlat:false
    // Rcvr:1
    // Sig:58
    // Spd:445.3
    // SpdTyp:0
    // Sqk: "6264"
    // TAlt:38016
    // TTrk:230.625
    // Tisb:false
    // Trak:232
    // TrkH:false
    // Vsi:0
    // VsiT:1
    // WTC:3
    // timestamp:"1522289872.2709999"

    let tracks={};
    let tracksInBoundary={};
    let flightIcos={};
    let tracksNoAlt={};

    const currentInspectAC = (currentInspectData && (currentInspectData.inspectType === InspectTypes.RTDM_EXCHANGE_AIRCRAFT))
        ? currentInspectData
        : null;

    const currentInspectIcao = (currentInspectAC) ? currentInspectAC.flight.Icao: null;
    //console.warn('===currentInspectData', currentInspectData);
    //console.warn('===currentInspectAC', currentInspectAC);
    //console.warn('===currentInspectIcao', currentInspectIcao);


    for (let i = 0; i<rawData.length; i++) {
        let tick = rawData[i];

        let icao = tick.Icao;

        let point = {}; //Object.assign({}, samplePoint);
        point.position = [tick.Long, tick.Lat];
        //point.position[2] = tick.Alt; //AGL/AMSL /ft? fixme!
        point.position[2] = 15; //fixme!

        if (!tracks[icao]) {
            tracks[icao] = [];
            tracksInBoundary[icao] = false;
            tracksNoAlt[icao] = false;
        }

        tick.__outOfBoundary = !!((tick.Alt > topCutoff) || (tick.Alt < bottomCutoff));
        //no alt should be somehow shown -> gray missing data...
        tick.__noAlt = (tick.Alt === undefined);
        if (tick.__noAlt && icao) {
            tracksNoAlt[icao] = true;
        }
        const transparency = (tick.__outOfBoundary)?32:255;
        const color = (tick.__noAlt)?standardColors.gray:standardColors.blue;
        point.color = [...color, transparency ];
        point.inspectData = {inspectType:InspectTypes.RTDM_EXCHANGE_TICK, tick};



        tracks[icao].push(point.position);

        points.push(point);

        if (tick.__outOfBoundary) continue;
        tracksInBoundary[icao]= true;

        //flight
        // CNum :"27981"
        // Cou:"Turkey"
        // EngType:3
        // Engines:"2"
        // From:"LTAI Antalya, Turkey"
        // Icao:"4BCDD9"
        // Id:4967897
        // Man:"Boeing"
        // Mdl:"Boeing 737NG 8K5/W"
        // Op:"SunExpress"
        // OpIcao:"SXS"
        // Reg:"TC-SNY"
        // Species:1
        // To:"EDDV Hannover, Germany"
        // Trt:2
        // Type:"B738"
        // Year:"1997"
        // timestamp:"1524438846.2479999"

        if (flightIcos[icao] && (flightIcos[icao].inspectData.tick.timestamp < tick.timestamp) ) {
            //console.log('updating with newer');
            let flight = getXChangeAircraftByIcaoCode(aircraftsData, tick.Icao);
            let aircraftIco = {}; //Object.assign({}, sampleIco);

            aircraftIco.icon = aircraft2ico(flight.Species, tick.__noAlt); //fixme unify naming

            if (tick.__noAlt) {
                aircraftIco.color = standardColors.black50;
            }

            aircraftIco.position = [...point.position];
            aircraftIco.angle = tick.Trak; //wrong cw/ccw should be fixed in icon layer
            aircraftIco.inspectData = {inspectType:InspectTypes.RTDM_EXCHANGE_AIRCRAFT, tick, flight};
            flightIcos[icao] = aircraftIco;

        } else if (!flightIcos[icao]) {
            let flight = getXChangeAircraftByIcaoCode(aircraftsData, tick.Icao);
            //console.log(aircraft);

            if (flight) {
                let aircraftIco = {}; //Object.assign({}, sampleIco);
                aircraftIco.size = ICO_SIZES.CHECKIN;

                aircraftIco.icon = aircraft2ico(flight.Species, tick.__noAlt); //fixme unify naming

                if (tick.__noAlt) {
                    aircraftIco.color = standardColors.black50;
                }

                aircraftIco.position = [...point.position];
                aircraftIco.angle = tick.Trak; //wrong cw/ccw should be fiexed in icon layer
                aircraftIco.inspectData = {inspectType:InspectTypes.RTDM_EXCHANGE_AIRCRAFT, tick, flight};
                flightIcos[icao] = (aircraftIco);
            }
        }
    }

    //console.log(Object.entries(tracks));

    for (const [key, value] of Object.entries(tracks)) {
        //console.log(key, value);
        let newPath ={}; // Object.assign({}, samplePath);
        newPath.path = value;
        const trackInBoundary = tracksInBoundary[key];
        const transparency = (trackInBoundary)?255:32;
        const color = (tracksNoAlt[key])?standardColors.gray:standardColors.blue;

        newPath.color = [...color, transparency];

        paths.push(newPath);

        //console.log('newPath', newPath);

    }

    for (const [key, value] of Object.entries(flightIcos)) {
        icos.push(value);

        if (key === currentInspectIcao) {

            //hmm, called from reducer from saga
            //somehow should be in saga?

            if (process.env.REACT_APP_DRONIADA_WIP) {
                console.log('===== dispatching', key, value);

                //known bug -> selecting flight blocks switching to other tabs.
                //more checks needed.. so disabling in build env
                const update = {
                    ...currentInspectData,
                    tick: value.inspectData.tick,
                    timestamp: value.inspectData.tick.timestamp
                };
                console.warn('==== dispatching inspect data', update);
                setTimeout(
                    function () {
                        StoreProvider.getStore().dispatch(
                            storeInspectData(update)
                        );
                    },
                    1
                );
            }
        }

    }

    return {icos, points, paths, polys, texts};

}


function requestCreotechTicks() {

    console.error('OLD requesting creotech ticks ')

    const opts = {
        method:'get',
        url:urls.rtdmCreotechTicksUrl,
    };

    axios.request(opts)
        .then(response => {
            if (response.data.length > 0) {
                console.log('creoticks', response.data);
            }
            console.error('axios requestCreotechTicks data processing removed!')

            //creotechTicks = response.data;
        })
        .catch(function (error) {
            console.error('axios getCreotechTicks error', error)
        });
}

function requestXChangeTicks() {

    console.error('OLD requesting xchange ticks ')


    const opts = {
        method:'get',
        url:urls.rtdmExchangeTicksUrl,
    };

    axios.request(opts)
        .then(response => {
            console.error('axios getXChangeTicks data processing removed!')

            //console.log('req', response.data);
            //xChangeTicks = response.data;
        })
        .catch(function (error) {
            console.error('axios getXChangeTicks error', error)
        });
}

function requestXChangeAircrafts() {

    console.error('OLD requesting xchange aircrafts ')

    const opts = {
        method:'get',
        url:urls.rtdmExchangeAircraftsUrl,
    };

    axios.request(opts)
        .then(response => {
            console.error('axios requestXChangeAircrafts data processing removed!')
        })
        .catch(function (error) {
            console.error('axios getXChangeAircrafts error', error)
        });
}

function requestData() {
    requestCreotechTicks();
    requestXChangeTicks();
    requestXChangeAircrafts();
}

let jsIntervalRef = null;

export function open(opts = {}) {
    console.log('opening adsb sources');

    if (jsIntervalRef) {
        console.error('ADSB sources already opened, close them first.');
        return;
    }

    const pollInterval = opts.pollIntervalInSec || defaultOpts.pollIntervalInSec;

    //const dataHandler = adsbDataHandler || (() => {});

    requestData();

    if (pollInterval && pollInterval > 0) {
        jsIntervalRef = setInterval(() => {
            requestData();
        }, pollInterval*1000);
    }
}

export function close() {
    console.log('closing adsb sources');

    clearInterval(jsIntervalRef);
    jsIntervalRef = null;
    //creotechTicks = [];
    //xChangeTicks = [];
    //xChangeAircrafts = [];
}

export function isOpened() {
    return !!(jsIntervalRef)
}

export default {
    open,
    close,
    isOpened,
    //getCreotechTicks,
    //getXChangeAircraftByIcaoCode,
    //getXChangeTicks
}
