
import {turfCircle, turfDistance, turfGreatCircle, turfDestination} from '../oldCommon/commonTurf.js'
import { PRIMITIVES} from '../deckDraw/DrawEditorEnums'
import {getDrawIco,  DEFAULT_MAX, DEFAULT_MIN} from '../deckDraw/deckEditor';
import {DeckPrimitive} from "./DeckPrimitive";
import {functionalColors} from "../oldCommon/commonInits";
import {turfArea} from "../oldCommon/commonTurf";
import {mSq2kmSq} from "../../../LogicV1Redux/oldLogic/GeoConverters";

//circle is defined by center and second construction point for radius calc or TODO radius itself
export default class DeckCircle extends DeckPrimitive {

    constructor(latlngA, radiusOrLatLngB) {
        super();

        this.primitiveType = PRIMITIVES.CIRCLE;
        if (Array.isArray(radiusOrLatLngB)) {
            this.helpers = [latlngA, radiusOrLatLngB];
            this._calcRadius();
        } else {
            this.radius = radiusOrLatLngB;
            this.helpers = [latlngA, turfDestination(latlngA, radiusOrLatLngB, 90)];
        }
        this._calcGeometry();
    }

    //fixme?
    recalc(latlngA, latlngB) {
        this.helpers = [latlngA, latlngB];
        this._calcRadius();
        this._calcGeometry();
        return this;
    }

    //fix helpers position
    setRadius(val) {
        this.radius = val;
        this.helpers[1] = turfDestination(this.helpers[0], val, 90);
        this._calcGeometry();
        return this;
    }

    _calcRadius() {
        this.centre = this.helpers[0];
        this.radius = turfDistance(this.helpers[0], this.helpers[1]);
    }

    _calcGeometry() {
        this.centre = this.helpers[0];

        const latLngs = turfCircle(this.helpers[0], this.radius);
        this.polygon = (this.radius !== 0) ? latLngs : [];
        this.markers = [
            getDrawIco('BLUE_CIRCLE', this.helpers[0][0], this.helpers[0][1]),
            getDrawIco('BLUE_CIRCLE', this.helpers[1][0], this.helpers[1][1]),
        ];
        this.paths = (this.radius !== 0) ? [
            {
                path: turfGreatCircle(this.helpers[0], this.helpers[1]),  //using greatCircle!.. hmm?
                widthScale: 1,
            }
        ] : null;

        this.centroid = this.centre;
        //fixme? should be optimized to custom call?
        this.__area = {mSq:turfArea(this.polygon)};
        this.__area.kmSq = mSq2kmSq(this.__area.mSq);
    }

}
