import {ArrayField, Datagrid, TextField} from "ra-ui-materialui";
import MyTextField from '../../MyTextField';
import MyLinkField from '../../MyLinkField';

import {dateTimeFormatter} from "../../../utils/momentHelpers";
import React from "react";

const ConflictsOperatorRenderer = ({shownConflicts}) => {
    console.log('ConflictsOperatorRenderer', shownConflicts);
    return (
        <ArrayField
            record={shownConflicts}
            //{...rest}
            source="filteredConflicts">

            <Datagrid>
                <MyTextField source="created"
                             label={'resources.zoneLegs.fields.created'}
                             sortable={false}
                             format={dateTimeFormatter}
                />

                <TextField source="zonePrimitive.primitive.inspectData.zoneRecord.type"
                           label={'resources.zones.fields.type'}
                           sortable={false}
                />

                <MyLinkField source="zonePrimitive.primitive.inspectData.name"
                             idSource="zoneId"
                             link={'/zonesAll/{id}/show'} //zonesAll!!!
                             label={'resources.zones.fields.name'}
                             sortable={false}
                />


                <TextField source="zonePrimitive.primitive.inspectData.zoneRecord.unit.name"
                           label={'resources.zones.fields.unitName'}
                           sortable={false}
                />

                <TextField source="status"
                           label={'resources.zoneLegs.fields.status'}
                           sortable={false}

                />

                {/*no endpoint for units for operator!, merging data in mission request*/}
                {/*<ReferenceField source="zonePrimitive.primitive.inspectData.zoneRecord.unitid"*/}
                {/*label={'resources.zones.fields.unitName'}*/}

                {/*reference="units"*/}
                {/*linkType="show"*/}
                {/*basePath={"/units"} //required*/}
                {/*>*/}
                {/*<TextField source="name"/>*/}
                {/*</ReferenceField>*/}
            </Datagrid>
        </ArrayField>
    )
};

export default ConflictsOperatorRenderer;