//ugh

import React from 'react';
import compose from 'recompose/compose';
import {translate} from "react-admin";
import Typography from '@material-ui/core/Typography';
import sanitizeRestProps from './RAField-sanitizeRestProps';

const TT = ({ translate, content, options = {}, upperCase, className, ...props }) => (
    <Typography className={className} {...sanitizeRestProps(props)}>{upperCase ? translate(content, options).toUpperCase() : translate(content, options)}</Typography>
);

const enhance = compose(
    //withStyles(styles),
    translate
);
export default enhance(TT);