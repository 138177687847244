
import React from 'react';

import * as dd from 'dis-gui'; //renaming didn't work

//fixme add custom type renderers with edit

export default class DisCircleRenderer extends React.Component {

    constructor(props) {
        super(props);
        console.log('circle here', props.options);
    }

    render() {
        const {primitive, onChangeMax, onChangeMin, onChangeRadius} = this.props.options;
        const DBG = JSON.stringify(primitive);
        return (
            <dd.Folder label='Circle' expanded={true}>
                <dd.Text
                    label='DBG!'
                    value={DBG}
                    onChange={()=>{}}
                />
                <dd.Text
                    label='centre'
                    value={primitive.centre.toString()}
                    onChange={()=>{}}
                />
                <dd.Number
                    label='radius'
                    value={primitive.radius}
                    onChange={onChangeRadius}
                />
                <dd.Number
                    label='max'
                    value={primitive.max}
                    onChange={onChangeMax}
                />
                <dd.Number
                    label='min'
                    value={primitive.min}
                    onChange={onChangeMin}
                />
            </dd.Folder>
        )
    }
}
