//early v2

export const FlightStatusesV2 = {
    CREATED : 'created',
    //POSTED : 'posted', //removed
    IN_QUEUE : 'inQueue',
    ACCEPTED : 'accepted',
    ACCEPTED_ACK : 'acceptedAck',
    SEEN : 'seen',
    ATC_MODIFIED : 'atcModified',
    ATC_MODIFIED_ACK : 'atcModifiedAck',
    REJECTED : 'rejected',
    REJECTED_ACK : 'rejectedAck',
    CANCELLED : 'cancelled',
    LOST_CONTROL : 'lostControl',
    LAND_NOW : 'landNow', //server-side now
    LANDING_ACK : 'landingAck',
    HOLD_RQ : 'holdRq', //planned
    HOLD_ACK : 'holdAck', //planned
    HOLD_ON : 'holdOn', //planned
    STARTED : 'started', //proposal for server-side
    ENDED : 'ended', //proposal for server-side
};


export const FlightStatusesV2Translations = {
    EN: {
        [FlightStatusesV2.CREATED]: 'sent',
        //[FlightStatusesV2.POSTED]: 'sent',
        [FlightStatusesV2.IN_QUEUE]: 'awaiting ATC decision',
        [FlightStatusesV2.ACCEPTED]: 'ATC accepted',
        [FlightStatusesV2.ACCEPTED_ACK]: 'ATC accept ACK',
        [FlightStatusesV2.SEEN]: 'FIS seen',
        [FlightStatusesV2.ATC_MODIFIED]: 'ATC modified',
        [FlightStatusesV2.ATC_MODIFIED_ACK]: 'ATC modified ACK',
        [FlightStatusesV2.REJECTED]: 'ATC rejected',
        [FlightStatusesV2.REJECTED_ACK]: 'ATC rejected ACK',
        [FlightStatusesV2.CANCELLED]:'cancelled',
        [FlightStatusesV2.LOST_CONTROL]: 'LOST CONTROL',
        [FlightStatusesV2.LAND_NOW]: 'LAND NOW',
        [FlightStatusesV2.LANDING_ACK]: 'landing ACK',
        [FlightStatusesV2.HOLD_RQ]: 'HOLD request',
        [FlightStatusesV2.HOLD_ACK]: 'HOLD ACK',
        [FlightStatusesV2.HOLD_ON]: 'HOLD on',
        [FlightStatusesV2.STARTED] : 'started',
        [FlightStatusesV2.ENDED] : 'ended',
    },
    PL: {
        [FlightStatusesV2.CREATED]: 'zgłoszony',
        //[FlightStatusesV2.POSTED]: 'zgłoszony',
        [FlightStatusesV2.IN_QUEUE]: 'oczekujący decyzji ATC',
        [FlightStatusesV2.ACCEPTED]: 'zaakceptowany przez ATC',
        [FlightStatusesV2.ACCEPTED_ACK]: 'potwierdzona akceptacja ATC',
        [FlightStatusesV2.SEEN]: 'widziany przez FIS',
        [FlightStatusesV2.ATC_MODIFIED]: 'zmodyfikowany przez ATC',
        [FlightStatusesV2.ATC_MODIFIED_ACK]: 'potwierdzona modyfikacja ATC',
        [FlightStatusesV2.REJECTED]: 'odrzucony przez ATC',
        [FlightStatusesV2.REJECTED_ACK]: 'potwierdzone odrzucenie ATC',
        [FlightStatusesV2.CANCELLED]: 'skancelowany',
        [FlightStatusesV2.LOST_CONTROL]: 'UTRATA KONTROLI',
        [FlightStatusesV2.LAND_NOW]: 'NAKAZ LĄDOWANIA',
        [FlightStatusesV2.LANDING_ACK]: 'potwierdzenie lądowania',
        [FlightStatusesV2.HOLD_RQ]: 'żądanie HOLD',
        [FlightStatusesV2.HOLD_ACK]: 'potwierdzenie HOLD',
        [FlightStatusesV2.HOLD_ON]: 'HOLD w trakcie',
        [FlightStatusesV2.STARTED] : 'started',
        [FlightStatusesV2.ENDED] : 'ended',
    }
};

//fixme

// export const atcAllowedStatuses = [
//     FlightStatusesV2.ATC_MODIFIED,
//     FlightStatusesV2.ACCEPTED,
//     FlightStatusesV2.REJECTED,
// ];
//
// export const opAllowedStatuses = [
//     FlightStatusesV2.CREATED,
//     FlightStatusesV2.CANCELLED,
//     FlightStatusesV2.LOST_CONTROL,
//     FlightStatusesV2.LANDING_ACK,
// ];

export const CheckinRenderModesV2 = {
    DEFAULT : 'default',
    UNKNOWN : 'unknown',
    FLIGHT_ACTIVE : 'flightActive',
    LANDING_ACK : 'landingAck',
    LOST_CONTROL: 'lostControl',
    HOLD_RQ: 'holdRq',
    HOLD_ACK: 'holdAck',
    HOLD_ON : 'holdOn',
};

export const CheckinPostRqEnums = {
    CREATE : 0,
    //ACCEPT : 1,
    //REJECT : -1,
    //CANCEL : -2,
    LOST_CONTROL : -3,
    //LANDING_ACK : -4,
    //HOLD_RQ : -5,
    //HOLD_ACK : -6,
    //HOLD_ON : -7,
    STATUS_UPDATE : -10,
};

export const WeightClassesEnums = {
    UNKNOWN : 'UNKNOWN',
    LIGHT : 'LIGHT',
    MEDIUM : 'MEDIUM',
    HEAVY : 'HEAVY'
};


export const WeightClassesDesc = {
    UNKNOWN:'N/A',
    LIGHT:'<0.6 kg',
    MEDIUM: '0.6-25 kg',
    HEAVY: '25-150 kg'
};


export const LostControlAlert = {
    SHOULD_REVIEW: 'shouldReview',
    REVIEWED: 'reviewed'
};
