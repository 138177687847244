import {TextField} from 'react-admin'
import React from "react";
import {getStatusIconColored} from "../../../utils/Dashboard/statusIcon";
import StatusField from "../../StatusField";
import {
    dateFormatter, dateTimeFormatter,
} from "../../../utils/momentHelpers";
import MyTextField from "../../MyTextField";
import {ReferenceFieldDecorator as ReferenceField} from "../../ReferenceFieldDecorator";
import {MissionActionButtons} from "./MissionActionButtons";
import Table from "@material-ui/core/Table/Table";
import TableRow from "@material-ui/core/TableRow/TableRow";
import TableCell from "@material-ui/core/TableCell/TableCell";
import TableBody from "@material-ui/core/TableBody/TableBody";
import withWidth, {isWidthDown} from "@material-ui/core/withWidth";
import FlightTakeoff from '@material-ui/icons/FlightTakeoff';
import {compose} from "redux";
import {translate} from "react-admin";
import {SummaryCards} from "../../SummaryCards";
import has from "lodash.has";
import {isGov} from "../../../logic/MyRestConfig";
import classnames from "classnames";
import {get} from "lodash";
import Configs, {FEATURES_V1_2_ENUMS} from "../../../../Configs";

const MISSION_112_ENABLED = Configs.enabledFeatures.includes(FEATURES_V1_2_ENUMS.MISSION_112);

const sortFieldOptions = [
    {
        label: 'resources.missions.fields.created',
        value: 'created'
    },
    {
        label: 'resources.missions.fields.modified',
        value: 'modified'
    },
    {
        label: 'resources.missions.fields.name',
        value: 'name'
    },
    {
        label: 'resources.missions.fields.status',
        value: 'status'
    },
    {
        label: 'resources.missions.fields.start',
        value: 'start'
    },
    {
        label: 'resources.missions.fields.stop',
        value: 'stop'
    },
    {
        label: 'resources.drones.fields.operator',
        value: 'operatorid'
    },
];




export const MissionSummaryCards = compose(translate, withWidth())
(
    ({
         basePath,
         isOp,
         permissions,
         resource,
         translate,
         getCtr,
         width,
         row112Class,
         ...rest
     }) => (
        <SummaryCards
            title={
                MISSION_112_ENABLED ?
                    ({record}) => <TextField record={record} source="name" className={classnames({
                        [row112Class]: get(record, 'etc.is112')
                    })}/>
                    :
                    ({record}) => <TextField record={record} source="name"/>
            }
            subheader={
                ({record}) => <MyTextField source="start"
                                       format={dateFormatter}
                                       record={record}/>
            }
            avatarIcon={<FlightTakeoff/>}
            actions={
                ({record}) => <MissionActionButtons resource={resource}
                                                permissions={permissions}
                                                record={record}
                                                {...{basePath}}/>
            }
            {...{sortFieldOptions, ...rest}}
        >
            {
                ({record}) =>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell>
                                    {translate('resources.missions.fields.status')}
                                </TableCell>
                                <TableCell>
                                    <StatusField source="status"
                                                 tooltip={isWidthDown('md', width)}
                                                 iconGetter={getStatusIconColored}
                                                 record={record}
                                    />
                                </TableCell>
                            </TableRow>
                            {isOp
                                ? null //<TextField source="name"/>. both for op and gov now

                                : <TableRow>
                                    <TableCell>
                                        Operator
                                    </TableCell>
                                    <TableCell>
                                        <ReferenceField source="operatorid"
                                                        reference="operators"
                                                        linkType="show"
                                                        addLabel={true}
                                                        label={'resources.drones.fields.operator'}
                                                        record={record}
                                            //TODO I think it should be set automatically
                                                        basePath={'operators'}
                                        >
                                            {/*
                                                             was: fixme to full name renderer

                                                             TODO connect to custom mission view, should be faster and
                                                                we have there full name done
                                                            */}

                                            <TextField source="lastName" record={record}/>
                                        </ReferenceField>
                                    </TableCell>
                                </TableRow>
                            }
                            <TableRow>
                                <TableCell>
                                    {translate('resources.missions.fields.start')}
                                </TableCell>
                                <TableCell>
                                    <MyTextField source="start"
                                                 format={dateTimeFormatter}
                                                 record={record}
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    {translate('resources.missions.fields.stop')}
                                </TableCell>
                                <TableCell>
                                    <MyTextField source="stop"
                                                 format={dateTimeFormatter}
                                                 record={record}
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    {translate('resources.missions.fields.approval')}
                                </TableCell>
                                <TableCell>
                                    <TextField source="approval"
                                               record={record}
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    {translate('resources.missions.fields.created')}
                                </TableCell>
                                <TableCell>
                                    <MyTextField source="created"
                                                 format={dateTimeFormatter}
                                                 record={record}
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    {translate('resources.missions.fields.modified')}
                                </TableCell>
                                <TableCell>
                                    <MyTextField source="modified"
                                                 format={dateTimeFormatter}
                                                 record={record}
                                    />
                                </TableCell>
                            </TableRow>
                            {
                                has(permissions, 'roles') &&
                                isGov(permissions.roles) &&
                                <TableRow>
                                    <TableCell>
                                        {translate('myroot.dashboard.ctrZonesHeader')}
                                    </TableCell>
                                    <TableCell>
                                        {
                                            React.cloneElement(getCtr(),
                                                {record: record}
                                                )
                                        }
                                    </TableCell>
                                </TableRow>
                            }
                        </TableBody>

                    </Table>
            }
        </SummaryCards>
    )
);