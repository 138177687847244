//really dirty, for berlin

//todo rewrite and split functionality
//zone rules (extended) should be taken from db (but, but.. backend), and accessable via redux store
//zones visibility should be stored locally as user preference ('cookied' redux store)

import Store from "store/dist/store.modern"; //fixme
import PubSub from "pubsub-js";
import {PubSubSignals} from '../constants/PubSubSignals'

import defaults from '../../modules2lib/ZoneTypesV2Decoded'

export function getZoneRules(zoneType = null) {

    if (zoneType === null) return defaults;

    if (!(defaults[zoneType])) {
        console.warn('cannot deserialize unknow type, fixme', zoneType);
        console.log(zoneType, defaults);
        return null;
    }
    return defaults[zoneType];

    //todo cookied cache is not updated with new rules.
    //fixme with redux and persistor gate
    //return Store.get('zoneRulesV0b', defaults);
}

export function setZoneVisibility(zone, val) {
    console.error('pubsub removed!');
    defaults[zone].visible = val;
    setLocalStore();
    //PubSub.publish(PubSubSignals.ON_ZONES_RULES_CHANGE, getZoneRules());
}

export function showHideAll(val) {

    const rules = getZoneRules();
    Object.entries(rules).forEach(([key, value]) => {
        console.log(`${key} ${value.toString()}`);
        // ret +=  `${key}:${value.toString()}\n`;
        defaults[key].visible = val;
    });
    console.log(defaults);
    setLocalStore();

    console.error('pubsub removed!');

    //PubSub.publish(PubSubSignals.ON_ZONES_RULES_CHANGE, getZoneRules());
}

function setLocalStore() {
    Store.set('zoneRulesV0', defaults)
}
