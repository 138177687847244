
import {
    FETCH_ALL_MISSIONS_V2_RQ,
    STORE_ALL_MISSIONS_V2,
    SAVE_MISSION_DATA_V2_RQ,
    SAVE_MISSION_STATUS_V2_RQ,

    STORE_CURRENT_MISSION_V2_EDIT,
    CLONE_MISSION_V2,
    CHECK_HOLIDAYS,
    MISSION_TERM_DIALOG_CALLBACK,
    MISSION_NO_CONFLICTS_DIALOG_CALLBACK,
    STORE_SELECTED_MISSION
} from "../constants/MissionsV2Constants";

export const fetchAllMissionsV2Rq = (payload) => {
    console.log('fetchAllMissionsV2Rq', payload);
    return ({
        type: FETCH_ALL_MISSIONS_V2_RQ,
        payload,
    });
};

export const storeAllMissionsV2 = (payload) => {
    console.log('storeAllMissionsV2', payload);
    return ({
        type: STORE_ALL_MISSIONS_V2,
        payload,
    });
};


//special multiphase saga
export const saveMissionDataV2Rq = (payload) => {

    console.log('saveMissionV2DataRq', payload);
    return ({
        type: SAVE_MISSION_DATA_V2_RQ,
        payload,
    });
};

export const saveMissionStatusV2Rq = (payload) => {

    console.log('saveMissionStatusV2Rq', payload);
    return ({
        type: SAVE_MISSION_STATUS_V2_RQ,
        payload,
    });
};


//editor/planner.. unused?
export const storeCurrentMissionEdit = (payload) => {
    console.log('storeCurrentMissionEdit', payload);
    return ({
        type: STORE_CURRENT_MISSION_V2_EDIT,
        payload,
    });
};

export const cloneMission = (payload) => {
    return ({
        type: CLONE_MISSION_V2,
        payload,
    });
};

export const checkHolidays = (payload) => {
    console.log('checkHolidays', payload);
    return ({
        type: CHECK_HOLIDAYS,
        payload,
    });
};

export const callbackFromMissionTermDialog = ({response}) => {
    console.log('callbackFromMissionTermDialog', response);
    return ({
        type: MISSION_TERM_DIALOG_CALLBACK,
        response,
    });
};

export const callbackFromMissionNoConflictsDialog = ({response}) => {
    console.log('callbackFromMissionNoConflictsDialog', response);
    return ({
        type: MISSION_NO_CONFLICTS_DIALOG_CALLBACK,
        response,
    });
};

export const storeSelectedMission = (payload) => {
    console.log('storeSelectedMission', payload);
    return ({
        type: STORE_SELECTED_MISSION,
        payload,
    });
};
