import {THREE_LIGHTS, THREE_LIGHTS_CODES, THREE_LIGHTS_COLORS} from "./ThreeLightsEnums";

export function code2val(codename) {
    return THREE_LIGHTS[codename];
}

export function val2code(val) {
    return THREE_LIGHTS_CODES[val]
}

export function val2hex(val) {
    return THREE_LIGHTS_COLORS[val]
}
