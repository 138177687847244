import {PRIMITIVES} from "../deckDraw/DrawEditorEnums";

export default class DeckLocation {

    constructor(latLng) {
        this.primitiveType = PRIMITIVES.LOCATION;
        this.latLng = latLng;
        this._calcGeometry();

    }

    recalc(latLng) {
        this.latLng = latLng;
        this._calcGeometry();
        return this;
    }

    _calcGeometry() {
        this.markers = [
            //hmm
            {position:  this.latLng, icon: 'LOCATION_HOME', size: 128}
        ];
    }
}
