import Button from '@material-ui/core/Button';
import React from "react";

const SanitizedMuiButton = ({children, ...props}) => {

    const {basePath, handleSubmit, submitOnEnter, handleSubmitWithRedirect, ...rest} = props;

    return <Button {...rest}>{children}</Button>
};

export default SanitizedMuiButton;