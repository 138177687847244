export const richText = {
    title: `<h2>Welcome to PansaUTM!</h2>`,
    content: `
    To get all features coverage please enter your <b>first name</b>, <b>last name</b> and <b>phone number</b>. In other case, 
    due to procedural issues, official authorities will have no possibility to consider mission forms. You can do it now by clicking the <b>UNDERSTOOD</b>
    button or later in settings window, by clicking the profile icon placed in the upper right corner of the screen. <br/><br/>
   
    We hope that our system will please you. In any case, if you notice a bug or you will have some insights, please send an email to:  
    <a href="mailto:drony@pansa.pl">drony@pansa.pl</a>. <br/><br/>
    Thank you for each message in advance. Have a nice flight!`,
    confirm: `Understood`,
};
