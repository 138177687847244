import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import RemoveCircle from '@material-ui/icons/RemoveCircle';
import PageView  from '@material-ui/icons/Pageview';
import FileInputPreview from 'ra-ui-materialui/lib/input/FileInputPreview'
import withMyRender from "../utils/withMyRender";
import {getBlobLink} from "./MyFileField";
import connect from "react-redux/es/connect/connect";
import {addBlobToCacheList} from "../logic/ui/MyUiActions";
import compose from "recompose/compose"

const _handleClick = (file, blobs, addBlobToCacheList) => {
    console.log("blob handle click", file);
    const blob = getBlobLink(file, blobs, addBlobToCacheList);

    //if edge
    if (/Edge\/\d./i.test(navigator.userAgent)){
       let a = document.createElement("a");
       document.body.appendChild(a);
       a.href = blob;
       a.download = file.title;
       a.style = "display: none";
       a.click();
       a.remove();
    }
    else window.open(blob);

} ;


//TODO pass styles not inline
function myRender(thisClass, superRender) {

            const elementsTree = superRender;

            const {
                children,
                classes = {},
                className,
                onRemove,
                revokeObjectURL,
                file,
                viewOnly,
                blobs,
                addBlobToCacheList,
                ...rest
            } = thisClass.props;

            return (
                <div className={className} {...rest}>
                    <IconButton className={classes.removeButton} onClick={()=>_handleClick(file, blobs, addBlobToCacheList)}>
                        <PageView className={classes.removeIcon} />
                    </IconButton>
                    {!viewOnly &&
                    <IconButton style={{bottom: "0.5rem", top: "unset"}} className={classes.removeButton}
                                onClick={onRemove}>
                        <RemoveCircle className={classes.removeIcon}/>
                    </IconButton>
                    }
                    {children}
                </div>
            );
}


const MyFileInputPreview = compose(
    connect(
        state => ({blobs: state.myUi.blobs}),
        ({addBlobToCacheList})
    ),
    withMyRender(myRender)
)(FileInputPreview);

MyFileInputPreview.defaultProps = {
    onRemove: ()=>{}
};

export default MyFileInputPreview;

