import React from 'react';
import PropTypes from 'prop-types';
import { Field, propTypes, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { parseUrl, stringify } from 'query-string';
import compose from 'recompose/compose';
import Typography from '@material-ui/core/Typography';
//import CardText from '@material-ui/core/CardText';

import {email, required } from 'react-admin';

import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import { translate } from 'ra-core';
import { Link } from 'react-router-dom';

import {loginPageStyles as styles, renderInput} from "../../utils/tempCommon";
import {myAuthSagaConfirmRegistrationRq} from "../../../LogicV2/auth/MyExtraAuthActions";
import StoreProvider from "../../MyStoreProvider";
import {showNotification} from "react-admin";

const confirmRegistrationRq = (auth, dispatch, { redirectTo }) => {
    console.log('can early test for data correctness here', auth);

    //assuming url passed
    const parsed = parseUrl(auth.link);
    const query = parsed.query;
    console.log('=====parsed', parsed);

    if (query && query.uid && query.token) {
        console.log('confirmRegistrationRq', auth);
        dispatch(myAuthSagaConfirmRegistrationRq({uid:query.uid,token:query.token}, redirectTo));
    }
    else
        return Promise.reject();

};

const validateEmail = [required()] //, email()];
const validateLink = [required()] //, email()];


const ConfirmRegisterForm = ({ classes, isLoading, handleSubmit, translate, mySelf }) => (
    <form onSubmit={handleSubmit(confirmRegistrationRq)}>
        <CardActions>
            <Typography gutterBottom>
                {translate('myroot.auth.registrationMailSentMsg', {email:mySelf.registrationEmail})}
            </Typography>
        </CardActions>
        <CardActions>
            <Typography component={"p"} color={"textSecondary"} >
                {translate('myroot.auth.registrationVerificationMsg')}
            </Typography>
        </CardActions>

        <div className={classes.form}>
            <div className={classes.input}>
                <Field
                    name="link"
                    component={renderInput}
                    label={translate('myroot.auth.verificationLink')}
                    disabled={isLoading}
                    validate={validateLink}
                />
            </div>

        </div>
        <CardActions>
            <Button
                variant="raised"
                type="submit"
                color="primary"
                disabled={isLoading}
                className={classes.button}
            >
                {isLoading && <CircularProgress size={25} thickness={2} />}
                {translate('myroot.auth.verify')}
            </Button>
        </CardActions>
        {/*
        <Link to={{pathname: "/login"}} >{translate('myroot.auth.signIn')}</Link>,&emsp;
        <Link to={{pathname: "/reset-pass"}} >{translate('myroot.auth.resetPass')}</Link>
        */}
    </form>
);

// ConfirmRegisterForm.propTypes = {
//     ...propTypes,
//     classes: PropTypes.object,
//     redirectTo: PropTypes.string,
// };

const mapStateToProps = state => {
    console.log('---------------mapStateToProps registerConfirmForm', state);
    return { isLoading: state.admin.loading > 0, mySelf:state.mySelf }
};

//form after connect ? works, but... hmmm..
const enhance = compose(
    withStyles(styles),
    translate,
    connect(mapStateToProps),
    reduxForm({
        form: 'register-confirm-form',
        // onChange: ({...e}) => {
        //
        // },
        //can have early custom validation, but have on submit.. so...
        // validate: (values, props) => {
        //     const errors = {};
        //     const { translate } = props;
        //
        //     return errors;
        // },
        // initialValues:{
        //     link:"https://api.droneradar.xyz/v2/Operators/confirm?uid=1bd4df79-c331-463b-9b0b-50f03d8a1ba6&redirect=https%3A%2F%2Fdroneradar.xyz%2Fadmin%2F%23%2Fverified&token=1e762a0fbd3699ba585a81ebcd078a6a69cb62536853c3fb9b7c0db6ec44dd86e9ff881d4a9e53382c8d4990149d24904fcabfbb86639c396ef64a625c94e12a"
        // },
        onSubmitFail: ({...e}) => {
            console.log('==============submit failed', e);
            //just test. but works.
            const store = StoreProvider.getStore();
            store.dispatch(showNotification('ra.message.invalid_form', 'warning'));
        },
        //replaced with saga, otherwise should be dispatched locally (probably)
        onSubmitSuccess: ({...e}) => {
            console.log('===============submit success', e);
        }

    })
);

export default enhance(ConfirmRegisterForm);
