//should be hidden for most gov users? who can accept mission? fixme!
//cannot use default SaveButton as it is rendered in Show context..
//or can be somehow faked Edit context? maybe... but later...
import {Component} from "react";
import {MissionV2Statuses} from "../../../../LogicV1Redux/constants/MissionsV2Enums";
import {Toolbar} from "ra-ui-materialui";
import Button from '@material-ui/core/Button';
import {connect} from "react-redux";
import React from "react";
import FlexDiv from "../../FlexDiv";
import MyInputDialog from "../../MyInputDialog";
import MyTempAtcAcceptDialog from "./MyTempAtcAcceptDialog";
import {mapDispatchToProps_MissionSaveActionsBtn} from './MissionSaveActionsButton'
import {isAtcMaster} from "../../../logic/MyRestConfig";
import {translate} from 'react-admin';
import compose from 'recompose/compose'

import SanitizedMuiButton from '../../SanitizedMuiButton';
import TranslatableTypography from '../../TranslatableTypography'
import {areAllMyZonesAccepted, areAllZonesOwned, isSomeZoneOwned} from "../../../utils/missionHelpers";
import PubSub from "pubsub-js";
import {PubSubSignals} from "../../../../LogicV1Redux/constants/PubSubSignals";


class MissionAtcUserToolbar extends Component {

    state = {
        rejectDialogOpen: false,
        acceptDialogOpen: false,
        allZonesOwned: false,
        afterMissionDeserialization: false,
    };

    componentWillMount() {
        this.pubsubMissionDeserialized = PubSub.subscribe(PubSubSignals.MISSION_DESERIALIZED, () => {
            this.setState({
                afterMissionDeserialization: true
            })
        });
    }

    componentWillUnmount() {
        PubSub.unsubscribe(this.pubsubMissionDeserialized);
    }

    handleRejectDialogClose = () => {
        this.setState({
            rejectDialogOpen: false,
        })
    };

    handleAcceptDialogClose = () => {
        this.setState({
            acceptDialogOpen: false,
        })
    };

    handleConfirmMine = (msg) => {
        const { record, dispatchSaveMissionStatusV2RqAction } = this.props;
        //fixme - due to unfinished for different users there are some issues with blocked status..
        // so.. temporarily enabling accept for all.
        //const data = {uid: record.uid, status: MissionV2Statuses.BLOCKED, legs: record.legs,
        const data = {uid: record.uid, status: MissionV2Statuses.ACCEPTED, legs: record.legs,
            __msg:msg, __etc:record.etc, __record:record};
            dispatchSaveMissionStatusV2RqAction(data);
            this.setState({
                acceptDialogOpen: false,
            })
        };

        handleConfirmFull = (msg) => {
            console.log('===handleConfirmFull', msg);
            const { record, dispatchSaveMissionStatusV2RqAction } = this.props;
            const data = {uid: record.uid, status: MissionV2Statuses.ACCEPTED, legs: record.legs,
                __msg:msg, __etc:record.etc, __record:record, forceAccept:true};
            dispatchSaveMissionStatusV2RqAction(data);
            this.setState({
                acceptDialogOpen: false,
            })
        };

        handleClickAccept = (e) => {
            const { record } = this.props;
            console.log('=====set status btn props = ', this.props, e);

            const legs = record.legs;

            //check for jurisdiction of all conflicts
            const allZonesOwned = areAllZonesOwned(legs);

        console.log('======allZonesOwned status check here', allZonesOwned);


        this.setState({
            acceptDialogOpen: true,
            allZonesOwned
        })
    };

    handleClickReject = () => {
        this.setState({
            rejectDialogOpen: true,
        })
    };

    handleReject = (msg) => {

        const { dispatchSaveMissionStatusV2RqAction, record } = this.props;
        console.log('=====set status btn props = ', this.props);

        //hacking saved data to process in saga
        const data = {uid: record.uid, status: MissionV2Statuses.REJECTED, legs: record.legs,
            __msg:msg, __etc:record.etc};

        dispatchSaveMissionStatusV2RqAction(data);
        this.setState({
            rejectDialogOpen: false,
        })
    };

    render() {
        const { basePath, label, refreshView, record, permissions, ...rest } = this.props;
        const {afterMissionDeserialization} = this.state;
        console.log('MissionAtcUserToolbar, should be visible only for some gov users', this.props);

        const hideForGov = false; //fixme user fitlering here ,, pass permissions?
        const hideForAtcUser = (
            // record.status ===  MissionV2Statuses.ACCEPTED
            // || record.status === MissionV2Statuses.REJECTED
            record.status !== MissionV2Statuses.PLANNED
        );
        if (hideForGov || hideForAtcUser) return null;

        const canOverrideStatus = isAtcMaster(permissions.roles);

        const acceptMsg = (this.state.allZonesOwned)
            ? null //no jurisdiction conflict, mission can be safely accepted
            : (canOverrideStatus)
                ? 'myroot.zoneConflictResolver.acceptDialogOverrideMsg'
                :  'myroot.zoneConflictResolver.acceptDialogPartialMsg';

        return (
            <div>
                {afterMissionDeserialization &&
                    <Toolbar>
                        <SanitizedMuiButton
                            //setStatus={"ACCEPTED"}
                            variant='raised'
                            color='primary'
                            onClick={this.handleClickAccept}
                            disabled={areAllMyZonesAccepted(record.legs) && !canOverrideStatus}
                        >
                            {/*<NavigationRefresh style={{paddingRight:"0.5em"}}/>*/}
                            <TranslatableTypography
                                content={'myroot.zoneConflictResolver.acceptBtn'}/>
                        </SanitizedMuiButton>
                        <FlexDiv/>
                        <SanitizedMuiButton
                            //setStatus={"REJECTED"}
                            variant='raised'
                            color='primary'
                            onClick={this.handleClickReject}
                            disabled={!isSomeZoneOwned(record.legs) && !canOverrideStatus}
                        >
                            {/*<NavigationRefresh style={{paddingRight:"0.5em"}}/>*/}
                            <TranslatableTypography
                                content={'myroot.zoneConflictResolver.rejectBtn'}/>
                        </SanitizedMuiButton>
                    </Toolbar>
                }

                <MyInputDialog title={'myroot.zoneConflictResolver.rejectDialogTitle'}
                               placeholder={'myroot.zoneConflictResolver.rejectDialogDefaultText'}
                               label={'myroot.zoneConflictResolver.rejectDialogLabel'}
                               confirm={'myroot.action.send'}
                               cancel={'ra.action.cancel'}
                               onConfirm={this.handleReject}
                               onClose={this.handleRejectDialogClose}
                               isOpen={this.state.rejectDialogOpen}
                               confirmColor={'warning'}
                />
                <MyTempAtcAcceptDialog title={'myroot.zoneConflictResolver.acceptDialogTitle'}
                                       label={'myroot.zoneConflictResolver.acceptDialogLabel'}
                                       confirmMine={'myroot.zoneConflictResolver.acceptMineLabel'}
                                       confirmFull={'myroot.zoneConflictResolver.acceptFullLabel'}
                                       cancel={'ra.action.cancel'}
                                       infoText={acceptMsg}
                                       onConfirmMine={this.handleConfirmMine}
                                       onConfirmFull={this.handleConfirmFull}
                                       onClose={this.handleAcceptDialogClose}
                                       isOpen={this.state.acceptDialogOpen}
                                       allZonesOwned={this.state.allZonesOwned}
                                       canOverrideStatus={canOverrideStatus}
                                       confirmColor={'warning'}
                                       confirmMineDisabled={areAllMyZonesAccepted(record.legs)}
                />
            </div>
        )
    }
}

export default compose(
    translate,
    connect(
        undefined,
        mapDispatchToProps_MissionSaveActionsBtn
    )
)(MissionAtcUserToolbar)