
import React from 'react';
export const disclaimerPage = (
    <div id="disclaimer-content">
        <h2>DISCLAIMER</h2>
        <br/>
        <br/>
        &copy;&nbsp;All rights reserved.
        <br/><br/>
        Any information, materials, articles, graphic elements contained in the PansaUTM are owned by Hawk-e and/or Polish Air Navigation Services Agency and/or other indicated owners and may not be used for commercial purposes.
        Any modification, reproduction or domain processing is prohibited.
        The information contained in the domain pages can be verified with:
        <ul>
            <li>
                regarding Flexible Use of Airspace (FUA) information please contact Airspace Management Cell (AMC – ASM-3) at the following phone numbers: +48 22 574 57 33, +48 22 574 57 34 or +48 22 574 57 35;
            </li>
            <li>
                regarding U-space structures and UAV flight rules please contact UAV Operations Department) at the following phone numbers: +48 22 574 5741, +48 22 574 5715, +48 22 574 7553
            </li>
        </ul>
        In case of sending any correspondence to the domain pages via Internet or e-mail, Polish Air Navigation Services Agency shall not ensure the privacy and confidentiality of any information and materials, and shall not ensure the non-infringement of other related rights.
        <br/><br/>
        Polish Air Navigation Services Agency reserves the right to copy, process, publish or take any other action, for any purpose, when using correspondence obtained in above mentioned way.
        <br/><br/>
        Polish Air Navigation Services Agency shall not be responsible for the availability and contents of links indicated in this domain, but administered by the third party.
        <br/><br/>
        Polish Air Navigation Services Agency reserves the right to change the above mentioned restrictions and disclaimers of liability in any time.
        <br/>
    </div>
);

export const richText = {
    title: `<h2>DISCLAIMER</h2>`,
    content:
        `
        &copy;All rights reserved. Any information, materials, articles, graphic elements contained in the PansaUTM are owned by Hawk-e and/or Polish Air Navigation Services Agency and/or other indicated owners and may not be used for commercial purposes.
        Any modification, reproduction or domain processing is prohibited.
        The information contained in the domain pages can be verified with:
        <ul>
            <li>
                regarding Flexible Use of Airspace (FUA) information please contact Airspace Management Cell (AMC – ASM-3) at the following phone numbers: +48 22 574 57 33, +48 22 574 57 34 or +48 22 574 57 35;
            </li>
            <li>
                regarding U-space structures and UAV flight rules please contact UAV Operations Department) at the following phone numbers: +48 22 574 5741, +48 22 574 5715, +48 22 574 7553
            </li>
        </ul>
        In case of sending any correspondence to the domain pages via Internet or e-mail, Polish Air Navigation Services Agency shall not ensure the privacy and confidentiality of any information and materials, and shall not ensure the non-infringement of other related rights.
        <br/><br/>
        Polish Air Navigation Services Agency reserves the right to copy, process, publish or take any other action, for any purpose, when using correspondence obtained in above mentioned way.
        <br/><br/>
        Polish Air Navigation Services Agency shall not be responsible for the availability and contents of links indicated in this domain, but administered by the third party.
        <br/><br/>
        Polish Air Navigation Services Agency reserves the right to change the above mentioned restrictions and disclaimers of liability in any time.
        <br/><br/>
        `
};
