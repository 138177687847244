export const THREE_LIGHTS = {
    RED:0,
    YELLOW:1,
    GREEN:2,
    BLUE:3,
};

export const THREE_LIGHTS_CODES = ["RED","YELLOW","GREEN","BLUE"]; //hmm fix for custom rpa

export const THREE_LIGHTS_COLORS = [
    "#FF0000", "#FFFF00", "#00FF00", "#3B88FF"
];
