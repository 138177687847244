import React from "react";
import compose from 'recompose/compose';
import {translate} from 'react-admin'

/**
 * @param resource The name of resource in specific i18n translation table.
 * @param recordName? Optional record name. If present will be passed instead 'name' in 'record.name'
 * Should be in path: 'resources.${resource}.name'
 */
export const MyRecordTitle = compose(translate)(
    ({record, translate, resource, recordName}) => {
        //console.log("record title", record, translate, resource, recordName);
        const name = recordName ? recordName : 'name';
        return (
        <span>{`${translate(`resources.${resource}.name`, {smart_count: 1})} #${record[name]}`} </span>
        )
    }
);

export const MyResourceListTitle = compose(translate)(
    ({record, translate, resource}) => {
        return (
            <span>{`${translate(`resources.${resource}.name`, {smart_count: 2})}`} </span>
        )
    }
);

