import ViewerMap from "./ViewerMap";
import {VieverMapHeightBox, makeHeightBundleForViewer} from "../MyHeightBoxes";
import {MissionZoneLegsCntFormatter} from "../../../dr_ra2/components/resources/Missions";
import React from "react";
import Button from '@material-ui/core/Button';
import {connect} from 'react-redux';
import compose from 'recompose/compose';
import BtnSwitchAllZonesV2 from './BtnSwitchAllZonesV2';
import BtnSwitchSateliteMap from './BtnSwitchSateliteMap';
import {withStyles} from "@material-ui/core";
import {storeUiUnitsV2Show} from "../../../LogicV1Redux/actions/MapUxConfigActions";
import {invertColorToCSSHexString} from "../../../dr_ra2/utils/cssColorInverter";
import MapOptionsToolbar from "./MapOptionsToolbar";
import MyGeomOutput from "../../../dr_ra2/components/MyGeomOutput"
import {storeUiZonesV2Show} from "../../../LogicV1Redux/actions/MapUxConfigActions"


//currently almost the same as ZoneViewerMapUIBase,


const styles = theme => ({
    //draft!
    toggleButtons: {
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        display: "flex",
        flexDirection: "row",
        justifyContent: "center"
    }
});

class UnitViewerMapUIBase extends React.Component {

    constructor() {
        super();
        this.state = {useSatelliteMap:false};

        this.onSatelliteToggle = this.onSatelliteToggle.bind(this);
        this.onUnitsToggle = this.onUnitsToggle.bind(this);
        this.onZonesToggle = this.onZonesToggle.bind(this);

    };

    onSatelliteToggle() {
        console.log('switch map here');
        this.setState({useSatelliteMap: !this.state.useSatelliteMap});
    }

    onUnitsToggle() {
        console.log('switch units here');
        this.props.storeUiUnitsV2Show(!this.props.uiUnitsV2Show);
    }

    onZonesToggle() {
        console.log('switch zones here');
        this.props.storeUiZonesV2Show(!this.props.uiZonesV2Show);
    }


    render() {
        console.log('=======UnitViewer', this.props);

        const {wrapperStyle, bBox, legsPrimitives, record, classes, inspectData, uiUnitsV2Show} = this.props;
        const {useSatelliteMap} = this.state;

        //const heightBundle = makeHeightBundleForViewer(legsPrimitives, inspectData);

        return (
            <>
                <div style={wrapperStyle}>
                    <ViewerMap
                        bBox={bBox}
                        useSatelliteMap={useSatelliteMap}

                    />

                    {/*<VieverMapHeightBox showAmslBox={heightBundle.showAmslBox}*/}
                    {/*inspectType={heightBundle.inspectType}*/}
                    {/*heightValuesBundle={heightBundle}/>*/}

                    <div className={classes.toggleButtons}>
                        <MapOptionsToolbar {...{
                            useSatelliteMap,
                            onSatelliteToggle: this.onSatelliteToggle,
                            uiUnitsV2Show,
                            onZonesToggle: this.onZonesToggle,
                            onUnitsToggle: this.onUnitsToggle
                        }}
                        />
                    </div>
                </div>
                <MyGeomOutput record = {record} />

            </>
        )
    }
};



const mapStateToProps = state => {
    return {
        //mySelf : state.mySelf,
        //myAppExtras: state.myAppExtras,
        inspectData: state.inspectData,
        uiUnitsV2Show: state.mapUxConfig.uiUnitsV2Show,
        uiZonesV2Show: state.mapUxConfig.uiZonesV2Show,

    }
};

const enhance = compose(
    withStyles(styles),

    //translate,
    connect(
        mapStateToProps,
        {
            storeUiUnitsV2Show,
            storeUiZonesV2Show
        },
    ),
);

export default enhance(UnitViewerMapUIBase);


