import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import sanitizeRestProps from './RAField-sanitizeRestProps';
import connect from "react-redux/es/connect/connect";
import compose from "recompose/compose"
import pure from "recompose/pure"
import {addBlobToCacheList} from "../logic/ui/MyUiActions";
import md5 from "crypto-js/md5"
import Base64 from 'crypto-js/enc-base64';
import {isSvg} from "../utils/reactHelpers";

const styles = {
    list: {
        display: 'flex',
        listStyleType: 'none',
    },
    image: {
        margin: '0.5rem',
        maxHeight: '10rem',
    },
};

function base64toBlob(base64Data, contentType) {
    contentType = contentType || '';
    var sliceSize = 1024;
    var byteCharacters = atob(base64Data);
    var bytesLength = byteCharacters.length;
    var slicesCount = Math.ceil(bytesLength / sliceSize);
    var byteArrays = new Array(slicesCount);

    for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
        var begin = sliceIndex * sliceSize;
        var end = Math.min(begin + sliceSize, bytesLength);

        var bytes = new Array(end - begin);
        for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
            bytes[i] = byteCharacters[offset].charCodeAt(0);
        }
        byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: contentType });
}

export const getBlobHash = (file) => {
    return Base64.stringify(md5(file.base64File.content + file.base64File.type + file.base64File.type));
};

export const getBlobLink = (record, blobs, addBlobToCacheList) => {

    let blobLink;
    const blobHash = getBlobHash(record);
    console.log("get blob link", blobHash);
    if (blobs.has(blobHash)) {
        console.log("blob getter blob exist getting from memory", record.blob);
        blobLink = blobs.get(blobHash);
    }

    else if (record.base64File) {

        const makeBlob =
            isSvg(record) ?
                () => new Blob(["SVG file preview is not supported"], {type: 'text/plain'})
                :
                () => base64toBlob(record.base64File.content, record.base64File.type)


        const blob = makeBlob();
        console.log('blob getter new blob', blob);

        const newBlob = URL.createObjectURL(blob);
        console.log('newBlobUrl', newBlob);

        addBlobToCacheList(blobHash, newBlob);
        blobLink = newBlob;
    }

    else {
        console.log('blob getter image null!!');
        blobLink = null;
    }
    return blobLink;
};

const MyFileField = ({record, className, classes = {}, source, src, title, blobs, addBlobToCacheList, ...rest}) => {

    let fileImg;

    //TODO refactoring consider moving this logic to MyFileInput
    if (record.thumbSrc) {
        console.log('thumb blob getting from record.thumbSrc', record.thumbSrc);
        fileImg = record.thumbSrc;
    }

    else {
        fileImg = getBlobLink(record, blobs, addBlobToCacheList);
    }

    if (!fileImg) {
        return <div className={className} {...sanitizeRestProps(rest)} />;
    }


    else if (Array.isArray(fileImg)) {
        return (
            <ul
                className={classnames(classes.list, className)}
                {...sanitizeRestProps(rest)}
            >
                {fileImg.map((file, index) => {

                    const titleValue = get(file, title) || title;
                    const srcValue = get(file, src) || title;
                    return (
                        <li key={index}>
                            <img
                                alt={titleValue}
                                title={titleValue}
                                src={srcValue}
                                className={classes.image}
                            />
                        </li>
                    );
                })}
            </ul>
        );
    }

    else {
        const titleValue = get(record, title) || title;

        return (
            <div className={className} {...sanitizeRestProps(rest)}>
                <img
                    title={titleValue}
                    alt={titleValue}
                    src={fileImg}
                    className={classes.image}
                />
            </div>
        );
    }
};

MyFileField.propTypes = {
    addLabel: PropTypes.bool,
    basePath: PropTypes.string,
    className: PropTypes.string,
    cellClassName: PropTypes.string,
    headerClassName: PropTypes.string,
    classes: PropTypes.object,
    record: PropTypes.object,
    sortBy: PropTypes.string,
    source: PropTypes.string,
    src: PropTypes.string,
    title: PropTypes.string,
};

export default compose(
    connect(
        null,
        ({addBlobToCacheList})
    ),
    pure,
    withStyles(styles)
)(MyFileField);
