//new zones from loobpack.. draft now


import {hexToRgb} from './ColorConverters'
import {getZoneRules} from "./ZoneRules";
import DataProvider from "../../dr_ra2/MyDataProvider";
import StoreProvider from "../../dr_ra2/MyStoreProvider";
import {fetchAllZonesV2Rq} from "../actions/ZonesV2Actions";
import {InspectTypes} from "../../modules2lib/common/";
import {deserializePrimitive, serializePrimitive} from "../../gl_map/glSetups/deckPrimitives/DeckPrimitiveSerialization";
import { PRIMITIVES} from '../../gl_map/glSetups/deckDraw/DrawEditorEnums'
import get from "lodash.get";
import {turfCentroid} from "../../gl_map/glSetups/oldCommon/commonTurf";
import {functionalColors} from "../../gl_map/glSetups/oldCommon/commonInits";
import {val2hex, code2val} from "../../modules2lib/threeLights/ThreeLightsConverters";


//helper moved from MissionsListV2
//well. somehow we have to check unit ownership for zoneleg.. huh
//let's do it once in deserializeMission,
// btw fixme roles, when defined/updated...

export function isEditableZone(zoneRecord, myEditableUnitsUids) {
    console.warn('=======_canSetStatus4ZoneLeg', zoneRecord, myEditableUnitsUids);
    return (myEditableUnitsUids.indexOf(zoneRecord.unitid) !== -1);
}

const allowedZoneTypes = ['DRAI', 'DRAP', 'DRAR'];

//hardcoded check for zone lock..
export const isLockedSourceZone = (record) => {
    //console.error('=======locking zone', record);
    return (allowedZoneTypes.indexOf(record.type) === -1);
};

//testing for zones out of current time

export function cleanOutdatedZones(tick) {
    console.log('cleanOutdatedZones fixme!')
    // let cleanedList = [];
    // let dirty = false;
    //
    // for (let i = 0; i < alertsStore.length; i++) {
    //     if ((!alertsStore[i].until) //can be null
    //         || (alertsStore[i].until > tick.unixTime)) {
    //         cleanedList.push(alertsStore[i]);
    //     }
    //     else {
    //         dirty = true;
    //     }
    // }
    // if (dirty) {
    //     alertsStore = cleanedList;
    //     PubSub.publish(PubSubSignals.ALERTS_DATA, alertsStore);
    // }
}

//hmm due to loopback limitations (15.07.2018)
//record from db contains two arrays -> legs AND zoneLegs
//.. so let's combine them
//BTW i don;t want to have circular refs, so there should be 2 functions (depending on needs)!!
//here goes legsZone as parent
export function zoneFixJoinLeg2LegZone(record) {

    console.log('rigth join here', record);

    const legs = record.legs || [];
    let zoneLegs = record.zoneLeg || [];

    //yay. each vs each...
    //now there are small tables, but...
    zoneLegs.forEach((zoneLeg) => {
        let cnt = 0;
        legs.forEach((leg) => {
            if (zoneLeg.legId === leg.uid) {
                zoneLeg.leg = leg;
                cnt++
            }
        });
        if (cnt !== 1) {
            console.warn('joining zonelegs WTF')
        }
    });

    //record.zoneLeg = [];
    return record;
}


export function deserializeZonesV2(zonesDBArr, zoneTypesV2) {
    console.log(' deserializeZonesV2 fixme!');

    let deserializedZonesArr = [];

    for (let i = 0; i < zonesDBArr.length; i++) {
        //for (let i = 0; i < 5; i++) {

        let deserialized = deserializeZoneData(zonesDBArr[i], zoneTypesV2);
        //console.log('deserialized',deserialized);

        if (!deserialized) {
            continue;
        }

        //AAAA
        //if (deserialized.properties.__zoneType !== 'ATZ') continue;
        //deserialized._sort = deserialized.primitive.poly.max || 0;
        deserializedZonesArr.push(deserialized);

    }

    return deserializedZonesArr;
}


//serializing *only* geometry here
// export function serializeZonePrimitive(zoneData) {
//
//     console.log('serializeZonePrimitive', zoneData);
//     const originalRecord = zoneData.primitive.inspectData.zoneRecord;
//     let ret = {etc: originalRecord.etc || {}, geometry:null};
//     //
//     const {primitive, wkb, min, max } = serializePrimitive(zoneData.primitive);
//     ret.etc.primitive = primitive;
//     ret.geometry = wkb;
//     ret.min = min;
//     ret.max = max;
//     //passing only custom editor data here...
//     console.log('serializedZoneData - primitive only', ret);
//
//     return ret;
// }

//keeping same as edit for now.
//maybe should be optimized (vertices? paths?, geom regeneration?)
//btw -> review if some functionality shouldn't be moved to primitives
//btw2 -> it looks like we should move zone record to primitive, since we have already serialize/deserialize calls.
//9.05.2019, fast hacking type override for non-zone

//not the same as mobile app, to unify

export function deserializeZoneData(zoneRecord, zoneTypesV2, typeOverride, inspectType = InspectTypes.ZONEV2) {

    let primitive = null
    //console.error('deserializeZoneData in ', inspectData);

    if (!zoneRecord) {
        console.error('wtf, no zoneRecord in deserializeZoneData');
        return null;
    }

    if (!(zoneRecord.type || typeOverride)) {
        console.warn('cannot deserialize zone without type, fixme', zoneRecord, typeOverride);
        return null;
    }

    if (!zoneRecord.geometry) {
        console.warn('cannot deserialize zone without geometry, fixme', zoneRecord);

        return null;
    }
//console.log("ZONERECORD", zoneRecord, typeOverride);
    if (zoneRecord.etc && zoneRecord.etc.primitive) {
        primitive = deserializePrimitive({
            primitive:zoneRecord.etc.primitive,
            geometry:zoneRecord.geometry,
            min:parseInt(zoneRecord.min) || 0,
            max:parseInt(zoneRecord.max) || 1,
        });
    } else {
        //forcing polygon
        primitive = deserializePrimitive({
            primitive:{type:PRIMITIVES.POLYGON},
            geometry:zoneRecord.geometry,
            min:parseInt(zoneRecord.min) || 0,
            max:parseInt(zoneRecord.max) || 1,
        });
    }

    if (!primitive) {
        console.warn('cannot deserialize empty geometry, fixme', zoneRecord);
        return null;

    }

    if (!primitive.polygon) {
        console.warn('cannot deserialize non-poly data', zoneRecord);
        return null;
    }

    const zoneType = typeOverride || zoneRecord.type || 'unknown';

    const zoneRules = zoneTypesV2[zoneType];

    if (!zoneRules) {
        console.error('dzd: cannot deserialize unknown type, fixme', zoneRecord);
        console.log(zoneType );
        return null;
    }


    let inspectData = {
        inspectType:InspectTypes.ZONEV2,
        name:zoneRecord.name,
        __typeOverride : typeOverride,
        __zoneType : zoneType,
        __min : zoneRecord.min,
        __max : zoneRecord.max,
        __zoneRules : zoneRules,
        zoneRecord,
    };

    //console.log('primitive here', primitive, zoneRules);

    //by default use default color
    let hex = (zoneRecord.etc && zoneRecord.etc.status)
        ? val2hex(code2val(zoneRecord.etc.status))
        : zoneRules.color_active;
    let color = hexToRgb(hex);
    //console.log(color);
    let alpha = 96;
    primitive.fillColor = [color.r, color.g, color.b, alpha];
    primitive.lineColor = [color.r, color.g, color.b];

    //fck pointer for reference on click
    primitive.inspectData = inspectData;

    return {inspectData, primitive};

}

/*
export function requestZonesV2() {

    console.warn('OLD requestZonesV2 here!');

    const store = StoreProvider.getStore();
    store.dispatch(fetchAllZonesV2Rq());

}
*/
export function processZonesV2ToDeck(payload) {

    console.log('processZonesV2ToDeck', payload);

    const {
        rawData = [],
        meta = {}
    } = payload;

    const {
        bottomCutoff = Number.MIN_SAFE_INTEGER,
        topCutoff = Number.MAX_SAFE_INTEGER,
        zoneTypesVis = {},
    } = meta;

    let icos = [], points = [], paths = [], polys = [], texts = [];

    // if (zonesPrimitives.length > 0)
    //     return {icos, points, paths, polys:zonesPrimitives };

    //if (zones.length < 2) return;
    //let zoneRules = getZoneRules();
    //console.log('=====processing zones, zoneRules', zoneRules);

    for (let i = 0; i<rawData.length; i++) {
        //console.log('processing rawData', rawData[i]);

        const {primitive, inspectData} = rawData[i];
        //poly.name = poly.inspectData.name;
        //if (primitive.min > topCutoff) continue;
        //if (primitive.max < bottomCutoff) continue;

        if (inspectData.__max <= bottomCutoff) continue;
        if (inspectData.__min >= topCutoff) continue;

        const zoneRecord = inspectData.zoneRecord;
        let isZoneActive = !inspectData.__inactive;
        //yup, zoneType CAN be overriden now (for units/pois renders, huh)
        const zoneType = inspectData.__typeOverride || inspectData.__zoneType || 'unknown';

        //console.log('processZonesV2', primitive, zoneType);

        if (!zoneTypesVis[zoneType]) continue;

        const zoneRules = getZoneRules(zoneType);

        if (!zoneRules) {
            console.warn('pzd: cannot deserialize unknow type, fixme', inspectData);
            console.log(zoneType, inspectData );
            continue;
        }

        let hex = (zoneRecord.etc && zoneRecord.etc.status)
            ? val2hex(code2val(zoneRecord.etc.status))
            : (isZoneActive)
                ? zoneRules.color_active
                : zoneRules.color;
        let color = hexToRgb(hex);
        //console.log(color);

        let alpha = (isZoneActive)?96:0;

        primitive.fillColor = [color.r, color.g, color.b, alpha];
        primitive.lineColor = [color.r, color.g, color.b];

        primitive.elevation = 1000; //- >err?
        primitive.draft = true;

        polys.push(primitive);

        //test!!
        // if (inspectData.aixmTimeslices) {// || inspectData.webcatActivations) {
        //     //if (!isZoneActive)
        //     //polys.push(primitive);
        //     for (let j = 0; j < inspectData.aixmTimeslices.length; j++) {
        //         if (inspectData.aixmTimeslices[j].__hasDabSubtract) {
        //             polys.push(primitive);
        //         }
        //     }
        // }

        let text = {};
        text.centroid = primitive.centroid;
        text.displayName = inspectData.name;

        texts.push(text);
    }

    //console.log('zonesPrimitives', zonesPrimitives);

    return {icos, points, paths, polys, texts};
}

export default {
    //requestZonesV2, //cleaning up old request mode
    deserializeZonesV2,
    cleanOutdatedZones,
    deserializeZoneData,
}
