import md5 from "crypto-js/md5";
import Base64 from 'crypto-js/enc-base64';



const WORDS_PER_MINUTE = 200;

export class Info {
    constructor(messages, locationValid = null) {
        if(!messages) throw new Error("Info class - no input argument");
        this._messages = Info._normalizeMessages(messages);
        this._locationValid = locationValid;
        this._readTime = Info._calcReadTime(this._messages);
        this._hash = Info._computeHash(this._messages, locationValid);
    }

    //get read time in milis
    static _calcReadTime = (messages) => {
        let words = 0;
        messages.map((message) => {
            words += message.split(" ").length;
        });
        const readTime = words/WORDS_PER_MINUTE*60*1000;
        return Math.ceil(readTime);
    };

    static _computeHash = (messages, locationValid) => {
        let input = "";
        messages.map((message) => {
            input += message.toString();
        });
        input += locationValid;
        return md5(input);
    };

    static _normalizeMessages = (messages) => {
        if(!Array.isArray(messages))
            return [messages];
        else return messages;
    };

    /**
     * Checks if text is richText by seraching for < and > chars
     * @param text
     * @returns {boolean | *}
     */
    static isRichText = (text) => {
        if(!text) throw new  Error(`Info class - rich text check, invalid argument. Is: ${text}.`)
        return text.includes('<') || text.includes('>');
    };

    getMessages = () => {
        return this._messages;
    };

    getLocationValid = () => {
        return this._locationValid;
    };

    /**
     * Gets read time of the Info
     * @returns {Integer}
     */
    getReadTime = () => {
        return this._readTime;
    };

    getHash = () => {
        return this._hash;
    };

    getHashBase64 = () => {
        return Base64.stringify(this._hash);
    };
}