//18.10.18
//probably unused, we have special server now...
//-> see adsb sources

//old setup connecting directly to adsb exchange servers...
//was used for tests, and can be used (very rarely) for some extra shows. (without messing with adbs sources  server - trtdm/prtdm )
//so ugh - keeping and refactoring state/inits to redux (2-02-2019)

import StoreProvider from "../../dr_ra2/MyStoreProvider";
import {fetchAllLiveAdsbExchangeRq} from "../actions/LiveAdsbExchangeActions";
import {aircraft2ico} from "../../gl_map/glSetups/oldCommon/commonInits";
import {ICO_SIZES} from "../../gl_map/glSetups/oldCommon/deckInits";
import {InspectTypes} from "../../modules2lib/common/";


const defaultOpts = {
    pollIntervalInSec : 3
};

let acList = []; //array!

//let lastDv;

export function getFlights() {
    console.error('should use redux store')

    return acList;
}

export function getByIcao(code) {
    console.error('should use redux store')
  for (let i=0; i<acList.length; i++) {
    if (acList[i].Icao === code) {
      return acList[i];
    }
  }
  return {'faild':`icao ${code} not found`};
}

export function processLiveAdsbExchangeToDeck(rawData=[]) {
    let icos = [], points = [], paths = [], polys = [], texts = [];


    // if (aircrafts[0])
    //     console.log('aircrafts', aircrafts[0]);

    // Alt:37000
    // AltT:0
    // Bad:false
    // CMsgs:1014
    // CNum:"30415"
    // Call:"TUI444"
    // CallSus:false
    // Cou:"Germany"
    // EngMount:0
    // EngType:3
    // Engines:"2"
    // FSeen:"/Date(1522288920110)/"
    // FlightsCount:0
    // From:"EDDV Hannover, Germany"
    // GAlt:36608
    // Gnd:false
    // HasPic:false
    // HasSig:true
    // Help:false
    // Icao:"3C60D6"
    // Id:3956950
    // InHg:29.52756
    // Interested:false
    // Lat:50.935829
    // Long:13.347855
    // Man:"Boeing"
    // Mdl:"Boeing 737NG 8K5/W"
    // Mil:false
    // Mlat:false
    // Op:"TUIfly"
    // OpIcao:"TUI"
    // PosTime:1522290454994
    // Rcvr:1
    // Reg:"D-AHFV"
    // Sig:189
    // Spd:488.7
    // SpdTyp:0
    // Species:1
    // Sqk:"6403"
    // TSecs:1536
    // Tisb:false
    // To:"LTAI Antalya, Turkey"
    // Trak:128.8
    // TrkH:false
    // Trt:2
    // Type:"B738"
    // Vsi:0
    // VsiT:0
    // WTC:2
    // Year:"2000"

    for (let i = 0; i<rawData.length; i++) {
        //let flight = records[records.length - 1];
        let flight = rawData[i];
        //console.log(flight);
        let aircraftIco = {} ; //Object.assign({}, sampleIco);

        aircraftIco.icon = aircraft2ico(flight.Species); //fixme unify naming
        aircraftIco.position = [flight.Long, flight.Lat];
        //aircraftIco.position[2] = flight.Alt; //AGL/AMSL /ft? fixme!
        aircraftIco.position[2] = 100; //2d override fixme!

        aircraftIco.size = ICO_SIZES.CHECKIN;

        aircraftIco.angle = flight.Trak; //wrong cw/ccw should be fiexed in icon layer

        aircraftIco.inspectData = {
            flight,
            inspectType:InspectTypes.LIVE_ADSB
        };

        icos.push(aircraftIco);
    }

    return {icos, points, paths, polys, texts};

}


export function requestLiveAdsbExchange() {

    console.warn('OLD requestLiveAdsbExchange here!');

    const store = StoreProvider.getStore();
    store.dispatch(fetchAllLiveAdsbExchangeRq());

}

let jsIntervalRef = null;

export function open(opts = {}) {
    console.log('opening live adsb ');

    if (jsIntervalRef) {
        console.error('live ADSB already opened, close them first.');
        return;
    }

    const pollInterval = opts.pollIntervalInSec || defaultOpts.pollInterval;

    requestLiveAdsbExchange();

    if (pollInterval && pollInterval > 0) {
        jsIntervalRef = setInterval(() => {
            requestLiveAdsbExchange();
        }, pollInterval*1000);
    }
}

export function close() {
    console.log('OLD closing live adsb ');

    clearInterval(jsIntervalRef);
    jsIntervalRef = null;

    acList = [];

    //fixme in redux!!
    //lastDv = null;
}

export function isOpened() {
    return !!(jsIntervalRef)
}

export default {
    open,
    close,
    isOpened,
    getByIcao,
    getFlights,
}
