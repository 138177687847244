
//abstract class,
import {DEFAULT_MAX, DEFAULT_MIN} from "../deckDraw/deckEditor";
import {functionalColors} from "../oldCommon/commonInits";


export class DeckPrimitive {

    constructor() {
        //minmax setup... hmmm. deafults are AMSL feets probably
        //but we need to keep agl user input
        this.aglMinMax = [0,50]; //damn was for legs, and now we have it everywhere.., fixme?

        this.max = DEFAULT_MAX;
        this.min = DEFAULT_MIN;
        this.__area = undefined;
        this.fillColor =  functionalColors.newPrimitive;
    }

    _calcGeometry() {
        //doing nothign as abstract
    }

    //fixme -> now val is number, should be preparsed, or string?
    setMax(val) {
        this.max = parseFloat(val);
        this._calcGeometry();
        return this;
    }

    //no min support in geom, so... doing nothing now
    setMin(val) {
        this.min = parseFloat(val);
        this._calcGeometry();
        return this;
    }

}


