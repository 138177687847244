export const richText = {
    title: `<h2>Witaj w systemie PansaUTM!</h2>`,
    content: `Aby móc korzystać z pełnej funkcjonalności systemu, podaj swoje <b>imię</b> i <b>nazwisko</b> oraz <b>telefon</b>. W przeciwnym wypadku, 
    ze względów formalnych, rozpatrzenie formularzy misji będzie niemożliwe. Możesz to zrobić teraz klikając przycisk <b>ZROZUMIAŁEM/-AM</b>
    lub w późniejszym czasie w ustawieniach, znajdujących się pod ikoną profilu w prawym górnym rogu ekranu. <br/><br/>
   
    Mamy nadzieję, że spodoba Ci się nasz system. W każdym wypadku, gdy zauważysz jakiś błąd lub będziesz miał spostrzeżenia 
    na temat działania systemu, pisz na adres:  
    <a href="mailto:drony@pansa.pl">drony@pansa.pl</a>. <br/><br/>
    Dziękujemy za każdą wiadomość i życzymy przyjemnego lotu!`,
    confirm: `Zrozumiałem/-am`,
};
