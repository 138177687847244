import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
//import Card from 'ra-ui-material-ui/Card';

import {withStyles} from '@material-ui/core/styles';
import LockIcon from '@material-ui/icons/LockOutline';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';

import Avatar from '@material-ui/core/Avatar';
import {Notification, defaultTheme} from 'react-admin';

import {connect} from 'react-redux';
import compose from 'recompose/compose';
import Typography from '@material-ui/core/Typography';
import Fade from '@material-ui/core/Fade';
import { TransitionGroup } from 'react-transition-group';
import CustomFade from './CustomFade'
//some refactoring awaits one day..
import LoginForm from './MyNewLoginForm';
import ResetPassRqForm from './MyNewResetPassRequestForm';
import ResetPassIntermediateMsg from './MyNewResetPassIntermediateMsg';
import ResetPassFinalMsg from './MyNewResetPassFinalMsg';

import ResetPassForm from './MyNewResetPassForm';

import RegisterForm from './MyNewRegisterRequestForm';
import ConfirmRegisterForm from './MyNewRegisterConfirmForm';
import RegisterVerifiedMsg from './MyNewRegisterVerifiedMsg';
import MyLangSelector from '../MyLangSelector';

const styles = theme => ({
    main: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        height: '1px',
        alignItems: 'center',
        justifyContent: 'center',//'flex-start',
        background: 'url(./img/drone_background.jpg)',
        //backgroundSize: 275px 125px;
        //backgroundColor: theme.palette.secondary[500],
        //backgroundColor: 'orange',
        //backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
    },
    textSecondary: {
        color: theme.palette.secondary[500],
    },
    //fixme compatibility (focus-within not supported in ie/edge)
    fadeWrapper : {
        transition: "opacity 0.3s ease-in-out",
        opacity: 0.85,
        '&:hover': {
            opacity: 1
        },
        '&:focus-within': {
            opacity: 1
        }
    },

    card: {
        maxWidth: 300,
        minWidth: 300,
        //opacity:0.5,
        //marginTop: '6em',
    },
    avatar: {
        margin: '1em',
        display: 'flex',
        justifyContent: 'center',
    },
    icon: {
        backgroundColor: theme.palette.secondary[500],
    },
    logoAreaDown: {
        position: "fixed",
        right: "0",
        bottom: "0",
        margin: "1rem",
        maxWidth: "15vw",
        maxHeight: "22vw",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    logoImg: {
        maxWidth: "100%"
    },
    logoImg2: {
        maxWidth: "60%"
    },
    powered: {
        fontSize: "0.6rem",
        color: "#fff"
    },
    myLangButton: {
        position: 'absolute',
        top: 0,
        right: 0,
        margin: 16,
        zIndex: 1,
    },
});

//hmm?

//aha!
//https://reactjs.org/warnings/unknown-prop.html

const sanitizeRestProps = ({
                               classes,
                               className,
                               location,
                               title,
                               array,
                               theme,
                               staticContext,


                               ...rest
                           }) => rest;


//fixme gov remove register path support..
//btw. routes set in MyAdmin could be cleaned to someting like /auth/login, auth/register etc,
//but there still be old links in Jasiek's emails.. so.. keeping it now as is, but could be temp fixed with double definitions like /auth/verify AND /verify
//and probably at least some should be state-drive multisteps. but let's wait for better backend support...
const getComponent = (location, extras, mySelf) => {
    const pathname = location.pathname;
    console.log('getComponent', {pathname, extras, mySelf});
    if (extras.appMode === 'OPERATOR') {
        if (pathname === '/register') return <RegisterForm extras={extras} mySelf={mySelf}/>;
        if (pathname === '/confirm-register') return <ConfirmRegisterForm extras={extras} mySelf={mySelf}/>; //after mail, can paste link
    }

    if (pathname === '/verified') return <RegisterVerifiedMsg extras={extras} mySelf={mySelf}/>; //jasiek mail redirect
    if (pathname === '/reset-pass-rq') return <ResetPassRqForm extras={extras} mySelf={mySelf}/>;
    if (pathname === '/reset-pass-intermediate') return <ResetPassIntermediateMsg extras={extras} mySelf={mySelf}/>;
    if (pathname === '/reset-pass-final') return <ResetPassFinalMsg extras={extras} mySelf={mySelf}/>;

    if (pathname === '/reset-pass') return <ResetPassForm location={location} extras={extras} mySelf={mySelf}/>;
    return <LoginForm extras={extras}/>;
};

const Login = ({
                   classes,
                   className,
                   //my fixes,
                   extras,
                   mySelf,

                   dispatch,
                   location,

                   ...rest
               }) => {
    console.log('login page my extras, mySelf', {extras, mySelf});
    const {appMode, builtOn, devBuild} = extras;
    const devInfo = `${appMode.toLowerCase()} app, ${builtOn}`;
    return (
        <div>
            <div
                className={classnames(classes.main, className)}
                style={{}}
                {...sanitizeRestProps(rest)}
            >
                <div className={classes.myLangButton}>
                    <MyLangSelector />
                </div>

                <div className={classes.fadeWrapper}>
                    <Fade in={true} timeout={750}>
                        <Card className={classes.card}>
                            <img src="./pansautm_logo.png" className={classes.logoImg}/>
                            <div className={classes.avatar}>
                                <Avatar className={classes.icon}>
                                    <LockIcon/>
                                </Avatar>
                            </div>
                            {devBuild
                                ? <CardActions>
                                    <pre>{devInfo}</pre>
                                </CardActions>
                                : null
                            }
                            {getComponent(location, extras, mySelf)}
                        </Card>
                    </Fade>
                </div>
                <Notification/>

            </div>

        </div>
    );
};


Login.propTypes = {
    className: PropTypes.string,
    authProvider: PropTypes.func,
    classes: PropTypes.object,
    input: PropTypes.object,
    meta: PropTypes.object,
    previousRoute: PropTypes.string,
};

Login.defaultProps = {
    theme: defaultTheme,
};

const mapStateToProps = state => {
    //lastDroneModels = state.admin.resources.droneModels;
    console.log('login mapStateToProps', state);
    return {
        extras: state.myAppExtras,
        mySelf: state.mySelf,
    }
};

// const enhance = compose(
//     //translate,
//     //withStyles(styles),
//     connect(mapStateToProps)
// );
//
//
// //export default withStyles(styles)(Login);
// export default enhance(Login);

export default compose(
    connect(mapStateToProps, null),
    withStyles(styles, {name: 'Login'}),
)(Login);
