//based on
//https://raw.githubusercontent.com/KamilDzierbicki/aor-language-polish/master/index.js
//@15-12-17
//fixing ra 2.0.2 support 12-06-2018

//updated to ra 2.4.0

module.exports = {
    ra: {

        action: {
            add: 'Dodaj',
            add_filter: 'Dodaj filtr',
            back: 'Powrót',
            bulk_actions: 'wybrano %{smart_count} |||| wybrano %{smart_count}', //hmm
            cancel: 'Anuluj',
            clear_input_value: 'Wyczyść wartość', //hmm
            clone: 'Sklonuj',
            create: 'Utwórz',
            delete: 'Usuń',
            edit: 'Edytuj',
            export: 'Export',
            list: 'Lista',
            refresh: 'Odśwież',
            remove: 'Usuń',
            remove_filter: 'Usuń filtr',
            save: 'Zapisz',
            search: 'Szukaj',
            show: 'Pokaż',
            sort: 'Sortuj',
            undo: 'Cofnij zmiany', //hmm
        },
        boolean: {
            true: 'Tak',
            false: 'Nie',
        },
        page: {
            create: 'Utwórz %{name}',
            dashboard: 'Panel główny',
            edit: '%{name} #%{id}',
            error: 'Coś poszło źle', //hmm
            list: 'Lista %{name}',
            loading: 'Ładowanie',
            not_found: 'Strona nie została znaleziona',
            show: '%{name} #%{id}',
        },
        input: {
            file: {
                upload_several: 'Upuść pliki tutaj lub naciśnij, aby wybrać.',
                upload_single: 'Upuść plik tutaj lub naciśnij, aby wybrać.',
            },
            image: {
                upload_several: 'Upuść obrazki tutaj lub naciśnij, aby wybrać.', //hmm
                upload_single: 'Upuść obrazki tutaj lub naciśnij, aby wybrać.', //hmm
            },
            references: {
                all_missing: 'Nie można znaleźć danych powiązań.', //hmm
                many_missing:
                    'Co najmniej jedno z powiązań nie jest dostępne.', //hmm
                single_missing:
                    'Powiązanie nie jest dostępne.', //hmm
            },
        },
        message: {
            about: 'O',
            are_you_sure: 'Czy jesteś pewien?',
            bulk_delete_content: '(PL) Are you sure you want to delete this %{name}? |||| (PL) Are you sure you want to delete these %{smart_count} items?', //hmm
            bulk_delete_title: 'Usuń %{name} |||| Usuń %{smart_count} %{name}', //hmm
            delete_content: 'Czy jesteś pewien?', //hmm
            delete_title: 'Usuń %{name} #%{id}',
            details: 'Szczegóły',
            error: "Błąd, akcja nie może być dokończona", //hmm
            invalid_form: "Błąd walidacji formularza.",
            loading: 'Strona się ładuje...',
            no: 'Nie',
            not_found: 'Strona której szukasz nie istnieje',
            yes: 'Tak',
        },
        navigation: {
            next: 'Następny',
            no_more_results: 'Strona %{page} jest poza limitem. Spróbuj wywołać poprzednią.',
            no_results: 'Nie znaleziono',
            page_out_from_begin: 'Nie można wyjść poza pierwszą stronę',
            page_out_from_end: 'Nie można wyjść poza ostatnią stronę',
            page_out_of_boundaries: 'Strona %{page} jest poza limitem',
            page_range_info: '%{offsetBegin}-%{offsetEnd} z %{total}',
            page_rows_per_page: 'Liczba pozycji na stronie:',
            prev: 'Poprzedni',

        },
        auth: {
            logout: 'Wyloguj się',
            password: 'Hasło',
            sign_in: 'Zaloguj',
            sign_in_error: 'Autoryzacja nie powiodła się, spróbuj ponownie',
            user_menu: 'Profil',
            username: 'Nazwa użytkownika',
        },
        notification: {
            bad_item: 'Element niepoprawny', //hmm
            canceled: 'Akcja anulowana', //hmm
            created: 'Utworzono pomyślnie',
            data_provider_error: 'dataProvider error. Check the console for details. (PL)', //hmm
            deleted: 'Usunięto pomyślnie',  //hmm (was smart count)
            http_error: 'Błąd komunikacji z serwerem',
            item_doesnt_exist: 'Element nie istnieje',
            updated: 'Zaktualizowano pomyślnie', //hmm (was smart count)
            logged_out: 'Sesja zakończona. Zaloguj się ponownie.',
        },
        validation: {
            email: 'Musi być prawidłowym adresem email',
            maxLength: 'Maksimum %{max} znaków',
            maxValue: 'Maksimum %{max}',
            minLength: 'Minimum %{min} znaków',
            minValue: 'Minimum %{min}',
            number: 'Musi być numerem',
            oneOf: 'Musi być jedną z opcji: %{options}',
            regex: 'Musi pasować do formatu REGEX (regexp): %{pattern}',
            required: 'Wymagane',
        },
    },
};