import Icon from "@material-ui/core/Icon/Icon";
import React from "react";
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';

const styles = theme => ({
    info: {
        display: "flex",
        alignItems: "center",
    },
    iconColor: {
        color: theme.palette.primary.contrastText
    }
});

export const InfoText = withStyles(styles)(

    ({classes, content, className}) =>
        <span className={classnames(classes.info, className)}>
            <Icon className={classes.iconColor}>info_outlined</Icon>
            <span>&nbsp;</span>
            {content}
        </span>
);