//bit different click count logic
//so move is different in this case


import {turfCircle, turfDistance} from '../oldCommon/commonTurf.js'
import {endDraw} from "./deckEditor";

import DeckCapsule from '../deckPrimitives/DeckCapsule';
import PubSub from "pubsub-js";
import {PubSubSignals} from "../../../LogicV1Redux/constants/PubSubSignals";

//no z-axis at the moment, so keeping clear params
//almost pure js
export function onCapsule2kmDrawClick(deckCapsule, latLng, layerClickEvent) {
    // sprint fixme some better dirty setup needed
    PubSub.publish(PubSubSignals.SET_DRAW_EDITOR_DIRTY);

    if (!deckCapsule) return;

    // const center = deckCircle.helpers[0];
    // deckCapsule = deckCircle.recalc(center, [lat, lng]);
    // if (deckCircle.radius !== 0) {//second click
    //     console.log('closing capsule2km');
    //     endDraw(deckCircle);
    // }
    deckCapsule = deckCapsule.recalc(deckCapsule.helpers[0], latLng, deckCapsule.radius);
    endDraw();
    return deckCapsule;
}

export function onCapsule2kmDrawMouseMove(deckCapsule, latLng) {
    console.log('capsule2km move', deckCapsule);

    if (deckCapsule) {
        deckCapsule = deckCapsule.recalc(deckCapsule.helpers[0], latLng, deckCapsule.radius);
    }
    return deckCapsule;

}
