import restClientRouter from './utils/raRestClientRouter';

let restClientRouterInstance = undefined;

//helper for acessing dataProviders...
//used in auth provider... (simplifies inits)
export default {
    init(restClientRouterOpts) {
        restClientRouterInstance = restClientRouter(restClientRouterOpts);
        return restClientRouterInstance;
    },
    getDataProvider(){
        return restClientRouterInstance;
    }
};