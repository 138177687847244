import { STARTUP, FULL_APP_CLEAN, FULL_APP_RELOAD, RESTART, SET_APP_STATE_STORE } from "../constants/AppStateConstants";

export const startup = (payload) => {

    console.log('startup', payload);
    return ({
        type: STARTUP,
        payload,
    });
};

export const restart = (payload) => {

    console.log('restart', payload);
    return ({
        type: RESTART,
        payload,
    });
};

export const fullAppClean = (payload) => {

    console.log('FULL_APP_CLEAN', payload);
    return ({
        type: FULL_APP_CLEAN,
        payload,
    });
};

//old reload rt-map button
export const fullAppReload = (payload) => {
    console.log('FULL_APP_RELOAD', payload);
    return ({
        type: FULL_APP_RELOAD,
        payload,
    });
};

export const setAppStateStore = (payload) => {

    console.log('SET_APP_STATE_STORE', payload);
    return ({
        type: SET_APP_STATE_STORE,
        payload,
    });
};
