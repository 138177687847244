import React from 'react';
import PropTypes from 'prop-types';

export default class MyStep extends React.Component {

    render() {
        const {children} = this.props;
        return children ? children : null
    }
}

MyStep.propTypes = {
    optional: PropTypes.bool,
    label: PropTypes.string,
    onStepSkip: PropTypes.func
};

MyStep.defaultProps = {
    optional: false,
    label: "",
    isValid: true,
    onStepSkip: ()=>{}
};