import React from 'react'
import compose from 'recompose/compose';
import withWidth, {isWidthDown} from '@material-ui/core/withWidth';
import { connect } from 'react-redux';
import { refreshView as refreshViewAction } from 'ra-core';
import {userLogout as userLogoutAction} from 'ra-core';
import pure from 'recompose/pure';
import {fetchStart as reloadStart, fetchEnd as reloadEnd} from "react-admin";
import {isSuperAdmin} from "../logic/MyRestConfig";

//TODO change globbal width listeners to view version listner
class ResponsiveController extends React.Component {


    _arePropsChanging = (previousProps, nextProps) => {
        return previousProps !== nextProps;
    };

    componentWillUpdate(nextProps, nextState, nextContext) {

        const isWidthChanging = this._arePropsChanging(this.props.width, nextProps.width);
        if (isWidthChanging) {
            this._silentlyReloadUi();
        }
    }

    // Uses ra fetchStart and fetchEnd.
    // Increments and decrements isLoading counter from react-admin once, during 10ms,
    // this makes components based on ListController, EditController, ShowController, CreateController re-render.
    // Made to solve #91.
    _silentlyReloadUi() {
        const {reloadStart, reloadEnd} = this.props;
        reloadStart();
        setTimeout(
            () => reloadEnd(),
            10
        );
    }

    render() {
        const {width, userLogout, mySelf} = this.props;
        //Super admin support to md only!
        if(isWidthDown('sm', width) && isSuperAdmin(mySelf.roles)) {
            return (
                <div>
                    <p style={{padding: "1rem"}}>
                    System PansaUTM działa na urządzeniach z ekranami o szerokości co najmniej 600 px.
<br/>
<br/>
Jeśli widzisz ten komunikat na telefonie komórkowym:
<br/>
-upewnij się, że Twoje urządzenie spełnia wymóg minimalnej szerokości ekranu
<br/>
-użyj przeglądarki Chrome,
<br/>
-zmień ułożenie ekranu z pionowego na poziomy,
<br/>
-uruchom przeglądarkę mobilną w trybie „na komputer”.
<br/>
<br/>
Jeśli widzisz ten komunikat na komputerze:
<br/>
-uruchom okno przeglądarki na pełnym ekranie
<br/>
-sprawdź rozdzielczość ekranu swojego komputera.
<br/>
<br/>
Jeżeli pomimo tego nadal widzisz ten błąd, skontaktuj się z administratorem systemu.
                    </p>
                    <button style={{padding: "1rem", marginLeft: "1rem"}} onClick={()=>userLogout()}>
                        Logout
                    </button>
                </div>
            );
        }
        else if(isWidthDown('xs', width))
            return (
                <div>
                    <p style={{padding: "1rem"}}>
                    System PansaUTM działa na urządzeniach z ekranami o szerokości co najmniej 600 px.
<br/>
<br/>
Jeśli widzisz ten komunikat na telefonie komórkowym:
<br/>
-upewnij się, że Twoje urządzenie spełnia wymóg minimalnej szerokości ekranu
<br/>
-użyj przeglądarki Chrome,
<br/>
-zmień ułożenie ekranu z pionowego na poziomy,
<br/>
-uruchom przeglądarkę mobilną w trybie „na komputer”.
<br/>
<br/>
Jeśli widzisz ten komunikat na komputerze:
<br/>
-uruchom okno przeglądarki na pełnym ekranie
<br/>
-sprawdź rozdzielczość ekranu swojego komputera.
<br/>
<br/>
Jeżeli pomimo tego nadal widzisz ten błąd, skontaktuj się z administratorem systemu.
                    </p>
                    <button style={{padding: "1rem", marginLeft: "1rem"}} onClick={()=>userLogout()}>
                        Logout
                    </button>
                </div>
            );
        else return this.props.children;
    }
}

export default compose(
    pure,
    connect(
        state => ({
            mySelf: state.mySelf,
        }),
        {
            refreshView: refreshViewAction,
            userLogout: userLogoutAction,
            reloadStart: reloadStart,
            reloadEnd: reloadEnd,
        }
    ),
    withWidth()
)(ResponsiveController);


