
import { TIMER_START_RQ, TIMER_STOP_RQ, TIMER_TICK_RQ } from "../constants/TickConstants";

export const timerStartRq = (payload) => {
    //console.log('====== start TIMER_START_RQ saga', payload);
    return ({
        type: TIMER_START_RQ,
        payload,
    });
};

export const timerStopRq = (payload) => {
    //console.log('====== on TIMER_STOP_RQ saga', payload);
    return ({
        type: TIMER_STOP_RQ,
        //payload,
    });
};

// export const timerToggleRq = (payload) => {
//     //console.log('====== on TIMER_STOP_RQ saga', payload);
//     return ({
//         type: TIMER_STOP_RQ,
//         //payload,
//     });
// };

// export const timerTickRq = (payload) => {
//     console.log('====== on TIMER_TICK_RQ', payload);
//     return ({
//         type: TIMER_TICK_RQ,
//         //payload,
//     });
// };