import React from "react"
import CloseIcon from "@material-ui/icons/Close"
import IconButton from "@material-ui/core/IconButton/IconButton";
import {withStyles} from "@material-ui/core";
import compose from 'recompose/compose';
import * as PropTypes from "prop-types";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent/CardContent";
import CardActions from "@material-ui/core/CardActions/CardActions";
import CardHeader from "@material-ui/core/CardHeader/CardHeader";
import {Rnd} from "react-rnd";
import Portal from "@material-ui/core/Portal/Portal";


const styles = theme => ({
    cardHeader: {
        ///backgroundColor: theme.palette.primary.main,
    },
    window: {
        zIndex: 1101,
        background: "red",
        minWidth: "700px",
        minHeight: "70px"
    },
    card: {
        height: "100%",
        margin: "0 -3px",
        display: "flex",
        flexDirection: "column",
        borderStyle: "solid",
        borderWidth: "1px",
        borderColor: theme.palette.primary.main
    },
    cardContent: {
        overflow: "auto",
        height: "100%"
    }
});

class ShowWindow extends React.Component {

    state = {
        width: this.props.initialSize.width,
        height: this.props.initialSize.height
    };

    render() {
        const {content, title, actions, open, onClose, classes, rndProps, container, contentRef = null, onResize, initialSize, ...props} = this.props;
        return (
            <>
                {open ?
                    <Portal container={container}>
                        <Rnd
                            default={{x: 10, y: 10}}
                            size={{width: this.state.width, height: this.state.height}}
                            onResize={(e, direction, ref, delta, position) => {
                                this.setState({
                                    width: ref.style.width,
                                    height: ref.style.height,
                                    ...position,
                                });
                                onResize({
                                    width: ref.style.width,
                                    height: ref.style.height,
                                    ...position,
                                })
                            }}
                            className={classes.window}
                            style={{position: "fixed"}}
                            {...rndProps}
                        >
                            <Card className={classes.card}>
                                <CardHeader
                                    title={title}
                                    action={
                                        <IconButton
                                            onClick={onClose}
                                        >
                                            <CloseIcon/>
                                        </IconButton>
                                    }
                                    className={classes.cardHeader}
                                    titleTypographyProps={{variant: "body2"}}
                                />

                                <CardContent className={classes.cardContent} ref={contentRef}>
                                    {content}
                                </CardContent>

                                <CardActions>
                                    {actions}
                                </CardActions>
                            </Card>
                        </Rnd>
                    </Portal>
                    :
                    null
                }
            </>
        )
    };
}

ShowWindow.defaultProps = {
    title: "Title",
    onClose: () => {},
    container: null,
    onResize: () => {},
    initialSize: {
        width: "50vw",
        height: "50vh"
    }
};

ShowWindow.propTypes = {
    title: PropTypes.string,
    content: PropTypes.node,
    actions: PropTypes.node,
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onResize: PropTypes.func,
    initialSize: PropTypes.object
};


export default compose(
    withStyles(styles),
)(ShowWindow);