
import {STORE_ALL_MISSIONS_V2, STORE_SELECTED_MISSION} from "../constants/MissionsV2Constants";
import {initializeLayersObject} from "../../gl_map/glSetups/newCommon/mapStateConstructors";
import {unixTimeNow} from "../oldLogic/TimeConverters";
import {processMissionsV2ToDeck} from "../oldLogic/MissionsListV2";


//for rt-map bars. (at least for now -> 13-11-18)
//duplicating (kind of... some separation is needed now) react admin resource....
const initialState = {rawData: [], deckData: initializeLayersObject(), selectedMissionUid: null, storeTimestamp: 0};

const MissionsV2Reducer = (previousState = initialState, { type, payload }) => {

    switch (type) {
        //hmm should we process 2 deck on store? or later?
        //let's see usage..
        case STORE_ALL_MISSIONS_V2:
            console.log('----- MissionsV2Reducer reducer STORE_ALL_MISSIONS_V2:', type, payload);

            if (payload.rawData) {
                payload.deckData = processMissionsV2ToDeck({selectedMissionUid: previousState.selectedMissionUid, ...payload});
            }

            return {...previousState, ...payload, storeTimestamp:unixTimeNow()};

        case STORE_SELECTED_MISSION: {

            payload.deckData = processMissionsV2ToDeck({...previousState, selectedMissionUid: payload.selectedMissionUid});
            return {...previousState, ...payload, storeTimestamp:unixTimeNow()};

        }
        default:
            return previousState;
    }
};

export default MissionsV2Reducer;
