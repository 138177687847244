//hmm mix with draw modes? especially capsule vs pipe, and line vs multiline
export const DRAW_MODES = {
    NO_DRAW: 'NO_DRAW',

    EDIT: 'EDIT', //hmm
    SELECT: 'SELECT', //hmm
    DELETE: 'DELETE', //hmm, currently unused, as there is problem with calling db delete .. with warnings etc

    MEASURE: 'MEASURE',

    DRAW_POINT: 'DRAW_POINT', //only test support
    DRAW_LINE: 'DRAW_LINE', //unused for now? rhumb? great circle?
    DRAW_POLY: 'DRAW_POLY',
    DRAW_CIRCLE: 'DRAW_CIRCLE', //TODO pass as circular string?
    DRAW_CIRCLE2KM: 'DRAW_CIRCLE2KM', //TODO pass as circular string?
    DRAW_CAPSULE: 'DRAW_CAPSULE', //TODO pass as circular string?
    DRAW_CAPSULE2KM: 'DRAW_CAPSULE2KM', //TODO pass as circular string?

    DRAW_PIPE: 'DRAW_PIPE', //TODO pass as circular string?
    DRAW_LOCATION: 'DRAW_LOCATION',


};
export const PRIMITIVES = {
    POINT: 'POINT',
    LOCATION: 'LOCATION', //hmm
    MEASURE: 'MEASURE',
    LINE: 'LINE', //hmm
    POLYGON: 'POLYGON',
    CIRCLE: 'CIRCLE',
    CAPSULE: 'CAPSULE',
    PIPE: 'PIPE'
};
