// TODO in fact it became a window manager - refactor and move to ui reducer
export const TOGGLE_SETTINGS_VIEW = 'TOGGLE_SETTINGS_VIEW';
export const TOGGLE_PROFILE_VIEW = 'TOGGLE_PROFILE_VIEW';
export const TOGGLE_NOTIFIACTION_VIEW = 'TOGGLE_NOTIFIACTION_VIEW';
export const TOGGLE_VIEW_VIEW = 'TOGGLE_VIEW_VIEW';
export const TOGGLE_ABOUT_VIEW = 'TOGGLE_ABOUT_VIEW';
export const TOGGLE_COMMERCIAL_SERVICES_VIEW = 'TOGGLE_COMMERCIAL_SERVICES_VIEW';
// TODO this nickname actions should not be here - refactor and move to ui reducer
export const TOGGLE_NICKNAME_DIALOG = 'TOGGLE_NICKNAME_VIEW';

export const NICKNAME_DIALOG_CALLBACK = 'NICKNAME_DIALOG_CALLBACK';

export const toggleSettingsView = (eventTarget) => {
    return ({
        type: TOGGLE_SETTINGS_VIEW,
        eventTarget: eventTarget
    })
};


export const toggleProfileView = ({show}) => {
    return ({
        type: TOGGLE_PROFILE_VIEW,
        show
    })
};

export const toggleNotificationView = ({show}) => {
    return ({
        type: TOGGLE_NOTIFIACTION_VIEW,
        show
    })
};

export const toggleViewView = ({show}) => {
    return ({
        type: TOGGLE_VIEW_VIEW,
        show
    })
};

export const toggleAboutView = ({show}) => {
    return ({
        type: TOGGLE_ABOUT_VIEW,
        show
    })
};

export const toggleCommercialServicesView = ({show}) => {
    return ({
        type: TOGGLE_COMMERCIAL_SERVICES_VIEW,
        show
    })
};

export const toggleNicknameDialog = ({show, nicknames=[]}) => {
    return ({
        type: TOGGLE_NICKNAME_DIALOG,
        show,
        nicknames
    })
};

export const callbackFromNicknameDialog = ({result}) => {
    return ({
        type: NICKNAME_DIALOG_CALLBACK,
        result
    })
};