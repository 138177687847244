import { PRIMITIVES} from '../deckDraw/DrawEditorEnums'
import {turfArea, turfCircle, turfGreatCircle} from "../oldCommon/commonTurf";
import {getDrawIco} from "../deckDraw/deckEditor";
//hmm drafting now, but looks like we need abstract primitive class (defining interface)

export default class DeckPoint {

    constructor(latLng) {
        this.primitiveType = PRIMITIVES.POINT;
        this.latLng = latLng;
        this._calcGeometry();
    }

    recalc(latLng) {
        this.latLng = latLng;
        this._calcGeometry();
        return this;
    }

    _calcGeometry() {
        this.markers = [
            getDrawIco('BLUE_CIRCLE', this.latLng[0], this.latLng[1]),
        ];
    }
}
