import WelcomeScreen from "../WelcomeScreen";
import StoreProvider from "../../../MyStoreProvider";
import {welcome} from "../../../../docs";
import {toggleProfileView} from "../../../logic/settings/MySettingsActions";
import React from "react";

const NoEnoughMissionData = () =>
<WelcomeScreen
    shouldShow={
        () => {
            const store = StoreProvider.getStore();
            const {mySelf, myAppExtras} = store.getState();
            const isOperatorApp = (myAppExtras.app.name === 'DR2_OP');
            const hasNoNames = !(!!mySelf.firstName && !!mySelf.lastName);
            const hasNoPhone = !mySelf.phone;
            return isOperatorApp && (hasNoNames || hasNoPhone);
        }
    }
    record={welcome}
    onConfirm={
        () => {
            const store = StoreProvider.getStore();
            store.dispatch(toggleProfileView({show: true}));
        }
    }
    onClose={() => {
    }}
/>;

export default NoEnoughMissionData;