import React from 'react'
import Card from "@material-ui/core/Card/Card";
import CardContent from "@material-ui/core/CardContent/CardContent";
import CardActions from "@material-ui/core/CardActions/CardActions";
import connect from "react-redux/es/connect/connect";
import {withStyles} from "@material-ui/core";
import {translate} from 'ra-core'
import compose from 'recompose/compose';
import CardHeader from "@material-ui/core/CardHeader/CardHeader";
import Avatar from "@material-ui/core/Avatar/Avatar";
import IconButton from "@material-ui/core/IconButton/IconButton";
import NotificationsIcon from '@material-ui/icons/Notifications';
import CloseIcon from '@material-ui/icons/Close';
import StoreProvider from "../../MyStoreProvider";
import {toggleNotificationView} from "../../logic/settings/MySettingsActions";
import Dialog from "@material-ui/core/Dialog/Dialog";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Switch from "@material-ui/core/Switch/Switch";
import {
    CONFIGURATION_KEY_NOTIFICATIONS_ALERTBOX_SOUNDS,
    CONFIGURATION_KEY_NOTIFICATIONS_INFOBOX_SOUNDS
} from "../../logic/settings/ConfigurationConstants";
import {setConfigurationKey} from "../../logic/settings/ConfigurationActions";
import HistoryIcon from '@material-ui/icons/History';
import {removeAllFromSeenInfos} from "../../logic/box/InfoSeenActions";
import {showNotification} from 'react-admin'
import {isOperator} from "../../logic/MyRestConfig";

const styles = theme => ({
    settingsCard: {
        margin: "0",
        borderRadius: "6px",
        '@media screen and (max-height: 919px)': {
            minHeight: "unset"
        },
        display: "flex",
        flexDirection: "column",
    },
    cardHeader: {
        backgroundColor: theme.palette.primary.main,
        borderRadius: "4px",
        //height: "4rem"
    },
    cardContent: {
        overflowY: "hidden",
        display: "flex",
        flexDirection: "column",
    },
    cardParagraph: {
        marginBottom: "1em",
        width: "100%",
        // '@media screen and (max-height: 919px)': {
        //     overflow: "auto",
        // },
    },
    paper: {
        '@media screen and (min-height: 920px)': {
            overflowY: "inherit",
            maxHeight: "unset",
        },
        borderRadius: "6px",
    },
});

class MyNotificationView extends React.Component {

    constructor(props) {
        super(props);
    }

    _handleClose = () => {
        const store = StoreProvider.getStore();
        store.dispatch(toggleNotificationView({show: false}));
    };

    _handleSwitchChange = name => event => {
        const store = StoreProvider.getStore();
        switch (name) {
            case CONFIGURATION_KEY_NOTIFICATIONS_INFOBOX_SOUNDS:
            case CONFIGURATION_KEY_NOTIFICATIONS_ALERTBOX_SOUNDS:
                store.dispatch(setConfigurationKey(name, event.target.checked));
                break;
        }
    };

    _handleInfoBoxHistoryClear = () => {
        const store = StoreProvider.getStore();
        store.dispatch(removeAllFromSeenInfos());
        store.dispatch(showNotification('myroot.configuration.notificationsResetInfoBoxHistoryDone'));
    };



    render() {

        const {classes, notificationView, infoBoxSounds, alertBoxSounds, translate, roles} = this.props;

        return (
            <Dialog
                open={notificationView.visible}
                onClose={this._handleClose}
                maxWidth="md"
                classes={{
                    paper: classes.paper
                }}
            >
                <Card className={classes.settingsCard}>

                    <CardHeader
                        avatar={
                            <Avatar aria-label="Notifications settings" className={classes.avatar}>
                                <NotificationsIcon/>
                            </Avatar>
                        }
                        action={
                            <IconButton onClick={this._handleClose}>
                                <CloseIcon/>
                            </IconButton>
                        }
                        title={translate("myroot.configuration.notificationsSettings")}
                        titleTypographyProps={{variant: "body2"}}
                        subheaderTypographyProps={{color: "textPrimary", variant: "button"}}
                        className={classes.cardHeader}
                    />

                    <CardContent className={classes.cardContent}>
                        {roles && isOperator(roles) &&
                        <div className={classes.cardParagraph}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={infoBoxSounds}
                                        onChange={this._handleSwitchChange(CONFIGURATION_KEY_NOTIFICATIONS_INFOBOX_SOUNDS)}
                                        value={CONFIGURATION_KEY_NOTIFICATIONS_INFOBOX_SOUNDS}
                                    />
                                }
                                label={translate('myroot.configuration.notificationsInfoBoxSounds')}
                            />
                        </div>
                        }

                        <div className={classes.cardParagraph}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={alertBoxSounds}
                                        onChange={this._handleSwitchChange(CONFIGURATION_KEY_NOTIFICATIONS_ALERTBOX_SOUNDS)}
                                        value={CONFIGURATION_KEY_NOTIFICATIONS_ALERTBOX_SOUNDS}
                                    />
                                }
                                label={translate('myroot.configuration.notificationsAlertBoxSounds')}
                            />
                        </div>

                        {roles && isOperator(roles) &&
                        <div className={classes.cardParagraph}>
                            <FormControlLabel
                                control={
                                    <IconButton onClick={this._handleInfoBoxHistoryClear} color={"secondary"}>
                                        <HistoryIcon/>
                                    </IconButton>
                                }
                                label={translate('myroot.configuration.notificationsResetInfoBoxHistory')}
                            />
                        </div>
                        }
                    </CardContent>
                    <CardActions>
                    </CardActions>
                </Card>
            </Dialog>
        )
    }
}


const mapStateToProps = state => {
    return {
        notificationView: state.mySettings.notificationView,
        infoBoxSounds: state.configuration[CONFIGURATION_KEY_NOTIFICATIONS_INFOBOX_SOUNDS],
        alertBoxSounds: state.configuration[CONFIGURATION_KEY_NOTIFICATIONS_ALERTBOX_SOUNDS],
    }
};


export default compose(
    translate,
    connect(
        mapStateToProps
    ),
    withStyles(styles)
)(MyNotificationView);
