import ArrayInput from "ra-ui-materialui/esm/input/ArrayInput";
import SimpleFormIterator from "ra-ui-materialui/esm/form/SimpleFormIterator";
import ReferenceInput from "ra-ui-materialui/esm/input/ReferenceInput";
import SelectInput from "ra-ui-materialui/esm/input/SelectInput";
import React from "react";
import StatusField from "../../StatusField";
import {getStatusIconColored} from "../../../utils/Dashboard/statusIcon";
import {translateCaaStatusToTypo} from "../../../utils/applicationsHelpers";
import { withStyles } from '@material-ui/core/styles';
import {TooltipProxy as Tooltip} from "../../TooltipProxy";

const styles = {
    droneEntry: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        width: "100%"
    }
};

export default withStyles(styles)
(
    ({dronesArray, shouldDisableAdd, classes}) => (
        <Tooltip content='resources.missions.tooltips.aircrafts'>
            <ArrayInput source="etc.drones"
                        label={'resources.missions.fields.etc_drones'}>
                <SimpleFormIterator disableAdd={shouldDisableAdd(dronesArray, ['id'])}>
                    <ReferenceInput source="id"
                                    label={'resources.drones.fields.name'}
                                    reference="drones"
                                    perPage={null}
                                    filter={{active: true}}
                    >

                        <SelectInput source="id"
                                     optionText={renderDroneNameWithStatus(classes)}
                        />

                    </ReferenceInput>
                </SimpleFormIterator>
            </ArrayInput>
        </Tooltip>
    )
);

const renderDroneNameWithStatus = (classes) => (choice) => {
    return (
    <div className={classes.droneEntry}>
        {choice.name}
        <StatusField source="status"
                     record={choice}
                     tooltip={true}
                     iconGetter={getStatusIconColored}
                     statusMappingFn={translateCaaStatusToTypo}
                     label={'resources.applications.verification'}
        />
    </div>
)};

