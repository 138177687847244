import {
    MAP_UI_VOLUME,
    MAP_UI_SIZE,
    MAP_UI_LPR_HELIPADS_V1_SHOW,
    MAP_UI_MATZ_SHOW,
    MAP_TICK_ENABLED,
    MAP_UI_RT_MAP_ZONES_V1_SHOW,
    MAP_UI_ZONES_V2_SHOW,
    MAP_UI_MISSIONS_V2_SHOW,
    MAP_UI_CHECKINS_V1_SHOW,
    MAP_UI_CHECKINS_V2_SHOW,

    MAP_UI_ALERTS_V1_SHOW,
    MAP_UI_ALERTS_V2_SHOW,

    MAP_SETUP_RQ,
    MAP_LPR_HELIPADS_V1_ENABLED,
    MAP_RT_MAP_ZONES_V1_ENABLED,
    MAP_ZONES_V2_ENABLED,
    MAP_MISSIONS_V2_ENABLED,
    MAP_ALERTS_V1_ENABLED,
    MAP_ALERTS_V2_ENABLED,

    MAP_CHECKINS_V1_ENABLED,
    MAP_CHECKINS_V2_ENABLED,

    MAP_LIVE_ABSB_EXCHANGE_ENABLED,
    MAP_UI_LIVE_ABSB_EXCHANGE_SHOW,

    MAP_RTDM_EXCHANGE_TICKS_ENABLED,
    MAP_RTDM_EXCHANGE_AIRCRAFTS_ENABLED,
    MAP_RTDM_CREOTECH_TICKS_ENABLED,
    MAP_RTDM_SOAP_LPR_UNITS_TICKS_ENABLED,

    MAP_UI_RTDM_EXCHANGE_TICKS_SHOW,
    MAP_UI_RTDM_EXCHANGE_AIRCRAFTS_SHOW,
    MAP_UI_RTDM_CREOTECH_TICKS_SHOW,
    MAP_UI_RTDM_SOAP_LPR_UNITS_TICKS_SHOW,


    MAP_SOCKET_IO_RTDM_SOURCES_ENABLED,
    MAP_UI_ENTITIES_VISIBILITY,
    MAP_UI_HEIGHT_FILTER,
    PANDORA_TRAFFIC_ENABLED,
    PANDORA_LPR_TRAFFIC_ENABLED,
    PANDORA_CREOTECH_TRAFFIC_ENABLED,
    PANDORA_TRAFFIC_HEIGHT_FILTER,
    MAP_CLEAR_SETUP_RQ,
    MAP_UI_CALLSIGN_FLOOR_NUMBERING_ENABLED
} from "../constants/MapUxConfigConstants";
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; //fixme fixed! good storage


//just a note
// -> if we really need to split reductor or saga per app, we can override inits with custom names during import phase for app setup
// (custom sagas/custom reducers)
// additionaly custom persistance rules can be moved per app in custom reducers
//https://blog.reactnativecoach.com/the-definitive-guide-to-redux-persist-84738167975
// , but let's wait..

//btw. pandora got really messy.

const initialState = {visibilityPrefs:{},heightFilter:{}}; //ALMOST empty! should be set during app/map initialization

const mapUxConfigReducer = (previousState = initialState, { type, payload }) => {

    switch (type) {
        case MAP_SETUP_RQ:

            return { ...previousState, ...payload };
        case MAP_UI_VOLUME:

            return { ...previousState, uiVolume: payload };
        case MAP_UI_SIZE:

            return { ...previousState, uiSize: payload };
        case MAP_UI_MATZ_SHOW: //zone display filters to rethink

            return { ...previousState, uiMatzShow: payload };
        case MAP_TICK_ENABLED:

            return { ...previousState, uiTickEnabled: payload };
        case MAP_ALERTS_V1_ENABLED:

            return { ...previousState, uiAlertsV1Enabled: payload };
        case MAP_UI_ALERTS_V1_SHOW:

            return { ...previousState, uiAlertsV1Show: payload };
        case MAP_ALERTS_V2_ENABLED:

            return { ...previousState, uiAlertsV2Enabled: payload };
        case MAP_UI_ALERTS_V2_SHOW:

            return { ...previousState, uiAlertsV2Show: payload };
        case MAP_CHECKINS_V1_ENABLED:

            return { ...previousState, uiCheckinsV1Enabled: payload };
        case MAP_UI_CHECKINS_V1_SHOW:

            return { ...previousState, uiCheckinsV1Show: payload };

        case MAP_CHECKINS_V2_ENABLED:

            return { ...previousState, uiCheckinsV2Enabled: payload };
        case MAP_UI_CHECKINS_V2_SHOW:

            return { ...previousState, uiCheckinsV2Show: payload };
        case MAP_LPR_HELIPADS_V1_ENABLED:

            return { ...previousState, uiLprHelipadsV1Enabled: payload };
        case MAP_UI_LPR_HELIPADS_V1_SHOW:

            return { ...previousState, uiLprHelipadsV1Show: payload };
        case MAP_MISSIONS_V2_ENABLED:

            return { ...previousState, uiMissionsV2Enabled: payload };
        case MAP_UI_MISSIONS_V2_SHOW:

            return { ...previousState, uiMissionsV2Show: payload };
        case MAP_RT_MAP_ZONES_V1_ENABLED:

            return { ...previousState, uiRtMapZonesV1Enabled: payload };
        case MAP_UI_RT_MAP_ZONES_V1_SHOW:

            return { ...previousState, uiRtMapZonesV1Show: payload };
        case MAP_ZONES_V2_ENABLED:

            return { ...previousState, uiZonesV2Enabled: payload };
        case MAP_UI_ZONES_V2_SHOW:

            return { ...previousState, uiZonesV2Show: payload };


        case MAP_LIVE_ABSB_EXCHANGE_ENABLED:

            return { ...previousState, uiLiveAbsbExchangeEnabled: payload };
        case MAP_UI_LIVE_ABSB_EXCHANGE_SHOW:

            return { ...previousState, uiLiveAbsbExchangeShow: payload };


        case MAP_RTDM_CREOTECH_TICKS_ENABLED:

            return { ...previousState, uiRtdmCreotechTicksEnabled: payload };
        case MAP_UI_RTDM_CREOTECH_TICKS_SHOW:

            return { ...previousState, uiRtdmCreotechTicksShow: payload };
        case MAP_RTDM_EXCHANGE_AIRCRAFTS_ENABLED:

            return { ...previousState, uiRtdmExchangeAircraftsEnabled: payload };
        case MAP_UI_RTDM_EXCHANGE_AIRCRAFTS_SHOW:

            return { ...previousState, uiRtdmExchangeAircraftsShow: payload };
        case MAP_UI_RTDM_SOAP_LPR_UNITS_TICKS_SHOW:

            return { ...previousState, uiRtdmSoapLprUnitsTicksShow: payload };
        case MAP_RTDM_EXCHANGE_TICKS_ENABLED:

            return { ...previousState, uiRtdmExchangeTicksEnabled: payload };
        case MAP_UI_RTDM_EXCHANGE_TICKS_SHOW:

            return { ...previousState, uiRtdmExchangeTicksShow: payload };
        case MAP_RTDM_SOAP_LPR_UNITS_TICKS_ENABLED:

            return { ...previousState, uiRtdmSoapLprUnitsTicksEnabled: payload };


        case MAP_SOCKET_IO_RTDM_SOURCES_ENABLED:
            return { ...previousState, mapSocketIoRtdmSourcesEnabled: payload };


        case MAP_UI_ENTITIES_VISIBILITY:
            return { ...previousState, visibilityPrefs: {...previousState.visibilityPrefs, ...payload} };
        case MAP_UI_HEIGHT_FILTER:
            return { ...previousState, heightFilter: {...previousState.heightFilter, ...payload} };

        case MAP_UI_CALLSIGN_FLOOR_NUMBERING_ENABLED:
            return { ...previousState, uiCallsignFloorNumberingEnabled: payload};

        case PANDORA_TRAFFIC_ENABLED:
            return { ...previousState, pandoraTrafficEnabled: payload};
        case PANDORA_LPR_TRAFFIC_ENABLED:
            return { ...previousState, pandoraLprTrafficEnabled: payload};
        case PANDORA_CREOTECH_TRAFFIC_ENABLED:
            return { ...previousState, pandoraCreotechTrafficEnabled: payload};

        case PANDORA_TRAFFIC_HEIGHT_FILTER:
            return { ...previousState, trafficHeightFilter: {...previousState.trafficHeightFilter, ...payload} };
        case MAP_CLEAR_SETUP_RQ: {
            return initialState
        }
        default:
            return previousState;
    }
};


const persistConfig = {
    key: 'mapUx',
    storage: storage,
    whitelist: [], //['visibilityPrefs']
};

//export default persistReducer(persistConfig, mapUxConfigReducer);

export default mapUxConfigReducer;
