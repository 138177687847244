import {getInitialCustomTheme} from "./initialCustomTheme";

export const colorCircle = {
    first: "#fff",
    second: "#F5F5F6", //nearly similar to grey.100
    third: "#E1E2E1", //nearly similar to grey.300
    forth: "#ff9800",
    fifth: "#37474f",
    white: "#fff",
    black: "#000"
};

export const primary = {
    light: '#62717b',
    main: '#37464f',
    dark: '#101f27',
    contrastText: '#fff',
};

export const secondary = {
    light: '#ffdc64',
    main: "#ff9800",
    dark: '#c17b00',
    contrastText: '#000',
};


export default {
    ...getInitialCustomTheme(primary, secondary, colorCircle)
};

