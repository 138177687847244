import WelcomeScreen from "../WelcomeScreen";
import {isChrome} from "../../../utils/browserHelpers";
import {chrome} from "../../../../docs";
import React from "react";

const NoChrome = () =>
    <WelcomeScreen
        shouldShow={() => !isChrome()}
        record={chrome}
        onConfirm={() => {}}
        onClose={() => {}}
    />;

export default NoChrome;