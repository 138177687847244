import React, {Component, createElement} from 'react';
//import Menu, { SubMenu, Item as MenuItem, Divider } from 'rc-menu';

// import 'rc-menu/assets/index.css';
import '../myDisplayAppMenu.css';
import {connect} from "react-redux";
import compose from 'recompose/compose';
import {
    storeUiAlertsV1Show, storeUiCheckinsV1Show,
    storeUiLprHelipadsV1Show,
    storeUiShowMatz, storeUiMissionsV2Show, storeUiRtMapZonesV1Show, storeUiZonesV2Show,
    storeUiSize, storeUiTickEnabled,
    storeUiVolume
} from "../../LogicV1Redux/actions/MapUxConfigActions";

export const MENU_KEYS = {
    TOGGLE_LIVE_ADSB:'TOGGLE_LIVE_ADSB',
    TOGGLE_ADSB_SOURCES:'TOGGLE_ADSB_SOURCES',
    TOGGLE_INSPECTOR_WND:'TOGGLE_INSPECTOR_WND',
    TOGGLE_MISSION_PLANNER: 'TOGGLE_MISSION_PLANNER',
    TOGGLE_ZONE_EDITOR: 'TOGGLE_ZONE_EDITOR',
    TOGGLE_TICK_UPDATE: 'TOGGLE_TICK_UPDATE',

    TOGGLE_ZONES_VIEWER_WND: 'TOGGLE_ZONES_VIEWER_WND',
    TOGGLE_ZONES_LIST_WND: 'TOGGLE_ZONES_LIST_WND',

    TOGGLE_SHOW_CREOTECH: 'TOGGLE_SHOW_CREOTECH',
    TOGGLE_SHOW_ADSBXCHANGE: 'TOGGLE_SHOW_ADSBXCHANGE',
    TOGGLE_SHOW_ALERTS: 'TOGGLE_SHOW_ALERTS', //hmm.. geometries CAN be zones duplicates
    TOGGLE_SHOW_WARNINGS: 'TOGGLE_SHOW_WARNINGS', //hmm.. warnings aka conflicts geometries are in fact zones duplicates

    TOGGLE_SHOW_ZONESV2: 'TOGGLE_SHOW_ZONESV2',
    TOGGLE_SHOW_CHECKINS: 'TOGGLE_SHOW_CHECKINS',

    TOGGLE_SHOW_MATZ: 'TOGGLE_SHOW_MATZ',
    TOGGLE_SHOW_LPR_HELIPADS: 'TOGGLE_SHOW_LPR_HELIPADS',

};

export const defaultMenuStore = {
    tickUpdate : false,
    liveADSB: false,
    adsbSources : false,

    inspectorWnd : false,
    zonesV2ListWnd : false,
    zonesViewerWnd : false,

    missionPlanner : false,
    zoneEditor : false,

};

export const menuMapping = {
    [MENU_KEYS.TOGGLE_LIVE_ADSB]:"liveADSB",
    [MENU_KEYS.TOGGLE_TICK_UPDATE]:"tickUpdate",
    [MENU_KEYS.TOGGLE_ADSB_SOURCES]:"adsbSources",

    [MENU_KEYS.TOGGLE_INSPECTOR_WND]:"inspectorWnd",
    [MENU_KEYS.TOGGLE_ZONES_LIST_WND]:"zonesV2ListWnd",
    [MENU_KEYS.TOGGLE_ZONES_VIEWER_WND]:"zonesViewerWnd",

    [MENU_KEYS.TOGGLE_MISSION_PLANNER]:"missionPlanner",
    [MENU_KEYS.TOGGLE_ZONE_EDITOR]:"zoneEditor",

    [MENU_KEYS.TOGGLE_SHOW_CREOTECH]:"showCreotech",
    [MENU_KEYS.TOGGLE_SHOW_ADSBXCHANGE]: 'showAdsbXchange',

    [MENU_KEYS.TOGGLE_SHOW_ALERTS]: 'showAlerts',
    [MENU_KEYS.TOGGLE_SHOW_CHECKINS]: 'showCheckins',

    [MENU_KEYS.TOGGLE_SHOW_ZONESV2]: 'showZonesV2',
    [MENU_KEYS.TOGGLE_SHOW_WARNINGS]: 'showWarnings',

    [MENU_KEYS.TOGGLE_SHOW_MATZ]: 'showMatz',
    [MENU_KEYS.TOGGLE_SHOW_LPR_HELIPADS]: 'showLprHelipads',

};


function menuStore2menuLabels (uxConfig) {
    return {
        liveAdsb : `!!!liveDevADSB:${uxConfig.liveADSB ? 'ON' : 'OFF'}`,
        tickUpdate : `tickUpdate:${uxConfig.uiTickEnabled ? 'ON' : 'OFF'}`,
        adsbSources : `!!!adsbSources:${uxConfig.adsbSources ? 'ON' : 'OFF'}`,

        inspectorWnd : `!!!inspectorWnd:${uxConfig.inspectorWnd ? 'ON' : 'OFF'}`,
        //zonesV2ListWnd : `zonesLoader:${menuStore.zonesV2ListWnd ? 'ON' : 'OFF'}`, //to re(? was working?) add /and or merge
        zonesViewerWnd : `!!!zoneViewer:${uxConfig.zonesViewerWnd ? 'ON' : 'OFF'}`, //hmm zonesViewer? zonesManager?

        missionPlanner : `!!!missionPlanner:${uxConfig.missionPlanner ? 'ON' : 'OFF'}`,
        zoneEditor : `!!!zoneEditor:${uxConfig.zoneEditor ? 'ON' : 'OFF'}`,
        showCreotech : `!!!showCreotech:${uxConfig.showCreotech ? 'ON' : 'OFF'}`,
        showAdsbXchange : `!!!showAdsbXchange:${uxConfig.showAdsbXchange ? 'ON' : 'OFF'}`,

        showAlerts : `showAlerts:${uxConfig.uiAlertsV1Show ? 'ON' : 'OFF'}`,
        showZonesV2 : `showZonesV2:${uxConfig.uiZonesV2Show ? 'ON' : 'OFF'}`,
        showZonesV1 : `showZonesV2:${uxConfig.uiRtMapZonesV1Show ? 'ON' : 'OFF'}`,

        showCheckins : `showCheckins:${uxConfig.uiCheckinsV1Show ? 'ON' : 'OFF'}`,

        showWarnings : `!!!!showWarningsV2:${uxConfig._showWarnings ? 'ON' : 'OFF'}`,

        showMatz : `showMatzV1:${uxConfig.uiMatzShow ? 'ON' : 'OFF'}`,
        showLprHelipads : `showLprHelipads:${uxConfig.uiLprHelipadsV1Show ? 'ON' : 'OFF'}`,

    }
}


//hmm test now, pure js
export function toggleMenu (e, mapUxConfig) {

    console.error('fixme -> finish swapping from menu store to mapuxconfig!!', mapUxConfig);

    return mapUxConfig;
    const menuItem = menuMapping[e.key];
    mapUxConfig[menuItem] = !mapUxConfig[menuItem];
    console.log('menu click ', e);
    console.log('menu click ', mapUxConfig);
    return mapUxConfig;
}

const FullMenuBase = ({mapUxConfig}) => {
    const labels = menuStore2menuLabels(mapUxConfig);


    console.log('fullMenu', mapUxConfig);
    return <div>rc-menu references removed due to SCP policy</div>

    // return (
    //     <Menu
    //         mode='horizontal'
    //         //onSelect={handleSelect}
    //         defaultActiveFirst
    //         onClick={(e) => toggleMenu(e, mapUxConfig)}
    //         forceSubMenuRender={true}
    //         className={'display-app'}
    //     >
    //         <MenuItem>
    //             <a href="/">DroneAdmin Alpha</a>
    //         </MenuItem>
    //
    //         <SubMenu title="Layers" key="1">
    //             <MenuItem key={MENU_KEYS.TOGGLE_ADSB_SOURCES}>{labels.adsbSources}</MenuItem>
    //             {/*<MenuItem key={MENU_KEYS.TOGGLE_ZONES_LIST_WND}>{labels.zonesList}</MenuItem>*/}
    //             {/*<Divider/>*/}
    //         </SubMenu>
    //         <SubMenu title="Editors" key="2">
    //             <MenuItem key={MENU_KEYS.TOGGLE_MISSION_PLANNER}>{labels.missionPlanner}</MenuItem>
    //             <MenuItem key={MENU_KEYS.TOGGLE_ZONE_EDITOR}>{labels.zoneEditor}</MenuItem>
    //         </SubMenu>
    //         <SubMenu title="Dev" key="3">
    //             <MenuItem key={MENU_KEYS.TOGGLE_LIVE_ADSB}>{labels.liveAdsb}</MenuItem>
    //             <MenuItem key={MENU_KEYS.TOGGLE_TICK_UPDATE}>{labels.tickUpdate}</MenuItem>
    //             <MenuItem key={MENU_KEYS.TOGGLE_INSPECTOR_WND}>{labels.inspectorWnd}</MenuItem>
    //         </SubMenu>
    //         {/*
    //         <MenuItem key="4">
    //             <a href="http://taobao.com" target="_blank">test link</a>
    //         </MenuItem>
    //         */}
    //
    //     </Menu>
    // )
};

export const LiveMapMenu = ({mapUxConfig}) => {

    const labels = menuStore2menuLabels(mapUxConfig); //FIXME!!!
    //console.log('LiveMapMenu', menuStore);
    const backUrl = `#`;
    return <div>rc-menu references removed due to SCP policy</div>

    // return (
    //     <Menu
    //         mode='horizontal'
    //         //onSelect={handleSelect}
    //         defaultActiveFirst
    //         onClick={(e) => toggleMenu(e, mapUxConfig)} //FIXME!!!
    //         forceSubMenuRender={true}
    //         className={'display-app'}
    //     >
    //         <MenuItem>
    //             <a href={backUrl}>Live Map</a>
    //         </MenuItem>
    //         <SubMenu title="Layers" key="1">
    //
    //
    //             <Divider/>
    //
    //             {/*<MenuItem key={MENU_KEYS.TOGGLE_ZONES_LIST_WND}>{labels.zonesList}</MenuItem>*/}
    //             {/*<Divider/>*/}
    //         </SubMenu>
    //         <SubMenu title="Dev" key="3">
    //
    //             <MenuItem key={MENU_KEYS.TOGGLE_SHOW_MATZ}>{labels.showMatz}</MenuItem>
    //             <MenuItem key={MENU_KEYS.TOGGLE_SHOW_LPR_HELIPADS}>{labels.showLprHelipads}</MenuItem>
    //
    //             <Divider/>
    //
    //
    //             <MenuItem key={MENU_KEYS.TOGGLE_SHOW_ALERTS}>{labels.showAlerts}</MenuItem>
    //             <MenuItem key={MENU_KEYS.TOGGLE_SHOW_ZONESV2}>{labels.showZonesV2}</MenuItem>
    //
    //
    //             <MenuItem key={MENU_KEYS.TOGGLE_SHOW_CHECKINS}>{labels.showCheckins}</MenuItem>
    //
    //             <MenuItem key={MENU_KEYS.TOGGLE_SHOW_CREOTECH}>{labels.showCreotech}</MenuItem>
    //             <MenuItem key={MENU_KEYS.TOGGLE_SHOW_ADSBXCHANGE}>{labels.showAdsbXchange}</MenuItem>
    //
    //             <MenuItem key={MENU_KEYS.TOGGLE_ADSB_SOURCES}>{labels.adsbSources}</MenuItem>
    //             <MenuItem key={MENU_KEYS.TOGGLE_TICK_UPDATE}>{labels.tickUpdate}</MenuItem>
    //
    //
    //             <Divider/>
    //
    //
    //             <MenuItem key={MENU_KEYS.TOGGLE_INSPECTOR_WND}>{labels.inspectorWnd}</MenuItem>
    //             <MenuItem key={MENU_KEYS.TOGGLE_ZONES_VIEWER_WND}>{labels.zonesViewerWnd}</MenuItem>
    //
    //         </SubMenu>
    //
    //     </Menu>
    // )
};


//huh somehow cannot render children, as is, and bit too afraid to clone deck.gl instance
//...so no children support, menu is still 'parallel' to map
//31-01-19 -> as it probably should be ...
class MyMenuBase extends Component {

    render() {

        //console.log('MyMenuBase, props', this.props);

        const {menuComponent, mapUxConfig} = this.props;

        if (menuComponent === null) return null;

        //console.log(children);
        return createElement(menuComponent, {mapUxConfig});
        //return MyMenu;
        //const MyMenu = menu;
        //return (<MyMenu menuStore={menuStore} onMenuChange={onMenuChange} >{}</MyMenu>)
    }
}

//common redux connect

const mapStateToProps = (state, props) => {
    //console.log('AtcTabs mapStateToProps', state, props);
    return {
        mapUxConfig: state.mapUxConfig,
        // uiVolume: state.mapUxConfig.uiVolume,
        // uiSize: state.mapUxConfig.uiSize,
        // uiLprHelipadsV1Show: state.mapUxConfig.uiLprHelipadsV1Show,
        // uiMatzShow: state.mapUxConfig.uiMatzShow,
        // uiTickEnabled: state.mapUxConfig.uiTickEnabled,
        // uiRtMapZonesV1Show: state.mapUxConfig.uiRtMapZonesV1Show,
        // uiZonesV2Show: state.mapUxConfig.uiZonesV2Show,
        // uiShowMissionsV2: state.mapUxConfig.uiShowMissionsV2,
        // uiAlertsV1Show: state.mapUxConfig.uiAlertsV1Show,
        // uiCheckinsV1Show: state.mapUxConfig.uiCheckinsV1Show,

    }
};

const enhance = compose(
    //translate,
    //muiThemeable(), // force redraw on theme change
    connect(
        mapStateToProps,
        {
            storeUiVolume,
            storeUiSize,

            storeUiLprHelipadsV1Show: storeUiLprHelipadsV1Show,
            storeUiShowMatz,
            storeUiTickEnabled,

            storeUiAlertsV1Show: storeUiAlertsV1Show,
            storeUiCheckinsV1Show: storeUiCheckinsV1Show,
            storeUiMissionsV2Show: storeUiMissionsV2Show,
            storeUiRtMapZonesV1Show: storeUiRtMapZonesV1Show,
            storeUiZonesV2Show: storeUiZonesV2Show
            //userLogout: userLogoutAction,
        }
    )
);

export const MyMenu = enhance(MyMenuBase);
