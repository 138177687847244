import {CaaApplicationHoldStatuses, CaaApplicationStatuses} from "../../LogicV1Redux/constants/CaaApplicationEnums";
import TranslatableTypography from "../components/TranslatableTypography";
import React from "react";
import {
    APPLICATION_PRINCIPAL_COMPANY,
    APPLICATION_PRINCIPAL_OPERATOR
} from "../../LogicV1Redux/constants/ApplicationsConstants";


export const isCaaApplMeetingHoldStatus = (status) => {
    return Object.values(CaaApplicationHoldStatuses).includes(status);
};

export const isCaaApplProcessStatus = (status) => {
    return !(
        status === CaaApplicationStatuses.REJECTED ||
        status === CaaApplicationStatuses.ACCEPTED ||
        status === CaaApplicationStatuses.CREATED
    );
};

export const getCaaStatusTranslationResource = (status) => {
    let translation;
    switch (status) {
        case CaaApplicationStatuses.VERIFY:
            translation = 'resources.applications.status.pending';
            break;
        case CaaApplicationStatuses.VERIFY_AGAIN:
            translation = 'resources.applications.status.pendingAgain';
            break;
        case CaaApplicationStatuses.CREATED:
            translation = 'resources.applications.status.created';
            break;
        case CaaApplicationStatuses.REJECTED:
            translation = 'resources.applications.status.rejected';
            break;
        case CaaApplicationStatuses.ACCEPTED:
            translation = 'resources.applications.status.accepted';
            break;
        case CaaApplicationStatuses.MODIFIED:
            translation = 'resources.applications.status.modified';
            break;
        case CaaApplicationStatuses.HOLD_MEETING_REQUEST:
            translation = 'resources.applications.status.meetingRequest';
            break;
        case CaaApplicationStatuses.HOLD_MEETING_ACCEPTED:
            translation = 'resources.applications.status.meetingAccepted';
            break;
        case CaaApplicationStatuses.HOLD_MEETING_REJECTED:
            translation = 'resources.applications.status.meetingRejected';
            break;
        default:
            translation = 'BAD_STATUS'
    }
    return translation;
};

export const translateCaaStatusToTypo = (status) => {
    return <TranslatableTypography component='span' content={getCaaStatusTranslationResource(status)} options={{smart_count: 1}}/>
};

export const translatePrincipalToTypo = (principal) => {
    let translation;
    switch (principal) {
        case APPLICATION_PRINCIPAL_OPERATOR:
            translation = "resources.operators.name";
            break;
        case APPLICATION_PRINCIPAL_COMPANY:
            translation = "myroot.company.name2";
            break;
    }
    return <TranslatableTypography component='span' content={translation} options={{smart_count: 1}}/>
};
