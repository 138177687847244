import {
    INFO_MSG_DRONE_PHOTO_SERIAL_NO,
    INFO_MSG_MISSION_CREATE_BVLOS, INFO_MSG_MISSION_CREATE_BVLOS_SUAVO,
    INFO_MSG_MISSION_CREATE_VLOS, INFO_MSG_MISSION_CREATE_VLOS_SUAVO,
    INFO_MSG_MISSION_EDITOR,
    INFO_MSG_MISSION_TARGET_BVLOS,
    INFO_MSG_MISSION_TARGET_VLOS
} from "./InfoboxConstants";
import {
    ALERT_MSG_CERT_STATUS_CHANGE,
    ALERT_MSG_DRONE_STATUS_CHANGE,
    ALERT_MSG_MISSION_STATUS_CHANGE
} from "./AlertboxConstants";

/**
 * Maps situation id's to translations.
 * @param id
 * @returns {string[]}
 */
export const getTranslationResource = (id) => {
    // Fill in the switch with translations needed for info
    switch(id) {
        case INFO_MSG_MISSION_CREATE_VLOS:
            return [
                'myroot.info.vlosSelection.title',
                'myroot.info.vlosSelection.content',
            ];
        case INFO_MSG_MISSION_CREATE_BVLOS:
            return [
                'myroot.info.bvlosSelection.title',
                'myroot.info.bvlosSelection.content',
            ];
        case INFO_MSG_MISSION_CREATE_VLOS_SUAVO:
            return [
                'myroot.info.vlosSelectionWithPriv.title',
                'myroot.info.vlosSelectionWithPriv.content',
            ];
        case INFO_MSG_MISSION_CREATE_BVLOS_SUAVO:
            return [
                'myroot.info.bvlosSelectionWithPriv.title',
                'myroot.info.bvlosSelectionWithPriv.content',
            ];
        case INFO_MSG_DRONE_PHOTO_SERIAL_NO:
            return [
                'myroot.info.dronePhotoSerial.title',
                'myroot.info.dronePhotoSerial.content',
            ];
        case INFO_MSG_MISSION_EDITOR:
            return [
                'myroot.info.missionEditor.title',
                'myroot.info.missionEditor.content',
            ];
        case INFO_MSG_MISSION_TARGET_VLOS:
            return [
                'myroot.info.targetVlos.title',
                'myroot.info.targetVlos.content',
            ];
        case INFO_MSG_MISSION_TARGET_BVLOS:
            return [
                'myroot.info.targetBvlos.title',
                'myroot.info.targetBvlos.content',
            ];
        case ALERT_MSG_CERT_STATUS_CHANGE:
            return [
                'myroot.info.alert.certChange'
            ];
        case ALERT_MSG_DRONE_STATUS_CHANGE:
            return [
                'myroot.info.alert.droneChange'
            ];
        case ALERT_MSG_MISSION_STATUS_CHANGE:
            return [
                'myroot.info.alert.missionChange'
            ];
    }

};