import {endDraw} from "./deckEditor";

import PubSub from "pubsub-js";
import {PubSubSignals} from "../../../LogicV1Redux/constants/PubSubSignals";


//pure functions
//no z-axis at the moment, so keeping clear
export function onLocationDrawClick(deckLocation, latLng, layerClickEvent) {
    // sprint fixme some better dirty setup needed
    PubSub.publish(PubSubSignals.SET_DRAW_EDITOR_DIRTY);

    if (!deckLocation) return;

    deckLocation = deckLocation.recalc(latLng);
    console.log('closing deckLocation');
    endDraw(deckLocation);

    return deckLocation;
}

export function onLocationDrawMouseMove(deckLocation, latLng) {
    console.log('point draw move', deckLocation);

    if (deckLocation) {
        deckLocation = deckLocation.recalc(latLng);
    }
    return deckLocation;
}
