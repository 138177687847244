import React from 'react';

import {CardActions, ListButton,} from 'react-admin';
import BackButton from "../../mui_test_suite/customComponents/BackButton";
import {withStyles} from "@material-ui/core/styles";

const styles = {
    box: {
        display: "contents"
    },
    push: {
        marginLeft: "auto"
    }
};

class ShowActions extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        const {classes, basePath} = this.props;

        return (
            <CardActions>
                <div className={classes.box}>
                    <BackButton/>
                    <div className={classes.push}/>
                    <ListButton basePath={basePath}/>
                </div>
            </CardActions>
        )
    }
}

export default withStyles(styles)(ShowActions);
