import connect from "react-redux/es/connect/connect";

//almost the same as translate, i guess..

const Page = (props) => {
    const {locale, content} = props;
    //console.error('props,', props);
    return content[locale];
};

const mapStateToProps = state => {
    return {
        locale: state.i18n.locale,
    }
};

export default connect(
        mapStateToProps,
        null
    ) (Page)
