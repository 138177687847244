
///ra2-playground/issues/101, aeronautical conversion
export const m2ftCeiling = (m) => {
    return Math.ceil(m * 3.28084);
};

export const ft2m = (ft) => {
    return ft * 0.3048;
};

export const mSq2kmSq = (mSq) => {
    return mSq/1000000
};


const _deg2dms = (val, dir) => {
    var D = parseFloat(val);
    if (dir.toLowerCase() === 'lat') {
        dir = D < 0 ? 'S' : 'N';
    } else if (dir.toLowerCase() === 'lng') {
        dir = D < 0 ? 'W' : 'E';
    } else {
        throw new Error('direction required');
    }

    const deg = 0 | (D < 0 ? D = -D : D);
    let min = 0 | (D < 0 ? D = -D : D) % 1 * 60;
    //hopefully polyfilled by babel, in old angular was lodash
    //https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/padStart
    min = min.toString().padStart(2, "0");
    //aargh. pazp sec precision 0,
    //fixme parametrize precision
    //var sec = ((0|D*60%1*60000)/1000).toFixed(3);
    let sec = Math.round((0 | D * 60 % 1 * 60000) / 1000);
    sec = sec.toString().padStart(2, "0");

    return `${dir}${deg}º${min}'${sec}"`;
};

//todo add precision parametrization (see _deg2dms)
export const LatLng2Dms = ({lat, lng}) => {
    let latDms, lngDms;

    latDms = _deg2dms(lat, 'lat');
    lngDms = _deg2dms(lng, 'lng');

    return [lngDms, latDms]
};

export const m2ft = (m) => {
    return m * 3.28084;
};

//currently only [lat,lng]
//fixme -> other formats
export function fixLatLngPrecision(LatLng) {
    //console.log('fixing latlng', LatLng[0], LatLng[1]);
    let newLatLng = [0,0];
    newLatLng[0] = Math.round(LatLng[0] * 1000000)/1000000;
    newLatLng[1] = Math.round(LatLng[1] * 1000000)/1000000;
    return newLatLng;
}

