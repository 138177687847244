import React from 'react';
import MyFileField from "./MyFileField";
import get from 'lodash.get';
import MyFileInputPreview from "./MyFileInputPreview";
import { withStyles } from '@material-ui/core/styles';
import {connect} from 'react-redux';
import {compose} from 'recompose';

//TODO refactoring - move styles to MyFileInputPreview
const styles = {
    removeButton: {
        display: 'inline-block',
        position: 'relative',
        float: 'left',
        '& button': {
            position: 'absolute',
            top: '0.5rem',
            right: '0.5rem',
            minWidth: '2rem',
            opacity: 0,
        },
        '&:hover button': {
            opacity: 1,
        },
    },
};
const FilesField = ({record, source, classes, blobs}) => {
    const arr = get(record, source);
    if (!arr) return null;
    return (
        <div>
            {
                arr.map((item, idx) => {
                    console.log('MyFilesField', item);
                    return (
                        <MyFileInputPreview file={item} key={idx} className={classes.removeButton} viewOnly>
                            <MyFileField key={idx} record={item} blobs={blobs}/>
                        </MyFileInputPreview>
                    )
                })
            }
        </div>
    )
};
FilesField.defaultProps = {addLabel: true};

export default compose(
    connect(
        state => ({blobs: state.myUi.blobs}),
    ),
    withStyles(styles)
)(FilesField);