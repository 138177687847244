//v 0.0
import React from 'react';

import * as dd from 'dis-gui';

import PubSub from "pubsub-js";
import logger from "../../LogicV1Redux/oldLogic/Logger";
import Store from "store/dist/store.modern";
import {PubSubSignals} from '../../LogicV1Redux/constants/PubSubSignals'

import {getZoneRules,setZoneVisibility, showHideAll} from "../../LogicV1Redux/oldLogic/ZoneRules";
import {Rnd} from "react-rnd";
import {verticalResizeOnly} from "./rndConstants";
//copy paste from doc
const ddStyle = {
    paddingX: 3,
    paddingY: 3,
    backgroundColor: '#EEE',
    lowlight: '#DDD',
    lowlighterr: '#FBB',
    highlight: '#444',
    separator: '1px solid #DDD',
    label: {
        fontColor: '#444',
        fontWeight: 'normal'
    },
    labelWidth: 100,
    controlWidth: 250,
    top: '0px',
    right: '0px',
    position: 'relative'
};

const defaults = {

    authToken : '',
    authType : 'Govs',
    vertScale : 1,
    topCutoff : 100000,
    bottomCutoff : 0,
    showHideAll : true,
    //hmm
};

//to remove!
export function getLocalStore() {
    let zoneViewerAuthStore = Store.get('myZonesViewerAuthV0', defaults);

    return {zoneViewerAuthStore};
}

export function setLocalStore(state) {
    const persistent = {
        //authToken : state.zoneViewerAuthStore.authToken,
        //authType : state.zoneViewerAuthStore.authType,
        vertScale : state.zoneViewerAuthStore.vertScale,
        topCutoff : state.zoneViewerAuthStore.topCutoff,
        bottomCutoff : state.zoneViewerAuthStore.bottomCutoff,

    };
    Store.set('myZonesViewerAuthV0', {...defaults, ...persistent});
}

const zoneTypesRenderer = (zoneTypes) => {
    //console.log(zones);
    return Object.entries(zoneTypes).map( (entry, idx) => {
        //console.log(entry[0], entry[1], idx);
        return (
            <dd.Checkbox
                key = {idx}
                label={entry[0]}
                checked={entry[1].visible}
                onChange={(e)=>{
                    console.log('zoneVis click', entry[0], entry[1], e);
                    setZoneVisibility(entry[0], e);

                }}
            />
        );
    });
};

//FIXME dedupe with redux

export default class ZonesViewerWindow extends React.Component {

    constructor(props) {
        super(props);

        this.state = getLocalStore();
        //buu! source of truth here now..

        this.onVerticalScaleChange =this.onVerticalScaleChange.bind(this);
        this.onTopCutoffChange =this.onTopCutoffChange.bind(this);
        this.onBottomCutoffChange =this.onBottomCutoffChange.bind(this);
        this.onShowHideAll = this.onShowHideAll.bind(this);
    }

    onVerticalScaleChange(e) {
        console.log('changin vert scale',e);
        let zoneViewerAuthStore = this.state.zoneViewerAuthStore;
        zoneViewerAuthStore.vertScale = e;
        PubSub.publish(PubSubSignals.ZONE_VERTICAL_SCALE, e);

        this.setState({zoneViewerAuthStore});
    }

    onTopCutoffChange(e) {
        console.log('changin top cutoff',e);
        let zoneViewerAuthStore = this.state.zoneViewerAuthStore;
        zoneViewerAuthStore.topCutoff = e;
        PubSub.publish(PubSubSignals.ZONE_TOP_CUTOFF, e);

        this.setState({zoneViewerAuthStore});
    }

    onBottomCutoffChange(e) {
        console.log('changin bottom cutoff',e);
        let zoneViewerAuthStore = this.state.zoneViewerAuthStore;
        zoneViewerAuthStore.bottomCutoff = e;
        PubSub.publish(PubSubSignals.ZONE_BOTTOM_CUTOFF, e);

        this.setState({zoneViewerAuthStore});
    }

    onShowHideAll(e) {
        showHideAll(e)
    }

    componentDidMount() {
        PubSub.publish(PubSubSignals.ZONE_VERTICAL_SCALE, this.state.zoneViewerAuthStore.vertScale);
        PubSub.publish(PubSubSignals.ZONE_BOTTOM_CUTOFF, this.state.zoneViewerAuthStore.bottomCutoff);
        PubSub.publish(PubSubSignals.ZONE_TOP_CUTOFF, this.state.zoneViewerAuthStore.topCutoff);

    }

    componentWillMount() {
        const state = getLocalStore();
        this.setState(state);
        //


        // this.pubsubZonesRulesChange = PubSub.subscribe(PubSubSignals.ON_ZONES_RULES_CHANGE, (msg, data) => {
        //     console.log('onZonesRulesChange zone editor');
        //     console.log('pubsub', msg, data);
        //
        //     this.forceUpdate();
        // });

    }

    componentWillUnmount() {

        // PubSub.unsubscribe(this.pubsubDrawEnd);
        //PubSub.unsubscribe(this.pubsubZonesLoaded);
        //PubSub.unsubscribe(this.pubsubZonesRulesChange);

    }


    render() {

        //console.log(this.state);
        setLocalStore(this.state);

        //console.log(this.state);

        const zoneRules = getZoneRules();

        const showGeometryRenderer = (this.state.zoneRecord);
        const { dragEnableHandler, dragDisableHandler}  = this.props;
        return (

            <dd.GUI style = {ddStyle} >
                {/* to reenable as splitted window for all stuff
                <dd.Folder onFocus={dragDisableHandler}
                           onBlur={dragEnableHandler }
                    label='Visibility1' expanded={true}>
                    <div

                    >
                    <dd.Number label='VertScale'
                               value={this.state.zoneViewerAuthStore.vertScale}
                               min={.1}
                               max={10}
                               step={.1}
                               onFinishChange={this.onVerticalScaleChange}
                    />
                    <dd.Number label='TopCutoff'
                               value={this.state.zoneViewerAuthStore.topCutoff}
                               min={0}
                               max={100000}
                               step={10}
                               onFinishChange={this.onTopCutoffChange}
                    />
                    <dd.Number label='BottomCutoff'
                               value={this.state.zoneViewerAuthStore.bottomCutoff}
                               min={0}
                               max={100000}
                               step={10}
                               onFinishChange={this.onBottomCutoffChange}
                    />
                    </div>
                </dd.Folder>
                */}
                <dd.Folder label='Zone Types' expanded={false}>
                    <dd.Checkbox
                        key = 'all'
                        label='Show/Hide ALL'
                        checked={this.state.zoneViewerAuthStore.showHideAll}
                        onChange={this.onShowHideAll}
                    />
                    {zoneTypesRenderer(zoneRules)}
                </dd.Folder>
            </dd.GUI>
        )
    }

}
//    console.error('fix css!, without sizeMe if possible!')

export const RndZonesViewerWnd = ({viewportData}) => (
    <Rnd
        className = 'rnd-window'
        default={{
            x: 10, //same place as draw toolbox.. fixme?
            y: 10,

        }}
        maxHeight="95%"
        bounds = "parent"
        enableUserSelectHack = {false}
        enableResizing = {verticalResizeOnly}
        dragHandleClassName = "rnd-dragbar"
    >
        <div className="rnd-dragbar">Zone Viewer</div>
        <div style={{overflowY:"auto", maxHeight:(viewportData.height-100)+"px"}}>
            <ZonesViewerWindow />
        </div>
    </Rnd>
);

