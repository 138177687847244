import React from "react"
import {AdminRouter as CoreAdminRouter} from "react-admin";
import {connect} from "react-redux"
import {createMuiTheme} from "@material-ui/core";
import MyLayoutThemeDecorator from "./MyLayoutThemeDecorator";
import mainTheme from '../themes/mainTheme';
import armyTheme from '../themes/armyTheme';
import lauTheme from '../themes/lauTheme';
import {startup} from "../../LogicV1Redux/actions/AppStateActions"
import {INIT_PHASES} from "../../LogicV1Redux/constants/AppStateEnums";
import {Authenticated, WithPermissions} from 'react-admin';

const themeArmy = createMuiTheme({
    ...armyTheme
});

const themeLau = createMuiTheme({
    ...lauTheme
});

const themeOrangeGrey = createMuiTheme({
    ...mainTheme
});

const themeArray = [undefined, themeOrangeGrey, themeArmy, themeLau];


class LoggedIn extends React.Component {
    componentDidMount() {
        this.props.startup();
    }

    render() {
        return (
            <Authenticated>
                {this.props.coreAdmin}
            </Authenticated>)
    }
}

//hmm looks like best place for initing state after login
class MyAdminThemeDecorator extends React.Component {

    render() {
        const {
            children,
            myThemeNumber,
            myAuth,
            startup,
            appState,
            requestLoopbackVersion,
        } = this.props;

        const theme = themeArray[myThemeNumber];

        const coreAdmin = (<CoreAdminRouter
            {...this.props}
            theme={theme}
            appLayout={MyLayoutThemeDecorator}
        >
            {children}
        </CoreAdminRouter>);

        console.log('myAuth', myAuth);

        return (myAuth.token)
            ? <LoggedIn coreAdmin={coreAdmin} startup={startup} appState={appState}/>
            : <>{coreAdmin}</>

    }
};


const mapStateToProps = state => {
    return {
        myThemeNumber: state.myTheme.themeNo,
        appState: state.appState,
        myAuth: state.myAuth,
    }
};

export default connect(mapStateToProps, {startup})(MyAdminThemeDecorator);
