import PubSub from "pubsub-js";
import {PubSubSignals} from "../../../LogicV1Redux/constants/PubSubSignals";

const EDIT_MODE = {
    SELECT : 'SELECT',
    MOVE_VTX:'VTX', //vertex
    MOVE_PIPE: 'MOVE'
};
let editMode = EDIT_MODE.SELECT;
let selectedIdx = null;
let moveLatLng = null;

//see deck poly notes

//no z-axis at the moment, so keeping clear
export function onPipeEditClick(deckPipe, latLng, layerClickEvent) {

    if (editMode === EDIT_MODE.SELECT) {
        const lceObject = (layerClickEvent && layerClickEvent.object)?layerClickEvent.object:null;

        for (let i=0; i < deckPipe.markers.length; i++) {
            if (lceObject === deckPipe.markers[i]) {
                editMode = EDIT_MODE.MOVE_VTX;
                selectedIdx = deckPipe.markers[i].llIdx;
                console.log('selected idx', i);
                return deckPipe; //probably some styling later..
            }
        }

        if (lceObject === deckPipe) {

            editMode = EDIT_MODE.MOVE_PIPE;
            moveLatLng = latLng;
            return deckPipe; //probably some styling later..

        } else {
            return null; //deselect
        }
    }
    else if (editMode === EDIT_MODE.MOVE_VTX) {
        editMode = EDIT_MODE.SELECT;
        let latlngs = deckPipe.helpers;
        latlngs[selectedIdx] = latLng;

        // sprint fixme some better dirty setup needed
        PubSub.publish(PubSubSignals.SET_DRAW_EDITOR_DIRTY);

        return deckPipe.recalc(latlngs);

    }
    else if (editMode === EDIT_MODE.MOVE_PIPE) {
        editMode = EDIT_MODE.SELECT
    }
    return deckPipe;
}

export function onPipeEditMouseMove(deckPipe, latLng) {
    if (editMode === EDIT_MODE.MOVE_VTX) {
        let latlngs = deckPipe.helpers;
        latlngs[selectedIdx] = latLng;

        return deckPipe.recalc(latlngs);
    }
    else if (editMode === EDIT_MODE.MOVE_PIPE) {

        //console.log('onPolyEditMouseMove, MOVE_VTX');

        let latlngs = deckPipe.helpers;

        let offset = [];
        offset[0] = latLng[0] - moveLatLng[0];
        offset[1] = latLng[1] - moveLatLng[1];
        for (let i = 0; i < latlngs.length; i++) {
            latlngs[i][0] += offset[0];
            latlngs[i][1] += offset[1];
        }
        moveLatLng = latLng;
        //console.log('onPolyEditMouseMove, recalc');

        return deckPipe.recalc(latlngs);
    }
    return null;

}
