import React from 'react';
import compose from 'recompose/compose';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import { Link, translate } from 'react-admin';
import { stringify } from 'query-string';


const styles = {
    icon: { paddingRight: '0.5em' },
    link: {
        display: 'inline-flex',
        alignItems: 'center',
    },
};

//re/fix icon support
const ButtonLink = ({ label, to, classes, translate}) => (
    <Button
        variant="contained"
        color="primary"
        component={Link}
        to={to}
        className={classes.link}
    >
        {/*<ProductIcon className={classes.icon} />*/}
        {(translate)?translate(label):label}
    </Button>
);

const enhance = compose(
    withStyles(styles),
    translate
);

export default enhance(ButtonLink);