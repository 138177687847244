//to cleanup
export const InspectTypes = {
    NO_DATA: 'NO_DATA', //right now fallback for background (like map itself)
    ZONEV1_FEATURE: 'ZONEV1_FEATURE', //not transformed, so feature
    ZONEV2: 'ZONEV2',
    //UNITV2: 'UNITV2', fixme -> enable when tested deserialize flow (Currently as zoneV2)
    //POIV2: 'POIV2', fixme -> enable when tested deserialize flow (Currently as zoneV2)
    LPR_HELIPAD_V1: 'LPR_HELIPAD_V1',
    CUSTOM_ALERT_V1: 'CUSTOM_ALERT_V1',
    CUSTOM_ALERT_V2: 'CUSTOM_ALERT_V2',

    CHECKINV1: 'CHECKINV1',
    CHECKINV2: 'CHECKINV2',

    MISSIONV2: 'MISSIONV2',
    LEGV2: 'LEGV2', //same in mission and zone?
    MISSIONLEGFIXME: 'MISSIONLEGFIXME', //same in mission and zone?

    LIVE_ADSB: 'LIVE_ADSB',
    RTDM_EXCHANGE_AIRCRAFT: 'RTDM_EXCHANGE_AIRCRAFT',
    RTDM_EXCHANGE_TICK: 'RTDM_EXCHANGE_TICK',
    RTDM_CREOTECH_AIRCRAFT: 'RTDM_CREOTECH_AIRCRAFT',
    RTDM_CREOTECH_TICK: 'RTDM_CREOTECH_TICK',
    RTDM_SOAP_LPR_UNITS_TICK: 'RTDM_SOAP_LPR_UNITS_TICK',
    RTDM_AEROBITS_TICK: 'RTDM_AEROBITS_TICK',

};
