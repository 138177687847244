import WelcomeScreensManager from "./WelcomeScreensManager";
import NoEnoughMissionData from "./customizedScreens/NoEnoughMissionData";
import RodoAndDisclaimer from "./customizedScreens/RodoAndDisclaimer";
import React from "react";
import {withCookies} from "react-cookie";
import {connect} from "react-redux";
import compose from 'recompose/compose';
import {userLogout as userLogoutAction} from 'ra-core';
import NoChrome from "./customizedScreens/NoChrome";

const WelcomeScreens = ({cookies, userLogout}) =>

    <WelcomeScreensManager welcomeScreens={[
        RodoAndDisclaimer({cookies, userLogout}),
        NoChrome(),
        NoEnoughMissionData(),
    ]}/>;

const enhance = compose(
    withCookies,
    connect(
        null,
        (dispatch, {redirectTo}) => ({
            userLogout: () => dispatch(userLogoutAction(redirectTo)),
        })
    )
);

export default enhance(WelcomeScreens);

