import React from "react";
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import {warningColor} from "./dashboard/style/material-dashboard-react";
import {Warning} from "@material-ui/icons";
import Icon from "@material-ui/core/Icon/Icon";

const styles = theme => ({
    info: {
        display: "flex",
        alignItems: "center",
    },
    iconColor: {
        color: theme.palette.primary.contrastText
    }
});

export const WarningText = withStyles(styles)(

    ({classes, content, className, colored}) =>
        <span className={classnames(classes.info, className)}>
            {colored ?
                <Warning style={{color: warningColor}}/>
                :
                <Icon className={classes.iconColor}>warning</Icon>
            }
            <span>&nbsp;</span>
            {content}
        </span>
);