import React from 'react';
export const helpPage = (
    <div id="help-content">
        <h2>Helpdesk</h2>
        Factual support concerning UAV fly regulations,
        appointed PANSA procedures and system operation is provided by<br/>staff members of
        Unmanned Aerial Vehicles Operations’ Management Department (OSU):
        <ul>
            <li>
                PANSA ext. phones: <b>5715</b>, <b>7553</b>, <b>5741</b>, <b>5749</b>
            </li>
            <li>
                mail: <a href="mailto:drony@pansa.pl">drony@pansa.pl</a>
            </li>
        </ul>
        <br/>

        Technical support is provided by PANSA Technical Duty Officer:
        <ul>
            <li>
                PANSA ext. phone: <b>6666</b> (H24/7)
            </li>
            <li>
                mail: <a href="mailto:dyzurny.at@pansa.pl">dyzurny.at@pansa.pl</a>
            </li>
            <li>
                public phone: <b>+48 22 574 66 66</b>
            </li>
        </ul>
    </div>
);

export const richText = {
    title: `<h2>Helpdesk</h2>`,
    content: ` Factual support concerning UAV fly regulations,
        appointed PANSA procedures and system operation is provided by staff members of
        Unmanned Aerial Vehicles Operations’ Management Department (OSU):
    <ul>
        <li>
            PANSA ext. phones: <b>5715</b>, <b>7553</b>, <b>5741</b>, <b>5749</b>
        </li>
        <li>
            mail: <a href="mailto:drony@pansa.pl">drony@pansa.pl</a>
        </li> 
    </ul>
    <br/>

    Technical support is provided by PANSA Technical Duty Officer:
    <ul>
        <li>
            PANSA ext. phone: <b>6666</b> (H24/7)
        </li>
        <li>
            mail: <a href="mailto:dyzurny.at@pansa.pl">dyzurny.at@pansa.pl</a>
        </li>
        <li>
            phone: <b>+48 22 574 66 66</b>
        </li>
    </ul>
    `,
};
