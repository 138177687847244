import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import pure from 'recompose/pure';
import { Link, translate } from 'react-admin';
import {withStyles} from "@material-ui/core";



const styles = theme => ({
    link: {
        color: theme.palette.colorCircle.link,
        textDecoration: "underline"
    }
});


//aor text field with added link support
// basically -> for custom link replacament
//fixme? translatable?
const MyLinkField =  withStyles(styles)(({ source, idSource, record = {}, elStyle, link, classes }) => {

    const id = get(record, idSource);

    //console.log('MyLinkField', record, id);

    const finalLink = link.replace('{id}',id);

    return (
        <Link classes={classes} to={{pathname: finalLink}} >{get(record, source)}</Link>
    )
});

MyLinkField.propTypes = {
    addLabel: PropTypes.bool,
    elStyle: PropTypes.object,
    label: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
};

const PureMyLinkField = pure(MyLinkField);

PureMyLinkField.defaultProps = {
    addLabel: true,
};

export default PureMyLinkField;
