//import { USER_LOGIN_SUCCESS, USER_LOGOUT } from './MyAuthActions.js';
import {MY_STORE_SETAUTH, MY_STORE_CLEARAUTH} from "./MyAuthConstants";

const defaultState = {};

//only persistable, see myself reducer..

//hmm defaults?
export default (previousState = defaultState, { type, payload }) => {
    //console.log('auth reducer, ',type, payload);

    switch (type) {
        case MY_STORE_SETAUTH:
            console.log('----- auth reducer MY_STORE_SETAUTH:', type, payload);

            return { ...payload };
        case MY_STORE_CLEARAUTH:
            console.log('----- auth reducer MY_STORE_CLEARAUTH:', type, payload);

            return defaultState;
        default:
            return previousState;
    }
};
