import {Avatar, Chip, withStyles} from "@material-ui/core";
import React from "react";


const styles = {
    avatar: {
        color: "#ffffff",
        backgroundColor: "#ff1408",
        borderRadius: "46%",
        fontSize: "0.7rem"
    },
    colorPrimary: {
        color: "#ff1408",
        backgroundColor: "#ffffff",
        border: "solid",
        borderWidth: "2px",
        borderRadius: "18px",
        fontSize: "1.9rem",
        fontWeight: "500",
    }
};

const U112Chip = ({classes}) => (
    <Chip
        avatar={
            <Avatar>UAVO</Avatar>
        }
        label="112"
        classes={classes}
        color="primary"
    />
);

export default withStyles(styles)(U112Chip);