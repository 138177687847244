import Dialog from "@material-ui/core/Dialog/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import Button from "@material-ui/core/Button/Button";
import React from "react";
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import connect from "react-redux/es/connect/connect";
import {withStyles} from "@material-ui/core";
import {RichTextField} from "ra-ui-materialui";
import ActionCheck from '@material-ui/icons/CheckCircle';

const styles = () => ({
    iconPaddingStyle: {
        paddingRight: '0.5em',
    },
});

class WelcomeScreen extends React.Component {
    render() {
        const {isOpen, record, classes, onClose, onConfirm, locale, registerPage} = this.props;

        const localizedRecord = record[locale];

        return (
            <Dialog
                open={isOpen}
                onClose={onClose}
                aria-labelledby="dialog-title"
                //Under rodo modal
                style={{zIndex: "1299"}}
            >
                <DialogTitle id="dialog-title"><RichTextField record={localizedRecord} source={'title'}/></DialogTitle>
                <DialogContent>
                    <DialogContentText><RichTextField record={localizedRecord} source={'content'}/></DialogContentText>
                </DialogContent>
                <DialogActions>
                    {!!localizedRecord.confirm && <Button
                        onClick={onConfirm}
                        autoFocus
                    >
                        <ActionCheck className={classes.iconPaddingStyle}/>
                        <RichTextField record={localizedRecord} source={registerPage ? 'close' : 'confirm'}/>
                    </Button>}
                </DialogActions>
            </Dialog>
        )
    }
}
WelcomeScreen.propTypes = {
    shouldShow: PropTypes.func.isRequired,
    record: PropTypes.object.isRequired,
    onConfirm: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired
};

const mapStateToProps = state => {
    return {
        locale: state.i18n.locale,
    }
};

export default compose(
    connect(
        mapStateToProps,
        null
    ),
    withStyles(styles)
)(WelcomeScreen)
