import {Component} from "react";
import { withRouter, Link } from 'react-router-dom';
import React from "react";
import ArrowBack from "@material-ui/icons/ArrowBack"
import {compose} from "recompose"
import PropTypes from 'prop-types'
import IconButton from "@material-ui/core/IconButton";

const sanitizeRestProps = ({
                               staticContext,
                               ...rest
                           }) => rest;

class BackButton extends Component {

    _handleClick = () => {
        if(this._isOnlyHistoryButton()) this.props.history.goBack();
    };

    _isOnlyHistoryButton() {
        return !this.props.basePath;
    }

    render() {
        const {basePath, ...rest} = this.props;
        return (
            <>
                <IconButton aria-label="Back" component={this._isOnlyHistoryButton() ? undefined : Link} onClick={this._handleClick}
                            to={basePath} {...sanitizeRestProps(rest)}>
                    <ArrowBack/>
                </IconButton>
            </>
        )
    }
}

BackButton.propTypes = {
    basePath: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

BackButton.defaultProps = {
    basePath: null,
};

export default compose(
    withRouter,
)(BackButton);
