//probably text is common, with only difference in accept line. so it probably can be unified.

import React from 'react';
export const rodoPage = (
    <div id="rodo-content">
    <h2>INFORMATION OBLIGATIONS</h2>
    In accordance with Article 13 (1) and (2) of the General Regulation on the Protection of Personal Data of 27 April 2016, I inform you that the controller of personal data is the Polish Air Navigation Services Agency, Wieżowa Street 8, 02-147 Warsaw. Contact to the Data Protection Officer: inspektorochronydanych@pansa.pl  
    <br/>
    <br/>
    Your personal data will be processed in order to register information about your intention to fly an unmanned aircraft, we will entrust in connection with the need to ensure safe, continuous, smooth and efficient air navigation 
in the Polish airspace. 
    <br/>
    <br/>
    The processing is necessary for the fulfillment of a legal obligation incumbent on the Administrator (Article 6(1)(c) of the GDPR), and this obligation arises from Guideline No. 15 of the President of the Civil Aviation Authority dated August 1, 2023, as well as in connection with the performance of a task carried out in the public interest and in the exercise of public authority entrusted to PAŻP (Article 6(1)(e) of the GDPR). We may also process your personal data in order to carry out communication with persons declaring their intention to fly an unmanned aircraft, and this processing is necessary for the purposes of the legitimate interests pursued by the Administrator (Article 6(1)(f) GDPR) consisting in ensuring that relevant information can be provided to persons declaring their intention to fly an unmanned aircraft.
    <br/>
    <br/>
    We will process your personal data for no longer than 6 years after you delete your account in the application. 
The personal data that we will collect about you are: name and surname, residential address, activity data, pilot number assigned by the ULC, telephone number and information about your current location (based on GPS).
    <br/>
    <br/>
    Your personal data may be transferred to providers of IT systems and services, including technology partners with whom PANSA cooperates, the Civil Aviation Authority, and authorized entities upon documented request, such as the police. Your personal data may also be transferred to institutions responsible for ensuring safe air navigation in neighboring countries in a situation where the flight is carried out in the vicinity of an air tower of a neighboring country. If you consent to this then your phone number will also be shared with other users of the application. Within the scope of the GDPR and with the limitations indicated therein, you have the right to access the content of your data and the right to rectify, delete, limit processing, the right to data portability, the right to object.
    <br/>
    <br/>
    You have the right to lodge a complaint with the Office for Personal Data Protection located at Stawki 2, in Warsaw. More information is available at <a target="_blank" href="https://www.uodo.gov.pl">www.uodo.gov.pl</a> The provision of your personal data is mandatory and results directly from the law. It is against the law to fly an unmanned aircraft without informing PANSA.
    <br/>
    <br/>    
</div>
);

export const richText = {
    title: `<h2>INFORMATION OBLIGATIONS</h2>`,
    content:
    `In accordance with Article 13 (1) and (2) of the General Regulation on the Protection of Personal Data of 27 April 2016, I inform you that the controller of personal data is the Polish Air Navigation Services Agency, Wieżowa Street 8, 02-147 Warsaw. Contact to the Data Protection Officer: inspektorochronydanych@pansa.pl  
    <br/>
    <br/>
    Your personal data will be processed in order to register information about your intention to fly an unmanned aircraft, we will entrust in connection with the need to ensure safe, continuous, smooth and efficient air navigation 
in the Polish airspace. 
    <br/>
    <br/>
    The processing is necessary for the fulfillment of a legal obligation incumbent on the Administrator (Article 6(1)(c) of the GDPR), and this obligation arises from Guideline No. 15 of the President of the Civil Aviation Authority dated August 1, 2023, as well as in connection with the performance of a task carried out in the public interest and in the exercise of public authority entrusted to PAŻP (Article 6(1)(e) of the GDPR). We may also process your personal data in order to carry out communication with persons declaring their intention to fly an unmanned aircraft, and this processing is necessary for the purposes of the legitimate interests pursued by the Administrator (Article 6(1)(f) GDPR) consisting in ensuring that relevant information can be provided to persons declaring their intention to fly an unmanned aircraft.
    <br/>
    <br/>
    We will process your personal data for no longer than 6 years after you delete your account in the application. 
The personal data that we will collect about you are: name and surname, residential address, activity data, pilot number assigned by the ULC, telephone number and information about your current location (based on GPS).
    <br/>
    <br/>
    Your personal data may be transferred to providers of IT systems and services, including technology partners with whom PANSA cooperates, the Civil Aviation Authority, and authorized entities upon documented request, such as the police. Your personal data may also be transferred to institutions responsible for ensuring safe air navigation in neighboring countries in a situation where the flight is carried out in the vicinity of an air tower of a neighboring country. If you consent to this then your phone number will also be shared with other users of the application. Within the scope of the GDPR and with the limitations indicated therein, you have the right to access the content of your data and the right to rectify, delete, limit processing, the right to data portability, the right to object.
    <br/>
    <br/>
    You have the right to lodge a complaint with the Office for Personal Data Protection located at Stawki 2, in Warsaw. More information is available at <a target="_blank" href="https://www.uodo.gov.pl">www.uodo.gov.pl</a> The provision of your personal data is mandatory and results directly from the law. It is against the law to fly an unmanned aircraft without informing PANSA.
    <br/>
    <br/>`,
        confirm: `Accept`,
        close: 'Close'
};
