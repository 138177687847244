import {isEmpty} from "lodash";


export function isChrome () {
    // please note,
// that IE11 now returns undefined again for window.chrome
// and new Opera 30 outputs true for window.chrome
// but needs to check if window.opr is not undefined
// and new IE Edge outputs to true now for window.chrome
// and if not iOS Chrome check
// so use the below updated condition
    const isChromium = window.chrome;
    const winNav = window.navigator;
    const vendorName = winNav.vendor;
    const  isOpera = typeof window.opr !== "undefined";
    const  isIEedge = winNav.userAgent.indexOf("Edge") > -1;
    const isIOSChrome = winNav.userAgent.match("CriOS");

    if (isIOSChrome) {
        // is Google Chrome on IOS
        return true;
    } else if(
        isChromium !== null &&
        typeof isChromium !== "undefined" &&
        vendorName === "Google Inc." &&
        isOpera === false &&
        isIEedge === false
    ) {
        return true;
    } else {
        // not Google Chrome
        return false;
    }
}

export function getSubdomain () {
    const host = window.location.hostname;
    const subdomain = host.match(/[A-z0-9]+(?=\.)/);
    return !isEmpty(subdomain) ? subdomain[0] : host;
}

export function getDeviceInfo () {
    const winNav = window.navigator;
    return {
        frontend: getSubdomain(),
        userAgent: winNav.userAgent,
        browserCodeName: winNav.appCodeName,
        browserName: winNav.appName,
        browserVersion: winNav.appVersion,
        cookies: winNav.cookieEnabled,
        deviceLocale: winNav.language,
        systemName: winNav.platform,
    }
}
