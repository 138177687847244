//import { USER_LOGIN_SUCCESS, USER_LOGOUT } from './MyAuthActions.js';
import {MY_STORE_CLEARSELF, MY_STORE_SETSELF, MY_STORE_SETSELF_USERSTATE} from "./MyAuthConstants";
import get from 'lodash.get'
import {isOperator} from "../../dr_ra2/logic/MyRestConfig";

const defaultState = {
    userState: {
        //year 2019 is static in Holidays, for other years set with MissionsV2Sagas as a side effect of checkHolidays action
        canCalcHolidays: true
    }
};

//FIXME got bit messy, as we are starting to mix both backend account,
// and extra non-persistable data... (pandoraUser, userState etc)
//split from auth reducer, as we don;t want to persist
//hmm defaults?
export default (previousState = defaultState, { type, payload, newUserState }) => {
    //console.log('self reducer, ',type, payload);
    const calcedUserState = _getUserState({...previousState, ...payload});

    switch (type) {
        case MY_STORE_SETSELF:
            console.log('----- self reducer MY_STORE_SETSELF:', type, payload);

            return {
                ...previousState,
                ...payload,
                userState: {
                    ...previousState.userState,
                    ...calcedUserState,
                }
            };
        case MY_STORE_CLEARSELF:
            console.log('----- self reducer MY_STORE_CLEARSELF:', type, payload);
            return defaultState;
        case MY_STORE_SETSELF_USERSTATE:
            console.log('----- self reducer MY_STORE_SETSELF_USERSTATE:', type, payload);
            return {
                ...previousState,
                userState: {
                    ...previousState.userState,
                    ...calcedUserState,
                    ...newUserState
                }
            };
        default:
            return previousState;
    }
}

const _hasNotNull = (object, path) => {
        return !!get(object, path, false)
};

const _getUserState = (mySelf) => {
    const roles = get(mySelf, 'roles');
    if(!!roles) {
        return isOperator(mySelf.roles) ? _getOperatorUserState(mySelf) : _getGovUserState(mySelf);
    }
    else {
        return null;
    }
};


const _getOperatorUserState = (mySelf) => {
    const hasCompany = _hasNotNull(mySelf, 'etc.company');
    const hasAddress = _hasNotNull(mySelf, 'address') && _hasNotNull(mySelf, 'country');
    const hasPhone = _hasNotNull(mySelf, 'phone') && _hasNotNull(mySelf, 'phoneVerified');
    const hasFullName = _hasNotNull(mySelf, 'firstName') && _hasNotNull(mySelf, 'lastName');
    const hasNickname = _hasNotNull(mySelf, 'nickName');
    const canSendApplAsOperator = hasFullName && hasAddress && hasPhone;
    const canSendApplAsCompany = hasCompany && hasPhone;
    const canSendAppl = canSendApplAsOperator || canSendApplAsCompany;
    const canSendMission = hasPhone && hasFullName && hasNickname;

    return ({
        hasCompany,
        hasAddress,
        hasPhone,
        hasFullName,
        hasNickname,
        canSendAppl,
        canSendApplAsOperator,
        canSendApplAsCompany,
        canSendMission,
    })
};

const _getGovUserState = (mySelf) => {
    const hasCompany = _hasNotNull(mySelf, 'units') && mySelf.units.length > 0;
    const hasPhone = _hasNotNull(mySelf, 'phone');
    const hasFullName = _hasNotNull(mySelf, 'firstName') && _hasNotNull(mySelf, 'lastName');

    return ({
        hasCompany,
        hasPhone,
        hasFullName,
    })
};
