import React from 'react';
import {connect} from 'react-redux';

import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Fade from '@material-ui/core/Fade';

import LanguageIcon from '@material-ui/icons/Language';

import { withCookies } from 'react-cookie';

import classnames from 'classnames';

import compose from 'recompose/compose';
//import { toggleSidebar as toggleSidebarAction } from '../../actions';
//import {blue900} from 'material-ui/styles/colors';
//import getMuiTheme from 'material-ui/styles/getMuiTheme';
//f
//const muiTheme = getMuiTheme(myBaseTheme);
import {changeLocale as changeLocaleAction} from 'react-admin';
import withStyles from "@material-ui/core-v3/styles/withStyles";
import {TooltipProxy as Tooltip} from "./TooltipProxy";


const styles = theme => ({
        _menuItem: {
            backgroundColor: "navyblue",
            '&:hover': {
                backgroundColor: "navyblue",
                '& $primary, & $icon': {
                    color: "white",
                },
            },

        },
        menuItem: {},
        primary: {},
        icon: {},
        whitish: {color: "#ffffff"},
        icoTxt: {
            position: "absolute",
            fontSize: "0.6rem",
            top: "0.4rem",
            right: "0.3rem",
        }
    });

class MyLangSelect extends React.Component {

    state = {
        anchorEl: null,
    };

    handleOpen = event => {
        this.setState({anchorEl: event.currentTarget});
    };

    handleSetLang = lang => {
        console.log(lang);
        this.props.changeLocale(lang);
        //hmm. cookie
        this.props.cookies.set('lang', lang, { path: '/'});

        this.handleClose();
    };

    handleClose = () => {
        this.setState({anchorEl: null});
    };

    render() {
        const {anchorEl} = this.state;
        const {classes, changeLocale, locale} = this.props;
        const open = !!(anchorEl);

        return (
            <div>
                <Tooltip content={'myroot.appBar.language'}>
                    <Button
                        aria-owns={open ? 'fade-menu' : null}
                        aria-haspopup="true"
                        onClick={this.handleOpen}
                    >
                        <div className={classes.whitish}>
                            <LanguageIcon/>

                            <div className={classes.icoTxt}>
                                {locale.toUpperCase()}</div>
                        </div>
                        {/*<span className={classes.whitish} >*/}
                        {/*<LanguageIcon />*/}
                        {/*<span className={classes.icoTxt}>*/}
                        {/*{locale.toUpperCase()}*/}
                        {/*</span>*/}
                        {/*</span>*/}
                    </Button>
                </Tooltip>
                <Menu
                    id="fade-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={this.handleClose}
                    TransitionComponent={Fade}
                >
                    <MenuItem className={classnames(classes.menuItem)}
                              onClick = {() => this.handleSetLang('pl')}
                    >
                        PL
                    </MenuItem>
                    <MenuItem className={classes.menuItem}
                              onClick = {() => this.handleSetLang('en')}
                    >
                        EN
                    </MenuItem>
                </Menu>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return { locale: state.i18n.locale}
};

const enhance = compose(
    //muiThemeable(), // force redraw on theme change
    withStyles(styles),
    withCookies,
    connect(mapStateToProps, {
        changeLocale: changeLocaleAction,
    })
);

export default enhance(MyLangSelect);
