import SimpleCrypto from "simple-crypto-js";

const secretKey = "q2WqkkVt7j05XLRA4mC0ryLHVtSnUspH";

const simpleCrypto = new SimpleCrypto(secretKey);


//fast and dirty placeholder for encrypt/decrypt rtmap reload settings (Query string user/pass),
// usage to review.. probably should use persisted store, but we need TESTED mechanism for logout/cleanup on pandora first

export function encrypt(val) {
    return simpleCrypto.encrypt(val);
}

export function decrypt(val, expectObject) {
    return simpleCrypto.decrypt(val, expectObject);
}
