import {Toolbar} from "ra-ui-materialui";
import React from "react";
import MissionSaveActionsButton from "./MissionSaveActionsButton";

const MissionCreateSaveToolbar = props => (

    <Toolbar  >
        <MissionSaveActionsButton
            //submitOnEnter={true}
            {...props}
            label={'ra.action.save'}

        />
    </Toolbar>
);

export default MissionCreateSaveToolbar;