import {INFO_SHOW_MSG} from "./InfoboxConstants";


export const showInfoMessages = ({situationID, locationValid, data}) => {
    return ({
        type: INFO_SHOW_MSG,
        situationID,
        locationValid,
        data
    })
};

