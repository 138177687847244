
import {turfPipe, turfDistance} from '../oldCommon/commonTurf.js'
import { PRIMITIVES} from '../deckDraw/DrawEditorEnums'
import {DEFAULT_MAX, DEFAULT_MIN, getDrawIco} from '../deckDraw/deckEditor';
import {isPosEqual, turfArea, turfCentroid, turfDestination, turfGreatCircle, turfKinks} from "../oldCommon/commonTurf";
import {DeckPrimitive} from "./DeckPrimitive";
import {functionalColors} from "../oldCommon/commonInits";
import {mSq2kmSq} from "../../../LogicV1Redux/oldLogic/GeoConverters";


const MARKER_TYPES = {
    VTX:'VTX',
    INSERT:'INSERT'
};

//pipe is defined by latlngs and ends and last construction point for radius calc or TODO radius itself
export default class DeckPipe extends DeckPrimitive{

    constructor(latlngs, radiusOrLatLng) {
        super();

        this.primitiveType = PRIMITIVES.PIPE;
        this._parseParams(latlngs, radiusOrLatLng);
        this._calcGeometry();
    }

    recalc(latlngs, radiusOrLatLng) {
        this._parseParams(latlngs, radiusOrLatLng);
        this._calcGeometry();
        return this;
    }

    //remove duplicate helpers
    fix() {
        let helpers = [this.helpers[0]];
        for (let i = 1; i < this.helpers.length; i++) {
            if (!isPosEqual(helpers[helpers.length-1], this.helpers[i])) {
                helpers.push(this.helpers[i]);
            }
        }
        this.helpers = helpers;
        this._calcGeometry();
        return this;

    }

    //fix helpers -> when radius helper is working...
    //combine with deserialize...
    setRadius(val) {
        this.radius = val;
        this._calcGeometry();
        return this;
    }

    _parseParams(latlngs, radiusOrLatLng) {
        this.helpers = latlngs;

        if (radiusOrLatLng) {
            console.warn('fixme! pipe radius calc')
            // if (Array.isArray(radiusOrLatLng)) {
            //     this.helpers.push(radiusOrLatLngC);
            // }
            // else {
            //     //fixme calculate bearing from axis
            //     this.helpers.push(turfDestination(latlngB, radiusOrLatLngC, 90));
            // }
        }
    }

    _calcRadius() {
    //     this.radius = (this.helpers.length > 2)?turfDistance(this.helpers[1], this.helpers[2]):undefined;
    //     this.start = (this.helpers[0])?this.helpers[0]:undefined;
    //     this.end = (this.helpers[1])?this.helpers[1]:undefined;
    }

    _calcGeometry() {
        console.log('pipe radius', this.radius);
        const latLngs = (this.radius)?turfPipe(this.helpers, this.radius):[];
        this.polygon = latLngs;
        this.markers = [];
        //this.poly = null;
        this.paths = null;

        if ( !isPosEqual(this.helpers[0], this.helpers[1])) {
            this.paths = [{
                path: this.helpers,
                widthScale: 1,
            }]
        }

        // if (this.helpers.length > 2) {
        //     this.poly = {
        //         polygon: [this.helpers],
        //         fillColor: functionalColors.newPrimitive,
        //     };
        // }


        for (let i = 0; i < this.helpers.length; i++) {

            const marker = getDrawIco('BLUE_CIRCLE', this.helpers[i][0], this.helpers[i][1]);

            //fixme add inserts

            marker.type = MARKER_TYPES.VTX;
            marker.llIdx = i;

            this.markers.push(marker);
        }

        this.centroid = turfCentroid(this.helpers);
        //fixme? should be optimized to custom call?
        this.__area = {mSq:turfArea(this.polygon)};
        this.__area.kmSq = mSq2kmSq(this.__area.mSq);

        const kinks = turfKinks(this.polygon);
        this.__kinks = (kinks && kinks.length > 0)?kinks:null;
        //hack! we don't allow multiring polys now
        if (!this.__kinks) {
            this.__kinks = this.polygon.length > 1;
        }
    }

}
