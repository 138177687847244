
import {FETCH_ALL_ALERTS_V2_RQ, STORE_ALL_ALERTS_V2, SET_ATC_OWNED_ALERT_V2, POST_ALERT_V2_RQ, CANCEL_ALERT_V2_RQ} from "../constants/AlertsV2Constants";

export const fetchAllAlertsV2Rq = (payload) => {
    console.log('fetchAllAlertsV2Rq', payload);
    return ({
        type: FETCH_ALL_ALERTS_V2_RQ,
        payload,
    });
};

export const storeAllAlerts = (payload) => {
    console.log('storeAllAlerts', payload);
    return ({
        type: STORE_ALL_ALERTS_V2,
        payload,
    });
};

//old approach -> single owned zone. fixme later, unused somehow?
export const setOwnedAlert = (payload) => {
    console.log('setOwnedAlert', payload);
    return ({
        type: SET_ATC_OWNED_ALERT_V2,
        payload,
    });
};

export const postAlertV2Rq = (payload) => {
    console.log('POST_ALERT_V2_RQ', payload);
    return ({
        type: POST_ALERT_V2_RQ,
        payload,
    });
};

export const cancelAlertV2Rq = (payload) => {
    console.log('CANCEL_ALERT_V2_RQ', payload);
    return ({
        type: CANCEL_ALERT_V2_RQ,
        payload,
    });
};
