import * as React from "react";
import PubSub from "pubsub-js";
import {isCaaEditor, isOperator, isSuperAdmin, RESOURCES} from "../logic/MyRestConfig";
import {PubSubSignals} from "../../LogicV1Redux/constants/PubSubSignals";
import {
    ALERT_MSG_CERT_STATUS_CHANGE,
    ALERT_MSG_DRONE_STATUS_CHANGE,
    ALERT_MSG_MISSION_STATUS_CHANGE
} from "../logic/box/AlertboxConstants";
import {bindActionCreators} from "redux";
import connect from "react-redux/es/connect/connect";
import {showAlertMessages} from "../logic/box/AlertboxActions";
import compose from 'recompose/compose';
import moment from "moment";
import {translate, refreshView} from 'react-admin'
import {getCaaStatusTranslationResource} from "../utils/applicationsHelpers";
import Configs, {FEATURES_V1_2_ENUMS} from "../../Configs";
import {getMissionStatusTranslationResource} from "../utils/missionHelpers";
import {MissionV2Statuses} from "../../LogicV1Redux/constants/MissionsV2Enums";
import xssFilters from "xss-filters";

const NEW_ALERTBOX_ENABLED = Configs.enabledFeatures.includes(FEATURES_V1_2_ENUMS.ALERTBOX_NEW_INFOS);


class PubSubSubscriber extends React.Component {

    _shouldRefreshOnLocation = ({resource, uid}) =>
        //List view
        this.props.location.pathname.endsWith(resource) ||
        //Show/Edit view
        this.props.location.pathname.includes(uid) ||
        //List with filter
        this.props.location.pathname.startsWith(`/${resource}?`);

    _refreshOnLocation = ({resource, uid}) => {
        if(!NEW_ALERTBOX_ENABLED) return;
        if(this._shouldRefreshOnLocation({resource, uid})) {
            this.props.refreshView();
        }
    };

    _composeAlertData = ({socketData, idKey, statusTranslationFn, resource, link}) => ({
        name: xssFilters.inHTMLData(socketData.name),
        status: this.props.translate(statusTranslationFn(socketData.status), {smart_count: 1}),
        link: xssFilters.uriPathInSingleQuotedAttr(link ? link : `/#/${resource}/${socketData[idKey]}`),
        time: moment().format('HH:mm:ss DD-MM-YYYY')
    });

    _subscribeDroneStatusChange = () =>
        PubSub.subscribe(PubSubSignals.DRONE_STATUS_CHANGE, (msg, data) => {
            this.props.showAlertMessages(
                {
                    situationID: ALERT_MSG_DRONE_STATUS_CHANGE,
                    data: this._composeAlertData({
                        socketData: data,
                        idKey: 'droneId',
                        statusTranslationFn: getCaaStatusTranslationResource,
                        resource: RESOURCES.DRONES
                    })
                }
            );

           this._refreshOnLocation({resource: RESOURCES.DRONES, uid: data.droneId})
        });

    _subscribeCertStatusChange = () =>
        PubSub.subscribe(PubSubSignals.CERT_STATUS_CHANGE, (msg, data) => {
            this.props.showAlertMessages(
                {
                    situationID: ALERT_MSG_CERT_STATUS_CHANGE,
                    data: this._composeAlertData({
                        socketData: data,
                        idKey: 'certId',
                        statusTranslationFn: getCaaStatusTranslationResource,
                        resource: RESOURCES.CERTS
                    })
                }
            );

            this._refreshOnLocation({resource: RESOURCES.CERTS, uid: data.certId})
        });

    _subscribeMissionStatusChange = () =>
        PubSub.subscribe(PubSubSignals.MISSION_STATUS_CHANGE, (msg, data) => {
            this.props.showAlertMessages(
                {
                    situationID: ALERT_MSG_MISSION_STATUS_CHANGE,
                    data: this._composeAlertData({
                        socketData: data,
                        idKey: 'missionId',
                        statusTranslationFn: getMissionStatusTranslationResource,
                        resource: RESOURCES.MISSIONS,
                        link: `/#/${RESOURCES.MISSIONS}/${data.missionId}/${
                            data.status === MissionV2Statuses.REJECTED && isOperator(this.props.mySelf.roles) ? 'edit' : 'show'
                        }`
                    })
                }
            );

            this._refreshOnLocation({resource: RESOURCES.MISSIONS, uid: data.missionId})
        });

    componentDidMount() {
        console.log("PUBSUB", this.props);
        const {mySelf} = this.props;

        if (!mySelf.roles) {
            console.warn('PubSubSubscriber mounted too early');
            return null;
        }

        const {roles} = mySelf;

        if (isCaaEditor(roles)) {
            this.pubsubDroneStatus = this._subscribeDroneStatusChange();
            this.pubsubCertStatus = this._subscribeCertStatusChange()
        }
        else if (isOperator(roles) || isSuperAdmin(roles)) {
            this.pubsubMissionStatus = this._subscribeMissionStatusChange();
            this.pubsubDroneStatus = this._subscribeDroneStatusChange();
            this.pubsubCertStatus = this._subscribeCertStatusChange();
        }
        else {
            this.pubsubMissionStatus = this._subscribeMissionStatusChange();
        }
    }

    componentWillUnmount() {
        if(!!this.pubsubCertStatus) PubSub.unsubscribe(this.pubsubCertStatus);
        if(!!this.pubsubDroneStatus) PubSub.unsubscribe(this.pubsubDroneStatus);
        if(!!this.pubsubMissionStatus) PubSub.unsubscribe(this.pubsubMissionStatus);
    }

    render() { return null }
}

const mapStateToProps = state => {
    return {
        mySelf : state.mySelf,
        location: state.routing.location,
    }
};

const mapDispatchToProps = (dispatch) => ({
    showAlertMessages: bindActionCreators(showAlertMessages, dispatch),
    refreshView: bindActionCreators(refreshView, dispatch),
});

export default compose(
    translate,
    connect(mapStateToProps,  mapDispatchToProps)
)(PubSubSubscriber);
