//special toolbar in edit view calling custom save poi saga

import {Component} from "react";
import PubSub from "pubsub-js";
import {PubSubSignals} from "../../../../LogicV1Redux/constants/PubSubSignals";
import {SaveButton} from "ra-ui-materialui";
import {saveUnitDataV2Rq} from "../../../../LogicV1Redux/actions/UnitsV2Actions";
import {getFormInitialValues, getFormMeta, getFormValues, isPristine, isValid} from "redux-form";
import {connect} from "react-redux";
import React from "react";
import { showNotification } from 'react-admin';

class UnitSaveActionsButtonBase extends Component {

    state = {
        unitDirty: false,         //yikes, to redux, but first we need to review/cleanup related events flow fixme later

    };

    componentDidMount() {
        //console.log('=====btn subscribin ')
        this.pubsubOnEditorDirty = PubSub.subscribe(PubSubSignals.SET_DRAW_EDITOR_DIRTY, (msg, data) => {
            //console.log('=======on pubsubOnMissionsLegsDirty MissionSaveActionsButtonBase');
            this.setState({unitDirty:true});
        });
        this.pubsubOnEditorClean = PubSub.subscribe(PubSubSignals.CLEAR_DRAW_EDITOR_DIRTY, (msg, data) => {
            //console.log('===========on pubsubOnMissionsLegsDirty MissionSaveActionsButtonBase');
            this.setState({unitDirty:false});
        });
    }

    componentWillUnmount() {
        //console.log('=====btn unmounting ')

        PubSub.unsubscribe(this.pubsubOnEditorDirty);
        PubSub.unsubscribe(this.pubsubOnEditorClean);

        this.setState({unitDirty:false}) //fixme on refresh!, not working, but keeping
    }

    handleClick = () => {
        const { basePath, handleSubmit, redirect, dispatchSaveUnitDataV2RqAction, showNotification, setStatus } = this.props;
        console.log('=====save btn props = ', this.props);

        //fixme redirects..
        //dispatchSaveMissionDataV2RqAction(record, basePath, redirect); //hmm, basePath/redirect not passed anyway
        return handleSubmit(record => {
            console.log('=====save btn props = ', this.props, record);

            dispatchSaveUnitDataV2RqAction(record);

        });

    };

    render() {
        const {basePath,
            submitOnEnter,
            handleSubmitWithRedirect,
            dispatchSaveMissionDataV2RqAction,
            dispatchSaveMissionStatusV2RqAction,
            dispatchSaveUnitDataV2RqAction,
            showNotification,
            isFormValid,
            isFormPristine,
            setStatus,
            formValues,
            formMeta,

            formInitialValues,
            isLoading,
            disabled,

            ...props} = this.props;

        const noLegs = (this.props.record && this.props.record.legs)
            ?(this.props.record.legs.length === 0):false;
        const {unitDirty} = this.state;
        //console.log('======saveBtn props', this.props);

        //somehow pristine doesn't work...
        //neither equal.. for formValues and formInitialValues...
        //on the other hand there is a lot happening here..
        //so let's fix the issue with acceptable level..

        //changes to dirty on any visit in fields...
        const dirtyValues = Object.keys(formMeta).length !== 0;

        const customDisabled = (setStatus && (noLegs || unitDirty || dirtyValues)) || !isFormValid;
        return (
            <SaveButton
                //icon={"Avatar"} //fck custom button doesnt work
                handleSubmitWithRedirect={this.handleClick}
                disabled = {!!disabled || customDisabled }

                {...props}
            />
        );
    }
}

const mapDispatchToProps = dispatch =>
    (
        {
            dispatchSaveUnitDataV2RqAction : (e) => {
                console.log('dispatchin dispatchSaveUnitDataV2RqAction', e);
                dispatch(saveUnitDataV2Rq(e))
            },

            showNotification : (e) => {
                dispatch(showNotification(e));
            }
        }
    );

const editFormName = 'record-form'; //have to be default
const mapStateToProps = (state) => ({
    //myAppExtras: state.myAppExtras,

    //hack, form currently doesn't set saving correctly,
    // but saga puts FETCH_START, FETCH_END we can use
    saving: state.admin.loading > 0,

    isFormValid: isValid(editFormName)(state),
    isFormPristine: isPristine(editFormName)(state),

    formMeta: getFormMeta(editFormName)(state),
    formValues: getFormValues(editFormName)(state),
    formInitialValues: getFormInitialValues(editFormName)(state),


});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UnitSaveActionsButtonBase);
