//still not sure about naming convention


export const MAP_SETUP_RQ = "MAP_SETUP_RQ";

export const MAP_CLEAR_SETUP_RQ = "MAP_CLEAR_SETUP_RQ";

export const MAP_UI_VOLUME = "MAP_UI_VOLUME";

export const MAP_UI_SIZE = "MAP_UI_SIZE";

export const MAP_TICK_ENABLED = "MAP_TICK_ENABLED";

export const MAP_ALERTS_V1_ENABLE_RQ = "MAP_ALERTS_V1_ENABLE_RQ";

export const MAP_ALERTS_V1_ENABLED = "MAP_ALERTS_V1_ENABLED";

export const MAP_UI_ALERTS_V1_SHOW = "MAP_UI_ALERTS_V1_SHOW";

export const MAP_ALERTS_V2_ENABLE_RQ = "MAP_ALERTS_V2_ENABLE_RQ";

export const MAP_ALERTS_V2_ENABLED = "MAP_ALERTS_V2_ENABLED";

export const MAP_UI_ALERTS_V2_SHOW = "MAP_UI_ALERTS_V2_SHOW";

export const MAP_CHECKINS_V1_ENABLE_RQ = "MAP_CHECKINS_V1_ENABLE_RQ";

export const MAP_CHECKINS_V1_ENABLED = "MAP_CHECKINS_V1_ENABLED";

export const MAP_UI_CHECKINS_V1_SHOW = "MAP_UI_CHECKINS_V1_SHOW";

export const MAP_CHECKINS_V2_ENABLE_RQ = "MAP_CHECKINS_V2_ENABLE_RQ";

export const MAP_CHECKINS_V2_ENABLED = "MAP_CHECKINS_V2_ENABLED";

export const MAP_UI_CHECKINS_V2_SHOW = "MAP_UI_CHECKINS_V2_SHOW";


export const MAP_LPR_HELIPADS_V1_ENABLE_RQ = "MAP_LPR_HELIPADS_V1_ENABLE_RQ";

export const MAP_LPR_HELIPADS_V1_ENABLED = "MAP_LPR_HELIPADS_V1_ENABLED";

export const MAP_UI_LPR_HELIPADS_V1_SHOW = "MAP_UI_LPR_HELIPADS_V1_SHOW";

export const MAP_MISSIONS_V2_ENABLE_RQ = "MAP_MISSIONS_V2_ENABLE_RQ";

export const MAP_MISSIONS_V2_ENABLED = "MAP_MISSIONS_V2_ENABLED";

export const MAP_UI_MISSIONS_V2_SHOW = "MAP_UI_MISSIONS_V2_SHOW";

export const MAP_RT_MAP_ZONES_V1_ENABLE_RQ = "MAP_RT_MAP_ZONES_V1_ENABLE_RQ";

export const MAP_RT_MAP_ZONES_V1_ENABLED = "MAP_RT_MAP_ZONES_V1_ENABLED";

export const MAP_UI_RT_MAP_ZONES_V1_SHOW = "MAP_UI_RT_MAP_ZONES_V1_SHOW";

export const MAP_ZONES_V2_ENABLE_RQ = "MAP_ZONES_V2_ENABLE_RQ";

export const MAP_ZONES_V2_ENABLED = "MAP_ZONES_V2_ENABLED";

export const MAP_UI_ZONES_V2_SHOW = "MAP_UI_ZONES_V2_SHOW";

export const MAP_POIS_V2_ENABLE_RQ = "MAP_POIS_V2_ENABLE_RQ";

export const MAP_POIS_V2_ENABLED = "MAP_POIS_V2_ENABLED";

export const MAP_UI_POIS_V2_SHOW = "MAP_UI_POIS_V2_SHOW";

export const MAP_UNITS_V2_ENABLE_RQ = "MAP_UNITS_V2_ENABLE_RQ";

export const MAP_UNITS_V2_ENABLED = "MAP_UNITS_V2_ENABLED";

export const MAP_UI_UNITS_V2_SHOW = "MAP_UI_UNITS_V2_SHOW";



//(zones) filters to rethink
export const MAP_UI_MATZ_SHOW = "MAP_UI_MATZ_SHOW";



export const MAP_UI_LIVE_ABSB_EXCHANGE_SHOW = "MAP_UI_LIVE_ABSB_EXCHANGE_SHOW";

export const MAP_LIVE_ABSB_EXCHANGE_ENABLE_RQ = "MAP_LIVE_ABSB_EXCHANGE_ENABLE_RQ";

export const MAP_LIVE_ABSB_EXCHANGE_ENABLED = "MAP_LIVE_ABSB_EXCHANGE_ENABLED";

//was all before moving to redux
//export const MAP_UI_RTDM_SOURCES_SHOW = "MAP_UI_RTDM_SOURCES_SHOW";

//export const MAP_RTDM_SOURCES_ENABLED = "MAP_RTDM_SOURCES_ENABLED";


export const MAP_UI_RTDM_EXCHANGE_TICKS_SHOW = "MAP_UI_RTDM_EXCHANGE_TICKS_SHOW";

export const MAP_RTDM_EXCHANGE_TICKS_ENABLE_RQ = "MAP_RTDM_EXCHANGE_TICKS_ENABLE_RQ";

export const MAP_RTDM_EXCHANGE_TICKS_ENABLED = "MAP_RTDM_EXCHANGE_TICKS_ENABLED";


export const MAP_UI_RTDM_EXCHANGE_AIRCRAFTS_SHOW = "MAP_UI_RTDM_EXCHANGE_AIRCRAFTS_SHOW";

export const MAP_RTDM_EXCHANGE_AIRCRAFTS_ENABLE_RQ = "MAP_RTDM_EXCHANGE_AIRCRAFTS_ENABLE_RQ";

export const MAP_RTDM_EXCHANGE_AIRCRAFTS_ENABLED = "MAP_RTDM_EXCHANGE_AIRCRAFTS_ENABLED";


export const MAP_UI_RTDM_CREOTECH_TICKS_SHOW = "MAP_UI_RTDM_CREOTECH_TICKS_SHOW";

export const MAP_RTDM_CREOTECH_TICKS_ENABLE_RQ = "MAP_RTDM_CREOTECH_TICKS_ENABLE_RQ";

export const MAP_RTDM_CREOTECH_TICKS_ENABLED = "MAP_RTDM_CREOTECH_TICKS_ENABLED";

export const MAP_UI_RTDM_AEROBITS_TICKS_SHOW = "MAP_UI_RTDM_AEROBITS_TICKS_SHOW";

export const MAP_RTDM_AEROBITS_TICKS_ENABLE_RQ = "MAP_RTDM_AEROBITS_TICKS_ENABLE_RQ";

export const MAP_RTDM_AEROBITS_TICKS_ENABLED = "MAP_RTDM_AEROBITS_TICKS_ENABLED";

export const MAP_UI_RTDM_SOAP_LPR_UNITS_TICKS_SHOW = "MAP_UI_RTDM_SOAP_LPR_UNITS_TICKS_SHOW";

export const MAP_RTDM_SOAP_LPR_UNITS_TICKS_ENABLE_RQ = "MAP_RTDM_SOAP_LPR_UNITS_TICKS_ENABLE_RQ";

export const MAP_RTDM_SOAP_LPR_UNITS_TICKS_ENABLED = "MAP_RTDM_SOAP_LPR_UNITS_TICKS_ENABLED";

export const MAP_UI_ENTITIES_VISIBILITY = "MAP_UI_ENTITIES_VISIBILITY";

export const MAP_UI_HEIGHT_FILTER = "MAP_UI_HEIGHT_FILTER";

export const MAP_SOCKET_IO_V1_ENABLED = "MAP_SOCKET_IO_V1_ENABLED";

//special pandora constnts
export const PANDORA_TRAFFIC_ENABLED = "PANDORA_TRAFFIC_ENABLED";
export const PANDORA_LPR_TRAFFIC_ENABLED = "PANDORA_LPR_TRAFFIC_ENABLED";
export const PANDORA_CREOTECH_TRAFFIC_ENABLED = "PANDORA_CREOTECH_TRAFFIC_ENABLED";

export const PANDORA_TRAFFIC_HEIGHT_FILTER = "PANDORA_TRAFFIC_HEIGHT_FILTER";


//was done for droniada.. socket_io iirc
export const MAP_SOCKET_IO_RTDM_SOURCES_ENABLED = "MAP_SOCKET_IO_RTDM_SOURCES_ENABLED";

export const MAP_UI_SIZES = {
    SMALL:'small',
    LARGE:'large'
};

export const MAP_UI_CALLSIGN_FLOOR_NUMBERING_ENABLED = "MAP_UI_CALLSIGN_FLOOR_NUMBERING_ENABLED";
