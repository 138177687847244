//gov/op links in separated files...
// security by obscurity ;

import Configs, {FEATURES_V1_2_ENUMS} from "../../Configs";

const ZONE_DELETE_ENABLED = Configs.enabledFeatures.includes(FEATURES_V1_2_ENUMS.ZONE_DELETE);

export const PM_ENUMS = {
    CREATE:'create',
    READ:'read',
    UPDATE:'update',
    DELETE:'delete',
};

export const PM_ROLES = {
    VIEWER:'viewer',
    EDITOR:'editor',
    MANAGER:'manager',
    OPERATOR:'operator',
    SUPERADMIN:'superadmin',
    CAA_EDITOR: 'caaEditor',
    DRAI_EDITOR: 'draiEditor',
    LAU_EDITOR: 'lauEditor',
    ATC_MASTER: 'atcMaster'

};

export const RESOURCES = {
    UNITS:'units',
    GOVS:'govs',
    UNIT_GOVS:'unitGovs',
    LOGS:'logs',
    OPERATORS:'operators',
    DRONES:'drones',
    CERTS:'certs',
    MISSIONS:'missions',
    ZONES:'zones',
    ZONE_LEGS:'zoneLegs',
    POIS:'pois',
    FAAS:'faas',
    DRONE_MODELS:'droneModels',
    CONTAINERS:'containers',
    ZONE_TYPES: "zoneTypes",
    NOTAMS: "notams",
    NOTAM_ACTIVATIONS: "notamActivations",
    EVENT_OPERATORS:'eventOperators',
    EVENT_GOVS:'eventGovs',
    ACM_RULES:'acmRules',
    ...(ZONE_DELETE_ENABLED && {ZONE_ARCHIVE: 'zoneArchive'}),

    LEGS:'legs',

    MY_ZONE_LEGS:'myZoneLegs',  //looks unused. so why created?
    //fake resources
    UNITS_ALL:'unitsAll',
    ZONES_ALL:'zonesAll',
    //MISSIONSV2_RT_MAP:'missionsV2RtMap', //for rt-map only
    MISSIONSV2_DASHBOARD:'missionsV2Dashboard',
    DRONES_DASHBOARD:'dronesDashboard',
    CERTS_DASHBOARD:'certsDashboard',
    DRONES_ARCHIVE: 'dronesArchive',
    CERTS_ARCHIVE: 'certsArchive',
};

//works only in react-admin standard resource view now (CRUD interface)
const permissions_grid = {
    [PM_ROLES.VIEWER]: {
        [RESOURCES.ZONES]: [PM_ENUMS.READ],
        [RESOURCES.ZONE_LEGS]: [PM_ENUMS.READ],
        [RESOURCES.GOVS]: [], //[PM_ENUMS.READ, PM_ENUMS.UPDATE],
        [RESOURCES.UNIT_GOVS]: [], //[PM_ENUMS.READ],

        [RESOURCES.UNITS]: [PM_ENUMS.READ],
        [RESOURCES.MISSIONS]: [PM_ENUMS.READ],
        [RESOURCES.OPERATORS]: [PM_ENUMS.READ],
        [RESOURCES.LOGS]: [],
        [RESOURCES.DRONES]: [PM_ENUMS.READ],
        [RESOURCES.CERTS]: [PM_ENUMS.READ],
        [RESOURCES.CONTAINERS]: [PM_ENUMS.READ],
        [RESOURCES.POIS]: [],

        [RESOURCES.FAAS]: [],
        [RESOURCES.DRONE_MODELS]: [PM_ENUMS.READ],

        [RESOURCES.NOTAMS]: [PM_ENUMS.READ],
        ...(ZONE_DELETE_ENABLED && {[RESOURCES.ZONE_ARCHIVE]: []}),

    },
    [PM_ROLES.EDITOR]: {
        [RESOURCES.ZONES]: [PM_ENUMS.READ, PM_ENUMS.UPDATE, PM_ENUMS.CREATE],
        [RESOURCES.ZONE_LEGS]: [PM_ENUMS.READ, PM_ENUMS.UPDATE],
        [RESOURCES.ZONE_TYPES]: [PM_ENUMS.READ],
        [RESOURCES.GOVS]: [], //[PM_ENUMS.READ, PM_ENUMS.UPDATE],
        [RESOURCES.UNIT_GOVS]: [], //[PM_ENUMS.READ],

        [RESOURCES.UNITS]: [PM_ENUMS.READ],
        [RESOURCES.MISSIONS]: [PM_ENUMS.READ],
        [RESOURCES.OPERATORS]: [PM_ENUMS.READ],
        [RESOURCES.LOGS]: [],
        [RESOURCES.DRONES]: [PM_ENUMS.READ],
        [RESOURCES.CERTS]: [PM_ENUMS.READ],
        [RESOURCES.CONTAINERS]: [PM_ENUMS.READ],
        [RESOURCES.POIS]: [],

        [RESOURCES.FAAS]: [],
        [RESOURCES.DRONE_MODELS]: [PM_ENUMS.READ],

        [RESOURCES.NOTAMS]: [PM_ENUMS.READ],
        ...(ZONE_DELETE_ENABLED && {[RESOURCES.ZONE_ARCHIVE]: []}),

    },
    [PM_ROLES.MANAGER]: {

        [RESOURCES.ZONES]: [PM_ENUMS.READ],
        [RESOURCES.ZONE_LEGS]: [PM_ENUMS.READ, PM_ENUMS.UPDATE],
        [RESOURCES.ZONE_TYPES]: [PM_ENUMS.READ],
        [RESOURCES.GOVS]: [], //[PM_ENUMS.READ, PM_ENUMS.UPDATE],
        [RESOURCES.UNIT_GOVS]: [], //[PM_ENUMS.READ],

        [RESOURCES.UNITS]: [PM_ENUMS.READ],
        [RESOURCES.MISSIONS]: [PM_ENUMS.READ],
        [RESOURCES.OPERATORS]: [PM_ENUMS.READ],
        [RESOURCES.LOGS]: [],
        [RESOURCES.DRONES]: [PM_ENUMS.READ],
        [RESOURCES.CERTS]: [PM_ENUMS.READ],
        [RESOURCES.CONTAINERS]: [PM_ENUMS.READ],
        [RESOURCES.POIS]: [],

        [RESOURCES.FAAS]: [],
        [RESOURCES.DRONE_MODELS]: [PM_ENUMS.READ],

        [RESOURCES.NOTAMS]: [PM_ENUMS.READ],
        ...(ZONE_DELETE_ENABLED && {[RESOURCES.ZONE_ARCHIVE]: []}),

    },

    [PM_ROLES.DRAI_EDITOR]: {

        [RESOURCES.ZONES]: [PM_ENUMS.READ, PM_ENUMS.UPDATE, PM_ENUMS.CREATE],
        ...(ZONE_DELETE_ENABLED && {[RESOURCES.ZONE_ARCHIVE]: [PM_ENUMS.CREATE]}),

    },

    [PM_ROLES.SUPERADMIN]: {

        [RESOURCES.ZONES]: [PM_ENUMS.READ, PM_ENUMS.UPDATE, PM_ENUMS.CREATE],
        [RESOURCES.ZONE_LEGS]: [PM_ENUMS.READ, PM_ENUMS.UPDATE],
        [RESOURCES.ZONE_TYPES]: [PM_ENUMS.READ, PM_ENUMS.CREATE, PM_ENUMS.UPDATE],
        [RESOURCES.GOVS]: [PM_ENUMS.READ, PM_ENUMS.UPDATE, PM_ENUMS.CREATE],
        [RESOURCES.UNIT_GOVS]: [PM_ENUMS.READ, PM_ENUMS.UPDATE, PM_ENUMS.CREATE],

        [RESOURCES.UNITS]: [PM_ENUMS.READ, PM_ENUMS.UPDATE, PM_ENUMS.CREATE],
        [RESOURCES.MISSIONS]: [PM_ENUMS.READ],
        [RESOURCES.OPERATORS]: [PM_ENUMS.READ],
        [RESOURCES.LOGS]: [PM_ENUMS.READ],
        [RESOURCES.DRONES]: [PM_ENUMS.READ],
        [RESOURCES.CERTS]: [PM_ENUMS.READ],
        [RESOURCES.CONTAINERS]: [PM_ENUMS.READ],
        [RESOURCES.FAAS]: [PM_ENUMS.READ],

        [RESOURCES.POIS]: [],


        [RESOURCES.DRONE_MODELS]: [PM_ENUMS.READ, PM_ENUMS.UPDATE, PM_ENUMS.CREATE],

        [RESOURCES.EVENT_GOVS]: [PM_ENUMS.READ],
        [RESOURCES.EVENT_OPERATORS]: [PM_ENUMS.READ],
        [RESOURCES.ACM_RULES]: [PM_ENUMS.READ],
    },

    [PM_ROLES.OPERATOR]: {
        [RESOURCES.DRONES]: [PM_ENUMS.READ, PM_ENUMS.UPDATE],
        [RESOURCES.CERTS]: [PM_ENUMS.READ, PM_ENUMS.UPDATE],
        [RESOURCES.MISSIONS]: [PM_ENUMS.READ, PM_ENUMS.UPDATE],
        [RESOURCES.ZONES]: [PM_ENUMS.READ, PM_ENUMS.UPDATE],
        [RESOURCES.DRONE_MODELS]: [PM_ENUMS.READ],
        [RESOURCES.CONTAINERS]: [PM_ENUMS.READ, PM_ENUMS.UPDATE],

        [RESOURCES.DRONES_ARCHIVE] : [PM_ENUMS.READ],
        [RESOURCES.CERTS_ARCHIVE] : [PM_ENUMS.READ],

    },

    //complete override with custom CAA role.
    [PM_ROLES.CAA_EDITOR]: {
        [RESOURCES.DRONES]: [PM_ENUMS.READ, PM_ENUMS.UPDATE],
        [RESOURCES.CERTS]: [PM_ENUMS.READ, PM_ENUMS.UPDATE],
        [RESOURCES.MISSIONS]: [],
        [RESOURCES.ZONES]: [],
        [RESOURCES.DRONE_MODELS]: [PM_ENUMS.READ],
        [RESOURCES.OPERATORS]: [PM_ENUMS.READ],
        [RESOURCES.POIS]: [],

        [RESOURCES.CONTAINERS]: [],

    },

    [PM_ROLES.ATC_MASTER]: {
        [RESOURCES.NOTAMS]: [PM_ENUMS.CREATE],
        [RESOURCES.ACM_RULES]: [PM_ENUMS.CREATE, PM_ENUMS.READ, PM_ENUMS.UPDATE, PM_ENUMS.DELETE]
    },

    //complete override with custom LAU role.
    [PM_ROLES.LAU_EDITOR]: {
        [RESOURCES.MISSIONS]: [PM_ENUMS.READ],
    },

};

export function combineRoles(roles) {
    let combinedPermissions = {};
    console.log("====roles", roles);
    //todo unify superadmin check
    const isSupAdmin = isSuperAdmin(roles);
    const isCaa = isCaaEditor(roles);
    const isLau = isLauEditor(roles);

    //console.log("isCAA", isCaa, isSuperAdmin);

    //fixme hacking
    //CAA not mixable for now!
    if (isCaa && !isSupAdmin) {
        return permissions_grid[PM_ROLES.CAA_EDITOR];
    }
    // TODO discuss the conception of roles
    if (isLau && !isSupAdmin) {
        return {...permissions_grid[PM_ROLES.LAU_EDITOR], ...permissions_grid[PM_ROLES.DRAI_EDITOR]};
    }

    for (let i=0; i<roles.length; i++) {
        const role = permissions_grid[roles[i]];

        if (!role) continue; //fake role like ATC_MASTER.
        //node 6-> no object.entries, probably...
        //to check out -> https://github.com/gas-buddy/babel-plugin-transform-object-entries/tree/master/src
        for (const [resource, permissions] of Object.entries(role)) {
            //console.log(`${key} ${value}`);
            if (!combinedPermissions[resource]) {
                combinedPermissions[resource] = [];
            }
            for (let j=0; j<permissions.length; j++) {
                if (combinedPermissions[resource].indexOf(permissions[j]) === -1) {
                    combinedPermissions[resource].push(permissions[j]);
                }
            }
        }
    }


    console.log(combinedPermissions);
    return combinedPermissions;
}

export function isCaaEditor(roles) {
    return (roles.indexOf(PM_ROLES.CAA_EDITOR) !== -1)
}

export function isLauEditor(roles) {
    return (roles.indexOf(PM_ROLES.LAU_EDITOR) !== -1)
}

export function isDraiEditor(roles) {
    return (roles.indexOf(PM_ROLES.DRAI_EDITOR) !== -1)
}

export function isSuperAdmin(roles) {
    return (roles.indexOf(PM_ROLES.SUPERADMIN) !== -1);
}

//special check for atc users allowed to override mission status
//for all zones,,
//currently -> all ansp users (civ/mil) (see roles assigment code)
export function isAtcMaster(roles) {
    return (roles.indexOf(PM_ROLES.ATC_MASTER) !== -1)
}

export function isOperator(roles) {
    return (roles.indexOf(PM_ROLES.OPERATOR) !== -1);
}

export function isGov(roles) {
    return (roles.indexOf(PM_ROLES.OPERATOR) === -1);
}

export function canRead(resource, permissions) {
    if (permissions && permissions.perResource[resource])
        return (permissions.perResource[resource].indexOf(PM_ENUMS.READ) !== -1);
    else
        return false;
}

export function canCreate(resource, permissions) {
    if (permissions && permissions.perResource[resource])
        return (permissions.perResource[resource].indexOf(PM_ENUMS.CREATE) !== -1);
    else
        return false;
}

export function canUpdate(resource, permissions) {
    if (permissions && permissions.perResource[resource])
        return (permissions.perResource[resource].indexOf(PM_ENUMS.UPDATE) !== -1);
    else
        return false;
}

export function canDelete(resource, permissions) {
    if (permissions && permissions.perResource[resource])
        return (permissions.perResource[resource].indexOf(PM_ENUMS.DELETE) !== -1);
    else
        return false;
}
