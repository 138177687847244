import {Toolbar} from "ra-ui-materialui";
import React from "react";
import UnitSaveActionButton from "./UnitSaveActionButton";

//currently the same as ZoneSaveToolbar, but for unit


const UnitSaveToolbar = props => (

    <Toolbar  >
        <UnitSaveActionButton
            //submitOnEnter={true}
            {...props}
            label={'ra.action.save'}

        />
    </Toolbar>
);

export default UnitSaveToolbar;
