import React, {createElement} from 'react';
import PropTypes from 'prop-types';
import {createStore, compose, applyMiddleware} from 'redux';
import {Provider} from 'react-redux';
import createHistory from 'history/createHashHistory';
import {Switch, Route} from 'react-router-dom';
import {ConnectedRouter, routerMiddleware} from 'react-router-redux';
import createSagaMiddleware from 'redux-saga';
import {all, fork} from 'redux-saga/effects';
import withContext from 'recompose/withContext';
import formMiddleware from 'ra-core/esm/form/formMiddleware'; //required!!!
//import { USER_LOGOUT } from './actions/authActions';
import {USER_LOGOUT} from 'react-admin';

//import createAppReducer from './reducer';
import {createAppReducer} from 'react-admin';

//import { adminSaga } from './sideEffect';
//import { adminSaga } from 'react-admin';

//import { TranslationProvider, defaultI18nProvider } from './i18n';
import {TranslationProvider, defaultI18nProvider, resolveBrowserLocale} from 'react-admin';

//import CoreAdminRouter from './CoreAdminRouter';

import {createLogger} from 'redux-logger';
import {persistStore, persistReducer} from 'redux-persist';
import {PersistGate} from 'redux-persist/lib/integration/react';
import storage from 'redux-persist/lib/storage'; //fixme fixed! good storage

import createFilter from 'redux-persist-transform-filter';

import storeProvider from '../MyStoreProvider'
import ErrorBoundary from "./ErrorBoundary";

//extra Admin defaults
import {
    Layout as DefaultLayout,
    Loading,
    Login,
    Logout,
    Menu,
    NotFound,
} from 'ra-ui-materialui';
import MyAdminThemeDecorator from "./MyAdminThemeDecorator";
import "./global.css"
import {setAppExtrasInitialState} from "../../LogicV1Redux/reducers/MyAppExtrasReducer";
import {setInitialTitle} from "../logic/ui/MyUiReducer";

import {defineCustomMomentObjects, setMomentLocale} from "../utils/momentHelpers";
import {reduxLoggerPredicate, useSimpleReduxLogger} from "../../DebugConfig";
import {omit} from "lodash";
//based on original RA2 CoreAdmin.js
//rewritten to easily update (see TODOS or notes)
//fixed imports
//removed default adminSaga
//added logger
//added persistance

//getting outdated -> see latest src.
//adding formmiddleware like in 2.4.2

const CoreAdmin = ({
                       appLayout,
                       authProvider,
                       children,
                       customReducers = {},
                       customSagas = [],
                       customRoutes = [],
                       dashboard,
                       history,
                       menu,
                       catchAll,
                       dataProvider,
                       i18nProvider = defaultI18nProvider,
                       theme,
                       title = 'React Admin',
                       loading,
                       loginPage,
                       logoutButton,
                       initialState,
                       configs,
                       locale = resolveBrowserLocale() //must be inited
                   }) => {
    setAppExtrasInitialState({...configs});
    setInitialTitle(title);

    console.log('initialState in myadmin', initialState);

    const messages = i18nProvider(locale);
    const appReducer = createAppReducer(customReducers, locale, messages);

    // Needs to be in this order - moment.defineLocale() has a side effect - sets this locale to active
    defineCustomMomentObjects();
    setMomentLocale(locale);


    //LGZ here -> not very happy about this,
    //my recommendation would be to set whitelist in specific reducer... (better control level)
    //... but there were some issues i will need to copy from mobile app code.
    // so for now if it works, let's keep it. TODO cleanup later.

    // DN -> leaving for one session only, sending it over the network is a little bit confusing for today
    const mySelfSubsetFilter = createFilter(
        'mySelf',
        ['smsCodeRequest', 'smsCodeConfirmed', 'smsCodeTime', 'smsCodeAgain']
    );

    const persistConfig = {
        key: `root2-${configs.appMode}`,
        storage: storage, //default persist storage
        //stateReconciler: autoMergeLevel2 // see "Merge Process" section for details.
        whitelist: ['persistedLocale', 'myAuth', 'infoSeen', 'configuration', 'mySelf'],
        transforms: [mySelfSubsetFilter]
        //whitelist: ['myAuth']
    };

    //fixme!!
    const resettableAppReducer = (state, action) =>
        appReducer(
            action.type !== USER_LOGOUT ?
                state
                :
                undefined,
            action);

    const saga = function* rootSaga() {
        yield all(
            [
                //12.06.18, removing default adminSaga
                //adminSaga(dataProvider, authProvider, i18nProvider),
                ...customSagas,
            ].map(fork)
        );
    };
    const sagaMiddleware = createSagaMiddleware();
    const routerHistory = history || createHistory();

    //const persistedReducer = persistReducer(persistConfig, appReducer);
    const persistedReducer = persistReducer(persistConfig, resettableAppReducer);

    let middlewareChain = [
        sagaMiddleware,
        formMiddleware,  //copied from 2.4.2, should fix some forms issues
        routerMiddleware(routerHistory),
    ];

    if (useSimpleReduxLogger) {
        const logger = createLogger({
            // ...options
            collapsed: true,
            predicate:reduxLoggerPredicate,
        });

        middlewareChain.push(logger);
    }

    const store = createStore(
        persistedReducer,
        initialState,
        compose(
            applyMiddleware(...middlewareChain),
            typeof window !== 'undefined' && window.devToolsExtension //add dev/prod switch ?
                ? window.devToolsExtension()
                : f => f
        )
    );

    const persistor = persistStore(store);

    //12.06.18, add helper for accessing reduxStore
    storeProvider.init(store);

    sagaMiddleware.run(saga);

    const logout = authProvider ? createElement(logoutButton) : null;

    return (
        <Provider store={store}>
            <PersistGate persistor={persistor} loading={null}>
                <TranslationProvider>
                    <ConnectedRouter history={routerHistory}>
                        <ErrorBoundary>
                        <Switch>
                            <Route
                                exact
                                path="/login"
                                render={props =>
                                    createElement(loginPage, {
                                        ...props,
                                        title,
                                    })}
                            />
                            {/*huh, so many routes...*/}
                            <Route
                                exact
                                path="/reset-pass-rq"
                                render={props =>
                                    createElement(loginPage, {
                                        ...props,
                                        title,
                                    })}
                            />
                            <Route
                                exact
                                path="/reset-pass-intermediate"
                                render={props =>
                                    createElement(loginPage, {
                                        ...props,
                                        title,
                                    })}
                            />
                            <Route
                                exact
                                path="/reset-pass"
                                render={props =>
                                    createElement(loginPage, {
                                        ...props,
                                        title,
                                    })}
                            />
                            <Route
                                exact
                                path="/reset-pass-final"
                                render={props =>
                                    createElement(loginPage, {
                                        ...props,
                                        title,
                                    })}
                            />

                            <Route
                                exact
                                path="/confirm-register"
                                render={props =>
                                    createElement(loginPage, {
                                        ...props,
                                        title,
                                    })}
                            />
                            <Route
                                exact
                                path="/verified"
                                render={props =>
                                    createElement(loginPage, {
                                        ...props,
                                        title,
                                    })}
                            />
                            <Route
                                exact
                                path="/register"
                                render={props =>
                                    createElement(loginPage, {
                                        ...props,
                                        title,
                                    })}
                            />
                            <Route
                                path="/"
                                render={props => (
                                    <MyAdminThemeDecorator
                                        appLayout={appLayout}
                                        catchAll={catchAll}
                                        customRoutes={customRoutes}
                                        dashboard={dashboard}
                                        loading={loading}
                                        loginPage={loginPage}
                                        logout={logout}
                                        menu={menu}
                                        theme={theme}
                                        title={title}

                                        {...props}
                                    >
                                        {children}
                                    </MyAdminThemeDecorator>
                                )}
                            />
                        </Switch>
                        </ErrorBoundary>
                    </ConnectedRouter>
                </TranslationProvider>
            </PersistGate>
        </Provider>
    );
};

const componentPropType = PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.string,
]);

CoreAdmin.propTypes = {
    appLayout: componentPropType,
    authProvider: PropTypes.func,
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
    catchAll: componentPropType,
    customSagas: PropTypes.array,
    customReducers: PropTypes.object,
    customRoutes: PropTypes.array,
    dashboard: componentPropType,
    dataProvider: PropTypes.func.isRequired,
    history: PropTypes.object,
    i18nProvider: PropTypes.func,
    initialState: PropTypes.object,
    loading: componentPropType,
    locale: PropTypes.string,
    loginPage: componentPropType,
    logoutButton: componentPropType,
    menu: componentPropType,
    theme: PropTypes.object,
    title: PropTypes.node,
};

const CoreAdminWContext = withContext(
    {
        authProvider: PropTypes.func,
    },
    ({authProvider}) => ({authProvider})
)(CoreAdmin);


const Admin = CoreAdminWContext;

Admin.defaultProps = {
    appLayout: DefaultLayout,
    catchAll: NotFound,
    loading: Loading,
    loginPage: Login,
    logoutButton: Logout,
    menu: Menu,
};

export default Admin;
