// at the moment (12-11-18) we need to mix old and new zones logic..
// furthermore hacking lb logic for resource managment and postprocessing
// (in ra2) seems to be a bad idea, since sooner or later
// we'll have to have some support on server-side....
// and let's not forget about planned data tiling, which is yet another thing
// so... writing flow for lb zones, as pluggable/replacable redux data module

//store related
export const STORE_ALL_ZONES_V2 = "STORE_ALL_ZONES_V2";
export const FORCE_UPDATE_ZONES_V2 = "FORCE_UPDATE_ZONES_V2"; //timestamp only, testing now
//sagas related
export const FETCH_ALL_ZONES_V2_RQ = "FETCH_ALL_ZONES_V2_RQ";
export const DELETE_ZONE = "DELETE_ZONE";

export const SAVE_ZONE_DATA_V2_RQ = "SAVE_ZONE_DATA_V2_RQ"; //SPECIAL SAGA call
