export class OperatorPrivileges {
    static FLAG_SUAVO = 1;
    static FLAG_112UAVO = 2;

    privileges;

    constructor(privileges = 0) {
        this.privileges = privileges;
    }

    get() {
        return this.privileges;
    }

    setPrivileges(mask) {
        this.privileges |= mask;
        return this;
    }

    removePrivileges(mask) {
        this.privileges &= ~mask;
        return this;
    }

    is112() {
        return !!(this.privileges & OperatorPrivileges.FLAG_112UAVO);
    }

    isSuavo() {
        return !!(this.privileges & OperatorPrivileges.FLAG_SUAVO);
    }

    /**
     * Checks if any flag set in mask is set. Note that a bitmask with multiple set flags acts like an "either/or".
     * @param mask
     * @returns {boolean}
     */
    isEither(mask) {
        if(mask===0)
            throw new Error(
                'Bitmask cannot be equal to 0. For none privileges check use getPrivileges method ' +
                'and make comparison with 0'
            );
        return !!(this.privileges & mask);
    }
}