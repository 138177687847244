//best would be to use code to find in translation table..

export default {
    THREE_LIGHTS : {

    },
    ZONE_ACTIVITY : {
        TIME_RANGE_VALIDATION: {
            code: 'TIME_RANGE_VALIDATION',
            msg: 'time range not supported yet',
        },
        TIME_VALIDATION: {
            code: 'TIME_VALIDATION',
            msg: 'time must be a number from Date().getTime, but value is %s',
        },
        //fixme -> should be object
        // ZONE_RULES_VALIDATION: {
        //     code: 'ZONE_RULES_VALIDATION',
        //     msg: 'no zonesRules array provided',
        // },

        INSPECT_DATA_VALIDATION: {
            code: 'ZONE_RULES_VALIDATION',
            msg: 'no inspect data provided',
        },

        ZONE_RECORD_VALIDATION: {
            code: 'ZONE_RECORD_VALIDATION',
            msg: 'no zone record provided',
        },
    },
    ZONE_TYPE : {
        ZONE_TYPES_VALIDATION: {
            code: 'ZONE_TYPES_VALIDATION',
            msg: 'no zoneTypes array provided',
        },
        ZONE_TYPE_NOT_FOUND: {
            code: 'ZONE_TYPE_NOT_FOUND',
            msg: 'zone_type data for type %s not found. zone: %s',
        }
    }
};

