
import {Utils, BaseModule, ErrorMsgs, DebugMsgs} from '../common/'

const ERR = ErrorMsgs.ZONE_ACTIVITY;
const DBG = DebugMsgs.ZONE_ACTIVITY;

const hackDRA = ['DRAI', 'DRAP', 'DRAR', 'DRARH', 'DRARL', 'DRAU', 'DRAT', 'DRARM'];

export default class ZoneActivityProcessor extends BaseModule {

    constructor(...args) {
        super(...args);
        this._moduleId = 'ZoneActivity';
    }

    //to extend?
    validateInput(inspectData, zoneRules, time) {
        if (!inspectData) {
            this.throwErr(ERR.INSPECT_DATA_VALIDATION)
        }

        if (!inspectData) {
            this.throwErr(ERR.ZONE_RECORD_VALIDATION)
        }

        if (Array.isArray(time)) {
            this.throwErr(ERR.TIME_RANGE_VALIDATION);
        }
        if (!Utils.isNumber(time)) {
            this.throwErr(ERR.TIME_VALIDATION, time);
        }
        //zone rules is object here. fixme
        // if (!Array.isArray(zoneRules) && zoneRules.length < 1) {
        //     this.throwErr(ERR.ZONE_RULES_VALIDATION, time);
        // }
    }

    //multitimeslice sucks. allows multiple layers with the same name.
    //not supported now. (actually just wrong min/max draw)
    //18.06.19. passing zone.inspectData to conform to new geojson flow required by mobile app
    //
    // TODO review flow for server side
    // (as there is no relation (yet?) beetwen zone and additional data sources
    // ie webcat, aixm and soon notam
    // TODO add timerange support (time is not a timestamp, but array[start, stop]


    calculate({inspectData, time}) {
        
        const zoneRecord = inspectData.zoneRecord;
        const aixmTimeslices = inspectData.aixmTimeslices;
        const webcatActivations = inspectData.webcatActivations;
        const notams = inspectData.notams;

        const zoneRules = inspectData.__zoneRules;

        const hasTimeslices = (aixmTimeslices && aixmTimeslices.length > 0);
        const hasWebcat = (webcatActivations && webcatActivations.length > 0);
        const hasNotams = (notams && notams.length > 0);

        //console.log('===zone', inspectData.name)

        let moreThan1Ts, moreThan1Acts, moreThan1Notams,  exactly1Wc, hasDabSubtract;

        let timeslicesActive, webcatActive, notamsActive, notamsActivations;
        let activated = false;

        if (!(hasTimeslices || hasWebcat)) {

            if (!zoneRules.fallback_active) {
                let archived = true;
                this.debug(DBG.ZONE_IS_ARCHIVED, zoneRecord.type, zoneRecord.name);
                return {archived};
            }
        }

        let __min = Number.MAX_SAFE_INTEGER;
        let __max = Number.MIN_SAFE_INTEGER;

        const timeComparator = (Array.isArray(time)) ? Utils.doesRangesOverlap : Utils.isWithinRange;

      
        if (zoneRules.use_aixm && hasTimeslices) {
            
            moreThan1Ts = aixmTimeslices.length > 1;

            //multiple timeslices can overlap

            //let __tssMin = Number.MAX_SAFE_INTEGER;
            //let __tssMax = Number.MIN_SAFE_INTEGER;

            for (let i = 0; i < aixmTimeslices.length; i++) {
                //if (!moreThan2Ts) continue;

                const currTS = aixmTimeslices[i];

                //hmmm. why i have to check this?
                currTS.__active = timeComparator(time, currTS.__startT, currTS.__stopT) && currTS.aixmActivations.length > 0;

                if (!currTS.__active) {
                    //this.log(DBG.INACTIVE_TIMESLICE, zoneRecord.type, zoneRecord.name);
                    console.log(DBG.INACTIVE_TIMESLICE, zoneRecord.type, zoneRecord.name, currTS);
                    continue;
                }

                let __tsMin = Number.MAX_SAFE_INTEGER;
                let __tsMax = Number.MIN_SAFE_INTEGER;
                let __tsActive = currTS.__hasDabSubtract; //default state. if subtract, have to be true. (what about DAB conflict?)

                moreThan1Acts = (moreThan1Acts)?moreThan1Acts:currTS.aixmActivations.length > 1;

                for (let j = 0; j < currTS.aixmActivations.length; j++) {
                    const currAC = currTS.aixmActivations[j];
                    //console.log('currAc', currAC);
                    const isSubtract = currAC.note === 'subtract';
                    //console.log('timeComparator', timeComparator(time, currAC.__startT, currAC.__stopT));
                    currAC.__inRange = timeComparator(time, currAC.__startT, currAC.__stopT);
                    currAC.___time = time;

                    if (isSubtract) {
                        hasDabSubtract = true;
                    }
                    if (currAC.__inRange) {
                        currAC.__active = true;
                        //possible dabConflict
                        if (isSubtract) {
                            __tsActive = false;
                        } else {
                            __tsActive = true;
                            if (currAC.min < __tsMin) {
                                __tsMin = currTS.min;
                            }
                            if (currAC.max > __tsMax) {
                                __tsMax = currTS.max;
                            }
                        }
                    } else {
                        if (isSubtract) {
                            if (currAC.min < __tsMin) {
                                __tsMin = currTS.min;
                            }
                            if (currAC.max > __tsMax) {
                                __tsMax = currTS.max;
                            }
                        }
                    }
                }

                if (__tsActive) {
                    timeslicesActive = true;

                    if (__tsMin < __min) {
                        __min = __tsMin;
                    }
                    if (__tsMax > __max) {
                        __max = __tsMax;
                    }
                    //console.log('===curr ts', __min, __max)
                }
            }
        }

        if (zoneRules.use_webcat && hasWebcat) {
            let __wcMin = Number.MAX_SAFE_INTEGER;
            let __wcMax = Number.MIN_SAFE_INTEGER;
            let __wcActive = false;

            if (webcatActivations.length === 1) {
                exactly1Wc = true;
                console.warn('=== 1 wc activation', zoneRecord.name)
            }

            for (let i = 0; i < webcatActivations.length; i++) {
                const currWA = webcatActivations[i];
                currWA.__active = timeComparator(time, currWA.__startT, currWA.__stopT);
                if (currWA.__active) {
                    __wcActive = true;
                    if (currWA.min < __wcMin) {
                        __wcMin = currWA.min;
                    }
                    if (currWA.max > __wcMax) {
                        __wcMax = currWA.max;
                    }
                }
            }

            if (__wcActive) {
                webcatActive = true;
                __min = __wcMin;
                __max = __wcMax;
            }
        }

        //we will need descrption from parent (probably)
        // , so later either move to inner loop, or fix description on preprocess...
        //if (zoneRules.use_notam && hasNotams) {
        //assuming if there is notam, use as override.
        if (hasNotams) {
            notamsActive = false;
            notamsActivations = false;
            moreThan1Notams = notams.length > 1;

            for (let i = 0; i < notams.length; i++) {
                //if (!moreThan2Ts) continue;

                const currNotam = notams[i];

                currNotam.__active = timeComparator(time, currNotam.__startT, currNotam.__stopT);// && currNotam.notamActivations.length > 0;

                if (!currNotam.__active) {
                    //this.log(DBG.INACTIVE_TIMESLICE, zoneRecord.type, zoneRecord.name);
                    console.error('inactive notam', zoneRecord.type, zoneRecord.name, currNotam);
                    continue;
                }

                notamsActive = true;

                let __notamMin = Number.MAX_SAFE_INTEGER;
                let __notamMax = Number.MIN_SAFE_INTEGER;
                let __notamActive = false;

                for (let j = 0; j < currNotam.notamActivations.length; j++) {
                    const currNA = currNotam.notamActivations[j];
                    currNA.__active = timeComparator(time, currNA.__startT, currNA.__stopT);
                    if (currNA.__active) {
                        __notamActive = true;
                        if (currNA.min < __notamMin) {
                            __notamMin = currNA.min;
                        }
                        if (currNA.max > __notamMax) {
                            __notamMax = currNA.max;
                        }
                    }
                }

                if (__notamActive) {
                    notamsActivations = true;

                    if (__notamMin < __min) {
                        __min = __notamMin;
                    }
                    if (__notamMax > __max) {
                        __max = __notamMax;
                    }
                    //console.log('===curr ts', __min, __max)
                }
            }

        }

        //activated = hasSubtract || hasNotam || moreThan2Ts || moreThan2Acts || hasWebcat || webcatActive || exactly1Wc || hasNotams;

        //activated = hasNotams;


        activated = (hasNotams && notamsActive)
            ? notamsActivations
            : timeslicesActive || webcatActive;
           
    
        //console.log('activated', activated);
        //console.log('notamsActive', notamsActive);
        //console.log('notamsActivations', notamsActivations);

        if (!activated) {
            
            //sets zone defaults if not overriden
            //and do final check on always active
            //activated = !(zoneRules.use_webcat || zoneRules.use_notam || zoneRules.use_aixm); //enabling causes NW to fail
            //activated = !(zoneRules.use_webcat || zoneRules.use_aixm); //enabling use notam causes R failed eval
            const actCheck = zoneRecord.acts && zoneRecord.acts.H24 === true;

            //to fix with backend!
            const draHACK = hackDRA.indexOf(zoneRecord.type) !== -1;

            activated = actCheck || draHACK;
            __min = zoneRecord.min;
            __max = zoneRecord.max;

          
            if (activated) {
                console.log('fallback switch activity', zoneRecord.name, zoneRecord.acts )
            }
        } else {
            //console.log('===curr z', inspectData.name, __min, __max)
        }
        return {__inactive:!activated, __min, __max}
    };
}
