import {MARKER_TYPES} from '../deckPrimitives/DeckPoly'
import PubSub from "pubsub-js";

import {PubSubSignals} from "../../../LogicV1Redux/constants/PubSubSignals";


const EDIT_MODE = {
    SELECT : 'SELECT',
    MOVE_VTX:'VTX', //vertex
    MOVE_POLY: 'MOVE'
};
let editMode = EDIT_MODE.SELECT;
let selectedIdx = null;
let moveLatLng = null;
//see deck poly notes

//no z-axis at the moment, so keeping clear, event required for right click check..
//assuming HAMMER event!
export function onPolyEditClick(deckPoly, latLng, layerClickEvent, event) {

    console.log('onPolyEditClick', editMode, deckPoly, layerClickEvent);
    const latlngs = deckPoly.helpers;

    const closingVtxIdx = latlngs.length - 1;

    if (editMode === EDIT_MODE.SELECT) {
        const lceObject = (layerClickEvent && layerClickEvent.object)?layerClickEvent.object:null;

        console.log('onPolyEditClick', deckPoly, lceObject);

        if (lceObject && lceObject.type === MARKER_TYPES.VTX) {

            selectedIdx = lceObject.llIdx;
            console.log('selected idx=======', selectedIdx, layerClickEvent, event);
            //IMPORTANT -> DELETE_VTX WITH BUTTON:2 (rightButton in hammer event!)
            if (event && event.rightButton) {
                console.log('=======delete VTX here!!!', layerClickEvent);

                //yup, 3 -> closing vertex overlap
                if (closingVtxIdx < 3) {
                    console.warn('no delete for starting segment (degenearting)', latlngs);
                    return deckPoly;
                }

                if (selectedIdx === closingVtxIdx || selectedIdx === 0) {
                    latlngs.splice(closingVtxIdx, 1); //splice mutates
                    latlngs[0][0] = latlngs[closingVtxIdx - 1][0];
                    latlngs[0][1] = latlngs[closingVtxIdx - 1][1];
                    console.warn('closing vertex remove', latlngs);

                } else {
                    latlngs.splice(selectedIdx, 1); //splice mutates
                }

                return deckPoly.recalc(latlngs, true);

            }

            editMode = EDIT_MODE.MOVE_VTX;

            //wrong answer!
            // //hmm, autoclosing if selected last
            // if (selectedIdx === deckPoly.helpers.length -1) {
            //     deckPoly.recalc([...deckPoly.helpers, layerClickEvent.object.position]);
            //     console.log('autoclosing')
            //
            // }

            if (selectedIdx === latlngs.length - 1)
                selectedIdx = 0;
            return deckPoly; //probably some styling later..


        } else if (lceObject && lceObject.type === MARKER_TYPES.INSERT) {
            console.log('insert here', lceObject);
            selectedIdx = lceObject.afterIdx + 1;

            latlngs.splice(selectedIdx , 0, lceObject.position); //splice mutates
            editMode = EDIT_MODE.MOVE_VTX;
            return deckPoly.recalc(latlngs, true);

        } else if (lceObject === deckPoly) {

            editMode = EDIT_MODE.MOVE_POLY;
            // sprint fixme some better dirty setup needed
            PubSub.publish(PubSubSignals.SET_DRAW_EDITOR_DIRTY);
            moveLatLng = latLng;
            //originalZeroLatLng = latlngs[0];
            return deckPoly; //probably some styling later..

        }
        return null; //deselect
    }

    else if (editMode === EDIT_MODE.MOVE_VTX) {
        editMode = EDIT_MODE.SELECT;
        latlngs[selectedIdx] = latLng;

        // sprint fixme some better dirty setup needed
        //console.log('======PubSubSignals.SET_MISSION_LEGS_DIRTY')
        PubSub.publish(PubSubSignals.SET_DRAW_EDITOR_DIRTY);

        //move closing vertex
        if (selectedIdx === 0) {
            latlngs[closingVtxIdx] = latLng;
        }
        return deckPoly.recalc(latlngs, true);

    } else if (editMode === EDIT_MODE.MOVE_POLY) {
        editMode = EDIT_MODE.SELECT
    }

    return deckPoly;
}

export function onPolyEditMouseMove(deckPoly, latLng) {

    //console.log('poly mouse move', editMode);

    if (editMode === EDIT_MODE.MOVE_VTX) {

        //console.log('onPolyEditMouseMove, MOVE_VTX');

        let latlngs = deckPoly.helpers;
        latlngs[selectedIdx] = latLng;

        //move closing vertex
        if (selectedIdx === 0) {
            latlngs[latlngs.length - 1] = latLng;
        }

        //console.log('onPolyEditMouseMove, recalc');

        return deckPoly.recalc(latlngs, true);
    }

    else if (editMode === EDIT_MODE.MOVE_POLY) {

        //console.log('onPolyEditMouseMove, MOVE_VTX');

        let latlngs = deckPoly.helpers;

        let offset = [];
        offset[0] = latLng[0] - moveLatLng[0];
        offset[1] = latLng[1] - moveLatLng[1];
        for (let i = 0; i < latlngs.length; i++) {
            latlngs[i][0] += offset[0];
            latlngs[i][1] += offset[1];
        }
        moveLatLng = latLng;
        //console.log('onPolyEditMouseMove, recalc');

        return deckPoly.recalc(latlngs, true);
    }

    return null;

}
