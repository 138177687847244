import Configs, {FEATURES_V1_2_ENUMS} from "../../Configs";

export const RESEND_VERIFICATION_EMAIL_OPERATOR = 'RESEND_VERIFICATION_EMAIL_OPERATOR';
export const RESEND_VERIFICATION_EMAIL_GOV = 'RESEND_VERIFICATION_EMAIL_GOV';
export const BAN_OPERATOR = 'BAN_OPERATOR';
export const UNBAN_OPERATOR = 'UNBAN_OPERATOR';
export const SET_PRIVILEGE_SUAVO = 'SET_PRIVILEGE_SUAVO';
export const SET_PRIVILEGE_112UAVO_AND_SUAVO = 'SET_PRIVILEGE_112UAVO';
export const UNSET_PRIVILEGE_SUAVO = 'UNSET_PRIVILEGE_SUAVO';
export const UNSET_PRIVILEGE_112UAVO = 'UNSET_PRIVILEGE_112UAVO';

// only REST api const
export const MANAGE_BAN_OPERATOR = 'MANAGE_BAN_OPERATOR';
export const GOV_ON_OPERATOR_UPDATE = 'GOV_ON_OPERATOR_UPDATE';

const OPERATOR_PRIVILEGES_ENABLED = Configs.enabledFeatures.includes(FEATURES_V1_2_ENUMS.OPERATOR_PRIVILIGES);

const BAN_ACTIONS = [
    {
        id: BAN_OPERATOR,
        label: 'myroot.admin.actions.banUser'
    },
    {
        id: UNBAN_OPERATOR,
        label: 'myroot.admin.actions.unbanUser'
    }
];

const PRIVILEGES_ACTIONS =
    OPERATOR_PRIVILEGES_ENABLED ?
        [
            {
                id: SET_PRIVILEGE_112UAVO_AND_SUAVO,
                label: 'myroot.admin.actions.setPrivilege112UAVOandSUAVO'
            },
            {
                id: SET_PRIVILEGE_SUAVO,
                label: 'myroot.admin.actions.setPrivilegeSUAVO'
            },
            {
                id: UNSET_PRIVILEGE_112UAVO,
                label: 'myroot.admin.actions.unsetPrivilege112UAVO'
            },
            {
                id: UNSET_PRIVILEGE_SUAVO,
                label: 'myroot.admin.actions.unsetPrivilegeSUAVO'
            },
        ]
        :
        [];

export const ADMIN_ON_OPERATOR = [
    {
        id: RESEND_VERIFICATION_EMAIL_OPERATOR,
        label: 'myroot.admin.actions.resendEmail'
    },
    ...BAN_ACTIONS,
    ...PRIVILEGES_ACTIONS
];

export const ADMIN_ON_GOV = [
    {
        id: RESEND_VERIFICATION_EMAIL_GOV,
        label: 'myroot.admin.actions.resendEmail'
    },
];
