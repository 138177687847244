import React from 'react';

import PubSub from "pubsub-js";
import logger from '../../LogicV1Redux/oldLogic/Logger'
import {PubSubSignals} from '../../LogicV1Redux/constants/PubSubSignals'

import {setDrawMode} from '../glSetups/deckDraw/deckDrawStoreBridge'
import { DRAW_MODES} from '../glSetups/deckDraw/DrawEditorEnums'

import connect from "react-redux/es/connect/connect";
import drawEditorData from "../../LogicV1Redux/reducers/DrawEditorReducer";
import {Rnd} from "react-rnd";
import toolbarStyles from "./deckGLDrawToolbarStyles"
import {withStyles} from '@material-ui/core/styles';
import compose from "recompose/compose"
import classnames from "classnames"

const TOOLBAR_MODES = {
    FREE_DRAW : 'FREE_DRAW', //unsupported yet.
    MISSION_PLANNER : 'MISSION_PLANNER',
};

const cssFix = {
    maxHeight: '100%',
    overflowY: 'auto'
};

const toolbarRenderer = (data) => {
    console.log('toolbar', data);

    // for (const [key, value] of Object.entries(data)) {
    //     console.log(key, value);
    //     let newPath = Object.assign({}, samplePath);
    //     newPath.path = value;
    //     newPath.color = [0,0,255,255];
    //     paths.push(newPath);
    // }
    //
    // return data.map(el => {
    //     console.log(el);
    //     const out = el.toString();
    //     return <div>{out}</div>
    // })
    let ret = '';

    Object.entries(data).forEach(([key, value]) => {
        console.log(`${key} ${value.toString()}`);
        ret +=  `${key}:${value.toString()}\n`;
    });
    return ret;
};

function renderToolCss(baseClass, isActive) {
    return baseClass+((isActive)?' active':'');
}

class DeckGLDrawToolbarBase extends React.Component {
    constructor(props) {
        super(props);
        //this.state = {mode:getDrawMode()};
        this.handleMeasureClick = this.handleMeasureClick.bind(this);

        this.handlePolyClick = this.handlePolyClick.bind(this);
        this.handleLineClick = this.handleLineClick.bind(this);
        this.handleCircleClick = this.handleCircleClick.bind(this);
        this.handleCapsuleClick = this.handleCapsuleClick.bind(this);
        this.handlePipeClick = this.handlePipeClick.bind(this);
        this.handleEditClick = this.handleEditClick.bind(this);
        this.handleSelectClick = this.handleSelectClick.bind(this);
        this.handleDeleteClick = this.handleDeleteClick.bind(this);
    };

    handleMeasureClick(e) {
        e.preventDefault();

        if (this.props.drawMode === DRAW_MODES.MEASURE)
            setDrawMode(DRAW_MODES.NO_DRAW);
        else
            setDrawMode(DRAW_MODES.MEASURE);
        //this.forceUpdate();
        console.log('measure poly was clicked.');
    }

    handlePolyClick(e) {
        e.preventDefault();

        //state approach.. to reenable? or redux?
        // if (this.state.mode === TOOLBAR_MODES.DRAW_POLY)
        //     this.setState({mode:TOOLBAR_MODES.DEFAULT});
        // else
        //     this.setState({mode:TOOLBAR_MODES.DRAW_POLY});
        if (this.props.drawMode === DRAW_MODES.DRAW_POLY)
            setDrawMode(DRAW_MODES.NO_DRAW);
        else
            setDrawMode(DRAW_MODES.DRAW_POLY);
        //this.forceUpdate();
        console.log('toolbar poly was clicked.');
    }

    handleCircleClick(e) {
        e.preventDefault();
        if (this.props.drawMode === DRAW_MODES.DRAW_CIRCLE)
            setDrawMode(DRAW_MODES.NO_DRAW);
        else
            setDrawMode(DRAW_MODES.DRAW_CIRCLE);
        //this.forceUpdate();
        console.log('toolbar circle was clicked.');
    }

    handleCapsuleClick(e) {
        e.preventDefault();
        if (this.props.drawMode === DRAW_MODES.DRAW_CAPSULE)
            setDrawMode(DRAW_MODES.NO_DRAW);
        else
            setDrawMode(DRAW_MODES.DRAW_CAPSULE);
        //this.forceUpdate();
        console.log('toolbar capsule was clicked.');
    }

    handlePipeClick(e) {
        e.preventDefault();
        if (this.props.drawMode === DRAW_MODES.DRAW_PIPE)
            setDrawMode(DRAW_MODES.NO_DRAW);
        else
            setDrawMode(DRAW_MODES.DRAW_PIPE);
        //this.forceUpdate();
        console.log('toolbar pipe was clicked.');
    }

    handleLineClick(e) {
        e.preventDefault();
        if (this.props.drawMode === DRAW_MODES.DRAW_LINE)
            setDrawMode(DRAW_MODES.NO_DRAW);
        else
            setDrawMode(DRAW_MODES.DRAW_LINE);
        //this.forceUpdate();
        console.log('toolbar line was clicked.');
    }

    handleEditClick(e) {
        e.preventDefault();
        if (this.props.drawMode === DRAW_MODES.EDIT)
            setDrawMode(DRAW_MODES.NO_DRAW);
        else
            setDrawMode(DRAW_MODES.EDIT);
        //this.forceUpdate();
        console.log('toolbar edit was clicked.');
    }

    handleSelectClick(e) {
        e.preventDefault();
        if (this.props.drawMode === DRAW_MODES.SELECT)
            setDrawMode(DRAW_MODES.NO_DRAW);
        else
            setDrawMode(DRAW_MODES.SELECT);
        //this.forceUpdate();
        console.log('toolbar select was clicked.');
    }

    handleDeleteClick(e) {
        e.preventDefault();
        if (this.props.drawMode === DRAW_MODES.DELETE)
            setDrawMode(DRAW_MODES.NO_DRAW);
        else
            setDrawMode(DRAW_MODES.DELETE);
        //this.forceUpdate();
        console.log('toolbar delete was clicked.');
    }

    componentDidUpdate(prevProps) {
        console.log('toolbar componentDidUpdate', prevProps);

        //hacking view only mode -> fast and dirty state fix for non-edit
        //probably should be custom toolbar. hmm
        if (prevProps.viewOnly && prevProps.drawMode === DRAW_MODES.EDIT) {
            setDrawMode(DRAW_MODES.NO_DRAW);
        }
    }

    //TODO configurable toolbar
    render() {
        const {drawMode, viewOnly = false, classes} = this.props;

        console.log('DeckGLDrawToolbar drawMode', drawMode);

        const showSelectTool = (!viewOnly);

        const showMeasureTool = true;
        const showLineTool = false; //unfinished!
        const showPolygonTool = (!viewOnly);
        const showCircleTool =  (!viewOnly);
        const showCapsuleTool =  (!viewOnly);
        const showEditTool =  (!viewOnly);
        const showDeleteTool =  (!viewOnly);
        const showPipeTool =  (!viewOnly); //not tested self intersection
        const showRemoveVtxTool = false;

        //order of class names in classnames(...) matters, due to sticking active label!! @see deckGLDrawToolbarStyles.js
        return(
            <div>
                {showMeasureTool ?
                    (
                        <div
                            className={renderToolCss(classnames(classes.deckGLDrawMeasure, classes.deckGLBtn),
                                (drawMode === DRAW_MODES.MEASURE))}
                            onClick={this.handleMeasureClick}
                        >
                        </div>
                    )
                    :null
                }
                {showSelectTool ?
                    (
                        <div
                            className={renderToolCss(classnames(classes.deckGLDrawArrow, classes.deckGLBtn),
                                (drawMode === DRAW_MODES.SELECT))}
                            onClick={this.handleSelectClick}
                        >
                        </div>
                    )
                    :null
                }
                {showLineTool ?
                    (
                        <div
                            className={renderToolCss(classnames(classes.deckGLDrawLine, classes.deckGLBtn),
                                (drawMode === DRAW_MODES.DRAW_LINE))}
                            onClick={this.handleLineClick}
                        >
                        </div>
                    )
                    :null
                }
                {showPipeTool ?
                    (
                        <div
                            className={renderToolCss(classnames(classes.deckGLDrawPipe, classes.deckGLBtn),
                                (drawMode === DRAW_MODES.DRAW_PIPE))}
                            onClick={this.handlePipeClick}
                        >
                        </div>
                    )
                    :null
                }
                {showPolygonTool ?
                    (
                        <div
                            className={renderToolCss(classnames(classes.deckGLDrawPolygon, classes.deckGLBtn),
                                (drawMode === DRAW_MODES.DRAW_POLY))}
                            onClick={this.handlePolyClick}
                        >
                        </div>
                    )
                    :null
                }
                {showCircleTool ?
                    (
                        <div
                            className={renderToolCss(classnames(classes.deckGLDrawCircle, classes.deckGLBtn),
                                (drawMode === DRAW_MODES.DRAW_CIRCLE))}
                            onClick={this.handleCircleClick}
                        >
                        </div>
                    )
                    :null
                }
                {showCapsuleTool ?
                    (
                        <div
                            className={renderToolCss(classnames(classes.deckGLDrawCapsule, classes.deckGLBtn),
                                (drawMode === DRAW_MODES.DRAW_CAPSULE))}
                            onClick={this.handleCapsuleClick}
                        >
                        </div>
                    )
                    :null
                }
                {showEditTool ?
                    (
                        <div
                            className={renderToolCss(classnames(classes.deckGLDrawEdit, classes.deckGLBtn),
                                (drawMode === DRAW_MODES.EDIT))}
                            onClick={this.handleEditClick}
                        >
                        </div>
                    )
                    :null
                }
                {showRemoveVtxTool ?
                    (
                        <div
                            className={renderToolCss(classnames(classes.deckGLDrawArrowMinus, classes.deckGLBtn),
                                false)}
                                //(mode === DRAW_MODES.EDIT))}
                            //onClick={this.handleEditClick}
                        >
                        </div>
                    )
                    :null
                }
                {showDeleteTool ?
                    (
                        <div
                            className={renderToolCss(classnames(classes.deckGLDrawTrash, classes.deckGLBtn),
                                (drawMode === DRAW_MODES.DELETE))}
                            onClick={this.handleDeleteClick}
                        >
                        </div>
                    )
                    :null
                }
            </div>
        )
    }
}


const mapStateToProps = state => {
    return { drawMode: state.drawEditorData.drawMode}
};

//maybe later.. for now keeping shorthand
// const mapDispatchToProps = dispatch => (
//     {
//         setDrawModeAction : (e) => {
//             console.log('dispatchin setDrawMode', e);
//             dispatch(setDrawMode(e))
//         }
//     }
// );

export const DeckGLDrawToolbar = compose(
    connect(mapStateToProps, {}),
)(DeckGLDrawToolbarBase);


export const RndDrawToolbar = withStyles(toolbarStyles) (
    ({classes}) => (
    <Rnd
        className={classnames('rnd-toolbar', classes.deckGLToolbar)}
        default={{
            x: 10,
            y: 10,
        }}
        bounds = "parent"
        enableResizing = {false} //v1.1
        disableDragging= {true} //v1.1
    >
        <DeckGLDrawToolbar classes={classes}/>
    </Rnd>
));

export const RndViewToolbar = withStyles(toolbarStyles) (
    ({classes}) => (
    <Rnd
        className={classnames('rnd-toolbar', classes.deckGLToolbar)}
        default={{
            x: 10,
            y: 10,
        }}
        bounds = "parent"
        enableResizing = {false} //v1.1
        disableDragging= {true} //v1.1
    >
        <DeckGLDrawToolbar viewOnly={true} classes={classes}/>
    </Rnd>
));

