
import {MAP_SHOW_INSPECT_DATA, STORE_INSPECT_DATA} from "../constants/InspectConstants";

export const storeInspectData = (payload) => {
    //console.log('storeInspectData', payload);
    return ({
        type: STORE_INSPECT_DATA,
        payload,
    });
};

export const showOnMap = (payload) => {
    return ({
        type: MAP_SHOW_INSPECT_DATA,
        payload,
    });
};