import {getInitialCustomTheme} from "./initialCustomTheme";
import merge from "lodash.merge";

const colorCircleLau = {
    first: "#f5f5f5",
    second: "#eaf0f1",
    third: "#BBBDC0",
    forth: "#232f3f",
    fifth: "#4fc4f7",
    white: "#FEFFFE",
    black: "#000",
};

const primaryLau = {
    light: '#72cff9',
    main: '#4fc4f7',
    dark: '#009be4',
    contrastText: colorCircleLau.forth,
};

const secondaryLau = {
    light: '#4f5865',
    main: "#232f3f",
    dark: '#18202a',
    contrastText: colorCircleLau.first,
};

const getLauThemeCustomKeys = (primary, secondary, colorCircle) => ({

    overrides: {
        MuiAppBar: {
            colorSecondary: {
                color: colorCircle.first,
                backgroundColor: primary.dark,
            }
        },
        MuiButton: {
            textPrimary: {
                backgroundColor: primary.main,
                margin: "0.1rem",
                '&:hover': {
                    color: "unset",
                    backgroundColor: primary.dark,
                },
            },
            containedPrimary: {
                color: "unset",
                '&:hover': {
                    color: "unset",
                    backgroundColor: "unset",
                }
            }
        },
        MuiListItem: {
            button: {
                '&:hover': {
                    color: secondary.contrastText
                },
            }
        },
        MuiMenuItem: {
            root: {
                fontWeight: "500",
            }
        },
        MuiPaper: {
            root: {
                backgroundColor: colorCircle.first
            },
        },
        MuiPickersCalendarHeader: {
            iconButton: {
                backgroundColor: "transparent",
                '& > *': {
                    backgroundColor: "transparent"
                }
            },
        },
        MuiTableRow: {
            root: {
                '&$hover': {
                    '&:hover': {
                        backgroundColor: primary.light,
                        color: primary.contrastText
                    },
                },
                '&:nth-child(odd)': {
                    backgroundColor: colorCircle.first
                },
                '&:nth-child(even)': {
                    backgroundColor: colorCircle.second
                },
            }
        },
        MuiTableCell: {
            body: {
                color: "inherit"
            },
            head: {
                color: primary.contrastText
            }
        },
        MuiTableSortLabel: {
            active: {
                color: primary.contrastText
            },
            root: {
                '&:hover' : {
                    color: secondary.light,
                },
                '&:focus' : {
                    color: "inherit"
                },
            }
        },
        MuiTypography: {
            body1: {
                color: primary.contrastText
            }
        },
        MuiTooltip: {
            tooltip: {
                backgroundColor: colorCircle.white,
                color: primary.contrastText
            }
        }
    },
    palette: {
        primary: primary,
        secondary: secondary,
        colorCircle: {
            link: colorCircle.black,
            menuLink: undefined,
            menuActiveText: colorCircle.fifth,
            menuActiveBackground: secondary.dark,
            menuText: colorCircle.third,
            menuBackgroundColor: "#0009",
        }
    },
});

//TODO clean for good order passing
const lauThemeCommons = getInitialCustomTheme(secondaryLau, primaryLau, colorCircleLau);
const lauThemeCustoms = getLauThemeCustomKeys(primaryLau, secondaryLau, colorCircleLau);

export default {
    ...merge(lauThemeCommons, lauThemeCustoms)
}