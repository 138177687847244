import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import {TooltipProxy as Tooltip} from "./TooltipProxy";
import IconButton from '@material-ui/core/IconButton';
import NavigationRefresh from '@material-ui/icons/Refresh';
import { refreshView, translate } from 'ra-core';

// Copy paste from ra-material-ui only for setting up corrct tooltip behaviour
class MyRefreshButton extends Component {
    static propTypes = {
        className: PropTypes.string,
        label: PropTypes.string,
        refreshView: PropTypes.func.isRequired,
        translate: PropTypes.func.isRequired,
    };

    static defaultProps = {
        label: 'ra.action.refresh',
    };

    handleClick = event => {
        event.preventDefault();
        this.props.refreshView();
    };

    render() {
        const {
            className,
            label,
            refreshView,
            translate,
            ...rest
        } = this.props;

        return (
            <Tooltip content={label}>
                <IconButton
                    aria-label={label && translate(label, { _: label })}
                    className={className}
                    color="inherit"
                    onClick={this.handleClick}
                    {...rest}
                >
                    <NavigationRefresh />
                </IconButton>
            </Tooltip>
        );
    }
}

const enhance = compose(
    connect(
        null,
        { refreshView }
    ),
    translate
);
export default enhance(MyRefreshButton);
