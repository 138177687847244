import React from 'react';
import PropTypes from 'prop-types';
import onlyUpdateForKeys from 'recompose/onlyUpdateForKeys';
import MuiButton from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import Storage from "@material-ui/icons/Storage";
import compose from 'recompose/compose';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { translate } from 'ra-core';

import {Button, Responsive} from "ra-ui-materialui";


const styles = theme => ({
    floating: {
        color: theme.palette.getContrastText(theme.palette.primary.main),
        margin: 0,
        top: 'auto',
        right: 20,
        bottom: 60,
        left: 'auto',
        position: 'fixed',
        zIndex: 1000,
    },
    floatingLink: {
        color: 'inherit',
    },
});

//copy of CreateButton, slightly modified for archive link
const ArchiveLinkButton = ({
                          basePath = '',
                          className,
                          classes = {},
                          translate,
                          label,
                          ...rest
                      }) => (
    <Responsive
        // collision with info and alert boxes
        // small={
        //     <MuiButton
        //         component={Link}
        //         variant="fab"
        //         color="primary"
        //         className={classnames(classes.floating, className)}
        //         to={`${basePath}`}
        //         aria-label={label && translate(label)}
        //         {...rest}
        //     >
        //         <Storage />
        //     </MuiButton>
        // }
        medium={
            <Button
                component={Link}
                to={`${basePath}`}
                className={className}
                label={label && translate(label)}
                {...rest}
            >
                <Storage />
            </Button>
        }
    />
);

ArchiveLinkButton.propTypes = {
    basePath: PropTypes.string,
    className: PropTypes.string,
    classes: PropTypes.object,
    label: PropTypes.string,
    size: PropTypes.string,
    translate: PropTypes.func.isRequired,
};

const enhance = compose(
    translate,
    onlyUpdateForKeys(['basePath', 'label']),
    withStyles(styles)
);

export default enhance(ArchiveLinkButton);
