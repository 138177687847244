
import {deserializeZoneData, deserializeZonesV2, processZonesV2ToDeck} from "./ZonesListV2";
import {InspectTypes} from "../../modules2lib/common";


//currently wraps zones processing,
export function processUnitsV2ToDeck({rawData = [], bottomCutoff = Number.MIN_SAFE_INTEGER, topCutoff = Number.MAX_SAFE_INTEGER}) {
    return processZonesV2ToDeck({rawData, bottomCutoff, topCutoff})
}

//currently wraps zones processing,
export function deserializeUnitsV2(unitsDBarr) {
    return deserializeZonesV2(unitsDBarr)
}

//currently wraps zones processing,
export function deserializeUnitData(unitRecord, zoneTypesV2) {
    //would conflict with fixed typ => type.
    if (!unitRecord.type) {
        unitRecord.type = '_TEST_';
    }

    return deserializeZoneData(unitRecord,  zoneTypesV2, '_TEST_');
}
