import {DateInput as DatePicker, DateTimeInput as DateTimePicker, TimeInput as TimePicker} from "react-admin-date-inputs";
import "moment/locale/en-gb";
import "moment/locale/pl";
import MomentUtils from "material-ui-pickers/utils/moment-utils"
import React from "react";
import TranslatableTypography from "./TranslatableTypography";
import { connect } from 'react-redux'

//TODO move to app init
//TODO old approach - upgrade material ui pickers 3

const mapStateToProps = state => ({
    locale: state.i18n.locale,
});

export const DateTimePickerLocale = connect(mapStateToProps)(
    (props) => {
        const {minDate = {}, locale = {}, momentFunc, ...rest} = props;

        return (
            <DateTimePicker
                options={{
                    minDate: minDate,
                    ampm: false,
                    cancelLabel: <TranslatableTypography content={'ra.action.cancel'}/>,
                    format: 'dd, Do MMMM YYYY, HH:mm (UTC ZZ)'
                }}
                providerOptions={{utils: MomentUtils, moment: momentFunc}}
                {...rest}
            />)
    }
);

export const DatePickerLocale = connect(mapStateToProps)(
    (props) => {
        const {minDate = {}, locale = {}, momentFunc, ...rest} = props;

        return (
            <DatePicker
                options={{
                    minDate: minDate,
                    cancelLabel: <TranslatableTypography content={'ra.action.cancel'}/>,
                    format: 'D.M.YYYY'
                }}
                providerOptions={{utils: MomentUtils, moment: momentFunc}}
                {...rest}
            />)
    }
);

export const TimePickerLocale = connect(mapStateToProps)(
    (props) => {
        const {minDate = {}, locale = {}, momentFunc, ...rest} = props;

        return (
            <TimePicker
                options={{
                    minDate: minDate,
                    ampm: false,
                    cancelLabel: <TranslatableTypography content={'ra.action.cancel'}/>,
                    format: 'HH:mm',
                    clearable: true,
                    clearLabel: <TranslatableTypography content={'myroot.action.clear'}/>
                }}
                providerOptions={{utils: MomentUtils, moment: momentFunc}}
                {...rest}
            />)
    }
);