
//review differences between operator and gov modes

//huh, quickly adding rt-map functionality, to refactor, maybe as a class with inheritance.

import io from 'socket.io-client';
import {urls, APP_MODES} from '../../Configs'
import PubSub from 'pubsub-js'

import logger from './Logger'
import {getPandoraAuth} from "./dirtyAuth";
import {PubSubSignals} from '../constants/PubSubSignals'
import StoreProvider from "../../dr_ra2/MyStoreProvider";
import {fetchAllCheckinsV1Rq} from "../actions/CheckinsV1Actions";
import {fetchAllAlertsV1Rq} from "../actions/AlertsV1Actions";

let mySocket;
export function close() {

    if (mySocket) {
        console.log(mySocket);
        mySocket.disconnect();
    }
    //mySocket = null;
    console.log('-----------closing socket');

}

//add init check!
export function emit(msg, data) {

    mySocket.emit(msg, data);
}


//authheader as quick and dirty solution for old auth!
//security and inits to fixanyway
//btw - what about socket.io anyway?
export function init(appName) {

    if (mySocket) {
        console.log('socket already opened, skipping init');
        return;
    }

    console.log('-----------opening socket');

    let query, path;
    //huh,
    if (appName === 'RT_MAP') {
        path = urls.socketV1Path[APP_MODES.GOV];
        const pandoraAuth = getPandoraAuth();

        console.log(pandoraAuth);

        const {user, pass} = pandoraAuth;
        query = `user=${user}&pass=${pass}`;
        console.log('query', query);
        //there was token support long time ago, but due to pandora issues was removed.. huh
        // query = 'user='+auth.user;
        // query += (auth.token)
        //     ?'&token='+auth.token
        //     :'&pass='+auth.pass;

    } else {
        path = urls.socketV1Path[APP_MODES.OPERATOR];
        query = 'hash_id=';
        let hashId = 'fixmeDeviceID'; //fixme!!
        query += (hashId)?hashId:'unknown';
    }
    console.warn('====fix prod/dev url config!');

    mySocket = io.connect(
        urls.socketV1Url.DEV,
        {
            path,
            query
        });


    mySocket.on('connect', function () {
        let msg = 'mySocket connected';
        PubSub.publish(PubSubSignals.NOTIFICATION, {msg:msg});

        //emitTimeSyncRequest();
        mySocket.emit('timeSyncRequest', {'emitted':new Date().getTime()/1000});
    });

    mySocket.on('pg_notify_operations', function(data){

        let msg = 'mySocket pg_notify_operations';
        logger.debug(msg, data);
        console.log(msg, data);

        PubSub.publish(PubSubSignals.NOTIFICATION, {msg:msg});

        //fixme call should be in saga?
        //request full list

        const store = StoreProvider.getStore();
        store.dispatch(fetchAllCheckinsV1Rq());
    });

    mySocket.on('pg_notify_atm_alerts', function(data){
        let msg = 'mySocket pg_notify_atm_alerts';
        logger.debug(msg, data);
        PubSub.publish(PubSubSignals.NOTIFICATION, {msg:msg});

        //fixme call should be in saga?

        //request full list
        const store = StoreProvider.getStore();
        store.dispatch(fetchAllAlertsV1Rq());
    });

    mySocket.on('timeSyncResponse', function (data) {
        logger.warn('mySocket timeSyncResponse', data);
    });

    mySocket.on('connect_failed', function(data){
        let msg = 'mySocket connect_failed';
        logger.error(msg, data);
        PubSub.publish(PubSubSignals.NOTIFICATION, {msg:msg});
    });

    mySocket.on('disconnect', function (data) {
        let msg = 'mySocket disconnect';
        logger.error(msg, data);
        PubSub.publish(PubSubSignals.NOTIFICATION, {msg:msg});
    });

    mySocket.on('error', function (err) {
        let msg = 'mySocket error';
        logger.error(msg, err);
        PubSub.publish(PubSubSignals.NOTIFICATION, {msg:msg});
    });

}

export default {
    init,
    close,
    emit
}
