import {AutocompleteInput, Filter, ReferenceInput} from "ra-ui-materialui";
import React from "react";

//fast and dirty operator search for drones and certs.
//should be optimized (custom call for names only?),
// and maybe reworked
// (like some issues with autocomplete clearing etc)
export default (props) => (
    <ReferenceInput
        {...props}
        //label={'resources.drones.fields.operator'} //from props
        //source="operatorid" //from props
        reference="operators"
        sort={{field: 'lastName', order: 'ASC'}}
        filter={{ "lastName": { "neq": null } }}
        filterToQuery={searchText => ({lastName: {ilike: searchText + '%'}})}
    >
        <AutocompleteInput
            optionText={choice =>
                `${choice.lastName} ${choice.firstName}`
            }
        />
    </ReferenceInput>
);
