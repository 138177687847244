import React from 'react';

import * as dd from 'dis-gui'; //renaming didn't work


import DisCapsuleRenderer from "./DisCapsuleRenderer";
import DisCircleRenderer from "./DisCircleRenderer";
import DisPolygonRenderer from "./DisPolygonRenderer";
import DisPipeRenderer from "./DisPipeRenderer";

import { PRIMITIVES} from '../glSetups/deckDraw/DrawEditorEnums'
import PubSub from "pubsub-js";

import {PubSubSignals} from '../../LogicV1Redux/constants/PubSubSignals'

//https://www.robinwieruch.de/conditional-rendering-react/ .. won;t work

const getSpecificRenderer = (options) => {
    switch (options.primitive.primitiveType) {

        case PRIMITIVES.CAPSULE:
            return (<DisCapsuleRenderer options={options}/>);
        case PRIMITIVES.CIRCLE:
            return (<DisCircleRenderer options={options}/>);
        case PRIMITIVES.POLYGON:
            return (<DisPolygonRenderer options={options}/>);
        case PRIMITIVES.PIPE:
            return (<DisPipeRenderer options={options}/>);
        default:
            return (
                <dd.Text label='unsupported'
                         value={'primitive'}

                />
            )
    }
};


export default class DisPrimitiveRenderer extends React.Component {

    constructor(props) {
        super(props);
        this.changeMinHandler = this.changeMinHandler.bind(this);
        this.changeMaxHandler = this.changeMaxHandler.bind(this);
        this.changeRadiusHandler = this.changeRadiusHandler.bind(this);

    }

    changeMaxHandler(e) {
        console.log('changing max', e, this.props);
        this.props.primitive.setMax(e);
        PubSub.publish(PubSubSignals.FORCE_REDRAW_DECK_GL, true);
    }

    changeMinHandler(e) {
        console.log('changing min', e, this.props);
        this.props.primitive.setMin(e);
        PubSub.publish(PubSubSignals.FORCE_REDRAW_DECK_GL, true);
    }

    changeRadiusHandler(e) {
        console.log('changing radius', e, this.props);
        this.props.primitive.setRadius(e);
        PubSub.publish(PubSubSignals.FORCE_REDRAW_DECK_GL, true);
    }

    render() {
        const {primitive} = this.props;

        const options = {
            primitive,
            onChangeMax:this.changeMaxHandler,
            onChangeMin:this.changeMinHandler,

            onChangeRadius:this.changeRadiusHandler
        };

        return getSpecificRenderer(options);
    }
}
