//v1 related

//store related

export const STORE_ALL_CHECKINS_V1 = "STORE_ALL_CHECKINS_V1";

//sagas related

export const FETCH_ALL_CHECKINS_V1_RQ = "FETCH_ALL_CHECKINS_V1_RQ";

export const SET_OP_OWNED_CHECKIN_V1 = "SET_OP_OWNED_CHECKIN_V1";
