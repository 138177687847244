import markerIcon from "../testIcos/pm-draw-icons/marker.png"
import drawIcon from "../testIcos/pm-draw-icons/draw.png"
import lineIcon from "../testIcos/pm-draw-icons/line.png"
import circleIcon from "../testIcos/pm-draw-icons/circle.png"
import capsuleIcon from "../testIcos/pm-draw-icons/capsule.png"
import editIcon from "../testIcos/pm-draw-icons/edit.png"
import arrowMinusIcon from "../testIcos/pm-draw-icons/arrow-minus.png"
import arrowPlusIcon from "../testIcos/pm-draw-icons/arrow-plus.png"
import removeIcon from "../testIcos/pm-draw-icons/remove.png"
import cursorIcon from "../testIcos/pm-draw-icons/cursor.png"
import multilineIcon from "../testIcos/pm-draw-icons/multiline.png"
import measureIcon from "../testIcos/pm-draw-icons/measure.png"
import {invertColorToCSSHexString} from "../../dr_ra2/utils/cssColorInverter";

export default theme => {
    console.log("deck theme", theme);
    return ({
    deckGLToolbar: {
        backgroundColor: invertColorToCSSHexString(theme.palette.primary.contrastText, 0.9)
    },
    deckGLDrawCtrlDraw: {
        backgroundColor: "rgba(0,0,0,0.75)",
        borderColor: "rgba(0,0,0,0.9)",
    },
    deckGLBtn: {
        /*border-color:rgba(0,0,0,0.9),*/
        // backgroundColor: "#fff",
        width: "30px",
        height: "30px",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        filter: `invert(${theme.palette.type === 'dark'? 1 : 0})`,
        "&:hover": {
            backgroundColor: "#fff",
            filter: "unset",
            /*color:rgba(255,255,255,0.75),*/
        },
        "&.active": {
            backgroundColor: "#fff900",
            filter: "unset",
        },
    },
    deckGLDrawPoint: {
        backgroundImage: `url(${markerIcon})`,
    },
    deckGLDrawPolygon: {
        backgroundImage: `url(${drawIcon})`,
    },
    deckGLDrawLine: {
        backgroundImage: `url(${lineIcon})`,
    },
    deckGLDrawCircle: {
        backgroundImage: `url(${circleIcon})`,
    },
    deckGLDrawCapsule: {
        backgroundImage: `url(${capsuleIcon})`,
    },
    deckGLDrawEdit: {
        backgroundImage: `url(${editIcon})`,
    },
    deckGLDrawArrowMinus: {
        backgroundImage: `url(${arrowMinusIcon})`,
    },
    deckGLDrawArrowPlus: {
        backgroundImage: `url(${arrowPlusIcon})`,
    },
    deckGLDrawTrash: {
        backgroundImage: `url(${removeIcon})`,
    },
    deckGLDrawArrow: {
        backgroundImage: `url(${cursorIcon})`,
    },
    deckGLDrawPipe: {
        backgroundImage: `url(${multilineIcon})`,
    },
    deckGLDrawMeasure: {
        backgroundImage: `url(${measureIcon})`,
    },


})}