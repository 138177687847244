import React from 'react'
import Card from "@material-ui/core/Card/Card";
import CardContent from "@material-ui/core/CardContent/CardContent";
import CardActions from "@material-ui/core/CardActions/CardActions";
import connect from "react-redux/es/connect/connect";
import {withStyles} from "@material-ui/core";
import {translate} from 'ra-core'
import compose from 'recompose/compose';
import CardHeader from "@material-ui/core/CardHeader/CardHeader";
import Avatar from "@material-ui/core/Avatar/Avatar";
import IconButton from "@material-ui/core/IconButton/IconButton";
import ViewIcon from '@material-ui/icons/ViewList';
import CloseIcon from '@material-ui/icons/Close';
import StoreProvider from "../../MyStoreProvider";
import {toggleViewView} from "../../logic/settings/MySettingsActions";
import Dialog from "@material-ui/core/Dialog/Dialog";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import {
    CONFIGURATION_KEY_DASH_CARDS,
} from "../../logic/settings/ConfigurationConstants";
import {removeConfigurationKey} from "../../logic/settings/ConfigurationActions";
import HistoryIcon from '@material-ui/icons/History';
import {showNotification} from 'react-admin'

const styles = theme => ({
    settingsCard: {
        margin: "0",
        borderRadius: "6px",
        '@media screen and (max-height: 919px)': {
            minHeight: "unset"
        },
        display: "flex",
        flexDirection: "column",
    },
    cardHeader: {
        backgroundColor: theme.palette.primary.main,
        borderRadius: "4px",
    },
    cardContent: {
        overflowY: "hidden",
        display: "flex",
        flexDirection: "column",
    },
    cardParagraph: {
        marginBottom: "1em",
        width: "100%",
    },
    paper: {
        '@media screen and (min-height: 920px)': {
            overflowY: "inherit",
            maxHeight: "unset",
        },
        borderRadius: "6px",
    },
});

class MyViewView extends React.Component {

    constructor(props) {
        super(props);
    }

    _handleClose = () => {
        const store = StoreProvider.getStore();
        store.dispatch(toggleViewView({show: false}));
    };

    _handleDashboardViewReset = () => {
        const store = StoreProvider.getStore();
        store.dispatch(removeConfigurationKey(CONFIGURATION_KEY_DASH_CARDS));
        store.dispatch(showNotification('myroot.configuration.viewDashResetDone'));
        this._handleClose();
    };



    render() {

        const {classes, viewView, translate} = this.props;

        return (
            <Dialog
                open={viewView.visible}
                onClose={this._handleClose}
                maxWidth="md"
                classes={{
                    paper: classes.paper
                }}
            >
                <Card className={classes.settingsCard}>

                    <CardHeader
                        avatar={
                            <Avatar aria-label="View settings" className={classes.avatar}>
                                <ViewIcon/>
                            </Avatar>
                        }
                        action={
                            <IconButton onClick={this._handleClose}>
                                <CloseIcon/>
                            </IconButton>
                        }
                        title={translate("myroot.configuration.viewSettings")}
                        titleTypographyProps={{variant: "body2"}}
                        subheaderTypographyProps={{color: "textPrimary", variant: "button"}}
                        className={classes.cardHeader}
                    />

                    <CardContent className={classes.cardContent}>
                        <div className={classes.cardParagraph}>
                            <FormControlLabel
                                control={
                                    <IconButton onClick={this._handleDashboardViewReset} color={"secondary"}>
                                        <HistoryIcon/>
                                    </IconButton>
                                }
                                label={translate('myroot.configuration.viewDashboardLayoutReset')}
                            />
                        </div>
                    </CardContent>
                    <CardActions>
                    </CardActions>
                </Card>
            </Dialog>
        )
    }
}


const mapStateToProps = state => {
    return {
        viewView: state.mySettings.viewView,
    }
};


export default compose(
    translate,
    connect(
        mapStateToProps
    ),
    withStyles(styles)
)(MyViewView);
