import React from "react";
import {withStyles} from "@material-ui/core";
import classnames from 'classnames';
import {LayoutScrollBottomContext} from "../components/MyLayoutThemeDecorator"

export const drawerWidth = 330;
export const drawerHeight =  "36vh";
const containerHeight = "72vh";

const styles = theme => ({
    boxContainer: {
        display: "flex",
        flexDirection: "column-reverse",
        alignItems: "flex-end",
        maxWidth: drawerWidth,
        maxHeight: containerHeight,
        top: "auto",
        bottom: "0",
        right: "0",
        left: "auto",
        position: "fixed",
        marginBottom: 2*theme.spacing.unit,
        paddingRight: "2px",
        zIndex: "2"
    },
    bottomDirection: {
        flexDirection: "row-reverse",
        maxWidth: containerHeight,
        maxHeight: drawerWidth,
    }
});

export default withStyles(styles)(
    class extends React.Component {

        static defaultProps = {
            children: []
        };

        state = {
            openBox: 0,
        };

        _onBoxOpen = () => {
            this.setState({
                openBox: this.state.openBox + 1
            })
        };
        _onBoxClose = () => {
            this.setState({
                openBox: this.state.openBox - 1
            })
        };

        render() {
            const {classes, children} = this.props;
            const {openBox} = this.state;

            return (
                <LayoutScrollBottomContext.Consumer>
                    {
                        (scrollBottom) =>
                            <div className={classnames(classes.boxContainer,
                                {
                                    [classes.bottomDirection]: scrollBottom && openBox === 0,
                                })
                            }>
                                {
                                    children.map((child, index) => React.cloneElement(child, {
                                        horizontalContainer: scrollBottom && openBox === 0,
                                        onBoxOpen: this._onBoxOpen,
                                        onBoxClose: this._onBoxClose,
                                        key: `BoxContainerChild${index}`,
                                    }))
                                }
                            </div>
                    }
                </LayoutScrollBottomContext.Consumer>
            )
        }
    }
);


