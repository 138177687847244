//temporary file, rules to encode on server..
//use_notam is not used now!

import {THREE_LIGHTS} from './'
export default {
    AAA: {
        color: "#08C9DD",
        color_active: "#05727D",
        type: "AAA",
        visible : true,
        use_master : false,
        use_master_heights : false,

        fallback_active : true,
        status_active : THREE_LIGHTS.YELLOW,
        status_inactive : THREE_LIGHTS.GREEN,
        weight_check: false,
        use_aixm: true,
        use_webcat: false,
        use_notam: true,
        ctr_controlled : false,
    },
    AREA: {
        color: "#08C9DD",
        color_active: "#05727D",
        type: "AREA",
        visible : true,
        use_master : false,
        use_master_heights : false,

        fallback_active : true,
        status_active : THREE_LIGHTS.YELLOW,
        status_inactive : THREE_LIGHTS.GREEN,
        weight_check: false,
        use_aixm: true,
        use_webcat: false,
        use_notam: true,
        ctr_controlled : false,

    },
    ADHOC: {
        color: "#08C9DD",
        color_active: "#05727D",
        type: "AREA",
        visible : true,
        use_master : false,
        use_master_heights : false,

        fallback_active : false,
        status_active : THREE_LIGHTS.YELLOW,
        status_inactive : THREE_LIGHTS.GREEN,
        weight_check: false,
        use_aixm: true,
        use_webcat: true,
        use_notam: true,
        ctr_controlled : false,

    },
    ADIZ: {
        color: "#808069",
        color_active: "#808069",
        type: "ADIZ",
        visible : true,
        use_master : false,
        use_master_heights : false,

        fallback_active : true,
        status_active : THREE_LIGHTS.YELLOW,
        status_inactive : THREE_LIGHTS.YELLOW,
        weight_check: false,
        use_aixm: false,
        use_webcat: false,
        use_notam: false,
        ctr_controlled : false,

    },
    ATZ: {
        color: "#9E6F45",
        color_active: "#332315",
        type: "ATZ" ,
        visible : true,
        use_master : false,
        use_master_heights : false,

        fallback_active : true,
        status_active : THREE_LIGHTS.YELLOW,
        status_inactive : THREE_LIGHTS.GREEN,
        weight_check: 1,
        use_aixm: true,
        use_webcat: true,
        use_notam: true,
        ctr_controlled : false,

    },
    ATZ1KM: {
        color: "#FF0000",
        color_active: "#FF0000",
        type: "ATZ1KM",
        visible : true,
        use_master : true,
        use_master_heights : true,

        fallback_active : true,
        status_active : THREE_LIGHTS.RED,
        status_inactive : THREE_LIGHTS.GREEN,
        weight_check: false,
        use_aixm: false,
        use_webcat: false,
        use_notam: false,
        ctr_controlled : false,

    },
    ATZ6KM: {
        color: "#B25CCC",
        color_active: "#B25CCC",
        type: "ATZ6KM",
        visible : true,
        use_master : true,
        use_master_heights : true,

        fallback_active : true,
        status_active : THREE_LIGHTS.YELLOW,
        status_inactive : THREE_LIGHTS.GREEN,
        weight_check: 2,
        use_aixm: false,
        use_webcat: false,
        use_notam: false,
        ctr_controlled : false,

    },
    CTR: {
        color: "#0F1AD9",
        color_active: "#0F1AD9",
        type: "CTR",
        visible : true,
        use_master : false,
        use_master_heights : false,

        fallback_active : true,
        status_active : THREE_LIGHTS.YELLOW,
        status_inactive : THREE_LIGHTS.GREEN,
        weight_check: 1,
        use_aixm: true,
        use_webcat: false,
        use_notam: true,
        ctr_controlled : true,

    },
    CTR1KM: {
        color: "#FF0000",
        color_active: "#FF0000",
        type: "CTR1KM",
        visible : true,
        use_master : true,
        use_master_heights : true,

        fallback_active : true,
        status_active : THREE_LIGHTS.RED,
        status_inactive : THREE_LIGHTS.GREEN,
        weight_check: false,
        use_aixm: false,
        use_webcat: false,
        use_notam: false,
        ctr_controlled : true,

    },
    CTR6KM: {
        color: "#B25CCC",
        color_active: "#B25CCC",
        type: "CTR6KM",
        visible : true,
        use_master : false,
        use_master_heights : true,

        fallback_active : true,
        status_active : THREE_LIGHTS.YELLOW,
        status_inactive : THREE_LIGHTS.GREEN,
        weight_check: 2,
        use_aixm: false,
        use_webcat: false,
        use_notam: false,
        ctr_controlled : true,

    },
    EA: {
        color: "#98A0C2",
        color_active: "#9259F3",
        type: "EA", //old rol
        visible : true,
        use_master : false,
        use_master_heights : false,

        fallback_active : true,
        status_active : THREE_LIGHTS.YELLOW,
        status_inactive : THREE_LIGHTS.GREEN,
        weight_check: false,
        use_aixm: false,
        use_webcat: true,
        use_notam: false,
        ctr_controlled : false,

    },
    ROL: {
        color: "#98A0C2",
        color_active: "#9259F3",
        type: "ROL",
        visible : true,
        use_master : false,
        use_master_heights : false,

        fallback_active : true,
        status_active : THREE_LIGHTS.YELLOW,
        status_inactive : THREE_LIGHTS.GREEN,
        weight_check: false,
        use_aixm: false,
        use_webcat: true,
        use_notam: false,
        ctr_controlled : false,

    },
    D: {
        color: "#FF837E",
        color_active: "#BF1616",
        type: "D", //old EPD
        visible : true,
        use_master : false,
        use_master_heights : false,

        fallback_active : true,
        status_active : THREE_LIGHTS.RED,
        status_inactive : THREE_LIGHTS.YELLOW,
        weight_check: false,
        use_aixm: false,
        use_webcat: true,
        use_notam: true,
        ctr_controlled : false,

    },
    EPD: {
        color: "#FF837E",
        color_active: "#BF1616",
        type: "EPD",
        visible : true,
        use_master : false,
        use_master_heights : false,

        fallback_active : true,
        status_active : THREE_LIGHTS.RED,
        status_inactive : THREE_LIGHTS.YELLOW,
        weight_check: false,
        use_aixm: false,
        use_webcat: true,
        use_notam: true,
        ctr_controlled : false,

    },
    EPP: {
        color: "#FF002A",
        color_active: "#FF002A",
        type: "EPP",
        visible : true,
        use_master : false,
        use_master_heights : false,

        fallback_active : true,
        status_active : THREE_LIGHTS.RED,
        status_inactive : THREE_LIGHTS.GREEN,
        weight_check: 3,
        use_aixm: false,
        use_webcat: false,
        use_notam: false,
        ctr_controlled : false,

    },
    P: {
        color: "#FF002A",
        color_active: "#FF002A",
        type: "P",
        visible : true,
        use_master : false,
        use_master_heights : false,

        fallback_active : true,
        status_active : THREE_LIGHTS.RED,
        status_inactive : THREE_LIGHTS.GREEN,
        weight_check: 3,
        use_aixm: false,
        use_webcat: false,
        use_notam: false,
        ctr_controlled : false,

    },
    EPR: {
        color: "#FF5100",
        color_active: "#E32A00",
        type: "EPR",
        visible : true,
        use_master : false,
        use_master_heights : false,

        fallback_active : true,
        status_active : THREE_LIGHTS.YELLOW,
        status_inactive : THREE_LIGHTS.GREEN,
        weight_check: false,
        use_aixm: false,
        use_webcat: true,
        use_notam: true,
        ctr_controlled : false,

    },
    R: {
        color: "#FF5100",
        color_active: "#FF002A",
        type: "R", //old EPR
        visible : true,
        use_master : false,
        use_master_heights : false,

        fallback_active : true,
        status_active : THREE_LIGHTS.YELLOW,
        status_inactive : THREE_LIGHTS.GREEN,
        weight_check: false,
        use_aixm: false,
        use_webcat: true,
        use_notam: true,
        ctr_controlled : false,

    },
    FIS: {
        color: "#8B4513",
        color_active: "#8B4513",
        type: "FIS",
        visible : true,
        use_master : false,
        use_master_heights : false,

        fallback_active : true,
        status_active : THREE_LIGHTS.GREEN,
        status_inactive : THREE_LIGHTS.GREEN,
        weight_check: false,
        use_aixm: false,
        use_webcat: false,
        use_notam: false,
        ctr_controlled : false,

    },
    MATZ: {
        color: "#839E7D",
        color_active: "#0F4F00",
        type: "MATZ", //old mctr
        visible : true,
        use_master : false,
        use_master_heights : false,

        fallback_active : true,
        status_active : THREE_LIGHTS.YELLOW,
        status_inactive : THREE_LIGHTS.GREEN,
        weight_check: false,
        use_aixm: true,
        use_webcat: false,
        use_notam: true,
        ctr_controlled : false,

    },
    MCTR: {
        color: "#839E7D",
        color_active: "#0F4F00",
        type: "MCTR",
        visible : true,
        use_master : false,
        use_master_heights : false,

        fallback_active : true,
        status_active : THREE_LIGHTS.YELLOW,
        status_inactive : THREE_LIGHTS.GREEN,
        weight_check: false,
        use_aixm: true,
        use_webcat: false,
        use_notam: true,
        ctr_controlled : false,

    },
    MATZ2KM: {
        color: "#FF0000",
        color_active: "#FF0000",
        type: "MATZ2KM", //old MCTR2KM
        visible : true,
        use_master : true,
        use_master_heights : true,

        fallback_active : true,
        status_active : THREE_LIGHTS.RED,
        status_inactive : THREE_LIGHTS.RED,
        weight_check: false,
        use_aixm: false,
        use_webcat: false,
        use_notam: false,
        ctr_controlled : false,

    },
    MCTR2KM: {
        color: "#FF0000",
        color_active: "#FF0000",
        type: "MCTR2KM",
        visible : true,
        use_master : true,
        use_master_heights : true,

        fallback_active : true,
        status_active : THREE_LIGHTS.RED,
        status_inactive : THREE_LIGHTS.RED,
        weight_check: false,
        use_aixm: false,
        use_webcat: false,
        use_notam: false,
        ctr_controlled : false,

    },
    MRT: {
        color: "#839E7D",
        color_active: "#0F4F00",
        type: "MRT",
        visible : true,
        use_master : false,
        use_master_heights : false,

        fallback_active : true,
        status_active : THREE_LIGHTS.RED,
        status_inactive : THREE_LIGHTS.GREEN,
        weight_check: false,
        use_aixm: false,
        use_webcat: true,
        use_notam: false,
        ctr_controlled : false,

    },

    MTMA: {
        color:  "#3B88FF",
        color_active: "#3B88FF",
        type: "TMA",
        visible : true,
        use_master : false,
        use_master_heights : false,

        fallback_active : true,
        status_active : THREE_LIGHTS.YELLOW,
        status_inactive : THREE_LIGHTS.GREEN,
        weight_check: false,
        use_aixm: true,
        use_webcat: false,
        use_notam: true,
        ctr_controlled : false,

    },

    NW: {
        color:  "#3B88FF",
        color_active: "#3B88FF",
        type: "NW",
        visible : true,
        use_master : false,
        use_master_heights : false,

        fallback_active : true,
        status_active : THREE_LIGHTS.YELLOW,
        status_inactive : THREE_LIGHTS.GREEN,
        weight_check: false,
        use_aixm: false,
        use_webcat: false,
        use_notam: true,
        ctr_controlled : false,

    },

    RPA: {
        color: "#0F1AA2",
        color_active: "#0F1AA2",
        type: "RPA",
        visible : true,
        use_master : true,
        use_master_heights : false,

        fallback_active : true,
        status_active : THREE_LIGHTS.YELLOW,
        status_inactive : THREE_LIGHTS.GREEN,
        weight_check: 4,
        use_aixm: false,
        use_webcat: false,
        use_notam: false,
        ctr_controlled : true,

    },

    RMZ: {
        color: "#C9ABFC",
        color_active: "#C9ABFC",
        type: "RMZ",
        visible : true,
        use_master : false,
        use_master_heights : false,

        fallback_active : true,
        status_active : THREE_LIGHTS.YELLOW,
        status_inactive : THREE_LIGHTS.GREEN,
        weight_check: 1,
        use_aixm: true,
        use_webcat: false,
        use_notam: false,
        ctr_controlled : false,

    },

    TFR: {
        color: "#0000FF",
        color_active: "#0000FF",
        type: "TFR",
        visible : true,
        use_master : false,
        use_master_heights : false,

        fallback_active : false,
        status_active : THREE_LIGHTS.YELLOW,
        status_inactive : THREE_LIGHTS.GREEN,
        weight_check: false,
        use_aixm: false,
        use_webcat: true,
        use_notam: false,
        ctr_controlled : false,

    },

    TMA: {
        color:  "#3B88FF",
        color_active: "#3B88FF",
        type: "TMA",
        visible : true,
        use_master : false,
        use_master_heights : false,

        fallback_active : true,
        status_active : THREE_LIGHTS.RED,
        status_inactive : THREE_LIGHTS.GREEN,
        weight_check: false,
        use_aixm: true,
        use_webcat: false,
        use_notam: true,
        ctr_controlled : false,

    },

    TRA: {
        color: "#BD94A7",
        color_active: "#C744A5",
        type: "TRA",
        visible : true,
        use_master : false,
        use_master_heights : false,

        fallback_active : true,
        status_active : THREE_LIGHTS.YELLOW,
        status_inactive : THREE_LIGHTS.GREEN,
        weight_check: false,
        use_aixm: false,
        use_webcat: true,
        use_notam: false,
        ctr_controlled : false,

    },
    TR: {
        color: "#BD94A7",
        color_active: "#C744A5",
        type: "TRA",
        visible : true,
        use_master : false,
        use_master_heights : false,

        fallback_active : true,
        status_active : THREE_LIGHTS.YELLOW,
        status_inactive : THREE_LIGHTS.GREEN,
        weight_check: false,
        use_aixm: false,
        use_webcat: true,
        use_notam: false,
        ctr_controlled : false,

    },
    TSA: {
        color: "#7A5C6D",
        color_active: "#FF002A",
        type: "TSA",
        visible : true,
        use_master : false,
        use_master_heights : false,

        fallback_active : true,
        status_active : THREE_LIGHTS.RED,
        status_inactive : THREE_LIGHTS.GREEN,
        weight_check: false,
        use_aixm: false,
        use_webcat: true,
        use_notam: false,
        ctr_controlled : false,

    },
    TS: {
        color: "#7A5C6D",
        color_active: "#FF002A",
        type: "TSA",
        visible : true,
        use_master : false,
        use_master_heights : false,

        fallback_active : true,
        status_active : THREE_LIGHTS.RED,
        status_inactive : THREE_LIGHTS.GREEN,
        weight_check: false,
        use_aixm: false,
        use_webcat: true,
        use_notam: false,
        ctr_controlled : false,

    },
    SECTOR: {
        color: "#0000FF",
        color_active: "#0000FF",
        type: "SECTOR",
        visible : true,
        use_master : false,
        use_master_heights : false,

        fallback_active : false,
        status_active : THREE_LIGHTS.YELLOW,
        status_inactive : THREE_LIGHTS.GREEN,
        weight_check: false,
        use_aixm: false,
        use_webcat: false,
        use_notam: false,
        ctr_controlled : false,

    },
    DRAI: {
        color: "#0F1AA2",
        color_active: "#0F1AA2",
        type: "DRAI",
        visible : true,
        use_master : false,
        use_master_heights : false,

        fallback_active : true,
        status_active : THREE_LIGHTS.GREEN,
        status_inactive : THREE_LIGHTS.GREEN,
        weight_check: false,
        use_aixm: false,
        use_webcat: false,
        use_notam: false,
        ctr_controlled : false,

    },
    DRAR: {
        color: "#C47718",
        color_active: "#C47718",
        type: "DRAR",
        visible : true,
        use_master : false,
        use_master_heights : false,

        fallback_active : true,
        status_active : THREE_LIGHTS.YELLOW,
        status_inactive : THREE_LIGHTS.GREEN,
        weight_check: false,
        use_aixm: false,
        use_webcat: false,
        use_notam: false,
        ctr_controlled : false,

    },
    DRARM: {
        color: "#C47718",
        color_active: "#C47718",
        type: "DRARM",
        visible : true,
        use_master : false,
        use_master_heights : false,

        fallback_active : true,
        status_active : THREE_LIGHTS.YELLOW,
        status_inactive : THREE_LIGHTS.GREEN,
        weight_check: false,
        use_aixm: false,
        use_webcat: false,
        use_notam: false,
        ctr_controlled : false,

    },
    DRARL: {
        color: "#C47718",
        color_active: "#C47718",
        type: "DRARL",
        visible : true,
        use_master : false,
        use_master_heights : false,

        fallback_active : true,
        status_active : THREE_LIGHTS.YELLOW,
        status_inactive : THREE_LIGHTS.GREEN,
        weight_check: false,
        use_aixm: false,
        use_webcat: false,
        use_notam: false,
        ctr_controlled : false,

    },
    DRARH: {
        color: "#C47718",
        color_active: "#C47718",
        type: "DRARH",
        visible : true,
        use_master : false,
        use_master_heights : false,

        fallback_active : true,
        status_active : THREE_LIGHTS.YELLOW,
        status_inactive : THREE_LIGHTS.GREEN,
        weight_check: false,
        use_aixm: false,
        use_webcat: false,
        use_notam: false,
        ctr_controlled : false,

    },
    DRAU: {
        color: "#0F1AA2",
        color_active: "#0F1AA2",
        type: "DRAU",
        visible : true,
        use_master : false,
        use_master_heights : false,

        fallback_active : true,
        status_active : THREE_LIGHTS.YELLOW,
        status_inactive : THREE_LIGHTS.GREEN,
        weight_check: false,
        use_aixm: false,
        use_webcat: false,
        use_notam: false,
        ctr_controlled : false,

    },
    DRAT: {
        color: "#60615D",
        color_active: "#60615D",
        type: "DRAT",
        visible : true,
        use_master : false,
        use_master_heights : false,

        fallback_active : true,
        status_active : THREE_LIGHTS.YELLOW,
        status_inactive : THREE_LIGHTS.GREEN,
        weight_check: false,
        use_aixm: false,
        use_webcat: false,
        use_notam: false,
        ctr_controlled : false,

    },
    DRAP: {
        color: "#A31D4A",
        color_active: "#A31D4A",
        type: "DRAP",
        visible : true,
        use_master : false,
        use_master_heights : false,

        fallback_active : true,
        status_active : THREE_LIGHTS.RED,
        status_inactive : THREE_LIGHTS.GREEN,
        weight_check: false,
        use_aixm: false,
        use_webcat: false,
        use_notam: false,
        ctr_controlled : false,

    },
    HOLDING_V0: {
        color: "#0000FF",
        color_active: "#0000FF",
        type: "_TEST_",
        visible : true,
        use_master : false,
        use_master_heights : false,

        fallback_active : true,
        status_active : THREE_LIGHTS.YELLOW,
        status_inactive : THREE_LIGHTS.GREEN,
        weight_check: false,
        use_aixm: false,
        use_webcat: false,
        use_notam: false,
        ctr_controlled : false,

    },
    _TEST_: {
        color: "#0000FF",
        color_active: "#0000FF",
        type: "_TEST_",
        visible : true,
        use_master : false,
        use_master_heights : false,

        fallback_active : true,
        status_active : THREE_LIGHTS.YELLOW,
        status_inactive : THREE_LIGHTS.GREEN,
        weight_check: false,
        use_aixm: false,
        use_webcat: false,
        use_notam: false,
        ctr_controlled : false,

    },

    _UNKNOWN_: {
        color: "#0000FF",
        color_active: "#0000FF",
        type: "_UNKNOWN_",
        visible : false,
        use_master : false,
        use_master_heights : false,

        fallback_active : false,
        status_active : THREE_LIGHTS.YELLOW,
        status_inactive : THREE_LIGHTS.GREEN,
        weight_check: false,
        use_aixm: false,
        use_webcat: false,
        use_notam: false,
        ctr_controlled : false,

    },

};


