import {INFO_SEEN_ADD, INFO_SEEN_REMOVE, INFO_SEEN_REMOVE_ALL} from "./InfoSeenConstants";


export const addToSeenInfos = ({hash}) => {
    return ({
        type: INFO_SEEN_ADD,
        hash,
    })
};

export const removeFromSeenInfos = ({hash}) => {
    return ({
        type: INFO_SEEN_REMOVE,
        hash,
    })
};

export const removeAllFromSeenInfos = () => {
    return ({
        type: INFO_SEEN_REMOVE_ALL,
    })
};