import React from 'react';
import {
    ArrayField,
    ArrayInput,
    Create,
    Datagrid,
    DisabledInput,
    Edit,
    EmailInput,
    Filter,
    FormDataConsumer, FunctionField,
    Labeled,
    Link,
    LongTextInput,
    maxLength,
    minLength,
    minValue,
    number,
    RadioButtonGroupInput,
    required,
    Responsive,
    SelectInput,
    ShowController,
    showNotification,
    ShowView,
    SimpleForm,
    SimpleFormIterator,
    SimpleShowLayout,
    Sing,
    TextField,
    TextInput,
    translate,
} from 'react-admin';
import {
    adjustTimeFormatterToScreen,
    dateTimeFormatter,
    dateTimeWeekDayFormatter,
    round2NextInterval
} from "../../utils/momentHelpers";

import MyIlikeSearch from './MyIlikeSearch';

import MyTextField from '../MyTextField';

import {isGov, isOperator, RESOURCES} from "../../logic/MyRestConfig";
import BasicEditActions from "../MyBasicEditActions"
import BasicListActions from "../MyBasicListActions"

import MyMissionField from "./MissionCustomComponents/MyMissionField";
import Icon from '@material-ui/core/Icon';
import BasicShowActions from "../MyBasicShowActions";

import Typography from '@material-ui/core/Typography';
import TranslatableTypography from '../TranslatableTypography'
import MyFileInput from "../MyFileInput";
import MyFileField from "../MyFileField";
import MyFilesField from "../MyFilesField";
import MyTransitionDecorator from "../MyTransitionDecorator";
import MyHorizontalStepper from "../MyHorizontalStepper";
import MyStep from "../MyStep";
import {connect} from "react-redux";
import {
    change,
    Field,
    formValueSelector,
    getFormMeta,
    getFormSyncErrors,
    getFormValues,
    isPristine,
    isValid,
    reset
} from 'redux-form';
import {compose, shallowEqual, withProps} from 'recompose';
import {withStyles} from '@material-ui/core/styles';
import StoreProvider from "../../MyStoreProvider";
import {bindActionCreators} from "redux";
import {ReferenceFieldController, toggleSidebar as toggleSidebarAction} from 'ra-core';

import moment from "moment";
import {DateTimePickerLocale} from "../TimePickersLocaleProxy";

import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

import {FormControl} from "@material-ui/core";
import {MyRecordTitle} from "../MyTitle";

import {MissionType, MissionV2Statuses} from '../../../LogicV1Redux/constants/MissionsV2Enums'
import {ZoneLegsV2Statuses} from '../../../LogicV1Redux/constants/ZoneLegsV2Enums'
import {TooltipProxy as Tooltip} from '../TooltipProxy';
import {has, get}from "lodash";

import {ReferenceFieldDecorator as ReferenceField} from "../ReferenceFieldDecorator";
import MyPagination from "../MyPagination";
import {ListDecorator as List} from "../ListDecorator";
import withWidth, {isWidthDown} from '@material-ui/core/withWidth';
import StatusField from "../StatusField";

import MissionAtcUserToolbar from './MissionCustomComponents/MissionAtcUserToolbar';
import MissionCreateSaveToolbar from './MissionCustomComponents/MissionCreateSaveToolbar';
import MissionEditSaveToolbar from './MissionCustomComponents/MissionEditSaveToolbar'
import AtcMsgBlock from './MissionCustomComponents/AtcMsgBlock';
import MyOperatorsSearch from "./MyOperatorsSearch";
import {getStatusIconColored} from "../../utils/Dashboard/statusIcon";
import {translateCaaStatusToTypo} from "../../utils/applicationsHelpers";
import DroneArrayInput from "./MissionCustomComponents/DroneArrayInput";
import {shouldDisableAdd} from "../../utils/reactHelpers";
import {InfoText} from "../InfoText";
import {WarningText} from "../WarningText";
import {showInfoMessages} from "../../logic/box/InfoboxActions";
import {
    INFO_MSG_MISSION_CREATE_BVLOS, INFO_MSG_MISSION_CREATE_BVLOS_SUAVO,
    INFO_MSG_MISSION_CREATE_VLOS, INFO_MSG_MISSION_CREATE_VLOS_SUAVO,
    INFO_MSG_MISSION_EDITOR,
    INFO_MSG_MISSION_TARGET_BVLOS,
    INFO_MSG_MISSION_TARGET_VLOS
} from "../../logic/box/InfoboxConstants";
import NumberInput from "ra-ui-materialui/esm/input/NumberInput";
import {BooleanField, BooleanInput, SelectField} from "ra-ui-materialui";
import {ReferenceFieldView} from "ra-ui-materialui/esm/field/ReferenceField";

import {
    BVLOS_MAX_ALT,
    BVLOS_OFFICIAL_CONSIDER_TIME, VLOS_MAX_ALT,
    VLOS_OFFICIAL_CONSIDER_TIME
} from "../../../LogicV1Redux/constants/MissionsV2Constants";
import {MissionSummaryCards} from "./MissionCustomComponents/MissionSummaryCards";
import {MissionActionButtons, PureMissionActionButtons} from "./MissionCustomComponents/MissionActionButtons";
import {MissionDronesSummaryCards} from "./MissionCustomComponents/MissionDronesSummaryCards";
import {ConditionalDroneRegistryField} from "./MissionCustomComponents/ConditionalDroneRegistryField";
import {toggleProfileView} from "../../logic/settings/MySettingsActions";
import Delay from "react-delay";
import PrivilegesField from "./OperatorCustomComponents/PrivilegesField";
import Configs, {FEATURES_V1_2_ENUMS} from "../../../Configs";
import {translateMissionStatusToTypo} from "../../utils/missionHelpers";
import {OperatorPrivileges} from "../../utils/operatorHelpers";
import classnames from 'classnames'

/*
Mission  20.04.18
{
    uid uuid NOT NULL DEFAULT gen_random_uuid(),
    created timestamp with time zone DEFAULT now(),
    name text COLLATE pg_catalog."default" NOT NULL,
    start timestamp with time zone,
    stop timestamp with time zone,
    status text COLLATE pg_catalog."default",
    etc jsonb,
    operatorid uuid NOT NULL,
    CONSTRAINT mission_pkey PRIMARY KEY (uid),
    CONSTRAINT operator_fk FOREIGN KEY (operatorid)
        REFERENCES public.operator (uid) MATCH SIMPLE
        ON UPDATE NO ACTION
        ON DELETE NO ACTION
}

special case -> for correct UX I need to combine legs AND zones..
since loopback can read correctly created document (with customFilter set in urls config) in one pass,
.. lets support it..
*/

const INFOBOX_NEW_MISSION_INFOS_ENABLED = Configs.enabledFeatures.includes(FEATURES_V1_2_ENUMS.INFOBOX_NEW_MISSION_INFOS);
const MISSION_STATUS_TRANSLATIONS_ENABLED = Configs.enabledFeatures.includes(FEATURES_V1_2_ENUMS.MISSION_STATUS_TRANSLATIONS);
const MISSION_112_ENABLED = Configs.enabledFeatures.includes(FEATURES_V1_2_ENUMS.MISSION_112);
const ACM_INFOBOXES_ENABLED = Configs.enabledFeatures.includes(FEATURES_V1_2_ENUMS.ACM_INFOBOXES);

//TODO withStyles refactoring
const styles = theme => ({
    createForm: {
        margin: "0 10% 0",
        [theme.breakpoints.down('sm')]: {
            margin: "0"
        }
    },
    createPaper: {
        marginTop: "1rem"
    },
    slider: {
        marginTop: "1rem"
    },
    info: {
        display: "flex",
        alignItems: "center",
    },
});


const activityTypes = {
    [MissionType.VLOS]: [
        {id: 'SPORT_RECREATION', name: 'resources.missions.fields.etc_puropse_sport'},
        {id: 'OTHER', name: 'resources.missions.fields.etc_purpose_other'},
    ],
    [MissionType.BVLOS]: [
        {id: 'TRAINING', name: 'resources.missions.fields.etc_puropse_training'},
        {id: 'SPEC', name: 'resources.missions.fields.etc_purpose_spec'},
        {id: 'AUTO', name: 'resources.missions.fields.etc_puropse_auto'},
        {id: 'OPERATIONAL', name: 'resources.missions.fields.etc_purpose_operational'},
    ],
};

//not used due to https://gidrone.droneradar.ovh/playground/ra2-playground/issues/60
//was extended fix for https://gidrone.droneradar.ovh/playground/ra2-playground/issues/50
function calculatedRecommededStart()  {
    const now = new Date();
    //8 am,
    const morningDayAfter = moment(now).clone().hours(8).minutes(0).seconds(0).add(2,'d');

    return morningDayAfter;
}

//funnily enough only DAYS are supported in picker component...
function calculatedMinDate()  {
    const now = new Date();

    const extra10min = moment(now).clone().add(10, 'm');

    const rounded = round2NextInterval(extra10min, 10).clone();
    console.log('======= rounding', now,  extra10min.format(), rounded.format());

    return rounded.add(1, 'd');
}
//also revert
const minDate = new Date();

const editFormName = 'record-form'; //have to be default
const createFormName = 'record-form'; //have to be default

//almost duplicate to merge/unify in zonelegs.jsx!
//btw reaaly dirty, but sleepy now

//aargh, to have correct count we need to add/merge all zone data (ie deserialize mission data)
//to pass through IsInvalidIntersect... damn. not now, should be backend anyway...

export function missionLegsStatusesCounter (legsArr) {
    let others = 0, accepts = 0, rejects = 0;

    let mergedZoneLegs =[], names = {};

    //clean up dups or not?
    //nope -> zone leg is already unique!, so these are different conflicts
    legsArr.forEach((legRecord) => {
        legRecord.zoneLeg.forEach((zoneLegRecord) => {
            switch (zoneLegRecord.status) {
                case ZoneLegsV2Statuses.ACCEPTED:
                    accepts++;
                    break;
                case  ZoneLegsV2Statuses.REJECTED:
                    rejects++;
                    break;
                default:
                    others++;
            }
        });
    });

    console.log('missionLegsStatusesCounter', accepts, rejects, others);

    return {accepts, rejects, others};
}
const iconStyle = {fontSize:"16px", paddingRight:"6px", paddingLeft:"6px", lineHeight: "130%"};

//almost duplicate to merge/unify in zonelegs.jsx!

export const missionZoneLegsCntFormatter = (arr) => {
    if (!arr && !Array.isArray(arr)) {
        console.warn ('wrong zoneLegsCntFormatter param', arr);
        return null;
    }
    console.log('missionZoneLegsCntFormatter', arr);

    const {accepts, rejects, others} = missionLegsStatusesCounter(arr);
    {/*<Button*/}
    {/*//color="primary"*/}
    {/*component={Link}*/}
    {/*to={to}*/}
    {/*//className={classes.link}*/}
    {/*>*/}
    return (
        <div style={{whiteSpace: "nowrap"}} >
            <span style={{color:"orange"}}><Icon style={iconStyle}>warning</Icon>{others}</span>
            <span style={{color:"green"}}><Icon style={iconStyle}>thumb_up</Icon>{accepts}</span>
            <span style={{color:"red"}}><Icon style={iconStyle}>thumb_down</Icon>{rejects}</span>
        </div>
    );
};

//true component
//*commenting out temporarily, as conflicts counter returns wrong results due to backend issue (z-filtering)*/}

export const MissionZoneLegsCntFormatter = ({record}) => {
    //console.log('MissionZoneLegsCntFormatter', record.legs);
    return null;
    //return missionZoneLegsCntFormatter(record.legs || []);
};


//*commenting out temporarily, as conflicts counter returns wrong results due to backend issue (z-filtering)*/}

const MissionConflicts2ShowLink = ({
                                      basePath = '',
                                      label = 'ra.action.edit',
                                      record = {},
                                      ...rest
                                  }) => {
    return null;
    //let link = `/zone-conflict-resolver/${encodeURIComponent(record.zone.uid)}`;
    console.log('MissionConflicts2ShowLink', JSON.stringify(record));
    //const to = {pathname: '/'};
    let link = `/missions/${encodeURIComponent(record.id)}/show`;

    //const link =  '/';
    return (
        <Link to={{pathname: link}} >
            <MissionZoneLegsCntFormatter record={record}/>
        </Link>
    )
};


const MissionDronesField = ({record}) => {
    console.log(record);
    //fixme: should be table row, which is bit hardcore with custom fields and reference mix
    return (

        <div>
            {record.make} :{record.class}, mtom:{record.mtom}g
        </div>
    );

};

MissionDronesField.defaultProps = { addLabel: true };

export const MissionsList = (props) => (
    <MyTransitionDecorator timeout={700} classNames="menucard" unmountOnExit>
        <PureMissionsList {...props}/>
    </MyTransitionDecorator>
);


export const ApprovalNo = (props) => {
    const {record, showLabel = false, classes = {}, width} = props; //fixme translatable label
    //console.log('===========approval no here', record.start);

    //console.log('===========approval no here', record);
    //TODO FSM needed!!!
    if (record.status === MissionV2Statuses.ACCEPTED ||record.status === 'PREPARED')
        return (
            <div className={classes.hasOwnProperty('root') ? classes.root : undefined}>
                <Typography variant={isWidthDown('md', width) ? 'caption' : undefined}>
                    {showLabel ?
                        <TranslatableTypography variant="caption"
                                                content={'resources.missions.fields.approval'}
                        />
                        : null
                    }
                </Typography>
                <Typography variant="body2">
                    {record.approval}
                </Typography>
            </div>);
    else return null;
};

const row112style = MISSION_112_ENABLED ?
    ({
        background: `repeating-linear-gradient(
                    -45deg,
                    #0000,
                    #0000 10px,
                    #F002 10px,
                    #F002 20px)`,
        textShadow: '-0.5px -0.5px 0 #FFF, 0.5px -0.5px 0 #FFF, -0.5px 0.5px 0 #FFF, 0.5px 0.5px 0 #FFF',
        '&:before': {
            content: "'112'",
            color: "#FFF",
            backgroundColor: "#F00",
            padding: "0 2px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "clip",
            textShadow: "none",
            float: "right",
            fontWeight: "800"
        },
        backgroundColor: "#FFF"
    })
    :
    ({});
const rowKSIDstyle = ({
        background: `repeating-linear-gradient(
              -45deg,
              rgba(0, 0, 0, 0),
              rgba(0, 0, 0, 0) 10px,
              rgba(0, 0, 255, 0.1) 10px,
              rgba(0, 0, 255, 0.1) 20px
              )
              `,
        textShadow:
            "-0.5px -0.5px 0 #FFF, 0.5px -0.5px 0 #FFF, -0.5px 0.5px 0 #FFF, 0.5px 0.5px 0 #FFF",
        "&:before": {
            content: "'KSID'",
            color: "#FFF",
            backgroundColor: "#0000ff",
            padding: "0 2px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "clip",
            textShadow: "none",
            float: "right",
            fontWeight: "800"
        },
        backgroundColor: "#FFF"
    })
const rowKSID112style = MISSION_112_ENABLED ?
    ({
        background: `repeating-linear-gradient(
              -45deg,
              rgba(0, 0, 0, 0),
              rgba(0, 0, 0, 0) 10px,
              rgba(212, 0, 255, 0.1) 10px,
              rgba(212, 0, 255, 0.1) 20px
              )
              `,
        textShadow:
            "-0.5px -0.5px 0 #FFF, 0.5px -0.5px 0 #FFF, -0.5px 0.5px 0 #FFF, 0.5px 0.5px 0 #FFF",
        "&:before": {
            content: "'KSID112'",
            color: "#FFF",
            backgroundColor: "rgb(212, 0, 255)",
            padding: "0 2px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "clip",
            textShadow: "none",
            float: "right",
            fontWeight: "800"
        },
        backgroundColor: "#FFF"
    })    :
    ({});
const datagridStyles = theme => ({
    rowCell: {
        [theme.breakpoints.up('md')]: {
            '&:last-child': {
                padding: `${theme.spacing.unit/2}px ${theme.spacing.unit}px`,
            },

        },
        [theme.breakpoints.up('lg')]: {
            '&:last-child': {
                padding: '0',
            }
        },

        [theme.breakpoints.up('xl')]: {
            '&:last-child': {
                padding: `0 12px`
            }
        },
    },
    row112: row112style,
    rowKSID: rowKSIDstyle,
    rowKSID112: rowKSID112style,
    ctrTuple: {
        '& a': {
            '&:not(:first-child)': {
                display: 'none'
            }
        }
    }
});


const missionStatusChoices = MISSION_STATUS_TRANSLATIONS_ENABLED ?
    [
        {id: 'accepted', name: 'resources.missions.status.accepted'},
        {id: 'rejected', name: 'resources.missions.status.rejected'},
        {id: 'planned', name: 'resources.missions.status.pending'}
    ]
    :
    [
        {id: 'accepted', name: 'accepted'},
        {id: 'rejected', name: 'rejected'},
        {id: 'planned', name: 'planned'},
    ]
;

const missionStatusChoicesOperator = [
    ...missionStatusChoices,
    MISSION_STATUS_TRANSLATIONS_ENABLED ?
        {id: 'created', name: 'resources.missions.status.created'}
        :
        {id: 'created', name: 'created'},
];

//to finish
//definitevely first we need approvalno fixed
//probably all simple searches should be refactored to autocomplete...
//but we should also take into account efficiency of autocomplete
// (maybe force at least 2 first letters)

//custom filters to add. null status issue to solve
const MissionsFilter = ({onHideFilter, hideFilter, linkStateFilter, filterValues, isOp, ...props}) => {
    if (!props.permissions) return null;

    //used for dual call to ListController and custom onHideFilter in List
    const hideFilterProxyFunc = (key) => {
        onHideFilter(key);
        hideFilter(key);
    };

    //proxy for custom linkStateFilter
    const filterValuesProxy = {
        ...filterValues,
        ...linkStateFilter
    };

    return (
        <Filter hideFilter={hideFilterProxyFunc} filterValues={filterValuesProxy} {...props} >
            {/*<MyUidSearch source="uid" />*/}
            <MyIlikeSearch source="uid"
                          label='resources.missions.fields.uid'
                          inlineStyle={{minWidth: "305px"}}
            />
            <MyIlikeSearch source="name"
                           label='resources.missions.fields.name'
            />
            <SelectInput source="status"
                         label='resources.missions.fields.status'
                         choices={isOp ? missionStatusChoicesOperator : missionStatusChoices}
                         optionText={
                             choice => props.translate(choice.name, {smart_count: 2})
                         }
                         translateChoice={false}
                         allowEmpty={false}
            />
            <MyIlikeSearch source="approval"
                           label='resources.missions.fields.approval'/>
            { (isOperator(props.permissions.roles))
                ?null
                :
                <MyOperatorsSearch source="operatorid"
                                   label={'resources.drones.fields.operator'}
                />
            }
        </Filter>
    );
};

//TODO LGZ maybe some better solution?
// RE -> we should switch to custom mission view, (special sql for exactly this)
// but let's do it together with extended filters support,
// so on hold now..
export const _getFirstCTR = (props, classes) => () => (

    <FormDataConsumer label='myroot.dashboard.ctrZonesHeader'>

        {({formData, ...rest}) => {

            return (
                <div className={classes.ctrTuple}>
                    {
                        formData.legs.map((leg, i) =>
                            leg.zoneLeg.map((zoneLeg, j) =>
                                <ReferenceFieldController  {...props}
                                                           record={zoneLeg}
                                                           source="zoneId"
                                                           reference="zones"
                                                           linkType='show'
                                                           basePath='/zones'
                                                           title={'test'}
                                                           key={i+j}
                                >
                                    {controllerProps => {
                                        if (has(controllerProps, 'referenceRecord.name')) {
                                            if (controllerProps.referenceRecord.type === 'CTR') {
                                                return (

                                                    <ReferenceFieldView {...props} {...controllerProps} >
                                                        <MyTextField source='name'/>
                                                    </ReferenceFieldView>
                                                )
                                            }
                                        }
                                        return null;
                                    }}
                                </ReferenceFieldController>
                            )
                        )
                    }
                </div>
            )
        }}
    </FormDataConsumer>
);

const defaultSort = {field: 'created', order: 'DESC'};

const PureMissionsList = compose(withStyles(datagridStyles), withWidth())(
    ({permissions, classes, width, ...props}) => {

        const onHideFilter = (key) => {
            if (key === 'status') {
                props.history.replace({
                    pathname: '/missions',
                    state: {
                        filter: {}
                    }
                });
            }
        };

        const location = props.location;
        const linkStateFilter =
            has(location, 'state.filter.status') &&
            location.state.filter.status !== 'unknown' ? {status: location.state.filter.status} : undefined;

        if (!permissions) return null;
        const isOp = isOperator(permissions.roles);
        return (
            <List {...props}
                  filters={
                      <MissionsFilter
                          onHideFilter={onHideFilter}
                          permissions={permissions}
                          linkStateFilter={linkStateFilter}
                          {...{isOp}}
                      />
                  }
                  filterDefaultValues={linkStateFilter}
                  title={'resources.missions.menu'}
                  sort={defaultSort}
                  bulkActions={false}
                  actions={<BasicListActions/>}
                  pagination={<MyPagination/>}
            >
                <Responsive
                    small={
                        <MissionSummaryCards resource={props.resource}
                                             getCtr={_getFirstCTR(props, classes)}
                                             row112Class={classes.row112}
                                             {...{isOp, permissions, width}}/>
                    }
                    medium={
                        <Datagrid classes={{rowCell: classes.rowCell}}>

                            <MyTextField source="modified"
                                         format={adjustTimeFormatterToScreen({date: true}, width)}
                                         tooltip={isWidthDown('md', width)}
                                         tooltipFormatter={dateTimeWeekDayFormatter}
                            />
                            {/*TODO Causes delays -> getFirstCTR accumulates multiple zones, to be fixed
                        additionaly -> right now shows only FIRST ctr, connect to custom view
                    */}
                            {has(permissions, 'roles') && isGov(permissions.roles) && _getFirstCTR(props, classes)()}

                            {isOp
                                ? null //<TextField source="name"/>. both for op and gov now

                                :
                                <ReferenceField source="operatorid"
                                                reference="operators"
                                                linkType="show"
                                                addLabel={true}
                                                label={'resources.drones.fields.operator'}
                                >
                                    {/*
                             was: fixme to full name renderer

                             TODO connect to custom mission view, should be faster and
                                we have there full name done
                            */}

                                    <TextField source="lastName"/>
                                </ReferenceField>
                            }

                       
                            <FunctionField
                                label={`resources.${
                                    RESOURCES.MISSIONS
                                }.fields.name`}
                                source="name"
                                render={record => {


                                    const observers = record.etc.observers ? record.etc.observers : []
                                    const hasKSIDObserver = observers.some((o) => o.name.startsWith("POL") && o.name.length === 16)


                                    const etc = record.etc || {};
                                    const is112 = MISSION_112_ENABLED && etc.is112
                                    const rowClassname = is112 && hasKSIDObserver ? classes.rowKSID112 :
                                                            record.etc.is112 ? classes.row112 :
                                                            hasKSIDObserver ? classes.rowKSID : 
                                                            classes.listItem;

                                    return (
                                        <TextField
                                            source="name"
                                            className={rowClassname}
                                            record={record} 
                                        />
                                    );
                                }}
                            />
                        


                            <MyTextField source="start"
                                         format={adjustTimeFormatterToScreen({time: true}, width)}
                                         tooltip={isWidthDown('md', width)}
                                         tooltipFormatter={dateTimeWeekDayFormatter}
                            />
                            <MyTextField source="stop"
                                         format={adjustTimeFormatterToScreen({time: true}, width)}
                                         tooltip={isWidthDown('md', width)}
                                         tooltipFormatter={dateTimeWeekDayFormatter}
                            />

                            <StatusField source="status"
                                         tooltip={isWidthDown('md', width)}
                                         statusMappingFn={MISSION_STATUS_TRANSLATIONS_ENABLED ? translateMissionStatusToTypo : undefined}
                                         iconGetter={getStatusIconColored}
                                         addLabel={false}
                            />
                            <TextField source="approval"/>


                            {/*<MyTextField source="legs"*/}
                            {/*format={missionZoneLegsCntFormatter}*/}
                            {/*sortable={false}*/}
                            {/*/>*/}
                            <MissionActionButtons resource={props.resource}
                                                  permissions={permissions}
                                //mySelf = {mySelf}
                                                  record="record"
                            />
                            {/*commenting out temporarily, as conflicts counter returns wrong results due to backend issue (z-filtering)*/}
                            {/*<MissionConflicts2ShowLink  />*/}
                        </Datagrid>
                    }
                />
            </List>
        )
    }
);




const showStyles = theme => ({
    showInnerLayout: {
        display: "flex",
        flexDirection: "column"
    },
    customField: {
        marginTop: 2*theme.spacing.unit,
        marginBottom: 1*theme.spacing.unit
    },
    row112: row112style,
    rowKSID: rowKSIDstyle,
    rowKSID112: rowKSID112style,
    abuse112Warning: {
        margin: "3rem 0"
    }
});

export const MissionsShow = withStyles(showStyles)(
    ({permissions, classes, ...props}) => {
       
        console.log('MissionsShow props', permissions, props);
        //buu, sprint,
        // not inited yet..
        if (!permissions) {
            return null
        }
        const hideForOperator = (permissions && permissions.roles.indexOf('operator') !== -1);
        return (
            <ShowController {...props}>
                {controllerProps =>

                    <ShowView {...props}
                              {...controllerProps}
                              title={<MyRecordTitle resource={'missions'}/>}
                              actions={<BasicShowActions/>}
                    >
                        <SimpleShowLayout className={classes.showInnerLayout}>

                            {
                                MISSION_112_ENABLED &&
                                get(controllerProps, 'record.etc.is112', false) &&
                                <div>
                                    <TranslatableTypography className={classes.row112}
                                                            content={'resources.missions.fields.etc_is112'}
                                                            variant={'display1'}
                                                            upperCase

                                    />

                                </div>
                            } 

                            {
                                get(controllerProps, 'record.etc.observers', [])
                                .some((o) => o.name.startsWith("POL") && o.name.length === 16) && (
                                    <div>
                                    <TranslatableTypography className={classes.rowKSID}
                                                            content={'resources.missions.fields.etc_isKSID'}
                                                            variant={'display1'}
                                                            upperCase
                                    />
                                    </div>
                                )
                            }

                            {MISSION_112_ENABLED && (hideForOperator
                                ? null
                                :
                                <ReferenceField source="operatorid"
                                                reference="operators"
                                                linkType={false}
                                                addLabel={false}
                                >
                                    <FunctionField render={
                                        record => {
                                            const is112Operator = new OperatorPrivileges(get(record, 'priv', 0)).is112();
                                            const is112Mission = get(controllerProps, 'record.etc.is112', false);
                                            return is112Mission && !is112Operator &&
                                                <WarningText className={classes.abuse112Warning}
                                                             colored
                                                             content={
                                                                 <TranslatableTypography
                                                                        content={`resources.${RESOURCES.MISSIONS}.abuse112`}
                                                                        variant={'body2'}
                                                                 />
                                                             }
                                                />
                                        }
                                    }

                                    />
                                </ReferenceField>
                            )}

                            <MyTextField source="uid"/>
                            {
                                MISSION_STATUS_TRANSLATIONS_ENABLED &&
                                <StatusField source="status"
                                             tooltip={false}
                                             statusMappingFn={translateMissionStatusToTypo}
                                             iconGetter={getStatusIconColored}
                                />
                            }
                            <ApprovalNo showLabel={true} classes={{root: classes.customField}}/>
                            <AtcMsgBlock classes={{root: classes.customField}}/>

                            <MyTextField source="created"
                                         format={dateTimeFormatter}/>

                            {hideForOperator
                                ? null
                                :
                                <ReferenceField source="operatorid"
                                                reference="operators"
                                                linkType={false}
                                                addLabel={false}
                                >
                                    <PrivilegesField source="priv"/>
                                </ReferenceField>
                            }

                            {hideForOperator //show self not needed
                                ? null
                                :
                                <ReferenceField source="operatorid"
                                                reference="operators"
                                                linkType="show"
                                                addLabel={true}
                                >
                                    <TextField source="email"/>
                                </ReferenceField>
                            }

                            {hideForOperator
                                ? null
                                :
                                <ReferenceField source="operatorid"
                                                reference="operators"
                                                linkType={false}
                                                label="resources.operators.fields.phone"
                                                addLabel={true}
                                >
                                    <TextField source="phone"/>
                                </ReferenceField>
                            }
                            <TextField source="name"/>

                            {controllerProps.record && controllerProps.record.typ ?
                                <TextField source="typ"
                                           label="resources.missions.fields.type"
                                />
                                :
                                <Labeled label="resources.missions.fields.type">
                                    <TranslatableTypography
                                        component="span"
                                        body1="body1"
                                        content={'not_translated'}
                                        options={{ '_': 'Default VLOS'}}
                                    />

                                </Labeled>
                            }

                            {   MISSION_112_ENABLED &&
                                has(controllerProps, 'record.etc.is112') &&
                                <BooleanField  source="etc.is112"
                                               label={'resources.missions.fields.etc_is112'}
                                />
                            }

                            {controllerProps.record && controllerProps.record.typ &&
                                <SelectField source="etc.activityType"
                                             label={'resources.missions.fields.etc_activityType'}
                                             choices={activityTypes[controllerProps.record.typ]}
                                />
                            }

                            <TextField source="etc.shortDescription"
                                       label={'resources.missions.fields.etc_shortDescription'}/>

                            <TextField source="etc.smbt"
                                         label={'resources.missions.fields.etc_smbt'}
                            />

                            <TextField source="mcmt"
                                       label={'resources.missions.fields.mcmt'}
                            />

                            <MyTextField source="start"
                                         format={dateTimeFormatter}/>

                            <MyTextField source="stop"
                                         format={dateTimeFormatter}/>

                            {/*<h3>!!!!!!!!unhide drones!!!!!!</h3>*/}

                            <ArrayField source="etc.drones"
                                        label={'resources.missions.fields.etc_drones'}
                                        >
                                {/* custom renderer here? */}
                                <Responsive
                                    small={
                                        <MissionDronesSummaryCards resource={props.resource}
                                                                   disableSort
                                                                   {...{permissions}}/>
                                    }
                                    medium={
                                        <Datagrid>
                                            <ReferenceField source="id"
                                                            label={'resources.drones.fields.registry'}
                                                            reference="drones"
                                                            linkType="show"
                                            >
                                                <ConditionalDroneRegistryField //source="registry"
                                                />

                                            </ReferenceField>
                                            <ReferenceField source="id"
                                                            label={'resources.droneModels.fields.make'}

                                                            reference="drones"
                                                            linkType={false}
                                            >
                                                <TextField source="make"/>

                                            </ReferenceField>
                                            <ReferenceField source="id"
                                                            label={'resources.droneModels.fields.model'}

                                                            reference="drones"
                                                            linkType={false}
                                            >
                                                <TextField source="model"/>

                                            </ReferenceField>
                                            <ReferenceField source="id"
                                                            label={'resources.droneModels.fields.type'}

                                                            reference="drones"
                                                            linkType={false}
                                            >
                                                <TextField source="type"/>

                                            </ReferenceField>
                                            <ReferenceField source="id"
                                                            label={'resources.droneModels.fields.class'}

                                                            reference="drones"
                                                            linkType={false}
                                            >
                                                <TextField source="class"/>
                                            </ReferenceField>

                                            <ReferenceField source="id"
                                                            label={'resources.droneModels.fields.mtom'}

                                                            reference="drones"
                                                            linkType={false}
                                            >
                                                <TextField source="mtom"/>

                                            </ReferenceField>

                                            <ReferenceField source="id"
                                                            label={'resources.droneModels.fields.fs'}

                                                            reference="drones"
                                                            linkType={false}
                                            >
                                                <TextField source="fs"/>

                                            </ReferenceField>
                                            <ReferenceField source="id"
                                                            label={'resources.applications.verification'}

                                                            reference="drones"
                                                            linkType={false}
                                            >
                                                <StatusField source="status"
                                                             tooltip={true}
                                                             iconGetter={getStatusIconColored}
                                                             statusMappingFn={translateCaaStatusToTypo}
                                                             label={'resources.applications.verification'}
                                                />

                                            </ReferenceField>
                                        </Datagrid>
                                    }
                                />


                            </ArrayField>

                            <ArrayField source="etc.observers"
                                        label={'resources.missions.fields.etc_observers'}>
                                <Datagrid>
                                    <TextField source="name"
                                               label={'resources.missions.fields.etc_observerName'}/>
                                    <TextField source="phone"
                                               label={'resources.missions.fields.etc_observerPhone'}/>
                                    
                                </Datagrid>
                            </ArrayField>

                            <TextField source="etc.pilotNr"
                                       label={'resources.missions.fields.etc_pilotNr'}/>
                            <TextField source="etc.operatorNr"
                                       label={'resources.missions.fields.etc_operatorNr'}/>

                            {/*<MyTextField source="legs" format={missionZoneLegsCntFormatter}*/}

                            <MyFilesField source="attn"
                                          label={'resources.files.dropboxLabel'} addLabel={true}/>

                            <NoConflictsUnderstoodField/>

                            <MyMissionField permissions={permissions}/>

                            {hideForOperator
                                ? null
                                :
                                <MissionAtcUserToolbar permissions={permissions}/>
                            }
                        </SimpleShowLayout>
                    </ShowView>
                }
            </ShowController>
        )
    });

const missionTypes = [
    {id: MissionType.VLOS, name: 'VLOS'},
    {id: MissionType.BVLOS, name: 'BVLOS'}
];

const additionalInfoEditFields = () =>
    <>
        <Tooltip content='resources.missions.tooltips.smbt'>
            <NumberInput source="etc.smbt"
                         label={'resources.missions.fields.etc_smbt'}
                         validate={[number(), minValue(1)]}
                         fullWidth
            />
        </Tooltip>
        <Tooltip content='resources.missions.tooltips.mcmt'>
            <NumberInput source="mcmt"
                         label={'resources.missions.fields.mcmt'}
                         validate={[number(), minValue(1)]}
                         fullWidth
            />
        </Tooltip>
        <Tooltip content='resources.missions.tooltips.shortDescription'>
            <LongTextInput source="etc.shortDescription"
                           label={'resources.missions.fields.etc_shortDescription'}
            />
        </Tooltip>
        <Tooltip content='resources.missions.tooltips.pilotNr'>
            <LongTextInput source="etc.pilotNr"
                           label={'resources.missions.fields.etc_pilotNr'}
                           validate={pilotNrRequired}
                           
            />
           
        </Tooltip>
        <Tooltip content='resources.missions.tooltips.operatorNr'>
            <LongTextInput source="etc.operatorNr"
                           label={'resources.missions.fields.etc_operatorNr'}
                           validate={operatorNrRequired}
                          
            />
        </Tooltip>
    </>;

const activityTypeEditFields = (missionType) =>
    <Tooltip content='resources.missions.tooltips.activityType'>
        <SelectInput source="etc.activityType"
                     label={'resources.missions.fields.etc_activityType'}
                     choices={activityTypes[missionType]}
                     validate={defaultRequired}
        />
    </Tooltip>;

const startStopEditFields = ({startTime, stopTime, formName, change, showNotification}) => {

    const _normalizeStartTime = (time) => {
        const normalizedTime = moment(time).subtract(5, 'm');
        change(formName, 'start', normalizedTime);
        showNotification(
            'resources.missions.startTimeNormalizeWarning',
            'warning',
            {
                autoHideDuration: 6000,
                messageArgs: {
                    time: normalizedTime.format('HH:mm')
                }
            });
    };

    const _normalizeStopTime = (time) => {
        const normalizedTime = moment(time).add(5, 'm');
        change(formName, 'stop', normalizedTime);
        showNotification(
            'resources.missions.stopTimeNormalizeWarning',
            'warning',
            {
                autoHideDuration: 6000,
                messageArgs: {
                    time: normalizedTime.format('HH:mm')
                }
            });
    };

    const _fixMinStartTime = () => {
        const normalizedTime = moment().add(1, 'm');
        change(formName, 'start', normalizedTime);
        showNotification(
            'resources.missions.startTimeMinNormalizeWarning',
            'warning',
            {
                autoHideDuration: 6000,
                messageArgs: {
                    time: normalizedTime.format('HH:mm')
                }
            });
    };

    return (
        <>
            <DateTimePickerLocale source="start"
                                  label={'resources.missions.fields.start'} //required for time picker
                                  validate={defaultRequired}
                                  minDate={minDate}
                                  onChange={(time) => {
                                      if(moment(time).isBefore(moment())) {
                                          //needs to use timeout because onChange is fired sync before view change,
                                          setTimeout(_fixMinStartTime, 10);
                                      }
                                      if (moment(time).isAfter(moment(stopTime))) {
                                          _normalizeStopTime(time);
                                      }
                                  }
                                  }
                                  isRequired={true}
            />
            <DateTimePickerLocale source="stop"
                                  label={'resources.missions.fields.stop'} //required for time picker
                                  validate={defaultRequired}
                                  minDate={moment(startTime).add(5, 'm') || minDate}
                                  isRequired={true}
                                  onChange={(time) => {
                                      if (moment(time).isBefore(moment(startTime).add(5, 'm'))) {
                                          //needs to use timeout because onChange is fired sync before view change,
                                          setTimeout(()=>_normalizeStopTime(startTime), 10);
                                      }
                                  }
                                  }
            />
        </>
    )
};

const nameAndTypeEditFields = (formSyncErrors) =>
    <>
        <Tooltip content='resources.missions.tooltips.name'>
            <LongTextInput source="name" validate={defaultRequired}
                           label={'resources.missions.fields.name'}
                           id="missionName"
                           onChange={() => {
                               if (formSyncErrors.name) {
                                   document.getElementById("missionName").blur();
                                   document.getElementById("missionName").focus();
                               }
                           }}
            />
        </Tooltip>
        <br/>
        <RadioButtonGroupInput source="typ"
                               choices={missionTypes}
                               label="resources.missions.fields.type"
                               translateChoice={false}
                               validate={required()}
        />
    </>;


const observersAndAttnEditFields = ({mySelf, myAppExtras, observers, translate, hasPrivileges}) =>
    <>
        <Labeled label={'resources.missions.labels.contactData'}>
            <>
                <Tooltip content='resources.missions.tooltips.phoneInputDisabled'>
                    <DisabledInput label={'myroot.dashboard.accountForm.phone'}
                                   input={{value: mySelf.phone}}
                    />
                </Tooltip>
                <DisabledInput label={'myroot.dashboard.accountForm.phoneVerified'}
                               input={mySelf.phoneVerified ? {value: translate('ra.boolean.true')} : {value: translate('ra.boolean.false')}}
                />
            </>
        </Labeled>
        {/* add find by email */}
        <Tooltip content='resources.missions.tooltips.observers'>
            <ArrayInput source="etc.observers"
                        label={'resources.missions.fields.etc_additionalObservers'}>

                <SimpleFormIterator disableAdd={shouldDisableAdd(observers, ['name', 'phone'])}>
                    <TextInput source="name"
                               label={'resources.missions.fields.etc_observerName'}/>
                    <TextInput source="phone"
                               label={'resources.missions.fields.etc_observerPhone'}/>
                </SimpleFormIterator>
            </ArrayInput>
        </Tooltip>
        <br/><br/>
        <InfoText content={
            <TranslatableTypography content={ACM_INFOBOXES_ENABLED && hasPrivileges ?
                'resources.missions.attnInfoWithPriv'
                :
                'resources.missions.attnInfo'}
            />
        }/>
        <MyFileInput source="attn"
                     multiple={true}
                     label={'resources.missions.fields.additionalDocs'}
                     labelMultiple={'resources.files.dropboxPlaceholder'}
                     accept="image/jpeg,image/png,application/pdf"
                     maxSize={myAppExtras.app.maxAttnSize}>
            <MyFileField source="src" title="title"/>
        </MyFileInput>
    </>;

const NoConflictsUnderstoodField = (props) =>
    <FormDataConsumer {...props}>
        {({formData, ...rest}) => {
            const {noConflictsDialogUnderstood} = formData.etc;
            if (noConflictsDialogUnderstood === undefined) return null;
            else return (
                <div style={{padding: "1rem 0"}}>
                    {
                        noConflictsDialogUnderstood ?
                            <InfoText
                                content={
                                    <TranslatableTypography
                                        variant={'body2'}
                                        content={'resources.missions.fields.etc_noConflictsDialogYesUnderstood'}
                                    />
                                }
                            />
                            :
                            <WarningText
                                colored
                                content={
                                    <TranslatableTypography
                                        variant={'body2'}
                                        content={'resources.missions.fields.etc_noConflictsDialogNotUnderstood'}
                                    />
                                }
                            />
                    }
                </div>
            );
        }}
    </FormDataConsumer>;

const mission112Field = () =>
        <Tooltip content='resources.missions.tooltips.is112' placement={'top-start'}>
            <BooleanInput source="etc.is112"
                          label={'resources.missions.fields.etc_is112'}
            />
        </Tooltip>;

class PureMissionsCreate extends React.Component {

    state = {
        lastStep: false,
    };



    componentWillUpdate(nextProps, nextState, nextContext) {
        const {showInfoMessages, translate, missionType, mySelf} = this.props;


        if(nextProps.missionType !== missionType) {
            const privileges = new OperatorPrivileges(mySelf.priv);
            const isSuavo = privileges.isSuavo();
            const privilegesArray = [];
            if(isSuavo) privilegesArray.push('SuperPILOT');
            if(privileges.is112()) privilegesArray.push('112R');

            const createVLOSinfoMessageFn = ACM_INFOBOXES_ENABLED && isSuavo ?
                ()=>showInfoMessages({
                    situationID: INFO_MSG_MISSION_CREATE_VLOS_SUAVO,
                    locationValid: '/missions/create',
                    data: {privileges: privilegesArray.join(', ')}})
                :
                ()=>showInfoMessages({situationID: INFO_MSG_MISSION_CREATE_VLOS, locationValid: '/missions/create'})
            ;
            const createBVLOSinfoMessageFn = ACM_INFOBOXES_ENABLED && isSuavo ?
                ()=>showInfoMessages({
                    situationID: INFO_MSG_MISSION_CREATE_BVLOS_SUAVO,
                    locationValid: '/missions/create',
                    data: {privileges: privilegesArray.join(', ')}})
                :
                ()=>showInfoMessages({situationID: INFO_MSG_MISSION_CREATE_BVLOS, locationValid: '/missions/create'})
            ;

            switch (nextProps.missionType) {
                case 'VLOS':
                    createVLOSinfoMessageFn();
                    break;
                case 'BVLOS':
                    createBVLOSinfoMessageFn();
                    break;
            }
        }
    }

    _resetForm = (formName) => {
        StoreProvider.getStore().dispatch(reset(formName));
        this.setState({
            lastStep: false
        });

        // clean records for case when create was fired by cloning
        // (react-admin tries to read out form sources from location.state.record object)
        this.props.history.replace({
            pathname: '/missions/create',
            state: {
                record: {}
            }
        });
    };

    _setLastStep = () => {
        if (this.state.lastStep === false)
            this.setState({
                lastStep: true
            })
    };

    _onStepChanged = (step) => {
        const {showInfoMessages, mySelf} = this.props;
        const is112User = new OperatorPrivileges(mySelf.priv).is112();

        if (INFOBOX_NEW_MISSION_INFOS_ENABLED && step === 3) {
            const {missionType} = this.props;
            switch (missionType) {
                case 'VLOS':
                    showInfoMessages({situationID: INFO_MSG_MISSION_TARGET_VLOS, locationValid: '/missions/create'});
                    break;
                case 'BVLOS':
                    showInfoMessages({situationID: INFO_MSG_MISSION_TARGET_BVLOS, locationValid: '/missions/create'});
                    break;
          }
        }

        if (MISSION_112_ENABLED && is112User ? step === 7 : step === 6) {
            const {toggleSidebar} = this.props;
            if (this.props.sidebarOpen) toggleSidebar();
            showInfoMessages({situationID: INFO_MSG_MISSION_EDITOR, locationValid: '/missions/create'});
        }
    };

    _onFormExit = () => {
        if(!this.props.sidebarOpen) this.props.toggleSidebar();
    };

    _getSubstractedMinutes = (stopTime, startTime) => {
        return moment(stopTime).diff(moment(startTime), 'minutes');
    };

    _handleSliderChange = (value) => {
        const {change, startTime} = this.props;
        change(createFormName, 'stop', moment(startTime).add(value, 'm'));
    };

    // _getSliderValue = () => {
    //     const {startTime, stopTime} = this.props;
    //     const duration = moment.duration(stopTime.diff(moment(startTime)));
    //     const min = duration.get('minutes');
    //     console.log("MissionsCreate value", min);
    //     return 100;
    // };


    render() {
        const {permissions, formSyncErrors, isFormValid, formMeta, classes, dispatch, myAppExtras,
            sidebarOpen, stopTime, startTime, change, toggleSidebar, showNotification, shortDescription, dronesArray,
            observers, attn, mySelf, translate, location, missionType, isFormPristine, showInfoMessages, mcmt, smbt,
            is112Mission, pilotNr, operatorNr, ...props} = this.props;
        console.log(' MissionsCreate',  this.props);

        const {lastStep} = this.state;
        const recordForMap = has(location, 'state.legs') ? location.state.legs : undefined;
        const isCloning = has(location, 'state.record.name') && location.state.record.name.endsWith("(CLONED)");
        const privileges = new OperatorPrivileges(mySelf.priv);
        const is112User = privileges.is112();
        const hasPrivileges = privileges.get() !== 0;

        const Radio = ({ record }) => <span>{record.name}</span>;

        return (
            <div>
                <Create location={location} {...props}
                    /* undoable={false} -  no undo in create anyway */
                        title={'resources.missions.create'}
                        className={classes.createPaper}

                >

                    <SimpleForm form={createFormName}
                                defaultValue={{}}
                                className={classes.createForm}
                                toolbar={isFormValid && lastStep ? <MissionCreateSaveToolbar/> : null}
                    >
                        <MyHorizontalStepper labelWhenOptional={'myroot.validation.optional'}
                            // subheader={<TranslatableTypography
                            //                      component='p' variant='subheading'
                            //                      color="error"
                            //                      content={'resources.missions.createHelp'}/>}
                                             title={'resources.missions.create'}
                                             onLastEditableStepEntered={() => this._setLastStep()}
                                             onReset={() => this._resetForm(createFormName)}
                                             onStepChanged={(step) => this._onStepChanged(step)}
                                             onExit={this._onFormExit}
                                             lastStepIsEditableOne
                        >

                            <MyStep label={'resources.missions.steps.missionNameAndType'} key={0}
                                    isValid={!formSyncErrors.name && !formSyncErrors.typ && (!isFormPristine || isCloning)}>

                                {nameAndTypeEditFields(formSyncErrors)}

                            </MyStep>

                            <MyStep label={'resources.missions.steps.duration'} key={1}
                                    isValid={!formSyncErrors.start && !formSyncErrors.stop}>
                                {/*should it be used as validation? or recommendation? for now just showing always*/}
                                <InfoText content={
                                    missionType === MissionType.VLOS
                                        ? <TranslatableTypography
                                            content={ACM_INFOBOXES_ENABLED && hasPrivileges ?
                                                'resources.missions.recommendedStartInfoVLOSwithPriv'
                                                :
                                                'resources.missions.recommendedStartInfoVLOS'
                                            }
                                            options={{
                                                vlosTime: VLOS_OFFICIAL_CONSIDER_TIME,
                                                ...(ACM_INFOBOXES_ENABLED && {bvlosTime: BVLOS_OFFICIAL_CONSIDER_TIME})
                                            }}
                                        />
                                        : <TranslatableTypography
                                            content={ACM_INFOBOXES_ENABLED && hasPrivileges ?
                                                'resources.missions.recommendedStartInfoBVLOSwithPriv'
                                                :
                                                'resources.missions.recommendedStartInfoBVLOS'
                                            }
                                            options={{
                                                bvlosTime: BVLOS_OFFICIAL_CONSIDER_TIME,
                                                ...(ACM_INFOBOXES_ENABLED && {vlosTime: VLOS_OFFICIAL_CONSIDER_TIME})
                                            }}
                                        />
                                }
                                />

                                {
                                    startStopEditFields({
                                        startTime,
                                        stopTime,
                                        formName: createFormName,
                                        change,
                                        showNotification,
                                    })
                                }

                                <br/>
                                {startTime &&
                                <FormControl>
                                    <TranslatableTypography content={'resources.missions.duration'}/>
                                    <Typography>{this._getSubstractedMinutes(stopTime, startTime)} min </Typography>
                                    <Slider
                                        className={classes.slider}
                                        value={this._getSubstractedMinutes(stopTime, startTime) > 180 ? 180 : this._getSubstractedMinutes(stopTime, startTime)}
                                        aria-labelledby="duration"
                                        onChange={this._handleSliderChange}
                                        min={5}
                                        max={180}
                                        step={5}
                                        marks={
                                            {
                                                30: '30min',
                                                60: "1h",
                                                90: "1h:30",
                                                120: "2h",
                                                150: "2h:30",
                                                180: "3h"
                                            }
                                        }
                                    />
                                </FormControl>
                                }
                            </MyStep>

                            {/*Validation needed although step is optional to proper button enabling order*/}
                            <MyStep label={'resources.missions.steps.additionalInfo'} key={2}
                                    // isValid={(!!shortDescription || !!smbt || !!mcmt) &&
                                    //     (operatorNr && pilotNr) &&
                                    //     !formSyncErrors.mcmt &&
                                    //     !has(formSyncErrors, 'etc.smbt')
                                    //     }
                                    isValid={(!!operatorNr && !!pilotNr && operatorNr.length === 16 && pilotNr.length === 19)}
                                
                             
                            >
                                {additionalInfoEditFields()}

                            </MyStep>

                            <MyStep label={'resources.missions.steps.target'} key={3}
                                    isValid={formSyncErrors.etc === undefined ? true : !formSyncErrors.etc.activityType}>

                                {activityTypeEditFields(missionType)}

                            </MyStep>

                            {/*Validation needed although step is optional to proper button enabling order*/}
                            <MyStep label={'resources.missions.steps.aircrafts'} key={4}
                                    isValid={
                                        !!dronesArray
                                        && dronesArray.length > 0
                                        && Object.values(dronesArray[0]).length > 0}
                            >
                                <DroneArrayInput dronesArray={dronesArray} shouldDisableAdd={shouldDisableAdd}/>
                            </MyStep>

                            <MyStep label={'resources.missions.steps.observers'} optional key={5}
                                    isValid={
                                        !!observers
                                        && observers.length > 0
                                        && Object.values(observers[0]).length > 0
                                        || !!attn && attn.length > 0
                                    }
                            >
                                {observersAndAttnEditFields({mySelf, myAppExtras, observers, translate, hasPrivileges})}

                            </MyStep>

                            {
                                MISSION_112_ENABLED &&
                                is112User &&
                                <MyStep label={'resources.missions.steps.mission112'} optional key={7}
                                        isValid={
                                            !!is112Mission
                                        }
                                >
                                    {mission112Field()}
                                </MyStep>
                            }


                            <MyStep label={'resources.missions.steps.graphicalMissionEditor'} key={6}>
                                <MyMissionField source="legs"
                                                enableEdit={true} //always operator
                                    //liveValues={liveValues}
                                                record={recordForMap}
                                                altSliderOptions={{
                                                    max: missionType === MissionType.VLOS ? VLOS_MAX_ALT : BVLOS_MAX_ALT,
                                                }}
                                />
                            </MyStep>

                        </MyHorizontalStepper>
                    </SimpleForm>

                </Create>
            </div>
        )
    }
}

const createFormSelector = formValueSelector(createFormName);

const mapDispatchToProps_create = (dispatch) => ({
    toggleSidebar: bindActionCreators(toggleSidebarAction, dispatch),
    change: bindActionCreators(change, dispatch),
    showNotification: bindActionCreators(showNotification, dispatch),
    showInfoMessages: bindActionCreators(showInfoMessages, dispatch),
});

const mapStateToProps_create = state => ({
        myAppExtras: state.myAppExtras,
        mySelf: state.mySelf,
        sidebarOpen: state.admin.ui.sidebarOpen,
        //global validation
        isFormValid: isValid(createFormName)(state),
        //touched or blured
        formMeta: getFormMeta(createFormName)(state),
        //partial validation
        formSyncErrors: getFormSyncErrors(createFormName)(state),
        startTime: createFormSelector(state, 'start'),
        stopTime: createFormSelector(state, 'stop'),
        missionType: createFormSelector(state, 'typ'),
        shortDescription: createFormSelector(state, 'etc.shortDescription'),
        mcmt: createFormSelector(state, 'mcmt'),
        smbt: createFormSelector(state, 'etc.smbt'),
        dronesArray:  createFormSelector(state, 'etc.drones'),
        observers: createFormSelector(state, 'etc.observers'),
        is112Mission: createFormSelector(state, 'etc.is112'),
        attn: createFormSelector(state, 'attn'),
        isFormPristine: isPristine(createFormName)(state),
        operatorNr: createFormSelector(state, 'etc.operatorNr'),
        pilotNr: createFormSelector(state, 'etc.pilotNr'),
});


export const MissionsCreate = compose(
    translate,
    withStyles(styles),
    connect(mapStateToProps_create,  mapDispatchToProps_create)
)(PureMissionsCreate);


const defaultRequired = [required(), minLength(2), maxLength(64)];
const defaultNonRequired = [minLength(2), maxLength(64)];
const operatorNrRequired=[required(), minLength(16), maxLength(16)];
const pilotNrRequired=[required(), minLength(19), maxLength(19)];



export class MissionsEditBase extends React.Component {

    _normalizeStartTime = (time) => {
        const normalizedTime = moment(time).subtract(5, 'm');
        this.props.change(editFormName, 'start', normalizedTime);
        this.props.showNotification(
            'resources.missions.startTimeNormalizeWarning',
            'warning',
            {
                autoHideDuration: 6000,
                messageArgs: {
                    time: normalizedTime.format('HH:mm')
                }
            });
    };

    render() {
        const {permissions,
            change,
            liveValues,
            myAppExtras,
            mySelf,
            sidebarOpen,
            isFormValid,
            formMeta,
            formSyncErrors,
            startTime,
            stopTime,
            status,
            translate,
            observers,
            dronesArray,
            missionType,
            showNotification,
            toggleProfileView,
            profileView,
            ...props} = this.props;
        console.log('MissionsEdit', permissions, this.props);
        const isOperator = permissions && permissions.roles && permissions.roles.indexOf('operator') !== -1;
        const {hasPhone, hasNickname, hasFullName} = mySelf.userState;
        const privileges = new OperatorPrivileges(mySelf.priv);
        const is112User = privileges.is112();
        const hasPrivileges = privileges.get() !== 0;

        //Safety valve. Do not render if finally mission went to accepted.
        //TODO probably redirect to show would be more profi
        if(status ===  MissionV2Statuses.ACCEPTED) return null;
        //Safety valve. Do not render toolbar if mission is awaiting for acceptance.
        if(status ===  MissionV2Statuses.PLANNED) return null;

        return (
            <Edit {...props}
                  undoable={false}
                  actions={<BasicEditActions/>}
                  title={<MyRecordTitle resource={'missions'}/>}
            >
                <SimpleForm form={editFormName}
                            //onChange={damn}
                            defaultValue={{}}
                            toolbar={profileView.visible ? null : <MissionEditSaveToolbar/>}
                >
                    <MyTextField source="uid"/>
                    {
                        MISSION_STATUS_TRANSLATIONS_ENABLED &&
                        <StatusField source="status"
                                     tooltip={false}
                                     statusMappingFn={translateMissionStatusToTypo}
                                     iconGetter={getStatusIconColored}
                        />
                    }
                    <AtcMsgBlock />

                    <DisabledInput source="created"
                                   format={dateTimeFormatter}/>

                    {nameAndTypeEditFields(formSyncErrors)}

                    {MISSION_112_ENABLED && is112User && mission112Field()}

                    {
                        startStopEditFields({
                            startTime,
                            stopTime,
                            formName: editFormName,
                            change,
                            showNotification,
                        })
                    }

                    {additionalInfoEditFields()}

                    {activityTypeEditFields(missionType)}

                    {/*
                // simpler way would be using ReferenceArrayInput,
                // but somehow i predict that we will need to pass some extra dynamic data per drone...
                // so... object not array.
                // DN: you were right..
                */}
                    <DroneArrayInput dronesArray={dronesArray} shouldDisableAdd={shouldDisableAdd}/>

                    {observersAndAttnEditFields({mySelf, myAppExtras, observers, translate, hasPrivileges})}

                    {/*describe me before removing - interesting not working example*/}

                    <NoConflictsUnderstoodField/>

                    {/*TODO fix MissionFieldInput causes on mySelf update and new form init crash. LGZ->This is a hack*/}
                    {/*Don't show mission field when profile view is open*/}
                    {
                        !profileView.visible &&
                        <>
                            <Delay wait={500}>
                            <MyMissionFieldInput source="legs"
                                                 enableEdit={isOperator}
                                                 liveValues={liveValues}
                                                 formName={editFormName}
                                                 altSliderOptions={{
                                                     max: missionType === MissionType.VLOS ? VLOS_MAX_ALT : BVLOS_MAX_ALT,
                                                 }}
                                                 // onMountChange={this._missionFieldMountChange}
                            />
                            </Delay>
                        </>
                    }
                    {(!hasFullName || !hasNickname || !hasPhone) &&
                    <div style={{width: "unset", marginTop: "2rem", marginBottom: "1rem"}}>
                        {!hasFullName &&
                        <WarningText content={<TranslatableTypography content="myroot.auth.sendLockedNoFullName" variant={'body2'}/>}/>
                        }
                        {!hasNickname &&
                        <WarningText content={<TranslatableTypography content="myroot.auth.sendLockedNoNickName" variant={'body2'}/>}/>
                        }
                        {!hasPhone &&
                        <WarningText content={<TranslatableTypography content="myroot.auth.sendLockedPhoneNotVerified" variant={'body2'}/>}/>
                        }
                        <Typography>
                            <a href="javascript:void(0)"
                               onClick={() => toggleProfileView({show: true})}
                            >
                                {translate('myroot.auth.jumpToAccountSettings')}
                            </a>
                        </Typography>
                    </div>
                    }
                </SimpleForm>
            </Edit>
        );
    }
}


//---bit problematic...~using state in field and custom save~
// ugh. save need input
// reenabling, cause otherwise there are problems with updating deleted legs, #130..
const MyMissionFieldInput = (props) => {
    const {source, liveValues, record, ...rest} = props;
    console.log('=========MyMissionFieldInput', props);


    return (
        <Field name={source}
            //onChange={damn}
               {...rest}
               record = {liveValues} //TADA!!!
               component={MyMissionField}
        />
    );
};
const editFormSelector = formValueSelector(editFormName);

const mapStateToProps_edit = (state) => {
    //console.log('====mapStateToProps_edit', state)
    return {
        myAppExtras: state.myAppExtras,
        mySelf: state.mySelf,
        sidebarOpen: state.admin.ui.sidebarOpen,
        //global validation
        isFormValid: isValid(editFormName)(state),
        //touched or editFormName
        formMeta: getFormMeta(editFormName)(state),
        //partial validation
        formSyncErrors: getFormSyncErrors(editFormName)(state),
        liveValues: getFormValues(editFormName)(state), //https://stackoverflow.com/questions/51595027/react-admin-get-current-value-in-a-form
        startTime: editFormSelector(state, 'start'),
        stopTime: editFormSelector(state, 'stop'),
        status: editFormSelector(state, 'status'),
        observers: editFormSelector(state, 'etc.observers'),
        dronesArray:  editFormSelector(state, 'etc.drones'),
        missionType:  editFormSelector(state, 'typ'),
        profileView: state.mySettings.profileView,
    };
};

const mapDispatchToProps_edit = (dispatch) => ({
    change: bindActionCreators(change, dispatch),
    showNotification: bindActionCreators(showNotification, dispatch),
    toggleProfileView: bindActionCreators(toggleProfileView, dispatch),
});

//nice (?) optimization of data passed from redux store to form..
//https://medium.com/@jidefr/the-most-unknown-redux-performance-trick-986fdfe871fa
const connectOpts_edit = {
    areStatesEqual: (prev, next) => {
        return (
            //prev.liveValues === next.liveValues
            //&&
            //prev.myAppExtras.clo next.myAppExtras
            shallowEqual(prev.myAppExtras, next.myAppExtras) // probably can be simple equal, now is  'standard' reducer... but let's wait and see....
        );
    }
};

export const MissionsEdit = compose(
    translate,
    //withStyles(styles), //react warnings here - clean styles
    //connect(mapStateToProps_edit, mapDispatchToProps_edit, null, connectOpts_edit),
    connect(mapStateToProps_edit, mapDispatchToProps_edit) //, null, connectOpts_edit),

)(MissionsEditBase);
