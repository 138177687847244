//based on https://github.com/mui-org/material-ui/blob/master/packages/material-ui/src/Fade/Fade.js

// @inheritedComponent Transition

import React from 'react';
import PropTypes from 'prop-types';
import Transition from 'react-transition-group/Transition';
import { duration } from '@material-ui/core/styles/transitions';
import { withTheme } from '@material-ui/core/styles'
//import { reflow, getTransitionProps } from '../transitions/utils';


export const reflow = node => node.scrollTop;

export function getTransitionProps(props, options) {
    const { timeout, style = {} } = props;

    return {
        duration:
            style.transitionDuration || typeof timeout === 'number' ? timeout : timeout[options.mode],
        delay: style.transitionDelay,
    };
}

const styles = {
    entering: {
        opacity: 1,
    },
    entered: {
        opacity: 1, //diff here!
    },
};

/**
 * The Fade transition is used by the [Modal](/utils/modal) component.
 * It uses [react-transition-group](https://github.com/reactjs/react-transition-group) internally.
 */
class Fade extends React.Component {
    handleEnter = node => {
        const { theme } = this.props;
        reflow(node); // So the animation always start from the start.

        const transitionProps = getTransitionProps(this.props, {
            mode: 'enter',
        });
        node.style.webkitTransition = theme.transitions.create('opacity', transitionProps);
        node.style.transition = theme.transitions.create('opacity', transitionProps);

        if (this.props.onEnter) {
            this.props.onEnter(node);
        }
    };

    handleExit = node => {
        const { theme } = this.props;
        const transitionProps = getTransitionProps(this.props, {
            mode: 'exit',
        });
        node.style.webkitTransition = theme.transitions.create('opacity', transitionProps);
        node.style.transition = theme.transitions.create('opacity', transitionProps);

        if (this.props.onExit) {
            this.props.onExit(node);
        }
    };

    render() {
        const { children, onEnter, onExit, style: styleProp, theme, ...other } = this.props;

        const style = {
            ...styleProp,
            ...(React.isValidElement(children) ? children.props.style : {}),
        };

        return (
            <Transition appear onEnter={this.handleEnter} onExit={this.handleExit} {...other}>
                {(state, childProps) => {
                    return React.cloneElement(children, {
                        style: {
                            opacity: 0,
                            willChange: 'opacity',
                            ...styles[state],
                            ...style,
                        },
                        ...childProps,
                    });
                }}
            </Transition>
        );
    }
}
//
// Fade.propTypes = {
//     /**
//      * A single child content element.
//      */
//     children: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
//     /**
//      * If `true`, the component will transition in.
//      */
//     in: PropTypes.bool,
//     /**
//      * @ignore
//      */
//     onEnter: PropTypes.func,
//     /**
//      * @ignore
//      */
//     onExit: PropTypes.func,
//     /**
//      * @ignore
//      */
//     style: PropTypes.object,
//     /**
//      * @ignore
//      */
//     theme: PropTypes.object.isRequired,
//     /**
//      * The duration for the transition, in milliseconds.
//      * You may specify a single timeout for all transitions, or individually with an object.
//      */
//     timeout: PropTypes.oneOfType([
//         PropTypes.number,
//         PropTypes.shape({ enter: PropTypes.number, exit: PropTypes.number }),
//     ]),
// };

Fade.defaultProps = {
    timeout: {
        enter: duration.enteringScreen,
        exit: duration.leavingScreen,
    },
};

export default withTheme()(Fade);