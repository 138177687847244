import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import pure from 'recompose/pure';
import ArchiveIcon from '@material-ui/icons/Archive';
import Typography from "@material-ui/core/Typography";
import {TooltipProxy as Tooltip} from './TooltipProxy';
import {makeElementTabbable} from "../utils/reactHelpers";


function _getRecordTypographyClear(elStyle, record, source) {
    if(source === 'active'){
        return <span style={elStyle}><Typography>{get(record, source) ? '' : <ArchiveIcon/>}</Typography></span>;
    }else{
        return <span style={elStyle}><Typography>{get(record, source)}</Typography></span>;
    }
    
}

function _getRecordTypographyFormatted(elStyle, format, record, source) {
    if(source === 'active'){
        return <span style={elStyle}><Typography>{format(get(record, source)  ? '' :  <ArchiveIcon/>)}</Typography></span>;
    }else{
        return <span style={elStyle}><Typography>{format(get(record, source))}</Typography></span>;
    }
}

function _getRecordTypography(format, elStyle, record, source) {
    return format
        ? _getRecordTypographyFormatted(elStyle, format, record, source)
        : _getRecordTypographyClear(elStyle, record, source)
}

const MyTextField = ({source, record = {}, elStyle, format, tooltip = false, tooltipFormatter}) => {
    if (tooltip) {
            return (
                <Tooltip
                    title={
                        <Typography>{
                            tooltip.text
                                ? tooltipFormatter(tooltip.text)
                                : tooltipFormatter(get(record, source))}
                        </Typography>
                    }
                    leaveDelay={200}
                    enterTouchDelay={50}
                >
                    {
                        makeElementTabbable(_getRecordTypography(format, elStyle, record, source))
                    }
                </Tooltip>
            );
    }
    else {
        return _getRecordTypography(format, elStyle, record, source);
    }
};

MyTextField.propTypes = {
    addLabel: PropTypes.bool,
    elStyle: PropTypes.object,
    label: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
    tooltip: PropTypes.bool,
    tooltipFormatter: PropTypes.func
};

const PureMyTextField = pure(MyTextField);

PureMyTextField.defaultProps = {
    addLabel: true,
    tooltipFormatter: (text) => text,
};

export default PureMyTextField;
