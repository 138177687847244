
// Mission Viewer, (should also work as Zone Viewer)
// called from mission field in non-editable mode..
// probably should have support for all zones display
// but right now zones are filtered as mission warnings

import React, {Component} from "react";
import { findDOMNode } from 'react-dom';

import 'mapbox-gl/dist/mapbox-gl.css';

import PubSub from 'pubsub-js';
import sizeMe from 'react-sizeme';
import { connect } from 'react-redux';
import compose from 'recompose/compose';

import { processRedraws as processTestRedraws, getTestIco, getTestPath, getTestPoly, getTestPoints, getTestCursor} from "../../glSetups/oldCommon/testDeckInits";

import DeckGLOverlay from '../../Components/DeckGLOverlay';

import {defaultViewportData, mapboxOpts, myAppDefaults} from "../../ConfigurableOpts";

import {
    primitives2newViewport, primitives2polys,
} from "../../glSetups/oldCommon/_CommonStuff";

import {setEditablePrimitives} from "../../glSetups/deckDraw/deckDrawStoreBridge";

import {
    ViewerMapStateConstructor,
    initializeLayersObject,
    AtcMapStateConstructor
} from "../../glSetups/newCommon/mapStateConstructors";

import "../../simplified.css";
import {FullMapLayersRedraw} from "../../glSetups/newCommon/mapStateLayersRedraws";
import {timerStartRq, timerStopRq} from "../../../LogicV1Redux/actions/TickActions";
import {runUiMapSetup} from "../../../LogicV1Redux/actions/MapUxConfigActions";
import get from "lodash.get";


class ViewerMap extends Component {

    constructor() {
        super();
        this.state =  AtcMapStateConstructor();
    };

    static getDerivedStateFromProps(nextProps, prevState) {

        //hmm to optimize?
        return FullMapLayersRedraw({
            prevState: prevState,
            nextProps: nextProps,
        });

    }

    componentDidMount() {

        console.warn('wip: fixing inits/disabes alerts/checkins/socket', this.props);

        //fixme -> config object, should be run earlier (probably)
        this.props.runUiMapSetup({
            tickUpdate: false,

            //passes enable-rq, show to fix
            //alertsV1: true,
            //checkinsV1: true,
            //lprHelipadsV1: true,
            //rtMapZonesV1: true,
            //zonesV2: true,

            //liveAbsbExchange: true,// <= just test

            //rtdmExchangeTicks: true,
            //rtdmCreotechTicks: true,
            //rtdmExchangeAircrafts: true,
            //rtdmSoapLprUnitsTicks: true,
            //missionsV2: true,

        });

    }

    componentWillUnmount() {
        //clearInterval(this.interval);
        //window.removeEventListener('resize', this.resize.bind(this));

    };

    render() {

        const {icos, points, paths, cursor, polys, texts, textsData} = this.state;

        //let overridenViewportData = Object.assign({}, defaultViewportData);


        return (

            <div className="map-window">

                <DeckGLOverlay
                    cursor={cursor}
                    icos={icos}
                    polys={polys}
                    points={points}
                    paths={paths}
                    texts = {texts}
                    textsData = {textsData}
                    //defaultViewportData = {viewportOverride}
                    bBox = {this.props.bBox}
                    useSatelliteMap = {this.props.useSatelliteMap}

                />
            </div>
        );
    }
}

const mapStateToProps = state => {
    //console.log('AtcMap, mapStateToProps', state);

    return {
        mapUxConfig : state.mapUxConfig,

        customLayer : state.customLayer,
        zonesV2 : state.zonesV2,
    }
};

const enhance = compose(
    //translate,
    //muiThemeable(), // force redraw on theme change
    connect(
        mapStateToProps,
        {
            runUiMapSetup,
        },
    )
);

export default enhance(ViewerMap);

