import React from "react";
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

export const GEOM_CONVERSIONS = {
    // WKB:'WKB',
    GEOJSON_FEATURE:'GEOJSON_FEATURE',
    GEOJSON_GEOMETRY:'GEOJSON_GEOMETRY',
};

const options = Object.keys(GEOM_CONVERSIONS);

class SimpleMenu extends React.Component {
    state = {
        anchorEl: null,
    };

    handleClick = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = (event) => {
        this.setState({ anchorEl: null });
    };

    handleMenuItemClick = (event, index) => {
        this.setState({ anchorEl: null });
        if (this.props.onChange) {
            this.props.onChange({mode:options[index]})
        }
    };

    render() {
        const { anchorEl } = this.state;
        console.log(options);

        const selectedIndex = options.indexOf(this.props.defaultVal);
        if (selectedIndex === -1) return null;
        return (
            <div>
                <Button
                    aria-owns={anchorEl ? 'simple-menu' : null}
                    aria-haspopup="true"
                    onClick={this.handleClick}
                >
                    {options[selectedIndex]}
                </Button>
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={this.handleClose}
                >

                    {options.map((option, index) => (
                        <MenuItem
                            key={option}
                            //disabled={index === 0}
                            selected={index === this.state.selectedIndex}
                            onClick={event => this.handleMenuItemClick(event, index)}
                        >
                            {option}
                        </MenuItem>
                    ))}

                </Menu>
            </div>
        );
    }
}

export default SimpleMenu;
