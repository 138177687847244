//early setup / naming conventions... to review

//important -> values are already stored in db, so modifications have to be synced manually with db content
export const MissionV2Statuses = {
    CREATED: 'created',
    PLANNED: 'planned',
    ACCEPTED: 'accepted',
    REJECTED: 'rejected',
    BLOCKED: 'blocked',
};

export const MissionType = {
    VLOS: 'VLOS',
    BVLOS: 'BVLOS',
};

//first iteration of backend status validation: dictionary
const govAllowedStatuses = [
    MissionV2Statuses.ACCEPTED,
    MissionV2Statuses.REJECTED,
    MissionV2Statuses.BLOCKED,
];

const opAllowedStatuses = [
    MissionV2Statuses.CREATED,
    MissionV2Statuses.PLANNED,
];

export function isMissionOpAllowedStatus(status) {
    return opAllowedStatuses.indexOf(status) !== -1;
}

export function isMissionGovAllowedStatus(status) {
    return govAllowedStatuses.indexOf(status) !== -1;
}
