import {getInitialCustomTheme} from "./initialCustomTheme";
import merge from "lodash.merge";

const colorCircleArmy = {
    first: "#FEFFFE",
    second: "#242424",
    third: "#828269",
    forth: "#585E4F",
    fifth: "#3F3E48",
    white: "#FEFFFE",
    black: "#000",
};

const primaryArmy = {
    light: '#9b9b87',
    main: '#828269',
    dark: '#5b5b49',
    contrastText: colorCircleArmy.first,
};

const secondaryArmy = {
    light: '#65646c',
    main: "#3F3E48",
    dark: '#2c2b32',
    contrastText: colorCircleArmy.first,
};

const getArmyThemeCustomKeys = (primary, secondary, colorCircle) => ({

    overrides: {
        MuiAppBar: {
            colorSecondary: {
                backgroundColor: colorCircle.second
            }
        },
        MuiPaper: {
            root: {
                backgroundColor: colorCircle.forth
            },
        },
        MuiPickersCalendarHeader: {
            iconButton: {
                backgroundColor: "transparent",
                '& > *': {
                    backgroundColor: "transparent"
                }
            },
        },
        MuiTableRow: {
            root: {
                '&$hover': {
                    '&:hover': {
                        backgroundColor: secondary.main,
                        color: secondary.contrastText
                    },
                },
                '&:nth-child(odd)': {
                    backgroundColor: primary.light
                },
                '&:nth-child(even)': {
                    backgroundColor: primary.main
                },
            }
        },
        MuiTypography: {
            body1: {
                color: primary.contrastText
            }
        },
    },
    //Default Matrial UI settings
    palette: {
        type: 'dark',
        primary: primary,
        secondary: secondary,
        colorCircle: {
            card: colorCircle.forth,
            link: colorCircle.white,
            menuLink: colorCircle.white,
            menuActiveText: secondary.contrastText,
            menuActiveBackground: primary.main,
            menuText: secondary.contrastText,
        }
    },
});

//TODO clean for good order passing
const armyThemeCommons = getInitialCustomTheme(secondaryArmy, primaryArmy, colorCircleArmy);
const armyThemeCustoms = getArmyThemeCustomKeys(primaryArmy, secondaryArmy, colorCircleArmy);


export default {
    ...merge(armyThemeCommons, armyThemeCustoms)
};

