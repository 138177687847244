
import {FETCH_ALL_ALERTS_V1_RQ, STORE_ALL_ALERTS_V1, SET_ATC_OWNED_ALERT_V1} from "../constants/AlertsV1Constants";

export const fetchAllAlertsV1Rq = (payload) => {
    console.log('fetchAllAlertsV1Rq', payload);
    return ({
        type: FETCH_ALL_ALERTS_V1_RQ,
        payload,
    });
};

export const storeAllAlerts = (payload) => {
    console.log('storeAllAlerts', payload);
    return ({
        type: STORE_ALL_ALERTS_V1,
        payload,
    });
};

//old approach -> single owned zone. fixme later, unused somehow?
export const setOwnedAlert = (payload) => {
    console.log('setOwnedAlert', payload);
    return ({
        type: SET_ATC_OWNED_ALERT_V1,
        payload,
    });
};
