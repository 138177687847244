import {breakpoints} from "./breakpoints";

export const getInitialCustomTheme = (primary, secondary, colorCircle) => ({
    overrides: {
        MuiAppBar: {
            colorSecondary: {
                backgroundColor: primary.dark
            }
        },
        MuiButton: {
            textPrimary: {
                color: primary.contrastText,
                backgroundColor: secondary.main,
                margin: "0.1rem",
                '&:hover': {
                    color: secondary.contrastText,
                    backgroundColor: secondary.light,
                },
            },
            containedPrimary: {
                color: primary.contrastText,
                '&:hover': {
                    color: secondary.contrastText,
                    backgroundColor: secondary.light,
                }
            }
        },
        MuiCard: {
            root: {
                overflowY: "inherit",
                overflowX: "inherit",
                height: "100%",
                margin: "15px 0px 15px",
                width: "100%"
            }
        },
        MuiFormControl: {
            root: {
                [`@media screen and (min-width: ${breakpoints.values.xl}px)`]: {
                    minWidth: "35%"
                },
                [`@media screen and (max-width: ${breakpoints.values.sm}px)`]: {
                    marginRight: "inherit"
                },
                minWidth: "300px",
                marginRight: "3rem",
            }
        },
        MuiFormLabel: {
            root: {
                overflow: "hidden",
                textOverflow: "ellipsis",
                width: "100%",
                whiteSpace: "nowrap",
            },
        },
        MuiInputLabel: {
            shrink: {
                width: "125%",
                overflow: "hidden",
                whiteSpace: "normal"
            },
            animated: {
                transition: "transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
            }
        },
        MuiListItem: {
            root: {
                borderRadius: "3px",
                margin: "10px 15px 0",
            },
            button: {
                '&:hover': {
                    backgroundColor: secondary.main,
                    color: primary.contrastText
                },
            }
        },
        MuiMenuItem: {
            root: {
                fontWeight: "300",
            }
        },
        MuiDrawer: {
            docked: {
                backgroundImage: `linear-gradient(to right,  ${primary.main}, ${primary.light})`,
                display: "flex",
                // '@media (max-width: 599.95px) and (min-width: 0px)': {
                //     backgroundColor: "inherit"
                // }
                //boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                //backgroundColor: "#A3A2A7"
            }
        },
        MuiPaper: {
            elevation0: {
                display: "flex",
                flexDirection: "column",
            }

        },
        /**MuiTableBody: {
                'root': {
                    '&.datagrid-body $': {
                        backgroundColor: "red"
                    }
                }
        },**/
        MuiTableRow: {
            root: {
                '&$hover': {
                    '&:hover': {
                        backgroundColor: secondary.light,
                        color: secondary.contrastText
                    },
                },
                '&:nth-child(even)': {
                    backgroundColor: colorCircle.second
                },
            }
        },
        MuiTableCell: {
            body: {
                color: "inherit"
            }
        },
        MuiTabs: {
            flexContainer: {
                backgroundColor: primary.light
            },
            indicator: {
                backgroundColor: secondary.main,
                height: "4px"
            }
        },
        MuiTab: {
            textColorInherit: {
                color: primary.contrastText
            }
        },
        MuiTypography: {
            body1: {
                color: "inherit"
            }
        },
        MuiStepper: {
            root: {
                //fix for showing connectors in Chrome
                paddingTop: "20px",
                paddingBottom: "22px",
                paddingRight: "20px",
                paddingLeft: "0px",
            }
        },
    },
    //Default Matrial UI settings
    palette: {
        type: 'light',
        primary: secondary,
        secondary: primary,
        colorCircle: {
            ...colorCircle,
            layout: colorCircle.second,
            card: colorCircle.first,
            link: colorCircle.forth,
            menuLink: undefined,
            menuActiveText: primary.contrastText,
            menuActiveBackground: secondary.main,
            menuText: primary.contrastText,
        }
    },
    breakpoints
});