//v1.0.4


import {APP_MODES, urls} from '../../Configs'
import PubSub from 'pubsub-js'
import logger from './Logger'
import {PubSubSignals} from '../constants/PubSubSignals'

import StoreProvider from '../../dr_ra2/MyStoreProvider'
//import DataProvider from '../../dr_ra2/MyDataProvider'
import {fetchAllAlertsV2Rq} from '../actions/AlertsV2Actions'
import {InspectTypes} from "../../modules2lib/common/";
import {functionalColors} from "../../gl_map/glSetups/oldCommon/commonInits";
import {cloneDeep, get, find} from "lodash";
import {isFlightControllable} from "./NewCheckinV2FSM";
import {isoString2UnixTime, unixTimeNow} from "./TimeConverters"
import {ALERT_STATUSES} from "../constants/AlertsV2Enums";
import {createOpCircle, findCheckinRawDataByUid} from "../oldLogic/CheckInsV2List";
import {turfDisjoint} from "../../gl_map/glSetups/oldCommon/commonTurf";
//fixme isCheckInInArea
// this function should compare alert zone or alert custom area with checkIn data
// should be moved to checkins as standarized function
function isCheckInInArea(rawDataCheckin, alert) {
    if (rawDataCheckin) {
        const opCircle = createOpCircle(rawDataCheckin.geojsonFeature.coordinates);
        console.log('isCheckInInArea', opCircle, alert);

        if (!alert.geojson) {
            console.error ('no geojson in alert', alert);
        }

        const alertFeature =  {type:"Feature", geometry: alert.geojson};
        console.log('opCircleFeature', opCircle);
        console.log('alertGeojson', alertFeature);

        const disjoint = turfDisjoint(opCircle, alertFeature );

        console.log('result', !disjoint);

        return !disjoint;
    }
    return false;
}

//call on fetch success, processing data here
export function preProcessAlertsForRtMap(alertsArr, appExtras, mySelf) {

    console.warn('preProcessAlertsForRtMap WIP', alertsArr, appExtras);
    const timeNow = unixTimeNow();

    const atcZoneAlerts = cloneDeep(mySelf.atcOwnedZones);
    const cleanedList = [];

    for (let i = 0; i < alertsArr.length; i++) {
        const alert = alertsArr[i];
        alert.__start = isoString2UnixTime(alert.start);
        alert.__stop = (alert.stop)?isoString2UnixTime(alert.stop):null;

        //request filter should do, but just to ensure;
        if (alert.__stop && alert.__stop < timeNow) {
            console.warn('outdated alert found');
            continue;
        }

        const zoneName = get(alertsArr[i], 'etc.zoneName', false);

        const zone = find(atcZoneAlerts, ['name', zoneName]);
        if (zone) {
            zone.alertData = alert;
        }

        cleanedList.push(alert);
    }

    return {rawData:cleanedList, atcZoneAlerts};
}

//default = temp? solution for old setups
export function processAlertsV2ToDeck(rawAlerts = []) {

    let icos = [], points = [], paths = [], polys = [], texts = [];

    //console.log('fixme -> process geom on load');

    // if (alerts[0])
    //     console.log('processAlerts', alerts[0]);

    // ended_by:null
    // extras:{alertSubtype: "namedZoneAlertLPR", alertTxt: "LPR/HEMS ALARM", amslHeight: 1000, isTest: false}
    // geojson:{coordinates: Array(1), type: "Polygon"}
    // id:789
    // started:1522284897.89835
    // started_by:"test"
    // status:-4
    // until:null
    // zone_name:"EA 48 Warszawa"

    console.log('rawAlerts', rawAlerts);

    rawAlerts.forEach((alert) => {

        let alertPoly = {};
        try {
            alertPoly.polygon = alert.geojson.coordinates;
            alertPoly.elevation = 10000 || alert.extras.amslHeight || 1000; //AGL/AMSL fixme!!
            alertPoly.fillColor = functionalColors.ban;
            alertPoly.inspectData = {
                alert,
                inspectType: InspectTypes.CUSTOM_ALERT_V2
            };

            alert.draft = true;
            polys.push(alertPoly);
        } catch (e) {
            console.error('error in rawAlerts', e.message);
            console.error('wrong alert data, skipping deck init', alert);
        }
    });

    return {icos, points, paths, polys, texts};
}


//call on tick update
//testing for outdated alerts is client-side based (reducing bandwidth)
//unixTime should be serverTime.
export function cleanOutdatedAlerts(rawData, tick) {
    console.log('cleanOutdatedAlerts should be server-side now')

    const timeNow = unixTimeNow();

    let cleanedList = [];
    let dirty = false;
    //console.log('alertsStore/tick', alertsStore, tick);

    for (let i = 0; i < rawData.length; i++) {

        if (rawData[i].__stop && rawData[i].__stop > timeNow) {
            cleanedList.push(rawData[i]);
        } else if (!rawData[i].__stop){
            // stop is null, so..
            cleanedList.push(rawData[i]);
        }
        else {
            // no timeout in owned area, so not need yet... but fixme
            // if (alertsArr[i].zone_name === getOwnedAreaName()) {
            //     setOwnedAreaAlert(null);
            // }

            //console.log('outdated alert in silent mode');

            dirty = true;
        }
    }
    if (dirty) {
        console.log('removed alerts!');
    }

    return {isDirty:dirty, cleanedList };
}


