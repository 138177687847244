import React from 'react'
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import Card from "@material-ui/core/Card/Card";
import CardContent from "@material-ui/core/CardContent/CardContent";
import Typography from "@material-ui/core/Typography/Typography";
import {withStyles} from "@material-ui/core";
import {translate} from 'ra-core'
import compose from 'recompose/compose';
import CardHeader from "@material-ui/core/CardHeader/CardHeader";
import Avatar from "@material-ui/core/Avatar/Avatar";
import IconButton from "@material-ui/core/IconButton/IconButton";
import SettingsIcon from '@material-ui/icons/Settings';
import Drawer from "@material-ui/core/Drawer/Drawer";
// Using icon from v3 here!
import MinimizeIcon from '@material-ui/icons-v3/Minimize';
import Button from "@material-ui/core/Button/Button";
import classnames from 'classnames'
import {RichTextField} from "ra-ui-materialui";
import {Info} from "../logic/box/Info";
import {getTranslationResource} from "../logic/box/BoxTranslationsMap";
import {InfoBoxActions} from "./resources/InfoBoxActions";
import Badge from "@material-ui/core/Badge/Badge";
import {drawerWidth, drawerHeight} from "./BoxContainer";
import {connect} from "react-redux";
import Configs, {FEATURES_V1_2_ENUMS} from "../../Configs";

const INFOBOX_BLINKS_ENABLED = Configs.enabledFeatures.includes(FEATURES_V1_2_ENUMS.INFOBOX_BLINKS);

const BLINK_TIME = 1000;
const BLINK_DELAY_TIME = 100;
const BLINK_ITERATION_COUNT = 1;
const BLINK_ANIM_TOTAL_TIME = BLINK_DELAY_TIME + BLINK_ITERATION_COUNT * BLINK_TIME;

const styles = theme => ({
    drawerPaper: {
        width: "100%",
        height: "unset",
        bottom: "2rem",
        top: "auto",
        border: "1px solid rgba(0, 0, 0, 0.12)",
        maxHeight: drawerHeight,
        borderRadius: "4px",
        boxShadow: "0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12)",
        position: "unset",
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    drawerDocked: {
        backgroundImage: "unset",
        maxHeight: drawerHeight,
        maxWidth: drawerWidth,
        marginTop: 2*theme.spacing.unit,

    },
    drawerDockedHidden: {
        maxHeight: "0",
        maxWidth: "0",
        transition: "max-height 0.5s ease-out 0.045s, max-width 0.01s ease-out 0.545s, margin-top 0.5s ease-out 0.045s",
        marginTop: "0"
    },
    drawerDockedHiddenHorizontal: {
        transition: "max-height 0.5s ease-out 0.045s, max-width 0.545s ease-out 0.01s, margin-top 0.5s ease-out 0.045s",

    },
    settingsCard: {
        margin: "0",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between"
    },
    cardHeader: {
        borderRadius: "4px",
        paddingTop: "0",
        paddingBottom: "0",
        paddingRight: "0",
        boxShadow: "0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12)"
    },
    cardContent: {
        overflowY: "auto",
        paddingTop: 1.8*theme.spacing.unit,
        paddingBottom: 1.8*theme.spacing.unit,
    },
    cardParagraph: {
    },
    action: {
        marginRight: "0",
        marginTop: "0",
        alignSelf: "unset",
    },

    button: {
        margin: 2.2*theme.spacing.unit,
        zIndex: "10",
    },
    avatarColorDefault: {
        backgroundColor: theme.palette.secondary.light,
    },
    badge: {
        color: theme.palette.secondary.contrastText,
        top: "-15px",
        right: "-16px"
    },
    avatarColorWarning: {
        backgroundColor: theme.palette.error.main,
    },
    blinkOnce: {
        backgroundColor: theme.palette.primary.main,
        animation: `blink ${BLINK_TIME}ms ease-out ${BLINK_DELAY_TIME}ms ${BLINK_ITERATION_COUNT}`,
    },
    '@keyframes blink': {
        '100%': {
            backgroundColor: "transparent",
        },
    },
});

class BoxView extends React.Component {

    constructor(props) {
        super(props);
        this.locationValid = null;
        this.snd = new Audio(props.soundPath);
        this.contentRef = React.createRef();
    }

    state = {
        boxOpen: false,
        infos: [],
        page: -1,
        blinkingOnceTimeout: null
    };


    componentWillUpdate(nextProps, nextState, nextContext) {
        //Working on one reference - object is in redux store and is in fact mutable
        //TODO consider shifting with an action
        const queue = nextProps.queue;

        const {popVersion} = this.props;

        //Close if no infos
        if(nextState.infos.length !== this.state.infos.length) {
            if(nextState.infos.length === 0) this._closeBox();
            return;
        }

        //Close if nextPath is not valid for actual info showing in box
        if(this.locationValid && nextProps.location.pathname !== this.props.location.pathname) {
            const nextPath = nextProps.location.pathname;
            if(!nextPath.includes(this.locationValid)) this._closeBox();
        }


        if(nextProps.popVersion !== popVersion) {
            const {infos,boxOpen} = this.state;

            while(queue.length > 0) {
                const infoMsgInit = queue.shift();
                const {situationID, locationValid, data} = infoMsgInit;
                const info = this._getInfo(situationID, locationValid, data);

                if(!this._isSeen(info) && !this._isInBox(info)) {
                    this._playNotification();
                    this.setState(
                        {infos: [...infos, info]},
                        this._showLastPage
                    );
                    if(!boxOpen) this._openBox();
                    this._blinkOnce();
                }
            }
        }
    }

    _isInBox = (infoInput) => {
        const {showDuplicates} = this.props;
        if(showDuplicates) return;
        const {infos} = this.state;
        let is = false;
        infos.map(
            (info) => {
                if(info.getHashBase64() === infoInput.getHashBase64()) {
                    is = true;
                }
            }
        );
        return is;
    };

    _isSeen = (info) => {
        const {seenPersist} = this.props;
        if(!seenPersist) return;
        const hash = info.getHashBase64();
        return seenPersist.includes(hash);
    };

    _getInfo = (situationID, locationValid, data) => {
        const {translate} = this.props;
        const messages = this._getMessagesFromId(situationID, data, translate);
        console.log("IB messages", messages);
        return new Info(messages, locationValid);

    };

    _getMessagesFromId = (id, data = {}, translate) => {
        const translations = getTranslationResource(id);
        console.log("IB", translations);
        const messages = [];
        translations.map(translation => {
            messages.push(translate(translation, data))
        });
        return messages
    };


    _playNotification = () => {
        if(this.props.playNotificationSound) {
            if(this.snd.readyState === 4) {
                this.snd.load();
            }
            this.snd.play().catch(
                (e) => console.warn('BoxView error on playing notification', e)
            );
        }
    };

    _closeBox = () => {
        if(this.state.boxOpen) {
            this.props.onBoxClose();
            this.setState({
                boxOpen: false,
            })
        }
    };

    _openBox = () => {
        if(!this.state.boxOpen) {
            this.props.onBoxOpen();
            this.setState({
                boxOpen: true,
            })
        }
    };

    //TODO move to component
    _mapMessages = (info, idxI) => {

        const messages = info.getMessages();
        this.locationValid = info.getLocationValid();

        return messages.map(
            (message, idxM) => {
                const isRichText = Info.isRichText(message);
                return (
                    <React.Fragment key={idxI * 10 + idxM}>
                        {idxM > 0 &&
                        <br/>
                        }
                        {isRichText ?
                            <RichTextField
                                record={{
                                    content: message
                                }}
                                source={'content'}
                            />
                            :
                            <Typography style={{whiteSpace: 'pre-line'}}>
                                {message}
                            </Typography>
                        }
                    </React.Fragment>
                )
            }
        )
    };

    _handlePrevious = () => {
        const {page} = this.state;
        if(page > -1) {
            this.setState({
                page: this.state.page - 1
            })
        }
    };


    _handleNext = () => {
        this.setState({
            page: this.state.page + 1
        })
    };

    _showLastPage = () => {
        const {page, infos} = this.state;
        const lastPage = infos.length-1;

        if(page !== lastPage) {
            this.setState({
                page: lastPage
            });
        }
        this._scrollToTop()
    };

    _handleSeen = () => {
        const {page, infos} = this.state;
        const {addToSeenInfos} = this.props;
        const info = infos[page];

        const isLastPage = page === infos.length-1;

        if(!info) return;

        const hash = info.getHashBase64();
        console.log("hash", hash);

        addToSeenInfos({hash});
        const newInfos = [...infos];
        newInfos.splice(page, 1);

        this.setState({
            infos: newInfos,
        });

        if(isLastPage) this._handlePrevious();
    };

    _handleNotificationsSettings = () => {
        this.props.toggleNotificationView({show: true});
    };

    _blinkOnce = () => {
        const {blinkingOnceTimeout} = this.state;

        if (!!blinkingOnceTimeout) {
            this._clearAndSetNewBlinkTimeout(blinkingOnceTimeout)
        } else {
            this._setNewBlinkTimeout();
        }
    };

    _setNewBlinkTimeout = () => {
        const timeoutId = setTimeout(
            () =>
                this.setState({
                    blinkingOnceTimeout: null
                }),
            BLINK_ANIM_TOTAL_TIME
        );

        this.setState({
            blinkingOnceTimeout: timeoutId
        })
    };

    _clearAndSetNewBlinkTimeout = (blinkingOnceTimeout) => {
        clearTimeout(blinkingOnceTimeout);
        this.setState({
                blinkingOnceTimeout: null
            },
            () =>
                // this 1ms is needed due to react setState batching
                setTimeout(
                    this._setNewBlinkTimeout,
                    1
                )
        );
    };

    _scrollToTop = () => {
        if(this.contentRef.current) {
            const node = ReactDOM.findDOMNode(this.contentRef.current);
            node.scrollTop = 0;
        }
    };

    render() {

        const {classes, title, hideSeen, icon, isWarning, horizontalContainer} = this.props;
        const {boxOpen, infos, page, blinkingOnceTimeout} = this.state;

        return (
            <>
                <Drawer
                    variant="persistent"
                    anchor="right"
                    open={boxOpen}
                    classes={{
                        paper: classes.drawerPaper,
                        docked: classnames(classes.drawerDocked,
                            {
                                [classes.drawerDockedHidden]: !boxOpen,
                                [classes.drawerDockedHiddenHorizontal]: horizontalContainer,
                            })
                    }}
                    ModalProps={{disablePortal: true}}
                >
                    <Card className={
                        classnames(classes.settingsCard,
                            {
                                [classes.blinkOnce]: INFOBOX_BLINKS_ENABLED && !!blinkingOnceTimeout
                            }
                        )}>
                        <CardHeader
                            avatar={
                                <Avatar aria-label="Info" classes={{colorDefault: classnames({
                                        [classes.avatarColorDefault]: !isWarning,
                                        [classes.avatarColorWarning]: isWarning,
                                    })}}>
                                    {icon}
                                </Avatar>
                            }
                            action={
                                <>
                                    <IconButton onClick={this._handleNotificationsSettings}>
                                        <SettingsIcon/>
                                    </IconButton>
                                    <IconButton onClick={this._closeBox}>
                                        <MinimizeIcon/>
                                    </IconButton>
                                </>
                            }
                            title={title}
                            titleTypographyProps={{variant: "body2"}}
                            className={classes.cardHeader}
                            classes={{
                                action: classes.action
                            }}
                        />
                        <CardContent className={classes.cardContent} ref={this.contentRef}>
                            <div className={classes.cardParagraph} >

                                {infos.map(
                                    (info, idxI) =>
                                        idxI === page && this._mapMessages(info, idxI)
                                )}

                            </div>

                        </CardContent>
                        <InfoBoxActions handlePrevious={this._handlePrevious}
                                        handleNext={this._handleNext}
                                        previousDisabled={page <= 0}
                                        nextDisabled={page === infos.length-1}
                                        handleSeen={this._handleSeen}
                                        pageStatus={[page+1,infos.length]}
                                        hideSeen={hideSeen}
                        />
                    </Card>
                </Drawer>
                {infos.length > 0 && !boxOpen &&
                <Button variant="fab"
                        color="secondary"
                        mini
                        className={classnames(
                            classes.button,
                            {
                                [classes.avatarColorWarning]: isWarning,
                            }
                        )}
                        onClick={this._openBox}
                >
                    <Badge badgeContent={infos.length} color="primary" classes={{badge: classes.badge}}>
                        {icon}
                    </Badge>
                </Button>
                }
            </>
        )
    }
}

BoxView.propTypes = {
    queue: PropTypes.array.isRequired,
    popVersion: PropTypes.number.isRequired,
    seenPersist: PropTypes.array,
    playNotificationSound: PropTypes.bool.isRequired,
    addToSeenInfos: PropTypes.func.isRequired,
    toggleNotificationView: PropTypes.func.isRequired,
    icon: PropTypes.node.isRequired,
    title: PropTypes.string.isRequired,
    hideSeen: PropTypes.bool,
    showDuplicates: PropTypes.bool,
    isWarning: PropTypes.bool,
    soundPath: PropTypes.string.isRequired,
    location: PropTypes.object.isRequired,
    onBoxOpen: PropTypes.func,
    onBoxClose: PropTypes.func,
};

BoxView.defaultProps = {
    onBoxOpen: ()=>{},
    onBoxClose: ()=> {}
};


const mapStateToProps = (state) => ({
    location: state.routing.location,
});

export default compose(
    connect(
        mapStateToProps,
        null
    ),
    translate,
    withStyles(styles),
)(BoxView);
