
//ugh messing up with loopback AND old atc database access at the same time... gross.

//store related

export const STORE_ALL_MISSIONS_V2 = "STORE_ALL_MISSIONS_V2"; //FOR RT_MAP!
export const STORE_CURRENT_MISSION_V2_EDIT = "STORE_CURRENT_MISSION_V2_EDIT"; //for mission editor/planner
export const STORE_SELECTED_MISSION = "STORE_SELECTED_MISSION";



//sagas related

export const FETCH_ALL_MISSIONS_V2_RQ = "FETCH_ALL_MISSIONS_V2_RQ"; //FOR RT_MAP!

//FIXME?
//missing, cos now (1.1) only context usage is from loopback editor.
//FETCH_ONE_MISSION_V2_RQ -> or something like that?

export const SAVE_MISSION_DATA_V2_RQ = "SAVE_MISSION_DATA_V2_RQ"; //SPECIAL SAGA call
export const SAVE_MISSION_STATUS_V2_RQ = "SAVE_MISSION_STATUS_V2_RQ"; //SPECIAL SAGA call
export const CLONE_MISSION_V2 = "CLONE_MISSION_V2";
export const CHECK_HOLIDAYS = 'CHECK_HOLIDAYS';
export const MISSION_TERM_DIALOG_CALLBACK = 'MISSION_TERM_DIALOG_CALLBACK';
export const MISSION_NO_CONFLICTS_DIALOG_CALLBACK = 'MISSION_NO_CONFLICTS_DIALOG_CALLBACK';


export const BVLOS_OFFICIAL_CONSIDER_TIME = 3;
export const VLOS_OFFICIAL_CONSIDER_TIME = 1;

export const BVLOS_MAX_ALT = 120;
export const VLOS_MAX_ALT = 500;


