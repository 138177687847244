import React from 'react';
import styles from "../../css/docs.css"

export const helpPage = (
    <div id="help-content">
        <h1>
        Welcome to PansaUTM!
        </h1>
        <p>
        Website <a href="https://www.pansa.pl/jak-korzystac-z-pansautm/" target="_blank">https://www.pansa.pl/jak-korzystac-z-pansautm/</a> contains instructions on how to use PansaUTM while maintaining the procedures and safety of performing operations.
        
        </p>
        <br/>
        <p>
        If you have any questions or suggestions regarding the operation of the system, please contact us by e-mail: <a href="mailto:drony@pansa.pl">drony@pansa.pl</a>
        </p>
        <br/>
        <p>
        Thank you and wish you safe flights.
        </p>

        <img id="help-image" src="./img/help/help_en.png" alt="Help" />
    </div>
)
