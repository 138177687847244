import React from 'react';
import {maxLength, minLength, required, SimpleForm, TextInput,} from 'react-admin';

import {DisabledInput} from 'ra-ui-materialui'
import {withStyles} from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TranslatableTypography from "../../TranslatableTypography";
import classnames from "classnames";
import {InfoText} from "../../InfoText";
import {WarningText} from "../../WarningText";
import {panelStyles} from "./panelStyles";
import {formValueSelector, isDirty} from "redux-form";
import {connect} from 'react-redux';
import compose from 'recompose/compose';
import SanitizedMuiButton from "../../SanitizedMuiButton";
import {ACCOUNT_USERNAME_FORM, PANEL_USER_NAME} from "../../../logic/settings/AccountConstants";
import {
    validateName,
    validateNoSpaceTrim,
    validateUsername
} from "../../../utils/validationHelpers";

const defaultRequired = [required(), minLength(2), maxLength(64)];

const UserNamePanel =
    ({
         classes,
         hasFullName,
         hasNickname,
         mySelf,
         expanded,
         handleChange,
         handleSave,
         offline,
         disabled,
         translate,
         isGov,
         isOperator,
         isDirty,
         firstName,
         lastName,
         handleNickNameCreate
     }) =>
        <ExpansionPanel expanded={expanded === PANEL_USER_NAME}
                        onChange={handleChange(PANEL_USER_NAME)}
                        id={PANEL_USER_NAME}
                        className={classnames(
                            classes.expansionPanel,
                            {
                                [classes.overflowHide]: expanded && expanded !== PANEL_USER_NAME,
                                [classes.expansionPanelNotActive]: expanded !== PANEL_USER_NAME,
                            }
                        )}
                        square={false}
        >
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon/>}>
                <div className={classes.block}>
                    <Typography component="p" className={classes.heading}>
                        {translate('myroot.dashboard.accountForm.username')}
                    </Typography>
                    <Typography component="p"
                                className={classnames(
                                    classes.subheading,
                                    {
                                        [classes.requiredNonFilled]: !hasFullName,
                                    }
                                )}
                    >
                        {translate('myroot.validation.required')}
                    </Typography>
                </div>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                <div className={classes.block}>

                    <Typography component="p" className={classes.subheading}>
                        {translate('myroot.dashboard.accountForm.usernameDscr')}
                    </Typography>
                    <SimpleForm form={ACCOUNT_USERNAME_FORM}
                                save={handleSave}
                                record={{
                                    username: mySelf.username,
                                    firstName: mySelf.firstName,
                                    lastName: mySelf.lastName,
                                    ...(isOperator && {nickName: mySelf.nickName}),
                                }}
                                redirect={false}
                                toolbar={disabled || !isDirty ? null : undefined}
                    >
                        <TextInput label={'myroot.dashboard.accountForm.username'}
                                   source="username"
                                   {...{disabled}}
                                   validate={[...defaultRequired, validateUsername]}/>
                        <TextInput label={'myroot.dashboard.accountForm.firstName'}
                                   source="firstName"
                                   validate={[...defaultRequired, validateName, validateNoSpaceTrim]}
                                   {...{disabled}}
                        />
                        <TextInput label={'myroot.dashboard.accountForm.lastName'}
                                   source="lastName"
                                   validate={[...defaultRequired, validateName, validateNoSpaceTrim]}
                                   {...{disabled}}
                        />
                        {
                            isOperator ?
                                <>
                                    <DisabledInput label={'myroot.dashboard.accountForm.nickName'}
                                                   source={'nickName'}
                                    />
                                    {hasFullName && !hasNickname && !isDirty &&
                                        <SanitizedMuiButton
                                            variant="raised"
                                            color='primary'
                                            onClick={() => handleNickNameCreate({
                                                firstname: firstName,
                                                lastname: lastName
                                            })}
                                        >
                                            <TranslatableTypography
                                                variant={'button'}
                                                color={'inherit'}
                                                content={'myroot.auth.createNickName'}
                                            />
                                        </SanitizedMuiButton>
                                    }
                                </>
                                :
                                null
                        }
                    </SimpleForm>

                    {
                        isGov ?
                            null
                            :
                            <>
                                {!hasFullName &&
                                <WarningText colored content={<TranslatableTypography content="myroot.auth.sendLockedNoFullName"/>}
                                             className={classnames({
                                                 [classes.disabledText]: expanded === PANEL_USER_NAME,
                                                 [classes.disabledTextHide]: expanded !== PANEL_USER_NAME,
                                             })}
                                />
                                }
                                {!hasNickname && hasFullName &&
                                <WarningText colored content={<TranslatableTypography content="myroot.auth.sendLockedNoNickName"/>}
                                             className={classnames({
                                                 [classes.disabledText]: expanded === PANEL_USER_NAME,
                                                 [classes.disabledTextHide]: expanded !== PANEL_USER_NAME,
                                             })}
                                />
                                }
                                {disabled && !offline &&
                                <InfoText content={<TranslatableTypography content="myroot.auth.editionLocked"/>}
                                          className={classnames({
                                              [classes.disabledText]: expanded === PANEL_USER_NAME,
                                              [classes.disabledTextHide]: expanded !== PANEL_USER_NAME,
                                          })}

                                />
                                }
                                {disabled && offline &&
                                <InfoText content={<TranslatableTypography content="myroot.auth.editionLockedOffline"/>}
                                          className={classnames({
                                              [classes.disabledText]: expanded === PANEL_USER_NAME,
                                              [classes.disabledTextHide]: expanded !== PANEL_USER_NAME,
                                          })}

                                />
                                }
                            </>
                    }

                </div>

            </ExpansionPanelDetails>
        </ExpansionPanel>;

const mapStateToProps = state => {
    return {
        isDirty: isDirty(ACCOUNT_USERNAME_FORM)(state),
        firstName: formValueSelector(ACCOUNT_USERNAME_FORM)(state, 'firstName'),
        lastName: formValueSelector(ACCOUNT_USERNAME_FORM)(state, 'lastName'),
    }
};

const enhance = compose(
    withStyles(panelStyles),
    connect(mapStateToProps, null),
);

export default enhance(UserNamePanel);
