
import React from 'react';

import * as dd from 'dis-gui';
import LegRenderer from './MissionPlannerLegRenderer'
//import { setEditablePrimitives} from "../glSetups/deckDraw";
import PubSub from "pubsub-js";
import logger from "../../LogicV1Redux/oldLogic/Logger";
import MissionPlanner, {MISSION_STATUSES} from '../../LogicV1Redux/oldLogic/MyMissionPlanner';

import {setZoneVisibility} from "../../LogicV1Redux/oldLogic/ZoneRules"; //fixme w redux
import {ddStyle} from "./sampleDisGUI";
import {PubSubSignals} from '../../LogicV1Redux/constants/PubSubSignals'
import { PRIMITIVES} from '../glSetups/deckDraw/DrawEditorEnums'
import {Rnd} from "react-rnd";
import {verticalResizeOnly} from "./rndConstants";
//unused?
// export const LEG_TYPES = [
//     PRIMITIVES.CIRCLE, PRIMITIVES.CAPSULE, PRIMITIVES.POLYGON
// ];

const legsRenderer = (legs) => {
    if (legs.length === 0) return (
        <dd.Text
            label='No Leg'
            value={'Start drawing'}
            onChange={() => {}}
        />
    );
    return legs.map( (leg, idx) =>
        <LegRenderer key={idx} legRecord={leg.legRecord} primitive={leg.primitive}/>
    )
};

const warningsRenderer = (warnings) => {
    console.log(warnings);
    // return Object.entries(zones).map( (entry, idx) => {
    //     console.log(entry[0], entry[1], idx);
    //     return (
    //         <dd.Text
    //             key={idx}
    //             label='Warning'
    //             value={entry[0]}
    //             onChange={() => {}}
    //         />
    //     );
    // });

    return warnings.map( (warning, idx) =>
        <dd.Text
            key={idx}
            label='Warning'
            value={warning.primitive.inspectData.zoneRecord.name}
            onChange={() => {}}
        />
    )
};

const defaultMissionStatus = MISSION_STATUSES.PLANNING;

const defaults = {
    //newLegType : 'unknown',
    operatorId : '',
    missionId : '',
    authToken : '',
    missionStatus : defaultMissionStatus, //hmm
    legs : [],
    missionRecord : null,
    warnings : [],
    fakeAwait : false, //fake for demo
};


//simplified version of old zone editor.
//zone is loaded in view
//FIXME dedupe with redux

export default class MissionPlannerWindow extends React.Component {

    constructor(props) {
        super(props);

        this.state = {};

        //this.onNewLegTypeChange = this.onNewLegTypeChange.bind(this);
        this.createNewLeg = this.createNewLeg.bind(this);
        this.saveMission = this.saveMission.bind(this);

    }

    //removed due to change in flow
    saveMission() {
        console.error('save mission click');
        // this.setState({ fakeAwait:true});
        // setEditablePrimitives(null);
        // const data = Object.assign({}, this.state);
        // PubSub.publish(PubSubSignals.REQUEST_MISSION_SAVE, data);

    }

    componentWillMount() {
        /*
        this.state = getLocalStore();

        //feeding from draw.js event directly, move to redux store...
        this.pubsubDrawEnd = PubSub.subscribe(PubSubSignals.DRAW_UPDATED, (msg, data) => {
            console.log('onDrawEnd mission planner');
            console.log('pubsub', msg, data);
            let updatedPrimitive = data.current;
            let legs = this.state.legs;
            let selected = null;
            for (let i=0; i<legs.length; i++) {
                if (legs[i].primitive === updatedPrimitive) {
                    console.log('updated primitive for leg', i);
                    this.setState({legs:legs});
                    return;
                }
            }
            //else
            console.log('creating new leg', data);
            legs.push({primitive:data});
            this.setState({legs:legs});
        });


        this.pubsubMissionLoaded = PubSub.subscribe(PubSubSignals.MISSION_LOADED, (msg, data) => {
            console.log('onMissionLoaded mission planner');
            console.log('pubsub', msg, data);
            const {missionRecord, legs, primitives} = data;
            const missionStatus = missionRecord.missionStatus;
            if (primitives.length > 0)
                setEditablePrimitives(primitives);
            else
                setEditablePrimitives(null);

            let warnings = {};
            for (let i=0; i < legs.length; i++) {
                const legRecord = legs[i].legRecord;
                if (legRecord.zones) {
                    for (let j = 0; j < legRecord.zones.length; j++) {
                        const zone = legRecord.zones[j];
                        warnings[zone.name] = true; //hmm
                    }
                }
            }

            this.setState({missionRecord, legs, primitives, missionStatus, warnings});

        });

        */

        console.log('NewMissionPlannerWindow, subscribing');
        console.error('double check flow (moved to redux?), heavy rewrites for 1.1');
        //feeding from draw.js event directly, move to redux store...
        // this.pubsubDrawEnd = PubSub.subscribe(PubSubSignals.DRAW_UPDATED, (msg, data) => {
        //     // console.log('onDrawEnd zone editor');
        //     // console.log('pubsub', msg, data);
        //     // this.setState({primitive:data});
        //
        //     console.log('onDrawEnd mission planner');
        //     console.log('pubsub', msg, data);
        //     let updatedPrimitive = data.current;
        //     let legs = this.state.legs;
        //     let selected = null;
        //     for (let i=0; i<legs.length; i++) {
        //         if (legs[i].primitive === updatedPrimitive) {
        //             console.log('updated primitive for leg', i);
        //             this.setState({legs:legs});
        //             return;
        //         }
        //     }
        //     //else
        //     console.log('creating new leg', data);
        //     legs.push({primitive:data});
        //     this.setState({legs:legs});
        // });


        this.pubsubZoneLoaded = PubSub.subscribe(PubSubSignals.NEW_MISSION_LOADED, (msg, data) => {

            console.log('onMissionLoaded mission planner');
            console.log('pubsub', msg, data);
            const {missionRecord, legs, primitives, warnings = []} = data;
            const missionStatus = missionRecord.missionStatus;

            //editable primitive set in  fullscreen editor
            // if (primitives.length > 0)
            //     setEditablePrimitives(primitives);
            // else
            //     setEditablePrimitives(null);


            this.setState({missionRecord, legs, primitives, missionStatus, warnings, fakeAwait:false});
        });
    }

    componentWillUnmount() {

        //PubSub.unsubscribe(this.pubsubDrawEnd);
        PubSub.unsubscribe(this.pubsubMissionLoaded);
    }

    // onClickCreateLegBtn() {
    //     console.log('creating leg');
    //     let legs = this.state.legs;
    //     legs.push({type:this.state.newLegType});
    //     this.setState({legs:legs});
    // }

    createNewLeg({primitive}) {
        console.log('remove? creating leg');
        let legs = this.state.legs;
        legs.push({primitive});
        this.setState({legs:legs});
    }

    // onNewLegTypeChange(e) {
    //     console.log('changin new leg type id',e);
    //     this.setState({
    //         newLegType : e
    //     })
    // }

    render() {
        console.log('mission planner', this.state);

        const showLegsRenderer = (this.state.missionRecord);
        const showFakeAwait = (this.state.fakeAwait);
        const showSaveButton = !showFakeAwait && (this.state.legs && this.state.legs.length > 0);
        const showWarningsRenderer = this.state.warnings && this.state.warnings.length > 0;

        return (

            <dd.GUI style = {ddStyle} >
                {showSaveButton
                    ? <dd.Button
                        label='Save Mission'
                        onClick={this.saveMission}
                    />
                    : null
                }
                {showWarningsRenderer
                    ?
                    <dd.Folder label='Warnings' expanded={false}>
                        {warningsRenderer(this.state.warnings)}
                    </dd.Folder>
                    : null
                }
                {showFakeAwait
                    ? <dd.Text
                        label='Processing'
                        value={'Data, pls wait'}
                        onChange={() => {}}
                    />
                    : legsRenderer((this.state.legs)?this.state.legs:[])
                }

            </dd.GUI>
        )
    }

}

//    console.error('fix css!, without sizeMe if possible!')

export const RndMissionPlannerWnd = ({viewportData}) => (
    <Rnd
        className = 'rnd-window'
        default={{
            //x: viewportData.width - 450,
            y: 10,
        }}

        maxHeight="95%"
        bounds = "parent"
        enableUserSelectHack = {false}
        enableResizing = {verticalResizeOnly}
        dragHandleClassName = "rnd-dragbar"
    >
        <div className="rnd-dragbar">Mission Planner</div>
        <div style={{overflowY:"auto", maxHeight:(viewportData.height-100)+"px"}}>
            <MissionPlannerWindow style={{overflowY:"auto"}}/>
        </div>
    </Rnd>
);

