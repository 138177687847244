export const INIT_PHASES = {
    STARTING:'STARTING',
    READY:'READY'
};

export const FETCH_PHASES = {
    ZONE_TYPES:'ZONE_TYPES',
    MISSIONS:'MISSIONS',
    CHECKINS:'CHECKINS',
    ZONES:'ZONES',
    ALERTS:'ALERTS',
    NONE:'NONE'
};
